import {Inject, Injectable} from '@angular/core';
import {AUTH_MODE} from '../auth.token';
import {AuthConfModel} from '../models/auth-conf.model';
import {MethodAuthService} from './method-auth.service';

@Injectable({
  providedIn: 'root'
})
export class MethodTabService extends MethodAuthService {
  private window: Window | null = null;
  private broadcastChannel: BroadcastChannel | null = null;

  constructor(@Inject(AUTH_MODE) protected readonly authConf: AuthConfModel) {
    super();
  }

  protected close() {
    this.window?.close();
    this.broadcastChannel?.close();
  }

  protected listen(callback: (message: MessageEvent) => void): void {
    if ( this.broadcastChannel){
      this.broadcastChannel.onmessage = callback;
    }
  }

  protected open() {
    this.window = window.open(
      this.authConf.urlAuth,
      this.authConf.appName
    );
    this.broadcastChannel = new BroadcastChannel('Auth Chanel');
  }

}
