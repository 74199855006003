import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {MenuItem, MessageService} from 'primeng/api';
import {PerimetreEnum, Utilisateur} from '../../shared/_models';

import {AuthenticationService} from '../../shared/_services';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.css'],
  providers: [MessageService]
})
export class AdminHomeComponent implements OnInit {
  currentUtilisateur: Utilisateur;
  menuItems: MenuItem[];

  constructor(
    private router: Router,
    private messageService: MessageService,
    private authenticationService: AuthenticationService
  ) {
  }

  get isRouteAdminHome() {
    return this.router.url === '/in/admin';
  }

  get showMenu() {
    return this.currentUtilisateur !== undefined && this.currentUtilisateur.est_administrateur === 'O';
  }

  ngOnInit() {
    this.menuItems = [];
    this.authenticationService.currentUtilisateur$.subscribe(utilisateur => {
      if (utilisateur) {
        this.currentUtilisateur = this.authenticationService.currentUtilisateurValue;
      }
    });

    this.initMenu();
  }

  initMenu() {
    if (this.currentUtilisateur !== undefined) {

      if (this.currentUtilisateur.perimetre === 'National') {
        this.menuItems.push({
          label: 'Paramètres', routerLink: '/in/admin',
          style: {fontSize: '17px', fontWeight: '400', color: '#777'}
        });

        if (!this.currentUtilisateur.isPersonia) {
          this.menuItems.push({label: 'Fédérations', routerLink: '/in/admin/federation'});
          this.menuItems.push({label: 'Associations', routerLink: '/in/admin/association'});
          this.menuItems.push({label: 'Agences', routerLink: '/in/admin/agence'});
          this.menuItems.push({label: 'Paramètres application', routerLink: '/in/admin/parametre'});
          this.menuItems.push({label: 'Profils', routerLink: '/in/admin/profil'});
          this.menuItems.push({label: 'Groupes', routerLink: '/in/admin/groupe'});
          this.menuItems.push({label: 'Motifs', routerLink: '/in/admin/motifs'});
          this.menuItems.push({label: 'Marqueurs', routerLink: '/in/admin/marqueurs'});
        }

        this.menuItems.push({label: 'Utilisateurs', routerLink: '/in/admin/utilisateur'});

      } else {
        const title = `Gérer les utilisateurs de votre ${this.currentUtilisateur.perimetre === PerimetreEnum.Federation ? 'fédération' : 'association'}`;
        this.menuItems.push({label: title, routerLink: '/in/admin/utilisateur'});
        this.menuItems.push({label: 'Profils', routerLink: '/in/admin/profil'});
        this.menuItems.push({label: 'Groupes', routerLink: '/in/admin/groupe'});
        if (this.currentUtilisateur.perimetre === PerimetreEnum.Federation) {
          this.menuItems.push({label: 'Associations', routerLink: '/in/admin/association'});
          this.menuItems.push({label: 'Motifs', routerLink: '/in/admin/motifs'});
          this.menuItems.push({label: 'Marqueurs', routerLink: '/in/admin/marqueurs'});
        }
      }
    }
  }

}
