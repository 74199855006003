import {classToPlain} from 'class-transformer';
import {Association} from '../_models/association';
import {Federation} from '../_models/federation';

export class FiltreIndividu {
  nom: string;
  prenom: string;
  federation: Federation;
  association: Association;
  addrComplement1: string;
  addrComplement2: string;
  agent: string;
  code_postal: string;

  constructor() {
    this.init();
  }

  init() {
    this.nom = '';
    this.prenom = '';
    this.federation = null;
    this.association = null;
    this.addrComplement1 = '';
    this.addrComplement2 = '';
    this.agent = '';
    this.code_postal = null;
  }

  setValuesFromEchangeForm(values: any, type) {
    if (type === 'tiers') {
      this.nom = values.personne_tiers_nom;
      this.prenom = values.personne_tiers_prenom;
    } else if (type === 'tiers_concerne') {
      this.nom = values.personne_tiers_concerne_nom;
      this.prenom = values.personne_tiers_concerne_prenom;
    } else {
      this.nom = values.personne_sujet_nom;
      this.prenom = values.personne_sujet_prenom;
    }


    this.federation = values.federation;
    this.association = values.association;
    // this.addrComplement1 = values.personne_sujet_adr_complement1;
    // this.addrComplement2 = values.personne_sujet_adr_complement2;
    // this.agent = null;
    this.code_postal = values.personne_sujet_adr_code_postal;
  }

  setValuesFromEvaluationForm(values: any) {
    this.nom = values.client.nom;
    this.prenom = values.client.prenom;
    this.federation = values.federation;
    this.association = values.association;
  }

  serialize() {
    let serializeObject = classToPlain(this);

    if (this.federation !== null && this.federation !== undefined) {
      serializeObject['federation'] = serializeObject['federation'].id_philia;
    }

    if (this.association !== null && this.association !== undefined) {
      serializeObject['association'] = serializeObject['association'].id_philia;
    } else {
      serializeObject['association'] = 0;
    }

    // On ajoute un "%" à la fin du nom si pas présent
    if (this.nom !== null && this.nom !== undefined && this.nom.length > 0 && this.nom.charAt(this.nom.length - 1) !== '%') {
      serializeObject['nom'] += '%';
    } else if (this.nom === null || this.nom === undefined || this.nom === '') {
      serializeObject['nom'] = '%';
    }

    // On ajoute un "%" à la fin du prenom si pas présent
    if (this.prenom !== null && this.prenom !== undefined && this.prenom.length > 0 && this.prenom.charAt(this.prenom.length - 1) !== '%') {
      serializeObject['prenom'] += '%';
    } else if (this.prenom === null || this.prenom === undefined || this.prenom === '') {
      serializeObject['prenom'] = '%';
    }

    if (this.code_postal === undefined || this.code_postal === '') {
      delete serializeObject['code_postal'];
    }

    return serializeObject;
  }
}
