<!-- message -->
<p-toast [style]="{marginTop: '80px'}"></p-toast>

<!-- menu -->
<div *ngIf="currentUtilisateur && router.url !== '/in/select-association'; else loading" id="header-menu"
  class="p-grid p-justify-center">
  <div id="menu-div" class="p-col">
    <app-menu [appVersion]="appVersion"
              title="{{title}}"></app-menu>
  </div>
</div>

<!-- main app container -->
<div id="main-content" class="p-grid p-justify-center">
  <div id="router-outlet-container" [class]="router.url | routerOutletContainerClass">
    <router-outlet style="width:inherit;"></router-outlet>
  </div>
  <nsi-pied-de-page title="{{title}}" version="{{appVersion}}"></nsi-pied-de-page>
</div>

<ng-template #loading>
  <div *ngIf="router.url !== '/forbidden' && router.url !== '/in/select-association'">
    <app-loader></app-loader>
  </div>
</ng-template>
