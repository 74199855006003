import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {ActiviteExtraScolaireEnfant} from './activite-extra-scolaire-enfant';
import {Personne} from './personne';
import {StructureAccueilEnfant} from './structure-accueil-enfant';

export class SocialEnfant {

  id_social_enfant: number;

  @Type(() => Personne)
  enfant: Personne;

  informations_complementaires: string;

  consignes: string;

  @Type(() => ActiviteExtraScolaireEnfant)
  activites_extra_scolaire_enfant: ActiviteExtraScolaireEnfant[] = [];

  @Type(() => StructureAccueilEnfant)
  structures_accueil_enfant: StructureAccueilEnfant[] = [];

  static getForm(socialEnfant = null): FormGroup {
    if (!socialEnfant) {
      socialEnfant = new SocialEnfant();
    }

    let activitesExtraScolaireEnfantForm = [];
    socialEnfant.activites_extra_scolaire_enfant.forEach(function (activite_extra_scolaire_enfant) {
      activitesExtraScolaireEnfantForm.push(ActiviteExtraScolaireEnfant.getForm(activite_extra_scolaire_enfant));
    });

    let structuresAccueilEnfantForm = [];
    socialEnfant.structures_accueil_enfant.forEach(function (structure_accueil_enfant) {
      structuresAccueilEnfantForm.push(StructureAccueilEnfant.getForm(structure_accueil_enfant));
    });

    return new FormGroup({
      'activites_extra_scolaire_enfant': new FormArray(activitesExtraScolaireEnfantForm),
      'informations_complementaires': new FormControl(socialEnfant.informations_complementaires),
      'structures_accueil_enfant': new FormArray(structuresAccueilEnfantForm),
      'consignes': new FormControl(socialEnfant.consignes),
    });
  }

  serialize() {
    let serializeObject = classToPlain(this);

    serializeObject['activites_extra_scolaire_enfant'] = [];
    this.activites_extra_scolaire_enfant.forEach(function (activite_extra_scolaire_enfant) {
      serializeObject['activites_extra_scolaire_enfant'].push(activite_extra_scolaire_enfant.serialize());
    });

    serializeObject['structures_accueil_enfant'] = [];
    this.structures_accueil_enfant.forEach(function (structure_accueil_enfant) {
      serializeObject['structures_accueil_enfant'].push(structure_accueil_enfant.serialize());
    });

    return serializeObject;
  }
}
