import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { EchangeRoutingModule } from './echange-routing.module';

import { EchangeNewComponent } from './echange-new/echange-new.component';
import { EchangeEditComponent } from './echange-edit/echange-edit.component';
import { EchangeFormComponent } from './echange-form/echange-form.component';

@NgModule({
  declarations: [EchangeNewComponent, EchangeEditComponent, EchangeFormComponent],
  imports: [
    CommonModule,
    SharedModule,
    EchangeRoutingModule
  ]
})
export class EchangeModule { }
