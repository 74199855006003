import {classToPlain} from 'class-transformer';

import {Association, Federation, Parametre, Utilisateur, Groupe} from '../_models';

export class FiltreAlerte {
  federation: Federation;
  association: Association;
  created_debut: Date;
  created_fin: Date;
  qualification_debut: Date;
  qualification_fin: Date;
  echeance_debut: Date;
  echeance_fin: Date;
  statut_alerte: Parametre[];
  motif_alerte: Parametre[];
  type_alerte: Parametre[];
  qualificateur: Utilisateur[];
  objet_action: Parametre[];
  client_nom: string;
  client_prenom: string;
  client_id_philia: string;
  echeance_passed: boolean;
  groupes: Groupe[];
  risque_infectieux: boolean;
  offset: number;
  limit: number;
  sort_property: string;
  sort_direction: string;

  constructor() {
    this.init();
  }

  static getSortProperty(property: string) {
    if (property === 'created1') {
      return 'created';
    } else if (property === 'firstDateEcheance') {
      return 'echeance';
    }
    return property;
  }

  static getSortOrder(sortOrder: number) {
    return sortOrder === 1 ? 'ASC' : 'DESC';
  }

  init() {
      this.federation = null;
      this.association = null;
      this.created_debut = null;
      this.created_fin = null;
      this.qualification_debut = null;
      this.qualification_fin = null;
      this.echeance_debut = null;
      this.echeance_fin = null;
      this.statut_alerte = [];
      this.motif_alerte = null;
      this.type_alerte = null;
      this.qualificateur = null;
      this.objet_action = null;
      this.client_nom = '';
      this.client_prenom = '';
      this.client_id_philia = '';
      this.echeance_passed = null;
      this.groupes = null;
      this.risque_infectieux = null;
      this.offset = 0;
      this.limit = 20;
      this.sort_property = 'date_creation';
      this.sort_direction = 'DESC';
  }

  setDatas(datas) {
    if (datas.federation) {
      this.federation = datas.federation;
    }
    if (datas.association) {
      this.association = datas.association;
    }
    if (datas.created_debut) {
      this.created_debut = datas.created_debut;
    }
    if (datas.created_fin) {
      this.created_fin = datas.created_fin;
    }
    if (datas.qualification_debut) {
      this.qualification_debut = datas.qualification_debut;
    }
    if (datas.qualification_fin) {
      this.qualification_fin = datas.qualification_fin;
    }
    if (datas.echeance_debut) {
      this.echeance_debut = datas.echeance_debut;
    }
    if (datas.echeance_fin) {
      this.echeance_fin = datas.echeance_fin;
    }
    if (datas.statut_alerte) {
      this.statut_alerte = datas.statut_alerte;
    }
    if (datas.motif_alerte) {
      this.motif_alerte = datas.motif_alerte;
    }
    if (datas.type_alerte) {
      this.type_alerte = datas.type_alerte;
    }
    if (datas.qualificateur) {
      this.qualificateur = datas.qualificateur;
    }
    if (datas.objet_action) {
      this.objet_action = datas.objet_action;
    }
    if (datas.client_nom) {
      this.client_nom = datas.client_nom;
    }
    if (datas.client_prenom) {
      this.client_prenom = datas.client_prenom;
    }
    if (datas.echeance_passed) {
      this.echeance_passed = datas.echeance_passed;
    }
    if (datas.id_philia) {
      this.client_id_philia = datas.id_philia;
    }
    if (datas.groupes) {
      this.groupes = datas.groupes;
    }
    if (datas.risque_infectieux) {
      this.risque_infectieux = datas.risque_infectieux;
    }
  }

  serialize() {
    let serializeObject = classToPlain(this);

    // On enleve toutes les proprietes null ou undefined
    for (let propertyName in serializeObject) {
      if (!serializeObject[propertyName]) {
        delete serializeObject[propertyName];
      }
    }

    if (this.federation !== null && this.federation !== undefined) {
        serializeObject['federation'] = serializeObject['federation'].id_federation;
    }

    if (this.association !== null && this.association !== undefined) {
        serializeObject['association'] = serializeObject['association'].id_association;
    }

    if (this.created_debut !== null && this.created_debut !== undefined) {
        serializeObject['created_debut'] = Math.floor(serializeObject['created_debut'].getTime() / 1000);
    }

    if (this.created_fin !== null && this.created_fin !== undefined) {
        serializeObject['created_fin'] = Math.floor(serializeObject['created_fin'].getTime() / 1000);
    }

    if (this.qualification_debut !== null && this.qualification_debut !== undefined) {
      serializeObject['qualification_debut'] = Math.floor(serializeObject['qualification_debut'].getTime() / 1000);
    }

    if (this.qualification_fin !== null && this.qualification_fin !== undefined) {
        serializeObject['qualification_fin'] = Math.floor(serializeObject['qualification_fin'].getTime() / 1000);
    }

    if (this.echeance_debut !== null && this.echeance_debut !== undefined) {
        serializeObject['echeance_debut'] = Math.floor(serializeObject['echeance_debut'].getTime() / 1000);
    }

    if (this.echeance_fin !== null && this.echeance_fin !== undefined) {
        serializeObject['echeance_fin'] = Math.floor(serializeObject['echeance_fin'].getTime() / 1000);
    }

    if (this.statut_alerte !== null && this.statut_alerte !== undefined) {
        serializeObject['statut_alerte'] = [];
        this.statut_alerte.forEach(function(statut_al) {
          serializeObject['statut_alerte'].push(statut_al.id_parametre);
        });
        serializeObject['statut_alerte'] = serializeObject['statut_alerte'].join(',');
    }

    if (this.motif_alerte !== null && this.motif_alerte !== undefined) {
      serializeObject['motif_alerte'] = [];
      this.motif_alerte.forEach(function(motif) {
        serializeObject['motif_alerte'].push(motif.id_parametre);
      });
      serializeObject['motif_alerte'] = serializeObject['motif_alerte'].join(',');
    }

    if (this.type_alerte !== null && this.type_alerte !== undefined) {
      serializeObject['type_alerte'] = [];
      this.type_alerte.forEach(function(type) {
        serializeObject['type_alerte'].push(type.id_parametre);
      });
      serializeObject['type_alerte'] = serializeObject['type_alerte'].join(',');
    }

    if (this.qualificateur !== null && this.qualificateur !== undefined) {
      serializeObject['qualificateur'] = [];
      this.qualificateur.forEach(function(qua) {
        serializeObject['qualificateur'].push(qua.id_utilisateur);
      });
      serializeObject['qualificateur'] = serializeObject['qualificateur'].join(',');
    }

    if (this.objet_action !== null && this.objet_action !== undefined) {
      serializeObject['objet_action'] = [];
      this.objet_action.forEach(function(objet) {
        serializeObject['objet_action'].push(objet.libelle);
      });
      serializeObject['objet_action'] = serializeObject['objet_action'].join(',');
    }

    if (this.groupes !== null && this.groupes !== undefined) {
      serializeObject['groupes'] = [];
      this.groupes.forEach(function(grp) {
        serializeObject['groupes'].push(grp.id_groupe);
      });
      serializeObject['groupes'] = serializeObject['groupes'].join(',');
    }

    return serializeObject;
  }
}
