import {FormControl, FormGroup} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {Personne} from './personne';

export class AllaitementEnfant {

  id_allaitement_enfant: number;

  allaitement_detail_organisation: string;

  @Type(() => Personne)
  enfant: Personne;

  static getForm(allaitement_enfant = null): FormGroup {
    if (!allaitement_enfant) {
      allaitement_enfant = new AllaitementEnfant();
    }

    return new FormGroup({
      'id_allaitement_enfant': new FormControl(allaitement_enfant.id_allaitement_enfant),
      'allaitement_detail_organisation': new FormControl(allaitement_enfant.allaitement_detail_organisation),
      'enfant': new FormControl(allaitement_enfant.enfant)
    });
  }

  serialize() {
    return classToPlain(this);
  }
}
