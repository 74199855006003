import {FormControl, FormGroup, Validators} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {emailValidator} from '../_directives/email-validator.directive';
import {phoneValidator} from '../_directives/phone-validator.directive';

import {Globals} from '../utils/globals';
import {InfosEntourage} from './infos-entourage';
import {Parametre} from './parametre';
import {Utilisateur} from './utilisateur';


export class Contact {
  id_contact: number;

  @Type(() => Date)
  date_creation: Date;

  source_creation: string;

  @Type(() => Utilisateur)
  utilisateur_creation: Utilisateur;

  @Type(() => Date)
  date_maj: Date;

  source_maj: string;

  @Type(() => Utilisateur)
  utilisateur_maj: Utilisateur;

  @Type(() => Parametre)
  civilite: Parametre;

  nom: string;
  prenom: string;

  tel_fixe: string;
  tel_mobile: string;
  email: string;
  numero_rue: string;
  rue: string;
  ville: string;
  cp: string;
  cplt_adresse_1: string;
  cplt_adresse_2: string;
  cplt_adresse_3: string;
  pays: string;

  @Type(() => Parametre)
  type_complement_adresse: Parametre;

  commentaire: string;

  @Type(() => InfosEntourage)
  infos_entourage: InfosEntourage[] = [];


  get prenom_nom() {
    if (!this.prenom) {
      return this.nom;
    }

    return this.prenom + ' ' + this.nom;
  }

  get nom_prenom() {
    if (!this.prenom) {
      return this.nom;
    }

    return this.nom + ' ' + this.prenom;
  }

  get civilite_nom_prenom() {
    let civilite_nom_prenom = '';

    if (this.civilite) {
      civilite_nom_prenom = this.civilite.libelle;
    }

    if (civilite_nom_prenom !== '') {
      civilite_nom_prenom += ' ';
    }

    if (this.nom) {
      if (civilite_nom_prenom !== '') {
        civilite_nom_prenom += ' ';
      }
      civilite_nom_prenom += this.nom;
    }

    if (this.prenom) {
      if (civilite_nom_prenom !== '') {
        civilite_nom_prenom += ' ';
      }
      civilite_nom_prenom += this.prenom;
    }

    return civilite_nom_prenom;
  }

  get civilite_nom_prenom_mail() {
    let civilite_nom_prenom_mail = this.civilite_nom_prenom;

    if (this.email) {
      civilite_nom_prenom_mail += ' - ' + this.email;
    }

    return civilite_nom_prenom_mail;
  }

  get telephone() {
    let telephone = '';

    if (this.tel_fixe) {
      telephone = this.tel_fixe;
    }
    if (this.tel_fixe && this.tel_mobile) {
      telephone += ' / ';
    }
    if (this.tel_mobile) {
      telephone += this.tel_mobile;
    }

    return telephone;
  }

  get date() {
    let date = this.date_creation;

    if (this.date_maj) {
      date = this.date_maj;
    }
    return date;
  }

  static getForm(contact = null): FormGroup {
    if (!contact) {
      contact = new Contact();
    }

    let form = new FormGroup({
      'id_contact': new FormControl(contact.id_contact),
      'date_creation': new FormControl(contact.date_creation),
      'source_creation': new FormControl(contact.source_creation),
      'utilisateur_creation': new FormControl(contact.utilisateur_creation),
      'date_maj': new FormControl(contact.date_maj),
      'source_maj': new FormControl(contact.source_maj),
      'utilisateur_maj': new FormControl(contact.utilisateur_maj),
      'civilite': new FormControl(contact.civilite),
      'nom': new FormControl(contact.nom, Validators.required),
      'prenom': new FormControl(contact.prenom),
      'date_de_naissance': new FormControl(contact.date_de_naissance),
      'tel_fixe': new FormControl(contact.tel_fixe, phoneValidator()),
      'tel_mobile': new FormControl(contact.tel_mobile, phoneValidator()),
      'email': new FormControl(contact.email, emailValidator()),
      'numero_rue': new FormControl(contact.numero_rue),
      'rue': new FormControl(contact.rue),
      'ville': new FormControl(contact.ville),
      'cp': new FormControl(contact.cp),
      'cplt_adresse_1': new FormControl(contact.cplt_adresse_1),
      'cplt_adresse_2': new FormControl(contact.cplt_adresse_2),
      'cplt_adresse_3': new FormControl(contact.cplt_adresse_3),
      'pays': new FormControl(contact.pays),
      'type_complement_adresse': new FormControl(contact.type_complement_adresse),
      'commentaire': new FormControl(contact.commentaire),
    });

    return form;
  }

  getEntouragesByType(type_personne) {
    let entourages_personnel = [];

    this.infos_entourage.forEach((info_entourage) => {
      if (info_entourage.type_personne && info_entourage.type_personne.code_parametre === type_personne) {
        entourages_personnel.push(info_entourage);
      }
    });

    return entourages_personnel;
  }

  getEntouragesActuelsByType(type_personne) {
    let entourage_personnel = null;

    this.infos_entourage.forEach((info_entourage) => {
      if (info_entourage.type_personne && info_entourage.type_personne.code_parametre === type_personne && info_entourage.actuel) {
        entourage_personnel = info_entourage;
      }
    });

    return entourage_personnel;
  }

  getInfosEntourageActuel(id_philia) {
    let ie = null;
    this.infos_entourage.forEach((info_entourage) => {
      if (info_entourage.id_philia_client === id_philia && info_entourage.actuel) {
        ie = info_entourage;
      }
    });

    return ie;
  }

  initDatas(utilisateur = null) {
    this.date_creation = new Date();

    if (utilisateur) {
      this.utilisateur_creation = utilisateur;
    }

    this.source_creation = 'Tracéo';
  }

  serialize() {
    let serializeObject = classToPlain(this);
    // delete serializeObject['id_contact'];

    if (this.civilite !== null && this.civilite !== undefined) {
      serializeObject['civilite'] = this.civilite.id_parametre;
    }

    if (this.type_complement_adresse !== null && this.type_complement_adresse !== undefined) {
      serializeObject['type_complement_adresse'] = this.type_complement_adresse.id_parametre;
    }

    if (this.utilisateur_creation !== null && this.utilisateur_creation !== undefined) {
      serializeObject['utilisateur_creation'] = this.utilisateur_creation.id_utilisateur;
    }

    if (this.utilisateur_maj !== null && this.utilisateur_maj !== undefined) {
      serializeObject['utilisateur_maj'] = this.utilisateur_maj.id_utilisateur;
    }

    // Conversion des dates en FR
    for (let propertyName in serializeObject) { // On parcourt les proprietes de l'objet
      if (serializeObject[propertyName] && serializeObject[propertyName] instanceof Date) { // Si c'est une date non null
        serializeObject[propertyName] = Globals.getDateWithTimezone(serializeObject[propertyName]);
      }
    }

    return serializeObject;
  }

}
