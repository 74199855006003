import {FormControl, FormGroup, Validators} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {ActiviteExtraScolaireEnfant} from './activite-extra-scolaire-enfant';
import {LoisirsEtActivites} from './loisirs-et-activites';
import {Parametre} from './parametre';


export class Horaires {

  id_horaires: number;

  @Type(() => Parametre)
  jour: Parametre;

  plage_horaires: string;

  @Type(() => LoisirsEtActivites)
  loisirs_et_activites: LoisirsEtActivites;

  @Type(() => ActiviteExtraScolaireEnfant)
  activite_extra_scolaire_enfant: ActiviteExtraScolaireEnfant;

  get libelle() {
    return this.jour.libelle + ' ' + this.plage_horaires;
  }

  static getForm(horaires = null): FormGroup {
    if (!horaires) {
      horaires = new Horaires();
    }

    let form = new FormGroup({
      'jour': new FormControl(horaires.jour, Validators.required),
      'plage_horaires': new FormControl(horaires.plage_horaires, Validators.required),
    });

    return form;
  }

  serialize() {
    let serializeObject = classToPlain(this);

    if (this.jour !== null && this.jour !== undefined) {
      serializeObject['jour'] = this.jour.id_parametre;
    }

    return serializeObject;
  }
}
