import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import {plainToClass} from 'class-transformer';

import {SelectItem} from 'primeng/api';

import { Disponibilites } from '../_models';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class DisponibilitesService {

    constructor(private http: HttpClient) { }

  /** GET disponibilites */
  getAll(): Observable<Disponibilites[]> {
    return this.http.get<Disponibilites[]>(`${environment.apiHost}/${environment.apiNamespace}/disponibilites`).pipe(
      tap(_ => console.log(`fetched disponibilites`)),
      catchError(this.handleError)
    );
  }

  /** GET disponibilites */
  getDisponibilitesByParams(paramsValue: any): Observable<Disponibilites[]> {
    let params = new HttpParams();
    for (let paramKey in paramsValue) {
      if (paramsValue.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValue[paramKey]);
      }
    }
    return this.http.get<Disponibilites[]>(`${environment.apiHost}/${environment.apiNamespace}/disponibilites`, { params: params });
  }

   /** GET disponibilites/:id */
   getDisponibilite(id_disponibilites: number): Observable<Disponibilites> {
    return this.http.get<Disponibilites>(`${environment.apiHost}/${environment.apiNamespace}/disponibilites/${id_disponibilites}`);
  }

  /** POST disponibilites */
  createDisponibilites(disponibilites: Disponibilites): Observable<Disponibilites> {
    return this.http.post<any>(`${environment.apiHost}/${environment.apiNamespace}/disponibilites`,
    plainToClass(Disponibilites, disponibilites).serialize());
  }

  /** PUT disponibilites */
  updateDisponibilites(disponibilites: Disponibilites): Observable<Disponibilites> {
    let id_disponibilites = disponibilites.id_disponibilites;
    return this.http.put<any>(`${environment.apiHost}/${environment.apiNamespace}/disponibilites/${id_disponibilites}`,
    plainToClass(Disponibilites, disponibilites).serialize());
  }

  /** DELETE disponibilites */
  removeDisponibilites(id_disponibilites: number): Observable<Disponibilites> {
    return this.http.delete<any>(`${environment.apiHost}/${environment.apiNamespace}/disponibilites/${id_disponibilites}`);
  }

  /* handleError */
  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(error);
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a utilisateur-facing error message
    return throwError(
      error);
  }

}
