import {Inject, Injectable} from '@angular/core';
import {AUTH_MODE} from '../auth.token';
import {AuthConfModel} from '../models/auth-conf.model';
import {MethodAuthService} from './method-auth.service';

@Injectable({
  providedIn: 'root'
})
export class MethodRedirectService extends MethodAuthService {

  constructor(@Inject(AUTH_MODE) protected readonly authConf: AuthConfModel) {
    super();
  }

  protected close(): void {
  }

  protected listen(callback: (message: MessageEvent) => void): void {
  }

  protected open(): void {
    const url = window.location.href;
    // On ne surcharge l'URL de redirection que dans le cas où on passe l'URL d'un échange
    if(url.includes("/in/echange/")){
      sessionStorage.setItem('redirectUrl',url);
    }
    window.location.href = this.authConf.urlAuth;
  }
}
