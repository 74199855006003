import {FormControl, FormGroup} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {Personne} from './personne';


export class RepasEnfant {

  id_repas_enfant: number;

  repas_preparation = false;

  repas_petits_pots = false;

  repas_enfant_mixe = false;

  repas_puree_maison = false;

  repas_petit_morceaux = false;

  repas_detail: string;

  repas_degout: string;

  repas_horaire_petit_dej: string;

  repas_horaire_dej: string;

  repas_horaire_gouter: string;

  repas_horaire_diner: string;

  repas_usages: string;

  repas_aide_prise: string;

  @Type(() => Personne)
  enfant: Personne;

  static getForm(repas_enfant = null): FormGroup {
    if (!repas_enfant) {
      repas_enfant = new RepasEnfant();
    }

    return new FormGroup({
      'id_repas_enfant': new FormControl(repas_enfant.id_repas_enfant),
      'repas_preparation': new FormControl(repas_enfant.repas_preparation),
      'repas_petits_pots': new FormControl(repas_enfant.repas_petits_pots),
      'repas_enfant_mixe': new FormControl(repas_enfant.repas_enfant_mixe),
      'repas_puree_maison': new FormControl(repas_enfant.repas_puree_maison),
      'repas_petit_morceaux': new FormControl(repas_enfant.repas_petit_morceaux),
      'repas_detail': new FormControl(repas_enfant.repas_detail),
      'repas_degout': new FormControl(repas_enfant.repas_degout),
      'repas_horaire_petit_dej': new FormControl(repas_enfant.repas_horaire_petit_dej),
      'repas_horaire_dej': new FormControl(repas_enfant.repas_horaire_dej),
      'repas_horaire_gouter': new FormControl(repas_enfant.repas_horaire_gouter),
      'repas_horaire_diner': new FormControl(repas_enfant.repas_horaire_diner),
      'repas_usages': new FormControl(repas_enfant.repas_usages),
      'besoin_repas_aide_prise': new FormControl(repas_enfant.repas_aide_prise ? true : false),
      'repas_aide_prise': new FormControl(repas_enfant.repas_aide_prise),
      'enfant': new FormControl(repas_enfant.enfant)
    });
  }

  serialize() {
    return classToPlain(this);
  }

}
