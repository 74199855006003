import {AuthMethod} from '../app/shared/auth/enums/auth-method.enum';
import {JwtStockage} from '../app/shared/auth/enums/jwt-stockage.enum';

export const environment = {
  production: true,
  apiHost: 'https://maincourante-api.admr.org',
  apiHostMc: 'https://maincourante-api.admr.org',
  apiHostEv: 'https://evaluations-api.admr.org',
  apiHostVi: 'https://vigilance-api.admr.org',
  apiNamespace: 'api',
  appUrl: 'https://maincourante.admr.org',
  appUrlEv: 'https://evaluations.admr.org',
  appUrlVi: 'https://vigilance.admr.org',
  app: 'main_courante',
  keycloak: {
    storage: {storage: JwtStockage.SESSION_STORAGE, key: 'tokenMC'},
    allowedDomains: ['maincourante-api.admr.org'],
    urlAuth: `https://maincourante-api.admr.org/keycloak/connect`,
    urlRefresh: `https://maincourante-api.admr.org/keycloak/refresh_token`,
    disconnectUrl: 'https://connect.admr.org/',
    method: AuthMethod.Redirect
  }
};
