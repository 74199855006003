import { Component, OnInit } from '@angular/core';


import { FederationMotif } from 'src/app/shared/_models/federation-motif';
import { FederationMotifsService } from 'src/app/shared/_services/federation-motifs.service';
import { Utilisateur, Federation, Parametre } from '../../shared/_models';
import { AuthenticationService, FederationService, ParametreService } from '../../shared/_services';

@Component({
  selector: 'app-admin-motifs',
  templateUrl: './admin-motifs.component.html',
  styleUrls: ['./admin-motifs.component.css']
})
export class AdminMotifsComponent implements OnInit {
  // Variables
  activeIndexFilter: number;
  federationsList: Federation[];
  categoriesList: Parametre[];
  motifsList: Parametre[];
  federationsMotifsList: FederationMotif[];
  currentUtilisateur: Utilisateur;
  currentFederation: Federation;
  currentCategorie: Parametre;
  currentMotifs: Parametre[];
  loading: boolean;
  typeReclamation: number;

  constructor(
    private authenticationService: AuthenticationService,
    private federationService: FederationService,
    private federationMotifsService: FederationMotifsService,
    private parametreService: ParametreService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.activeIndexFilter = 0;
    this.currentUtilisateur = this.authenticationService.currentUtilisateurValue;
    this.getFederations();
    if (this.currentUtilisateur.perimetre === 'National') {
      this.getAllFederationsMotifs();
    } else {
      this.currentFederation = this.currentUtilisateur.federation;
      this.onChangeFederation();
    }
    this.getCategories();
  }

  getFederations() {
    if (this.currentUtilisateur.perimetre === 'National') { // Si perimetre National, on cherche toutes les federations
      this.federationService.getAll().subscribe(federations => {
        this.federationsList = federations;
      });
    } else {
      this.federationsList = [this.currentUtilisateur.federation];
    }
  }

  onChangeFederation() {
    this.loading = true;
    this.federationMotifsService.getFederationsMotifsByFederation(this.currentFederation.id_federation).subscribe(res => {
      this.federationsMotifsList = res;
    });
    this.loading = false;
  }

  async onChangeCategorie() {
    this.typeReclamation = null;
    await this.getMotifs();
    if (this.currentCategorie) {
      this.loading = true;
      this.federationMotifsService.getFederationsMotifsByFederationAndCategorie(this.currentFederation.id_federation, this.currentCategorie.id_parametre).subscribe(res => {
        this.currentMotifs = res.map(fm => {
          return fm.motif;
        });
        this.federationsMotifsList = res;
      });
      this.loading = false;
    }
  }

  async clickReclamationCategorie() {
    await this.getMotifs();
    this.federationMotifsService.getFederationMotifsByFederationCategorieAndReclamation(this.currentFederation.id_federation, this.currentCategorie.id_parametre, this.typeReclamation).subscribe(res => {
      this.currentMotifs = res.map(fm => {
        return fm.motif;
      });
      this.federationsMotifsList = res;
    });
  }

  getAllFederationsMotifs() {
    this.federationMotifsService.getAll().subscribe(res => {
      this.federationsMotifsList = res;
      this.loading = false;
    });
  }

  getCategories() {
    this.parametreService.getParametresByParams({ type: 'CATEGORIE' })
      .subscribe((parametres: Parametre[]) => {
        this.categoriesList = parametres;
        // On trie par ordre alphabétique
        this.categoriesList.sort(function (a, b) {
          if (a.libelle < b.libelle) { return -1; }
          if (a.libelle > b.libelle) { return 1; }
          return 0;
        });
      });
  }

  async getMotifs() {
    let type: string;
    switch (this.currentCategorie.code_parametre) {
      case 'DEV': {
        type = 'MOTIF_DEVIS';
        break;
      }
      case 'REMINF': {
        type = 'MOTIF_REMONTEE_INFORMATIONS';
        break;
      }
      case 'REC': {
        if (this.typeReclamation === 1) {
          type = 'MOTIF_RECLAMATION_ASSOCIATION';
        } else {
          type = 'MOTIF_RECLAMATION_INTERVENTION';
        }
        break;
      }
      default: {
        type = 'MOTIF';
        break;
      }
    }
    this.parametreService.getParametresByParams({ type: type })
      .subscribe((parametres: Parametre[]) => {
        this.motifsList = parametres;
        // On trie par ordre alphabétique
        this.motifsList.sort(function (a, b) {
          if (a.libelle < b.libelle) { return -1; }
          if (a.libelle > b.libelle) { return 1; }
          return 0;
        });
      });
  }

  initFilter() {
    if (this.currentUtilisateur.perimetre === 'National') {
      this.currentFederation = null;
    }
    this.currentCategorie = null;
    this.currentMotifs = null;
    this.typeReclamation = null;
    this.ngOnInit();
  }

  toggleShowFilter() {
    if (this.activeIndexFilter === 0) {
      this.activeIndexFilter++;
    } else {
      this.activeIndexFilter--;
    }
  }

  saveFederationMotifs() {
    let dataToPost = {
      federationId: this.currentFederation.id_federation,
      categorieMotifId: this.currentCategorie.id_parametre,
      motifIds: this.currentMotifs.map(motif => {
        return motif.id_parametre;
      }),
      typeReclamation: this.typeReclamation
    };
    this.federationMotifsService.createFederationMotif(dataToPost).subscribe(res => {
      if (this.currentCategorie.code_parametre !== 'REC') {
        this.typeReclamation = null;
        this.onChangeCategorie();
      } else {
        this.clickReclamationCategorie();
      }
    });
  }
}
