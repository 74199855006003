import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {plainToClass} from 'class-transformer';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {environment} from '../../../environments/environment';

import {Association} from '../_models';
import {FiltreAssociation} from '../utils/filtre-association';

@Injectable({providedIn: 'root'})
export class AssociationService {

  filtreAssociation = new FiltreAssociation();

  constructor(private http: HttpClient) {
  }

  /** GET associations */
  getAll(restricted_datas = false): Observable<Association[]> {
    if (restricted_datas) {
      let params = new HttpParams();
      params = params.append('restricted_datas', 'true');
      return this.http.get<Association[]>(`${environment.apiHost}/${environment.apiNamespace}/associations`, {params: params});
    } else {
      return this.http.get<Association[]>(`${environment.apiHost}/${environment.apiNamespace}/associations`);
    }
  }

  /** GET parametres */
  getAssociationsByParams(paramsValue: any): Observable<Association[]> {
    let params = new HttpParams();
    for (let paramKey in paramsValue) {
      if (paramsValue.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValue[paramKey]);
      }
    }
    return this.http.get<Association[]>(`${environment.apiHost}/${environment.apiNamespace}/associations`, {params: params});
  }

  /** GET associations/count */
  getNbAssociationsByParams(paramsValue: FiltreAssociation): Observable<any> {
    let paramsValueSerialized = paramsValue.serialize();
    let params = new HttpParams();
    for (let paramKey in paramsValueSerialized) {
      if (paramsValueSerialized.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValueSerialized[paramKey]);
      }
    }
    return this.http.get<any>(`${environment.apiHost}/${environment.apiNamespace}/associations/count`, {params: params});
  }

  /** GET associations/:id */
  getAssociation(id_association: number): Observable<Association> {
    return this.http.get<Association>(`${environment.apiHost}/${environment.apiNamespace}/associations/${id_association}`);
  }

  /** POST associations */
  createAssociation(association: Association): Observable<Association> {
    return this.http.post<any>(
      `${environment.apiHost}/${environment.apiNamespace}/associations`,
      plainToClass(Association, association).serialize()
    );
  }

  /** PUT associations */
  updateAssociation(association: Association): Observable<Association> {
    return this.http.put<any>(
      `${environment.apiHost}/${environment.apiNamespace}/associations/${association.id_association}`,
      plainToClass(Association, association).serialize()
    );
  }

  /** DELETE associations */
  removeAssociation(id_association: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiHost}/${environment.apiNamespace}/associations/${id_association}`,
      {observe: 'response'}).pipe(
      catchError(this.handleError)
    );
  }

  /** Get Associations d'une federation Modus */
  getAssociationsModus(id_modus_federation: string, existsMainCourante?: boolean): Observable<Association[]> {
    const params = existsMainCourante !== undefined
      ? new HttpParams().append('exists_main_courante', `${existsMainCourante}`)
      : new HttpParams();

    return this.http.get<Association[]>(`${environment.apiHost}/${environment.apiNamespace}/modus/federations/${id_modus_federation}/associations`, {params});
  }

  /** Get Associations d'une federation Modus */
  getAssociationModus(idModusFederation: string, idModusAssociation?: string): Observable<Association> {
    return this.http.get<Association>(`${environment.apiHost}/${environment.apiNamespace}/modus/federations/${idModusFederation}/associations/${idModusAssociation}`);
  }

  /** Get all Associations Modus */
  getAllAssociationsModus() {
    return this.http.get<Association[]>(`${environment.apiHost}/${environment.apiNamespace}/ext/associations`);
  }

  initFiltreAssociation() {
    this.filtreAssociation = new FiltreAssociation();
  }

  // /* Récupère une association Modus d'une federation */
  // getAssociationModus(id_association, id_federation){
  //   return this.http.get<Association[]>(`${environment.apiHost}/${environment.apiNamespace}/ext/${id_federation}/association/${id_association}`)
  // }

  // /* Récupère les horaires d'une asso à partir de l'id */
  // getAssociationHoraireModus(id_association){
  //   return this.http.get<Association[]>(`${environment.apiHost}/${environment.apiNamespace}/ext/association/${id_association}`)
  // }

  /* Met à jour les associations Modus */
  getMajAssociationsModus() {
    return this.http.put<any>(`${environment.apiHost}/${environment.apiNamespace}/modus/associations/update`, null);
  }

  /* handleError */
  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(error);
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a utilisateur-facing error message
    return throwError(
      error);
  }

}
