import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import {plainToClass} from 'class-transformer';

import { Individu } from '../_models';
import { FiltreIndividu } from '../utils/filtre-individu';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class IndividuService {

  filtreIndividu = new FiltreIndividu();
  selectedIndividu: Individu;

  constructor(private http: HttpClient) { }

  /** GET individus */
  getAll(): Observable<Individu[]> {
    return this.http.get<Individu[]>(`${environment.apiHost}/${environment.apiNamespace}/individus`).pipe(
      tap(_ => console.log(`fetched individus`)),
      catchError(this.handleError)
    );
  }

  /** GET individus */
  getIndividusByParams(paramsValue: FiltreIndividu, updateFiltre = true): Observable<Individu[]> {
    if (updateFiltre) {
      this.filtreIndividu = paramsValue;
    }

    let paramsValueSerialized = paramsValue.serialize();
    let params = new HttpParams();
    for (let paramKey in paramsValueSerialized) {
      if (paramsValueSerialized.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValueSerialized[paramKey]);
      }
    }
    return this.http.get<Individu[]>(`${environment.apiHost}/${environment.apiNamespace}/search/individu`, { params: params });
  }

   /** GET individus/:id */
   getIndividu(id_philia: string): Observable<Individu> {
    return this.http.get<Individu>(`${environment.apiHost}/${environment.apiNamespace}/individus/${id_philia}`);
  }

  /** POST individus */
  createIndividu(individu: Individu): Observable<any> {
    return this.http.post<any>(`${environment.apiHost}/${environment.apiNamespace}/individus`,
    plainToClass(Individu, individu).serialize());
  }

  /** PUT individus */
  updateIndividu(individu: Individu): Observable<Individu> {
    let id_philia = individu.id_philia;
    delete individu.id_philia;
    return this.http.put<any>(`${environment.apiHost}/${environment.apiNamespace}/individus/${id_philia}`,
    plainToClass(Individu, individu).serialize());
  }

  /** DELETE individus */
  removeIndividu(id_philia: number): Observable<Individu> {
    return this.http.delete<any>(`${environment.apiHost}/${environment.apiNamespace}/individus/${id_philia}`);
  }

  /* handleError */
  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(error);
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a utilisateur-facing error message
    return throwError(
      error);
  }

}
