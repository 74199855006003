import { classToPlain } from 'class-transformer';
import { Echange } from './echange';
import { FederationMarqueur } from './federation-marqueur';

export class EchangeMarqueur {
  id: number;
  echange: Echange;
  federationMarqueur: FederationMarqueur;

  serialize() {
    let serializeObject = classToPlain(this);
    console.warn(serializeObject);
    delete serializeObject['id'];
    if (this.echange !== null && this.echange !== undefined) {
      serializeObject['echange'] = this.echange.id_echange;
    }
    if (this.federationMarqueur !== null && this.federationMarqueur !== undefined) {
      serializeObject['federationMarqueur'] = this.federationMarqueur.id;
    }

    return serializeObject;
  }

}
