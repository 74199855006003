import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {Horaires} from './horaires';
import {Personne} from './personne';
import {SocialEnfant} from './social-enfant';


export class ActiviteExtraScolaireEnfant {

  id_activite_extra_scolaire_enfant: number;

  libelle: string;

  lieu: string;

  @Type(() => Personne)
  referent: Personne;

  @Type(() => SocialEnfant)
  social_enfant: SocialEnfant;

  @Type(() => Horaires)
  horaires: Horaires[] = [];

  static getForm(activiteExtraScolaireEnfant = null): FormGroup {
    if (!activiteExtraScolaireEnfant) {
      activiteExtraScolaireEnfant = new ActiviteExtraScolaireEnfant();
    }

    let horairesForm = [];
    activiteExtraScolaireEnfant.horaires.forEach(function (horaire) {
      horairesForm.push(Horaires.getForm(horaire));
    });

    return new FormGroup({
      'libelle': new FormControl(activiteExtraScolaireEnfant.libelle, Validators.required),
      'lieu': new FormControl(activiteExtraScolaireEnfant.lieu),
      'referent': new FormControl(activiteExtraScolaireEnfant.referent),
      'horaires': new FormArray(horairesForm),
    });
  }

  serialize() {
    let serializeObject = classToPlain(this);

    if (this.referent !== null && this.referent !== undefined) {
      serializeObject['referent'] = this.referent.serialize();
    }

    serializeObject['horaires'] = [];
    this.horaires.forEach(function (horaire) {
      serializeObject['horaires'].push(horaire.serialize());
    });

    return serializeObject;
  }
}
