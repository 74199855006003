import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { FederationMarqueur } from '../_models/federation-marqueur';

@Injectable({ providedIn: 'root' })
export class FederationMarqueursService {

  private http = inject(HttpClient); // Utilisation de inject pour HttpClient

  constructor() { }

  /** GET federationsmarqueurs/all */
  getAll(): Observable<FederationMarqueur[]> {
    return this.http.get<FederationMarqueur[]>(`${environment.apiHost}/${environment.apiNamespace}/federationsmarqueurs/all`);
  }

  /** GET federationsmarqueurs/:id_federation */
  getFederationsMarqueursByFederation(id_federation: number): Observable<FederationMarqueur[]> {
    return this.http.get<FederationMarqueur[]>(`${environment.apiHost}/${environment.apiNamespace}/federationsmarqueurs/${id_federation}`);
  }

  /** GET federationsmarqueurs */
  getFederationsMarqueursByFederations(id_federations: number[]): Observable<FederationMarqueur[]> {
    const params = new HttpParams().set('id_federations', id_federations.join(','));
    return this.http.get<FederationMarqueur[]>(`${environment.apiHost}/${environment.apiNamespace}/federations_array_marqueurs`, { params });
  }

  /** POST federationsmarqueurs */
  saveFederationMarqueur(federationMarqueurData: any): Observable<any> {
    return this.http.post<any>(`${environment.apiHost}/${environment.apiNamespace}/federationsmarqueurs`, federationMarqueurData)
      .pipe(
        catchError(error => {
          let errorMessage = 'An unknown error occurred';
          if (error.status === 500) {
            errorMessage = 'Internal Server Error (500)';
            console.error(errorMessage, error.error.message);
          } else if (error.error && error.error.message) {
            errorMessage = error.error.message;
            console.error('Error:', errorMessage);
          } else {
            console.error('Error:', errorMessage);
          }
          throw new Error(error.error.message);
        })
      );
  }

}
