import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {AccompagnementParentalite} from './accompagnement-parentalite';
import {Autonomie} from './autonomie';
import {Document} from './document';
import {Habitude} from './habitude';
import {InterventionBesoin} from './intervention-besoin';
import {InterventionPlanification} from './intervention-planification';
import {Logement} from './logement';
import {Parametre} from './parametre';
import {Personne} from './personne';
import {Risque} from './risque';

export class EvaluationContenu {

  id_evaluation_contenu: number;

  realise = false;

  en_attente = false;

  @Type(() => Date)
  date_origine: Date;

  commentaire_origine: string;

  motif_commentaire: string;

  item_menu_evaluation: string;

  item_menu_intervention: string;

  item_bloc_alimentation: string;

  info_document: string;

  info_mail: string;

  facturation_mail: string;

  @Type(() => Personne)
  entourage_facturation: Personne;

  @Type(() => Logement)
  logement: Logement;

  @Type(() => Habitude)
  habitude: Habitude;

  @Type(() => Personne)
  entourages: Personne[] = [];

  @Type(() => Autonomie)
  autonomies: Autonomie[] = [];

  @Type(() => AccompagnementParentalite)
  accompagnement_parentalite: AccompagnementParentalite;

  @Type(() => InterventionBesoin)
  interventions_besoin: InterventionBesoin[] = [];

  @Type(() => InterventionPlanification)
  intervention_planification: InterventionPlanification;

  @Type(() => Risque)
  risques: Risque[] = [];

  @Type(() => Document)
  documents: Document[] = [];

  grossesse: boolean;

  naissance: boolean;

  famille_nombreuse: boolean;

  famille_recomposee: boolean;

  deces_enfant: boolean;

  rupture_famille: boolean;

  soins_court: boolean;

  soins_long: boolean;

  decision_justice: boolean;

  @Type(() => Parametre)
  mode_paiement: Parametre;

  commentaire_paiement: string;

  pourcentage_charge: string;

  gir: string;

  bic: string;

  iban: string;

  naissance_multiple: boolean;
Ø
  grossesse_pathologique: boolean;

  @Type(() => Personne)
  entourage_mail_facturation: Personne;

  @Type(() => Personne)
  entourage_notification_planification: Personne;

  @Type(() => Personne)
  entourage_adresse_facturation: Personne;


  static getForm(evaluation_contenu = null): FormGroup {
    if (!evaluation_contenu) {
      evaluation_contenu = new EvaluationContenu();
    }

    let entouragesForm = [];
    evaluation_contenu.entourages.forEach(function (entourage) {
      entouragesForm.push(Personne.getForm(entourage));
    });

    let autonomiesForm = [];
    evaluation_contenu.autonomies.forEach(function (autonomie) {
      autonomiesForm.push(Autonomie.getForm(autonomie));
    });

    let interventionsBesoinForm = [];
    evaluation_contenu.interventions_besoin.forEach(function (intervention_besoin) {
      interventionsBesoinForm.push(InterventionBesoin.getForm(intervention_besoin));
    });

    let risquesForm = [];
    evaluation_contenu.risques.forEach(function (risque) {
      risquesForm.push(Risque.getForm(risque));
    });

    let documentsForm = [];
    evaluation_contenu.documents.forEach(function (document) {
      documentsForm.push(Document.getForm(document));
    });

    return new FormGroup({
      'id_evaluation_contenu': new FormControl(evaluation_contenu.id_evaluation_contenu),
      'realise': new FormControl(evaluation_contenu.realise),
      'en_attente': new FormControl(evaluation_contenu.en_attente),
      'date_origine': new FormControl(evaluation_contenu.echeance),
      'commentaire_origine': new FormControl(evaluation_contenu.commentaire_origine),
      'motif_commentaire': new FormControl(evaluation_contenu.motif_commentaire),
      'item_menu_evaluation': new FormControl(evaluation_contenu.item_menu_evaluation),
      'item_menu_intervention': new FormControl(evaluation_contenu.item_menu_intervention),
      'item_bloc_alimentation': new FormControl(evaluation_contenu.item_bloc_alimentation),
      'info_document': new FormControl(evaluation_contenu.info_document),
      'info_mail': new FormControl(evaluation_contenu.info_mail),
      'facturation_mail': new FormControl(evaluation_contenu.facturation_mail),
      'logement': Logement.getForm(evaluation_contenu.logement),
      'habitude': Habitude.getForm(evaluation_contenu.habitude),
      'entourages': new FormArray(entouragesForm),
      'autonomies': new FormArray(autonomiesForm),
      'accompagnement_parentalite': AccompagnementParentalite.getForm(evaluation_contenu.accompagnement_parentalite),
      'interventions_besoin': new FormArray(interventionsBesoinForm),
      'intervention_planification': InterventionPlanification.getForm(evaluation_contenu.intervention_planification),
      'risques': new FormArray(risquesForm),
      'documents': new FormArray(documentsForm),
      'mode_paiement': new FormControl(evaluation_contenu.mode_paiement),
      'commentaire_paiement': new FormControl(evaluation_contenu.commentaire_paiement),
      'pourcentage_charge': new FormControl(evaluation_contenu.pourcentage_charge),
      'gir': new FormControl(evaluation_contenu.gir),
      'bic': new FormControl(evaluation_contenu.bic),
      'iban': new FormControl(evaluation_contenu.iban),
      'naissance_multiple': new FormControl(evaluation_contenu.naissance_multiple),
      'grossesse': new FormControl(evaluation_contenu.grossesse),
      'naissance': new FormControl(evaluation_contenu.naissance),
      'famille_nombreuse': new FormControl(evaluation_contenu.famille_nombreuse),
      'famille_recomposee': new FormControl(evaluation_contenu.famille_recomposee),
      'deces_enfant': new FormControl(evaluation_contenu.deces_enfant),
      'rupture_famille': new FormControl(evaluation_contenu.rupture_famille),
      'soins_court': new FormControl(evaluation_contenu.soins_court),
      'soins_long': new FormControl(evaluation_contenu.soins_long),
      'decision_justice': new FormControl(evaluation_contenu.decision_justice),
      'grossesse_pathologique': new FormControl(evaluation_contenu.grossesse_pathologique),
      'notification_mail_facturation': new FormControl(evaluation_contenu.entourage_mail_facturation ? true : false),
      'entourage_mail_facturation': new FormControl(evaluation_contenu.entourage_mail_facturation),
      'notification_planification': new FormControl(evaluation_contenu.entourage_notification_planification ? true : false),
      'entourage_notification_planification': new FormControl(evaluation_contenu.entourage_notification_planification),
      'adresse_facturation_beneficiaire': new FormControl(evaluation_contenu.entourage_adresse_facturation ? false : true),
      'entourage_adresse_facturation': new FormControl(evaluation_contenu.entourage_adresse_facturation),
    });
  }

  getInterventionsBesoinEquipements() {
    let interventionsBesoinEquipements = [];
    for (let index = 0; index < this.interventions_besoin.length; index++) {
      let intervention_besoin = this.interventions_besoin[index];
      if (intervention_besoin.groupes_equipements.length > 0) {
        let intervention_besoin_libelle = intervention_besoin.typeBesoinEquipementLibelle;

        let alreadyPresent = false;
        for (let indexIbe = 0; indexIbe < interventionsBesoinEquipements.length; indexIbe++) {
          if (interventionsBesoinEquipements[indexIbe].intervention_besoin_libelle === intervention_besoin_libelle) {
            alreadyPresent = true;
            let groupesEquipements = interventionsBesoinEquipements[indexIbe].groupes_equipements;
            groupesEquipements = groupesEquipements.concat(intervention_besoin.groupes_equipements);
            interventionsBesoinEquipements[indexIbe] = {
              intervention_besoin_libelle: intervention_besoin_libelle,
              groupes_equipements: groupesEquipements,
              type_besoin: intervention_besoin.type_besoin
            };
          }
        }

        if (!alreadyPresent) {
          interventionsBesoinEquipements.push({
            intervention_besoin_libelle: intervention_besoin_libelle,
            groupes_equipements: intervention_besoin.groupes_equipements,
            type_besoin: intervention_besoin.type_besoin
          });
        }
      }
    }

    return interventionsBesoinEquipements;
  }

  hasEquipement(existe: boolean, type_besoin_libelle: string) {
    let interventionsBesoinEquipements = this.getInterventionsBesoinEquipements();

    for (let indexIbe = 0; indexIbe < interventionsBesoinEquipements.length; indexIbe++) {
      if (interventionsBesoinEquipements[indexIbe].intervention_besoin_libelle === type_besoin_libelle) {
        for (let indexGe = 0; indexGe < interventionsBesoinEquipements[indexIbe].groupes_equipements.length; indexGe++) {
          if (interventionsBesoinEquipements[indexIbe].groupes_equipements[indexGe].existe === existe) {
            return true;
          }
        }
      }
    }
    return false;
  }

  isPlanificationEmpty() {
    if (!this.intervention_planification
      || (!this.intervention_planification.date_premiere && !this.intervention_planification.date_fin
        && !this.intervention_planification.consigne_intervention && this.intervention_planification.planifications_nb_heures.length === 0
        && !this.entourage_notification_planification)) {
      return true;
    } else {
      return false;
    }
  }

  serialize() {
    let serializeObject = classToPlain(this);
    delete serializeObject['id_evaluation_contenu'];

    if (this.mode_paiement !== null && this.mode_paiement !== undefined) {
      serializeObject['mode_paiement'] = this.mode_paiement.id_parametre;
    }

    serializeObject['autonomies'] = [];
    this.autonomies.forEach(function (autonomie) {
      serializeObject['autonomies'].push(autonomie.serialize());
    });

    serializeObject['entourages'] = [];
    this.entourages.forEach(function (entourage) {
      serializeObject['entourages'].push(entourage.serialize());
    });

    serializeObject['interventions_besoin'] = [];
    this.interventions_besoin.forEach(function (intervention_besoin) {
      serializeObject['interventions_besoin'].push(intervention_besoin.serialize());
    });

    if (this.habitude !== null && this.habitude !== undefined) {
      serializeObject['habitude'] = this.habitude.serialize();
    }

    if (this.accompagnement_parentalite !== null && this.accompagnement_parentalite !== undefined) {
      serializeObject['accompagnement_parentalite'] = this.accompagnement_parentalite.serialize();
    }

    if (this.entourage_mail_facturation !== null && this.entourage_mail_facturation !== undefined) {
      serializeObject['entourage_mail_facturation'] = this.entourage_mail_facturation.serialize();
    }

    if (this.entourage_notification_planification !== null && this.entourage_notification_planification !== undefined) {
      serializeObject['entourage_notification_planification'] = this.entourage_notification_planification.serialize();
    }

    if (this.entourage_adresse_facturation !== null && this.entourage_adresse_facturation !== undefined) {
      serializeObject['entourage_adresse_facturation'] = this.entourage_adresse_facturation.serialize();
    }

    if (this.intervention_planification !== null && this.intervention_planification !== undefined) {
      serializeObject['intervention_planification'] = this.intervention_planification.serialize();
    }

    serializeObject['risques'] = [];
    this.risques.forEach(function (risque) {
      serializeObject['risques'].push(risque.serialize());
    });

    serializeObject['documents'] = [];
    this.documents.forEach(function (document) {
      serializeObject['documents'].push(document.serialize());
    });

    return serializeObject;
  }
}
