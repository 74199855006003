import {isTokenWrapperForbidden, isTokenWrapperSuccess, TokenWrapper, TokenWrapperSuccess} from '../models/token.model';

export abstract class MethodAuthService {
  start(
    success: (token: TokenWrapperSuccess) => void,
    forbidden: () => void): void {
    this.open();
    this.listen((message: MessageEvent) => {
      const tokenWrapper: TokenWrapper = message.data;
      if (isTokenWrapperSuccess(tokenWrapper)) {
        success({refreshToken: tokenWrapper.refreshToken, accessToken: tokenWrapper.accessToken});
        this.close();
      } else if (isTokenWrapperForbidden(tokenWrapper)) {
        forbidden();
        this.close();
      } else {
        console.error(message);
      }
    });
  }

  protected abstract close(): void;

  protected abstract listen(callback: (message: MessageEvent) => void): void;

  protected abstract open(): void;
}
