import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {plainToClass} from 'class-transformer';
import {Observable, throwError} from 'rxjs';

import {environment} from '../../../environments/environment';

import {Federation} from '../_models';
import {FiltreFederation} from '../utils/filtre-federation';

@Injectable({providedIn: 'root'})
export class FederationService {

  filtreFederation = new FiltreFederation();

  constructor(
    private http: HttpClient
  ) {
  }

  /** GET federations */
  getAll(restricted_datas = false): Observable<Federation[]> {
    if (restricted_datas) {
      let params = new HttpParams();
      params = params.append('restricted_datas', 'true');
      return this.http.get<Federation[]>(`${environment.apiHost}/${environment.apiNamespace}/federations`, {params: params});
    } else {
      return this.http.get<Federation[]>(`${environment.apiHost}/${environment.apiNamespace}/federations`);
    }
  }

  /** GET federations */
  getFederationsByParams(paramsValue: any): Observable<Federation[]> {
    let params = new HttpParams();
    for (let paramKey in paramsValue) {
      if (paramsValue.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValue[paramKey]);
      }
    }
    return this.http.get<Federation[]>(`${environment.apiHost}/${environment.apiNamespace}/federations`, {params: params});
  }

  /** GET federations/count */
  getNbFederationsByParams(paramsValue: FiltreFederation): Observable<any> {
    let paramsValueSerialized = paramsValue.serialize();
    let params = new HttpParams();
    for (let paramKey in paramsValueSerialized) {
      if (paramsValueSerialized.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValueSerialized[paramKey]);
      }
    }
    return this.http.get<any>(`${environment.apiHost}/${environment.apiNamespace}/federations/count`, {params: params});
  }

  /** GET federations/:id */
  getFederation(id_federation: number): Observable<Federation> {
    return this.http.get<Federation>(`${environment.apiHost}/${environment.apiNamespace}/federations/${id_federation}`);
  }

  /** GET federation Modus /:id */
  getFederationModusById(id_modus_federation: number): Observable<Federation> {
    return this.http.get<Federation>(`${environment.apiHost}/${environment.apiNamespace}/modus/federations/${id_modus_federation}`);
  }

  /** POST federations */
  createFederation(federation: Federation): Observable<Federation> {
    return this.http.post<any>(`${environment.apiHost}/${environment.apiNamespace}/federations`,
      plainToClass(Federation, federation).serialize());
  }

  /** PUT federations */
  updateFederation(federation: Federation): Observable<Federation> {
    return this.http.put<any>(
      `${environment.apiHost}/${environment.apiNamespace}/federations/${federation.id_federation}`,
      plainToClass(Federation, federation).serialize());
  }

  initFiltreFederation() {
    this.filtreFederation = new FiltreFederation();
  }

  /** Get Federations Modus */
  getFederationsModus() {
    return this.http.get<Federation[]>(`${environment.apiHost}/${environment.apiNamespace}/modus/federations`);
  }

  //  /** POST modus federation */
  //  postModusAll(federation: any): Observable<any> {
  //   return this.http.post<any>(`${environment.modusUrl}`, federation);
  // }


  /* handleError */
  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(error);
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a utilisateur-facing error message
    return throwError(
      error);
  }

}
