import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl } from '@angular/forms';

export function phoneValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    let phoneRegEx = /^(0|(00|\+)33)[1-9]{1}[ ]?[0-9]{2}[ ]?[0-9]{2}[ ]?[0-9]{2}[ ]?[0-9]{2}$/i;
    let valid = phoneRegEx.test(control.value);
    return valid || !control.value || control.value === ''
          || control.value === '__ __ __ __ __' ? null : {'isPhone': true};
  };
}

@Directive({
  selector: '[appPhoneValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: PhoneValidatorDirective, multi: true}]
})
export class PhoneValidatorDirective implements Validator {

  constructor() { }

  public validate(control: AbstractControl): {[key: string]: any} {
    return phoneValidator()(control);
  }

}
