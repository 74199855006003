import {FormControl, FormGroup, Validators} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {Globals} from '../utils/globals';
import {EvaluationContenu} from './evaluation-contenu';
import {Parametre} from './parametre';

export class Document {

  id_document: number;

  @Type(() => Parametre)
  type: Parametre;

  precisions: string;

  duree: number;

  nom: string;

  path: string;

  @Type(() => Date)
  date: Date;

  @Type(() => EvaluationContenu)
  evaluation_contenu: EvaluationContenu;

  file: any;

  static getForm(document = null): FormGroup {
    if (!document) {
      document = new Document();
    }

    return new FormGroup({
      'id_document': new FormControl(document.id_document),
      'type': new FormControl(document.type, Validators.required),
      'precisions': new FormControl(document.precisions),
      'duree': new FormControl(document.duree),
      'nom': new FormControl(document.nom),
      'path': new FormControl(document.path),
      'date': new FormControl(document.date),
      'file': new FormControl(null)
    });
  }

  serialize() {
    let serializeObject = classToPlain(this);

    if (this.type !== null && this.type !== undefined) {
      serializeObject['type'] = this.type.id_parametre;
    }

    // Conversion des dates en FR
    for (let propertyName in serializeObject) { // On parcourt les proprietes de l'objet
      if (serializeObject[propertyName] && serializeObject[propertyName] instanceof Date) { // Si c'est une date non null
        serializeObject[propertyName] = Globals.getDateWithTimezone(serializeObject[propertyName]);
      }
    }

    return serializeObject;
  }
}
