import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../shared/_guards';
import {RechercheActionComponent} from './recherche-action/recherche-action.component';

const rechercheActionRoutes: Routes = [
  {
    path: 'in/recherche-action',
    canActivate: [AuthGuard],
    component: RechercheActionComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(rechercheActionRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class RechercheActionRoutingModule {
}
