import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

import {plainToClass} from 'class-transformer';

import {Association} from '../../shared/_models';
import {AssociationService} from '../../shared/_services';

@Component({
  selector: 'app-recherche-telephone-association',
  templateUrl: './recherche-telephone-association.component.html',
  styleUrls: ['./recherche-telephone-association.component.css']
})
export class RechercheTelephoneAssociationComponent implements OnInit {

  @Output()
  searchTelephoneAssociation = new EventEmitter<any>();

  @Input()
  associationFound: Association;

  telephoneAssociation: string;
  showMessage: boolean;

  constructor(private associationService: AssociationService) { }

  ngOnInit() {
    this.showMessage = false;
  }

  onSubmit() {
    this.showMessage = false;
    this.associationFound = null;
    // On recherche l'association
    this.associationService.getAssociationsByParams({telephone: this.telephoneAssociation, show_all_infos: 'true'})
        .subscribe((associations: Association[]) => {
          if (associations.length > 0) {
            this.associationFound = plainToClass(Association, associations[0]);
            this.searchTelephoneAssociation.emit(plainToClass(Association, associations));
          } else {
            this.showMessage = true;
          }
      });
  }

}
