<p-card id="select-association-card" [style]="{width: '450px', margin: 'auto', padding: '30px' }" styleClass="ui-card-shadow">
  <form (ngSubmit)="selectAssociation()" novalidate>
    <p-header>
      <div style="text-align: center;">
          <img src="assets/img/admr_logo.png" alt="admr_logo" width="200" height="100">
          <h2 class="text-center" style="margin:0px 0px 30px 0px">{{appTitle}}</h2>
          <p style="text-align: center;color: #5E5E5E;margin-bottom:20px">Veuillez choisir l'association pour laquelle vous souhaitez travailler :</p>
      </div>
    </p-header>

    <div class="ui-g ui-fluid">
        <div class="ui-g-12">
            <p-dropdown [options]="federationsList" [(ngModel)]="currentFederation" [style]="{'width':'100%'}" [placeholder]="'Fédération...'" name="currentFederation" optionLabel="libelle" dataKey="id_federation"></p-dropdown>
        </div>
    </div>
    <div class="ui-g ui-fluid">
        <div class="ui-g-12">
            <p-dropdown [options]="associationsList" [(ngModel)]="currentAssociation" [style]="{'width':'100%'}" [placeholder]="associationPlaceholder" name="currentAssociation" optionLabel="libelle" dataKey="id_association"></p-dropdown>
        </div>
    </div>
    <p-footer>
        <div class="p-grid p-dir-col">
            <div class="p-col">
                <button pButton class="green-button select-association-button"  [disabled]="loading" (click)="selectAssociation()" label="Valider"></button>
            </div>
            <div *ngIf="msgs" class="p-col">
                <p-messages [(value)]="msgs" styleClass="message-select-association"></p-messages>
            </div>
        </div>
  </p-footer>
  </form>
</p-card>
