import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {FormGroup, FormArray} from '@angular/forms';

import {plainToClass} from 'class-transformer';

import {ParametreService} from '../_services';
import { Personne, Parametre, EvaluationContenu} from '../../shared/_models';
import {Globals} from '../utils/globals';

@Component({
  selector: 'app-entourage-form-dialog',
  templateUrl: './entourage-form-dialog.component.html',
  styleUrls: ['./entourage-form-dialog.component.css'],
  //providers: [ConfirmationService]
})
export class EntourageFormDialogComponent implements OnInit {

  @Input()
  entourage: Personne = new Personne();

  @Input()
  formMode: string;

  @Input()
  showPopupEntourage = false;

  @Input()
  saveNew = false;

  @Input()
  entouragesList: Personne[];

  @Input()
  typeEntourageSelectable = false;

  @Input()
  evaluationForm: FormGroup; // propriété d'entrée du composant : le formulaire de l'évaluation a editer

  @Output()
  cancel = new EventEmitter<any>();

  @Output()
  entourageCreated = new EventEmitter<any>();

  showAdresse: boolean;
  currentEntourageForm: FormGroup;

  entouragesPersonnelsList: Personne[];
  civilitesList: Parametre[];
  civilitesProList: Parametre[];
  liensFamilleList: Parametre[];
  typesRueList: Parametre[];
  typesRepresentantList: Parametre[];
  specialitesList: Parametre[];

  currentTypeEntourage: string;

  datePipeFormat = Globals.datePipeFormat;
  datePipeFormatWithoutTime = Globals.datePipeFormatWithoutTime;
  dateFormat = Globals.dateFormat;
  locale = Globals.dateLocale;
  today = new Date();

  unitesTempsList: Parametre[];

  constructor(
    private parametreService: ParametreService,
  ) { }

  ngOnInit() {
    this.currentEntourageForm = Personne.getForm(this.entourage);

    this.getCivilites();
    this.getLiensFamille();

    this.getCivilitesPro();
    this.getSpecialites();
    this.getUnitesTempsList();

    this.getTypesRue();
    this.getTypesRepresentant();
    this.getEntourages();
    this.updateCurrentTypeEntourage();
  }

  getCivilites() {
    this.parametreService.getParametresByParams({ type: 'CIVILITE' })
      .subscribe((parametres: Parametre[]) => {
        this.civilitesList = parametres;
      });
  }

  getCivilitesPro() {
    this.parametreService.getParametresByParams({ type: 'CIVILITE_PRO' })
      .subscribe((parametres: Parametre[]) => {
        this.civilitesProList = parametres;
      });
  }

  getLiensFamille() {
    this.parametreService.getParametresByParams({ type: 'LIEN' })
      .subscribe((parametres: Parametre[]) => {
        this.liensFamilleList = parametres;
      });
  }

  getTypesRue() {
    this.parametreService.getParametresByParams({ type: 'TYPE_RUE' })
      .subscribe((parametres: Parametre[]) => {
        this.typesRueList = parametres;
      });
  }

  getTypesRepresentant() {
    this.parametreService.getParametresByParams({ type: 'REPRESENTANT' })
      .subscribe((parametres: Parametre[]) => {
        this.typesRepresentantList = parametres;
      });
  }

  getSpecialites() {
      this.parametreService.getParametresByParams({ type: 'SPECIALITE' })
        .subscribe((parametres: Parametre[]) => {
          this.specialitesList = parametres;
        });
  }

  getUnitesTempsList() {
    this.parametreService.getParametresByParams({type: 'UNITE'})
        .subscribe((parametres: Parametre[]) => {
            this.unitesTempsList = parametres;
        });
  }

  getEntourages() {
    this.entouragesList = plainToClass(Personne, this.evaluationForm.get('evaluation_contenu').get('entourages').value as Personne[]);
    this.entouragesPersonnelsList = [];

    let clientValue = plainToClass(Personne, this.evaluationForm.get('client').value as Personne);
    this.entouragesPersonnelsList.push(clientValue); // + client

    let self = this;
    this.entouragesList.forEach(function(element) {
      if (element.entourage_personnel && !element.enfant) {
        self.entouragesPersonnelsList.push(element);
      }
    });
  }

  onCloseEntouragePopup(event) {
    this.cancel.emit(event);
    // this.showPopupEntourage = false;
  }

  updateAge() {
    if (this.currentEntourageForm.value.date_de_naissance) {
      let age = Globals.getAge(this.currentEntourageForm.value.date_de_naissance);

      if (age >= 1) {
        this.currentEntourageForm.get('age').setValue(age.toFixed(0));
        this.currentEntourageForm.get('unite_age').setValue('ans');
      } else {
        let mois = (age * 12);

        this.currentEntourageForm.get('age').setValue(mois.toFixed(0));
        this.currentEntourageForm.get('unite_age').setValue('mois');
      }
    }
  }

  onChangeVivantAuFoyer(event) {
    if (event.checked) {
      // Vivant au foyer doit propager les coordonnées du client pour téléphone fixe et adresse et empêcher la saisie de ces champs
      this.currentEntourageForm.get('tel_fixe').setValue(this.evaluationForm.get('client').value.tel_fixe);
      this.copyAdresseClient();
    }
  }

  copyAdresseClient() {
    this.currentEntourageForm.get('numero_rue').setValue(this.evaluationForm.get('client').value.numero_rue);
    this.currentEntourageForm.get('type_complement_adresse').setValue(this.evaluationForm.get('client').value.type_complement_adresse);
    this.currentEntourageForm.get('rue').setValue(this.evaluationForm.get('client').value.rue);
    this.currentEntourageForm.get('ville').setValue(this.evaluationForm.get('client').value.ville);
    this.currentEntourageForm.get('cp').setValue(this.evaluationForm.get('client').value.cp);
    this.currentEntourageForm.get('pays').setValue(this.evaluationForm.get('client').value.pays);
    this.currentEntourageForm.get('cplt_adresse_1').setValue(this.evaluationForm.get('client').value.cplt_adresse_1);
    this.currentEntourageForm.get('cplt_adresse_2').setValue(this.evaluationForm.get('client').value.cplt_adresse_2);
    this.currentEntourageForm.get('cplt_adresse_3').setValue(this.evaluationForm.get('client').value.cplt_adresse_3);
  }

  saveEntourage() {
    if (this.currentEntourageForm !== null && this.currentEntourageForm !== undefined) {
      (this.evaluationForm.get('evaluation_contenu').get('entourages') as FormArray).push(this.currentEntourageForm);
      this.entourageCreated.emit(plainToClass(Personne, this.currentEntourageForm.value));
    }

    this.showPopupEntourage = false;
  }

  updateTypeEntourageForm() {
    if (this.currentEntourageForm.value.entourage_personnel) {
      this.currentTypeEntourage = 'personnel';
    } else if (this.currentEntourageForm.value.entourage_professionnel) {
      this.currentTypeEntourage = 'professionnel';
    }
  }

  setCurrentTypeEntourage(typeEntourage) {
    if (typeEntourage === 'personnel') {
      this.currentEntourageForm.get('entourage_personnel').patchValue(true);
      this.currentEntourageForm.get('entourage_professionnel').patchValue(false);
    } else {
      this.currentEntourageForm.get('entourage_personnel').patchValue(false);
      this.currentEntourageForm.get('entourage_professionnel').patchValue(true);
    }
  }

  newEntourage(typeEntourage) {
    /*
    if (this.isEditable) {
      this.entourageToEdit = new Personne();

      this.entourageToEdit.evaluation_contenu = plainToClass(EvaluationContenu, (this.evaluationForm.get('evaluation_contenu').value as Personne));

      if (typeEntourage === 'enfant') {
        this.entourageToEdit.enfant = true;
        this.entourageToEdit.parent_1 = this.evaluationForm.value.client;
      }
      if (typeEntourage === 'perso') {
        this.entourageToEdit.entourage_personnel = true;
      }
      if (typeEntourage === 'pro') {
        this.entourageToEdit.entourage_professionnel = true;
      }

      this.currentEntourageForm = Personne.getForm(this.entourageToEdit);

      this.updateTypeEntourageForm();

      this.indexSelectedEntourage = (this.evaluationForm.get('evaluation_contenu').get('entourages') as FormArray).length;
      (this.evaluationForm.get('evaluation_contenu').get('entourages') as FormArray).insert(this.indexSelectedEntourage, this.currentEntourageForm);

      this.showPopupEntourage = true;
      this.currentEntourageFormMode = 'create';
      this.popupEntourageTitle = 'Nouvelle Action';
    }
    */
  }

  updateCurrentTypeEntourage() {
    if (this.currentEntourageForm.get('entourage_personnel').value) {
      this.currentTypeEntourage = 'personnel';
    } else if (this.currentEntourageForm.get('entourage_professionnel').value) {
      this.currentTypeEntourage = 'professionnel';
    } else if (this.currentEntourageForm.get('enfant').value) {
      this.currentTypeEntourage = 'enfant';
    }
  }

}

