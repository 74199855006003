import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { RechercheEchangeRoutingModule } from './recherche-echange-routing.module';
import { RechercheEchangeComponent } from './recherche-echange/recherche-echange.component';
import { FiltresEchangeComponent } from './filtres-echange/filtres-echange.component';

@NgModule({
  declarations: [RechercheEchangeComponent, FiltresEchangeComponent],
  imports: [
    CommonModule,
    SharedModule,
    RechercheEchangeRoutingModule
  ]
})
export class RechercheEchangeModule { }
