import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';

export const interventionOperationValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {

  let valid = true;
  let errors = null;

  if (control && control.get('intervention_besoin') && control.get('intervention_besoin').value
  && control.get('intervention_besoin').value.type_besoin && control.get('intervention_besoin').value.type_besoin) {
    // Intervention Operation Alimentation
    if (control.get('intervention_besoin').value.type_besoin.code_parametre === 'ALI') {
      // Si aucun repas sélectionné => Invalide
      if (!control.get('aide_petit_dejeuner').value && !control.get('aide_dejeuner').value
      && !control.get('aide_gouter').value && !control.get('aide_diner').value) {
        valid = false;
        errors = {actionOpen: true};
      }
    }
  }

  return valid ? null : errors;
};

@Directive({
  selector: '[appInterventionOperationValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: InterventionOperationValidatorDirective, multi: true}]
})
export class InterventionOperationValidatorDirective implements Validator {

  constructor() { }

  public validate(control: FormGroup): {[key: string]: any} {
    return interventionOperationValidator(control);
  }

}

