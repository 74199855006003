import {FormControl, FormGroup} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {Personne} from './personne';


export class SanteEnfant {

  id_sante_enfant: number;

  traitement = false;

  ponctuel = false;

  ordonnance = false;

  emplacement: string;

  info: string;

  @Type(() => Personne)
  medecin: Personne;

  @Type(() => Personne)
  enfant: Personne;

  static getForm(santeEnfant = null): FormGroup {
    if (!santeEnfant) {
      santeEnfant = new SanteEnfant();
    }

    return new FormGroup({
      'id_sante_enfant': new FormControl(santeEnfant.id_sante_enfant),
      'traitement': new FormControl(santeEnfant.traitement),
      'ponctuel': new FormControl(santeEnfant.ponctuel),
      'ordonnance': new FormControl(santeEnfant.ordonnance),
      'emplacement': new FormControl(santeEnfant.emplacement),
      'info': new FormControl(santeEnfant.info),
      'medecin': new FormControl(santeEnfant.medecin),
    });
  }

  serialize() {
    let serializeObject = classToPlain(this);

    if (this.medecin !== null && this.medecin !== undefined) {
      serializeObject['medecin'] = this.medecin.serialize();
    }

    return serializeObject;
  }
}
