import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import * as moment from 'moment';
import {alerteValidator} from '../_directives/alerte-validator.directive';
import {Globals} from '../utils/globals';
import {Action} from './action';
import {Appreciation} from './appreciation';
import {Association} from './association';
import {Federation} from './federation';
import {Parametre} from './parametre';
import {Utilisateur} from './utilisateur';

export class Alerte {
  id_alerte: number;

  @Type(() => Appreciation)
  appreciation: Appreciation;

  @Type(() => Parametre)
  type_alerte: Parametre;

  client_id_philia: string;

  @Type(() => Federation)
  federation: Federation;

  @Type(() => Association)
  association: Association;

  @Type(() => Date)
  date_creation: Date = new Date();

  @Type(() => Date)
  created: Date = new Date();

  @Type(() => Date)
  updated: Date = new Date();

  @Type(() => Date)
  closed: Date;

  @Type(() => Utilisateur)
  utilisateur_fermeture: Utilisateur;

  @Type(() => Parametre)
  statut_alerte: Parametre;

  justifiee: boolean;

  @Type(() => Parametre)
  motif_alerte: Parametre;

  description: string;

  @Type(() => Action)
  actions: Action[] = [];

  statut_mail: number;

  @Type(() => Date)
  date_envoi_mail: Date;

  @Type(() => Parametre)
  symptomes_covid: Parametre[];

  get client_civilite() {
    if (this.appreciation) {
      return this.appreciation.client_civilite;
    }
    return null;
  }

  get client_nom() {
    if (this.appreciation) {
      return this.appreciation.client_nom;
    }
    return null;
  }

  get client_prenom() {
    if (this.appreciation) {
      return this.appreciation.client_prenom;
    }
    return null;
  }

  get openActionsLength() {
    let nbOpen = 0;

    this.actions.forEach((action) => {
      if (action.statut_action.code_parametre !== 'F') {
        nbOpen++;
      }
    });

    return nbOpen;
  }

  get openActionsNotSuiviLength() {
    let nbOpen = 0;

    this.actions.forEach((action) => {
      if (action.statut_action.code_parametre !== 'F' && action.objet_action !== 'Suivi') {
        nbOpen++;
      }
    });

    return nbOpen;
  }

  get firstDateEcheance() {
    let firstDate = null;

    this.actions.forEach((action) => {
      let echeance = action.echeance;
      if (action.statut_action.code_parametre !== 'F') {
        if (echeance !== undefined) {
          if (firstDate == null) {
            firstDate = echeance;
          } else if (echeance.getTime() < firstDate.getTime()) {
            firstDate = echeance;
          }
        }
      }
    });

    return firstDate;
  }

  get lastAction() {
    let nbActions = this.actions.length;
    if (nbActions > 0) {
      return this.actions[nbActions - 1];
    }
    return null;
  }

  get description_with_symptomes_covid() {
    let description_with_symptomes_covid = this.description;

    if (this.motif_alerte && this.motif_alerte.code_parametre === 'COVID19'
      && this.symptomes_covid && this.symptomes_covid.length > 0) {
      description_with_symptomes_covid += '. ';
      this.symptomes_covid.forEach((symptome_covid) => {
        description_with_symptomes_covid += symptome_covid.libelle + ', ';
      });
      // Si ca finit par une virgule, on l'enlève
      if (description_with_symptomes_covid.slice(-2) === ', ') {
        description_with_symptomes_covid = description_with_symptomes_covid.substring(0, description_with_symptomes_covid.length - 2);
      }
    }

    return description_with_symptomes_covid;
  }

  static getForm(alerte = null): FormGroup {
    if (!alerte) {
      alerte = new Alerte();
    }

    let actionsForm = [];
    alerte.actions.forEach(function (action) {
      actionsForm.push(Action.getForm(action));
    });

    return new FormGroup({
      'id_alerte': new FormControl(alerte.id_alerte),
      'appreciation': Appreciation.getForm(alerte.appreciation),
      'type_alerte': new FormControl(alerte.type_alerte),
      'client_id_philia': new FormControl(alerte.client_id_philia),
      'federation': new FormControl(alerte.federation),
      'association': new FormControl(alerte.association),
      'date_creation': new FormControl(alerte.date_creation),
      'created': new FormControl(alerte.created),
      'updated': new FormControl(alerte.updated),
      'statut_alerte': new FormControl(alerte.statut_alerte),
      'isStatutClosed': new FormControl(alerte.statut_alerte ? alerte.statut_alerte.code_parametre === 'F' : false),
      'justifiee': new FormControl(alerte.justifiee, Validators.required),
      'motif_alerte': new FormControl(alerte.motif_alerte),
      'description': new FormControl(alerte.description),
      'actions': new FormArray(actionsForm),
      'statut_mail': new FormControl(alerte.statut_mail),
      'date_envoi_mail': new FormControl(alerte.date_envoi_mail),
      'symptomes_covid': new FormControl(alerte.symptomes_covid),
      'utilisateur_fermeture': new FormControl(alerte.utilisateur_fermeture),
    }, {validators: alerteValidator});
  }

  static getDateQualificationFromData(data) {
    let date_qualification = null;
    let date_justification = null;
    let nbActions = data.actions.length;
    if (nbActions > 0) {
      data.actions.forEach((action) => {
        if (action.objet_action === 'Qualification') {
          date_qualification = action.created;
        }
        if (action.objet_action === 'Justification') {
          date_justification = action.created;
        }
      });
      if (!date_qualification) {
        date_qualification = date_justification;
      }
    }
    return date_qualification;
  }

  static getQualificateurFromData(data) {
    // Si pas de qualificateur, on retourne le justificateur
    let qualificateur = null;
    let nbActions = data.actions.length;
    if (nbActions > 0) {
      data.actions.forEach((action) => {
        if (action.objet_action === 'Qualification' || action.objet_action === 'Justification') {
          qualificateur = action.redacteur;
        }
      });
    }
    return qualificateur;
  }

  isClosed(currentUtilisateur: Utilisateur) {
    if (!this.statut_alerte) {
      return false;
    }

    // Alerte close ?
    let isClosed = this.statut_alerte.code_parametre === 'F';

    // Utilisateur en lecture => Alerte close (pas de modif)
    if (!isClosed && !currentUtilisateur.hasFonctionnalite('vigilance', 'edit_alertes')) {
      isClosed = true;
    }

    return isClosed;
  }

  hasActionQualificationJustification() {
    let hasActionQualificationJustification = false;

    this.actions.forEach((action) => {
      if (action.objet_action === 'Qualification'
        || action.objet_action === 'Justification') {
        hasActionQualificationJustification = true;
      }
    });

    return hasActionQualificationJustification;
  }

  canEditJustification() {
    if (!this.hasActionQualificationJustification()) {
      return true;
    } else if (this.openActionsNotSuiviLength > 0) {
      return false;
    } else {
      return true;
    }
  }

  canUnclose() {
    let periodeUnclose = 24; // Période de possibilite de dé-cloturer : 24 heures
    let dateLimitEditable = moment(this.updated).add(periodeUnclose, 'h').toDate(); // On ajoute le nombre d'heures pour obtenir la date limit
    let isDateLimitValid = moment(dateLimitEditable).isAfter(moment(new Date()));

    return isDateLimitValid;
  }

  getDateQualification() {
    return Alerte.getDateQualificationFromData(this);
  }

  getQualificateur() {
    return Alerte.getQualificateurFromData(this);
  }

  serialize() {
    let serializeObject = classToPlain(this);
    delete serializeObject['id_alerte'];
    delete serializeObject['created'];
    delete serializeObject['updated'];
    delete serializeObject['closed'];
    delete serializeObject['date_envoi_mail'];
    delete serializeObject['actions'];

    if (this.federation !== null && this.federation !== undefined) {
      serializeObject['federation'] = this.federation.id_federation;
    }

    if (this.association !== null && this.association !== undefined) {
      serializeObject['association'] = this.association.id_association;
    }

    if (this.statut_alerte !== null && this.statut_alerte !== undefined) {
      serializeObject['statut_alerte'] = this.statut_alerte.id_parametre;
    }

    if (this.motif_alerte !== null && this.motif_alerte !== undefined) {
      serializeObject['motif_alerte'] = this.motif_alerte.id_parametre;
    }

    if (this.type_alerte !== null && this.type_alerte !== undefined) {
      serializeObject['type_alerte'] = this.type_alerte.id_parametre;
    }

    if (this.appreciation !== null && this.appreciation !== undefined) {
      serializeObject['appreciation'] = this.appreciation.id_appreciation;
    }

    if (this.justifiee !== null && this.justifiee !== undefined) {
      serializeObject['justifiee'] = this.justifiee;
    }

    if (this.symptomes_covid !== null && this.symptomes_covid !== undefined) {
      serializeObject['symptomes_covid'] = [];
      this.symptomes_covid.forEach(function (symptome_covid) {
        serializeObject['symptomes_covid'].push(symptome_covid.id_parametre);
      });
    }

    if (this.utilisateur_fermeture !== null && this.utilisateur_fermeture !== undefined) {
      serializeObject['utilisateur_fermeture'] = this.utilisateur_fermeture.id_utilisateur;
    }

    // Conversion des dates en FR
    for (let propertyName in serializeObject) { // On parcourt les proprietes de l'objet
      if (serializeObject[propertyName] && serializeObject[propertyName] instanceof Date) { // Si c'est une date non null
        serializeObject[propertyName] = Globals.getDateWithTimezone(serializeObject[propertyName]);
      }
    }

    return serializeObject;
  }

  serializeForExport() {
    let serializeObject = {
      'Date création': this.date_creation ? Globals.formatDate(this.date_creation, true) : '',
      'Statut alerte': this.statut_alerte ? this.statut_alerte.libelle : '',
      'Nom Individu': this.client_nom ? this.client_nom : '',
      'Prénom Individu': this.client_prenom ? this.client_prenom : '',
      'Id Philia Individu': this.client_id_philia ? this.client_id_philia : '',
      'Motif alerte': this.motif_alerte ? this.motif_alerte.libelle : '',
      'Justifiée': this.justifiee ? 'O' : 'N',
      'Action(s) Ouverte(s)': this.openActionsLength,
      'Action(s) Total': this.actions.length,
      'Date Echéance': this.firstDateEcheance ? Globals.formatDate(this.firstDateEcheance) : '',
      'Association': this.association ? this.association.libelle : '',
      'Fédération': this.federation ? this.federation.libelle : '',
      'Description': this.description_with_symptomes_covid ? this.description_with_symptomes_covid : '',
    };

    return serializeObject;
  }

  serializeWithActionsForExport() {
    let serializeObject = [];
    serializeObject[0] = [];
    let serializeActionObject = [];
    serializeObject[0][0] = this.serializeForExport(); // Données alertes

    let self = this;

    this.actions.forEach(function (action) { // On insere les données actions
      action.alerte = self;
      serializeActionObject.push(action.serializeForExport());
    });

    if (serializeActionObject.length > 0) {
      serializeObject.push(serializeActionObject);
    }

    return serializeObject;
  }
}
