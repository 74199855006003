import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import {Validators, FormControl, FormGroup, FormBuilder} from '@angular/forms';

import {plainToClass} from 'class-transformer';

import {SelectItem} from 'primeng/api';

import { Action, EvaluationEntete } from '../_models';
import { FiltreAction } from '../utils/filtre-action';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ActionService {

  filtreAction = new FiltreAction();

  constructor(
    private http: HttpClient,
    private fb: FormBuilder) { }

  /** GET actions */
  getAll(): Observable<Action[]> {
    return this.http.get<Action[]>(`${environment.apiHost}/${environment.apiNamespace}/actions`);
  }

  /** GET actions */
  getActionsByParams(paramsValue: FiltreAction, updateFiltre = true): Observable<Action[]> {
    if (updateFiltre) {
      this.filtreAction = paramsValue;
    }

    let paramsValueSerialized = paramsValue.serialize();
    let params = new HttpParams();
    for (let paramKey in paramsValueSerialized) {
      if (paramsValueSerialized.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValueSerialized[paramKey]);
      }
    }
    return this.http.get<Action[]>(`${environment.apiHost}/${environment.apiNamespace}/actions`, { params: params });
  }

  /** GET actions */
  getActionById(id_action: number): Observable<Action> {
    return this.http.get<Action>(`${environment.apiHost}/${environment.apiNamespace}/actions/${id_action}`);
  }

  /** GET actions/count */
  getNbActionsByParams(paramsValue: FiltreAction): Observable<any> {
    let paramsValueSerialized = paramsValue.serialize();
    let params = new HttpParams();
    for (let paramKey in paramsValueSerialized) {
      if (paramsValueSerialized.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValueSerialized[paramKey]);
      }
    }
    return this.http.get<any>(`${environment.apiHost}/${environment.apiNamespace}/actions/count`, { params: params });
  }

  /** POST actions */
  createAction(action: Action): Observable<Action> {
    return this.http.post<any>(
      `${environment.apiHost}/${environment.apiNamespace}/actions`,
      plainToClass(Action, action).serialize()
     );
  }

  /** PUT actions */
  updateAction(action: Action): Observable<Action> {
    return this.http.put<any>(
      `${environment.apiHost}/${environment.apiNamespace}/actions/${action.id_action}`,
      plainToClass(Action, action).serialize()
    );
  }

  /** PATCH echanges */
  patchAction(params, id_action): Observable<Action> {
    return this.http.patch<Action>(
      `${environment.apiHost}/${environment.apiNamespace}/actions/${id_action}`,
      params
    );
  }

  /** GET objet/action */
  getObjetActionList(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiHost}/${environment.apiNamespace}/objet/action`);
  }

  initFiltreAction() {
    this.filtreAction = new FiltreAction();
  }

  createActionForm(action: Action): FormGroup {
    return Action.getForm(action);
    /*
    if (action.isEvaluation()) { // Action evaluation

      let evaluationActionForm = this.fb.group({
        'statut_evaluation': new FormControl( action.evaluation_entete.statut_evaluation)
      });

      return this.fb.group({
        'id_action': new FormControl( action.id_action),
        'created': new FormControl( action.created),
        'redacteur': new FormControl(action.redacteur),
        'objet_action': new FormControl(action.objet_action, Validators.required),
        'echeance': new FormControl(action.echeance, Validators.required),
        'responsable': new FormControl(action.responsable),
        'description_action': new FormControl(action.description_action, Validators.required),
        'updated': new FormControl(action.updated),
        'statut_action': new FormControl(action.statut_action, Validators.required),
        'send_mail_responsable': new FormControl(false),
        'save_before_close': new FormControl(false),
        'type_public': new FormControl(action.type_public, Validators.required),
        'is_evaluation': new FormControl(true),
        'evaluation_entete': evaluationActionForm,
      });
    } else { // Action par défaut
      return this.fb.group({
        'id_action': new FormControl( action.id_action),
        'created': new FormControl( action.created),
        'redacteur': new FormControl(action.redacteur),
        'objet_action': new FormControl(action.objet_action, Validators.required),
        'echeance': new FormControl(action.echeance),
        'responsable': new FormControl(action.responsable),
        'description_action': new FormControl(action.description_action, Validators.required),
        'updated': new FormControl(action.updated),
        'statut_action': new FormControl(action.statut_action, Validators.required),
        'send_mail_responsable': new FormControl(false),
        'save_before_close': new FormControl(false),
      });
    }
*/
  }

  /* handleError */
  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(error);
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a utilisateur-facing error message
    return throwError(
      error);
  }

}
