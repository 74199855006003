import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { plainToClass } from 'class-transformer';

import { MessageService } from 'primeng/api';

import { Echange } from '../../shared/_models';
import { EchangeService } from '../../shared/_services';
import { EchangeMarqueursService } from 'src/app/shared/_services/echange-marqueurs.service';
import { EchangeMarqueur } from 'src/app/shared/_models/echange-marqueur';
import { FederationMarqueur } from 'src/app/shared/_models/federation-marqueur';
import {OngletService} from '../../shared/_services/onglet.service';

@Component({
  selector: 'app-echange-edit',
  templateUrl: './echange-edit.component.html',
  styleUrls: ['./echange-edit.component.css']
})
export class EchangeEditComponent implements OnInit {

  echange: Echange;
  isUpdating = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private echangeService: EchangeService,
    private titleService: Title,
    private ongletService: OngletService
  ) { }

  ngOnInit() {
    this.getEchange();
  }

  getEchange() {
    this.isUpdating = true;
    let id = +this.route.snapshot.paramMap.get('id');
    this.echangeService.getEchange(id)
      .subscribe((echange: Echange) => {
        this.echange = plainToClass(Echange, echange);
        this.isUpdating = false;
        this.titleService.setTitle('ech_' + this.echange.personne_sujet_nom);
      });
  }

  onEchangeUpdated(event) {
    event.id_echange = this.echange.id_echange;
    this.isUpdating = true;
    this.echangeService.updateEchange(event)
      .subscribe(
        (echangeUpdated: Echange) => {
          console.log(`update echange`);
          this.echange = plainToClass(Echange, echangeUpdated);
          this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Echange mis à jour avec succès.' });
          this.isUpdating = false;
          if (localStorage.getItem('fermerOnglet') !== 'true') {
            this.router.navigate(['/in/echange/' + this.echange.id_echange]);
          } else {
            this.ongletService.disable();
            window.close();
          }
        },
        error => {
          console.log(error);
          this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Erreur dans la mise à jour de l\'echange.' });
          this.isUpdating = false;
        }
      );
  }

}
