import {classToPlain} from 'class-transformer';

import {Association, Federation, Parametre, Utilisateur, Groupe} from '../_models';
import {Globals} from './globals';

export class FiltreAction {
  federation: Federation;
  association: Association;
  created_debut: Date;
  created_fin: Date;
  no_echeance: string;
  closed_debut: Date;
  closed_fin: Date;
  statut_action: Parametre[];
  categorie_echange: Parametre[];
  motif_echange: Parametre[];
  operateur: Utilisateur[];
  responsable: Utilisateur[];
  personne_sujet_nom: string;
  personne_sujet_prenom: string;
  personne_sujet_telephone: string;
  objet_action: string;
  module: string[];
  groupes: Groupe[];
  offset: number;
  limit: number;
  sort_property: string;
  sort_direction: string;
  statut_echeance: string[];

  constructor() {
    this.init();
  }

  static getSortProperty(property: string) {
    if (property === 'created1') {
      return 'created';
    } else if (property === 'firstDateEcheance' || property === 'statut_echeance') {
      return 'echeance';
    }
    return property;
  }

  static getSortOrder(sortOrder: number) {
    return sortOrder === 1 ? 'ASC' : 'DESC';
  }

  init() {
      this.federation = null;
      this.association = null;
      this.created_debut = null;
      this.created_fin = null;
      this.no_echeance = 'N';
      this.closed_debut = null;
      this.closed_fin = null;
      this.statut_action = [];
      this.categorie_echange = null;
      this.motif_echange = null;
      this.operateur = null;
      this.responsable = null;
      this.personne_sujet_nom = '';
      this.personne_sujet_prenom = '';
      this.personne_sujet_telephone = '';
      this.objet_action = '';
      this.module = null;
      this.groupes = null;
      this.offset = 0;
      this.limit = 20;
      this.sort_property = 'created';
      this.sort_direction = 'DESC';
      this.statut_echeance = null;
  }

  setDatas(datas) {
    if (datas.federation) {
      this.federation = datas.federation;
    }
    if (datas.association) {
      this.association = datas.association;
    }
    if (datas.created_debut) {
      this.created_debut = datas.created_debut;
    }
    if (datas.created_fin) {
      this.created_fin = datas.created_fin;
    }
    if (datas.closed_debut) {
      this.closed_debut = datas.closed_debut;
    }
    if (datas.closed_fin) {
      this.closed_fin = datas.closed_fin;
    }
    if (datas.statut_action) {
      this.statut_action = datas.statut_action;
    }
    if (datas.categorie_echange) {
      this.categorie_echange = datas.categorie_echange;
    }
    if (datas.motif_echange) {
      this.motif_echange = datas.motif_echange;
    }
    if (datas.operateur) {
      this.operateur = datas.operateur;
    }
    if (datas.responsable) {
      this.responsable = datas.responsable;
    }
    if (datas.personne_sujet_nom) {
      this.personne_sujet_nom = datas.personne_sujet_nom;
    }
    if (datas.personne_sujet_prenom) {
      this.personne_sujet_prenom = datas.personne_sujet_prenom;
    }
    if (datas.personne_sujet_telephone) {
      this.personne_sujet_telephone = datas.personne_sujet_telephone;
    }
    if (datas.objet_action) {
      this.objet_action = datas.objet_action;
    }
    if (datas.module) {
      this.module = datas.module;
    }
    if (datas.groupes) {
      this.groupes = datas.groupes;
    }
    if (datas.statut_echeance) {
      this.statut_echeance = datas.statut_echeance;
    }
  }

  serialize() {
    let serializeObject = classToPlain(this);

    // On enleve toutes les proprietes null ou undefined ou tableau vide
    for (let propertyName in serializeObject) {
      if (!serializeObject[propertyName]
        || (Array.isArray(serializeObject[propertyName]) && serializeObject[propertyName].length === 0)) {
        delete serializeObject[propertyName];
      }
    }

    if (this.federation !== null && this.federation !== undefined) {
        serializeObject['federation'] = serializeObject['federation'].id_federation;
    }

    if (this.association !== null && this.association !== undefined) {
        serializeObject['association'] = serializeObject['association'].id_association;
    }

    if (this.created_debut !== null && this.created_debut !== undefined) {
        serializeObject['created_debut'] = Math.floor(serializeObject['created_debut'].getTime() / 1000);
    }

    if (this.created_fin !== null && this.created_fin !== undefined) {
        serializeObject['created_fin'] = Math.floor(serializeObject['created_fin'].getTime() / 1000);
    }

    if (this.closed_debut !== null && this.closed_debut !== undefined) {
        serializeObject['closed_debut'] = Math.floor(serializeObject['closed_debut'].getTime() / 1000);
    }

    if (this.closed_fin !== null && this.closed_fin !== undefined) {
        serializeObject['closed_fin'] = Math.floor(serializeObject['closed_fin'].getTime() / 1000);
    }

    if (this.statut_action !== null && this.statut_action !== undefined) {
        serializeObject['statut_action'] = [];
        this.statut_action.forEach(function(statut_e) {
          serializeObject['statut_action'].push(statut_e.id_parametre);
        });
        serializeObject['statut_action'] = serializeObject['statut_action'].join(',');
    }

    if (this.categorie_echange !== null && this.categorie_echange !== undefined) {
      serializeObject['categorie_echange'] = [];
      this.categorie_echange.forEach(function(categorie) {
        serializeObject['categorie_echange'].push(categorie.id_parametre);
      });
      serializeObject['categorie_echange'] = serializeObject['categorie_echange'].join(',');
    }

    if (this.motif_echange !== null && this.motif_echange !== undefined) {
      serializeObject['motif_echange'] = [];
      this.motif_echange.forEach(function(motif) {
        serializeObject['motif_echange'].push(motif.id_parametre);
      });
      serializeObject['motif_echange'] = serializeObject['motif_echange'].join(',');
    }

    if (this.operateur !== null && this.operateur !== undefined) {
      serializeObject['operateur'] = [];
      this.operateur.forEach(function(op) {
        serializeObject['operateur'].push(op.id_utilisateur);
      });
      serializeObject['operateur'] = serializeObject['operateur'].join(',');
    }

    if (this.responsable !== null && this.responsable !== undefined) {
      serializeObject['responsable'] = [];
      this.responsable.forEach(function(respo) {
        serializeObject['responsable'].push(respo.id_utilisateur);
      });
      serializeObject['responsable'] = serializeObject['responsable'].join(',');
    }

    if (this.module !== null && this.module !== undefined && this.module.length > 0) {
      serializeObject['module'] = serializeObject['module'].join(',');
    }

    if (this.groupes !== null && this.groupes !== undefined) {
      serializeObject['groupes'] = [];
      this.groupes.forEach(function(grp) {
        serializeObject['groupes'].push(grp.id_groupe);
      });
      serializeObject['groupes'] = serializeObject['groupes'].join(',');
    }

    return serializeObject;
  }
}
