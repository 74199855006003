import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class DocumentService {

    constructor(private http: HttpClient) { }

  getDocumentImageSrc(id_document) {
    return `${environment.apiHost}/${environment.apiNamespace}/evaluations/documents/${id_document}/image`;
  }

  public getImage(id_document): Observable<any> {
    return this.http
      .get(`${environment.apiHost}/${environment.apiNamespace}/evaluations/documents/${id_document}/image`, {
        responseType: 'blob'
      });
  }

  /* handleError */
  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(error);
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a utilisateur-facing error message
    return throwError(
      error);
  }

}
