import {classToPlain, Type} from 'class-transformer';
import {Association} from './association';
import {Federation} from './federation';
import {Utilisateur} from './utilisateur';

export class Agence {
  id_agence: number;
  code: string;
  libelle: string;
  id_philia: string;

  @Type(() => Federation)
  federation: Federation;

  @Type(() => Association)
  association: Association;

  @Type(() => Utilisateur)
  utilisateurs: Utilisateur[];

  disabled: boolean;

  serialize() {
    let serializeObject = classToPlain(this);
    delete serializeObject['id_agence'];
    delete serializeObject['utilisateurs'];

    if (this.association !== null && this.association !== undefined) {
      serializeObject['association'] = this.association.id_association;
    }

    if (this.federation !== null && this.federation !== undefined) {
      serializeObject['federation'] = this.federation.id_federation;
    }

    return serializeObject;
  }
}
