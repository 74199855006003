import {classToPlain} from 'class-transformer';

export class Parametre {
  id_parametre: number;
  id_philia: string;
  type = 'TYPE';
  libelle: string;
  code_parametre: string;
  is_default = false;
  type_parent: string;
  type_enfant: string;
  code_parametre_parent: string;

  /**
   * Retourne le libellé tronqué d'un paramètre.
   * Exemple : "Appel entrant" => "Appel E"
   * @return string
   */
  get libelleTronque() {
    let libelleTab = this.libelle.split(' ');
    return libelleTab.length === 1 ? libelleTab[0] : (libelleTab[0] + ' ' + libelleTab[1].substr(0, 1).toUpperCase());
  }

  serialize() {
    let serializeObject = classToPlain(this);
    delete serializeObject['id_parametre'];

    return serializeObject;
  }
}
