import {classToPlain} from 'class-transformer';

import {Federation, Association} from '../_models';

export class FiltreAgence {
  federation: Federation;
  association: Association;
  libelle: string;
  disabled: string[];
  id_philia: string;
  offset: number;
  limit: number;
  sort_property: string;
  sort_direction: string;

  constructor() {
    this.init();
  }

  static getSortProperty(property: string) {
    return property;
  }

  static getSortOrder(sortOrder: number) {
    return sortOrder === 1 ? 'ASC' : 'DESC';
  }

  init() {
      this.federation = null;
      this.association = null;
      this.libelle = '';
      this.disabled = null;
      this.id_philia = null;
      this.offset = 0;
      this.limit = 20;
      this.sort_property = 'libelle';
      this.sort_direction = 'DESC';
  }

  setDatas(datas) {
    if (datas.federation) {
      this.federation = datas.federation;
    }
    if (datas.association) {
      this.association = datas.association;
    }
    if (datas.libelle) {
      this.libelle = datas.libelle;
    }
    if (datas.disabled) {
      this.disabled = datas.disabled;
    }
    if (datas.id_philia) {
      this.id_philia = datas.id_philia;
    }
  }

  serialize() {
    let serializeObject = classToPlain(this);

    // On enleve toutes les proprietes null ou undefined ou tableau vide
    for (let propertyName in serializeObject) {
      if (!serializeObject[propertyName]
        || (Array.isArray(serializeObject[propertyName]) && serializeObject[propertyName].length === 0)) {
        delete serializeObject[propertyName];
      }
    }

    if (this.federation !== null && this.federation !== undefined) {
        serializeObject['federation'] = serializeObject['federation'].id_federation;
    }

    if (this.association !== null && this.association !== undefined) {
      serializeObject['association'] = serializeObject['association'].id_association;
  }

    if (this.disabled !== null && this.disabled !== undefined && this.disabled.length > 0) {
      serializeObject['disabled'] = serializeObject['disabled'].join(',');
    }

    return serializeObject;
  }
}
