import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { Commune } from '../_models';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class CommuneService {

    constructor(private http: HttpClient) { }

  /** GET communes */
  getCommunesByParams(paramsValue: any): Observable<Commune[]> {
    let params = new HttpParams();
    for (let paramKey in paramsValue) {
      if (paramsValue.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValue[paramKey]);
      }
    }
    return this.http.get<Commune[]>(`${environment.apiHost}/${environment.apiNamespace}/communes`, { params: params });
  }

  /* handleError */
  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(error);
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a utilisateur-facing error message
    return throwError(
      error);
  }

}
