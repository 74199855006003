import {classToPlain} from 'class-transformer';

import {Association, Federation, Parametre, Utilisateur} from '../_models';

export class FiltreEvaluation {
  federation: Federation;
  association: Association;
  echeance_debut: Date;
  echeance_fin: Date;
  synchronisation_debut: Date;
  synchronisation_fin: Date;
  evaluation_debut: Date;
  evaluation_fin: Date;
  creation_debut: Date;
  creation_fin: Date;
  statut_evaluation: Parametre[];
  type_public: Parametre[];
  evaluateur: Utilisateur[];
  client_id_philia: string;
  client_nom: string;
  client_prenom: string;
  client_telephone: string;
  id_evaluation_origine: number;
  offset: number;
  limit: number;
  sort_property: string;
  sort_direction: string;

  constructor() {
    this.init();
  }

  static getSortProperty(property: string) {
    return property;
  }

  static getSortOrder(sortOrder: number) {
    return sortOrder === 1 ? 'ASC' : 'DESC';
  }

  init() {
      this.federation = null;
      this.association = null;
      this.echeance_debut = null;
      this.echeance_fin = null;
      this.synchronisation_debut = null;
      this.synchronisation_fin = null;
      this.evaluation_debut = null;
      this.evaluation_fin = null;
      this.creation_debut = null;
      this.creation_fin = null;
      this.statut_evaluation = [];
      this.type_public = null;
      this.evaluateur = null;
      this.client_id_philia = null;
      this.client_nom = null;
      this.client_prenom = null;
      this.client_telephone = null;
      this.offset = 0;
      this.limit = 20;
      this.sort_property = 'id_evaluation_entete';
      this.sort_direction = 'DESC';
      this.id_evaluation_origine = null;
  }

  setDatas(datas) {
    if (datas.federation) {
      this.federation = datas.federation;
    }
    if (datas.association) {
      this.association = datas.association;
    }
    if (datas.echeance_debut) {
      this.echeance_debut = datas.echeance_debut;
    }
    if (datas.echeance_fin) {
      this.echeance_fin = datas.echeance_fin;
    }
    if (datas.synchronisation_debut) {
      this.synchronisation_debut = datas.synchronisation_debut;
    }
    if (datas.synchronisation_fin) {
      this.synchronisation_fin = datas.synchronisation_fin;
    }
    if (datas.evaluation_debut) {
      this.evaluation_debut = datas.evaluation_debut;
    }
    if (datas.evaluation_fin) {
      this.evaluation_fin = datas.evaluation_fin;
    }
    if (datas.creation_debut) {
      this.evaluation_debut = datas.evaluation_debut;
    }
    if (datas.creation_fin) {
      this.evaluation_fin = datas.evaluation_fin;
    }
    if (datas.statut_evaluation) {
      this.statut_evaluation = datas.statut_evaluation;
    }
    if (datas.type_public) {
      this.type_public = datas.type_public;
    }
    if (datas.evaluateur) {
      this.evaluateur = datas.evaluateur;
    }
    if (datas.client_id_philia) {
      this.client_id_philia = datas.client_id_philia;
    }
    if (datas.client_nom) {
      this.client_nom = datas.client_nom;
    }
    if (datas.client_prenom) {
      this.client_prenom = datas.client_prenom;
    }
    if (datas.client_telephone) {
      this.client_telephone = datas.client_telephone;
    }
  }

  serialize() {
    let serializeObject = classToPlain(this);

    // On enleve toutes les proprietes null ou undefined
    for (let propertyName in serializeObject) {
      if (!serializeObject[propertyName]) {
        delete serializeObject[propertyName];
      }
    }

    if (this.federation !== null && this.federation !== undefined) {
        serializeObject['federation'] = serializeObject['federation'].id_federation;
    }

    if (this.association !== null && this.association !== undefined) {
        serializeObject['association'] = serializeObject['association'].id_association;
    }

    if (this.echeance_debut !== null && this.echeance_debut !== undefined) {
        serializeObject['echeance_debut'] = Math.floor(serializeObject['echeance_debut'].getTime() / 1000);
    }

    if (this.echeance_fin !== null && this.echeance_fin !== undefined) {
        serializeObject['echeance_fin'] = Math.floor(serializeObject['echeance_fin'].getTime() / 1000);
    }

    if (this.synchronisation_debut !== null && this.synchronisation_debut !== undefined) {
      serializeObject['synchronisation_debut'] = Math.floor(serializeObject['synchronisation_debut'].getTime() / 1000);
    }

    if (this.synchronisation_fin !== null && this.synchronisation_fin !== undefined) {
        serializeObject['synchronisation_fin'] = Math.floor(serializeObject['synchronisation_fin'].getTime() / 1000);
    }

    if (this.evaluation_debut !== null && this.evaluation_debut !== undefined) {
      serializeObject['evaluation_debut'] = Math.floor(serializeObject['evaluation_debut'].getTime() / 1000);
    }

    if (this.evaluation_fin !== null && this.evaluation_fin !== undefined) {
        serializeObject['evaluation_fin'] = Math.floor(serializeObject['evaluation_fin'].getTime() / 1000);
    }

    if (this.creation_debut !== null && this.creation_debut !== undefined) {
      serializeObject['creation_debut'] = Math.floor(serializeObject['creation_debut'].getTime() / 1000);
    }

    if (this.creation_fin !== null && this.creation_fin !== undefined) {
        serializeObject['creation_fin'] = Math.floor(serializeObject['creation_fin'].getTime() / 1000);
    }

    if (this.statut_evaluation !== null && this.statut_evaluation !== undefined) {
      serializeObject['statut_evaluation'] = [];
      this.statut_evaluation.forEach(function(statut_e) {
        serializeObject['statut_evaluation'].push(statut_e.id_parametre);
      });
      serializeObject['statut_evaluation'] = serializeObject['statut_evaluation'].join(',');
    }

    if (this.type_public !== null && this.type_public !== undefined) {
      serializeObject['type_public'] = [];
      this.type_public.forEach(function(type_public) {
        serializeObject['type_public'].push(type_public.id_parametre);
      });
      serializeObject['type_public'] = serializeObject['type_public'].join(',');
    }

    if (this.evaluateur !== null && this.evaluateur !== undefined) {
      serializeObject['evaluateur'] = [];
      this.evaluateur.forEach(function(evaluateur) {
        serializeObject['evaluateur'].push(evaluateur.id_utilisateur);
      });
      serializeObject['evaluateur'] = serializeObject['evaluateur'].join(',');
    }

    return serializeObject;
  }
}
