import {Inject, Injectable} from '@angular/core';
import {Storage} from '../models/auth-conf.model';
import {AUTH_STORAGE} from '../auth.token';
import {JwtStockage} from '../enums/jwt-stockage.enum';
import {isTokenWrapperSuccess, TokenWrapperSuccess} from '../models/token.model';

@Injectable({
  providedIn: 'root'
})
export class JwtStorageService {
  private noCacheData: TokenWrapperSuccess | null = null;

  constructor(@Inject(AUTH_STORAGE) private readonly storage?: Storage) {
  }

  getToken(): TokenWrapperSuccess | null {
    switch (this.storage?.storage) {
      case JwtStockage.LOCAL_STORAGE:
        return this.parseIfNotNull(localStorage.getItem(this.storage.key));
      case JwtStockage.SESSION_STORAGE:
        return this.parseIfNotNull(sessionStorage.getItem(this.storage.key));
      case JwtStockage.LOCAL:
        return this.noCacheData;
      default:
        return null;
    }
  }

  setToken(value: TokenWrapperSuccess | null): void {
    switch (this.storage?.storage) {
      case JwtStockage.LOCAL_STORAGE:
        localStorage.setItem(this.storage.key, JSON.stringify(value));
        break;
      case JwtStockage.SESSION_STORAGE:
        sessionStorage.setItem(this.storage.key, JSON.stringify(value));
        break;
      case JwtStockage.LOCAL:
        this.noCacheData = value;
        break;
      default:
        console.error('No storage defined');
        break;
    }
  }

  private parseIfNotNull(data: string | null): TokenWrapperSuccess | null {
    if (data) {
      const token = JSON.parse(data);
      return isTokenWrapperSuccess(token) ? token : null;
    }

    return null;
  }
}
