<div *ngIf="value && value.length > 0" class="ui-g-4 no-padding">
  <div class="ui-g-6" *ngFor="let i of value">
    <i [class]="i.marqueur.libelle + ' ' + i.marqueur.code_parametre" [pTooltip]="i.libelle_marqueur"
              (click)="selectIcon(i)" onKeyUp></i>&nbsp;
  </div>
  <div *ngIf="value && deletable && !readonly" class="ui-g-6">
    <i class="zmdi zmdi-close cursor-pointer" title="Réinitialiser" (click)="initIcon()"></i>
  </div>
</div>
<div class="ui-g-8 no-padding" *ngIf="federation">
  <button pButton type="button" label="Marqueur" icon="fa fa-plus-circle" class="ui-button-raised ui-button-secondary"
    (click)="showPopupIcons=true" [disabled]="readonly"></button>
</div>

<p-dialog [(visible)]="showPopupIcons" [style]="{maxWidth: '900px'}" [contentStyle]="{'max-height':'500px'}">
  <p-header>
    Selection Marqueur
  </p-header>
  <div class="ui-grid ui-grid-responsive ui-grid-pad ui-fluid" style="margin: 10px 0px">
    <table>
      <tbody>
        <tr *ngFor="let icon of iconsKeys">
          <td *ngFor="let i of icons[icon]" class="td-select-icon">
            <i [class]="i.marqueur.libelle + ' ' + i.marqueur.code_parametre" [pTooltip]="i.libelle_marqueur"
              (click)="selectIcon(i)" onKeyUp></i>&nbsp;
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <p-footer>
    <div class="ui-rtl" dir="rtl">
      <button pButton type="button" class="grey-button" label="Fermer" (click)="showPopupIcons=false"></button>
    </div>
  </p-footer>
</p-dialog>