import {FormControl, FormGroup} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {Personne} from './personne';


export class CentreInteretEnfant {

  id_centre_interet_enfant: number;

  activite: string;

  info: string;

  @Type(() => Personne)
  enfant: Personne;

  restriction_tv: boolean;

  consignes_restriction_tv: string;

  info_interet: string;

  activite_eveil_interet: boolean;

  activite_manuelle_interet: boolean;

  dessin_peinture_interet: boolean;

  histoire_interet: boolean;

  jeux_eau_interet: boolean;

  jeux_eveil_interet: boolean;

  jeux_construction_interet: boolean;

  jeux_moteur_interet: boolean;

  jeux_symbolique_interet: boolean;

  jeux_plein_air_interet: boolean;

  livre_interet: boolean;

  musique_interet: boolean;

  pate_modeler_interet: boolean;

  promenade_interet: boolean;

  sortie_interet: boolean;

  static getForm(centreInteretEnfant = null): FormGroup {
    if (!centreInteretEnfant) {
      centreInteretEnfant = new CentreInteretEnfant();
    }

    return new FormGroup({
      'id_centre_interet_enfant': new FormControl(centreInteretEnfant.id_centre_interet_enfant),
      'activite': new FormControl(centreInteretEnfant.activite),
      'info': new FormControl(centreInteretEnfant.info),
      'restriction_tv': new FormControl(centreInteretEnfant.restriction_tv),
      'consignes_restriction_tv': new FormControl(centreInteretEnfant.consignes_restriction_tv),
      'info_interet': new FormControl(centreInteretEnfant.info_interet),
      'activite_eveil_interet': new FormControl(centreInteretEnfant.activite_eveil_interet),
      'activite_manuelle_interet': new FormControl(centreInteretEnfant.activite_manuelle_interet),
      'dessin_peinture_interet': new FormControl(centreInteretEnfant.dessin_peinture_interet),
      'histoire_interet': new FormControl(centreInteretEnfant.histoire_interet),
      'jeux_eau_interet': new FormControl(centreInteretEnfant.jeux_eau_interet),
      'jeux_eveil_interet': new FormControl(centreInteretEnfant.jeux_eveil_interet),
      'jeux_construction_interet': new FormControl(centreInteretEnfant.jeux_construction_interet),
      'jeux_moteur_interet': new FormControl(centreInteretEnfant.jeux_moteur_interet),
      'jeux_symbolique_interet': new FormControl(centreInteretEnfant.jeux_symbolique_interet),
      'jeux_plein_air_interet': new FormControl(centreInteretEnfant.jeux_plein_air_interet),
      'livre_interet': new FormControl(centreInteretEnfant.livre_interet),
      'musique_interet': new FormControl(centreInteretEnfant.musique_interet),
      'pate_modeler_interet': new FormControl(centreInteretEnfant.pate_modeler_interet),
      'promenade_interet': new FormControl(centreInteretEnfant.promenade_interet),
      'sortie_interet': new FormControl(centreInteretEnfant.sortie_interet),
    });
  }

  serialize() {
    return classToPlain(this);
  }
}
