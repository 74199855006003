import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {Globals} from '../utils/globals';
import {EvaluationContenu} from './evaluation-contenu';
import {Parametre} from './parametre';
import {PlanificationNbHeures} from './planification-nb-heures';

export class InterventionPlanification {

  id_intervention_planification: number;

  @Type(() => Date)
  date_premiere: Date;

  @Type(() => Date)
  date_fin: Date;

  consigne_intervention: string;

  @Type(() => EvaluationContenu)
  evaluation_contenu: EvaluationContenu;

  nombre_heures: number;

  @Type(() => Parametre)
  ref_frequence: Parametre;

  @Type(() => PlanificationNbHeures)
  planifications_nb_heures: PlanificationNbHeures[] = [];

  static getForm(intervention_planification = null): FormGroup {
    if (!intervention_planification) {
      intervention_planification = new InterventionPlanification();
    }

    let planificationsNbHeuresForm = [];
    intervention_planification.planifications_nb_heures.forEach(function (planification_nb_heures) {
      planificationsNbHeuresForm.push(PlanificationNbHeures.getForm(planification_nb_heures));
    });

    return new FormGroup({
      'id_intervention_planification': new FormControl(intervention_planification.id_intervention_planification),
      'date_premiere': new FormControl(intervention_planification.date_premiere),
      'date_fin': new FormControl(intervention_planification.date_fin),
      'consigne_intervention': new FormControl(intervention_planification.consigne_intervention),
      'nombre_heures': new FormControl(intervention_planification.nombre_heures),
      'ref_frequence': new FormControl(intervention_planification.ref_frequence),
      'planifications_nb_heures': new FormArray(planificationsNbHeuresForm),
    });
  }

  getInterventionDetailleeValue(separator = '<br>') {
    let interventionDetaillee = '';
    this.planifications_nb_heures.forEach(function (planification_nb_heures) {
      if (!planification_nb_heures.isEmpty()) {
        if (interventionDetaillee !== '') {
          interventionDetaillee += separator + separator;
        }
        interventionDetaillee += planification_nb_heures.jour.libelle + ' ' + planification_nb_heures.periode.libelle + separator;

        if (planification_nb_heures.intervention_aide_personne) {
          interventionDetaillee += 'Aide Personne, ';
        }
        if (planification_nb_heures.intervention_alimentation) {
          interventionDetaillee += 'Alimentation, ';
        }
        if (planification_nb_heures.intervention_accompagnement) {
          interventionDetaillee += 'Accompagnement, ';
        }
        if (planification_nb_heures.intervention_menage_linge) {
          interventionDetaillee += 'Menage - Linge, ';
        }
        if (planification_nb_heures.intervention_jardinage_bricolage) {
          interventionDetaillee += 'Jardinage - Bricolage, ';
        }
        if (planification_nb_heures.intervention_gardiennage) {
          interventionDetaillee += 'Gardiennage, ';
        }
        if (planification_nb_heures.intervention_duree) {
          let duree = planification_nb_heures.intervention_duree;
          if (planification_nb_heures.intervention_duree_unite === 'h') {
            duree = planification_nb_heures.intervention_duree * 60;
          }
          interventionDetaillee += duree + ' ' + 'min, ';
        }
        if (planification_nb_heures.intervention_continuite_service) {
          interventionDetaillee += 'continuité de service nécessaire, ';
        }
        if (planification_nb_heures.detail) {
          interventionDetaillee += planification_nb_heures.detail;
        }
        // Si ca finit par une virgule, on l'enlève
        if (interventionDetaillee.slice(-2) === ', ') {
          interventionDetaillee = interventionDetaillee.substring(0, interventionDetaillee.length - 2);
        }
      }
    });

    return interventionDetaillee;
  }

  serialize() {
    let serializeObject = classToPlain(this);

    if (this.ref_frequence !== null && this.ref_frequence !== undefined) {
      serializeObject['ref_frequence'] = this.ref_frequence.id_parametre;
    }

    serializeObject['planifications_nb_heures'] = [];
    this.planifications_nb_heures.forEach(function (planification_nb_heures) {
      serializeObject['planifications_nb_heures'].push(planification_nb_heures.serialize());
    });

    // Conversion des dates en FR
    for (let propertyName in serializeObject) { // On parcourt les proprietes de l'objet
      if (serializeObject[propertyName] && serializeObject[propertyName] instanceof Date) { // Si c'est une date non null
        serializeObject[propertyName] = Globals.getDateWithTimezone(serializeObject[propertyName]);
      }
    }

    return serializeObject;
  }
}
