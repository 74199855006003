import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { environment } from '../../../environments/environment';
import { EchangeMarqueur } from '../_models/echange-marqueur';

@Injectable({ providedIn: 'root' })
export class EchangeMarqueursService {

  constructor(
    private http: HttpClient
  ) {
  }

  /** GET echangesmarqueurs/all */
  getAll(): Observable<EchangeMarqueur[]> {
    return this.http.get<EchangeMarqueur[]>(`${environment.apiHost}/${environment.apiNamespace}/echangesmarqueurs/all`);
  }

  /** GET echangesmarqueurs/:id_echange */
  getEchangesMarqueursByEchange(id_echange: number): Observable<EchangeMarqueur[]> {
    return this.http.get<EchangeMarqueur[]>(`${environment.apiHost}/${environment.apiNamespace}/echangesmarqueurs/${id_echange}`);
  }

  getEchangesMarqueursByEchanges(id_echanges: number[]): Observable<EchangeMarqueur[]> {
    const params = new HttpParams().set('id_echanges', id_echanges.join(','));
    return this.http.get<EchangeMarqueur[]>(`${environment.apiHost}/${environment.apiNamespace}/echanges_array_marqueurs`, { params });
  }
}
