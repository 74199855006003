import {classToPlain} from 'class-transformer';

import {Federation, Association} from '../_models';

export class FiltreGroupe {
  perimetre: string;
  federation: Federation;
  association: Association;
  libelle: string;
  offset: number;
  limit: number;
  sort_property: string;
  sort_direction: string;

  constructor() {
    this.init();
  }

  static getSortProperty(property: string) {
    return property;
  }

  static getSortOrder(sortOrder: number) {
    return sortOrder === 1 ? 'ASC' : 'DESC';
  }

  init() {
    this.perimetre = '';
    this.federation = null;
    this.association = null;
    this.libelle = '';
    this.offset = 0;
    this.limit = 20;
    this.sort_property = 'libelle';
    this.sort_direction = 'DESC';
  }

  setDatas(datas) {
    if (datas.perimetre) {
      this.perimetre = datas.perimetre;
    }
    if (datas.federation) {
      this.federation = datas.federation;
    }
    if (datas.association) {
      this.association = datas.association;
    }
    if (datas.libelle) {
      this.libelle = datas.libelle;
    }
  }

  serialize() {
    let serializeObject = classToPlain(this);

    // On enleve toutes les proprietes null ou undefined ou tableau vide
    for (let propertyName in serializeObject) {
      if (!serializeObject[propertyName]
        || (Array.isArray(serializeObject[propertyName]) && serializeObject[propertyName].length === 0)) {
        delete serializeObject[propertyName];
      }
    }

    if (this.federation !== null && this.federation !== undefined) {
        serializeObject['federation'] = serializeObject['federation'].id_federation;
    }

    if (this.association !== null && this.association !== undefined) {
      serializeObject['association'] = serializeObject['association'].id_association;
    }

    return serializeObject;
  }
}
