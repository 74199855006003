import {FormControl, FormGroup, Validators} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {Habitude} from './habitude';
import {Parametre} from './parametre';


export class RythmeDeVie {

  id_rythme_de_vie: number;

  @Type(() => Parametre)
  moment: Parametre;

  descriptif: string;

  debut: string;

  fin: string;

  @Type(() => Habitude)
  habitude: Habitude;

  static getForm(rythme_de_vie = null): FormGroup {
    if (!rythme_de_vie) {
      rythme_de_vie = new RythmeDeVie();
    }

    return new FormGroup({
      'id_rythme_de_vie': new FormControl(rythme_de_vie.id_rythme_de_vie),
      'moment': new FormControl(rythme_de_vie.moment, Validators.required),
      'descriptif': new FormControl(rythme_de_vie.descriptif),
      'debut': new FormControl(rythme_de_vie.debut, Validators.required),
      'fin': new FormControl(rythme_de_vie.fin, Validators.required),
      'habitude': new FormControl(rythme_de_vie.habitude),
    });
  }

  serialize() {
    let serializeObject = classToPlain(this);

    if (this.moment !== null && this.moment !== undefined) {
      serializeObject['moment'] = this.moment.id_parametre;
    }

    return serializeObject;
  }

}
