import {classToPlain, Type} from 'class-transformer';
import {Association} from './association';
import {DroitModule} from './droit-module';
import {Federation} from './federation';

export class Profil {
  id_profil: number;
  perimetre: string;

  @Type(() => Federation)
  federation?: Federation;

  @Type(() => Association)
  association?: Association;

  libelle: string;

  @Type(() => DroitModule)
  droits_module: DroitModule[];

  visibilite_main_courante_restreinte = false;

  visibilite_evaluations_restreinte = false;

  visibilite_vigilance_restreinte = false;

  acces_echanges = true;

  acces_evaluations: boolean;

  acces_alertes: boolean;

  serialize() {
    let serializeObject = classToPlain(this);
    delete serializeObject['id_profil'];

    if (this.federation !== null && this.federation !== undefined) {
      serializeObject['federation'] = this.federation.id_federation;
    }

    if (this.association !== null && this.association !== undefined) {
      serializeObject['association'] = this.association.id_association;
    }

    serializeObject['droits_module'] = [];
    if (this.droits_module !== null && this.droits_module !== undefined && this.droits_module.length > 0) {
      this.droits_module.forEach(function (droit_module) {
        serializeObject['droits_module'].push(droit_module.id_droit_module);
      });
    }

    // Prise en compte des booléens (meme false)
    for (let propertyName in serializeObject) { // On parcourt les proprietes de l'objet
      if (serializeObject[propertyName] === false) { // Si c'est un booléen false
        serializeObject[propertyName] = false;
      }
    }

    return serializeObject;
  }
}
