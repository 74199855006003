import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';

export const organismeFinancierValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {

  let valid = true;
  let errors = null;

  if (control) {
    // Pas en cours
    if (!control.value.en_cours) {
      // Si pas d'heure ou pas de montant => Invalide
      if ((!control.get('nombre_heure').value || !control.get('frequence_nombre_heure').value)
          && !control.get('montant').value) {
        valid = false;
        errors = {notEnCoursInvalid: true};
      }
    }
  }

  return valid ? null : errors;
};

@Directive({
  selector: '[appOrganismeFinancierValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: OrganismeFinancierValidatorDirective, multi: true}]
})
export class OrganismeFinancierValidatorDirective implements Validator {

  constructor() { }

  public validate(control: FormGroup): {[key: string]: any} {
    return organismeFinancierValidator(control);
  }

}
