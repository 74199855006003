import {FormControl, FormGroup} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {Personne} from './personne';


export class HygieneEnfant {

  id_hygiene_enfant: number;

  heure: string;

  duree: string;

  produits: string;

  info: string;

  @Type(() => Personne)
  enfant: Personne;

  static getForm(hygieneEnfant = null): FormGroup {
    if (!hygieneEnfant) {
      hygieneEnfant = new HygieneEnfant();
    }

    return new FormGroup({
      'id_hygiene_enfant': new FormControl(hygieneEnfant.id_hygiene_enfant),
      'heure': new FormControl(hygieneEnfant.heure),
      'duree': new FormControl(hygieneEnfant.duree),
      'produits': new FormControl(hygieneEnfant.produits),
      'info': new FormControl(hygieneEnfant.info),
    });
  }

  serialize() {
    return classToPlain(this);
  }
}
