import {FormControl, FormGroup} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {Globals} from '../utils/globals';
import {Contact} from './contact';
import {Parametre} from './parametre';
import {Utilisateur} from './utilisateur';


export class InfosEntourage {
  id_infos_entourage: number;

  @Type(() => Date)
  date_creation: Date;

  source_creation: string;

  @Type(() => Utilisateur)
  utilisateur_creation: Utilisateur;

  @Type(() => Date)
  date_maj: Date;

  source_maj: string;

  @Type(() => Utilisateur)
  utilisateur_maj: Utilisateur;

  id_philia_client: string;

  @Type(() => Parametre)
  lien_specialite: Parametre;

  representant_legal: boolean;

  @Type(() => Parametre)
  type_representant: Parametre;

  copie_jugement: boolean;

  personne_confiance: boolean;

  formulaire_confiance: boolean;

  aidant: boolean;

  vivant_au_foyer: boolean;

  dependant: boolean;

  a_contacter_urgence: boolean;

  autorisation_acces_donnees: boolean;

  nombre_frequence: number;

  @Type(() => Parametre)
  frequence: Parametre;

  @Type(() => Parametre)
  type_personne: Parametre;

  @Type(() => Contact)
  contact: Contact;

  actuel: boolean;

  actif: boolean;


  static getForm(infos_entourage = null): FormGroup {
    if (!infos_entourage) {
      infos_entourage = new InfosEntourage();
    }

    let form = new FormGroup({
      'id_infos_entourage': new FormControl(infos_entourage.id_infos_entourage),
      'date_creation': new FormControl(infos_entourage.date_creation),
      'source_creation': new FormControl(infos_entourage.source_creation),
      'utilisateur_creation': new FormControl(infos_entourage.utilisateur_creation),
      'date_maj': new FormControl(infos_entourage.date_maj),
      'source_maj': new FormControl(infos_entourage.source_maj),
      'utilisateur_maj': new FormControl(infos_entourage.utilisateur_maj),
      'id_philia_client': new FormControl(infos_entourage.id_philia_client),
      'lien_specialite': new FormControl(infos_entourage.lien_specialite),
      'representant_legal': new FormControl(infos_entourage.representant_legal),
      'type_representant': new FormControl(infos_entourage.type_representant),
      'copie_jugement': new FormControl(infos_entourage.copie_jugement),
      'personne_confiance': new FormControl(infos_entourage.personne_confiance),
      'formulaire_confiance': new FormControl(infos_entourage.formulaire_confiance),
      'aidant': new FormControl(infos_entourage.aidant),
      'vivant_au_foyer': new FormControl(infos_entourage.vivant_au_foyer),
      'dependant': new FormControl(infos_entourage.dependant),
      'a_contacter_urgence': new FormControl(infos_entourage.a_contacter_urgence),
      'autorisation_acces_donnees': new FormControl(infos_entourage.autorisation_acces_donnees),
      'nombre_frequence': new FormControl(infos_entourage.nombre_frequence),
      'frequence': new FormControl(infos_entourage.frequence),
      'type_personne': new FormControl(infos_entourage.type_personne),
      'contact': Contact.getForm(infos_entourage.contact),
      'actuel': new FormControl(infos_entourage.actuel),
      'actif': new FormControl(infos_entourage.actif),
    });

    return form;
  }

  initDatas(utilisateur = null) {
    this.date_creation = new Date();

    if (!this.contact) {
      this.contact = new Contact();
      this.contact.initDatas(utilisateur);
    }

    if (utilisateur) {
      this.utilisateur_creation = utilisateur;
    }

    this.source_creation = 'Tracéo';
    this.actif = true;
    this.actuel = true;
  }

  serialize() {
    let serializeObject = classToPlain(this);
    delete serializeObject['id_infos_entourage'];

    if (this.utilisateur_creation !== null && this.utilisateur_creation !== undefined) {
      serializeObject['utilisateur_creation'] = this.utilisateur_creation.id_utilisateur;
    }

    if (this.utilisateur_maj !== null && this.utilisateur_maj !== undefined) {
      serializeObject['utilisateur_maj'] = this.utilisateur_maj.id_utilisateur;
    }

    if (this.lien_specialite !== null && this.lien_specialite !== undefined) {
      serializeObject['lien_specialite'] = this.lien_specialite.id_parametre;
    }

    if (this.type_representant !== null && this.type_representant !== undefined) {
      serializeObject['type_representant'] = this.type_representant.id_parametre;
    }

    if (this.frequence !== null && this.frequence !== undefined) {
      serializeObject['frequence'] = this.frequence.id_parametre;
    }

    if (this.type_personne !== null && this.type_personne !== undefined) {
      serializeObject['type_personne'] = this.type_personne.id_parametre;
    }

    if (this.contact !== null && this.contact !== undefined) {
      serializeObject['contact'] = this.contact.serialize();
    }

    // Conversion des dates en FR
    for (let propertyName in serializeObject) { // On parcourt les proprietes de l'objet
      if (serializeObject[propertyName] && serializeObject[propertyName] instanceof Date) { // Si c'est une date non null
        serializeObject[propertyName] = Globals.getDateWithTimezone(serializeObject[propertyName]);
      }
    }

    return serializeObject;
  }

}
