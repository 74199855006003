import { Injectable, OnDestroy } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OngletService implements OnDestroy {

  constructor() {}

  /**
   * Activer le service de confirmation de fermeture d'onglet.
   */
  enable(msg: string) {
    window.onbeforeunload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = msg;
      const e = event || window.event;
      if (e) {
        e.returnValue = msg;
      }
      return msg;
    };
  }

  /**
   * Désactivation du service de confirmation de fermeture d'onglet
   */
  disable() {
    window.onbeforeunload = null;
  }

  ngOnDestroy() {
    this.disable();
  }
}
