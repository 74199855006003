import {FormControl, FormGroup, Validators} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {phoneValidator} from '../_directives/phone-validator.directive';
import {Personne} from './personne';
import {SocialEnfant} from './social-enfant';

export class StructureAccueilEnfant {

  id_structure_accueil_enfant: number;

  @Type(() => SocialEnfant)
  social_enfant: SocialEnfant;

  nom_etablissement: string;

  adresse1_etablissement: string;

  adresse2_etablissement: string;

  adresse3_etablissement: string;

  voie: string;

  numero: string;

  extention_voie: string;

  code_postal: string;

  localite: string;

  pays: string;

  telephone_etablissement: string;

  @Type(() => Personne)
  referent: Personne;

  mode_de_garde: string;

  distance_domicile_etablissement: number;

  horaire_entre_sortie: string;

  rentre_seul: boolean;

  horaire_rentre: string;

  vehicule_necessaire: boolean;

  acces_en_transport: boolean;

  commentaire_transport: string;

  vehicule_client: boolean;

  static getForm(structureAccueilEnfant = null): FormGroup {
    if (!structureAccueilEnfant) {
      structureAccueilEnfant = new StructureAccueilEnfant();
    }

    return new FormGroup({
      'nom_etablissement': new FormControl(structureAccueilEnfant.nom_etablissement, Validators.required),
      'adresse1_etablissement': new FormControl(structureAccueilEnfant.adresse1_etablissement),
      'adresse2_etablissement': new FormControl(structureAccueilEnfant.adresse2_etablissement),
      'adresse3_etablissement': new FormControl(structureAccueilEnfant.adresse3_etablissement),
      'voie': new FormControl(structureAccueilEnfant.voie),
      'numero': new FormControl(structureAccueilEnfant.numero),
      'extention_voie': new FormControl(structureAccueilEnfant.extention_voie),
      'code_postal': new FormControl(structureAccueilEnfant.code_postal, Validators.required),
      'localite': new FormControl(structureAccueilEnfant.localite, Validators.required),
      'pays': new FormControl(structureAccueilEnfant.pays, Validators.required),
      'telephone_etablissement': new FormControl(structureAccueilEnfant.telephone_etablissement, [Validators.required, phoneValidator()]),
      'referent': new FormControl(structureAccueilEnfant.referent),
      'mode_de_garde': new FormControl(structureAccueilEnfant.mode_de_garde),
      'distance_domicile_etablissement': new FormControl(structureAccueilEnfant.distance_domicile_etablissement, Validators.pattern(/^[.\d]+$/)),
      'horaire_entre_sortie': new FormControl(structureAccueilEnfant.horaire_entre_sortie),
      'rentre_seul': new FormControl(structureAccueilEnfant.rentre_seul),
      'horaire_rentre': new FormControl(structureAccueilEnfant.horaire_rentre),
      'vehicule_necessaire': new FormControl(structureAccueilEnfant.vehicule_necessaire),
      'acces_en_transport': new FormControl(structureAccueilEnfant.acces_en_transport),
      'commentaire_transport': new FormControl(structureAccueilEnfant.commentaire_transport),
      'vehicule_client': new FormControl(structureAccueilEnfant.vehicule_client),
    });
  }

  serialize() {
    let serializeObject = classToPlain(this);

    if (this.referent !== null && this.referent !== undefined) {
      serializeObject['referent'] = this.referent.serialize();
    }

    return serializeObject;
  }
}
