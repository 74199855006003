import {Association} from './association';
import {Federation} from './federation';
import {Utilisateur} from './utilisateur';

import {Type, classToPlain} from 'class-transformer';

export class Groupe {
    id_groupe: number;
    perimetre: string;

    @Type(() => Federation)
    federation?: Federation;

    @Type(() => Association)
    association?: Association;

    libelle: string;

    mail: string;

    @Type(() => Utilisateur)
    utilisateurs: Utilisateur[] = [];

    disabled: boolean;

    serialize() {
      let serializeObject = classToPlain(this);
      delete serializeObject['id_groupe'];

      if (this.federation !== null && this.federation !== undefined) {
        serializeObject['federation'] = this.federation.id_federation;
      }

      if (this.association !== null && this.association !== undefined) {
        serializeObject['association'] = this.association.id_association;
      }

      serializeObject['utilisateurs'] = [];
      if (this.utilisateurs !== null && this.utilisateurs !== undefined && this.utilisateurs.length > 0) {
        this.utilisateurs.forEach(function(utilisateur) {
          serializeObject['utilisateurs'].push(utilisateur.id_utilisateur);
        });
      }

      return serializeObject;
    }
}
