<p-accordion #accordionFilter expandIcon="pi pi-fw pi-chevron-down" collapseIcon="pi pi-fw pi-chevron-up" [(activeIndex)]="activeIndexFilter">
  <p-accordionTab>
    <p-header>
      <i class="fa fa-search"></i> Rechercher des échanges
    </p-header>
    <app-filtres-echange (search)="searchEchange($event)" (showFilter)="closeFilters()"></app-filtres-echange>
  </p-accordionTab>
</p-accordion>

<div class="ui-grid-row">
  <div class="ui-g-12 filtre-actions">
    <a (click)="initFilter()">Réinitialiser les filtres</a> | <a (click)="toggleShowFilter()">{{toggleShowFilterLabel}}</a>
  </div>
</div>

<app-liste-echanges [filtreEchange]="filtreEchange" (refreshList)="refresh()"></app-liste-echanges>
