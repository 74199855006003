import { Federation } from '../_models';
import { FederationMarqueur } from '../_models/federation-marqueur';
import { FederationMarqueursService } from '../_services/federation-marqueurs.service';
import { Globals } from './../utils/globals';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-select-icon',
  templateUrl: './select-icon.component.html',
  styleUrls: ['./select-icon.component.css']
})
export class SelectIconComponent implements OnInit {

  @Input() readonly: boolean;
  @Input() deletable: boolean;
  @Input() value: FederationMarqueur[];
  @Input() federation: Federation;
  @Output() iconSelected = new EventEmitter<any>();

  defaultClassName = Globals.defaultIconClassName;
  iconsList = Globals.marqueursList;
  iconsColorsList = Globals.marqueursColorsList;
  showPopupIcons = false;
  federationMarqueursSelected: FederationMarqueur[] = [];
  icons: any = {};
  iconsKeys: string[];

  constructor(
    private federationMarqueurService: FederationMarqueursService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.federation && changes.federation.currentValue && changes.federation.currentValue.id_federation) {
      this.federationMarqueurService.getFederationsMarqueursByFederation(changes.federation.currentValue.id_federation).subscribe(res => {
        let tmp = {};
        res.forEach(fm => {
          if (!fm.disabled) {
            const key = fm.marqueur.libelle.replace(/\s/g, '');
            if (!tmp[key]) {
              tmp[key] = [];
            }
            tmp[key].push(fm);
          }
        });
        this.icons = tmp;
        this.iconsKeys = Object.keys(tmp);
      }, err => {
        console.error('Erreur lors de la récupération des marqueurs de fédération', err);
      });
    }
  }

  selectIcon(icon) {
    if (!this.value.find(fm => fm.id == icon.id)) {
      this.value.push(icon);
    }
    this.iconSelected.emit(this.value);
  }

  initIcon() {
    if (!this.readonly) {
      this.value = [];
      this.iconSelected.emit(this.value);
    }
  }

}
