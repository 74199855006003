import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import {plainToClass} from 'class-transformer';
import {SelectItem} from 'primeng/api';

import { Appreciation } from '../_models';
import { FiltreAppreciation } from '../utils/filtre-appreciation';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AppreciationService {

  filtreAppreciation = new FiltreAppreciation();
  appreciationsList: Appreciation[]; // Le derniers resultat de la recherche des appreciations

  constructor(private http: HttpClient) { }

  /** GET appreciations */
  getAll(): Observable<Appreciation[]> {
    return this.http.get<Appreciation[]>(`${environment.apiHost}/${environment.apiNamespace}/appreciations`);
  }

  /** GET appreciations/:id */
  getAppreciation(id_appreciation: number): Observable<Appreciation> {
    return this.http.get<Appreciation>(`${environment.apiHost}/${environment.apiNamespace}/appreciations/${id_appreciation}`);
  }

  /** GET appreciations */
  getAppreciationsByParams(paramsValue: FiltreAppreciation, updateFiltre = true): Observable<Appreciation[]> {
    if (updateFiltre) {
      this.filtreAppreciation = paramsValue;
    }

    let paramsValueSerialized = paramsValue.serialize();
    let params = new HttpParams();
    for (let paramKey in paramsValueSerialized) {
      if (paramsValueSerialized.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValueSerialized[paramKey]);
      }
    }
    return this.http.get<Appreciation[]>(`${environment.apiHost}/${environment.apiNamespace}/appreciations`, { params: params });
  }

  /** GET appreciations/count */
  getNbAppreciationsByParams(paramsValue: FiltreAppreciation): Observable<any> {
    let paramsValueSerialized = paramsValue.serialize();
    let params = new HttpParams();
    for (let paramKey in paramsValueSerialized) {
      if (paramsValueSerialized.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValueSerialized[paramKey]);
      }
    }
    return this.http.get<any>(`${environment.apiHost}/${environment.apiNamespace}/appreciations/count`, { params: params });
  }

  /** POST appreciations */
  createAppreciation(appreciation: Appreciation): Observable<Appreciation> {
    return this.http.post<Appreciation>(
      `${environment.apiHost}/${environment.apiNamespace}/appreciations`,
      plainToClass(Appreciation, appreciation).serialize()
     );
  }

  /** PUT appreciations */
  updateAppreciation(appreciation: Appreciation): Observable<Appreciation> {
    return this.http.put<Appreciation>(
      `${environment.apiHost}/${environment.apiNamespace}/appreciations/${appreciation.id_appreciation}`,
      plainToClass(Appreciation, appreciation).serialize()
    );
  }

  initFiltreAppreciation() {
    this.filtreAppreciation = new FiltreAppreciation();
  }

  /* handleError */
  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(error);
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a utilisateur-facing error message
    return throwError(
      error);
  }

}
