import {Location} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {plainToClass} from 'class-transformer';
import {MessageService} from 'primeng/api';
import {filter, tap} from 'rxjs/operators';
import {Utilisateur} from './shared/_models';
import {AuthenticationService} from './shared/_services';
import {Globals} from './shared/utils/globals';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [MessageService]
})
export class AppComponent implements OnInit {
  title: string = Globals.appTitle;
  currentUtilisateur: Utilisateur;
  appVersion: string = Globals.appVersion;

  constructor(
    public readonly router: Router,
    public readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly authenticationService: AuthenticationService,
    private readonly titleService: Title
    ) {
  }

  ngOnInit() {
    this.authenticationService.currentUtilisateur$.pipe(
      tap(utilisateur => {
        if (utilisateur) {
          this.currentUtilisateur = plainToClass(Utilisateur, utilisateur);
        }
      })
    ).subscribe();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      tap(() => this.titleService.setTitle(this.title)),
    ).subscribe();
  }
}
