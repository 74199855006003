import {FormControl, FormGroup} from '@angular/forms';
import {Type} from 'class-transformer';
import {EvaluationContenu} from './evaluation-contenu';


export class Logement {

  id_logement: number;

  commentaire_global: string;

  presence_ascenseur: boolean;

  accessibilite_transport: boolean;

  parking: boolean;

  presence_animaux: boolean;

  cles: boolean;

  @Type(() => Date)
  attestation_date_remise_cles: Date;

  boite_cles: boolean;

  digicode: boolean;

  code_digicode: string;

  systeme_alarme: boolean;

  code_systeme_alarme: string;

  type_logement: string;

  nombre_niveau: string;

  code_boite_cles: string;

  @Type(() => EvaluationContenu)
  evaluation_contenu: EvaluationContenu;

  @Type(() => Date)
  attestation_date_remise_boite_cles: Date;

  @Type(() => Date)
  attestation_date_remise_digicode: Date;

  @Type(() => Date)
  attestation_date_remise_alarme: Date;

  chauffage_gaz: boolean;

  chauffage_fioul: boolean;

  chauffage_electrique: boolean;

  chauffage_bois_charbon: boolean;

  logement_encombre: boolean;

  logement_insalubre: boolean;

  logement_vetuste: boolean;

  detail_etat: string;

  emplacement_robinet_gaz: string;

  emplacement_robinet_eau: string;

  emplacement_disjoncteur: string;

  gratuite_parking: boolean;

  detail_animaux_compagnie: string;

  logement_satisfaisant: boolean;

  static getForm(logement = null): FormGroup {
    if (!logement) {
      logement = new Logement();
    }

    return new FormGroup({
      'id_logement': new FormControl(logement.id_logement),
      'commentaire_global': new FormControl(logement.commentaire_global),
      'presence_ascenseur': new FormControl(logement.presence_ascenseur),
      'accessibilite_transport': new FormControl(logement.accessibilite_transport),
      'parking': new FormControl(logement.parking),
      'presence_animaux': new FormControl(logement.presence_animaux),
      'cles': new FormControl(logement.cles),
      'attestation_date_remise_cles': new FormControl(logement.attestation_date_remise_cles),
      'boite_cles': new FormControl(logement.boite_cles),
      'code_boite_cles': new FormControl(logement.code_boite_cles),
      'digicode': new FormControl(logement.digicode),
      'code_digicode': new FormControl(logement.code_digicode),
      'systeme_alarme': new FormControl(logement.systeme_alarme),
      'code_systeme_alarme': new FormControl(logement.code_systeme_alarme),
      'type_logement': new FormControl(logement.type_logement),
      'nombre_niveau': new FormControl(logement.nombre_niveau),
      'commentaire': new FormControl(logement.commentaire),
      'evaluation_contenu': new FormControl(logement.evaluation_contenu),
      'attestation_date_remise_boite_cles': new FormControl(logement.attestation_date_remise_boite_cles),
      'attestation_date_remise_digicode': new FormControl(logement.attestation_date_remise_digicode),
      'attestation_date_remise_alarme': new FormControl(logement.attestation_date_remise_alarme),
      'chauffage_gaz': new FormControl(logement.chauffage_gaz),
      'chauffage_fioul': new FormControl(logement.chauffage_fioul),
      'chauffage_electrique': new FormControl(logement.chauffage_electrique),
      'chauffage_bois_charbon': new FormControl(logement.chauffage_bois_charbon),
      'logement_encombre': new FormControl(logement.logement_encombre),
      'logement_insalubre': new FormControl(logement.logement_insalubre),
      'logement_vetuste': new FormControl(logement.logement_vetuste),
      'detail_etat': new FormControl(logement.detail_etat),
      'emplacement_robinet_gaz': new FormControl(logement.emplacement_robinet_gaz),
      'emplacement_robinet_eau': new FormControl(logement.emplacement_robinet_eau),
      'emplacement_disjoncteur': new FormControl(logement.emplacement_disjoncteur),
      'gratuite_parking': new FormControl(logement.gratuite_parking),
      'detail_animaux_compagnie': new FormControl(logement.detail_animaux_compagnie),
      'logement_satisfaisant': new FormControl(logement.logement_satisfaisant)
    });
  }

  hasSaisie(): boolean {
    return !!(this.type_logement || this.nombre_niveau
      || this.chauffage_gaz || this.chauffage_fioul || this.chauffage_electrique || this.chauffage_bois_charbon
      || this.logement_encombre || this.logement_insalubre || this.logement_vetuste || this.logement_satisfaisant
      || this.detail_etat
      || this.parking || this.accessibilite_transport || this.presence_ascenseur || this.presence_animaux
      || this.emplacement_robinet_eau || this.emplacement_robinet_gaz || this.emplacement_disjoncteur
      || this.cles || this.boite_cles || this.digicode || this.systeme_alarme
      || this.commentaire_global);
  }

  serialize() {

  }
}
