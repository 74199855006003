import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {Habitude} from './habitude';
import {Horaires} from './horaires';


export class LoisirsEtActivites {

  id_loisirs_et_activites: number;

  libelle: string;

  frequence: number;

  unite: string;

  commentaire: string;

  @Type(() => Horaires)
  horaires: Horaires[] = [];

  @Type(() => Habitude)
  habitude: Habitude;

  get horairesLabel() {
    let horairesLabel = '';
    this.horaires.forEach(function (horaire) {
      if (horairesLabel !== '') {
        horairesLabel += ', ';
      }
      horairesLabel += horaire.libelle;
    });
    return horairesLabel;
  }

  static getForm(loisirs_et_activites = null): FormGroup {
    if (!loisirs_et_activites) {
      loisirs_et_activites = new LoisirsEtActivites();
    }

    let horairesForm = [];
    loisirs_et_activites.horaires.forEach(function (horaire) {
      horairesForm.push(new FormControl(horaire));
    });

    return new FormGroup({
      'id_loisirs_et_activites': new FormControl(loisirs_et_activites.id_loisirs_et_activites),
      'libelle': new FormControl(loisirs_et_activites.libelle, Validators.required),
      'frequence': new FormControl(loisirs_et_activites.frequence),
      'unite': new FormControl(loisirs_et_activites.unite),
      'commentaire': new FormControl(loisirs_et_activites.commentaire),
      'horaires': new FormArray(horairesForm),
      'habitude': new FormControl(loisirs_et_activites.habitude),
    });
  }

  serialize() {
    let serializeObject = classToPlain(this);

    serializeObject['horaires'] = [];
    this.horaires.forEach(function (horaire) {
      serializeObject['horaires'].push(horaire.serialize());
    });

    return serializeObject;
  }
}
