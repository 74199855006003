<p-accordion #accordionFilter expandIcon="pi pi-fw pi-chevron-down" collapseIcon="pi pi-fw pi-chevron-up"
    [(activeIndex)]="activeIndexFilter">
    <p-accordionTab>
        <p-header>
            <i class="fa fa-search"></i> Paramétrage des motifs
        </p-header>
        <div class="ui-grid ui-grid-responsive ui-grid-pad ui-fluid" style="margin: 10px 0px">
            <div class="ui-grid-row">
                <div class="ui-g-8">
                    <div class="ui-g-3">
                        <label>Fédération</label>
                    </div>
                    <div class="ui-g-9">
                        <p-dropdown [options]="federationsList" [style]="{'width':'100%'}"
                            class="app-dropdown" [placeholder]="'Fédération...'" name="federation" optionLabel="libelle"
                            [filter]="true" (onChange)="onChangeFederation()"
                            [(ngModel)]="currentFederation" [disabled]="currentUtilisateur.perimetre != 'National'"></p-dropdown>
                    </div>
                </div>
            </div>
            <div class="ui-grid-row" *ngIf="currentFederation">
                <div class="ui-g-8">
                    <div class="ui-g-3">
                        <label>Catégorie</label>
                    </div>
                    <div [ngClass]="(!currentCategorie || currentCategorie.code_parametre != 'REC') ? 'ui-g-9' : 'ui-g-4'">
                        <p-dropdown [options]="categoriesList" [style]="{'width':'100%'}"
                            class="app-dropdown" [placeholder]="'Catégorie...'" name="categorie" optionLabel="libelle"
                            [(ngModel)]="currentCategorie" (onChange)="onChangeCategorie()"></p-dropdown>
                    </div>
                    <div class="ui-g-5" *ngIf="(currentCategorie && currentCategorie.code_parametre == 'REC')">
                        <p-radioButton name="association" [value]="1" label="Association" [(ngModel)]="typeReclamation" inputId="association" (click)="clickReclamationCategorie()"></p-radioButton>
                        &nbsp;
                        <p-radioButton name="intervention" [value]="2" label="Intervention" [(ngModel)]="typeReclamation" inputId="intervention" (click)="clickReclamationCategorie()"></p-radioButton>
                    </div>
                </div>
                <div class="ui-g-4" *ngIf="currentFederation && currentCategorie">
                    <div class="ui-g-4">
                        <label>Motifs associés</label>
                    </div>
                    <div class="ui-g-8">
                        <p-multiSelect [options]="motifsList" dataKey="id_parametre"
                            emptyFilterMessage="Aucun résultat trouvé" name="federation" optionLabel="libelle"
                            optionValue="id_parametre" defaultLabel="Motif..." selectedItemsLabel="{0} sélectionnés"
                            [(ngModel)]="currentMotifs" [disabled]="!typeReclamation && currentCategorie.code_parametre == 'REC'" ></p-multiSelect>
                    </div>
                </div>
            </div>
            <div class="ui-grid-row p-dir-rev" *ngIf="currentMotifs != undefined">
                <div class="ui-grid-col-2">
                    <button pButton class="green-button button-with-icon" (click)="saveFederationMotifs()"><span
                            class="ui-button-text-with-icon ui-clickable"><i class="fa fa-save"></i>
                            Enregistrer</span></button>
                </div>
            </div>
        </div>
    </p-accordionTab>
</p-accordion>
<div class="p-col filtre-actions">
    <a (click)="initFilter()">Réinitialiser les filtres</a> | <a (click)="toggleShowFilter()" *ngIf="activeIndexFilter == 0">Masquer les critères</a><a (click)="toggleShowFilter()" *ngIf="activeIndexFilter != 0">Afficher les critères</a>
</div>
<br />
<p-table [value]="federationsMotifsList" [paginator]="true" [rows]="10" [loading]="loading">
    <ng-template pTemplate="header">
        <tr>
            <th scope="col" *ngIf="currentUtilisateur.perimetre == 'National'">FÉDÉRATION</th>
            <th scope="col">CATÉGORIE</th>
            <th scope="col">LIBELLÉ MOTIF</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-federationMotif>
        <tr>
            <td *ngIf="currentUtilisateur.perimetre == 'National'">{{federationMotif.federation.libelle}}</td>
            <td *ngIf="!federationMotif.associationIntervention">{{federationMotif.categorie_motif.libelle}}</td>
            <td *ngIf="federationMotif.associationIntervention">{{federationMotif.categorie_motif.libelle}} - {{federationMotif.associationIntervention == 1 ? 'Association' : federationMotif.associationIntervention == 2 ? 'Intervention' : ''}}</td>
            <td>{{federationMotif.motif.libelle}}</td>
        </tr>
    </ng-template>
</p-table>
