import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import {plainToClass} from 'class-transformer';
import {SelectItem} from 'primeng/api';

import { Contact } from '../_models';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ContactService {

  constructor(private http: HttpClient) { }

  /** GET contact */
  getAll(): Observable<Contact[]> {
    return this.http.get<Contact[]>(`${environment.apiHost}/${environment.apiNamespace}/contacts`);
  }

  /** GET contacts/:id */
  getContact(id_contact: number): Observable<Contact> {
    return this.http.get<Contact>(`${environment.apiHost}/${environment.apiNamespace}/contacts/${id_contact}`);
  }

  /** GET contacts */
  getContactsByParams(paramsValue: any): Observable<Contact[]> {
    let params = new HttpParams();
    for (let paramKey in paramsValue) {
      if (paramsValue.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValue[paramKey]);
      }
    }
    return this.http.get<Contact[]>(`${environment.apiHost}/${environment.apiNamespace}/contacts`, { params: params });
  }


  /** POST contacts */
  createContact(contact: Contact): Observable<Contact> {
    return this.http.post<Contact>(
      `${environment.apiHost}/${environment.apiNamespace}/contacts`,
      plainToClass(Contact, contact).serialize()
     );
  }

  /** PUT contacts */
  updateContact(contact: Contact): Observable<Contact> {
    return this.http.put<Contact>(
      `${environment.apiHost}/${environment.apiNamespace}/contacts/${contact.id_contact}`,
      plainToClass(Contact, contact).serialize()
    );
  }

  /* handleError */
  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(error);
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a utilisateur-facing error message
    return throwError(
      error);
  }

}
