import {Association} from './association';
import {classToPlain} from 'class-transformer';

export class Federation {
  id_federation: number;
  id_modus_federation?: number;
  code: string;
  libelle: string;
  id_philia: string;
  id_modus: string;
  associations: Association[];
  association_reference: Association;
  departement: string;
  acces_evaluations: boolean;
  acces_alertes: boolean;

  serialize() {
    let serializeObject = classToPlain(this);
    console.warn(serializeObject);
    delete serializeObject['id_federation'];
    if (this.association_reference !== null && this.association_reference !== undefined) {
      serializeObject['association_reference'] = this.association_reference.id_association ?? this.association_reference.id_modus_association;
    }

    return serializeObject;
  }

}
