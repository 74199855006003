import {classToPlain} from 'class-transformer';

import {Association, Federation} from '../_models';

export class FiltreUtilisateur {
  federation: Federation;
  association: Association;
  perimetre: string;
  login: string;
  nom: string;
  prenom: string;
  mail: string;
  departement: string;
  mode_droit: string;
  acces_personia: boolean;
  est_administrateur: boolean;
  module: string[];
  disabled: string[];
  statut_habilitation: number;
  user_id: string;
  offset: number;
  limit: number;
  sort_property: string;
  sort_direction: string;

  constructor() {
    this.init();
  }

  static getSortProperty(property: string) {
    return property;
  }

  static getSortOrder(sortOrder: number) {
    return sortOrder === 1 ? 'ASC' : 'DESC';
  }

  init() {
      this.federation = null;
      this.association = null;
      this.perimetre = '';
      this.login = '';
      this.nom = '';
      this.prenom = '';
      this.mail = '';
      this.departement = '';
      // this.acces_personia = false;
      // this.est_administrateur = false;
      this.module = null;
      this.disabled = null;
      this.statut_habilitation = null;
      this.user_id = '';
      this.offset = 0;
      this.limit = 20;
      this.sort_property = 'created';
      this.sort_direction = 'DESC';
  }

  setDatas(datas) {
    if (datas.federation) {
      this.federation = datas.federation;
    }
    if (datas.association) {
      this.association = datas.association;
    }
    if (datas.perimetre) {
      this.perimetre = datas.perimetre;
    }
    if (datas.login) {
      this.login = datas.login;
    }
    if (datas.nom) {
      this.nom = datas.nom;
    }
    if (datas.prenom) {
      this.prenom = datas.prenom;
    }
    if (datas.mail) {
      this.mail = datas.mail;
    }
    if (datas.departement) {
      this.departement = datas.departement;
    }
    if (datas.mode_droit) {
      this.mode_droit = datas.mode_droit;
    }
    if (datas.acces_personia) {
      this.acces_personia = datas.acces_personia;
    }
    if (datas.est_administrateur) {
      this.est_administrateur = datas.est_administrateur;
    }
    if (datas.module) {
      this.module = datas.module;
    }
    if (datas.disabled) {
      this.disabled = datas.disabled;
    }
    if (datas.statut_habilitation) {
      this.statut_habilitation = datas.statut_habilitation;
    }
    if (datas.user_id) {
      this.user_id = datas.user_id;
    }
  }

  serialize() {
    let serializeObject = classToPlain(this);

    // On enleve toutes les proprietes null ou undefined ou tableau vide
    for (let propertyName in serializeObject) {
      if (!serializeObject[propertyName]
        || (Array.isArray(serializeObject[propertyName]) && serializeObject[propertyName].length === 0)) {
        delete serializeObject[propertyName];
      }
    }

    if (this.federation !== null && this.federation !== undefined) {
        serializeObject['federation'] = serializeObject['federation'].id_federation;
    }

    if (this.association !== null && this.association !== undefined) {
        serializeObject['association'] = serializeObject['association'].id_association;
    }

    if (this.module !== null && this.module !== undefined && this.module.length > 0) {
      serializeObject['module'] = serializeObject['module'].join(',');
    }

    if (this.disabled !== null && this.disabled !== undefined && this.disabled.length > 0) {
      serializeObject['disabled'] = serializeObject['disabled'].join(',');
    }

    return serializeObject;
  }
}
