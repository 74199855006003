import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {DroitModule, PerimetreEnum, Utilisateur} from '../_models';
import {AuthenticationService} from '../_services';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, {url}: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> {
    if (!this.authenticationService.isLoggedIn()) {
      return this.authenticationService.login().pipe(
        map((user: Utilisateur) => this.checkAccess(url, user)),
        catchError(() => of(false)),
      );
    }

    return this.checkAccess(url, this.authenticationService.currentUtilisateurValue);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }

  checkAdmin(url: string, currentUtilisateur: Utilisateur): boolean | UrlTree {
    if (currentUtilisateur.est_administrateur === 'O' && currentUtilisateur.perimetre === PerimetreEnum.National) { // Admin National : accès accepté
      return true;
    } else if (url === ('/in/admin/utilisateur/edit/' + currentUtilisateur.id_utilisateur)) { // Page mon profil
      return true;
    } else if (
      url.startsWith('/in/admin/utilisateur') ||
      url.startsWith('/in/admin/profil') ||
      (url.startsWith('/in/admin/association') && currentUtilisateur.perimetre === PerimetreEnum.Federation) ||
      url.startsWith('/in/admin/groupe')
    ) { // Gestionnaire utilisateur, Profil, Groupe, Federation : accès accepté
      return currentUtilisateur.est_administrateur === 'O';
    }

    return true;
  }

  private checkAccess(url: string, currentUtilisateur: Utilisateur): boolean | UrlTree {
    return this.checkCommonRoute(url, currentUtilisateur) && this.checkAdmin(url, currentUtilisateur) && this.checkMultiSite(url, currentUtilisateur);
  }

  private checkCommonRoute(url: string, currentUtilisateur): boolean | UrlTree {
    let defaultUrl = '/in/tableau-bord';

    // Vérification acces echanges
    if (((url.includes('/in/echange/') || url === '/in/recherche') && !currentUtilisateur.acces_echanges && !currentUtilisateur.acces_personia)
      || (url === '/in/echange/new' && !currentUtilisateur.hasFonctionnalite(DroitModule.module_echanges, DroitModule.fonctionnalite_edit_echanges) && !currentUtilisateur.hasFonctionnalite(DroitModule.module_personia, DroitModule.fonctionnalite_edit_echanges))) {
      return this.router.createUrlTree([defaultUrl]);
    }

    // Vérification acces evaluations
    if (((url.includes('/in/evaluation/') || url === '/in/recherche-evaluation') && !currentUtilisateur.hasAccesEvaluations)
      || (url === '/in/evaluation/new' && !currentUtilisateur.hasFonctionnalite('main_courante', 'init_evaluations'))) {
      return this.router.createUrlTree([defaultUrl]);

    }

    // Vérification acces alertes
    if ((url.includes('/in/alerte/') || url === '/in/recherche-alerte' || url === '/in/recherche-appreciation') && !currentUtilisateur.hasAccesAlertes) {
      return this.router.createUrlTree([defaultUrl]);
    }

    return true;
  }

  private checkMultiSite(url: string, currentUtilisateur: Utilisateur): boolean | UrlTree {
    if (currentUtilisateur.type_acces === 'multiSite' &&
      !this.authenticationService.currentAssociationForUserMultiSite &&
      !url.startsWith('/in/select-association')
    ) {
      return this.router.createUrlTree(['in/select-association']);
    }

    return true;
  }
}
