import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../shared/_guards';
import {EchangeEditComponent} from './echange-edit/echange-edit.component';
import {EchangeNewComponent} from './echange-new/echange-new.component';

const echangeRoutes: Routes = [
  {
    path: 'in/echange/new',
    canActivate: [AuthGuard],
    component: EchangeNewComponent
  },
  {
    path: 'in/echange/:id',
    canActivate: [AuthGuard],
    component: EchangeEditComponent,
    runGuardsAndResolvers: 'paramsChange'
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(echangeRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class EchangeRoutingModule {
}
