<div *ngIf="selectedIndividu" class="p-grid" style="text-align: center;color:black; font-weight: bold;">
  <div class="p-col">{{selectedIndividu.civilite_nom_prenom}}</div>
</div>
<p-accordion #accordionFilter expandIcon="pi pi-fw pi-chevron-down" collapseIcon="pi pi-fw pi-chevron-up">
  <p-accordionTab [(selected)]="accordionFilterOpen">
    <p-header>
      <i class="fa fa-search"></i> Rechercher un client
    </p-header>
    <div class="p-grid">
      <div class="p-col-1">
        <label>Fédération</label>
      </div>
      <div class="p-col-5">
        <p-dropdown [options]="federationsList" [(ngModel)]="filtreIndividu.federation"
          [readonly]="currentUtilisateur.perimetre !== 'National'"
          [showClear]="currentUtilisateur.perimetre === 'National'" [style]="{'width':'100%'}" class="app-dropdown"
          [placeholder]="'Fédération...'" (onChange)="onChangeFederation()" #federation="ngModel" name="federation"
          optionLabel="libelle"></p-dropdown>
      </div>
      <div class="p-col-1">
        <label>Association</label>
      </div>
      <div class="p-col-5">
        <!--
        <p-dropdown [options]="associationsList" [(ngModel)]="filtreIndividu.association"
          [readonly]="isAssociationReadOnly()"
          [showClear]="currentUtilisateur.type_acces === 'multiAsso' || currentUtilisateur.perimetre === 'National'"
          [style]="{'width':'100%'}" [placeholder]="'Association'" #association="ngModel" name="association"
          optionLabel="libelle" dataKey="id_association"></p-dropdown>
        -->
          <p-dropdown [options]="associationsItemsList" [(ngModel)]="filtreIndividu.association"
                      [readonly]="isAssociationReadOnly()"
                      [showClear]="currentUtilisateur.type_acces === 'multiAsso' || currentUtilisateur.perimetre === 'National'"
                      [style]="{'width':'100%'}" [placeholder]="'Association'" #association="ngModel" name="association"
                      dataKey="id_association">
                      <ng-template let-item pTemplate="selectedItem">
                        <div *ngIf="item.value">
                          <span *ngIf="item.value.disabled" style="font-style: italic;"><i class="pi pi-times" style="color: red;float: left;"></i>{{item.value.libelle}}</span>
                          <span *ngIf="!item.value.disabled">{{item.value.libelle}}</span>
                        </div>
                      </ng-template>
                      <ng-template let-association pTemplate="item">
                        <span *ngIf="association.value.disabled" style="font-style: italic;"><i class="pi pi-times" style="color: red;float: left;"></i>{{association.value.libelle}}</span>
                        <span *ngIf="!association.value.disabled">{{association.value.libelle}}</span>
                      </ng-template>
                  </p-dropdown>
      </div>
    </div>

    <div class="p-grid">
      <div class="p-col-6">
        <div class="p-grid">
          <div class="p-col-2">
            <label>Nom</label>
          </div>
          <div class="p-col-4">
            <input pInputText type="text" [(ngModel)]="filtreIndividu.nom" #nom="ngModel" name="client_nom"
              placeholder="Nom" style="width: 100%" />
          </div>
          <div class="p-col-2">
            <label>Prénom</label>
          </div>
          <div class="p-col-4">
            <input pInputText type="text" [(ngModel)]="filtreIndividu.prenom" #prenom="ngModel" name="client_prenom"
              placeholder="Prénom" style="width: 100%" />
          </div>
        </div>
      </div>
    </div>

    <div class="p-grid p-dir-rev">
      <button *ngIf="!loadingIndividus" pButton type="button" class="grey-button" label="Rechercher"
        (click)="searchIndividuPhilia()" icon="pi pi-search" [disabled]="!canSearchIndividuPhilia"></button>
      <button *ngIf="loadingIndividus" pButton type="button" class="grey-button" label="Rechercher"
        (click)="searchIndividuPhilia()" icon="pi pi-spin pi-spinner" [disabled]="!canSearchIndividuPhilia"></button>
    </div>
  </p-accordionTab>
</p-accordion>

<p-tabView [style]="{'marginTop': '10px'}" (onChange)="handleChangeTabView($event)" [activeIndex]="index">
  <p-tabPanel header="Général">
    <div *ngIf="selectedIndividu" class="p-grid" style="width: 100%;">
      <div class="p-col-6 panel-tableau-bord">
        <div class="panel-tableau-bord-body">
          <div class="header">
            <span class="title">Coordonnées client</span>
          </div>
          <div class="content" style="padding-left: 10px;">
            <div>
              <span style="color:black; font-weight: bold;">{{selectedIndividu.civilite_nom_prenom}}
                <span *ngIf="nomNaissanceClient || dateNaissanceClient">
                  , né(e) <span *ngIf="nomNaissanceClient">{{nomNaissanceClient}} </span>
                  <span *ngIf="dateNaissanceClient">le {{dateNaissanceClient | date: datePipeFormatWithoutTime}}</span>
                </span>
              </span>
            </div>
            <div *ngIf="selectedIndividu.tel_domicile || selectedIndividu.tel_mobile || selectedIndividu.mail">
              <i *ngIf="selectedIndividu.tel_domicile" class="pi pi-home">&nbsp; </i>
              {{selectedIndividu.tel_domicile}}&nbsp; &nbsp;
              <i *ngIf="selectedIndividu.tel_mobile" class="pi pi-mobile">&nbsp; </i>
              {{selectedIndividu.tel_mobile}}&nbsp; &nbsp;
              <i *ngIf="selectedIndividu.mail" class="pi pi-envelope">&nbsp; </i> {{selectedIndividu.mail}}
            </div>
            <p-accordion class="accordion-recapClient-adresse" expandIcon="pi pi-fw pi-chevron-down"
              collapseIcon="pi pi-fw pi-chevron-up">
              <p-accordionTab header="Adresse" [selected]="true" style="padding:0px;">
                <div *ngIf="selectedIndividu.adr_voie">
                  <span *ngIf="selectedIndividu.adr_num">{{selectedIndividu.adr_num}}&nbsp;</span>
                  <span *ngIf="selectedIndividu.adr_complement">{{selectedIndividu.adr_complement}}&nbsp;</span>
                  <span *ngIf="selectedIndividu.adr_voie">{{selectedIndividu.adr_voie}}</span>
                </div>
                <div *ngIf="selectedIndividu.adr_complement1">
                  {{selectedIndividu.adr_complement1}}
                </div>
                <div *ngIf="selectedIndividu.adr_complement2">
                  {{selectedIndividu.adr_complement2}}
                </div>
                <div *ngIf="selectedIndividu.adr_lieudit">
                  {{selectedIndividu.adr_lieudit}}
                </div>
                <div *ngIf="selectedIndividu.adr_code_postale || selectedIndividu.adr_ville">
                  <span *ngIf="selectedIndividu.adr_code_postale">{{selectedIndividu.adr_code_postale}}&nbsp;</span>
                  <span *ngIf="selectedIndividu.adr_ville">{{selectedIndividu.adr_ville}}</span>
                </div>
              </p-accordionTab>
            </p-accordion>
          </div>
        </div>
      </div>

      <div class="p-col-6">
        <div class="p-grid">
          <div *ngIf="currentUtilisateur.acces_echanges" class="p-col-6 panel-tableau-bord selectable green"
            (click)="goToLastEchange()">
            <div class="panel-tableau-bord-body">
              <div class="header">
                <span class="title">Dernier échange</span>
              </div>
              <div class="content" style="text-align: center;">
                <div *ngIf="!loadingEchanges">
                  <div *ngIf="echangesList.length === 0">
                    Aucun échange
                  </div>
                  <div *ngIf="echangesList.length > 0">
                    <div>{{echangesList[0].date_creation | date: datePipeFormatWithoutTime}}</div>
                    <div>{{echangesList[0].motif_echange.libelle}}</div>
                    <div>{{echangesList[0].operateur.libelle}}</div>
                  </div>
                </div>
                <span *ngIf="loadingEchanges"><i class="pi pi-spin pi-spinner"></i></span>
              </div>
            </div>
          </div>

          <div *ngIf="currentUtilisateur.acces_evaluations" class="p-col-6 panel-tableau-bord selectable blue"
            (click)="goToLastEvaluation()">
            <div class="panel-tableau-bord-body">
              <div class="header">
                <span class="title">Dernière évaluation</span>
              </div>
              <div class="content" style="text-align: center;">
                <div *ngIf="!loadingEvaluations">
                  <div *ngIf="evaluationsList.length === 0">
                    Aucune évaluation
                  </div>
                  <div *ngIf="evaluationsList.length > 0">
                    <div>
                      <span *ngIf="evaluationsList[0].statut_evaluation.code_parametre !== 'REA'
                                  && evaluationsList[0].statut_evaluation.code_parametre !== 'ARC'
                                  && evaluationsList[0].echeance">
                        Avant le {{evaluationsList[0].echeance | date: datePipeFormatWithoutTime}}
                      </span>
                      <span *ngIf="(evaluationsList[0].statut_evaluation.code_parametre === 'REA'
                                  || evaluationsList[0].statut_evaluation.code_parametre === 'ARC')
                                  && evaluationsList[0].date_evaluation">
                        {{evaluationsList[0].date_evaluation | date: datePipeFormatWithoutTime}}
                      </span>
                    </div>
                    <div>{{evaluationsList[0].statut_evaluation.libelle}}</div>
                    <div>{{evaluationsList[0].redacteur.libelle}}</div>
                  </div>
                </div>
                <span *ngIf="loadingEvaluations"><i class="pi pi-spin pi-spinner"></i></span>
              </div>
            </div>
          </div>

          <div *ngIf="currentUtilisateur.acces_alertes" class="p-col-6 panel-tableau-bord selectable pink"
            (click)="goToLastAlerte()">
            <div class="panel-tableau-bord-body">
              <div class="header">
                <span class="title">Dernière alerte</span>
              </div>
              <div class="content" style="text-align: center;">
                <div *ngIf="!loadingAlertes">
                  <div *ngIf="alertesList.length === 0">
                    Aucune alerte
                  </div>
                  <div *ngIf="alertesList.length > 0">
                    <div>{{alertesList[0].date_creation | date: datePipeFormatWithoutTime}}</div>
                    <div>{{alertesList[0].statut_alerte.libelle}}</div>
                    <div><span *ngIf="alertesList[0].motif_alerte">{{alertesList[0].motif_alerte.libelle}}</span>
                    </div>
                  </div>
                </div>
                <span *ngIf="loadingAlertes"><i class="pi pi-spin pi-spinner"></i></span>
              </div>
            </div>
          </div>

          <div *ngIf="currentUtilisateur.acces_alertes" class="p-col-6 panel-tableau-bord selectable pink"
            (click)="goToLastAlerte()">
            <div class="panel-tableau-bord-body">
              <div class="header">
                <span class="title">Dernière appréciation</span>
              </div>
              <div class="content" style="text-align: center;">
                <div *ngIf="!loadingAppreciations">
                  <div *ngIf="appreciationsList.length === 0">
                    Aucune appréciation
                  </div>
                  <div *ngIf="appreciationsList.length > 0">
                    <div>{{appreciationsList[0].date_creation | date: datePipeFormatWithoutTime}}</div>
                    <div>{{appreciationsList[0].valeur}}</div>
                  </div>
                </div>
                <span *ngIf="loadingAppreciations"><i class="pi pi-spin pi-spinner"></i></span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div *ngIf="!selectedIndividu" class="div-recapClient-aucunClient">
      <p>Veuillez sélectionner un client</p>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Disponibilités" *ngIf="currentUtilisateur.acces_evaluations">
    <div class="ui-g">
      <div class="ui-g-6">
        <p-dropdown [options]="disponibilitesListItem" [(ngModel)]="disponibilitesSelected" [style]="{'width':'250px'}"
                    [disabled]="!selectedIndividu" (onChange)="getCheckBoxDisponibilitesValues()">
        </p-dropdown>
      </div>
      <div class="ui-g-2">
        <button *ngIf="!isDisponibilitesEditable" pButton type="button" class="grey-button" label="Modifier" (click)="isDisponibilitesEditable=true"
          icon="pi pi-pencil" [disabled]="!selectedIndividu"></button>
      </div>
      <div class="ui-g-2">
        <button *ngIf="isDisponibilitesEditable" pButton type="button" class="grey-button" label="Annuler"
          (click)="isDisponibilitesEditable=false" icon="pi pi-times" [disabled]="!selectedIndividu"></button>
      </div>
      <div class="ui-g-2">
        <button *ngIf="isDisponibilitesEditable" pButton type="button" class="grey-button" label="Enregistrer"
          (click)="saveDispos()" icon="pi pi-save" [disabled]="!selectedIndividu"></button>
      </div>
      <div class="ui-g-2"></div>
      <div class="ui-g-4">
        <label>Version : </label> {{disponibilitesSelected.version}}
      </div>
      <div class="ui-g-4">
        <label>Création : </label> {{disponibilitesSelected.created | date : datePipeFormatWithoutTime}}
      </div>
      <div class="ui-g-4">
        <label>Clôture : </label> {{disponibilitesSelected.closed | date : datePipeFormatWithoutTime}}
      </div>
      <div class="ui-g-4">
        <label>Rédacteur : </label> {{disponibilitesSelected.redacteur?.libelle}}
      </div>
      <div class="ui-g-4">
        <label>Source : </label> {{disponibilitesSelected.source}}
      </div>

      <div class="ui-g-12">
        <p-table class="table-hover" [value]="[]">
          <ng-template pTemplate="header">
            <tr>
              <th></th>
              <th *ngFor="let periode of periodesList" class="cursor-pointer" (click)="selectPeriode(periode)">
                {{periode.libelle}}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
          </ng-template>
          <ng-template pTemplate="body" let-interventionDisponibilites let-rowIndex="rowIndex">
          </ng-template>
        </p-table>
      </div>
      <div *ngFor="let jour of joursList" class="ui-g-12">
        <div *ngIf="jour.code_parametre !== 'TOUJOU'">
          <div class="ui-g-2" style="border-right:1px solid #c8c8c8;">
            <span class="cursor-pointer" (click)="selectJour(jour)">{{jour.libelle}}</span>
          </div>
          <div *ngFor="let periode of periodesList" class="ui-g-2" style="text-align: center;">
            <p-checkbox [label]="" [value]="jour.id_parametre + '_' + periode.id_parametre"
              [(ngModel)]="checkBoxDisponibilitesValues" (onChange)="updateDisponibilitesValues($event, jour, periode)"
              [disabled]="!isDisponibilitesEditable">
            </p-checkbox>
          </div>
        </div>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Entourage" *ngIf="currentUtilisateur.acces_evaluations">
    <div class="ui-g">
      <div class="ui-g-6">
        <label style="margin-right: 10px;">Montrer les inactifs</label>
        <p-inputSwitch [(ngModel)]="showEntourageInactif"></p-inputSwitch>
      </div>
      <div class="ui-g-6">
        <button pButton type="button" class="grey-button" label="Créer un entourage" (click)="createEntourage()" [disabled]="!selectedIndividu"></button>
      </div>

      <div class="ui-g-12">
        <p-accordion expandIcon="pi pi-fw pi-chevron-down" collapseIcon="pi pi-fw pi-chevron-up" [multiple]="true">
          <p-accordionTab class="accordionTab-panel-header-category" [selected]="true" id="panelEntouragePersonnel"
            #panelEntouragePersonnel>
            <p-header>
              Entourage personnel
            </p-header>
            <p-table class="table-hover" [value]="contactsList" autoLayout="true">
              <ng-template pTemplate="header">
                <tr>
                  <th>Date</th>
                  <th>Contact</th>
                  <th>CP</th>
                  <th>Ville</th>
                  <th>Tél. fixe</th>
                  <th>Tél. portable</th>
                  <th>Lien</th>
                  <th>Rep. Légal</th>
                  <th>Aidant</th>
                  <th>Urgence</th>
                  <th>Confiance</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="11" class="table-emptymessage">Aucun entourage personnel</td>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-contact let-rowIndex="rowIndex">
                <tr *ngIf="contact.getEntouragesActuelsByType('ENT_PERSO')
                          && (contact.getEntouragesActuelsByType('ENT_PERSO').actif || showEntourageInactif)" class="selectable" (click)="editEntourage(contact.getEntouragesActuelsByType('ENT_PERSO'), contact)">
                  <td>{{contact.date | date: datePipeFormat}}</td>
                  <td>{{contact.nom}} {{contact.prenom}}</td>
                  <td>{{contact.cp}}</td>
                  <td>{{contact.ville}}</td>
                  <td>{{contact.tel_fixe}}</td>
                  <td>{{contact.tel_mobile}}</td>
                  <td>{{contact.getEntouragesActuelsByType('ENT_PERSO').lien_specialite?.libelle}}</td>
                  <td style="text-align: center"><img
                      *ngIf="contact.getEntouragesActuelsByType('ENT_PERSO').representant_legal"
                      src="assets/img/rep_legal.png" alt="representant_legal" pTooltip="Aidant" width=35 height=35></td>
                  <td style="text-align: center"><img *ngIf="contact.getEntouragesActuelsByType('ENT_PERSO').aidant" src="assets/img/evaluation-pics/aidant.png"
                      alt="aidant" pTooltip="Aidant" width=35 height=35></td>
                  <td style="text-align: center"><img *ngIf="contact.getEntouragesActuelsByType('ENT_PERSO').a_contacter_urgence"
                      src="assets/img/evaluation-pics/a_contacter_urgence.png" alt="a_contacter_urgence"
                      pTooltip="A contacter en cas d'urgence" width=35 height=35></td>
                  <td style="text-align: center"><img *ngIf="contact.getEntouragesActuelsByType('ENT_PERSO').personne_confiance"
                      src="assets/img/evaluation-pics/personne_confiance.png" alt="personne_confiance"
                      pTooltip="Personne de confiance"></td>
                </tr>
              </ng-template>
            </p-table>
          </p-accordionTab>
          <p-accordionTab class="accordionTab-panel-header-category" [selected]="true" id="panelEntourageProfessionnel"
            #panelEntourageProfessionnel>
            <p-header>
              Entourage professionnel
            </p-header>
            <p-table class="table-hover" [value]="contactsList" autoLayout="true">
              <ng-template pTemplate="header">
                <tr>
                  <th>Date</th>
                  <th>Contact</th>
                  <th>CP</th>
                  <th>Ville</th>
                  <th>Tél. fixe</th>
                  <th>Tél. portable</th>
                  <th>Spécialité</th>
                  <th>Rep. Légal</th>
                  <th>Urgence</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="11" class="table-emptymessage">Aucun entourage professionnel</td>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-contact let-rowIndex="rowIndex">
                <tr *ngIf="contact.getEntouragesActuelsByType('ENT_PRO')
                          && (contact.getEntouragesActuelsByType('ENT_PRO').actif || showEntourageInactif)" class="selectable" (click)="editEntourage(contact.getEntouragesActuelsByType('ENT_PRO'), contact)">
                  <td>{{contact.date | date: datePipeFormat}}</td>
                  <td>
                    <span *ngIf="!contact.civilite || contact.civilite.code_parametre !== 'AUT'">{{contact.nom}} {{contact.prenom}}</span>
                    <span *ngIf="contact.civilite?.code_parametre === 'AUT'">{{contact.nom}}</span>
                  </td>
                  <td>{{contact.cp}}</td>
                  <td>{{contact.ville}}</td>
                  <td>{{contact.tel_fixe}}</td>
                  <td>{{contact.tel_mobile}}</td>
                  <td>{{contact.getEntouragesActuelsByType('ENT_PRO').lien_specialite?.libelle}}</td>
                  <td style="text-align: center"><img
                      *ngIf="contact.getEntouragesActuelsByType('ENT_PRO').representant_legal"
                      src="assets/img/rep_legal.png" alt="representant_legal" pTooltip="Aidant" width=35 height=35></td>
                  <td style="text-align: center"><img *ngIf="contact.getEntouragesActuelsByType('ENT_PRO').a_contacter_urgence"
                      src="assets/img/evaluation-pics/a_contacter_urgence.png" alt="a_contacter_urgence"
                      pTooltip="A contacter en cas d'urgence" width=35 height=35></td>
                </tr>
              </ng-template>
            </p-table>
          </p-accordionTab>
        </p-accordion>
      </div>
    </div>
  </p-tabPanel>

  <p-tabPanel *ngIf="currentUtilisateur.acces_echanges" header="Echanges" headerStyleClass="green" class="echange">
    <app-liste-echanges [filtreEchange]="filtreEchange" [updateFiltre]="false" (refreshList)="refreshList('echange')">
    </app-liste-echanges>
  </p-tabPanel>
  <p-tabPanel *ngIf="currentUtilisateur.acces_evaluations" header="Evaluations" headerStyleClass="blue"
    class="evaluation">
    <app-liste-evaluations [filtreEvaluation]="filtreEvaluation" [updateFiltre]="false"
      (refreshList)="refreshList('evaluation')"></app-liste-evaluations>
  </p-tabPanel>
  <p-tabPanel *ngIf="currentUtilisateur.acces_alertes" header="Alertes" headerStyleClass="pink" class="alerte">
    <app-liste-alertes [filtreAlerte]="filtreAlerte" [updateFiltre]="false" (refreshList)="refreshList('alerte')">
    </app-liste-alertes>
  </p-tabPanel>
  <p-tabPanel *ngIf="currentUtilisateur.acces_alertes" header="Appréciations" headerStyleClass="pink"
    class="appreciation">
    <app-liste-appreciations [filtreAppreciation]="filtreAppreciation" [updateFiltre]="false"
      (refreshList)="refreshList('appreciation')"></app-liste-appreciations>
  </p-tabPanel>
</p-tabView>

<p-dialog [(visible)]="showPopupIndividus" [style]="{maxWidth: '90%', minWidth:'50%'}"
  [contentStyle]="{'max-height':'600px'}">
  <p-header>
    Sélection personne
  </p-header>
  <div class="ui-grid ui-grid-responsive ui-grid-pad ui-fluid" style="margin: 10px 0px">
    <div id="panel-selection-individus" class="ui-grid-row panel-header-category">
      <div class="ui-g-12">
        <h2>
          Résultats pour {{filtreIndividu.nom}} {{filtreIndividu.prenom}}
        </h2>
      </div>
    </div>
    <p-table class="table-hover" [value]="individusList" autoLayout="true">
      <ng-template pTemplate="header">
        <tr>
          <th>Association</th>
          <th>Nature</th>
          <th>Nom</th>
          <th>Prénom</th>
          <th>Adresse</th>
          <th><i class="pi pi-info-circle"></i></th>
          <th>Actif</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7" class="table-emptymessage">Aucune personne correspondant à vos critères de recherche n'a été
            trouvée.</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-individu>
        <tr
          *ngIf="!individu.nature || individu.nature.code_parametre === 'CLI' || individu.nature.code_parametre === 'PRO'"
          class="selectable" (click)="selectIndividu(individu);showPopupIndividus=false">
          <td>
            <span *ngIf="individu.association">{{individu.association.libelle}}</span>
            <span *ngIf="!individu.association">Inconnue</span>
          </td>
          <td>
            <span *ngIf="individu.nature">{{individu.nature.libelle}}</span>
            <span *ngIf="!individu.nature">Inconnue</span>
          </td>
          <td>{{individu.nomSecure}}</td>
          <td>{{individu.prenomSecure}}</td>
          <td>{{individu.adresseSecure}}</td>
          <td><i class="pi pi-info-circle" style="color:#17A345" pTooltip={{individu.infoTelephoneEmail}}
              tooltipStyleClass="tooltip-selection-individu"></i></td>
          <td>
            <span *ngIf="individu.actif" style="color:green">Oui</span>
            <span *ngIf="!individu.actif" style="color:red">Non</span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <p-footer>
    <div class="ui-rtl" dir="rtl">
      <button pButton type="button" class="grey-button" label="Fermer" (click)="showPopupIndividus=false"></button>
    </div>
  </p-footer>
</p-dialog>

<p-dialog *ngIf="currentEntourageForm" modal=true [(visible)]="showPopupEntourage" [style]="{maxWidth: '90%', minWidth:'80%'}" [contentStyle]="{'max-height':'540px'}" positionTop=163
 (entourageUpdated)="onEntourageCreated($event)" (entourageCreated)="onEntourageCreated($event)" (onHide)="onCloseEntouragePopup($event)" baseZIndex="10001">
   <p-header>
         <div class="ui-g-3">Entourage</div>
            <div class="ui-g-8" style="text-align: right;">
               <button pButton type="button" class="white-button small-button" style="width: 150px;" label="Enregistrer" (click)="saveEntourage()" [disabled]="!currentEntourageForm.valid"></button>
            </div>
   </p-header>

   <div class="ui-grid-row">
      <div class="ui-g-6" style="text-align: right;">
        <p-radioButton name="type_entourage" value="personnel" [(ngModel)]="currentTypeEntourage" (onClick)="setCurrentTypeEntourage('ENT_PERSO')" label="Personnel" [disabled]="currentEntourageFormMode === 'edit'"></p-radioButton>&nbsp;
        <p-radioButton name="type_entourage" value="professionnel" [(ngModel)]="currentTypeEntourage" (onClick)="setCurrentTypeEntourage('ENT_PRO')" label="Professionnel" [disabled]="currentEntourageFormMode === 'edit'"></p-radioButton>
      </div>
      <div class="ui-g-1">
        <label>Actif</label>
      </div>
      <div class="ui-g-5" [formGroup]="currentEntourageForm">
          <p-inputSwitch formControlName="actif"></p-inputSwitch>
      </div>
    </div>

   <!-- PROFESSIONNEL -->

   <div *ngIf="currentEntourageForm.value.type_personne.code_parametre === 'ENT_PRO'">
      <div class="ui-grid-row" [formGroup]="currentEntourageForm">
         <div class="ui-g-3" formGroupName="contact">
            <p-dropdown [options]="civilitesProList" [style]="{width: '100%'}" placeholder="--" formControlName="civilite" optionLabel="libelle"></p-dropdown>
         </div>
         <div class="ui-g-3" *ngIf="currentEntourageForm.value.contact.civilite?.code_parametre !== 'AUT'" formGroupName="contact">
            <input pInputText type="text" formControlName="nom" placeholder="Nom*" [style]="{width: '100%'}"/>
         </div>
         <div class="ui-g-3" *ngIf="currentEntourageForm.value.contact.civilite?.code_parametre !== 'AUT'" formGroupName="contact">
            <input pInputText type="text" formControlName="prenom" placeholder="Prénom" [style]="{width: '100%'}"/>
         </div>
         <div class="ui-g-6" *ngIf="currentEntourageForm.value.contact.civilite?.code_parametre === 'AUT'" formGroupName="contact">
            <input pInputText type="text" formControlName="nom" placeholder="Intitulé*" [style]="{width: '100%'}"/>
         </div>
         <div class="ui-g-1">
            <label style="float: right;">Spécialité</label>
         </div>
         <div class="ui-g-2">
            <p-dropdown [options]="specialitesList" [style]="{width: '100%'}" placeholder="--" formControlName="lien_specialite" optionLabel="libelle"></p-dropdown>
         </div>
      </div>

      <div class="ui-grid-row">
         <div class="ui-g-12" style="text-align: right;">
            <p-toggleButton [(ngModel)]="showAdresse" onLabel="Adresse" offLabel="Adresse" [onIcon]="'pi pi-minus'" offIcon="pi pi-plus" [style]="{'width':'150px'}"></p-toggleButton>
         </div>
      </div>

      <div *ngIf="showAdresse" [formGroup]="currentEntourageForm">
         <div class="ui-grid-row" formGroupName="contact">
            <div class="ui-g-12">
               <input pInputText type="text" formControlName="cplt_adresse_1" placeholder="Raison sociale, service" [style]="{width: '100%'}"/>
            </div>
         </div>
         <div class="ui-grid-row" formGroupName="contact">
            <div class="ui-g-12">
               <input pInputText type="text" formControlName="cplt_adresse_2" placeholder="Entrée, Bât, Immeuble, Résidence" [style]="{width: '100%'}"/>
            </div>
         </div>
         <div class="ui-grid-row" formGroupName="contact">
            <div class="ui-g-1">
               <input pInputText type="text" formControlName="numero_rue" placeholder="N°" [style]="{width: '100%'}"/>
            </div>
            <div class="ui-g-1">
               <p-dropdown [options]="typesRueList" [style]="{width: '100%'}" formControlName="type_complement_adresse" optionLabel="libelle"></p-dropdown>
            </div>
            <div class="ui-g-10">
               <input pInputText type="text" formControlName="rue" placeholder="Nom de la rue" [style]="{width: '100%'}"/>
            </div>
         </div>
         <div class="ui-grid-row" formGroupName="contact">
            <div class="ui-g-12">
               <input pInputText type="text" formControlName="cplt_adresse_3"  placeholder="Lieu dit, Ancienne commune" [style]="{width: '100%'}"/>
            </div>
         </div>
         <div class="ui-grid-row" formGroupName="contact">
            <div class="ui-g-2">
               <input pInputText type="text" formControlName="cp"  placeholder="Code postal" [style]="{width: '100%'}"/>
            </div>
            <div class="ui-g-7">
               <input pInputText type="text" formControlName="ville"  placeholder="Ville" [style]="{width: '100%'}"/>
            </div>
            <div class="ui-g-3">
               <input pInputText type="text" formControlName="pays"  placeholder="Pays" [style]="{width: '100%'}"/>
            </div>
         </div>
      </div>

      <div class="ui-grid-row" [formGroup]="currentEntourageForm">
         <div class="ui-g-6">
            <div class="ui-grid-row">
               <div class="ui-g-6">
                  <label>Représentant légal</label>
               </div>
               <div class="ui-g-6">
                  <p-inputSwitch formControlName="representant_legal" (onChange)="onChangeRepresentantLegal($event)"></p-inputSwitch>
               </div>
            </div>
            <div class="ui-grid-row" *ngIf="currentEntourageForm.value.representant_legal">
               <div class="ui-g-2">
                  <label>Type</label>
               </div>
               <div class="ui-g-8">
                  <p-dropdown [options]="typesRepresentantList" [style]="{width: '100%'}" formControlName="type_representant" optionLabel="libelle"></p-dropdown>
               </div>
            </div>
            <div class="ui-grid-row">
               <div class="ui-g-6">
                  <label>A contacter en cas d'urgence</label>
               </div>
               <div class="ui-g-6">
                  <p-inputSwitch formControlName="a_contacter_urgence"></p-inputSwitch>
               </div>
            </div>
            <div *ngIf="currentEntourageForm.value.lien_specialite?.code_parametre == 'SOIDOM'
                || currentEntourageForm.value.lien_specialite?.code_parametre == 'INF'
                || currentEntourageForm.value.lien_specialite?.code_parametre == 'SSIAD'"
                class="ui-grid-row">
               <div class="ui-g-3" style="text-align: center; margin-top: 5px">
                     <label>Fréquence</label>
                  </div>
               <div class="ui-g-2">
                  <input type="text" pInputText formControlName="nombre_frequence" [style]="{width: '100%'}"/>
               </div>
               <div class="ui-g-3" style="text-align: center; margin-top: 5px">
                  <p>fois par</p>
               </div>
               <div class="ui-g-4">
                  <p-dropdown [options]="unitesTempsList" [placeholder]="'Fréquence'" [style]="{width: '100%'}"
                     formControlName="frequence" optionLabel="libelle"></p-dropdown>
               </div>
            </div>
         </div>

         <div class="ui-g-6" [formGroup]="currentEntourageForm">
            <div class="ui-grid-row" formGroupName="contact">
               <div class="ui-g-12">
                  <div class="ui-inputgroup">
                     <span class="ui-inputgroup-addon"><i class="fa fa-phone"></i></span>
                     <input pInputText type="text" formControlName="tel_fixe" placeholder="Fixe" [style]="{width: '100%'}"/>
                  </div>
               </div>
            </div>
            <div class="ui-grid-row" formGroupName="contact">
               <div class="ui-g-12">
                  <div class="ui-inputgroup">
                     <span class="ui-inputgroup-addon"><i class="fa fa-mobile"></i></span>
                     <input pInputText type="text" formControlName="tel_mobile" placeholder="Mobile" [style]="{width: '100%'}"/>
                  </div>
               </div>
            </div>
            <div class="ui-grid-row" formGroupName="contact">
               <div class="ui-g-12">
                  <div class="ui-inputgroup">
                     <span class="ui-inputgroup-addon"><i class="fa fa-envelope"></i></span>
                     <input pInputText type="text" formControlName="email" placeholder="Email" [style]="{width: '100%'}"/>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="ui-grid-row">
         <label>Informations complémentaires</label>
      </div>
      <div class="ui-grid-row" [formGroup]="currentEntourageForm">
        <div class="ui-g-12" formGroupName="contact">
          <textarea pInputTextarea [rows]="5"
         formControlName="commentaire" style="width:100%"></textarea>
        </div>
      </div>

      <div class="ui-grid-row">
        <div class="ui-g-12 message-important">
           Requis *<br/>
        </div>
      </div>

   </div>

   <!-- PERSONNEL -->

   <div *ngIf="currentEntourageForm.value.type_personne.code_parametre === 'ENT_PERSO'">
      <div class="ui-grid-row" [formGroup]="currentEntourageForm">
         <div class="ui-g-3" formGroupName="contact">
            <p-dropdown [options]="civilitesList" [style]="{width: '100%'}" placeholder="--" formControlName="civilite" optionLabel="libelle"></p-dropdown>
         </div>
         <div class="ui-g-3" formGroupName="contact">
            <input pInputText type="text" formControlName="nom" placeholder="Nom*" [style]="{width: '100%'}"/>
         </div>
         <div class="ui-g-3" formGroupName="contact">
            <input pInputText type="text" formControlName="prenom" placeholder="Prénom" [style]="{width: '100%'}"/>
         </div>
         <div class="ui-g-1">
            <label>Lien :</label>
         </div>
         <div class="ui-g-2">
            <p-dropdown [options]="liensFamilleList" [style]="{width: '100%'}" placeholder="--" formControlName="lien_specialite" optionLabel="libelle"></p-dropdown>
         </div>
      </div>

      <div class="ui-grid-row">
         <div class="ui-g-3" [formGroup]="currentEntourageForm">
            <div class="ui-inputgroup" formGroupName="contact">
               <span class="ui-inputgroup-addon"><i class="fa fa-phone"></i></span>
               <input *ngIf="!currentEntourageForm.value.vivant_au_foyer" pInputText type="text" formControlName="tel_fixe" placeholder="Fixe" [style]="{width: '100%'}"/>
               <input *ngIf="currentEntourageForm.value.vivant_au_foyer" pInputText type="text" [value]="currentEntourageForm.value.contact.tel_fixe" placeholder="Fixe" disabled="true" [style]="{width: '100%'}"/>
            </div>
         </div>
         <div class="ui-g-3" [formGroup]="currentEntourageForm">
            <div class="ui-inputgroup" formGroupName="contact">
               <span class="ui-inputgroup-addon"><i class="fa fa-mobile"></i></span>
               <input pInputText type="text" formControlName="tel_mobile" placeholder="Mobile" [style]="{width: '100%'}"/>
            </div>
         </div>
         <div class="ui-g-3" [formGroup]="currentEntourageForm">
            <div class="ui-inputgroup" formGroupName="contact">
               <span class="ui-inputgroup-addon"><i class="fa fa-envelope"></i></span>
               <input pInputText type="text" formControlName="email" placeholder="Email" [style]="{width: '100%'}"/>
            </div>
         </div>
         <div class="ui-g-3" style="text-align: right;">
            <p-toggleButton [(ngModel)]="showAdresse" onLabel="Adresse" offLabel="Adresse" [onIcon]="'pi pi-minus'" offIcon="pi pi-plus" [style]="{'width':'150px'}"></p-toggleButton>
         </div>
      </div>

      <div *ngIf="showAdresse" [formGroup]="currentEntourageForm">
         <div class="ui-grid-row" formGroupName="contact">
            <div class="ui-g-12">
                <input *ngIf="!currentEntourageForm.value.vivant_au_foyer" pInputText type="text" formControlName="cplt_adresse_1" placeholder="N° appt, Etage, Escalier" [style]="{width: '100%'}"/>
                <input *ngIf="currentEntourageForm.value.vivant_au_foyer" pInputText type="text" [value]="currentEntourageForm.value.contact.cplt_adresse_1" placeholder="N° appt, Etage, Escalier" disabled="true" [style]="{width: '100%'}"/>
            </div>
         </div>
         <div class="ui-grid-row" formGroupName="contact">
            <div class="ui-g-12">
              <input *ngIf="!currentEntourageForm.value.vivant_au_foyer" pInputText type="text" formControlName="cplt_adresse_2" placeholder="Entrée, Bât, Immeuble, Résidence" [style]="{width: '100%'}"/>
              <input *ngIf="currentEntourageForm.value.vivant_au_foyer" pInputText type="text" [value]="currentEntourageForm.value.contact.cplt_adresse_2" placeholder="Entrée, Bât, Immeuble, Résidence" disabled="true" [style]="{width: '100%'}"/>
            </div>
         </div>
         <div class="ui-grid-row" formGroupName="contact">
            <div class="ui-g-1">
                <input *ngIf="!currentEntourageForm.value.vivant_au_foyer" pInputText type="text" formControlName="numero_rue" placeholder="N°" [style]="{width: '100%'}"/>
                <input *ngIf="currentEntourageForm.value.vivant_au_foyer" pInputText type="text" [value]="currentEntourageForm.value.contact.numero_rue" placeholder="N°" disabled="true" [style]="{width: '100%'}"/>
            </div>
            <div class="ui-g-2">
                <p-dropdown *ngIf="!currentEntourageForm.value.vivant_au_foyer" [options]="typesRueList" [style]="{width: '100%'}" formControlName="type_complement_adresse" optionLabel="libelle"></p-dropdown>
                <input *ngIf="currentEntourageForm.value.vivant_au_foyer" pInputText type="text" [value]="currentEntourageForm.value.contact.type_complement_adresse?.libelle" disabled="true" [style]="{width: '100%'}"/>
            </div>
            <div class="ui-g-9">
                <input *ngIf="!currentEntourageForm.value.vivant_au_foyer" pInputText type="text" formControlName="rue" placeholder="Nom de la rue" [style]="{width: '100%'}"/>
                <input *ngIf="currentEntourageForm.value.vivant_au_foyer" pInputText type="text" [value]="currentEntourageForm.value.contact.rue" placeholder="Nom de la rue" disabled="true" [style]="{width: '100%'}"/>
            </div>
         </div>
         <div class="ui-grid-row" formGroupName="contact">
            <div class="ui-g-12">
                <input *ngIf="!currentEntourageForm.value.vivant_au_foyer" pInputText type="text" formControlName="cplt_adresse_3" placeholder="Lieu dit, Ancienne commune" [style]="{width: '100%'}"/>
                <input *ngIf="currentEntourageForm.value.vivant_au_foyer" pInputText type="text" [value]="currentEntourageForm.value.contact.cplt_adresse_3" placeholder="Lieu dit, Ancienne commune" disabled="true" [style]="{width: '100%'}"/>
            </div>
         </div>
         <div class="ui-grid-row" formGroupName="contact">
            <div class="ui-g-2">
                <input *ngIf="!currentEntourageForm.value.vivant_au_foyer" pInputText type="text" formControlName="cp" placeholder="Code postal" [style]="{width: '100%'}"/>
                <input *ngIf="currentEntourageForm.value.vivant_au_foyer" pInputText type="text" [value]="currentEntourageForm.value.contact.cp" placeholder="Code postal" disabled="true" [style]="{width: '100%'}"/>
            </div>
            <div class="ui-g-7">
                <input *ngIf="!currentEntourageForm.value.vivant_au_foyer" pInputText type="text" formControlName="ville" placeholder="Ville" [style]="{width: '100%'}"/>
                <input *ngIf="currentEntourageForm.value.vivant_au_foyer" pInputText type="text" [value]="currentEntourageForm.value.contact.ville" placeholder="Ville" disabled="true" [style]="{width: '100%'}"/>
            </div>
            <div class="ui-g-3">
                <input *ngIf="!currentEntourageForm.value.vivant_au_foyer" pInputText type="text" formControlName="pays" placeholder="Pays" [style]="{width: '100%'}"/>
                <input *ngIf="currentEntourageForm.value.vivant_au_foyer" pInputText type="text" [value]="currentEntourageForm.value.contact.pays" placeholder="Pays" disabled="true" [style]="{width: '100%'}"/>
            </div>
         </div>
      </div>

      <div class="ui-grid-row" [formGroup]="currentEntourageForm">
         <div class="ui-g-6">
            <div class="ui-grid-row">
               <div class="ui-g-6">
                  <label>A contacter en cas d'urgence</label>
               </div>
               <div class="ui-g-6">
                  <p-inputSwitch formControlName="a_contacter_urgence"></p-inputSwitch>
               </div>
            </div>
            <div class="ui-grid-row">
               <div class="ui-g-6">
                  <label>Représentant légal</label>
               </div>
               <div class="ui-g-6">
                  <p-inputSwitch formControlName="representant_legal" (onChange)="onChangeRepresentantLegal($event)"></p-inputSwitch>
               </div>
            </div>
            <div class="ui-grid-row" *ngIf="currentEntourageForm.value.representant_legal">
               <div class="ui-g-2">
                  <label>Type</label>
               </div>
               <div class="ui-g-8">
                  <p-dropdown [options]="typesRepresentantList" [style]="{width: '100%'}" formControlName="type_representant" optionLabel="libelle"></p-dropdown>
               </div>
            </div>
            <div class="ui-grid-row" *ngIf="currentEntourageForm.value.representant_legal">
               <div class="ui-g-12">
                  <p-checkbox label="Copie du jugement" formControlName="copie_jugement" binary="true"></p-checkbox>
               </div>
            </div>
            <div class="ui-grid-row">
               <div class="ui-g-6">
                  <label>Vivant au foyer</label>
               </div>
               <div class="ui-g-6">
                  <p-inputSwitch formControlName="vivant_au_foyer" (onChange)="onChangeVivantAuFoyer($event)"></p-inputSwitch>
               </div>
            </div>
            <div class="ui-grid-row" *ngIf="currentEntourageForm.value.vivant_au_foyer">
               <div class="ui-g-12">
                  <p-checkbox label="Dépendant" formControlName="dependant" binary="true"></p-checkbox>
               </div>
            </div>
         </div>

         <div class="ui-g-6">
            <div class="ui-grid-row">
               <div class="ui-g-6">
                  <label>Accès aux données</label>
               </div>
               <div class="ui-g-6">
                  <p-inputSwitch formControlName="autorisation_acces_donnees"></p-inputSwitch>
               </div>
            </div>
            <div class="ui-grid-row">
               <div class="ui-g-6">
                  <label>Aidant</label>
               </div>
               <div class="ui-g-6">
                  <p-inputSwitch formControlName="aidant"></p-inputSwitch>
               </div>
            </div>
            <div class="ui-grid-row">
               <div class="ui-g-6">
                  <label>Personne de confiance</label>
               </div>
               <div class="ui-g-6">
                  <p-inputSwitch formControlName="personne_confiance"></p-inputSwitch>
               </div>
            </div>
            <div class="ui-grid-row" *ngIf="currentEntourageForm.value.personne_confiance">
               <div class="ui-g-12">
                  <p-checkbox label="Formulaire de désignation existant" formControlName="formulaire_confiance" binary="true"></p-checkbox>
               </div>
            </div>
         </div>
      </div>

      <div class="ui-grid-row">
         <label>Informations complémentaires</label>
      </div>
      <div class="ui-grid-row" [formGroup]="currentEntourageForm">
        <div class="ui-g-12" formGroupName="contact">
          <textarea pInputTextarea [rows]="5"
         formControlName="commentaire" style="width:100%"></textarea>
        </div>
      </div>

      <div class="ui-grid-row">
        <div class="ui-g-12 message-important">
           Requis *<br/>
        </div>
      </div>

   </div>
</p-dialog>
