<div id="panel-liste-alertes" class="ui-grid-row panel-header-category-transparent" style="margin-top:10px;">
  <div class="ui-g-12 no-padding-left">
    <h2 style="float: left;">
      <i class="zmdi zmdi-view-list"></i>
      Liste des alertes (page {{currentPage}} / {{nbTotalPages}}) <button #btnExport type="button" pButton
        icon="pi pi-file" class="grey-button" style="margin-left: 20px;" label="Exporter"
        (click)="menuExport.toggle($event)"></button>
    </h2>

    <p-slideMenu #menuExport [model]="exportItems" [popup]="true" [viewportHeight]="150" backLabel="Retour"
      appendTo="body"></p-slideMenu>

    <!--<span style="float:right;">Nombre d'éléments {{firstElementNumber}} - {{lastElementNumber}} sur {{currentPage ? nbTotalAlertes : 0}}</span>-->
  </div>
</div>
<p-table #dt class="table table-hover table-condensed" [columns]="cols" [value]="alertesList" dataKey="id_alerte"
  autoLayout="true" [lazy]="true" [lazyLoadOnInit]="false" (onLazyLoad)="loadAlertesLazy($event)"
  [rows]="filtreAlerte.limit" [totalRecords]="nbTotalAlertes" [loading]="loading" [paginator]="true"
  [rowsPerPageOptions]="rowsPerPageOptions" paginatorDropdownAppendTo="body" (sortFunction)="customSort($event)"
  [customSort]="true" stateStorage="session" stateKey="state-alertesList" [(contextMenuSelection)]="selectedAlerte"
  [contextMenu]="cmAlerte" [selectionMode]="'single'" (onContextMenuSelect)="updateContextMenuAlerte($event)">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field"
        [pSortableColumnDisabled]="getSortableColumnDisabled(col.field)">
        <span>{{col.header}}</span>
        <p-sortIcon *ngIf="!getSortableColumnDisabled(col.field)" [field]="col.field"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
    <tr [class]="getClassTrAlerte(rowData)" [pSelectableRow]="rowData" [pContextMenuRow]="rowData"
      (click)="editAlerte(rowData['id_alerte'], $event)">
      <td *ngFor="let col of columns" [class]="getClassTdAlerte(col.field)">
        <span *ngIf="col.field ==='client_nom'" [pTooltip]="getTooltip(col.field, rowData)"
          tooltipStyleClass="tooltip-utilisateurLibelle">{{getRowLabel(col.field, rowData)}}</span>
        <span
          *ngIf="col.field ==='date_creation' && getRowLabel('date_creation', rowData).getTime()">{{getRowLabel('date_creation', rowData) | date: datePipeFormatWithoutTime}}</span>
        <span *ngIf="col.field ==='statut_alerte'">{{getRowLabel(col.field, rowData)}}</span>
        <span *ngIf="col.field ==='motif_alerte'">{{getRowLabel(col.field, rowData)}}</span>
        <span
          *ngIf="col.field ==='date_qualification' && getRowLabel('date_qualification', rowData)?.getTime()">{{getRowLabel('date_qualification', rowData) | date: datePipeFormat}}</span>
        <span *ngIf="col.field ==='qualificateur'">{{getRowLabel(col.field, rowData)}}</span>
        <span *ngIf="col.field ==='actions'">{{getRowLabel(col.field, rowData)}}</span>
        <span *ngIf="col.field ==='firstDateEcheance'">{{getRowLabel(col.field, rowData) | date: datePipeFormatWithoutTime}}</span>
        <span *ngIf="col.field ==='association'">{{getRowLabel(col.field, rowData)}}</span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary">
    Nombre d'éléments {{firstElementNumber}} - {{lastElementNumber}} sur {{currentPage ? nbTotalAlertes : 0}} <i
      class="pi pi-refresh" style="margin-left:10px; cursor:pointer;" (click)="refresh()"></i>
  </ng-template>
</p-table>

<p-contextMenu #cmAlerte [model]="itemsContextMenu" class="contextMenuListeAction"></p-contextMenu>

<p-dialog *ngIf="alerteForm" [(visible)]="showPopupQualificationAlerte" [style]="{maxWidth: '900px', minWidth:'50%'}"
  [contentStyle]="{'max-height':'600px'}" (onHide)="showPopupQualificationAlerte=false" baseZIndex="100001"
  modal="true">
  <p-header>
    <div class="ui-g-8" style="font-size: 15px;">Vérification de l'alerte</div>
    <div class="ui-g-3" style="text-align: right;">
      <button pButton type="button" class="white-button small-button" style="width: 150px;" label="Enregistrer"
      (click)="submitQualificationAlerte()" [disabled]="!alerteForm.valid"></button>
    </div>
  </p-header>
  <div class="ui-grid ui-grid-responsive ui-grid-pad ui-fluid" style="margin: 10px 0px">
    <form [formGroup]="alerteForm">
      <div class="ui-grid-row">
        <div class="ui-g-6">
          <div class="ui-g-12">
            <label>Client</label>
          </div>
          <div class="ui-g-12">
            <input type="text" pInputText [value]="alerteForm.value.appreciation.client_nom + ' ' + alerteForm.value.appreciation.client_prenom"
              readonly="true" />
          </div>
        </div>
        <div class="ui-g-6">
          <div class="ui-g-12">
            <label>Alerte</label>
          </div>
          <div class="ui-g-12">
            <input type="text" pInputText [value]="alerteForm.value.date_creation | date: datePipeFormat"
              readonly="true" />
          </div>
        </div>
      </div>
      <!--
    <div class="ui-grid-row">
      <div class="ui-g-6">
        <div class="ui-g-12">
          <label>Date de qualification</label>
        </div>
        <div class="ui-g-12">
          <input type="text" pInputText [value]="alerteForm.value.date_qualification | date: datePipeFormat" readonly="true" />
        </div>
      </div>
      <div class="ui-g-6">
        <div class="ui-g-12">
          <label>Qualificateur</label>
        </div>
        <div class="ui-g-12">
          <input type="text" pInputText [value]="alerteForm.value.qualificateur?.libelle" readonly="true" />
        </div>
      </div>
    </div>
    -->
      <div class="ui-grid-row">
        <div class="ui-g-6">
          <div class="ui-g-12">
            <p-radioButton name="justifiee" label="Injustifiée" [value]="false" formControlName="justifiee"
                    (onClick)="onChangeJustifiee(false)"></p-radioButton>
            <p-radioButton name="justifiee" label="Justifiée" [value]="true" formControlName="justifiee"
              [style]="{'marginLeft':'10px'}" (onClick)="onChangeJustifiee(true)"></p-radioButton>
          </div>
        </div>
        <div class="ui-g-6">
          <div class="ui-g-12">
            <label>Motif</label>
          </div>
          <div class="ui-g-12">
            <p-dropdown [options]="motifsList" [class]="!canEdit() || !alerteForm.value.justifiee || !canEditJustification ? 'disabled' : ''"
              [placeholder]="'Motif...'" [style]="{width: '100%'}" formControlName="motif_alerte" optionLabel="libelle" dataKey="id_parametre"
              showClear="true" [readonly]="!canEdit() || !alerteForm.value.justifiee || !canEditJustification"
              (onChange)="onChangeMotif($event)"></p-dropdown>
          </div>
          <div class="ui-g-12"
            *ngIf="alerteForm.controls['motif_alerte'].invalid && (alerteForm.controls['motif_alerte'].dirty || alerteForm.controls['motif_alerte'].touched)">
            <p-message severity="error" text="Le motif de l'alerte est invalide"></p-message>
          </div>
        </div>
      </div>
      <div class="ui-grid-row">
        <div class="ui-g-12">
          <div class="ui-g-12">
            <label>Descriptif/ commentaire</label>
          </div>
          <div class="ui-g-12">
            <textarea pInputTextarea [rows]="5" placeholder="Descriptif/ commentaire"
              formControlName="description"></textarea>
          </div>
          <div class="ui-g-12"
            *ngIf="alerteForm.controls['description'].invalid && (alerteForm.controls['description'].dirty || alerteForm.controls['description'].touched)">
            <p-message severity="error" text="La description est invalide"></p-message>
          </div>
        </div>
      </div>
    </form>
  </div>
</p-dialog>

<app-action-form-dialog *ngIf="newActionAlerteSelected && showPopupAction" [action]="newActionAlerteSelected" [showPopupAction]="showPopupAction" [formMode]="'create'" [saveNew]="true" (actionCreated)="onActionCreated($event)" (cancel)="cancelNewAction()"></app-action-form-dialog>
