import {classToPlain, plainToClass, Type} from 'class-transformer';
import {Agence} from './agence';
import {Association} from './association';
import {Droit} from './droit';
import {DroitModule} from './droit-module';
import {Federation} from './federation';
import {Groupe} from './groupe';
import {LogUtilisateur} from './log-utilisateur';
import {Parametre} from './parametre';
import {PerimetreEnum} from './perimetre.enum';
import {Profil} from './profil';

export class Utilisateur {
  id_utilisateur: number;
  login: string;
  mdp: string;
  nom: string;
  prenom: string;
  perimetre: PerimetreEnum;
  mail: string;
  telephone: string;
  mode_droit: string;
  fonction: string;
  departement: string;

  @Type(() => Federation)
  federation?: Federation;

  @Type(() => Association)
  association?: Association;

  @Type(() => Agence)
  agences: Agence[];

  @Type(() => LogUtilisateur)
  logs?: LogUtilisateur;

  date_debut: string;
  date_fin: string;
  est_administrateur: 'O' | 'N' = 'N';
  token?: string;
  disabled: boolean;
  droits: Droit[];

  @Type(() => Parametre)
  preferences_contact: Parametre[];

  type_acces: string;
  multiSiteAssociations: Association[];

  @Type(() => Parametre)
  types_droit: Parametre[] = [];

  is_utilisateur_mobile: boolean;

  preference_affichage: string;

  acces_echanges = true;

  acces_evaluations: boolean;

  acces_alertes: boolean;

  acces_personia: boolean;

  preferences_tableau_bord: string[];

  @Type(() => Groupe)
  groupes: Groupe[];

  @Type(() => Profil)
  profil: Profil;

  @Type(() => DroitModule)
  droits_module: DroitModule[];

  visibilite_main_courante_restreinte = false;

  visibilite_evaluations_restreinte = false;

  visibilite_vigilance_restreinte = false;

  user_id: string;
  statut_habilitation: number;
  log_creation: number;
  date_rattachement: Date;

  switchAstreinte = null;

  get libelle(): string {
    if (!this.prenom) {
      return this.nom;
    }

    return this.nom + ' ' + this.prenom;
  }

  get prenom_nom(): string {
    if (!this.prenom) {
      return this.nom;
    }

    return this.prenom + ' ' + this.nom;
  }

  get isAstreinte(): boolean {
    return this.profil.libelle === 'Astreinte';
  }

  get isMultiSite(): boolean {
    let isMultiSite = false;
    if (this.type_acces === 'multiSite' || this.type_acces === 'multiAsso') {
      isMultiSite = true;
    }

    if (this.perimetre !== 'Association') {
      isMultiSite = false;
    }

    return isMultiSite;
  }

  get hasAccesEvaluations(): boolean {
    if (this.acces_evaluations
      && (this.perimetre === 'National'
        || (this.federation && this.federation.acces_evaluations))) {
      return true;
    } else {
      return false;
    }
  }

  get hasAccesAlertes(): boolean {
    if (this.acces_alertes
      && (this.perimetre === 'National'
        || (this.federation && this.federation.acces_alertes))) {
      return true;
    } else {
      return false;
    }
  }

  get isPersonia(): boolean {
    if (!this.acces_personia) {
      return false;
    } else if (!this.acces_echanges && !this.acces_evaluations && !this.acces_alertes) {
      return true;
    }
    return false;
  }

  get isLecteur(): boolean {
    if (this.droits_module && this.droits_module.length > 0) {
      return false;
    }
    return true;
  }

  setMultiSiteAssociations(droits) {
    let multiSiteAssociations = []; // On réinitialise les associations
    this.droits.forEach(droit => {
      multiSiteAssociations.push(plainToClass(Association, droit.association));
    });
    // On trie par ordre alphabétique
    multiSiteAssociations.sort(function (a, b) {
      if (a.libelle < b.libelle) {
        return -1;
      }
      if (a.libelle > b.libelle) {
        return 1;
      }
      return 0;
    });
    this.multiSiteAssociations = multiSiteAssociations;
  }

  hasFonctionnalite(module, fonctionnalite) {
    let hasFonctionnalite = false;

    if (this.droits_module) {
      this.droits_module.forEach(droit_module => {
        if (droit_module.module === module && droit_module.fonctionnalite === fonctionnalite) {
          hasFonctionnalite = true;
        }
      });
    }

    return hasFonctionnalite;
  }

  hasAssociation(association: Association) {
    let hasAssociation = false;
    if (this.multiSiteAssociations && this.multiSiteAssociations.length > 0) {
      this.multiSiteAssociations.forEach(multiSiteAssociation => {
        if (multiSiteAssociation.id_association === association.id_association) {
          hasAssociation = true;
        }
      });
    }
    return hasAssociation;
  }

  hasAgence(agence: Agence) {
    let hasAgence = false;
    if (this.perimetre !== 'Agence') {
      hasAgence = true;
    } else if (this.agences && this.agences.length > 0) {
      this.agences.forEach(agenceUtilisateur => {
        if (agence.id_agence === agenceUtilisateur.id_agence) {
          hasAgence = true;
        }
      });
    }
    return hasAgence;
  }

  isInPerimetreAssociation(association: Association, federation: Federation = null) {
    if (association) {
      if (this.perimetre === 'National'
        || (this.perimetre === 'Federation' && this.federation && federation && this.federation.id_federation === federation.id_federation)
        || (this.perimetre === 'Association' && this.association && association && this.association.id_association === association.id_association)
        || this.hasAssociation(association)) {
        return true;
      }
    }

    return false;
  }

  getPreferencesContactLabel(separator = ', ') {
    let labelPreferencesContact = '';
    if (this.preferences_contact && this.preferences_contact.length > 0) {
      this.preferences_contact.forEach(preference_contact => {
        if (labelPreferencesContact !== '') {
          labelPreferencesContact += separator;
        }
        labelPreferencesContact += preference_contact.libelle;
      });
    }

    return labelPreferencesContact;
  }

  serialize() {
    let serializeObject = classToPlain(this);
    delete serializeObject['id_utilisateur'];
    delete serializeObject['multiSiteAssociations'];
    delete serializeObject['date_debut'];
    delete serializeObject['date_fin'];
    delete serializeObject['droits'];
    delete serializeObject['is_utilisateur_mobile'];
    delete serializeObject['statut_habilitation'];

    if (this.mdp === null || this.mdp === undefined || this.mdp === '') {
      delete serializeObject['mdp'];
    }

    if (serializeObject['code']) {
      delete serializeObject['code'];
    }

    if (this.federation !== null && this.federation !== undefined) {
      serializeObject['federation'] = this.federation.id_federation;
    }

    if (this.association !== null && this.association !== undefined) {
      serializeObject['association'] = this.association.id_association;
    }

    if (this.logs !== null && this.logs !== undefined) {
      serializeObject['logs'] = this.logs.id_log_utilisateur;
    }

    serializeObject['agences'] = [];
    if (this.agences !== null && this.agences !== undefined) {
      this.agences.forEach(function (agence) {
        serializeObject['agences'].push(agence.id_agence);
      });
    }

    serializeObject['preferences_contact'] = [];
    if (this.preferences_contact !== null && this.preferences_contact !== undefined) {
      this.preferences_contact.forEach(function (preference_contact) {
        serializeObject['preferences_contact'].push(preference_contact.id_parametre);
      });
    }

    serializeObject['types_droit'] = [];
    if (this.types_droit !== null && this.types_droit !== undefined) {
      this.types_droit.forEach(function (type_droit) {
        serializeObject['types_droit'].push(type_droit.id_parametre);
      });
    }

    serializeObject['groupes'] = [];
    if (this.groupes !== null && this.groupes !== undefined) {
      this.groupes.forEach(function (groupe) {
        serializeObject['groupes'].push(groupe.id_groupe);
      });
    }

    if (this.profil !== null && this.profil !== undefined) {
      serializeObject['profil'] = this.profil.id_profil;
    }

    serializeObject['droits_module'] = [];
    if (this.droits_module !== null && this.droits_module !== undefined && this.droits_module.length > 0) {
      this.droits_module.forEach(function (droit_module) {
        serializeObject['droits_module'].push(droit_module.id_droit_module);
      });
    }

    // Prise en compte des booléens (meme false)
    for (let propertyName in serializeObject) { // On parcourt les proprietes de l'objet
      if (serializeObject[propertyName] === false) { // Si c'est un booléen false
        serializeObject[propertyName] = false;
      }
    }

    return serializeObject;
  }

  serializeForExport() {

    let federation_libelle = '';
    let association_libelle = '';
    let logs_description = '';

    if (this.federation !== null && this.federation !== undefined) {
      federation_libelle = this.federation.libelle;
    }

    if (this.association !== null && this.association !== undefined) {
      association_libelle = this.association.libelle;
    }

    if (this.logs !== null && this.logs !== undefined) {
      logs_description = this.logs.description;
    }

    let serializeObject = {
      'ID': this.id_utilisateur,
      'Login': this.login,
      'Email': this.mail,
      'Nom': this.nom,
      'Prénom': this.prenom,
      'Périmètre': this.perimetre,
      'Département': this.departement,
      'Multi-Site': this.isMultiSite ? 'Oui' : 'Non',
      'Fédération': federation_libelle,
      'Association': association_libelle,
      'Inactif': this.disabled ? 'Oui' : 'Non',
      'User Id': this.user_id,
      'Status Habilitation': this.statut_habilitation,
      'Log création': this.log_creation,
      'Date rattachement': this.date_rattachement,
      'Logs': logs_description
    };

    return serializeObject;
  }
}
