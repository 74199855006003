import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import {plainToClass} from 'class-transformer';

import { Agence } from '../_models';

import { environment } from '../../../environments/environment';
import { FiltreAgence } from '../utils/filtre-agence';

@Injectable({ providedIn: 'root' })
export class AgenceService {

  filtreAgence = new FiltreAgence();

  constructor(private http: HttpClient) { }

  /** GET agences */
  getAll(): Observable<Agence[]> {
    return this.http.get<Agence[]>(`${environment.apiHost}/${environment.apiNamespace}/agences`).pipe(
      tap(_ => console.log(`fetched agences`)),
      catchError(this.handleError)
    );
  }

  /** GET parametres */
  getAgencesByParams(paramsValue: any): Observable<Agence[]> {
    let params = new HttpParams();
    for (let paramKey in paramsValue) {
      if (paramsValue.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValue[paramKey]);
      }
    }
    return this.http.get<Agence[]>(`${environment.apiHost}/${environment.apiNamespace}/agences`, { params: params });
  }

  /** GET agences/count */
  getNbAgencesByParams(paramsValue: FiltreAgence): Observable<any> {
    let paramsValueSerialized = paramsValue.serialize();
    let params = new HttpParams();
    for (let paramKey in paramsValueSerialized) {
      if (paramsValueSerialized.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValueSerialized[paramKey]);
      }
    }
    return this.http.get<any>(`${environment.apiHost}/${environment.apiNamespace}/agences/count`, { params: params });
  }

   /** GET agences/:id */
   getAgence(id_agence: number): Observable<Agence> {
    return this.http.get<Agence>(`${environment.apiHost}/${environment.apiNamespace}/agences/${id_agence}`);
  }

  /** POST agences */
  createAgence(agence: Agence): Observable<Agence> {
    return this.http.post<any>(
      `${environment.apiHost}/${environment.apiNamespace}/agences`,
      plainToClass(Agence, agence).serialize()
     );
  }

  /** PUT agences */
  updateAgence(agence: Agence): Observable<Agence> {
    return this.http.put<any>(
      `${environment.apiHost}/${environment.apiNamespace}/agences/${agence.id_agence}`,
      plainToClass(Agence, agence).serialize()
    );
  }

  /** DELETE agences */
  removeAgence(id_agence: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiHost}/${environment.apiNamespace}/agences/${id_agence}`,
    { observe: 'response' }).pipe(
      catchError(this.handleError)
    );
  }

  initFiltreAgence() {
    this.filtreAgence = new FiltreAgence();
  }

  /* handleError */
  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(error);
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a utilisateur-facing error message
    return throwError(
      error);
  }

}
