import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';

import { plainToClass, classToClass } from 'class-transformer';
import * as moment from 'moment';

import { MessageService, MenuItem, LazyLoadEvent } from 'primeng/api';

import { AppreciationService, ActionService, ParametreService, AuthenticationService, ExportService } from '../../shared/_services';
import { Appreciation, Action, Parametre, Utilisateur } from '../../shared/_models';

import { FiltreAppreciation } from '../../shared/utils/filtre-appreciation';

import { Globals } from '../../shared/utils/globals';
import { environment } from "../../../environments/environment";

@Component({
  selector: 'app-liste-appreciations',
  templateUrl: './liste-appreciations.component.html',
  styleUrls: ['./liste-appreciations.component.css']
})
export class ListeAppreciationsComponent implements OnInit {

  @Input()
  filtreAppreciation: FiltreAppreciation = new FiltreAppreciation(); // propriété d'entrée du composant : le filtre appreciation

  @Input()
  updateFiltre = true; // propriété d'entrée du composant : mise a jour du filtre globale des appreciations

  @Output()
  refreshList = new EventEmitter<any>();

  @ViewChild('dt', { static: true }) dt; // Composant datatable qui affiche la liste des echanges

  currentUtilisateur: Utilisateur;
  nbTotalAppreciations = 0;
  appreciationsList: Appreciation[] = [];
  cols: any[];
  loading: boolean;
  rowsPerPageOptions = [20, 50, 100];
  itemsContextMenu: MenuItem[];
  selectedAppreciation: Appreciation;
  statutsAppreciationList: Parametre[];
  statutsActionList: Parametre[];
  exportItems: MenuItem[];
  motifsList: Parametre[];

  datePipeFormatWithoutTime = Globals.datePipeFormatWithoutTime;
  datePipeFormat = Globals.datePipeFormat;
  timeFormat = Globals.timeFormat;

  appreciationForm: FormGroup;
  showPopupQualificationAppreciation = false;
  submittingAppreciation = false;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private appreciationService: AppreciationService,
    private actionService: ActionService,
    private parametreService: ParametreService,
    private exportService: ExportService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.authenticationService.currentUtilisateur$.subscribe(utilisateur => {
      if (utilisateur) {
        this.currentUtilisateur = this.authenticationService.currentUtilisateurValue;
      }
    });

    this.getCols();
    this.getStatutsAppreciation();
    this.getMotifs();
    this.getExportItems();
  }

  getStatutsAppreciation() {
    this.parametreService.getParametresByParams({ type: 'STATUT_ALERTE' })
      .subscribe((parametres: Parametre[]) => {
        this.statutsAppreciationList = parametres;
      });
  }

  getMotifs() {
    this.parametreService.getParametresByParams({ type: 'MOTIF_ALERTE' })
      .subscribe((parametres: Parametre[]) => {
        this.motifsList = parametres;
      });
  }

  searchAppreciation(filtreAppreciation) {
    this.loading = true;
    this.filtreAppreciation.setDatas(filtreAppreciation);
    this.appreciationService.getNbAppreciationsByParams(filtreAppreciation)
      .subscribe((result) => {
        this.nbTotalAppreciations = result.count;
      });

    this.appreciationService.getAppreciationsByParams(filtreAppreciation, this.updateFiltre)
      .subscribe((appreciations: Appreciation[]) => {
        this.appreciationService.appreciationsList = plainToClass(Appreciation, appreciations); // On conserve le resultat pour naviguer dans les appreciations sur la page appreciation
        this.appreciationsList = plainToClass(Appreciation, appreciations);
        this.loading = false;
      },
        error => {
          console.log(error);
          this.loading = false;
        });

  }

  loadAppreciationsLazy(event: LazyLoadEvent) {
    // event.first = First row offset
    // event.rows = Number of rows per page
    // event.sortField = Field name to sort with
    // event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    // filters: FilterMetadata object having field as key and filter value, filter matchMode as value
    this.filtreAppreciation.offset = event.first;
    this.filtreAppreciation.limit = event.rows;
    this.filtreAppreciation.sort_property = FiltreAppreciation.getSortProperty(event.sortField);
    this.filtreAppreciation.sort_direction = FiltreAppreciation.getSortOrder(event.sortOrder);

    this.searchAppreciation(this.filtreAppreciation);
  }

  refresh() {
    this.refreshList.emit();
  }

  resetPagination() {
    this.dt.reset();
  }

  updateFiltreAppreciation(filtreAppreciation: FiltreAppreciation) {
    this.filtreAppreciation = filtreAppreciation;
  }

  getCols(): void {
    this.cols = [
      { field: 'client_nom', header: 'Nom/Prénom' },
      { field: 'date_creation', header: 'Date' },
      { field: 'valeur', header: 'Niveau' },
      { field: 'association', header: 'Association' },
    ];
  }

  getClassTrAppreciation(rowData) {
    let classTrAppreciation = 'selectable';

    if (rowData['alerte']) {
      classTrAppreciation += ' tr-appreciation-alerte';
    }

    return classTrAppreciation;
  }

  getClassTdAppreciation(field) {
    if (field === 'valeur') {
      return 'td-centered';
    }

    return '';
  }

  getRowLabel(field, rowData) {
    if (field === 'association') {
      return rowData[field] ? rowData[field].libelle : '';
    } else if (field === 'client_nom') {
      let labelSujet = '';
      if (rowData[field]) {
        labelSujet += rowData[field];
      }
      if (rowData['client_prenom']) {
        labelSujet += ' ' + rowData['client_prenom'];
      }
      return labelSujet;
    }

    return rowData[field];
  }

  getTooltip(field, rowData) {
    let tooltipLabel = null;
    if (field === 'client_nom') {
      tooltipLabel = rowData['client_id_philia'] ? 'Id Philia : ' + rowData['client_id_philia'] + '\n' : '';
      tooltipLabel += rowData['commentaire'] ? rowData['commentaire'] + '\n' : '';
    }

    if (tooltipLabel !== null && tooltipLabel !== '') {
      tooltipLabel.substring(0, tooltipLabel.length - 2); // On supprime le dernier retour a la ligne
    }

    return tooltipLabel;
  }

  getSortableColumnDisabled(field) {
    return false;
  }

  customSort(event) {
    this.filtreAppreciation.sort_property = event.field;
    this.filtreAppreciation.sort_direction = event.order;

    this.searchAppreciation(this.filtreAppreciation);
  }

  getExportItems() {
    this.exportItems = [
      {
        label: 'Exporter la page',
        icon: 'zmdi zmdi-file',
        items: [
          {
            label: 'Format Excel', icon: 'zmdi zmdi-file', command: () => {
              this.exportExcel(false);
            }
          },
          {
            label: 'Format CSV', icon: 'zmdi zmdi-file-text', command: () => {
              this.exportCsv(false);
            }
          }
        ]
      },
      {
        label: 'Exporter toutes les appreciations',
        icon: 'zmdi zmdi-file-text',
        items: [
          {
            label: 'Format Excel', icon: 'zmdi zmdi-file', command: () => {
              this.exportExcel(true);
            }
          },
          {
            label: 'Format CSV', icon: 'zmdi zmdi-file-text', command: () => {
              this.exportCsv(true);
            }
          }
        ]
      }
    ];
  }

  exportExcel(allAppreciations = true) {
    if (allAppreciations) {
      let filtreAppreciationAll = classToClass(this.filtreAppreciation); // On clone le filtre appreciation
      filtreAppreciationAll.limit = null;  // On enleve la limite pour récupérer tous les appreciations
      filtreAppreciationAll.offset = null; // On réinitialise l'offset
      this.appreciationService.getAppreciationsByParams(filtreAppreciationAll)
        .subscribe((appreciations: Appreciation[]) => {
          this.exportService.exportAsExcelFile(this.getDataForExport(plainToClass(Appreciation, appreciations)), 'appreciations');
        });
    } else {
      this.exportService.exportAsExcelFile(this.getDataForExport(), 'appreciations');
    }
  }

  exportCsv(allAppreciations = true) {
    if (allAppreciations) {
      let filtreAppreciationAll = classToClass(this.filtreAppreciation); // On clone le filtre appreciation
      filtreAppreciationAll.limit = null;  // On enleve la limite pour récupérer tous les appreciations
      filtreAppreciationAll.offset = null; // On réinitialise l'offset
      this.appreciationService.getAppreciationsByParams(filtreAppreciationAll)
        .subscribe((appreciations: Appreciation[]) => {
          this.exportService.exportAsCsvFile(this.getDataForExport(plainToClass(Appreciation, appreciations)), 'appreciations');
        });
    } else {
      this.exportService.exportAsCsvFile(this.getDataForExport(), 'appreciations');
    }
  }

  getDataForExport(appreciationsList: Appreciation[] = this.appreciationsList) {
    let data = [];
    appreciationsList.forEach(appreciation => {
      data.push(appreciation.serializeForExport());
    });

    return data;
  }

  editAppreciation(alerte, event) {
    if (alerte) {
      let isNewTab = false;
      if (event.ctrlKey) { // Si Ctrl + Clic
        isNewTab = true;
      }

      let url = environment.appUrlVi + '/in/alerte/' + alerte.id_alerte;

      if (isNewTab) {
        window.open(url.toString(), '_blank');
      } else {
        window.open(url.toString(), '_self');
      }
    }
  }

  get currentPage(): number {
    let currentPage = 0;
    if (this.appreciationsList.length > 0 && this.dt.rows > 0) {
      currentPage = this.dt.first / this.dt.rows + 1;
    }
    return currentPage;
  }

  get nbTotalPages(): number {
    let nbTotalPages = 0;
    if (this.nbTotalAppreciations > 0 && this.appreciationsList.length > 0 && this.dt.rows > 0) {
      nbTotalPages = Math.ceil(this.nbTotalAppreciations / this.dt.rows); // On arrondi au supérieur
    }
    return nbTotalPages;
  }

  get firstElementNumber() {
    if (!this.nbTotalAppreciations || !this.currentPage) {
      return 0;
    }

    return this.filtreAppreciation.limit * (this.currentPage - 1) + 1;
  }

  get lastElementNumber() {
    if (!this.nbTotalAppreciations || !this.currentPage) {
      return 0;
    }

    let lastElementNumber = this.firstElementNumber + this.filtreAppreciation.limit - 1;

    if (lastElementNumber < this.nbTotalAppreciations) {
      return lastElementNumber;
    }
    return this.nbTotalAppreciations;
  }

}
