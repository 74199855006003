import {FormControl, FormGroup, Validators} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {Globals} from '../utils/globals';
import {Alerte} from './alerte';
import {Association} from './association';
import {Echange} from './echange';
import {EvaluationContenu} from './evaluation-contenu';
import {Federation} from './federation';
import {Parametre} from './parametre';
import {Personne} from './personne';
import {Utilisateur} from './utilisateur';

export class EvaluationEntete {
  id_evaluation_entete: number;

  @Type(() => Utilisateur)
  redacteur: Utilisateur;

  @Type(() => Utilisateur)
  evaluateur: Utilisateur;

  @Type(() => Date)
  echeance: Date;

  @Type(() => Parametre)
  type_public: Parametre;

  @Type(() => Date)
  synchronisation: Date;

  @Type(() => Date)
  date_evaluation: Date;

  @Type(() => Parametre)
  statut_evaluation: Parametre;

  @Type(() => Echange)
  echange: Echange;

  @Type(() => Alerte)
  alerte: Alerte;

  @Type(() => Federation)
  federation: Federation;

  @Type(() => Association)
  association: Association;

  @Type(() => Personne)
  client: Personne;

  @Type(() => EvaluationContenu)
  evaluation_contenu: EvaluationContenu;

  @Type(() => Parametre)
  canal_origine: Parametre;

  canal_origine_sens: string;

  @Type(() => Parametre)
  motif: Parametre;

  @Type(() => Personne)
  contactant: Personne;

  evaluation_existante: boolean;

  @Type(() => EvaluationEntete)
  evaluation_reevaluation: EvaluationEntete;

  objet = 'Evaluation';

  @Type(() => Date)
  created: Date;

  @Type(() => Date)
  updated: Date;

  @Type(() => Utilisateur)
  modificateur: Utilisateur;

  modification_en_cours: boolean;

  version: string;

  id_evaluation_origine: number;

  id_evaluation_edited: number;

  @Type(() => Parametre)
  motif_annulation: Parametre;

  @Type(() => Date)
  date_transmission: Date;

  @Type(() => Utilisateur)
  utilisateur_transmission: Utilisateur;

  get client_telephone() {
    let client_telephone = '';
    if (this.client) {
      client_telephone = this.client.telephone;
    }
    return client_telephone;
  }

  static getForm(evaluation_entete = null): FormGroup {
    if (!evaluation_entete) {
      evaluation_entete = new EvaluationEntete();
    }

    let form = new FormGroup({
      'id_evaluation_entete': new FormControl(evaluation_entete.id_evaluation_entete),
      'redacteur': new FormControl(evaluation_entete.redacteur),
      'evaluateur': new FormControl(evaluation_entete.evaluateur),
      'echeance': new FormControl(evaluation_entete.echeance, Validators.required),
      'type_public': new FormControl(evaluation_entete.type_public, Validators.required),
      'synchronisation': new FormControl(evaluation_entete.synchronisation),
      'date_evaluation': new FormControl(evaluation_entete.date_evaluation),
      'statut_evaluation': new FormControl(evaluation_entete.statut_evaluation),
      'echange': new FormControl(evaluation_entete.echange),
      'alerte': new FormControl(evaluation_entete.alerte),
      'federation': new FormControl(evaluation_entete.federation, Validators.required),
      'association': new FormControl(evaluation_entete.association, Validators.required),
      'client': Personne.getForm(evaluation_entete.client),
      'evaluation_contenu': EvaluationContenu.getForm(evaluation_entete.evaluation_contenu),
      'canal_origine': new FormControl(evaluation_entete.canal_origine),
      'canal_origine_sens': new FormControl(evaluation_entete.canal_origine_sens),
      'motif': new FormControl(evaluation_entete.motif),
      'contactant': new FormControl(evaluation_entete.contactant),
      'evaluation_existante': new FormControl(evaluation_entete.evaluation_existante),
      'evaluation_reevaluation': new FormControl(evaluation_entete.evaluation_reevaluation),
      'objet': new FormControl(evaluation_entete.objet, Validators.required),
      'created': new FormControl(evaluation_entete.created),
      'updated': new FormControl(evaluation_entete.updated),
      'modificateur': new FormControl(evaluation_entete.modificateur),
      'modification_en_cours': new FormControl(evaluation_entete.modification_en_cours),
      'version': new FormControl(evaluation_entete.version),
      'id_evaluation_origine': new FormControl(evaluation_entete.id_evaluation_origine),
      'id_evaluation_edited': new FormControl(evaluation_entete.id_evaluation_edited),
      'motif_annulation': new FormControl(evaluation_entete.motif_annulation),
      'date_transmission': new FormControl(evaluation_entete.date_transmission),
      'utilisateur_transmission': new FormControl(evaluation_entete.utilisateur_transmission)
    });

    if (evaluation_entete.statut_evaluation
      && evaluation_entete.statut_evaluation.code_parametre !== 'INI'
      && evaluation_entete.statut_evaluation.code_parametre !== 'O') {
      form.get('evaluateur').disable();
    }

    return form;
  }

  serialize() {
    let serializeObject = classToPlain(this);
    delete serializeObject['id_evaluation_entete'];

    if (this.evaluation_contenu !== null && this.evaluation_contenu !== undefined) {
      serializeObject['evaluation_contenu'] = this.evaluation_contenu.serialize();
    }

    if (this.redacteur !== null && this.redacteur !== undefined) {
      serializeObject['redacteur'] = this.redacteur.id_utilisateur;
    }

    if (this.evaluateur !== null && this.evaluateur !== undefined) {
      serializeObject['evaluateur'] = this.evaluateur.id_utilisateur;
    }

    if (this.type_public !== null && this.type_public !== undefined) {
      serializeObject['type_public'] = this.type_public.id_parametre;
    }

    if (this.statut_evaluation !== null && this.statut_evaluation !== undefined) {
      serializeObject['statut_evaluation'] = this.statut_evaluation.id_parametre;
    }

    if (this.motif !== null && this.motif !== undefined) {
      serializeObject['motif'] = this.motif.id_parametre;
    }

    if (this.canal_origine !== null && this.canal_origine !== undefined) {
      serializeObject['canal_origine'] = this.canal_origine.id_parametre;
    }

    if (this.echange !== null && this.echange !== undefined) {
      serializeObject['echange'] = this.echange.id_echange;
    }

    if (this.alerte !== null && this.alerte !== undefined) {
      serializeObject['alerte'] = this.alerte.id_alerte;
    }

    if (this.federation !== null && this.federation !== undefined) {
      serializeObject['federation'] = this.federation.id_federation;
    }

    if (this.association !== null && this.association !== undefined) {
      serializeObject['association'] = this.association.id_association;
    }

    if (this.client !== null && this.client !== undefined) {
      serializeObject['client'] = this.client.serialize();
    }

    if (this.modificateur !== null && this.modificateur !== undefined) {
      serializeObject['modificateur'] = this.modificateur.id_utilisateur;
    }

    if (this.evaluation_reevaluation !== null && this.evaluation_reevaluation !== undefined) {
      serializeObject['evaluation_reevaluation'] = this.evaluation_reevaluation.id_evaluation_entete;
    }

    if (this.motif_annulation !== null && this.motif_annulation !== undefined) {
      serializeObject['motif_annulation'] = this.motif_annulation.id_parametre;
    }

    // Conversion des dates en FR
    for (let propertyName in serializeObject) { // On parcourt les proprietes de l'objet
      if (serializeObject[propertyName] && serializeObject[propertyName] instanceof Date) { // Si c'est une date non null
        serializeObject[propertyName] = Globals.getDateWithTimezone(serializeObject[propertyName]);
      }
    }

    return serializeObject;
  }

  serializeForExport() {
    let serializeObject = {

      'Nom': (this.client && this.client.nom) ? this.client.nom : '',
      'Prénom': (this.client && this.client.prenom) ? this.client.prenom : '',
      'Téléphone': this.client_telephone,
      'Type de public': this.type_public ? this.type_public.libelle : '',
      'Échéance': this.echeance ? Globals.formatDate(this.echeance) : '',
      'Statut': this.statut_evaluation ? this.statut_evaluation.libelle : '',
      'Évaluateur': this.evaluateur ? this.evaluateur.libelle : '',
      'Synchronisation': this.synchronisation ? Globals.formatDate(this.synchronisation) : '',
      'Date évaluation': this.date_evaluation ? Globals.formatDate(this.date_evaluation) : '',
      'Association': this.association ? this.association.libelle : '',
    };

    return serializeObject;
  }

  isSocialVisible() {
    if (this.type_public && this.type_public.code_parametre === 'ENFPAR') {
      return true;
    }
    return false;
  }

  isHabitudesVisible() {
    if (this.type_public && this.type_public.code_parametre !== 'ENTMAI') {
      return true;
    }
    return false;
  }

  isAlimentationVisible() {
    let menusInterventionList = [];

    if (this.evaluation_contenu && this.evaluation_contenu.item_menu_intervention) {
      menusInterventionList = this.evaluation_contenu.item_menu_intervention.split(';');
    }

    if (this.type_public
      && (this.type_public.code_parametre === 'SERSOISEN'
        || (this.type_public.code_parametre === 'ACCHAN')
        || (this.type_public.code_parametre === 'ENFPAR')
        || (this.type_public.code_parametre === 'ENTMAI' && menusInterventionList.includes('Alimentation'))
      )) {
      return true;
    }
    return false;
  }

  isAccompagnementVisible() {
    let menusInterventionList = [];

    if (this.evaluation_contenu && this.evaluation_contenu.item_menu_intervention) {
      menusInterventionList = this.evaluation_contenu.item_menu_intervention.split(';');
    }

    if (this.type_public
      && (this.type_public.code_parametre === 'SERSOISEN'
        || (this.type_public.code_parametre === 'ACCHAN')
        || (this.type_public.code_parametre === 'ENFPAR')
        || (this.type_public.code_parametre === 'ENTMAI' && menusInterventionList.includes('Accompagnement'))
      )) {
      return true;
    }
    return false;
  }

  isJardBriVisible() {
    let menusInterventionList = [];

    if (this.evaluation_contenu && this.evaluation_contenu.item_menu_intervention) {
      menusInterventionList = this.evaluation_contenu.item_menu_intervention.split(';');
    }

    if (this.type_public
      && (
        (this.type_public.code_parametre === 'SERSOISEN' && menusInterventionList.includes('Jardinage - Bricolage'))
        || (this.type_public.code_parametre === 'ACCHAN' && menusInterventionList.includes('Jardinage - Bricolage'))
        || (this.type_public.code_parametre === 'ENTMAI' && menusInterventionList.includes('Jardinage - Bricolage'))
      )) {
      return true;
    }
    return false;
  }

  isGardiennageVisible() {
    let menusInterventionList = [];

    if (this.evaluation_contenu && this.evaluation_contenu.item_menu_intervention) {
      menusInterventionList = this.evaluation_contenu.item_menu_intervention.split(';');
    }

    if (this.type_public
      && (this.type_public.code_parametre === 'SERSOISEN'
        || (this.type_public.code_parametre === 'ACCHAN' && menusInterventionList.includes('Gardiennage'))
        || (this.type_public.code_parametre === 'ENTMAI' && menusInterventionList.includes('Gardiennage'))
      )) {
      return true;
    }
    return false;
  }

  isAutonomieVisible() {
    let menusInterventionList = [];

    if (this.evaluation_contenu && this.evaluation_contenu.item_menu_intervention) {
      menusInterventionList = this.evaluation_contenu.item_menu_intervention.split(';');
    }

    if (this.type_public
      && (this.type_public.code_parametre === 'ENFPAR' && menusInterventionList.includes('Autonomie'))
      || (this.type_public.code_parametre === 'ENTMAI' && menusInterventionList.includes('Autonomie'))
    ) {
      return true;
    }
    return false;
  }

  hasEntourageEnfant(): boolean {
    let hasEntourageEnfant = false;
    this.evaluation_contenu.entourages.forEach(function (entourage) {
      if (entourage.enfant) {
        hasEntourageEnfant = true;
      }
    });
    return hasEntourageEnfant;
  }

}
