import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { TableauBordRoutingModule } from './tableau-bord-routing.module';
import { TableauBordComponent } from './tableau-bord/tableau-bord.component';
import { RecapitulatifClientComponent } from './recapitulatif-client/recapitulatif-client.component';

@NgModule({
  declarations: [TableauBordComponent, RecapitulatifClientComponent],
  imports: [
    CommonModule,
    SharedModule,
    TableauBordRoutingModule
  ]
})
export class TableauBordModule { }
