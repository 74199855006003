import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DocumentationRoutingModule } from './documentation-routing.module';
import { DocumentationTableauBordComponent } from './documentation-tableau-bord/documentation-tableau-bord.component';
import { DocumentationGestionComponent } from './documentation-gestion/documentation-gestion.component';


@NgModule({
  declarations: [DocumentationTableauBordComponent],
  imports: [
    CommonModule,
    DocumentationRoutingModule
  ]
})
export class DocumentationModule { }
