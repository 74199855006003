import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';

import { forkJoin } from 'rxjs';
import { plainToClass, classToClass } from 'class-transformer';
import * as moment from 'moment';

import { MessageService, MenuItem, LazyLoadEvent } from 'primeng/api';

import { AlerteService, ActionService, ParametreService, AuthenticationService, ExportService } from '../../shared/_services';
import { Alerte, Action, Parametre, Utilisateur } from '../../shared/_models';

import { FiltreAlerte } from '../../shared/utils/filtre-alerte';

import { Globals } from '../../shared/utils/globals';
import { environment } from "../../../environments/environment";

@Component({
  selector: 'app-liste-alertes',
  templateUrl: './liste-alertes.component.html',
  styleUrls: ['./liste-alertes.component.css']
})
export class ListeAlertesComponent implements OnInit {

  @Input()
  filtreAlerte: FiltreAlerte = new FiltreAlerte(); // propriété d'entrée du composant : le filtre alerte

  @Input()
  updateFiltre = true; // propriété d'entrée du composant : mise a jour du filtre globale des alertes

  @Output()
  refreshList = new EventEmitter<any>();

  @ViewChild('dt', { static: true }) dt; // Composant datatable qui affiche la liste des alertes

  currentUtilisateur: Utilisateur;
  nbTotalAlertes = 0;
  alertesList: Alerte[] = [];
  cols: any[];
  loading: boolean;
  rowsPerPageOptions = [20, 50, 100];
  itemsContextMenu: MenuItem[];
  selectedAlerte: Alerte;
  statutsAlerteList: Parametre[];
  statutsActionList: Parametre[];
  exportItems: MenuItem[];
  motifsList: Parametre[];

  datePipeFormatWithoutTime = Globals.datePipeFormatWithoutTime;
  datePipeFormat = Globals.datePipeFormat;
  timeFormat = Globals.timeFormat;

  alerteForm: FormGroup;
  showPopupQualificationAlerte = false;
  submittingAlerte = false;

  showPopupAction = false;
  canEditJustification: boolean;
  newActionAlerteSelected: Action;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private alerteService: AlerteService,
    private actionService: ActionService,
    private parametreService: ParametreService,
    private exportService: ExportService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.authenticationService.currentUtilisateur$.subscribe(utilisateur => {
      if (utilisateur) {
        this.currentUtilisateur = this.authenticationService.currentUtilisateurValue;
      }
    });

    this.getCols();
    this.getStatutsAlerte();
    this.getStatutsAction();
    this.getMotifs();
    this.getExportItems();
  }

  getStatutsAlerte() {
    this.parametreService.getParametresByParams({ type: 'STATUT_ALERTE' })
      .subscribe((parametres: Parametre[]) => {
        this.statutsAlerteList = parametres;
      });
  }

  getStatutsAction() {
    this.parametreService.getParametresByParams({type: 'STATUT_A'})
    .subscribe((parametres: Parametre[]) => {
        this.statutsActionList = parametres;
    });
  }

  getMotifs() {
    this.parametreService.getParametresByParams({ type: 'MOTIF_ALERTE' })
      .subscribe((parametres: Parametre[]) => {
        this.motifsList = parametres;
      });
  }

  searchAlerte(filtreAlerte) {
    this.loading = true;
    this.filtreAlerte.setDatas(filtreAlerte);
    this.alerteService.getNbAlertesByParams(filtreAlerte)
      .subscribe((result) => {
        this.nbTotalAlertes = result.count;
      });

    this.alerteService.getAlertesByParams(filtreAlerte, this.updateFiltre)
      .subscribe((alertes: Alerte[]) => {
        this.alerteService.alertesList = plainToClass(Alerte, alertes); // On conserve le resultat pour naviguer dans les alertes sur la page alerte
        this.alertesList = plainToClass(Alerte, alertes);
        this.loading = false;
      },
        error => {
          console.log(error);
          this.loading = false;
        });

  }

  loadAlertesLazy(event: LazyLoadEvent) {
    // event.first = First row offset
    // event.rows = Number of rows per page
    // event.sortField = Field name to sort with
    // event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    // filters: FilterMetadata object having field as key and filter value, filter matchMode as value
    this.filtreAlerte.offset = event.first;
    this.filtreAlerte.limit = event.rows;
    this.filtreAlerte.sort_property = FiltreAlerte.getSortProperty(event.sortField);
    this.filtreAlerte.sort_direction = FiltreAlerte.getSortOrder(event.sortOrder);

    this.searchAlerte(this.filtreAlerte);
  }

  refresh() {
    this.refreshList.emit();
  }

  resetPagination() {
    this.dt.reset();
  }

  updateFiltreAlerte(filtreAlerte: FiltreAlerte) {
    this.filtreAlerte = filtreAlerte;
  }

  getCols(): void {
    this.cols = [
      { field: 'client_nom', header: 'Nom/Prénom' },
      { field: 'date_creation', header: 'Date' },
      { field: 'statut_alerte', header: 'Statut' },
      { field: 'motif_alerte', header: 'Motif' },
      { field: 'date_qualification', header: 'Date Vérification' },
      { field: 'qualificateur', header: 'Vérificateur' },
      { field: 'actions', header: 'Actions' },
      { field: 'firstDateEcheance', header: 'Echéance' },
      { field: 'association', header: 'Association' },
    ];
  }

  getClassTrAlerte(rowData) {
    let classTrAlerte = 'selectable';

    if (rowData['priorite'] === 'U') {
      classTrAlerte += ' tr-alerte-urgent';
    }
    if (rowData['statut_alerte'].code_parametre === 'F') {
      classTrAlerte += ' tr-alerte-closed';
    }
    if (rowData['firstDateEcheance'] !== null && rowData['firstDateEcheance'] < Date.now()) {
      classTrAlerte += ' tr-alerte-echeancePassed';
    }

    return classTrAlerte;
  }

  getClassTdAlerte(field) {
    if (field === 'statut_alerte' || field === 'actions' || field === 'date_qualification') {
      return 'td-centered';
    }
    return '';
  }

  getRowLabel(field, rowData) {
    if (field === 'statut_alerte' || field === 'motif_alerte') {
      return rowData[field] ? rowData[field].libelle : '';
    } else if (field === 'association') {
      return rowData[field] ? rowData[field].libelle : '';
    } else if (field === 'client_nom') {
      let labelSujet = '';
      if (rowData[field]) {
        labelSujet += rowData[field];
      }
      if (rowData['client_prenom']) {
        labelSujet += ' ' + rowData['client_prenom'];
      }
      return labelSujet;
    } else if (field === 'actions') {
      return rowData['openActionsLength'] + '/' + rowData['actions'].length;
    } else if (field === 'date_qualification') {
      return Alerte.getDateQualificationFromData(rowData);
    } else if (field === 'qualificateur') {
      return Alerte.getQualificateurFromData(rowData) ? Alerte.getQualificateurFromData(rowData).libelle : '';
    }

    return rowData[field];
  }

  getTooltip(field, rowData) {
    let tooltipLabel = null;
    if (field === 'client_nom') {
      tooltipLabel = rowData['client_id_philia'] ? 'Id Philia : ' + rowData['client_id_philia'] + '\n' : '';
      tooltipLabel += rowData['description'] ? rowData['description'] + '\n' : '';
    }

    if (tooltipLabel !== null && tooltipLabel !== '') {
      tooltipLabel.substring(0, tooltipLabel.length - 2); // On supprime le dernier retour a la ligne
    }

    return tooltipLabel;
  }

  getSortableColumnDisabled(field) {
    if (field === 'client_nom' || field === 'date_creation' || field === 'statut_alerte'
      || field === 'motif_alerte' || field === 'date_qualification' || field === 'qualificateur') {
      return true;
    }

    return false;
  }

  customSort(event) {
    this.filtreAlerte.sort_property = event.field;
    this.filtreAlerte.sort_direction = event.order;

    this.searchAlerte(this.filtreAlerte);
  }

  updateContextMenuAlerte(event) {
    this.selectedAlerte = plainToClass(Alerte, event.data);
    this.alerteForm = Alerte.getForm(this.selectedAlerte);
    this.updateCanEditJustification();
    this.updateNewActionAlerteSelected();
    let itemsContextMenu = [];
    let disabledCloseAlerte = this.selectedAlerte.isClosed(this.currentUtilisateur) || this.selectedAlerte.openActionsLength > 0;

    if (this.selectedAlerte.statut_alerte.code_parametre !== 'F') {
      itemsContextMenu = [
        { label: 'Vérifier l\'alerte', icon: 'pi pi-search-plus', disabled: this.selectedAlerte.isClosed(this.currentUtilisateur), command: () => this.showPopupQualification() }
      ];
    } else {
      itemsContextMenu = [
        { label: 'Dé-clôturer', icon: 'pi pi-step-backward', disabled: !this.selectedAlerte.canUnclose(), command: () => this.unCloseSelectedAlerte() }
      ];
    }

    if (this.selectedAlerte.statut_alerte.code_parametre === 'JUS') {
      itemsContextMenu.push({ label: 'Ajouter une action', icon: 'pi pi-plus-circle', disabled: this.selectedAlerte.isClosed(this.currentUtilisateur), command: () => this.addAction() });
      itemsContextMenu.push({ label: 'Fermer toutes les actions', icon: 'pi pi-times', disabled: this.disabledCloseAllActionsMenu(this.selectedAlerte), command: () => this.closeAllActions() });
      itemsContextMenu.push({ label: 'Clôturer', icon: 'pi pi-times-circle', disabled: disabledCloseAlerte, command: () => this.closeAlerte() });
    }

    this.itemsContextMenu = itemsContextMenu;
  }

  disabledCloseAllActionsMenu(selectedAlerte: Alerte) {
    // Cas particulier "reponsable" => peux fermer toutes les actions si toutes les action ouvertes lui sont attribué
    if ( ! selectedAlerte.isClosed(this.currentUtilisateur) && selectedAlerte.openActionsLength > 0 ) {
      let actions = selectedAlerte.actions;
      let actionToAnother = false;
      actions.forEach((action) => {
          if ( action.responsable && this.currentUtilisateur.id_utilisateur !== action.responsable.id_utilisateur && action.statut_action.code_parametre === 'E' ) {
              actionToAnother = true;
          }
      });
      return actionToAnother; // Menu inactif si au moins une action ouverte est attribuée à quelqu'un d'autre
    }

    return selectedAlerte.isClosed(this.currentUtilisateur) || selectedAlerte.openActionsLength === 0;
  }

  showPopupQualification() {
    this.showPopupQualificationAlerte = true;
  }

  unCloseSelectedAlerte() {
    let newStatutAlerte = '';
    if (this.selectedAlerte.actions.length === 0) {
      newStatutAlerte = 'O';
    } else if (this.selectedAlerte.justifiee) {
      newStatutAlerte = 'JUS';
    } else {
      newStatutAlerte = 'INJ';
    }

    this.statutsAlerteList.forEach((statut) => {
      if ( statut.code_parametre === newStatutAlerte ) {
          this.selectedAlerte.statut_alerte = statut; // On met a jour le statut de l'alerte
      }
    });
    // On met à jour l'alerte
    this.alerteService.updateAlerte(this.selectedAlerte)
    .subscribe((alerteUpdated: Alerte) => {
      this.messageService.add({severity: 'success', summary: 'Succès', detail: 'Alerte dé-clôturée avec succès.'});
      this.searchAlerte(this.filtreAlerte); // On recharge la liste des alertes
    });
  }

  closeAlerte() {
    this.statutsAlerteList.forEach((statut) => {
      if ( statut.code_parametre === 'F' ) {
          this.selectedAlerte.statut_alerte = statut; // On met le statut de l'alerte a fermé
      }
    });
    // On met à jour l'alerte
    this.alerteService.updateAlerte(this.selectedAlerte)
    .subscribe((alerteUpdated: Alerte) => {
      this.messageService.add({severity: 'success', summary: 'Succès', detail: 'Alerte fermée avec succès.'});
      this.searchAlerte(this.filtreAlerte); // On recharge la liste des alertes
    });
  }

  addAction() {
    this.showPopupAction = true;
  }

  closeAllActions() {
    let updateActionList = [];
    this.statutsActionList.forEach((statutAction) => {
      if ( statutAction.code_parametre === 'F' ) {
        this.selectedAlerte.actions.forEach((action) => {
          if ( action.statut_action.code_parametre !== 'F' ) {
              action.id_alerte = this.selectedAlerte.id_alerte;
              action.statut_action = statutAction; // On met le statut de l'action a fermé
              updateActionList.push(this.actionService.updateAction(action));
          }
        });
      }
    });
    // On met à jour les actions
    if (updateActionList.length > 0) {
      forkJoin(
        updateActionList
      ).subscribe((actionsList) => {
        this.messageService.add({severity: 'success', summary: 'Succès', detail: 'Actions fermées avec succès.'});
        this.searchAlerte(this.filtreAlerte); // On recharge la liste des alertes
      });
    }
  }

  canEdit() {
    if (!this.currentUtilisateur.hasFonctionnalite('vigilance', 'edit_alertes')) {
      return false;
    } else if (this.alerteForm.value.statut_alerte.code_parametre === 'F') {
      let periodeModif = 24; // Période de 24H
      let dateLimitEditable = moment(this.alerteForm.value.updated).add(periodeModif, 'h'); // On ajoute le nombre d'heures pour obtenir la date limit
      let isDateLimitValid = moment(dateLimitEditable).isAfter(moment(new Date()));

      return isDateLimitValid;
    }

    return true;
  }

  onChangeJustifiee(justifiee: boolean) {
    let code_parametre_statut = '';
    if (justifiee) { // Justifiee
      code_parametre_statut = 'JUS';
    } else { // Injustifiee
      code_parametre_statut = 'INJ';
      this.alerteForm.patchValue({motif_alerte: null});
    }
    let self = this;
      this.statutsAlerteList.forEach(function (parametre) {
          if (parametre.code_parametre === code_parametre_statut) {
              self.alerteForm.patchValue({statut_alerte: parametre});
          }
      });
    this.alerteForm.updateValueAndValidity();
  }

  onChangeMotif(event) {
    /*
    let code_parametre_statut = '';
    if (event.value && this.alerteForm.value.justifiee) { // Justifiee
      code_parametre_statut = 'JUS';
    } else { // Injustifiee
      code_parametre_statut = 'INJ';
    }
    let self = this;
    this.statutsAlerteList.forEach(function (parametre) {
      if (parametre.code_parametre === code_parametre_statut) {
        self.alerteForm.patchValue({ statut_alerte: parametre });
      }
    });
    this.alerteForm.updateValueAndValidity();
    */
  }

  submitQualificationAlerte() {
    if (this.alerteForm.valid) {
      // On enleve les controles uniquement nécessaires à l'affichage du formulaire
      this.alerteForm.get('isStatutClosed').disable();
      this.submittingAlerte = true;

      let alerte = plainToClass(Alerte, this.alerteForm.value);

      this.alerteService.updateAlerte(alerte)
        .subscribe(
          (alerteUpdated: Alerte) => {
            console.log(`update alerte`);
            this.searchAlerte(this.filtreAlerte); // On recharge la liste des alertes
            this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Alerte mise à jour avec succès.' });
            this.submittingAlerte = false;
          },
          error => {
            console.log(error);
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Erreur dans la mise à jour de l\'alerte.' });
            this.submittingAlerte = false;
          }
        );
    } else {
      this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Le formulaire de l\'alerte est invalide' });
    }

    this.showPopupQualificationAlerte = false;
  }

  getExportItems() {
    this.exportItems = [
      {
        label: 'Exporter la page',
        icon: 'zmdi zmdi-file',
        items: [
          {
            label: 'Format Excel', icon: 'zmdi zmdi-file', command: () => {
              this.exportExcel(false);
            }
          },
          {
            label: 'Format CSV', icon: 'zmdi zmdi-file-text', command: () => {
              this.exportCsv(false);
            }
          }
        ]
      },
      {
        label: 'Exporter toutes les alertes',
        icon: 'zmdi zmdi-file-text',
        items: [
          {
            label: 'Format Excel', icon: 'zmdi zmdi-file', command: () => {
              this.exportExcel(true);
            }
          },
          {
            label: 'Format CSV', icon: 'zmdi zmdi-file-text', command: () => {
              this.exportCsv(true);
            }
          }
        ]
      }
    ];
  }

  exportExcel(allAlertes = true) {
    if (allAlertes) {
      let filtreAlerteAll = classToClass(this.filtreAlerte); // On clone le filtre alerte
      filtreAlerteAll.limit = null;  // On enleve la limite pour récupérer tous les alertes
      filtreAlerteAll.offset = null; // On réinitialise l'offset
      this.alerteService.getAlertesByParams(filtreAlerteAll)
        .subscribe((alertes: Alerte[]) => {
          this.exportService.exportAsExcelFile(this.getDataForExport(plainToClass(Alerte, alertes)), 'alertes');
        });
    } else {
      this.exportService.exportAsExcelFile(this.getDataForExport(), 'alertes');
    }
  }

  exportCsv(allAlertes = true) {
    if (allAlertes) {
      let filtreAlerteAll = classToClass(this.filtreAlerte); // On clone le filtre alerte
      filtreAlerteAll.limit = null;  // On enleve la limite pour récupérer tous les alertes
      filtreAlerteAll.offset = null; // On réinitialise l'offset
      this.alerteService.getAlertesByParams(filtreAlerteAll)
        .subscribe((alertes: Alerte[]) => {
          this.exportService.exportAsCsvFile(this.getDataForExport(plainToClass(Alerte, alertes)), 'alertes');
        });
    } else {
      this.exportService.exportAsCsvFile(this.getDataForExport(), 'alertes');
    }
  }

  getDataForExport(alertesList: Alerte[] = this.alertesList) {
    let data = [];
    alertesList.forEach(alerte => {
      data.push(alerte.serializeForExport());
    });

    return data;
  }

  editAlerte(id_alerte, event) {
    let isNewTab = false;
    if (event.ctrlKey) { // Si Ctrl + Clic
      isNewTab = true;
    }

    let url = environment.appUrlVi + '/in/alerte/' + id_alerte;
    if (isNewTab) {
      window.open(url.toString(), '_blank');
    } else {
      window.open(url.toString(), '_self');
    }
  }

  cancelNewAction() {
    this.showPopupAction = false;
  }

  onActionCreated(event) {
    this.selectedAlerte.actions.push(event);
    this.showPopupAction = false;
  }

  updateCanEditJustification() {
    let canEditJustification = false;

    if (this.alerteForm) {
      let alerte = plainToClass(Alerte, this.alerteForm.value);
      canEditJustification = alerte.canEditJustification();
    }

    this.canEditJustification = canEditJustification;
  }

  updateNewActionAlerteSelected() {
    let newAction = null;
    if (this.selectedAlerte) {
      newAction = new Action();
      newAction.alerte = this.selectedAlerte;
      newAction.redacteur = this.currentUtilisateur;
      newAction.responsable = this.currentUtilisateur;
      this.statutsActionList.forEach(function(statutAction) {
        if (statutAction.is_default) {
          newAction.statut_action = statutAction; // On definit le statut par defaut
        }
      });
    }

    this.newActionAlerteSelected = newAction;
  }

  get currentPage(): number {
    let currentPage = 0;
    if (this.alertesList.length > 0 && this.dt.rows > 0) {
      currentPage = this.dt.first / this.dt.rows + 1;
    }
    return currentPage;
  }

  get nbTotalPages(): number {
    let nbTotalPages = 0;
    if (this.nbTotalAlertes > 0 && this.alertesList.length > 0 && this.dt.rows > 0) {
      nbTotalPages = Math.ceil(this.nbTotalAlertes / this.dt.rows); // On arrondi au supérieur
    }
    return nbTotalPages;
  }

  get firstElementNumber() {
    if (!this.nbTotalAlertes || !this.currentPage) {
      return 0;
    }

    return this.filtreAlerte.limit * (this.currentPage - 1) + 1;
  }

  get lastElementNumber() {
    if (!this.nbTotalAlertes || !this.currentPage) {
      return 0;
    }

    let lastElementNumber = this.firstElementNumber + this.filtreAlerte.limit - 1;

    if (lastElementNumber < this.nbTotalAlertes) {
      return lastElementNumber;
    }
    return this.nbTotalAlertes;
  }

}
