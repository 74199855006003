import {Type} from 'class-transformer';
import {Federation} from './federation';
import {Utilisateur} from './utilisateur';

export class LogUtilisateur {
  id_log_utilisateur: number;

  @Type(() => Federation)
  federation?: Federation;

  @Type(() => Utilisateur)
  utilisateur: Utilisateur;

  date_heure_tentative: Date;
  nom_utilisateur: string;
  prenom_utilisateur: string;
  description: string;

}
