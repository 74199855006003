import {classToPlain} from 'class-transformer';

import {Association, Federation, Agence, Parametre, Utilisateur, Groupe} from '../_models';
import { FederationMarqueur } from '../_models/federation-marqueur';

export class FiltreEchange {
  federation: Federation[];
  association: Association[];
  agence: Agence[];
  created_debut: Date;
  created_fin: Date;
  modified_debut: Date;
  modified_fin: Date;
  closed_debut: Date;
  closed_fin: Date;
  statut_echange: Parametre[];
  statut_echeance: string[];
  personne_sujet_nature: Parametre[];
  personne_sujet_lien: Parametre[];
  type_echange: Parametre[];
  categorie_echange: Parametre[];
  motif_echange: Parametre[];
  operateur: Utilisateur[];
  responsable: Utilisateur[];
  personne_sujet_nom: string;
  personne_sujet_prenom: string;
  personne_sujet_telephone: string;
  personne_sujet_id_philia: string;
  priorite: string[];
  astreinte: string[];
  marqueur: FederationMarqueur[];
  marqueurIds: number[];
  objet_action: string;
  groupes: Groupe[];
  offset: number;
  limit: number;
  sort_property: string;
  sort_direction: string;
  objet_echange: string;
  personne_tiers_nom: string;
  personne_tiers_prenom: string;
  personne_tiers_telephone: string;
  personne_tiers_id_philia: string;
  personne_tiers_concerne_nature: Parametre[];

  risques_salarie: Parametre[];
  risques_client: Parametre[];
  frequence: Parametre[];
  gravite: Parametre[];
  services: Parametre[];
  finalite_echange: Parametre[];
  organisme_financier: Parametre;
  type_aide: Parametre[];

  constructor() {
    this.init();
  }

  static getSortProperty(property: string) {
    if (property === 'created1') {
      return 'created';
    } else if (property === 'firstDateEcheance') {
      return 'echeance';
    }
    return property;
  }

  static getSortOrder(sortOrder: number) {
    return sortOrder === 1 ? 'ASC' : 'DESC';
  }

  init() {
      this.federation = null;
      this.association = null;
      this.agence = null;
      this.created_debut = null;
      this.created_fin = null;
      this.modified_debut = null;
      this.modified_fin = null;
      this.closed_debut = null;
      this.closed_fin = null;
      this.statut_echange = [];
      this.statut_echeance = null;
      this.personne_sujet_nature = [];
      this.personne_sujet_lien = null;
      this.type_echange = [];
      this.categorie_echange = [];
      this.motif_echange = null;
      this.operateur = null;
      this.responsable = null;
      this.personne_sujet_nom = '';
      this.personne_sujet_prenom = '';
      this.personne_sujet_telephone = '';
      this.personne_sujet_id_philia = '';
      this.priorite = null;
      this.astreinte = null;
      this.marqueur = null;
      this.marqueurIds = null;
      this.objet_action = '';
      this.groupes = null;
      this.offset = 0;
      this.limit = 20;
      this.sort_property = 'created';
      this.sort_direction = 'DESC';
      this.objet_echange = '';
      this.personne_tiers_nom = '';
      this.personne_tiers_prenom = '';
      this.personne_tiers_telephone = '';
      this.personne_tiers_id_philia = '';
      this.personne_tiers_concerne_nature = null;
      this.risques_salarie = null;
      this.risques_client = null;
      this.frequence = null;
      this.gravite = null;
      this.services = null;
      this.finalite_echange = null;
      this.organisme_financier = null;
      this.type_aide = null;
  }

  setDatas(datas) {
    if (datas.federation) {
      this.federation = datas.federation;
    }
    if (datas.association) {
      this.association = datas.association;
    }
    if (datas.agence) {
      this.agence = datas.agence;
    }
    if (datas.created_debut) {
      this.created_debut = datas.created_debut;
    }
    if (datas.created_fin) {
      this.created_fin = datas.created_fin;
    }
    if (datas.modified_debut) {
      this.modified_debut = datas.modified_debut;
    }
    if (datas.modified_fin) {
      this.modified_fin = datas.modified_fin;
    }
    if (datas.closed_debut) {
      this.closed_debut = datas.closed_debut;
    }
    if (datas.closed_fin) {
      this.closed_fin = datas.closed_fin;
    }
    if (datas.statut_echange) {
      this.statut_echange = datas.statut_echange;
    }
    if (datas.statut_echeance) {
      this.statut_echeance = datas.statut_echeance;
    }
    if (datas.personne_sujet_nature) {
      this.personne_sujet_nature = datas.personne_sujet_nature;
    }
    if (datas.personne_sujet_lien) {
      this.personne_sujet_lien = datas.personne_sujet_lien;
    }
    if (datas.type_echange) {
      this.type_echange = datas.type_echange;
    }
    if (datas.categorie_echange) {
      this.categorie_echange = datas.categorie_echange;
    }
    if (datas.motif_echange) {
      this.motif_echange = datas.motif_echange;
    }
    if (datas.operateur) {
      this.operateur = datas.operateur;
    }
    if (datas.responsable) {
      this.responsable = datas.responsable;
    }
    if (datas.personne_sujet_nom) {
      this.personne_sujet_nom = datas.personne_sujet_nom;
    }
    if (datas.personne_sujet_prenom) {
      this.personne_sujet_prenom = datas.personne_sujet_prenom;
    }
    if (datas.personne_sujet_telephone) {
      this.personne_sujet_telephone = datas.personne_sujet_telephone;
    }
    if (datas.priorite) {
      this.priorite = datas.priorite;
    }
    if (datas.astreinte) {
      this.astreinte = datas.astreinte;
    }
    if (datas.nom) {
      this.personne_sujet_nom = datas.nom;
    }
    if (datas.prenom) {
      this.personne_sujet_prenom = datas.prenom;
    }
    if (datas.id_philia) {
      this.personne_sujet_id_philia = datas.id_philia;
    }
    if (datas.objet_action) {
      this.objet_action = datas.objet_action;
    }
    if (datas.groupes) {
      this.groupes = datas.groupes;
    }
    if (datas.objet_echange) {
      this.objet_echange = datas.objet_echange;
    }
    if (datas.personne_tiers_nom) {
      this.personne_tiers_nom = datas.personne_tiers_nom;
    }
    if (datas.personne_tiers_prenom) {
      this.personne_tiers_prenom = datas.personne_tiers_prenom;
    }
    if (datas.personne_tiers_telephone) {
      this.personne_tiers_telephone = datas.personne_tiers_telephone;
    }
    if (datas.personne_tiers_id_philia) {
      this.personne_tiers_id_philia = datas.personne_tiers_id_philia;
    }
    if (datas.personne_tiers_concerne_nature) {
      this.personne_tiers_concerne_nature = datas.personne_tiers_concerne_nature;
    }
    if (datas.risques_salarie) {
      this.risques_salarie = datas.risques_salarie;
    }
    if (datas.risques_client) {
      this.risques_client = datas.risques_client;
    }
    if (datas.frequence) {
      this.frequence = datas.frequence;
    }
    if (datas.gravite) {
      this.gravite = datas.gravite;
    }
    if (datas.services) {
      this.services = datas.services;
    }
    if (datas.finalite_echange) {
      this.finalite_echange = datas.finalite_echange;
    }
    if (datas.organisme_financier) {
      this.organisme_financier = datas.organisme_financier;
    }
    if (datas.type_aide) {
      this.type_aide = datas.type_aide;
    }
    if (datas.marqueur) {
      this.marqueurIds = datas.marqueur.map(mark => {
        return mark.id
      });
    }
  }

  serialize() {
    let serializeObject = classToPlain(this);

    // On enleve toutes les proprietes null ou undefined
    for (let propertyName in serializeObject) {
      if (!serializeObject[propertyName]) {
        delete serializeObject[propertyName];
      }
    }

    if (this.federation !== null && this.federation !== undefined) {
      serializeObject['federation'] = serializeObject['federation'].id_federation;
      serializeObject['federation'] = [];
      this.federation.forEach(function(federation) {
        serializeObject['federation'].push(federation.id_federation);
      });
      if (serializeObject['federation'].length === 0) {
        delete serializeObject['federation'];
      } else {
        serializeObject['federation'] = serializeObject['federation'].join(',');
      }
    }

    if (this.association !== null && this.association !== undefined) {
      serializeObject['association'] = serializeObject['association'].id_association;
      serializeObject['association'] = [];
      this.association.forEach(function(association) {
        serializeObject['association'].push(association.id_association);
      });
      if (serializeObject['association'].length === 0) {
        delete serializeObject['association'];
      } else {
        serializeObject['association'] = serializeObject['association'].join(',');
      }
    }

    if (this.agence !== null && this.agence !== undefined) {
      serializeObject['agence'] = serializeObject['agence'].id_agence;
      serializeObject['agence'] = [];
      this.agence.forEach(function(agence) {
        serializeObject['agence'].push(agence.id_agence);
      });
      if (serializeObject['agence'].length === 0) {
        delete serializeObject['agence'];
      } else {
        serializeObject['agence'] = serializeObject['agence'].join(',');
      }
    }

    if (this.created_debut !== null && this.created_debut !== undefined) {
        serializeObject['created_debut'] = Math.floor(serializeObject['created_debut'].getTime() / 1000);
    }

    if (this.created_fin !== null && this.created_fin !== undefined) {
        serializeObject['created_fin'] = Math.floor(serializeObject['created_fin'].getTime() / 1000);
    }

    if (this.modified_debut !== null && this.modified_debut !== undefined) {
        serializeObject['modified_debut'] = Math.floor(serializeObject['modified_debut'].getTime() / 1000);
    }

    if (this.modified_fin !== null && this.modified_fin !== undefined) {
        serializeObject['modified_fin'] = Math.floor(serializeObject['modified_fin'].getTime() / 1000);
    }

    if (this.closed_debut !== null && this.closed_debut !== undefined) {
        serializeObject['closed_debut'] = Math.floor(serializeObject['closed_debut'].getTime() / 1000);
    }

    if (this.closed_fin !== null && this.closed_fin !== undefined) {
        serializeObject['closed_fin'] = Math.floor(serializeObject['closed_fin'].getTime() / 1000);
    }

    if (this.statut_echange !== null && this.statut_echange !== undefined) {
        serializeObject['statut_echange'] = [];

        if (this.statut_echange.length > 0) {
          this.statut_echange.forEach(function(statut_e) {
            serializeObject['statut_echange'].push(statut_e.id_parametre);
          });
        }

        if (serializeObject['statut_echange'].length === 0) {
          delete serializeObject['statut_echange'];
        } else {
          serializeObject['statut_echange'] = serializeObject['statut_echange'].join(',');
        }
    }

    if (this.personne_sujet_nature !== null && this.personne_sujet_nature !== undefined) {
      serializeObject['personne_sujet_nature'] = [];
      this.personne_sujet_nature.forEach(function(nature) {
        serializeObject['personne_sujet_nature'].push(nature.id_parametre);
      });
      serializeObject['personne_sujet_nature'] = serializeObject['personne_sujet_nature'].join(',');
    }

    if (this.personne_tiers_concerne_nature !== null && this.personne_tiers_concerne_nature !== undefined) {
      serializeObject['personne_tiers_concerne_nature'] = [];
      this.personne_tiers_concerne_nature.forEach(function(nature) {
        serializeObject['personne_tiers_concerne_nature'].push(nature.id_parametre);
      });
      serializeObject['personne_tiers_concerne_nature'] = serializeObject['personne_tiers_concerne_nature'].join(',');
    }

    if (this.personne_sujet_lien !== null && this.personne_sujet_lien !== undefined) {
      serializeObject['personne_sujet_lien'] = [];
      this.personne_sujet_lien.forEach(function(lien) {
        serializeObject['personne_sujet_lien'].push(lien.id_parametre);
      });
      serializeObject['personne_sujet_lien'] = serializeObject['personne_sujet_lien'].join(',');
    }

    if (this.type_echange !== null && this.type_echange !== undefined) {
      serializeObject['type_echange'] = [];
      this.type_echange.forEach(function(type) {
        serializeObject['type_echange'].push(type.id_parametre);
      });
      serializeObject['type_echange'] = serializeObject['type_echange'].join(',');
    }

    if (this.categorie_echange !== null && this.categorie_echange !== undefined) {
      serializeObject['categorie_echange'] = [];
      this.categorie_echange.forEach(function(categorie) {
        serializeObject['categorie_echange'].push(categorie.id_parametre);
      });
      serializeObject['categorie_echange'] = serializeObject['categorie_echange'].join(',');
    }

    if (this.motif_echange !== null && this.motif_echange !== undefined) {
      serializeObject['motif_echange'] = [];
      this.motif_echange.forEach(function(motif) {
        serializeObject['motif_echange'].push(motif.id_parametre);
      });
      serializeObject['motif_echange'] = serializeObject['motif_echange'].join(',');
    }

    if (this.operateur !== null && this.operateur !== undefined) {
      serializeObject['operateur'] = [];
      this.operateur.forEach(function(op) {
        serializeObject['operateur'].push(op.id_utilisateur);
      });
      serializeObject['operateur'] = serializeObject['operateur'].join(',');
    }

    if (this.responsable !== null && this.responsable !== undefined) {
      serializeObject['responsable'] = [];
      this.responsable.forEach(function(respo) {
        serializeObject['responsable'].push(respo.id_utilisateur);
      });
      serializeObject['responsable'] = serializeObject['responsable'].join(',');
    }

    if (this.groupes !== null && this.groupes !== undefined) {
      serializeObject['groupes'] = [];
      this.groupes.forEach(function(grp) {
        serializeObject['groupes'].push(grp.id_groupe);
      });
      serializeObject['groupes'] = serializeObject['groupes'].join(',');
    }

    if (this.risques_salarie !== null && this.risques_salarie !== undefined) {
      serializeObject['risques_salarie'] = [];
      this.risques_salarie.forEach(function(risque_salarie) {
        serializeObject['risques_salarie'].push(risque_salarie.id_parametre);
      });
      serializeObject['risques_salarie'] = serializeObject['risques_salarie'].join(',');
    }

    if (this.risques_client !== null && this.risques_client !== undefined) {
      serializeObject['risques_client'] = [];
      this.risques_client.forEach(function(risque_client) {
        serializeObject['risques_client'].push(risque_client.id_parametre);
      });
      serializeObject['risques_client'] = serializeObject['risques_client'].join(',');
    }

    if (this.frequence !== null && this.frequence !== undefined) {
      serializeObject['frequence'] = [];
      this.frequence.forEach(function(freq) {
        serializeObject['frequence'].push(freq.id_parametre);
      });
      serializeObject['frequence'] = serializeObject['frequence'].join(',');
    }

    if (this.gravite !== null && this.gravite !== undefined) {
      serializeObject['gravite'] = [];
      this.gravite.forEach(function(grav) {
        serializeObject['gravite'].push(grav.id_parametre);
      });
      serializeObject['gravite'] = serializeObject['gravite'].join(',');
    }

    if (this.services !== null && this.services !== undefined) {
      serializeObject['services'] = [];
      this.services.forEach(function(service) {
        serializeObject['services'].push(service.id_parametre);
      });
      serializeObject['services'] = serializeObject['services'].join(',');
    }

    if (this.finalite_echange !== null && this.finalite_echange !== undefined) {
      serializeObject['finalite_echange'] = [];
      this.finalite_echange.forEach(function(fe) {
        serializeObject['finalite_echange'].push(fe.id_parametre);
      });
      serializeObject['finalite_echange'] = serializeObject['finalite_echange'].join(',');
    }

    if (this.organisme_financier !== null && this.organisme_financier !== undefined) {
      serializeObject['finalite_echange'] = this.organisme_financier.id_parametre;
    }

    if (this.type_aide !== null && this.type_aide !== undefined) {
      serializeObject['type_aide'] = [];
      this.type_aide.forEach(function(ta) {
        serializeObject['type_aide'].push(ta.id_parametre);
      });
      serializeObject['type_aide'] = serializeObject['type_aide'].join(',');
    }

    return serializeObject;
  }
}
