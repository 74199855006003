import {classToPlain, Type} from 'class-transformer';
import {Parametre} from './parametre';

export class ParametreRisque {

  id_parametre_risque: number;

  @Type(() => Parametre)
  type_risque: Parametre;

  element: string;

  risque_suggestion: string;

  mesure_suggestion: string;

  @Type(() => Parametre)
  types_public: Parametre[];

  hasTypePublic(type_public) {
    let hasTypePublic = false;
    this.types_public.forEach(function (pr_type_public) {
      if (pr_type_public.code_parametre === type_public.code_parametre) {
        hasTypePublic = true;
      }
    });
    return hasTypePublic;
  }

  serialize() {
    let serializeObject = classToPlain(this);
    delete serializeObject['id_parametre_risque'];

    if (this.type_risque !== null && this.type_risque !== undefined) {
      serializeObject['type_risque'] = this.type_risque.id_parametre;
    }

    serializeObject['types_public'] = [];
    if (this.types_public !== null && this.types_public !== undefined) {
      this.types_public.forEach(function (type_public) {
        serializeObject['types_public'].push(type_public.id_parametre);
      });
    }

    return serializeObject;
  }
}
