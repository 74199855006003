import {FormControl, FormGroup} from '@angular/forms';
import {Type} from 'class-transformer';
import {Personne} from './personne';


export class AlimentationEnfant {

  id_alimentation_enfant: number;

  allaitement_detail_organisation: string;

  nourrisson_preparation_biberon: boolean;

  nourrisson_hygiene_biberon: string;

  nourrisson_aide_prise_biberon: boolean;

  nourrisson_temperature_biberon: string;

  nourrisson_eau_biberon: string;

  repas_preparation: boolean;

  repas_petits_pots: boolean;

  repas_enfant_mixte: boolean;

  repas_puree_maison: boolean;

  repas_petits_morceaux: boolean;

  repas_detail: boolean;

  repas_degout: boolean;

  repas_chaise_haute: boolean;

  repas_rehausseur: boolean;

  repas_genoux: boolean;

  repas_horaire_petit_dej: string;

  repas_horaire_dej: string;

  repas_horaire_gouter: string;

  repas_horaire_diner: string;

  repas_usages: string;

  repas_aide_prise: boolean;

  @Type(() => Personne)
  enfant: Personne;

  static getForm(alimentation_enfant = null): FormGroup {
    if (!alimentation_enfant) {
      alimentation_enfant = new AlimentationEnfant();
    }

    return new FormGroup({
      'id_alimentation_enfant': new FormControl(alimentation_enfant.id_alimentation_enfant),
      'allaitement_detail_organisation': new FormControl(alimentation_enfant.allaitement_detail_organisation),
      'nourrisson_preparation_biberon': new FormControl(alimentation_enfant.nourrisson_preparation_biberon),
      'nourrisson_hygiene_biberon': new FormControl(alimentation_enfant.nourrisson_hygiene_biberon),
      'nourrisson_aide_prise_biberon': new FormControl(alimentation_enfant.nourrisson_aide_prise_biberon),
      'nourrisson_temperature_biberon': new FormControl(alimentation_enfant.nourrisson_temperature_biberon),
      'nourrisson_eau_biberon': new FormControl(alimentation_enfant.nourrisson_eau_biberon),
      'repas_preparation': new FormControl(alimentation_enfant.repas_preparation),
      'repas_petits_pots': new FormControl(alimentation_enfant.repas_petits_pots),
      'repas_detail': new FormControl(alimentation_enfant.repas_detail),
      'repas_degout': new FormControl(alimentation_enfant.repas_degout),
      'repas_chaise_haute': new FormControl(alimentation_enfant.repas_chaise_haute),
      'repas_rehausseur': new FormControl(alimentation_enfant.repas_rehausseur),
      'repas_genoux': new FormControl(alimentation_enfant.repas_genoux),
      'repas_horaire_petit_dej': new FormControl(alimentation_enfant.repas_horaire_petit_dej),
      'repas_horaire_dej': new FormControl(alimentation_enfant.repas_horaire_dej),
      'repas_horaire_gouter': new FormControl(alimentation_enfant.repas_horaire_gouter),
      'repas_horaire_diner': new FormControl(alimentation_enfant.repas_horaire_diner),
      'repas_usages': new FormControl(alimentation_enfant.repas_usages),
      'repas_aide_prise': new FormControl(alimentation_enfant.repas_aide_prise),
      'enfant': new FormControl(alimentation_enfant.enfant)
    });
  }

  serialize() {

  }
}
