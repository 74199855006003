import {classToPlain} from 'class-transformer';

export class DroitModule {
    static module_echanges = 'main_courante';
    static module_evaluations = 'evaluations';
    static module_vigilance = 'vigilance';
    static module_personia = 'personia';
    static fonctionnalite_edit_echanges = 'edit_echanges';
    static fonctionnalite_edit_actions = 'edit_actions';
    static fonctionnalite_creation_evaluations = 'init_evaluations';
    static fonctionnalite_edit_evaluations = 'init_evaluations';
    static fonctionnalite_edit_alertes = 'edit_alertes';
    static fonctionnalite_edit_actions_alertes = 'edit_actions_alertes';

    id_droit_module: number;
    module: string;
    fonctionnalite: string;
    libelle: string;

    serialize() {
      let serializeObject = classToPlain(this);
      delete serializeObject['id_droit_module'];

      return serializeObject;
    }
}
