import {FormControl, FormGroup} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {Personne} from './personne';

export class RituelEnfant {

  id_rituel_enfant: number;

  doudou = false;

  moments_doudou: string;

  tetine = false;

  moments_tetine: string;

  ecole: boolean;

  info: string;

  @Type(() => Personne)
  enfant: Personne;

  portage = false;

  static getForm(rituelEnfant = null): FormGroup {
    if (!rituelEnfant) {
      rituelEnfant = new RituelEnfant();
    }

    return new FormGroup({
      'id_rituel_enfant': new FormControl(rituelEnfant.id_rituel_enfant),
      'doudou': new FormControl(rituelEnfant.doudou),
      'moments_doudou': new FormControl(rituelEnfant.moments_doudou),
      'tetine': new FormControl(rituelEnfant.tetine),
      'moments_tetine': new FormControl(rituelEnfant.moments_tetine),
      'ecole': new FormControl(rituelEnfant.ecole),
      'info': new FormControl(rituelEnfant.info),
      'portage': new FormControl(rituelEnfant.portage),
    });
  }

  serialize() {
    return classToPlain(this);
  }
}
