import {FormControl, FormGroup} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {InterventionPlanification} from './intervention-planification';
import {Parametre} from './parametre';


export class PlanificationNbHeures {

  id_planification_nb_heures: number;

  detail: string;

  @Type(() => InterventionPlanification)
  intervention_planification: InterventionPlanification;

  @Type(() => Parametre)
  jour: Parametre;

  @Type(() => Parametre)
  periode: Parametre;

  intervention_aide_personne: boolean;

  intervention_alimentation: boolean;

  intervention_accompagnement: boolean;

  intervention_menage_linge: boolean;

  intervention_jardinage_bricolage: boolean;

  intervention_gardiennage: boolean;

  intervention_duree: number;

  intervention_duree_unite = 'min';

  intervention_continuite_service: boolean;

  static getForm(planification_nb_heures = null): FormGroup {
    if (!planification_nb_heures) {
      planification_nb_heures = new PlanificationNbHeures();
    }

    return new FormGroup({
      'id_planification_nb_heures': new FormControl(planification_nb_heures.id_planification_nb_heures),
      'detail': new FormControl(planification_nb_heures.detail),
      'jour': new FormControl(planification_nb_heures.jour),
      'periode': new FormControl(planification_nb_heures.periode),
      'intervention_aide_personne': new FormControl(planification_nb_heures.intervention_aide_personne),
      'intervention_alimentation': new FormControl(planification_nb_heures.intervention_alimentation),
      'intervention_accompagnement': new FormControl(planification_nb_heures.intervention_accompagnement),
      'intervention_menage_linge': new FormControl(planification_nb_heures.intervention_menage_linge),
      'intervention_jardinage_bricolage': new FormControl(planification_nb_heures.intervention_jardinage_bricolage),
      'intervention_gardiennage': new FormControl(planification_nb_heures.intervention_gardiennage),
      'intervention_duree': new FormControl(planification_nb_heures.intervention_duree),
      'intervention_duree_unite': new FormControl(planification_nb_heures.intervention_duree_unite),
      'intervention_continuite_service': new FormControl(planification_nb_heures.intervention_continuite_service),
    });
  }

  isEmpty() {
    return !(this.detail || this.intervention_duree || this.intervention_continuite_service
      || this.intervention_aide_personne || this.intervention_alimentation
      || this.intervention_accompagnement || this.intervention_menage_linge
      || this.intervention_jardinage_bricolage || this.intervention_gardiennage);
  }

  serialize() {
    let serializeObject = classToPlain(this);

    if (this.jour !== null && this.jour !== undefined) {
      serializeObject['jour'] = this.jour.id_parametre;
    }

    if (this.periode !== null && this.periode !== undefined) {
      serializeObject['periode'] = this.periode.id_parametre;
    }

    if (this.intervention_duree_unite !== null && this.intervention_duree_unite !== undefined
      && this.intervention_duree_unite === 'h') {
      // Conversion en minutes
      serializeObject['intervention_duree'] = this.intervention_duree * 60;
    }

    return serializeObject;
  }
}
