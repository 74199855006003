<form (ngSubmit)="onSubmit()">
    <div class="ui-grid ui-grid-responsive ui-grid-pad ui-fluid" style="margin: 10px 0px">
        <div id="panel-personne" class="ui-grid-row panel-header-category">
            <div class="ui-g-12">
              <h2>
                <i class="fa fa-building"></i>
                Association
              </h2>
            </div>
        </div>
        <div class="ui-grid-row">
          <div class="ui-g-6">
              <div class="ui-g-4">
                  <label>Téléphone</label>
              </div>
              <div class="ui-g-8">
                  <p-inputMask  type="text" mask="99 99 99 99 99" [(ngModel)]="telephoneAssociation" name="telephoneAssociation" placeholder="Numéro de téléphone de l'association"></p-inputMask>
              </div>
          </div>
          <div class="ui-g-3">
            <p-message *ngIf="showMessage" severity="error" text="Aucune association trouvée"></p-message>
          </div>
          <div class="ui-g-3">
            <button pButton type="submit" class="green-button" label="Recherche" icon="pi pi-search"></button>
          </div>
        </div>
        <div *ngIf="associationFound" class="ui-grid-row">
          <div class="ui-g-4">
            <label>Association</label> : {{associationFound.libelle}}
          </div>
          <div class="ui-g-4">
            <label>Mail</label> : {{associationFound.mail}}
          </div>
        </div>
        <div *ngIf="associationFound && !associationFound.is_personia" class="ui-grid-row">
          <p-message severity="error" text="Cette association n'est pas abonnée au service Personia"></p-message>
        </div>
        <div *ngIf="associationFound" class="ui-grid-row">
          <div class="ui-g-4">
            <label>Services</label><br/>
            <span *ngFor="let service of associationFound.services" style="white-space: pre-wrap">{{service.libelle}}<br/></span>
          </div>
          <div class="ui-g-4">
            <label>Jours et horaires d'ouverture</label><br/>
            <span style="white-space: pre-wrap">{{associationFound.jours_horaires_ouverture}}</span>
          </div>
          <div class="ui-g-4">
            <label>Jours et horaires de fermeture exceptionnelle</label><br/>
            <span style="white-space: pre-wrap">{{associationFound.jours_horaires_fermeture}}</span>
          </div>
        </div>
        <div *ngIf="associationFound && associationFound.is_personia">
          <div class="ui-grid-row">
            <label style="font-weight: bold;text-decoration: underline;">Personia</label>
          </div>
          <div class="ui-grid-row">
            <span style="font-weight: bold;">Mail Personia : </span><span *ngIf="associationFound.mail_personia">{{associationFound.mail_personia}}</span>
          </div>
          <div class="ui-grid-row">
            <span style="font-weight: bold;">A contacter : </span>
          </div>
          <div *ngIf="associationFound.contact" class="ui-grid-row">
            {{associationFound.contact.prenom_nom}}
            <span *ngIf="associationFound.contact.telephone"> - {{associationFound.contact.telephone}}</span>
            <span *ngIf="associationFound.contact.mail"> - {{associationFound.contact.mail}}</span>
            <span *ngIf="associationFound.contact.getPreferencesContactLabel()"> - A contacter de préférence par {{associationFound.contact.getPreferencesContactLabel()}}</span>
          </div>
          <div *ngIf="associationFound.groupe_personia" class="ui-grid-row">
            {{associationFound.groupe_personia.libelle}}
            <span *ngIf="associationFound.groupe_personia.mail"> - {{associationFound.groupe_personia.mail}}</span>
          </div>
        </div>
        <div *ngIf="associationFound">
          <div class="ui-grid-row">
            <span style="font-weight: bold;">A contacter en cas d'urgence : </span>
          </div>
          <div *ngIf="associationFound.contact_urgence" class="ui-grid-row">
            {{associationFound.contact_urgence.prenom_nom}}
            <span *ngIf="associationFound.contact.telephone"> - {{associationFound.contact_urgence.telephone}}</span>
            <span *ngIf="associationFound.contact.mail"> - {{associationFound.contact_urgence.mail}}</span>
            <span *ngIf="associationFound.contact_urgence.getPreferencesContactLabel()"> - A contacter de préférence par {{associationFound.contact_urgence.getPreferencesContactLabel()}}</span>
          </div>
        </div>
    </div>
</form>
