import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import {plainToClass} from 'class-transformer';
import {SelectItem} from 'primeng/api';

import { Contact, InfosEntourage } from '../_models';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class InfosEntourageService {

  constructor(private http: HttpClient) { }

  /** POST infos_entourage */
  createInfosEntourage(infosEntourage: InfosEntourage): Observable<InfosEntourage> {
    return this.http.post<InfosEntourage>(
      `${environment.apiHost}/${environment.apiNamespace}/infos_entourage`,
      plainToClass(InfosEntourage, infosEntourage).serialize()
     );
  }

  /** PUT infos_entourage */
  updateInfosEntourage(infosEntourage: InfosEntourage): Observable<InfosEntourage> {
    return this.http.put<InfosEntourage>(
      `${environment.apiHost}/${environment.apiNamespace}/infos_entourage/${infosEntourage.id_infos_entourage}`,
      plainToClass(InfosEntourage, infosEntourage).serialize()
    );
  }

  /* handleError */
  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(error);
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a utilisateur-facing error message
    return throwError(
      error);
  }

}
