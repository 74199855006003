import { environment } from '../../../environments/environment';
import {SelectItem} from 'primeng/api';
import { version } from '../../../../package.json';

export class Globals {
  static appVersion = version;
  static appTitle = 'Tracéo';
  static datePipeFormat = 'dd/MM/yyyy HH:mm';
  static datePipeFormatWithoutTime = 'dd/MM/yyyy';
  static datePipeFormatYMD = 'yyyy/MM/dd';
  static dateFormat = 'dd/mm/yy';
  static dateYMDFormat = 'yyyy/mm/dd';
  static timeFormat = 'HH:mm';
  static dateLocale = {
    firstDayOfWeek: 1,
    dayNames: [ 'dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi' ],
    dayNamesShort: [ 'di', 'lu', 'ma', 'me', 'je', 've', 'sa' ],
    dayNamesMin: [ 'D', 'L', 'M', 'M', 'J', 'V', 'S' ],
    monthNames: [ 'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
    'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre' ],
    monthNamesShort: [ 'jan', 'fev', 'mar', 'avr', 'mai', 'jui', 'jul', 'aou', 'sep', 'oct', 'nov', 'dec' ],
    today: 'Aujourd\'hui',
    clear: 'Annuler'
  };

  static emailPattern = '^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$';
  static containsLettersPattern = '.*[a-zA-Z]+.*';

  static mailActionObjet = 'TRACEO : Action assignée';
  /* TEMPLATE EMAIL RESPONSABLE */
  static mailActionTemplate = ' \
  Bonjour,\n\n \
  Vous avez été identifié sur une action Tracéo :\n\n \
  Vous pouvez vous connecter sur ' + environment.appUrl + '/in/echange/@(action.echange.id_echange) pour plus de détails,\n\n\n Merci.';
  

  static mailActionAlerteTemplate = ' \
  Bonjour,\n\n \
  Vous avez été identifié sur une action Tracéo :\n\n \
  Action (échéance : @(action.echeance))\n \
  - @(action.objet_action) \n \
  - @(action.description_action) \n\n \
  Client concerné : @(action.alerte.client_civilite.libelle) @(action.alerte.client_nom) @(action.alerte.client_prenom)\n\n \
  Alerte du @(action.alerte.date_creation)\n \
  Motif : @(action.alerte.motif_alerte.libelle)\n \
  - Asso : @(action.alerte.association.libelle) \n \
  - Description : @(action.alerte.description) \n\n  \
  Vous pouvez vous connecter sur ' + environment.appUrl + '/in/alerte/@(action.alerte.id_alerte) pour plus de détails, Merci.';

  static prioriteList = [
    {label: 'Standard', value: 'S'},
    {label: 'Urgent', value: 'U'}
  ];

  static echeanceList = [
    {label: 'Urgente', value: 'U'},
    {label: 'Dépassée', value: 'D'}
  ];

  static astreinteList = [
    {label: 'Oui', value: 'true'},
    {label: 'Non', value: 'false'}
  ];

  static horaires = [
    {label: '00h00', value: '00h00'},
    {label: '00h30', value: '00h30'},
    {label: '01h00', value: '01h00'},
    {label: '01h30', value: '01h30'},
    {label: '02h00', value: '02h00'},
    {label: '02h30', value: '02h30'},
    {label: '03h00', value: '03h00'},
    {label: '03h30', value: '03h30'},
    {label: '04h00', value: '04h00'},
    {label: '04h30', value: '04h30'},
    {label: '05h00', value: '05h00'},
    {label: '05h30', value: '05h30'},
    {label: '06h00', value: '06h00'},
    {label: '06h30', value: '06h30'},
    {label: '07h00', value: '07h00'},
    {label: '07h30', value: '07h30'},
    {label: '08h00', value: '08h00'},
    {label: '08h30', value: '08h30'},
    {label: '09h00', value: '09h00'},
    {label: '09h30', value: '09h30'},
    {label: '10h00', value: '10h00'},
    {label: '10h30', value: '10h30'},
    {label: '11h00', value: '11h00'},
    {label: '11h30', value: '11h30'},
    {label: '12h00', value: '12h00'},
    {label: '12h30', value: '12h30'},
    {label: '13h00', value: '13h00'},
    {label: '13h30', value: '13h30'},
    {label: '14h00', value: '14h00'},
    {label: '14h30', value: '14h30'},
    {label: '15h00', value: '15h00'},
    {label: '15h30', value: '15h30'},
    {label: '16h00', value: '16h00'},
    {label: '16h30', value: '16h30'},
    {label: '17h00', value: '17h00'},
    {label: '17h30', value: '17h30'},
    {label: '18h00', value: '18h00'},
    {label: '18h30', value: '18h30'},
    {label: '19h00', value: '19h00'},
    {label: '19h30', value: '19h30'},
    {label: '20h00', value: '20h00'},
    {label: '20h30', value: '20h30'},
    {label: '21h00', value: '21h00'},
    {label: '21h30', value: '21h30'},
    {label: '22h00', value: '22h00'},
    {label: '22h30', value: '22h30'},
    {label: '23h00', value: '23h00'},
    {label: '23h30', value: '23h30'}
  ];

  static horairesMatin = [
    {label: '00h00', value: '00h00'},
    {label: '00h30', value: '00h30'},
    {label: '01h00', value: '01h00'},
    {label: '01h30', value: '01h30'},
    {label: '02h00', value: '02h00'},
    {label: '02h30', value: '02h30'},
    {label: '03h00', value: '03h00'},
    {label: '03h30', value: '03h30'},
    {label: '04h00', value: '04h00'},
    {label: '04h30', value: '04h30'},
    {label: '05h00', value: '05h00'},
    {label: '05h30', value: '05h30'},
    {label: '06h00', value: '06h00'},
    {label: '06h30', value: '06h30'},
    {label: '07h00', value: '07h00'},
    {label: '07h30', value: '07h30'},
    {label: '08h00', value: '08h00'},
    {label: '08h30', value: '08h30'},
    {label: '09h00', value: '09h00'},
    {label: '09h30', value: '09h30'},
    {label: '10h00', value: '10h00'},
    {label: '10h30', value: '10h30'},
    {label: '11h00', value: '11h00'},
    {label: '11h30', value: '11h30'},
    {label: '12h00', value: '12h00'}
  ];

  static horairesApresMidi = [
    {label: '12h00', value: '12h00'},
    {label: '12h30', value: '12h30'},
    {label: '13h00', value: '13h00'},
    {label: '13h30', value: '13h30'},
    {label: '14h00', value: '14h00'},
    {label: '14h30', value: '14h30'},
    {label: '15h00', value: '15h00'},
    {label: '15h30', value: '15h30'},
    {label: '16h00', value: '16h00'},
    {label: '16h30', value: '16h30'},
    {label: '17h00', value: '17h00'},
    {label: '17h30', value: '17h30'},
    {label: '18h00', value: '18h00'},
    {label: '18h30', value: '18h30'},
    {label: '19h00', value: '19h00'},
    {label: '19h30', value: '19h30'},
    {label: '20h00', value: '20h00'},
    {label: '20h30', value: '20h30'},
    {label: '21h00', value: '21h00'},
    {label: '21h30', value: '21h30'},
    {label: '22h00', value: '22h00'},
    {label: '22h30', value: '22h30'},
    {label: '23h00', value: '23h00'},
    {label: '23h30', value: '23h30'},
    {label: '00h00', value: '00h00'}
  ];

  static durees = [
    {label: '00 min', value: '00 min'},
    {label: '05 min', value: '05 min'},
    {label: '10 min', value: '10 min'},
    {label: '15 min', value: '15 min'},
    {label: '20 min', value: '20 min'},
    {label: '25 min', value: '25 min'},
    {label: '30 min', value: '30 min'},
    {label: '35 min', value: '35 min'},
    {label: '40 min', value: '40 min'},
    {label: '45 min', value: '45 min'},
    {label: '50 min', value: '50 min'},
    {label: '55 min', value: '55 min'},
    {label: '60 min', value: '60 min'},
  ];

  static girList = [
    {label: '', value: null},
    {label: '1', value: '1'},
    {label: '2', value: '2'},
    {label: '3', value: '3'},
    {label: '4', value: '4'},
    {label: '5', value: '5'},
    {label: '6', value: '6'}
  ];

  static optionsChartBar = {
    title: {
      display: false,
      text: ''
    },
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true
            }
        }]
    },
    plugins: {
      datalabels: {
        display: true,
        align: 'end',
        anchor: 'end',
        borderRadius: 4,
        backgroundColor: 'teal',
        color: 'white',
        font: {
          weight: 'bold'
        }
      }
    },
    hover: {
       onHover: function(e) {
          let point = this.getElementAtEvent(e);
          if (point.length) {
            e.target.style.cursor = 'pointer';
          } else {
            e.target.style.cursor = 'default';
          }
       }
    }
  };

  // Classes definissant les marqueurs
  static marqueursList = ['zmdi zmdi-badge-check', 'zmdi zmdi-flag', 'zmdi zmdi-label', 'zmdi zmdi-pin-help', 'zmdi zmdi-alert-triangle', 'zmdi zmdi-notifications', 'zmdi zmdi-star', 'zmdi zmdi-eye'];
  // Classes definissant les couleurs des icones
  static marqueursColorsList = ['icon-amber', 'icon-red', 'icon-green', 'icon-blue', 'icon-grey'];
  static defaultIconClassName = 'icon zmdi-hc-2x cursor-pointer no-padding-bottom no-padding-top';

  static isEmpty(varTotTest: any) {
    if (varTotTest !== null && varTotTest !== undefined && varTotTest.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  static isNullOrUndefined(varTotTest: any) {
    if (varTotTest === null || varTotTest === undefined) {
      return true;
    } else {
      return false;
    }
  }

  static formatDate(date: Date, showHour = false): string {
    if (date !== null && date !== undefined) {
      let month = (date.getMonth() + 1).toString();
      let day = date.getDate().toString();
      let year = date.getFullYear().toString();

      if (month.length < 2) {
        month = '0' + month;
      }
      if (day.length < 2) {
        day = '0' + day;
      }

      let dateStr = [day, month, year].join('/');

      if (showHour) {
        dateStr += ' ' + (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
      }

      return dateStr;
    } else {
      return null;
    }
  }

  static formatDateForFilename(date: Date, showHour = false): string {
    if (date !== null && date !== undefined) {
      let month = (date.getMonth() + 1).toString();
      let day = date.getDate().toString();
      let year = date.getFullYear().toString();

      if (month.length < 2) {
        month = '0' + month;
      }
      if (day.length < 2) {
        day = '0' + day;
      }

      let dateStr = day + month + year;

      if (showHour) {
        dateStr += '_' + (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
      }

      return dateStr;
    } else {
      return null;
    }
  }

  static getDateWithTimezone(date: Date) { // Retourne la date avec la bonne timezone (et donc la bonne heure)
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()));
  }

  static getFirstDateOfMonth() { // Retourne la premiere date du mois
    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();

    return  new Date(year, month, 0);
  }

  static getLastDateOfMonth() { // Retourne la derniere date du mois
    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();

    return  new Date(year, month + 1, 0);
  }

  static getMondayOfCurrentWeek() { // Retourne le premier jour de la semaine (Lundi)
    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    let day = today.getDay();

    return new Date(year, month, today.getDate() + (day === 0 ? -6 : 1) - day );
  }

  static getSundayOfCurrentWeek() {
    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    let day = today.getDay();

    return new Date(year, month, today.getDate() + (day === 0 ? 0 : 7) - day );
  }

  static getNextDay(date: Date) {
    date.setDate(date.getDate() + 1);

    return date;
  }

  static addDays(date: Date, nb_days: number) {
    date.setDate(date.getDate() + nb_days);

    return date;
  }

  static getSavedGlobalFilter(stateKey: string) {
    if (JSON.parse(sessionStorage.getItem(stateKey))) {
      if (JSON.parse(sessionStorage.getItem(stateKey)).filters) {
        if (JSON.parse(sessionStorage.getItem(stateKey)).filters.global) {
          if (JSON.parse(sessionStorage.getItem(stateKey)).filters.global.value) {
            return JSON.parse(sessionStorage.getItem(stateKey)).filters.global.value;
          }
        }
      }
    }
    return '';
  }

  static getComplementsAdresse(): SelectItem[] {
    let complements = [];
    complements.push({label: '', value: ''});
    complements.push({label: 'BIS', value: 'B'});
    complements.push({label: 'TER', value: 'T'});
    complements.push({label: 'QUATER', value: 'Q'});

    return complements;
  }

  // Retourne l'age a partir de la date anniversaire (en années)
  static getAge(dateNaissance: Date): number {
    let today = new Date();
    return ((today.getTime() - dateNaissance.getTime()) / 31536000000);
  }

  static getNextHoraire(arrayList: any[], valueTest: any) {
    let isNextValue = false;
    let nextValue = valueTest;

    for (let index = 0; index < arrayList.length; index++) {
      if (isNextValue) {
        nextValue = arrayList[index].value;
        isNextValue = false;
      }

      if (arrayList[index].value === valueTest) {
        isNextValue = true;
      }
    }

    return nextValue;
  }

  static selectItemListIncludes(arrayList: any[], valueTest: any): boolean {
    let containsValue = false;
    for (let index = 0; index < arrayList.length; index++) {
      if (arrayList[index].value === valueTest) {
        containsValue = true;
      }
    }
    return containsValue;
  }

  static toFormData<T>( formValue: T ) {
    const formData = new FormData();

    for ( const key of Object.keys(formValue) ) {
      const value = formValue[key];
      formData.append(key, value);
    }

    return formData;
  }

  static validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}
