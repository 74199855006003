import { Directive } from '@angular/core';
import { NG_ASYNC_VALIDATORS, AsyncValidator, ValidationErrors, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import {UtilisateurService} from '../_services';

@Directive({
  selector: '[appLoginValidator]',
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: LoginValidatorDirective, multi: true}]
})
export class LoginValidatorDirective implements AsyncValidator {

  constructor(
    private utilisateurService: UtilisateurService
  ) { }

  public validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
      return this.utilisateurService.checkLogin(control.value).pipe(
        map(
        utilisateurs => {
          return (utilisateurs && utilisateurs.length) ? {'isTaken': true} : null;
        },
        catchError(() => null)
      ));
  }
}
