import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {echangeValidator} from '../../shared/_directives/echange-validator.directive';
import {emailValidator} from '../../shared/_directives/email-validator.directive';
import {phoneValidator} from '../../shared/_directives/phone-validator.directive';
import {Globals} from '../utils/globals';
import {Action} from './action';
import {Agence} from './agence';
import {Association} from './association';
import {Commune} from './commune';
import {DocumentEchange} from './document-echange';
import {DroitModule} from './droit-module';
import {Federation} from './federation';
import {Parametre} from './parametre';
import {Utilisateur} from './utilisateur';
import { EchangeMarqueur } from './echange-marqueur';

export class Echange {
  id_echange: number;
  num_identifiant: string;

  @Type(() => Date)
  created: Date = new Date();

  @Type(() => Date)
  date_creation: Date = new Date();

  @Type(() => Date)
  closed: Date;

  @Type(() => Utilisateur)
  operateur: Utilisateur;

  @Type(() => Utilisateur)
  utilisateur_fermeture: Utilisateur;
  objet_echange: string;
  description: string;

  @Type(() => Parametre)
  statut_echange: Parametre;

  priorite = 'S';

  @Type(() => Federation)
  federation: Federation;

  @Type(() => Association)
  association: Association;

  @Type(() => Agence)
  agence: Agence;

  @Type(() => Parametre)
  type_echange: Parametre;

  type_echange_sens: string;

  @Type(() => Parametre)
  motif_echange: Parametre;

  @Type(() => Parametre)
  categorie_echange: Parametre;

  personne_sujet_id_philia: string;
  personne_sujet_nom: string;
  personne_sujet_prenom: string;
  personne_sujet_mail: string;

  @Type(() => Parametre)
  personne_sujet_civilite: Parametre;

  @Type(() => Parametre)
  personne_sujet_lien: Parametre;

  @Type(() => Parametre)
  personne_sujet_nature: Parametre;

  personne_sujet_adr_type_voie: string;
  personne_sujet_tel_domicile: string;
  personne_sujet_tel_travail: string;
  personne_sujet_tel_mobile: string;
  personne_sujet_adr_num: number;
  personne_sujet_adr_lieudit: string;
  personne_sujet_adr_complement: string;
  personne_sujet_adr_complement1: string;
  personne_sujet_adr_complement2: string;
  personne_sujet_adr_voie: string;

  @Type(() => Commune)
  personne_sujet_adr_commune: Commune;

  personne_sujet_societe: string;
  personne_tiers_id_philia: string;
  personne_tiers_nom: string;
  personne_tiers_prenom: string;
  personne_tiers_mail: string;

  @Type(() => Parametre)
  personne_tiers_civilite: Parametre;

  personne_tiers_tel_domicile: string;
  personne_tiers_tel_travail: string;
  personne_tiers_tel_mobile: string;
  personne_tiers_adr_num: string;
  personne_tiers_adr_voie: string;

  @Type(() => Commune)
  personne_tiers_adr_commune: Commune;

  personne_tiers_societe: string;
  personne_tiers_adr_lieudit: string;
  personne_tiers_adr_complement: string;
  personne_tiers_adr_complement1: string;
  personne_tiers_adr_complement2: string;

  personne_tiers_concerne_id_philia: string;

  @Type(() => Parametre)
  personne_tiers_concerne_nature: Parametre;

  personne_tiers_concerne_nom: string;
  personne_tiers_concerne_prenom: string;
  personne_tiers_concerne_mail: string;

  @Type(() => Parametre)
  personne_tiers_concerne_civilite: Parametre;

  personne_tiers_concerne_tel_domicile: string;
  personne_tiers_concerne_tel_travail: string;
  personne_tiers_concerne_tel_mobile: string;
  personne_tiers_concerne_adr_num: string;
  personne_tiers_concerne_adr_voie: string;

  @Type(() => Commune)
  personne_tiers_concerne_adr_commune: Commune;

  personne_tiers_concerne_societe: string;
  personne_tiers_concerne_adr_lieudit: string;
  personne_tiers_concerne_adr_complement: string;
  personne_tiers_concerne_adr_complement1: string;
  personne_tiers_concerne_adr_complement2: string;

  marqueur: string;
  notes_traitement = '';
  description_complement = '';

  @Type(() => Action)
  actions: Action[] = [];

  @Type(() => Parametre)
  services: Parametre[];

  @Type(() => Parametre)
  finalite_echange: Parametre;

  @Type(() => Parametre)
  organisme_financier: Parametre;

  @Type(() => Parametre)
  type_aide: Parametre;

  @Type(() => Date)
  date_reouverture: Date;

  @Type(() => Utilisateur)
  utilisateur_reouverture: Utilisateur;

  @Type(() => Parametre)
  risques_salarie: Parametre[];

  @Type(() => Parametre)
  risques_client: Parametre[];

  @Type(() => Parametre)
  gravite: Parametre;

  @Type(() => Parametre)
  frequence: Parametre;

  sous_categorie_echange: string;
  astreinte: string;

  @Type(() => DocumentEchange)
  documents: DocumentEchange[] = [];

  @Type(() => EchangeMarqueur)
  echange_marqueurs: EchangeMarqueur[] = [];

  echange_fermable: boolean; // Champ calculé pour savoir si un échange peut être fermé.

  a_fermer: boolean; // Précise si l'échange doit être fermé.

  // Actions triées par ordre déchronologique
  get actionsDateDesc() {
    let actionsDateDesc = [];

    for (let i = this.actions.length - 1; i >= 0; i--) {
      actionsDateDesc.push(this.actions[i]);
    }

    return actionsDateDesc;
  }

  get categorieLabel() {
    let categorieLabel = '';

    if (this.categorie_echange) {
      categorieLabel = this.categorie_echange.libelle;
    }
    if (this.sous_categorie_echange) {
      categorieLabel += ' ' + this.sous_categorie_echange;
    }

    return categorieLabel;
  }

  get firstDateEcheance() {
    let firstDate = null;

    this.actions.forEach((action) => {
      let echeance = action.echeance;
      if (action.statut_action.code_parametre !== 'F') {
        if (echeance !== undefined) {
          if (firstDate == null) {
            firstDate = echeance;
          } else if (echeance.getTime() < firstDate.getTime()) {
            firstDate = echeance;
          }
        }
      }
    });

    return firstDate;
  }

  get hasTiers() {
    if (this.personne_sujet_nature !== null && this.personne_sujet_nature !== undefined) {
      if (this.personne_sujet_nature.code_parametre === 'TIE' || this.personne_sujet_nature.code_parametre === 'TIN') {
        return true;
      }
    }
    return false;
  }

  get hasTiersConcerne() {
    if (this.categorie_echange !== null && this.categorie_echange !== undefined) {
      if (this.categorie_echange.code_parametre === 'REC' || this.categorie_echange.code_parametre === 'REMINF') {
        return true;
      }
    }
    return false;
  }

  get isDevis() {
    if (this.categorie_echange === null || this.categorie_echange === undefined || this.categorie_echange.code_parametre !== 'DEV') {
      return false;
    } else {
      return true;
    }
  }

  get isTiers() {
    if (this.personne_sujet_nature !== null
      && this.personne_sujet_nature !== undefined
      && (this.personne_sujet_nature.code_parametre === 'TIE' || this.personne_sujet_nature.code_parametre === 'TIN')) {
      return true;
    } else {
      return false;
    }
  }

  get openActionsLength() {
    let nbOpen = 0;

    this.actions.forEach((action) => {
      if (action.statut_action.code_parametre !== 'F') {
        nbOpen++;
      }
    });

    return nbOpen;
  }

  get risquesClientLabel() {
    let risquesClientLabel = '';

    this.risques_client.forEach(function (risque_client) {
      if (risquesClientLabel !== '') {
        risquesClientLabel += ', ';
      }
      risquesClientLabel += risque_client.libelle;
    });

    return risquesClientLabel;
  }

  get risquesSalarieLabel() {
    let risquesSalarieLabel = '';

    this.risques_salarie.forEach(function (risque_salarie) {
      if (risquesSalarieLabel !== '') {
        risquesSalarieLabel += ', ';
      }
      risquesSalarieLabel += risque_salarie.libelle;
    });

    return risquesSalarieLabel;
  }

  get servicesLabel() {
    let servicesLabel = '';

    this.services.forEach(function (service) {
      if (servicesLabel !== '') {
        servicesLabel += ', ';
      }
      servicesLabel += service.libelle;
    });

    return servicesLabel;
  }

  static getForm(echange = null): FormGroup {
    if (!echange) {
      echange = new Echange();
    }

    let actionsForm = [];
    echange.actions.forEach(function (action) {
      actionsForm.push(Action.getForm(action));
    });

    let documentsForm = [];
    echange.documents.forEach(function (document) {
      documentsForm.push(DocumentEchange.getForm(document));
    });

    let echangeForm = new FormGroup({
      'personne_sujet_civilite': new FormControl(echange.personne_sujet_civilite, Validators.required),
      'personne_sujet_nom': new FormControl(echange.personne_sujet_nom, Validators.required),
      'personne_sujet_prenom': new FormControl(echange.personne_sujet_prenom, Validators.required),
      'personne_sujet_societe': new FormControl(echange.personne_sujet_societe),
      'personne_sujet_tel_domicile': new FormControl(echange.personne_sujet_tel_domicile, {
        validators: phoneValidator(),
        updateOn: 'blur'
      }),
      'personne_sujet_tel_travail': new FormControl(echange.personne_sujet_tel_travail, {
        validators: phoneValidator(),
        updateOn: 'blur'
      }),
      'personne_sujet_tel_mobile': new FormControl(echange.personne_sujet_tel_mobile, {
        validators: phoneValidator(),
        updateOn: 'blur'
      }),
      'personne_sujet_mail': new FormControl(echange.personne_sujet_mail, {
        validators: emailValidator(),
        updateOn: 'blur'
      }),
      'personne_sujet_adr_num': new FormControl(echange.personne_sujet_adr_num),
      'personne_sujet_adr_complement': new FormControl(echange.personne_sujet_adr_complement),
      'personne_sujet_adr_voie': new FormControl(echange.personne_sujet_adr_voie),
      'personne_sujet_adr_complement1': new FormControl(echange.personne_sujet_adr_complement1),
      'personne_sujet_adr_complement2': new FormControl(echange.personne_sujet_adr_complement2),
      'personne_sujet_adr_code_postal': new FormControl(echange.personne_sujet_adr_commune ? echange.personne_sujet_adr_commune.code_postal : null),
      'personne_sujet_adr_commune': new FormControl(echange.personne_sujet_adr_commune),
      'personne_sujet_adr_lieudit': new FormControl(echange.personne_sujet_adr_lieudit),
      'personne_sujet_lien': new FormControl({
        value: echange.personne_sujet_lien,
        disabled: !echange.isTiers
      }, Validators.required),
      'personne_tiers_civilite': new FormControl(echange.personne_tiers_civilite),
      'personne_tiers_nom': new FormControl({
        value: echange.personne_tiers_nom,
        disabled: !echange.isTiers
      }, Validators.required),
      'personne_tiers_prenom': new FormControl(echange.personne_tiers_prenom),
      'personne_tiers_societe': new FormControl(echange.personne_tiers_societe),
      'personne_tiers_tel_domicile': new FormControl(echange.personne_tiers_tel_domicile, {
        validators: phoneValidator(),
        updateOn: 'blur'
      }),
      'personne_tiers_tel_travail': new FormControl(echange.personne_tiers_tel_travail, {
        validators: phoneValidator(),
        updateOn: 'blur'
      }),
      'personne_tiers_tel_mobile': new FormControl(echange.personne_tiers_tel_mobile, {
        validators: phoneValidator(),
        updateOn: 'blur'
      }),
      'personne_tiers_mail': new FormControl(echange.personne_tiers_mail, {
        validators: emailValidator(),
        updateOn: 'blur'
      }),
      'personne_tiers_adr_num': new FormControl(echange.personne_tiers_adr_num),
      'personne_tiers_adr_complement': new FormControl(echange.personne_tiers_adr_complement),
      'personne_tiers_adr_voie': new FormControl(echange.personne_tiers_adr_voie),
      'personne_tiers_adr_complement1': new FormControl(echange.personne_tiers_adr_complement1),
      'personne_tiers_adr_complement2': new FormControl(echange.personne_tiers_adr_complement2),
      'personne_tiers_adr_code_postal': new FormControl(echange.personne_tiers_adr_commune ? echange.personne_tiers_adr_commune.code_postal : null),
      'personne_tiers_adr_commune': new FormControl(echange.personne_tiers_adr_commune),
      'personne_tiers_adr_lieudit': new FormControl(echange.personne_tiers_adr_lieudit),
      'personne_tiers_concerne_civilite': new FormControl(echange.personne_tiers_concerne_civilite),
      'personne_tiers_concerne_nature': new FormControl(echange.personne_tiers_concerne_nature),
      'personne_tiers_concerne_nom': new FormControl(echange.personne_tiers_concerne_nom),
      'personne_tiers_concerne_prenom': new FormControl(echange.personne_tiers_concerne_prenom),
      'personne_tiers_concerne_societe': new FormControl(echange.personne_tiers_concerne_societe),
      'personne_tiers_concerne_tel_domicile': new FormControl(echange.personne_tiers_concerne_tel_domicile, {
        validators: phoneValidator(),
        updateOn: 'blur'
      }),
      'personne_tiers_concerne_tel_travail': new FormControl(echange.personne_tiers_concerne_tel_travail, {
        validators: phoneValidator(),
        updateOn: 'blur'
      }),
      'personne_tiers_concerne_tel_mobile': new FormControl(echange.personne_tiers_concerne_tel_mobile, {
        validators: phoneValidator(),
        updateOn: 'blur'
      }),
      'personne_tiers_concerne_mail': new FormControl(echange.personne_tiers_concerne_mail, {
        validators: emailValidator(),
        updateOn: 'blur'
      }),
      'personne_tiers_concerne_adr_num': new FormControl(echange.personne_tiers_concerne_adr_num),
      'personne_tiers_concerne_adr_complement': new FormControl(echange.personne_tiers_concerne_adr_complement),
      'personne_tiers_concerne_adr_voie': new FormControl(echange.personne_tiers_concerne_adr_voie),
      'personne_tiers_concerne_adr_complement1': new FormControl(echange.personne_tiers_concerne_adr_complement1),
      'personne_tiers_concerne_adr_complement2': new FormControl(echange.personne_tiers_concerne_adr_complement2),
      'personne_tiers_concerne_adr_code_postal': new FormControl(echange.personne_tiers_concerne_adr_commune ? echange.personne_tiers_concerne_adr_commune.code_postal : null),
      'personne_tiers_concerne_adr_commune': new FormControl(echange.personne_tiers_concerne_adr_commune),
      'personne_tiers_concerne_adr_lieudit': new FormControl(echange.personne_tiers_concerne_adr_lieudit),
      'federation': new FormControl(echange.federation, Validators.required),
      'association': new FormControl(echange.association, Validators.required),
      'agence': new FormControl(echange.agence,
        echange.statut_echange && echange.statut_echange.code_parametre === 'O' ? Validators.required : null
      ),
      'personne_sujet_nature': new FormControl(echange.personne_sujet_nature),
      'categorie_echange': new FormControl(echange.categorie_echange, Validators.required),
      'created': new FormControl(echange.created, Validators.required),
      'operateur': new FormControl(echange.operateur),
      'statut_echange': new FormControl(echange.statut_echange),
      'isStatutOpen': new FormControl(echange.statut_echange ? echange.statut_echange.code_parametre === 'O' : false),
      'priorite': new FormControl(echange.priorite),
      'isPrioriteUrgent': new FormControl(echange.priorite ? echange.priorite === 'U' : false),
      'type_echange': new FormControl(echange.type_echange, Validators.required),
      'type_echange_sens': new FormControl(echange.type_echange_sens),
      // Si l'échange est une demande de devis on enleve le controle sur le motif
      'motif_echange': new FormControl(echange.motif_echange, Validators.required),
      'marqueur': new FormControl(echange.marqueur),
      'services': new FormControl({value: echange.services, disabled: !echange.isDevis}),
      'objet_echange': new FormControl({value: echange.objet_echange, disabled: echange.isDevis}, Validators.required),
      'description': new FormControl(echange.description),
      'description_complement': new FormControl(''),
      'notes_traitement': new FormControl({value: echange.notes_traitement, disabled: !echange.isDevis}),
      'personne_sujet_id_philia': new FormControl(echange.personne_sujet_id_philia),
      'personne_tiers_id_philia': new FormControl(echange.personne_tiers_id_philia),
      'personne_tiers_concerne_id_philia': new FormControl(echange.personne_tiers_concerne_id_philia),
      'actions': new FormArray(actionsForm),
      'finalite_echange': new FormControl(echange.finalite_echange),
      'organisme_financier': new FormControl(echange.organisme_financier),
      'type_aide': new FormControl(echange.type_aide),
      'date_reouverture': new FormControl(echange.date_reouverture),
      'utilisateur_reouverture': new FormControl(echange.utilisateur_reouverture),
      'risques_salarie': new FormControl(echange.risques_salarie),
      'risques_client': new FormControl(echange.risques_client),
      'gravite': new FormControl(echange.gravite),
      'frequence': new FormControl(echange.frequence),
      'sous_categorie_echange': new FormControl(echange.sous_categorie_echange),
      'documents': new FormArray(documentsForm),
    }, {validators: echangeValidator});

    if (echange.motif_echange.code_parametre === 'NSPSE' || echange.motif_echange.code_parametre === 'FAUNUM') {
      echangeForm.get('personne_sujet_civilite').clearValidators();
      echangeForm.get('personne_sujet_nom').clearValidators();
      echangeForm.get('personne_sujet_prenom').clearValidators();
    }

    return echangeForm;
  }

  isClosed(currentUtilisateur: Utilisateur) {
    if (!this.statut_echange) {
      return false;
    }

    // Echange clos ?
    let isClosed = this.statut_echange.code_parametre === 'F';

    // Utilisateur en lecture => Echange clos (pas de modif)
    if (!isClosed && !currentUtilisateur.hasFonctionnalite(DroitModule.module_echanges, DroitModule.fonctionnalite_edit_echanges)) {
      isClosed = true;
    } else if (!isClosed) {
      // Reponsable => on met l'échange en clos si le responsable n'a pas d'action ouvertes affectées à lui
      let hasActionAffectee = false;
      if (this.actions !== undefined && this.actions !== null) {
        this.actions.forEach((action) => {
          let id_utilisateur = currentUtilisateur.id_utilisateur;
          // Action pas fermé et responsable = user
          if (action.statut_action.code_parametre !== 'F' && action.responsable && action.responsable.id_utilisateur === id_utilisateur) {
            hasActionAffectee = true;
          }
        });
      }

      if (!hasActionAffectee) {
        isClosed = true;
      }
    }

    return isClosed;
  }

  serialize() {
    let serializeObject = classToPlain(this);
    delete serializeObject['id_echange'];
    delete serializeObject['actions'];
    delete serializeObject['utilisateur_reouverture'];
    delete serializeObject['date_reouverture'];

    delete serializeObject['closed'];
    delete serializeObject['utilisateur_fermeture'];

    if (serializeObject.hasOwnProperty('num_identifiant')) {
      delete serializeObject['num_identifiant'];
    }

    if (serializeObject.hasOwnProperty('date_creation')) {
      delete serializeObject['date_creation'];
    }

    if (serializeObject.hasOwnProperty('notes_traitement')) {
      delete serializeObject['notes_traitement'];
    }

    if (serializeObject.hasOwnProperty('description_complement')) {
      delete serializeObject['description_complement'];
    }

    if (serializeObject.hasOwnProperty('personne_sujet_adr_code_postal')) {
      delete serializeObject['personne_sujet_adr_code_postal'];
    }

    if (serializeObject.hasOwnProperty('personne_tiers_adr_code_postal')) {
      delete serializeObject['personne_tiers_adr_code_postal'];
    }

    if (serializeObject.hasOwnProperty('personne_tiers_concerne_adr_code_postal')) {
      delete serializeObject['personne_tiers_concerne_adr_code_postal'];
    }

    if (this.federation !== null && this.federation !== undefined) {
      serializeObject['federation'] = this.federation.id_federation;
    }

    if (this.association !== null && this.association !== undefined) {
      serializeObject['association'] = this.association.id_association;
    }

    if (this.agence !== null && this.agence !== undefined) {
      serializeObject['agence'] = this.agence.id_agence;
    }

    if (this.operateur !== null && this.operateur !== undefined) {
      serializeObject['operateur'] = this.operateur.id_utilisateur;
    }

    if (this.personne_sujet_civilite !== null && this.personne_sujet_civilite !== undefined) {
      serializeObject['personne_sujet_civilite'] = this.personne_sujet_civilite.id_parametre;
    }

    if (this.personne_tiers_civilite !== null && this.personne_tiers_civilite !== undefined) {
      serializeObject['personne_tiers_civilite'] = this.personne_tiers_civilite.id_parametre;
    }

    if (this.personne_tiers_concerne_civilite !== null && this.personne_tiers_concerne_civilite !== undefined) {
      serializeObject['personne_tiers_concerne_civilite'] = this.personne_tiers_concerne_civilite.id_parametre;
    }

    if (this.statut_echange !== null && this.statut_echange !== undefined) {
      serializeObject['statut_echange'] = this.statut_echange.id_parametre;
    }

    if (this.categorie_echange !== null && this.categorie_echange !== undefined) {
      serializeObject['categorie_echange'] = this.categorie_echange.id_parametre;
    }

    if (this.type_echange !== null && this.type_echange !== undefined) {
      serializeObject['type_echange'] = this.type_echange.id_parametre;
    }

    if (this.motif_echange !== null && this.motif_echange !== undefined) {
      serializeObject['motif_echange'] = this.motif_echange.id_parametre;
    }

    if (this.personne_sujet_nature !== null && this.personne_sujet_nature !== undefined) {
      serializeObject['personne_sujet_nature'] = this.personne_sujet_nature.id_parametre;
    }

    if (this.personne_tiers_concerne_nature !== null && this.personne_tiers_concerne_nature !== undefined) {
      serializeObject['personne_tiers_concerne_nature'] = this.personne_tiers_concerne_nature.id_parametre;
    }

    if (this.personne_sujet_adr_commune !== null && this.personne_sujet_adr_commune !== undefined) {
      serializeObject['personne_sujet_adr_commune'] = this.personne_sujet_adr_commune.id_commune;
    }

    if (this.personne_tiers_adr_commune !== null && this.personne_tiers_adr_commune !== undefined) {
      serializeObject['personne_tiers_adr_commune'] = this.personne_tiers_adr_commune.id_commune;
    }

    if (this.personne_tiers_concerne_adr_commune !== null && this.personne_tiers_concerne_adr_commune !== undefined) {
      serializeObject['personne_tiers_concerne_adr_commune'] = this.personne_tiers_concerne_adr_commune.id_commune;
    }

    if (this.personne_sujet_lien !== null && this.personne_sujet_lien !== undefined) {
      serializeObject['personne_sujet_lien'] = this.personne_sujet_lien.id_parametre;
    }

    if (this.notes_traitement !== null && this.notes_traitement !== undefined && this.notes_traitement !== '') {
      let separator = '\n';
      if (this.description !== null && this.description !== undefined && this.description !== '') {
        serializeObject['description'] = this.description + separator + this.notes_traitement;
      } else {
        serializeObject['description'] = this.notes_traitement;
      }
    }

    if (this.description_complement !== null && this.description_complement !== undefined && this.description_complement !== '') {
      let separator = '\n';
      if (this.description !== null && this.description !== undefined && this.description !== '') {
        serializeObject['description'] = this.description + separator + this.description_complement;
      } else {
        serializeObject['description'] = this.description_complement;
      }
    }

    if (this.services !== null && this.services !== undefined) {
      serializeObject['services'] = [];
      this.services.forEach(function (service) {
        serializeObject['services'].push(service.id_parametre);
      });
    }

    if (this.finalite_echange !== null && this.finalite_echange !== undefined) {
      serializeObject['finalite_echange'] = this.finalite_echange.id_parametre;
    }

    if (this.organisme_financier !== null && this.organisme_financier !== undefined) {
      serializeObject['organisme_financier'] = this.organisme_financier.id_parametre;
    }

    if (this.type_aide !== null && this.type_aide !== undefined) {
      serializeObject['type_aide'] = this.type_aide.id_parametre;
    }

    if (this.risques_salarie !== null && this.risques_salarie !== undefined) {
      serializeObject['risques_salarie'] = [];
      this.risques_salarie.forEach(function (risque_salarie) {
        serializeObject['risques_salarie'].push(risque_salarie.id_parametre);
      });
    }

    if (this.risques_client !== null && this.risques_client !== undefined) {
      serializeObject['risques_client'] = [];
      this.risques_client.forEach(function (risque_client) {
        serializeObject['risques_client'].push(risque_client.id_parametre);
      });
    }

    if (this.gravite !== null && this.gravite !== undefined) {
      serializeObject['gravite'] = this.gravite.id_parametre;
    }

    if (this.frequence !== null && this.frequence !== undefined) {
      serializeObject['frequence'] = this.frequence.id_parametre;
    }

    serializeObject['documents'] = [];
    this.documents.forEach(function (document) {
      serializeObject['documents'].push(document.serialize());
    });

    if (this.echange_marqueurs !== null && this.echange_marqueurs !== undefined) {
      serializeObject['echange_marqueurs'] = [];
      this.echange_marqueurs.forEach(function (echange) {
        serializeObject['echange_marqueurs'].push(echange.id);
      });
    }

    // Conversion des dates en FR
    for (let propertyName in serializeObject) { // On parcourt les proprietes de l'objet
      if (serializeObject[propertyName] && serializeObject[propertyName] instanceof Date) { // Si c'est une date non null
        serializeObject[propertyName] = Globals.getDateWithTimezone(serializeObject[propertyName]);
      }
    }

    return serializeObject;
  }

  serializeForExport(type_export = '', isList: boolean = false) {
    let serializeArray = [];
    let indexAction = 0;
    let serializeObject = {};

    do {
      let currentAction = null;
      if (this.actions && this.actions.length > 0) {
        currentAction = this.actions[indexAction];
      }

      if (type_export === 'DEV' || isList) { // Devis ou liste
        serializeObject = {
          'Fédération': this.federation && indexAction === 0 ? this.federation.libelle : '',
          'Association': this.association && indexAction === 0 ? this.association.libelle : '',
          'Agence': this.agence && indexAction === 0 ? this.agence.libelle : '',
          'Date Echange': this.created && indexAction === 0 ? Globals.formatDate(this.created, true) : '',
          'Statut Echange': this.statut_echange && indexAction === 0 ? this.statut_echange.libelle : '',
          'Date Clotûre': this.closed && indexAction === 0 ? Globals.formatDate(this.closed) : '',
          'Priorité': this.priorite && indexAction === 0 ? this.priorite : '',
          'Type': indexAction === 0 ? this.type_echange.libelle : '',
          'Type Sens': this.type_echange_sens && indexAction === 0 ? this.type_echange_sens : '',
          'Catégorie': this.categorie_echange && indexAction === 0 ? this.categorie_echange.libelle : '',
          'Motif': this.motif_echange && indexAction === 0 ? this.motif_echange.libelle : '',
          'Utilisateur': this.operateur && indexAction === 0 ? this.operateur.libelle : '',
          'Civilité Individu': this.personne_sujet_civilite && indexAction === 0 ? this.personne_sujet_civilite.libelle : '',
          'Nom Individu': this.personne_sujet_nom && indexAction === 0 ? this.personne_sujet_nom : '',
          'Prénom Individu': this.personne_sujet_prenom && indexAction === 0 ? this.personne_sujet_prenom : '',
          'Nature Individu': this.personne_sujet_nature && indexAction === 0 ? this.personne_sujet_nature.libelle : '',
          'Lien Social Individu/Tiers': this.personne_sujet_lien && indexAction === 0 ? this.personne_sujet_lien.libelle : '',
          'Civilité Tiers': this.personne_tiers_civilite && indexAction === 0 ? this.personne_tiers_civilite.libelle : '',
          'Nom Tiers': this.personne_tiers_nom && indexAction === 0 ? this.personne_tiers_nom : '',
          'Prénom Tiers': this.personne_tiers_prenom && indexAction === 0 ? this.personne_tiers_prenom : '',
          'Sujet': this.objet_echange && indexAction === 0 ? this.objet_echange : '',
          'Services': this.services && indexAction === 0 ? this.servicesLabel : '',
          'Description': this.description && indexAction === 0 ? this.description : '',
          'Organisme Financeur': this.organisme_financier && indexAction === 0 ? this.organisme_financier.libelle : '',
          'Type d\'aide': this.type_aide && indexAction === 0 ? this.type_aide.libelle : '',
          'Finalité': this.finalite_echange && indexAction === 0 ? this.finalite_echange.libelle : '',
          'Action(s) Ouverte(s)': indexAction === 0 ? this.openActionsLength : '',
          'Action(s) Total': indexAction === 0 ? this.actions.length : '',

          'Date création Action': currentAction && currentAction.created ? Globals.formatDate(currentAction.created, true) : '',
          'Date échéance Action': currentAction && currentAction.echeance ? Globals.formatDate(currentAction.echeance) : '',
          'Statut Action': currentAction && currentAction.statut_action ? currentAction.statut_action.libelle : '',
          'Acteur Action': currentAction && currentAction.responsable ? currentAction.responsable.libelle : '',
          'Objet Action': currentAction && currentAction.objet_action ? currentAction.objet_action : '',
          'Description Action': currentAction && currentAction.description_action ? currentAction.description_action : '',
          'Date MAJ Action': currentAction && currentAction.updated ? Globals.formatDate(currentAction.updated, true) : '',
          'Clotûre Action par': currentAction && currentAction.utilisateur_fermeture ? currentAction.utilisateur_fermeture.libelle : '',

          'Numéro Voie Individu': this.personne_sujet_adr_num && indexAction === 0 ? this.personne_sujet_adr_num : '',
          'Type/Libellé Voie Individu': this.personne_sujet_adr_voie && indexAction === 0 ? this.personne_sujet_adr_voie : '',
          'CP Individu': this.personne_sujet_adr_commune && indexAction === 0 ? this.personne_sujet_adr_commune.code_postal : '',
          'Ville Individu': this.personne_sujet_adr_commune && indexAction === 0 ? this.personne_sujet_adr_commune.nom_commune : '',
          'Tél. Domicile Individu': this.personne_sujet_tel_domicile && indexAction === 0 ? this.personne_sujet_tel_domicile : '',
          'Tel. Prof. Individu': this.personne_sujet_tel_travail && indexAction === 0 ? this.personne_sujet_tel_travail : '',
          'Tel. Mobile Individu': this.personne_sujet_tel_mobile && indexAction === 0 ? this.personne_sujet_tel_mobile : '',
          'E-mail Individu': this.personne_sujet_mail && indexAction === 0 ? this.personne_sujet_mail : '',
          'Société Individu': this.personne_sujet_societe && indexAction === 0 ? this.personne_sujet_societe : '',
          'Numéro Voie Tiers': this.personne_tiers_adr_num && indexAction === 0 ? this.personne_tiers_adr_num : '',
          'Type/Libellé Voie Tiers': this.personne_tiers_adr_voie && indexAction === 0 ? this.personne_tiers_adr_voie : '',
          'CP Tiers': this.personne_tiers_adr_commune && indexAction === 0 ? this.personne_tiers_adr_commune.code_postal : '',
          'Ville Tiers': this.personne_tiers_adr_commune && indexAction === 0 ? this.personne_tiers_adr_commune.nom_commune : '',
          'Tél. Domicile Tiers': this.personne_tiers_tel_domicile && indexAction === 0 ? this.personne_tiers_tel_domicile : '',
          'Tel. Prof. Tiers': this.personne_tiers_tel_travail && indexAction === 0 ? this.personne_tiers_tel_travail : '',
          'Tel. Mobile Tiers': this.personne_tiers_tel_mobile && indexAction === 0 ? this.personne_tiers_tel_mobile : '',
          'E-mail Tiers': this.personne_tiers_mail && indexAction === 0 ? this.personne_tiers_mail : '',
          'Société Tiers': this.personne_tiers_societe && indexAction === 0 ? this.personne_tiers_societe : '',
          'Lieu dit Individu': this.personne_sujet_adr_lieudit && indexAction === 0 ? this.personne_sujet_adr_lieudit : ''
        };
      } else if (type_export === 'REMINF') { // Remontée Informations
        serializeObject = {
          'Fédération': this.federation && indexAction === 0 ? this.federation.libelle : '',
          'Association': this.association && indexAction === 0 ? this.association.libelle : '',
          'Agence': this.agence && indexAction === 0 ? this.agence.libelle : '',
          'Date Echange': this.created && indexAction === 0 ? Globals.formatDate(this.created, true) : '',
          'Statut Echange': this.statut_echange && indexAction === 0 ? this.statut_echange.libelle : '',
          'Date Clotûre': this.closed && indexAction === 0 ? Globals.formatDate(this.closed) : '',
          'Priorité': this.priorite && indexAction === 0 ? this.priorite : '',
          'Type': indexAction === 0 ? this.type_echange.libelle : '',
          'Type Sens': this.type_echange_sens && indexAction === 0 ? this.type_echange_sens : '',
          'Catégorie': this.categorie_echange && indexAction === 0 ? this.categorie_echange.libelle : '',
          'Motif': this.motif_echange && indexAction === 0 ? this.motif_echange.libelle : '',
          'Utilisateur': this.operateur && indexAction === 0 ? this.operateur.libelle : '',
          'Civilité Individu': this.personne_sujet_civilite && indexAction === 0 ? this.personne_sujet_civilite.libelle : '',
          'Nom Individu': this.personne_sujet_nom && indexAction === 0 ? this.personne_sujet_nom : '',
          'Prénom Individu': this.personne_sujet_prenom && indexAction === 0 ? this.personne_sujet_prenom : '',
          'Nature Individu': this.personne_sujet_nature && indexAction === 0 ? this.personne_sujet_nature.libelle : '',
          'Lien Social Individu/Tiers': this.personne_sujet_lien && indexAction === 0 ? this.personne_sujet_lien.libelle : '',
          'Civilité Tiers': this.personne_tiers_civilite && indexAction === 0 ? this.personne_tiers_civilite.libelle : '',
          'Nom Tiers': this.personne_tiers_nom && indexAction === 0 ? this.personne_tiers_nom : '',
          'Prénom Tiers': this.personne_tiers_prenom && indexAction === 0 ? this.personne_tiers_prenom : '',
          'Nature Tiers Concerné': this.personne_tiers_concerne_nature ? this.personne_tiers_concerne_nature.libelle : '',
          'Civilité Tiers Concerné': this.personne_tiers_concerne_civilite ? this.personne_tiers_concerne_civilite.libelle : '',
          'Nom Tiers Concerné': this.personne_tiers_concerne_nom ? this.personne_tiers_concerne_nom : '',
          'Prénom Tiers Concerné': this.personne_tiers_concerne_prenom ? this.personne_tiers_concerne_prenom : '',
          'Sujet': this.objet_echange && indexAction === 0 ? this.objet_echange : '',
          'Description': this.description && indexAction === 0 ? this.description : '',

          'Risques salarié': this.risques_salarie && indexAction === 0 ? this.risquesSalarieLabel : '',
          'Risques client': this.risques_client && indexAction === 0 ? this.risquesClientLabel : '',
          'Gravité': this.gravite && indexAction === 0 ? this.gravite.libelle : '',
          'Fréquence': this.frequence && indexAction === 0 ? this.frequence.libelle : '',
          'Action(s) Ouverte(s)': indexAction === 0 ? this.openActionsLength : '',
          'Action(s) Total': indexAction === 0 ? this.actions.length : '',

          'Date création Action': currentAction && currentAction.created ? Globals.formatDate(currentAction.created, true) : '',
          'Date échéance Action': currentAction && currentAction.echeance ? Globals.formatDate(currentAction.echeance) : '',
          'Statut Action': currentAction && currentAction.statut_action ? currentAction.statut_action.libelle : '',
          'Acteur Action': currentAction && currentAction.responsable ? currentAction.responsable.libelle : '',
          'Objet Action': currentAction && currentAction.objet_action ? currentAction.objet_action : '',
          'Description Action': currentAction && currentAction.description_action ? currentAction.description_action : '',
          'Date MAJ Action': currentAction && currentAction.updated ? Globals.formatDate(currentAction.updated, true) : '',
          'Clotûre Action par': currentAction && currentAction.utilisateur_fermeture ? currentAction.utilisateur_fermeture.libelle : '',

          'Numéro Voie Individu': this.personne_sujet_adr_num && indexAction === 0 ? this.personne_sujet_adr_num : '',
          'Type/Libellé Voie Individu': this.personne_sujet_adr_voie && indexAction === 0 ? this.personne_sujet_adr_voie : '',
          'CP Individu': this.personne_sujet_adr_commune && indexAction === 0 ? this.personne_sujet_adr_commune.code_postal : '',
          'Ville Individu': this.personne_sujet_adr_commune && indexAction === 0 ? this.personne_sujet_adr_commune.nom_commune : '',
          'Tél. Domicile Individu': this.personne_sujet_tel_domicile && indexAction === 0 ? this.personne_sujet_tel_domicile : '',
          'Tel. Prof. Individu': this.personne_sujet_tel_travail && indexAction === 0 ? this.personne_sujet_tel_travail : '',
          'Tel. Mobile Individu': this.personne_sujet_tel_mobile && indexAction === 0 ? this.personne_sujet_tel_mobile : '',
          'E-mail Individu': this.personne_sujet_mail && indexAction === 0 ? this.personne_sujet_mail : '',
          'Société Individu': this.personne_sujet_societe && indexAction === 0 ? this.personne_sujet_societe : '',
          'Numéro Voie Tiers': this.personne_tiers_adr_num && indexAction === 0 ? this.personne_tiers_adr_num : '',
          'Type/Libellé Voie Tiers': this.personne_tiers_adr_voie && indexAction === 0 ? this.personne_tiers_adr_voie : '',
          'CP Tiers': this.personne_tiers_adr_commune && indexAction === 0 ? this.personne_tiers_adr_commune.code_postal : '',
          'Ville Tiers': this.personne_tiers_adr_commune && indexAction === 0 ? this.personne_tiers_adr_commune.nom_commune : '',
          'Tél. Domicile Tiers': this.personne_tiers_tel_domicile && indexAction === 0 ? this.personne_tiers_tel_domicile : '',
          'Tel. Prof. Tiers': this.personne_tiers_tel_travail && indexAction === 0 ? this.personne_tiers_tel_travail : '',
          'Tel. Mobile Tiers': this.personne_tiers_tel_mobile && indexAction === 0 ? this.personne_tiers_tel_mobile : '',
          'E-mail Tiers': this.personne_tiers_mail && indexAction === 0 ? this.personne_tiers_mail : '',
          'Société Tiers': this.personne_tiers_societe && indexAction === 0 ? this.personne_tiers_societe : '',

          'Numéro Voie Tiers Concerné': this.personne_tiers_concerne_adr_num && indexAction === 0 ? this.personne_tiers_concerne_adr_num : '',
          'Type/Libellé Voie Tiers Concerné': this.personne_tiers_concerne_adr_voie && indexAction === 0 ? this.personne_tiers_concerne_adr_voie : '',
          'CP Tiers Concerné': this.personne_tiers_concerne_adr_commune && indexAction === 0 ? this.personne_tiers_concerne_adr_commune.code_postal : '',
          'Ville Tiers Concerné': this.personne_tiers_concerne_adr_commune && indexAction === 0 ? this.personne_tiers_concerne_adr_commune.nom_commune : '',
          'Tél. Domicile Tiers Concerné': this.personne_tiers_concerne_tel_domicile && indexAction === 0 ? this.personne_tiers_concerne_tel_domicile : '',
          'Tel. Prof. Tiers Concerné': this.personne_tiers_concerne_tel_travail && indexAction === 0 ? this.personne_tiers_concerne_tel_travail : '',
          'Tel. Mobile Tiers Concerné': this.personne_tiers_concerne_tel_mobile && indexAction === 0 ? this.personne_tiers_concerne_tel_mobile : '',
          'E-mail Tiers Concerné': this.personne_tiers_concerne_mail && indexAction === 0 ? this.personne_tiers_concerne_mail : '',
          'Société Tiers Concerné': this.personne_tiers_concerne_societe && indexAction === 0 ? this.personne_tiers_concerne_societe : '',
          'Lieu dit Individu': this.personne_sujet_adr_lieudit && indexAction === 0 ? this.personne_sujet_adr_lieudit : ''
        };
      } else if (type_export === 'REC') { // Reclamation
        serializeObject = {
          'Fédération': this.federation && indexAction === 0 ? this.federation.libelle : '',
          'Association': this.association && indexAction === 0 ? this.association.libelle : '',
          'Agence': this.agence && indexAction === 0 ? this.agence.libelle : '',
          'Date Echange': this.created && indexAction === 0 ? Globals.formatDate(this.created, true) : '',
          'Statut Echange': this.statut_echange && indexAction === 0 ? this.statut_echange.libelle : '',
          'Date Clotûre': this.closed && indexAction === 0 ? Globals.formatDate(this.closed) : '',
          'Priorité': this.priorite && indexAction === 0 ? this.priorite : '',
          'Type': indexAction === 0 ? this.type_echange.libelle : '',
          'Type Sens': this.type_echange_sens && indexAction === 0 ? this.type_echange_sens : '',
          'Catégorie': this.categorie_echange && indexAction === 0 ? this.categorieLabel : '',
          'Motif': this.motif_echange && indexAction === 0 ? this.motif_echange.libelle : '',
          'Utilisateur': this.operateur && indexAction === 0 ? this.operateur.libelle : '',
          'Civilité Individu': this.personne_sujet_civilite && indexAction === 0 ? this.personne_sujet_civilite.libelle : '',
          'Nom Individu': this.personne_sujet_nom && indexAction === 0 ? this.personne_sujet_nom : '',
          'Prénom Individu': this.personne_sujet_prenom && indexAction === 0 ? this.personne_sujet_prenom : '',
          'Nature Individu': this.personne_sujet_nature && indexAction === 0 ? this.personne_sujet_nature.libelle : '',
          'Lien Social Individu/Tiers': this.personne_sujet_lien && indexAction === 0 ? this.personne_sujet_lien.libelle : '',
          'Civilité Tiers': this.personne_tiers_civilite && indexAction === 0 ? this.personne_tiers_civilite.libelle : '',
          'Nom Tiers': this.personne_tiers_nom && indexAction === 0 ? this.personne_tiers_nom : '',
          'Prénom Tiers': this.personne_tiers_prenom && indexAction === 0 ? this.personne_tiers_prenom : '',
          'Nature Tiers Concerné': this.personne_tiers_concerne_nature ? this.personne_tiers_concerne_nature.libelle : '',
          'Civilité Tiers Concerné': this.personne_tiers_concerne_civilite ? this.personne_tiers_concerne_civilite.libelle : '',
          'Nom Tiers Concerné': this.personne_tiers_concerne_nom ? this.personne_tiers_concerne_nom : '',
          'Prénom Tiers Concerné': this.personne_tiers_concerne_prenom ? this.personne_tiers_concerne_prenom : '',
          'Sujet': this.objet_echange && indexAction === 0 ? this.objet_echange : '',
          'Description': this.description && indexAction === 0 ? this.description : '',
          'Action(s) Ouverte(s)': indexAction === 0 ? this.openActionsLength : '',
          'Action(s) Total': indexAction === 0 ? this.actions.length : '',

          'Date création Action': currentAction && currentAction.created ? Globals.formatDate(currentAction.created, true) : '',
          'Date échéance Action': currentAction && currentAction.echeance ? Globals.formatDate(currentAction.echeance) : '',
          'Statut Action': currentAction && currentAction.statut_action ? currentAction.statut_action.libelle : '',
          'Acteur Action': currentAction && currentAction.responsable ? currentAction.responsable.libelle : '',
          'Objet Action': currentAction && currentAction.objet_action ? currentAction.objet_action : '',
          'Description Action': currentAction && currentAction.description_action ? currentAction.description_action : '',
          'Date MAJ Action': currentAction && currentAction.updated ? Globals.formatDate(currentAction.updated, true) : '',
          'Clotûre Action par': currentAction && currentAction.utilisateur_fermeture ? currentAction.utilisateur_fermeture.libelle : '',

          'Numéro Voie Individu': this.personne_sujet_adr_num && indexAction === 0 ? this.personne_sujet_adr_num : '',
          'Type/Libellé Voie Individu': this.personne_sujet_adr_voie && indexAction === 0 ? this.personne_sujet_adr_voie : '',
          'CP Individu': this.personne_sujet_adr_commune && indexAction === 0 ? this.personne_sujet_adr_commune.code_postal : '',
          'Ville Individu': this.personne_sujet_adr_commune && indexAction === 0 ? this.personne_sujet_adr_commune.nom_commune : '',
          'Tél. Domicile Individu': this.personne_sujet_tel_domicile && indexAction === 0 ? this.personne_sujet_tel_domicile : '',
          'Tel. Prof. Individu': this.personne_sujet_tel_travail && indexAction === 0 ? this.personne_sujet_tel_travail : '',
          'Tel. Mobile Individu': this.personne_sujet_tel_mobile && indexAction === 0 ? this.personne_sujet_tel_mobile : '',
          'E-mail Individu': this.personne_sujet_mail && indexAction === 0 ? this.personne_sujet_mail : '',
          'Société Individu': this.personne_sujet_societe && indexAction === 0 ? this.personne_sujet_societe : '',
          'Numéro Voie Tiers': this.personne_tiers_adr_num && indexAction === 0 ? this.personne_tiers_adr_num : '',
          'Type/Libellé Voie Tiers': this.personne_tiers_adr_voie && indexAction === 0 ? this.personne_tiers_adr_voie : '',
          'CP Tiers': this.personne_tiers_adr_commune && indexAction === 0 ? this.personne_tiers_adr_commune.code_postal : '',
          'Ville Tiers': this.personne_tiers_adr_commune && indexAction === 0 ? this.personne_tiers_adr_commune.nom_commune : '',
          'Tél. Domicile Tiers': this.personne_tiers_tel_domicile && indexAction === 0 ? this.personne_tiers_tel_domicile : '',
          'Tel. Prof. Tiers': this.personne_tiers_tel_travail && indexAction === 0 ? this.personne_tiers_tel_travail : '',
          'Tel. Mobile Tiers': this.personne_tiers_tel_mobile && indexAction === 0 ? this.personne_tiers_tel_mobile : '',
          'E-mail Tiers': this.personne_tiers_mail && indexAction === 0 ? this.personne_tiers_mail : '',
          'Société Tiers': this.personne_tiers_societe && indexAction === 0 ? this.personne_tiers_societe : '',

          'Numéro Voie Tiers Concerné': this.personne_tiers_concerne_adr_num && indexAction === 0 ? this.personne_tiers_concerne_adr_num : '',
          'Type/Libellé Voie Tiers Concerné': this.personne_tiers_concerne_adr_voie && indexAction === 0 ? this.personne_tiers_concerne_adr_voie : '',
          'CP Tiers Concerné': this.personne_tiers_concerne_adr_commune && indexAction === 0 ? this.personne_tiers_concerne_adr_commune.code_postal : '',
          'Ville Tiers Concerné': this.personne_tiers_concerne_adr_commune && indexAction === 0 ? this.personne_tiers_concerne_adr_commune.nom_commune : '',
          'Tél. Domicile Tiers Concerné': this.personne_tiers_concerne_tel_domicile && indexAction === 0 ? this.personne_tiers_concerne_tel_domicile : '',
          'Tel. Prof. Tiers Concerné': this.personne_tiers_concerne_tel_travail && indexAction === 0 ? this.personne_tiers_concerne_tel_travail : '',
          'Tel. Mobile Tiers Concerné': this.personne_tiers_concerne_tel_mobile && indexAction === 0 ? this.personne_tiers_concerne_tel_mobile : '',
          'E-mail Tiers Concerné': this.personne_tiers_concerne_mail && indexAction === 0 ? this.personne_tiers_concerne_mail : '',
          'Société Tiers Concerné': this.personne_tiers_concerne_societe && indexAction === 0 ? this.personne_tiers_concerne_societe : '',
          'Lieu dit Individu': this.personne_sujet_adr_lieudit && indexAction === 0 ? this.personne_sujet_adr_lieudit : ''
        };
      } else {
        serializeObject = {
          'Fédération': this.federation && indexAction === 0 ? this.federation.libelle : '',
          'Association': this.association && indexAction === 0 ? this.association.libelle : '',
          'Agence': this.agence && indexAction === 0 ? this.agence.libelle : '',
          'Date Echange': this.created && indexAction === 0 ? Globals.formatDate(this.created, true) : '',
          'Statut Echange': this.statut_echange && indexAction === 0 ? this.statut_echange.libelle : '',
          'Date Clotûre': this.closed && indexAction === 0 ? Globals.formatDate(this.closed) : '',
          'Priorité': this.priorite && indexAction === 0 ? this.priorite : '',
          'Type': indexAction === 0 ? this.type_echange.libelle : '',
          'Type Sens': this.type_echange_sens && indexAction === 0 ? this.type_echange_sens : '',
          'Catégorie': this.categorie_echange && indexAction === 0 ? this.categorie_echange.libelle : '',
          'Motif': this.motif_echange && indexAction === 0 ? this.motif_echange.libelle : '',
          'Utilisateur': this.operateur && indexAction === 0 ? this.operateur.libelle : '',
          'Civilité Individu': this.personne_sujet_civilite && indexAction === 0 ? this.personne_sujet_civilite.libelle : '',
          'Nom Individu': this.personne_sujet_nom && indexAction === 0 ? this.personne_sujet_nom : '',
          'Prénom Individu': this.personne_sujet_prenom && indexAction === 0 ? this.personne_sujet_prenom : '',
          'Nature Individu': this.personne_sujet_nature && indexAction === 0 ? this.personne_sujet_nature.libelle : '',
          'Lien Social Individu/Tiers': this.personne_sujet_lien && indexAction === 0 ? this.personne_sujet_lien.libelle : '',
          'Civilité Tiers': this.personne_tiers_civilite && indexAction === 0 ? this.personne_tiers_civilite.libelle : '',
          'Nom Tiers': this.personne_tiers_nom && indexAction === 0 ? this.personne_tiers_nom : '',
          'Prénom Tiers': this.personne_tiers_prenom && indexAction === 0 ? this.personne_tiers_prenom : '',
          'Sujet': this.objet_echange && indexAction === 0 ? this.objet_echange : '',
          'Description': this.description && indexAction === 0 ? this.description : '',
          'Action(s) Ouverte(s)': indexAction === 0 ? this.openActionsLength : '',
          'Action(s) Total': indexAction === 0 ? this.actions.length : '',

          'Date création Action': currentAction && currentAction.created ? Globals.formatDate(currentAction.created, true) : '',
          'Date échéance Action': currentAction && currentAction.echeance ? Globals.formatDate(currentAction.echeance) : '',
          'Statut Action': currentAction && currentAction.statut_action ? currentAction.statut_action.libelle : '',
          'Acteur Action': currentAction && currentAction.responsable ? currentAction.responsable.libelle : '',
          'Objet Action': currentAction && currentAction.objet_action ? currentAction.objet_action : '',
          'Description Action': currentAction && currentAction.description_action ? currentAction.description_action : '',
          'Date MAJ Action': currentAction && currentAction.updated ? Globals.formatDate(currentAction.updated, true) : '',
          'Clotûre Action par': currentAction && currentAction.utilisateur_fermeture ? currentAction.utilisateur_fermeture.libelle : '',

          'Numéro Voie Individu': this.personne_sujet_adr_num && indexAction === 0 ? this.personne_sujet_adr_num : '',
          'Type/Libellé Voie Individu': this.personne_sujet_adr_voie && indexAction === 0 ? this.personne_sujet_adr_voie : '',
          'CP Individu': this.personne_sujet_adr_commune && indexAction === 0 ? this.personne_sujet_adr_commune.code_postal : '',
          'Ville Individu': this.personne_sujet_adr_commune && indexAction === 0 ? this.personne_sujet_adr_commune.nom_commune : '',
          'Tél. Domicile Individu': this.personne_sujet_tel_domicile && indexAction === 0 ? this.personne_sujet_tel_domicile : '',
          'Tel. Prof. Individu': this.personne_sujet_tel_travail && indexAction === 0 ? this.personne_sujet_tel_travail : '',
          'Tel. Mobile Individu': this.personne_sujet_tel_mobile && indexAction === 0 ? this.personne_sujet_tel_mobile : '',
          'E-mail Individu': this.personne_sujet_mail && indexAction === 0 ? this.personne_sujet_mail : '',
          'Société Individu': this.personne_sujet_societe && indexAction === 0 ? this.personne_sujet_societe : '',
          'Numéro Voie Tiers': this.personne_tiers_adr_num && indexAction === 0 ? this.personne_tiers_adr_num : '',
          'Type/Libellé Voie Tiers': this.personne_tiers_adr_voie && indexAction === 0 ? this.personne_tiers_adr_voie : '',
          'CP Tiers': this.personne_tiers_adr_commune && indexAction === 0 ? this.personne_tiers_adr_commune.code_postal : '',
          'Ville Tiers': this.personne_tiers_adr_commune && indexAction === 0 ? this.personne_tiers_adr_commune.nom_commune : '',
          'Tél. Domicile Tiers': this.personne_tiers_tel_domicile && indexAction === 0 ? this.personne_tiers_tel_domicile : '',
          'Tel. Prof. Tiers': this.personne_tiers_tel_travail && indexAction === 0 ? this.personne_tiers_tel_travail : '',
          'Tel. Mobile Tiers': this.personne_tiers_tel_mobile && indexAction === 0 ? this.personne_tiers_tel_mobile : '',
          'E-mail Tiers': this.personne_tiers_mail && indexAction === 0 ? this.personne_tiers_mail : '',
          'Société Tiers': this.personne_tiers_societe && indexAction === 0 ? this.personne_tiers_societe : '',
          'Lieu dit Individu': this.personne_sujet_adr_lieudit && indexAction === 0 ? this.personne_sujet_adr_lieudit : ''
          /*
          'Civilité Tiers Concerné': this.personne_tiers_concerne_civilite ? this.personne_tiers_concerne_civilite.libelle : '',
          'Nature Tiers Concerné': this.personne_tiers_concerne_nature ? this.personne_tiers_concerne_nature.libelle : '',
          'Nom Tiers Concerné': this.personne_tiers_concerne_nom ? this.personne_tiers_concerne_nom : '',
          'Prénom Tiers Concerné': this.personne_tiers_concerne_prenom ? this.personne_tiers_concerne_prenom : '',
          'Numéro Voie Tiers Concerné': this.personne_tiers_concerne_adr_num ? this.personne_tiers_concerne_adr_num : '',
          'Type/Libellé Voie Tiers Concerné': this.personne_tiers_concerne_adr_voie ? this.personne_tiers_concerne_adr_voie : '',
          'Code Postal Tiers Concerné': this.personne_tiers_concerne_adr_commune ? this.personne_tiers_concerne_adr_commune.code_postal : '',
          'Ville Tiers Concerné': this.personne_tiers_concerne_adr_commune ? this.personne_tiers_concerne_adr_commune.nom_commune : '',
          'Tél. Domicile Tiers Concerné': this.personne_tiers_concerne_tel_domicile ? this.personne_tiers_concerne_tel_domicile : '',
          'Tel. Prof. Tiers Concerné': this.personne_tiers_concerne_tel_travail ? this.personne_tiers_concerne_tel_travail : '',
          'Tel. Mobile Tiers Concerné': this.personne_tiers_concerne_tel_mobile ? this.personne_tiers_concerne_tel_mobile : '',
          'E-mail Tiers Concerné': this.personne_tiers_concerne_mail ? this.personne_tiers_concerne_mail : '',
          'Société Tiers Concerné': this.personne_tiers_concerne_societe ? this.personne_tiers_concerne_societe : ''*/
        };
      }

      serializeArray.push(serializeObject);

      indexAction++;

    } while (indexAction < this.actions.length);

    return serializeArray;
  }

  serializeWithActionsForExport() {
    let serializeObject = [];
    serializeObject[0] = [];
    let serializeActionObject = [];
    serializeObject[0][0] = this.serializeForExport(); // Données echange

    let self = this;

    this.actions.forEach(function (action) { // On insere les données actions
      action.echange = self;
      serializeActionObject.push(action.serializeForExport());
    });

    if (serializeActionObject.length > 0) {
      serializeObject.push(serializeActionObject);
    }

    return serializeObject;
  }
}
