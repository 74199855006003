<form (ngSubmit)="onSubmit()" #filtreForm="ngForm" >
  <div class="ui-grid ui-grid-responsive ui-grid-pad ui-fluid" style="margin: 10px 0px">

    <div class="ui-grid-row">
      <div class="ui-g-4">
        <div class="ui-g-3">
          <label>Fédération</label>
        </div>
        <div class="ui-g-9">
          <!-- <p-dropdown [options]="federationsList" [(ngModel)]="filtreEchange.federation"
            [readonly]="currentUtilisateur.perimetre !== 'National'"
            [showClear]="currentUtilisateur.perimetre === 'National'" [style]="{'width':'100%'}" class="app-dropdown"
            [placeholder]="'Fédération...'" (onChange)="onChangeFederation()" #federation="ngModel" name="federation"
            optionLabel="libelle"></p-dropdown> -->
          <p-multiSelect [options]="federationsList" [(ngModel)]="filtreEchange.federation" dataKey="id_federation"
            [readonly]="isFederationReadOnly()" emptyFilterMessage="Aucun résultat trouvé"
            (onChange)="onChangeFederation()" #federation="ngModel" name="federation" optionLabel="libelle"
            defaultLabel="Fédérations..." selectedItemsLabel="{0} sélectionnés"></p-multiSelect>
        </div>
      </div>
      <div class="ui-g-4">
        <div class="ui-g-3">
          <label>Association</label>
        </div>
        <div class="ui-g-9">
          <!-- <p-dropdown [options]="associationsList" [(ngModel)]="filtreEchange.association"
            [readonly]="currentUtilisateur.type_acces === 'monoAsso' || currentUtilisateur.type_acces === 'multiSite' || (this.currentUtilisateur.perimetre === 'Association' && !this.currentUtilisateur.type_acces)"
            [showClear]="currentUtilisateur.type_acces === 'multiAsso'" [style]="{'width':'100%'}"
            [placeholder]="'Association'" (onChange)="onChangeAssociation()" #association="ngModel" name="association" optionLabel="libelle"
            dataKey="id_association"></p-dropdown> -->
          <p-multiSelect [options]="associationsItemsList" [(ngModel)]="filtreEchange.association"
            [readonly]="isAssociationReadOnly()" pTooltip="Impossible de sélectionner une association si plusieurs fédérations sont sélectionnées"
            (onChange)="onChangeAssociation()" #association="ngModel" name="association" emptyFilterMessage="Aucun résultat trouvé"
            defaultLabel="Associations..." selectedItemsLabel="{0} sélectionnés" dataKey="id_association">
            <ng-template let-association pTemplate="item">
              <span *ngIf="association.value.disabled" style="font-style: italic;"><i class="pi pi-times" style="color: red;float: left;"></i>{{association.value.libelle}}</span>
              <span *ngIf="!association.value.disabled">{{association.value.libelle}}</span>
            </ng-template>
          </p-multiSelect>
        </div>
      </div>
      <div class="ui-g-4">
        <div class="ui-g-2">
          <label>Agence</label>
        </div>
        <div class="ui-g-9">
          <!-- <p-dropdown [options]="agencesList" [(ngModel)]="filtreEchange.agence" [style]="{'width':'100%'}"
            [placeholder]="'Agence'" #agence="ngModel" name="agence" optionLabel="libelle"
            dataKey="id_agence"></p-dropdown> -->
          <p-multiSelect [options]="agencesItemsList" [(ngModel)]="filtreEchange.agence"
            [readonly]="isAgenceReadOnly()" pTooltip="Impossible de sélectionner une agence si plusieurs associations sont sélectionnées"
            #agence="ngModel" name="agence" emptyFilterMessage="Aucun résultat trouvé"
            defaultLabel="Agences..." selectedItemsLabel="{0} sélectionnés" dataKey="id_agence">
            <ng-template let-agence pTemplate="item">
              <span *ngIf="agence.value.disabled" style="font-style: italic;"><i class="pi pi-times" style="color: red;float: left;"></i>{{agence.value.libelle}}</span>
              <span *ngIf="!agence.value.disabled">{{agence.value.libelle}}</span>
            </ng-template>
          </p-multiSelect>
        </div>
      </div>
    </div>


<!-- DEBUT NEW TAB -->

    <p-accordion #accordionFilter expandIcon="pi pi-fw pi-chevron-down" collapseIcon="pi pi-fw pi-chevron-up">
      <p-accordionTab [selected]="true">
        <p-header>
          Personne
        </p-header>

        <div class="ui-grid-row">
          <div class="ui-g-2">
            <div class="ui-g-12">
              <input pInputText type="text" [(ngModel)]="filtreEchange.personne_sujet_nom" #nom="ngModel" name="nom"
              placeholder="Nom" />
            </div>
          </div>
          <div class="ui-g-2">
            <div class="ui-g-12">
              <input pInputText type="text" [(ngModel)]="filtreEchange.personne_sujet_prenom" #prenom="ngModel"
                name="prenom" placeholder="Prénom"/>
            </div>
          </div>
          <div class="ui-g-2">
            <div class="ui-g-12">
              <p-inputMask type="text" [(ngModel)]="filtreEchange.personne_sujet_telephone" mask="99 99 99 99 99"
                #telephone="ngModel" name="personne_sujet_telephone" placeholder="Téléphone"></p-inputMask>
            </div>
          </div>
          <div class="ui-g-2">
            <div class="ui-g-12">
              <p-multiSelect [options]="naturesList" [(ngModel)]="filtreEchange.personne_sujet_nature" dataKey="id_parametre"
                #personne_sujet_nature="ngModel" name="personne_sujet_nature" optionLabel="libelle" [filter]="false"
                defaultLabel="Nature - Tous" selectedItemsLabel="{0} sélectionnés"></p-multiSelect>
            </div>
          </div>
          <div class="ui-g-2">
            <div class="ui-g-12">
              <input pInputText type="text" [(ngModel)]="filtreEchange.personne_sujet_id_philia" name="personne_sujet_id_philia" placeholder="ID Philia"/>
            </div>
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>

    <p-accordion #accordionFilter expandIcon="pi pi-fw pi-chevron-down" collapseIcon="pi pi-fw pi-chevron-up">
      <p-accordionTab>
        <p-header>
          Tiers
        </p-header>

        <div class="ui-grid-row">
          <div class="ui-g-2">
            <div class="ui-g-12">
              <input pInputText type="text" [(ngModel)]="filtreEchange.personne_tiers_nom" #nom="ngModel" name="nom"
              placeholder="Nom" />
            </div>
          </div>
          <div class="ui-g-2">
            <div class="ui-g-12">
              <input pInputText type="text" [(ngModel)]="filtreEchange.personne_tiers_prenom" #prenom="ngModel"
                name="personne_tiers_prenom" placeholder="Prénom"/>
            </div>
          </div>
          <div class="ui-g-2">
            <div class="ui-g-12">
              <p-inputMask type="text" [(ngModel)]="filtreEchange.personne_tiers_telephone" mask="99 99 99 99 99"
                #telephone="ngModel" name="personne_tiers_telephone" placeholder="Téléphone"></p-inputMask>
            </div>
          </div>
          <div class="ui-g-2">
            <div class="ui-g-12">
              <p-multiSelect [options]="naturesTiersConcerneList" [(ngModel)]="filtreEchange.personne_tiers_concerne_nature" dataKey="id_parametre"
                #personne_tiers_concerne_nature="ngModel" name="personne_tiers_concerne_nature" optionLabel="libelle" [filter]="false"
                defaultLabel="Nature - Tous" selectedItemsLabel="{0} sélectionnés"></p-multiSelect>
            </div>
          </div>
          <div class="ui-g-2">
            <div class="ui-g-12">
              <p-multiSelect [options]="liensList" [(ngModel)]="filtreEchange.personne_sujet_lien" dataKey="id_parametre"
                #personne_sujet_lien="ngModel" name="personne_sujet_lien" optionLabel="libelle" [filter]="false"
                defaultLabel="Lien - Tous" selectedItemsLabel="{0} sélectionnés"></p-multiSelect>
            </div>
          </div>
          <div class="ui-g-2">
            <div class="ui-g-12">
              <input pInputText type="text" name="personne_tiers_id_philia"	[(ngModel)]="filtreEchange.personne_tiers_id_philia" placeholder="ID Philia"/>
            </div>
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>

    <p-accordion #accordionFilter expandIcon="pi pi-fw pi-chevron-down" collapseIcon="pi pi-fw pi-chevron-up">
      <p-accordionTab [selected]="true">
        <p-header>
          Échange
        </p-header>

        <div class="ui-grid-row">
          <div class="ui-g-2">
            <div class="ui-g-7">
              <label>Créé entre le</label>
            </div>
            <div class="ui-g-5">
              <p-calendar [(ngModel)]="filtreEchange.created_debut" [dateFormat]="dateFormat" [locale]="locale"
                name="created_debut" baseZIndex="11001"></p-calendar>
            </div>
          </div>
          <div class="ui-g-2">
            <div class="ui-g-7">
              <label>Et le</label>
            </div>
            <div class="ui-g-5">
              <p-calendar [(ngModel)]="filtreEchange.created_fin" [dateFormat]="dateFormat" [locale]="locale"
                name="created_fin" baseZIndex="11001"></p-calendar>
            </div>
          </div>
          <div class="ui-g-2">
            <div class="ui-g-7">
              <label>Utilisateurs inactifs</label>
            </div>
            <div class="ui-g-5">
              <p-inputSwitch [(ngModel)]="utilisateursInactifs" (onChange)="getUtilisateurs()" name="utilisateursInactifs"></p-inputSwitch>
            </div>
          </div>
          <div class="ui-g-2">
            <div *ngIf="!currentUtilisateur.visibilite_main_courante_restreinte" class="ui-g-4">
              <label>Par</label>
            </div>
            <div *ngIf="!currentUtilisateur.visibilite_main_courante_restreinte"  class="ui-g-8">
              <p-multiSelect [options]="utilisateursList" [(ngModel)]="filtreEchange.operateur" #operateur="ngModel"
                name="operateur" optionLabel="libelle" defaultLabel="Tous" dataKey="id_utilisateur"
                defaultLabel="Utilisateur" selectedItemsLabel="{0} sélectionnés">
                <ng-template let-utilisateur pTemplate="item">
                  <span *ngIf="utilisateur.value.disabled" style="font-style: italic;"><i class="pi pi-times" style="color: red;float: left;"></i>{{utilisateur.value.libelle}}</span>
                  <span *ngIf="!utilisateur.value.disabled">{{utilisateur.value.libelle}}</span>
                </ng-template>
              </p-multiSelect>
            </div>
          </div>
          <div class="ui-g-2">
            <div class="ui-g-4 ">
              <label>Statut</label>
            </div>
            <div class="ui-g-8 ">
              <p-multiSelect [options]="statutsList" [(ngModel)]="filtreEchange.statut_echange" #statut_echange="ngModel"
                name="statut_echange" optionLabel="libelle" [filter]="false" defaultLabel="Tous" dataKey="id_parametre"
                selectedItemsLabel="{0} sélectionnés"></p-multiSelect>
            </div>
          </div>
          <div class="ui-g-2">
            <div class="ui-g-4">
              <label>Priorité</label>
            </div>
            <div class="ui-g-8">
              <p-multiSelect [options]="prioriteList" [(ngModel)]="filtreEchange.priorite" #priorite="ngModel"
                name="priorite" [filter]="false" defaultLabel="Tous" selectedItemsLabel="{0} sélectionnés"></p-multiSelect>
            </div>
          </div>
          <div class="ui-g-2">
            <div class="ui-g-4">
              <label>Astreinte</label>
            </div>
            <div class="ui-g-8">
              <p-multiSelect [options]="astreinteList" [(ngModel)]="filtreEchange.astreinte" #astreinte="ngModel"
                             name="astreinte" [filter]="false" defaultLabel="Tous" selectedItemsLabel="{0} sélectionnés"></p-multiSelect>
            </div>
          </div>
        </div>
        <div class="ui-grid-row">
          <div class="ui-g-2">
            <div class="ui-g-4">
              <label>Type</label>
            </div>
            <div *ngIf="!currentUtilisateur.isPersonia" class="ui-g-8">
              <p-multiSelect [options]="typesList" [(ngModel)]="filtreEchange.type_echange" #type_echange="ngModel"
                             name="type_echange" optionLabel="libelle" [filter]="false" defaultLabel="Tous" dataKey="id_parametre"
                             selectedItemsLabel="{0} sélectionnés"></p-multiSelect>
            </div>
            <div *ngIf="currentUtilisateur.isPersonia" class="ui-g-8">
              <label>Personia</label>
            </div>
          </div>
          <div class="ui-g-2">
            <div class="ui-g-12 ui-xl-4">
              <label>Catégorie</label>
            </div>
            <div class="ui-g-12 ui-xl-8">
              <p-multiSelect [options]="categoriesList" [(ngModel)]="filtreEchange.categorie_echange" dataKey="id_parametre"
                #categorie_echange="ngModel" name="categorie_echange" optionLabel="libelle" [filter]="false"
                defaultLabel="Tous" selectedItemsLabel="{0} sélectionnés" (onChange)="onChangeCategorie()"></p-multiSelect>
            </div>
          </div>
          <div class="ui-g-4">
            <div class="ui-g-12 ui-xl-2">
              <label>Motif</label>
            </div>
            <div class="ui-g-12 ui-xl-10">
              <p-multiSelect [options]="tousMotifsList" [(ngModel)]="filtreEchange.motif_echange" #motif_echange="ngModel"
                name="motif_echange" optionLabel="libelle" emptyFilterMessage="Aucun résultat trouvé" defaultLabel="Tous"
                selectedItemsLabel="{0} sélectionnés" dataKey="id_parametre"></p-multiSelect>
            </div>
          </div>
          <div class="ui-g-4">
            <div class="ui-g-12 ui-xl-2">
                <label>Sujet</label>
            </div>
            <div class="ui-g-12 ui-xl-10">
                <input pInputText type="text" [(ngModel)]="filtreEchange.objet_echange" #nom="ngModel" name="objet_echange" />
            </div>
          </div>
          <div class="ui-g-2" *ngIf="filtreEchange.federation && filtreEchange.federation.length > 0">
            <div class="ui-g-12 ui-xl-4">
              <label>Marqueurs</label>
            </div>
            <div class="ui-g-12 ui-xl-8">
              <p-multiSelect [options]="marqueursList" [(ngModel)]="filtreEchange.marqueur" name="marqueurs"
                            optionLabel="libelle" [filter]="false">
                  <ng-template let-value pTemplate="selectedItems">
                      <div *ngFor="let option of filtreEchange.marqueur" class="ui-multiselected-item-token ui-corner-all" style="float:left;margin: 1px;">
                          <i [class]="option.marqueur.libelle + ' ' + option.marqueur.code_parametre"></i>
                      </div>
                      <div *ngIf="!filtreEchange.marqueur || filtreEchange.marqueur.length === 0"
                          class="marqueurs-placeholder">
                          Sélectionner vos marqueurs
                      </div>
                  </ng-template>
                  <ng-template let-mark pTemplate="item">
                      <span><i [class]="mark.value.marqueur.libelle + ' ' + mark.value.marqueur.code_parametre"></i>&nbsp; {{mark.value.libelle_marqueur}}</span>
                  </ng-template>
                </p-multiSelect>
            </div>
          </div>
        </div>
        <div class="ui-grid-row">
          <div class="ui-g-2">
            <div class="ui-g-7">
              <label>Modifié entre le</label>
            </div>
            <div class="ui-g-5">
              <p-calendar [(ngModel)]="filtreEchange.modified_debut" [dateFormat]="dateFormat" [locale]="locale"
                          name="modified_debut" baseZIndex="11001"></p-calendar>
            </div>
          </div>
          <div class="ui-g-2">
            <div class="ui-g-7">
              <label>Et le</label>
            </div>
            <div class="ui-g-5">
              <p-calendar [(ngModel)]="filtreEchange.modified_fin" [dateFormat]="dateFormat" [locale]="locale"
                          name="modified_fin" baseZIndex="11001"></p-calendar>
            </div>
          </div>
        </div>
        <div *ngIf="showFiltresRI" class="ui-grid-row">
          <div class="ui-g-3">
            <div class="ui-g-12 ui-xl-4">
              <label>Risque salarié</label>
            </div>
            <div class="ui-g-12 ui-xl-8">
              <p-multiSelect [options]="risquesSalarieList" [(ngModel)]="filtreEchange.risques_salarie"
                #risques_salarie="ngModel" name="risques_salarie" optionLabel="libelle" [filter]="false"
                defaultLabel="Tous" selectedItemsLabel="{0} sélectionnés" dataKey="id_parametre"></p-multiSelect>
            </div>
          </div>
          <div class="ui-g-3">
            <div class="ui-g-12 ui-xl-4">
              <label>Risque client</label>
            </div>
            <div class="ui-g-12 ui-xl-8">
              <p-multiSelect [options]="risquesClientList" [(ngModel)]="filtreEchange.risques_client"
                #risques_client="ngModel" name="risques_client" optionLabel="libelle" [filter]="false"
                defaultLabel="Tous" selectedItemsLabel="{0} sélectionnés" dataKey="id_parametre"></p-multiSelect>
            </div>
          </div>
          <div class="ui-g-3">
            <div class="ui-g-12 ui-xl-4">
              <label>Fréquence</label>
            </div>
            <div class="ui-g-12 ui-xl-8">
              <p-multiSelect [options]="frequencesList" [(ngModel)]="filtreEchange.frequence"
                #frequence="ngModel" name="frequence" optionLabel="libelle" [filter]="false"
                defaultLabel="Tous" selectedItemsLabel="{0} sélectionnés" dataKey="id_parametre"></p-multiSelect>
            </div>
          </div>
          <div class="ui-g-3">
            <div class="ui-g-12 ui-xl-4">
              <label>Gravité</label>
            </div>
            <div class="ui-g-12 ui-xl-8">
              <p-multiSelect [options]="gravitesList" [(ngModel)]="filtreEchange.gravite"
                #gravite="ngModel" name="gravite" optionLabel="libelle" [filter]="false"
                defaultLabel="Tous" selectedItemsLabel="{0} sélectionnés" dataKey="id_parametre"></p-multiSelect>
            </div>
          </div>
        </div>
        <div *ngIf="showFiltresDevis" class="ui-grid-row">
          <div class="ui-g-3">
            <div class="ui-g-12 ui-xl-4">
              <label>Services</label>
            </div>
            <div class="ui-g-12 ui-xl-8">
              <p-multiSelect [options]="servicesList" [(ngModel)]="filtreEchange.services"
                #services="ngModel" name="services" optionLabel="libelle" [filter]="false"
                defaultLabel="Tous" selectedItemsLabel="{0} sélectionnés" dataKey="id_parametre"></p-multiSelect>
            </div>
          </div>
          <div class="ui-g-3">
            <div class="ui-g-12 ui-xl-4">
              <label>Finalité</label>
            </div>
            <div class="ui-g-12 ui-xl-8">
              <p-multiSelect [options]="finalitesList" [(ngModel)]="filtreEchange.finalite_echange"
                #finalite_echange="ngModel" name="finalite_echange" optionLabel="libelle" [filter]="false"
                defaultLabel="Tous" selectedItemsLabel="{0} sélectionnés" dataKey="id_parametre"></p-multiSelect>
            </div>
          </div>
          <div class="ui-g-3">
            <div class="ui-g-12 ui-xl-4">
              <label>Organisme financeur</label>
            </div>
            <div class="ui-g-12 ui-xl-8">
              <p-dropdown [options]="organismesFinanciersList" [(ngModel)]="filtreEchange.organisme_financier"
                #organisme_financier="ngModel" name="organisme_financier" optionLabel="libelle" [filter]="false"
                defaultLabel="Tous" selectedItemsLabel="{0} sélectionnés" (onChange)="getTypesAides()" dataKey="id_parametre">
              </p-dropdown>
            </div>
          </div>
          <div class="ui-g-3">
            <div class="ui-g-12 ui-xl-4">
              <label>Type d'aide</label>
            </div>
            <div class="ui-g-12 ui-xl-8">
              <p-multiSelect [options]="typesAidesList" [(ngModel)]="filtreEchange.type_aide"
                #type_aide="ngModel" name="type_aide" optionLabel="libelle" [filter]="false"
                defaultLabel="Tous" selectedItemsLabel="{0} sélectionnés" dataKey="id_parametre"></p-multiSelect>
            </div>
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>

    <p-accordion #accordionFilter expandIcon="pi pi-fw pi-chevron-down" collapseIcon="pi pi-fw pi-chevron-up">
      <p-accordionTab>
        <p-header>
          Action
        </p-header>

        <div class="ui-grid-row">
          <div class="ui-g-3">
            <div class="ui-g-7">
              <label>Échéance entre le</label>
            </div>
            <div class="ui-g-5">
              <p-calendar [(ngModel)]="filtreEchange.closed_debut" [dateFormat]="dateFormat" [locale]="locale"
                name="closed_debut" baseZIndex="11001"></p-calendar>
            </div>
          </div>
          <div class="ui-g-3">
            <div class="ui-g-7">
              <label>Et le</label>
            </div>
            <div class="ui-g-5">
              <p-calendar [(ngModel)]="filtreEchange.closed_fin" [dateFormat]="dateFormat" [locale]="locale"
                name="closed_fin" baseZIndex="11001"></p-calendar>
            </div>
          </div>
          <div class="ui-g-3">
            <div class="ui-g-6">
              <label>Statut Échéance</label>
            </div>
            <div class="ui-g-6">
              <p-multiSelect [options]="echeanceList" [(ngModel)]="filtreEchange.statut_echeance" #statut_echeance="ngModel"
                             name="statut_echeance" [filter]="false" defaultLabel="Tous" selectedItemsLabel="{0} sélectionnés"></p-multiSelect>
            </div>
          </div>
          <div class="ui-g-3">
            <div class="ui-g-4">
                <label>Objet</label>
            </div>
            <div class="ui-g-8">
                <input pInputText type="text" [(ngModel)]="filtreEchange.objet_action" #nom="ngModel" name="objet_action" />
            </div>
          </div>
        </div>
        <div class="ui-grid-row">
          <div class="ui-g-4">
            <div class="ui-g-2">
              <label>G. Obs.</label>
            </div>
            <div class="ui-g-10">
              <p-multiSelect [options]="groupesList" [(ngModel)]="filtreEchange.groupes"
                             #groupes="ngModel" name="groupes" optionLabel="libelle" dataKey="id_groupe"
                             emptyFilterMessage="Aucun résultat trouvé" defaultLabel="Groupe observateur - Tous" selectedItemsLabel="{0} sélectionnés"></p-multiSelect>
            </div>
          </div>
          <div *ngIf="!currentUtilisateur.visibilite_main_courante_restreinte" class="ui-g-2">
            <div class="ui-g-4">
              <label>Acteur</label>
            </div>
            <div class="ui-g-8">
              <p-multiSelect [options]="utilisateursList" [(ngModel)]="filtreEchange.responsable" #responsable="ngModel" dataKey="id_utilisateur"
                             name="responsable" optionLabel="libelle" defaultLabel="Tous" selectedItemsLabel="{0} sélectionnés">
                <ng-template let-utilisateur pTemplate="item">
                  <span *ngIf="utilisateur.value.disabled" style="font-style: italic;"><i class="pi pi-times" style="color: red;float: left;"></i>{{utilisateur.value.libelle}}</span>
                  <span *ngIf="!utilisateur.value.disabled">{{utilisateur.value.libelle}}</span>
                </ng-template>
              </p-multiSelect>
            </div>
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>


<!-- FIN NEW TAB -->

    <div class="ui-grid-row p-dir-rev">
      <div class="ui-grid-col-2">
        <button pButton type="submit" class="green-button button-with-icon"><span
            class="ui-button-text-with-icon ui-clickable"><i class="fa fa-search"></i> Rechercher</span></button>
      </div>
      <!--
            <div class="p-col filtre-actions">
                <a (click)="initFilter()">Réinitialiser les filtres</a> | <a (click)="toggleShowFilter()">Masquer les critères</a>
            </div>
            -->
    </div>

  </div>

</form>
