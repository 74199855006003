import {FormControl, FormGroup} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {EvaluationContenu} from './evaluation-contenu';
import {ParametreRisque} from './parametre-risque';


export class Risque {

  id_risque: number;

  type_risque: number;

  element: number;

  niveau_de_risque: string;

  details_risque: string;

  mesure_prevention: string;

  @Type(() => EvaluationContenu)
  evaluation_contenu: EvaluationContenu;

  is_edited = false;

  @Type(() => ParametreRisque)
  parametre_risque: ParametreRisque;

  static getForm(risque = null): FormGroup {
    if (!risque) {
      risque = new Risque();
    }

    return new FormGroup({
      'id_risque': new FormControl(risque.id_risque),
      'type_risque': new FormControl(risque.type_risque),
      'element': new FormControl(risque.element),
      'niveau_de_risque': new FormControl(risque.niveau_de_risque),
      'details_risque': new FormControl(risque.details_risque),
      'mesure_prevention': new FormControl(risque.mesure_prevention),
      'is_edited': new FormControl(risque.is_edited),
      'parametre_risque': new FormControl(risque.parametre_risque),
    });
  }

  serialize() {
    let serializeObject = classToPlain(this);

    if (this.parametre_risque !== null && this.parametre_risque !== undefined) {
      serializeObject['parametre_risque'] = this.parametre_risque.id_parametre_risque;
    }

    return serializeObject;
  }
}
