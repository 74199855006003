import {FormControl, FormGroup, Validators} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {Habitude} from './habitude';
import {Personne} from './personne';

export class ContactAvecEntourage {

  id_contact_avec_entourage: number;

  @Type(() => Personne)
  personne: Personne;

  frequence: number;

  unite: string;

  non_regulier = false;

  @Type(() => Habitude)
  habitude: Habitude;

  commentaire: string;

  static getForm(contact_avec_entourage = null): FormGroup {
    if (!contact_avec_entourage) {
      contact_avec_entourage = new ContactAvecEntourage();
    }

    let form = new FormGroup({
      'id_contact_avec_entourage': new FormControl(contact_avec_entourage.id_contact_avec_entourage),
      'personne': new FormControl(contact_avec_entourage.personne, Validators.required),
      'frequence': new FormControl(contact_avec_entourage.frequence),
      'unite': new FormControl(contact_avec_entourage.unite),
      'habitude': new FormControl(contact_avec_entourage.habitude),
      'commentaire': new FormControl(contact_avec_entourage.commentaire),
      'non_regulier': new FormControl(contact_avec_entourage.non_regulier),
    });

    if (!contact_avec_entourage.non_regulier) {
      form.get('frequence').setValidators(Validators.required);
      form.get('unite').setValidators(Validators.required);
    }

    return form;
  }

  serialize() {
    return classToPlain(this);
  }
}
