import {classToPlain} from 'class-transformer';

import {Association, Federation, Parametre, Utilisateur} from '../_models';

export class FiltreAppreciation {
  federation: Federation;
  association: Association;
  created_debut: Date;
  created_fin: Date;
  valeur_min: number;
  valeur_max: number;
  client_nom: string;
  client_prenom: string;
  client_id_philia: string;
  offset: number;
  limit: number;
  sort_property: string;
  sort_direction: string;

  constructor() {
    this.init();
  }

  static getSortProperty(property: string) {
    return property;
  }

  static getSortOrder(sortOrder: number) {
    return sortOrder === 1 ? 'ASC' : 'DESC';
  }

  init() {
      this.federation = null;
      this.association = null;
      this.created_debut = null;
      this.created_fin = null;
      this.valeur_min = null;
      this.valeur_max = null;
      this.client_nom = '';
      this.client_prenom = '';
      this.client_id_philia = '';
      this.offset = 0;
      this.limit = 20;
      this.sort_property = 'date_creation';
      this.sort_direction = 'DESC';
  }

  setDatas(datas) {
    if (datas.federation) {
      this.federation = datas.federation;
    }
    if (datas.association) {
      this.association = datas.association;
    }
    if (datas.created_debut) {
      this.created_debut = datas.created_debut;
    }
    if (datas.created_fin) {
      this.created_fin = datas.created_fin;
    }
    if (datas.valeur_min) {
      this.valeur_min = datas.valeur_min;
    }
    if (datas.valeur_max) {
      this.valeur_max = datas.valeur_max;
    }
    if (datas.client_nom) {
      this.client_nom = datas.client_nom;
    }
    if (datas.client_prenom) {
      this.client_prenom = datas.client_prenom;
    }
    if (datas.id_philia) {
      this.client_id_philia = datas.id_philia;
    }
  }

  serialize() {
    let serializeObject = classToPlain(this);

    // On enleve toutes les proprietes null ou undefined
    for (let propertyName in serializeObject) {
      if (!serializeObject[propertyName]) {
        delete serializeObject[propertyName];
      }
    }

    if (this.federation !== null && this.federation !== undefined) {
        serializeObject['federation'] = serializeObject['federation'].id_federation;
    }

    if (this.association !== null && this.association !== undefined) {
        serializeObject['association'] = serializeObject['association'].id_association;
    }

    if (this.created_debut !== null && this.created_debut !== undefined) {
        serializeObject['created_debut'] = Math.floor(serializeObject['created_debut'].getTime() / 1000);
    }

    if (this.created_fin !== null && this.created_fin !== undefined) {
        serializeObject['created_fin'] = Math.floor(serializeObject['created_fin'].getTime() / 1000);
    }

    return serializeObject;
  }
}
