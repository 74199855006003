import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import {plainToClass} from 'class-transformer';

import {SelectItem} from 'primeng/api';

import { Groupe } from '../_models';

import { environment } from '../../../environments/environment';
import { FiltreGroupe } from '../utils/filtre-groupe';

@Injectable({ providedIn: 'root' })
export class GroupeService {

  filtreGroupe = new FiltreGroupe();

  constructor(private http: HttpClient) { }

  /** GET groupes */
  getAll(restricted_datas = false): Observable<Groupe[]> {
    if (restricted_datas) {
      let params = new HttpParams();
      params = params.append('restricted_datas', 'true');
      return this.http.get<Groupe[]>(`${environment.apiHost}/${environment.apiNamespace}/groupes`, { params: params });
    } else {
      return this.http.get<Groupe[]>(`${environment.apiHost}/${environment.apiNamespace}/groupes`);
    }
  }

  /** GET groupes */
  getGroupesByParams(paramsValue: any, restricted_datas = false, show_groupe_utilisateurs = false, show_only_enabled_group= false): Observable<Groupe[]> {
    let params = new HttpParams();
    for (let paramKey in paramsValue) {
      if (paramsValue.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValue[paramKey]);
      }
    }
    if (restricted_datas) {
      params = params.append('restricted_datas', 'true');
    }
    if (show_groupe_utilisateurs) {
      params = params.append('show_groupe_utilisateurs', 'true');
    }
    if (show_only_enabled_group) {
      params = params.append('show_only_enabled_group', 'true');
    }
    return this.http.get<Groupe[]>(`${environment.apiHost}/${environment.apiNamespace}/groupes`, { params: params });
  }

  /** GET groupes/count */
  getNbGroupesByParams(paramsValue: FiltreGroupe): Observable<any> {
    let paramsValueSerialized = paramsValue.serialize();
    let params = new HttpParams();
    for (let paramKey in paramsValueSerialized) {
      if (paramsValueSerialized.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValueSerialized[paramKey]);
      }
    }
    return this.http.get<any>(`${environment.apiHost}/${environment.apiNamespace}/groupes/count`, { params: params });
  }

   /** GET groupes/:id */
   getGroupe(id_groupe: number): Observable<Groupe> {
    return this.http.get<Groupe>(`${environment.apiHost}/${environment.apiNamespace}/groupes/${id_groupe}`);
  }

  /** POST groupes */
  createGroupe(groupe: Groupe): Observable<Groupe> {
    return this.http.post<any>(`${environment.apiHost}/${environment.apiNamespace}/groupes`,
    plainToClass(Groupe, groupe).serialize());
  }

  /** PUT groupes */
  updateGroupe(groupe: Groupe): Observable<Groupe> {
    let id_groupe = groupe.id_groupe;
    return this.http.put<any>(`${environment.apiHost}/${environment.apiNamespace}/groupes/${id_groupe}`,
    plainToClass(Groupe, groupe).serialize());
  }

  /** DELETE groupes */
  removeGroupe(id_groupe: number): Observable<Groupe> {
    return this.http.delete<any>(`${environment.apiHost}/${environment.apiNamespace}/groupes/${id_groupe}`);
  }

  initFiltreGroupe() {
    this.filtreGroupe = new FiltreGroupe();
  }

  /* handleError */
  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(error);
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a utilisateur-facing error message
    return throwError(
      error);
  }

}
