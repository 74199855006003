<p-toast [style]="{marginTop: '80px'}"></p-toast>

<p-menubar *ngIf="showMenu" [model]="menuItems" [style]="{ backgroundColor: 'rgb(248, 248, 248)'}"></p-menubar>

<div *ngIf="isRouteAdminHome" class="div-home-content">
  <p>Veuillez sélectionner une catégorie de donnée pour en administrer les données</p>
</div>

<div id="admin-content">
  <router-outlet></router-outlet>
</div>
