import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import {plainToClass} from 'class-transformer';
import {SelectItem} from 'primeng/api';

import { Echange } from '../_models';
import { FiltreEchange } from '../utils/filtre-echange';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class EchangeService {

  filtreEchange = new FiltreEchange();
  echangesList: Echange[]; // Le derniers resultat de la recherche des echanges

  constructor(private http: HttpClient) { }

  /** GET echanges */
  getAll(): Observable<Echange[]> {
    return this.http.get<Echange[]>(`${environment.apiHost}/${environment.apiNamespace}/echanges`);
  }

  /** GET echanges/:id */
  getEchange(id_echange: number): Observable<Echange> {
    return this.http.get<Echange>(`${environment.apiHost}/${environment.apiNamespace}/echanges/${id_echange}`);
  }

  /** GET echanges */
  getEchangesByParams(paramsValue: FiltreEchange, updateFiltre = true): Observable<Echange[]> {
    if (updateFiltre) {
      this.filtreEchange = paramsValue;
    }

    let paramsValueSerialized = paramsValue.serialize();
    let params = new HttpParams();
    for (let paramKey in paramsValueSerialized) {
      if (paramsValueSerialized.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValueSerialized[paramKey]);
      }
    }
    return this.http.get<Echange[]>(`${environment.apiHost}/${environment.apiNamespace}/echanges`, { params: params });
  }

  /** GET echanges/count */
  getNbEchangesByParams(paramsValue: FiltreEchange): Observable<any> {
    let paramsValueSerialized = paramsValue.serialize();
    let params = new HttpParams();
    for (let paramKey in paramsValueSerialized) {
      if (paramsValueSerialized.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValueSerialized[paramKey]);
      }
    }
    return this.http.get<any>(`${environment.apiHost}/${environment.apiNamespace}/echanges/count`, { params: params });
  }

  /** POST echanges */
  createEchange(echange: Echange): Observable<Echange> {
    return this.http.post<Echange>(
      `${environment.apiHost}/${environment.apiNamespace}/echanges`,
      plainToClass(Echange, echange).serialize()
     );
  }

  /** PUT echanges */
  updateEchange(echange: Echange): Observable<Echange> {
    return this.http.put<Echange>(
      `${environment.apiHost}/${environment.apiNamespace}/echanges/${echange.id_echange}`,
      plainToClass(Echange, echange).serialize()
    );
  }

  /** PATCH echanges */
  patchEchange(params, id_echange): Observable<Echange> {
    return this.http.patch<Echange>(
      `${environment.apiHost}/${environment.apiNamespace}/echanges/${id_echange}`,
      params
    );
  }

  initFiltreEchange() {
    this.filtreEchange = new FiltreEchange();
  }

  /* handleError */
  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(error);
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a utilisateur-facing error message
    return throwError(
      error);
  }

}
