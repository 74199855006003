<div id="panel-liste-appreciations" class="ui-grid-row panel-header-category-transparent" style="margin-top:10px;">
  <div class="ui-g-12 no-padding-left">
    <h2 style="float: left;">
      <i class="zmdi zmdi-view-list"></i>
      Liste des appréciations (page {{currentPage}} / {{nbTotalPages}}) <button #btnExport type="button" pButton
        icon="pi pi-file" class="grey-button" style="margin-left: 20px;" label="Exporter"
        (click)="menuExport.toggle($event)"></button>
    </h2>

    <p-slideMenu #menuExport [model]="exportItems" [popup]="true" [viewportHeight]="150" backLabel="Retour"
      appendTo="body"></p-slideMenu>

    <!--<span style="float:right;">Nombre d'éléments {{firstElementNumber}} - {{lastElementNumber}} sur {{currentPage ? nbTotalAppreciations : 0}}</span>-->
  </div>
</div>
<p-table #dt class="table table-hover table-condensed" [columns]="cols" [value]="appreciationsList" dataKey="id_appreciation"
  autoLayout="true" [lazy]="true" [lazyLoadOnInit]="false" (onLazyLoad)="loadAppreciationsLazy($event)"
  [rows]="filtreAppreciation.limit" [totalRecords]="nbTotalAppreciations" [loading]="loading" [paginator]="true"
  [rowsPerPageOptions]="rowsPerPageOptions" paginatorDropdownAppendTo="body" (sortFunction)="customSort($event)"
  [customSort]="true" stateStorage="session" stateKey="state-appreciationsList"
  [selectionMode]="'single'">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field"
        [pSortableColumnDisabled]="getSortableColumnDisabled(col.field)">
        <span>{{col.header}}</span>
        <p-sortIcon *ngIf="!getSortableColumnDisabled(col.field)" [field]="col.field"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
    <tr [class]="getClassTrAppreciation(rowData)" [pSelectableRow]="rowData" [pContextMenuRow]="rowData"
      (click)="editAppreciation(rowData['alerte'], $event)">
      <td *ngFor="let col of columns" [class]="getClassTdAppreciation(col.field)">
        <span *ngIf="col.field ==='client_nom'" [pTooltip]="getTooltip(col.field, rowData)"
          tooltipStyleClass="tooltip-utilisateurLibelle">{{getRowLabel(col.field, rowData)}}</span>
        <span
          *ngIf="col.field ==='date_creation' && getRowLabel('date_creation', rowData).getTime()">
          {{getRowLabel('date_creation', rowData) | date: datePipeFormat}}
        </span>
        <span *ngIf="col.field ==='valeur'">{{getRowLabel(col.field, rowData)}}</span>
        <span *ngIf="col.field ==='association'">{{getRowLabel(col.field, rowData)}}</span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary">
    Nombre d'éléments {{firstElementNumber}} - {{lastElementNumber}} sur {{currentPage ? nbTotalAppreciations : 0}} <i
      class="pi pi-refresh" style="margin-left:10px; cursor:pointer;" (click)="refresh()"></i>
  </ng-template>
</p-table>
