import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-documentation-gestion',
  templateUrl: './documentation-gestion.component.html',
  styleUrls: ['./documentation-gestion.component.css']
})
export class DocumentationGestionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
