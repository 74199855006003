import {FormControl, FormGroup} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {EvaluationContenu} from './evaluation-contenu';


export class AccompagnementParentalite {

  id_accompagnement_parentalite: number;

  @Type(() => EvaluationContenu)
  evaluation_contenu: EvaluationContenu;

  conseil_achat: boolean;

  courses: boolean;

  preparation_accompagnement_ecole: boolean;

  gestion_budget_courant: boolean;

  organisation_vie_gestion_temps: boolean;

  info_cplt_vie_quotidienne: string;

  aide_maintien_developpement_equilibre: boolean;

  aide_mobilisation_potentialite: boolean;

  info_cplt_ecoute_aide_relationelle: boolean;

  preparation_arrive_bebe: boolean;

  soins_enfant: boolean;

  hygiene: boolean;

  activite_eveil: boolean;

  rythme_enfant: boolean;

  education_alimentaire: boolean;

  aide_scolarite: boolean;

  prevention_accident_domestique: boolean;

  fixer_limite_enfant: boolean;

  apprentissage_enfant_limite: boolean;

  gerer_conflit_enfant: boolean;

  favoriser_dialogue_enfant: boolean;

  reconstituer_equilibre_apres_evenement: boolean;

  suppleance_parent: boolean;

  info_cplt_soutient_parentalite: string;

  demarche_administrative_emploi: boolean;

  connaissance_structure_aide: boolean;

  insertion_environnement: boolean;

  relation_ecole: boolean;

  acces_adroit: boolean;

  info_cplt_vie_sociale: string;

  static getForm(accompagnement_parentalite = null): FormGroup {
    if (!accompagnement_parentalite) {
      accompagnement_parentalite = new AccompagnementParentalite();
    }

    return new FormGroup({
      'id_accompagnement_parentalite': new FormControl(accompagnement_parentalite.id_accompagnement_parentalite),
      'conseil_achat': new FormControl(accompagnement_parentalite.conseil_achat),
      'courses': new FormControl(accompagnement_parentalite.courses),
      'preparation_accompagnement_ecole': new FormControl(accompagnement_parentalite.preparation_accompagnement_ecole),
      'gestion_budget_courant': new FormControl(accompagnement_parentalite.gestion_budget_courant),
      'organisation_vie_gestion_temps': new FormControl(accompagnement_parentalite.organisation_vie_gestion_temps),
      'info_cplt_vie_quotidienne': new FormControl(accompagnement_parentalite.info_cplt_vie_quotidienne),
      'aide_maintien_developpement_equilibre': new FormControl(accompagnement_parentalite.aide_maintien_developpement_equilibre),
      'aide_mobilisation_potentialite': new FormControl(accompagnement_parentalite.aide_mobilisation_potentialite),
      'info_cplt_ecoute_aide_relationelle': new FormControl(accompagnement_parentalite.info_cplt_ecoute_aide_relationelle),
      'preparation_arrive_bebe': new FormControl(accompagnement_parentalite.preparation_arrive_bebe),
      'soins_enfant': new FormControl(accompagnement_parentalite.soins_enfant),
      'hygiene': new FormControl(accompagnement_parentalite.hygiene),
      'activite_eveil': new FormControl(accompagnement_parentalite.activite_eveil),
      'rythme_enfant': new FormControl(accompagnement_parentalite.rythme_enfant),
      'education_alimentaire': new FormControl(accompagnement_parentalite.education_alimentaire),
      'aide_scolarite': new FormControl(accompagnement_parentalite.aide_scolarite),
      'prevention_accident_domestique': new FormControl(accompagnement_parentalite.prevention_accident_domestique),
      'fixer_limite_enfant': new FormControl(accompagnement_parentalite.fixer_limite_enfant),
      'apprentissage_enfant_limite': new FormControl(accompagnement_parentalite.apprentissage_enfant_limite),
      'gerer_conflit_enfant': new FormControl(accompagnement_parentalite.gerer_conflit_enfant),
      'favoriser_dialogue_enfant': new FormControl(accompagnement_parentalite.favoriser_dialogue_enfant),
      'reconstituer_equilibre_apres_evenement': new FormControl(accompagnement_parentalite.reconstituer_equilibre_apres_evenement),
      'suppleance_parent': new FormControl(accompagnement_parentalite.suppleance_parent),
      'info_cplt_soutient_parentalite': new FormControl(accompagnement_parentalite.info_cplt_soutient_parentalite),
      'demarche_administrative_emploi': new FormControl(accompagnement_parentalite.demarche_administrative_emploi),
      'connaissance_structure_aide': new FormControl(accompagnement_parentalite.connaissance_structure_aide),
      'insertion_environnement': new FormControl(accompagnement_parentalite.insertion_environnement),
      'relation_ecole': new FormControl(accompagnement_parentalite.relation_ecole),
      'acces_adroit': new FormControl(accompagnement_parentalite.acces_adroit),
      'info_cplt_vie_sociale': new FormControl(accompagnement_parentalite.info_cplt_vie_sociale),
    });
  }

  serialize() {
    return classToPlain(this);
  }
}
