import {Directive} from '@angular/core';
import {FormGroup, NG_VALIDATORS, ValidationErrors, ValidatorFn} from '@angular/forms';
import {plainToClass} from 'class-transformer';
import {Parametre} from '../_models/parametre';

export const alerteValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {

  let valid = true;
  let errors = null;

  if (control && control.get('statut_alerte') && control.get('statut_alerte').value) {
    const statut_alerte = plainToClass(Parametre, control.get('statut_alerte').value as Object);
    const actions = control.get('actions');

    // Si le statut de l'alerte est fermé et qu'il y a au moins une action ouverte => Invalide
    if (statut_alerte.code_parametre === 'F' && actions.value.length > 0) {
      actions.value.forEach(function (action) {
        if (action.statut_action.code_parametre === 'E') {
          valid = false;
          errors = {actionOpen: true};
        }
      });
    }
  }

  return valid ? null : errors;
};

@Directive({
  selector: '[appAlerteValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: AlerteValidatorDirective, multi: true}]
})
export class AlerteValidatorDirective {

  constructor() {
  }

  public validate(control: FormGroup): { [key: string]: any } {
    return alerteValidator(control);
  }

}
