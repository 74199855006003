import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'routerOutletContainerClass'
})
export class RouterOutletContainerClassPipe implements PipeTransform {

  transform(url: string): unknown {
    const containerClass = 'p-col-12 p-xl-10';

    if (url.includes('/in/echange') || url === '/in/recherche' || url.includes('/in/recherche?') || url === '/in/recherche-action' || url.includes('/in/recherche-action?')) {
      return `${containerClass} echange`;
    } else if (url.includes('/in/evaluation') || url === '/in/recherche-evaluation' || url.includes('/in/recherche-evaluation?')) {
      return `${containerClass} evaluation`;
    } else if (url.includes('/in/alerte') || url === '/in/recherche-alerte' || url.includes('/in/recherche-alerte?')) {
      return `${containerClass} alerte`;
    } else if (url.includes('/in/appreciation') || url === '/in/recherche-appreciation' || url.includes('/in/recherche-appreciation?')) {
      return `${containerClass} appreciation`;
    }

    return containerClass;
  }

}
