import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import * as uuid from 'uuid';
import {emailValidator} from '../_directives/email-validator.directive';
import {personneValidator} from '../_directives/personne-validator.directive';
import {phoneValidator} from '../_directives/phone-validator.directive';
import {Globals} from '../utils/globals';
import {AlimentationEnfant} from './alimentation-enfant';
import {AllaitementEnfant} from './allaitement-enfant';
import {Autonomie} from './autonomie';
import {CentreInteretEnfant} from './centre-interet-enfant';
import {EvaluationContenu} from './evaluation-contenu';
import {HygieneEnfant} from './hygiene-enfant';
import {NourrissonEnfant} from './nourrisson-enfant';
import {OrganismeFinancier} from './organisme-financier';
import {Parametre} from './parametre';
import {RepasEnfant} from './repas-enfant';
import {RituelEnfant} from './rituel-enfant';
import {SanteEnfant} from './sante-enfant';
import {SocialEnfant} from './social-enfant';
import {SommeilEnfant} from './sommeil-enfant';


export class Personne {
  id_personne: number;

  id_philia: number;

  @Type(() => Parametre)
  civilite: Parametre;

  nom: string;
  prenom: string;

  @Type(() => Date)
  date_de_naissance: Date;

  tel_fixe: string;
  tel_mobile: string;
  email: string;
  numero_rue: string;
  rue: string;
  ville: string;
  cp: string;
  cplt_adresse_1: string;
  cplt_adresse_2: string;
  cplt_adresse_3: string;
  pays: string;

  @Type(() => Parametre)
  type_complement_adresse: Parametre;

  commentaire: string;
  aidant = false;
  vivant_au_foyer: boolean;
  vit_seul: boolean;
  a_contacter_urgence = false;
  personne_confiance = false;
  autorisation_acces_donnees = false;
  representant_legal = false;
  present_visite: boolean;

  @Type(() => Date)
  date_jugement: Date;

  libelle: string;

  @Type(() => Parametre)
  lien_specialite: Parametre;

  formulaire = false;

  @Type(() => Parametre)
  type_representant: Parametre;

  copie_jugement = false;
  client = false;
  tiers = false;
  entourage_personnel = false;
  entourage_professionnel = false;
  dependant = false;

  nom_jeune_fille: string;
  enfant = false;

  @Type(() => Personne)
  parent_1: Personne;

  @Type(() => Personne)
  parent_2: Personne;

  @Type(() => Personne)
  urgence_1: Personne;

  @Type(() => Personne)
  urgence_2: Personne;

  age: number;

  unite_age: string;

  @Type(() => Personne)
  entourage_facturation: Personne;

  @Type(() => OrganismeFinancier)
  organismes_financiers: OrganismeFinancier[] = [];

  @Type(() => EvaluationContenu)
  evaluation_contenu: EvaluationContenu;

  @Type(() => SocialEnfant)
  social_enfant: SocialEnfant;

  @Type(() => SanteEnfant)
  sante_enfant: SanteEnfant;

  @Type(() => SommeilEnfant)
  sommeil_enfant: SommeilEnfant;

  @Type(() => HygieneEnfant)
  hygiene_enfant: HygieneEnfant;

  @Type(() => RituelEnfant)
  rituel_enfant: RituelEnfant;

  @Type(() => CentreInteretEnfant)
  centre_interet_enfant: CentreInteretEnfant;

  @Type(() => AllaitementEnfant)
  allaitement_enfant: AllaitementEnfant;

  @Type(() => AlimentationEnfant)
  alimentation_enfant: AlimentationEnfant;

  @Type(() => NourrissonEnfant)
  nourrisson_enfant: NourrissonEnfant;

  @Type(() => RepasEnfant)
  repas_enfant: RepasEnfant;

  @Type(() => Autonomie)
  autonomies: Autonomie[] = [];

  nombre_frequence: number;

  @Type(() => Parametre)
  frequence: Parametre;

  uuid: string;

  commentaire_coordonnees: string;

  constructor() {
    if (this.id_personne) {
      this.uuid = this.id_personne.toString();
    } else if (!this.uuid) {
      this.uuid = uuid.v4();
    }
  }

  get prenom_nom() {
    if (!this.prenom) {
      return this.nom;
    }

    return this.prenom + ' ' + this.nom;
  }

  get nom_prenom() {
    if (!this.prenom) {
      return this.nom;
    }

    return this.nom + ' ' + this.prenom;
  }

  get civilite_nom_prenom() {
    let civilite_nom_prenom = '';

    if (this.civilite) {
      civilite_nom_prenom = this.civilite.libelle;
    }

    if (civilite_nom_prenom !== '') {
      civilite_nom_prenom += ' ';
    }

    if (this.nom) {
      if (civilite_nom_prenom !== '') {
        civilite_nom_prenom += ' ';
      }
      civilite_nom_prenom += this.nom;
    }

    if (this.prenom) {
      if (civilite_nom_prenom !== '') {
        civilite_nom_prenom += ' ';
      }
      civilite_nom_prenom += this.prenom;
    }

    return civilite_nom_prenom;
  }

  get civilite_nom_prenom_mail() {
    let civilite_nom_prenom_mail = this.civilite_nom_prenom;

    if (this.email) {
      civilite_nom_prenom_mail += ' - ' + this.email;
    }

    return civilite_nom_prenom_mail;
  }

  get telephone() {
    let telephone = '';

    if (this.tel_fixe) {
      telephone = this.tel_fixe;
    }
    if (this.tel_fixe && this.tel_mobile) {
      telephone += ' / ';
    }
    if (this.tel_mobile) {
      telephone += this.tel_mobile;
    }

    return telephone;
  }

  static getForm(personne = null): FormGroup {
    if (!personne) {
      personne = new Personne();
    }

    let organismesFinanciersForm = [];
    personne.organismes_financiers.forEach(function (organisme_financier) {
      organismesFinanciersForm.push(OrganismeFinancier.getForm(organisme_financier));
    });

    let autonomiesForm = [];
    personne.autonomies.forEach(function (autonomie) {
      autonomiesForm.push(Autonomie.getForm(autonomie));
    });

    let form = new FormGroup({
      'id_personne': new FormControl(personne.id_personne),
      'uuid': new FormControl(personne.uuid),
      'id_philia': new FormControl(personne.id_philia),
      'civilite': new FormControl(personne.civilite),
      'nom': new FormControl(personne.nom),
      'prenom': new FormControl(personne.prenom),
      'date_de_naissance': new FormControl(personne.date_de_naissance),
      'tel_fixe': new FormControl(personne.tel_fixe, phoneValidator()),
      'tel_mobile': new FormControl(personne.tel_mobile, phoneValidator()),
      'email': new FormControl(personne.email, emailValidator()),
      'numero_rue': new FormControl(personne.numero_rue),
      'rue': new FormControl(personne.rue),
      'ville': new FormControl(personne.ville),
      'cp': new FormControl(personne.cp),
      'cplt_adresse_1': new FormControl(personne.cplt_adresse_1),
      'cplt_adresse_2': new FormControl(personne.cplt_adresse_2),
      'cplt_adresse_3': new FormControl(personne.cplt_adresse_3),
      'pays': new FormControl(personne.pays),
      'type_complement_adresse': new FormControl(personne.type_complement_adresse),
      'commentaire': new FormControl(personne.commentaire),
      'aidant': new FormControl(personne.aidant),
      'vivant_au_foyer': new FormControl(personne.vivant_au_foyer),
      'vit_seul': new FormControl(personne.vit_seul),
      'a_contacter_urgence': new FormControl(personne.a_contacter_urgence),
      'personne_confiance': new FormControl(personne.personne_confiance),
      'autorisation_acces_donnees': new FormControl(personne.autorisation_acces_donnees),
      'representant_legal': new FormControl(personne.representant_legal),
      'present_visite': new FormControl(personne.present_visite),
      'date_jugement': new FormControl(personne.date_jugement),
      'libelle': new FormControl(personne.libelle),
      'lien_specialite': new FormControl(personne.lien_specialite),
      'formulaire': new FormControl(personne.formulaire),
      'type_representant': new FormControl(personne.type_representant),
      'copie_jugement': new FormControl(personne.copie_jugement),
      'client': new FormControl(personne.client),
      'tiers': new FormControl(personne.tiers),
      'entourage_personnel': new FormControl(personne.entourage_personnel),
      'entourage_professionnel': new FormControl(personne.entourage_professionnel),
      'dependant': new FormControl(personne.dependant),
      'nom_jeune_fille': new FormControl(personne.nom_jeune_fille),
      'enfant': new FormControl(personne.enfant),
      'parent_1': new FormControl(personne.parent_1),
      'parent_2': new FormControl(personne.parent_2),
      'urgence_1': new FormControl(personne.urgence_1),
      'urgence_2': new FormControl(personne.urgence_2),
      'age': new FormControl(personne.age),
      'unite_age': new FormControl(personne.unite_age),
      'entourage_facturation': new FormControl(personne.entourage_facturation),
      'organismes_financiers': new FormArray(organismesFinanciersForm),
      'social_enfant': SocialEnfant.getForm(personne.social_enfant),
      'sante_enfant': SanteEnfant.getForm(personne.sante_enfant),
      'sommeil_enfant': SommeilEnfant.getForm(personne.sommeil_enfant),
      'hygiene_enfant': HygieneEnfant.getForm(personne.hygiene_enfant),
      'rituel_enfant': RituelEnfant.getForm(personne.rituel_enfant),
      'centre_interet_enfant': CentreInteretEnfant.getForm(personne.centre_interet_enfant),
      'allaitement_enfant': AllaitementEnfant.getForm(personne.allaitement_enfant),
      'nourrisson_enfant': NourrissonEnfant.getForm(personne.nourrisson_enfant),
      'repas_enfant': RepasEnfant.getForm(personne.repas_enfant),
      'autonomies': new FormArray(autonomiesForm),
      'nombre_frequence': new FormControl(personne.nombre_frequence),
      'frequence': new FormControl(personne.frequence),
      'commentaire_coordonnees': new FormControl(personne.commentaire_coordonnees),
    }, {validators: personneValidator});

    if (personne.entourage_personnel || personne.entourage_professionnel) {
      form.get('nom').setValidators(Validators.required);
      form.get('lien_specialite').setValidators(Validators.required);
    }

    if (personne.enfant) {
      form.get('nom').setValidators(Validators.required);
      form.get('prenom').setValidators(Validators.required);
      form.get('date_de_naissance').setValidators(Validators.required);
    }

    form.updateValueAndValidity();

    return form;
  }

  hasHabitudeEnfant() {
    if (this.hasSanteEnfant()
      || this.hasSommeilEnfant()
      || this.hasHygieneEnfant()
      || this.hasRituelEnfant()
      || this.hasCentreInteretOrInfosInteret()) {
      return true;
    }
    return false;
  }

  hasSanteEnfant() {
    if (this.sante_enfant
      && (this.sante_enfant.traitement
        || this.sante_enfant.ordonnance
        || this.sante_enfant.emplacement
        || this.sante_enfant.info)) {
      return true;
    }
    return false;
  }

  hasSommeilEnfant() {
    if (this.sommeil_enfant
      && (this.sommeil_enfant.reveil
        || this.sommeil_enfant.fermer
        || this.sommeil_enfant.coucher
        || this.sommeil_enfant.tenue
        || this.sommeil_enfant.siestes
        || this.sommeil_enfant.info)) {
      return true;
    }
    return false;
  }

  hasHygieneEnfant() {
    if (this.hygiene_enfant
      && (this.hygiene_enfant.heure
        || this.hygiene_enfant.duree
        || this.hygiene_enfant.produits
        || this.hygiene_enfant.info)) {
      return true;
    }
    return false;
  }

  hasRituelEnfant() {
    if (this.rituel_enfant
      && (this.rituel_enfant.doudou
        || this.rituel_enfant.tetine
        || this.rituel_enfant.portage
        || this.rituel_enfant.info)) {
      return true;
    }
    return false;
  }

  hasCentreInteret() {
    if (this.centre_interet_enfant
      && (this.centre_interet_enfant.activite_eveil_interet
        || this.centre_interet_enfant.activite_manuelle_interet
        || this.centre_interet_enfant.dessin_peinture_interet
        || this.centre_interet_enfant.histoire_interet
        || this.centre_interet_enfant.jeux_eau_interet
        || this.centre_interet_enfant.jeux_eveil_interet
        || this.centre_interet_enfant.jeux_construction_interet
        || this.centre_interet_enfant.jeux_moteur_interet
        || this.centre_interet_enfant.jeux_symbolique_interet
        || this.centre_interet_enfant.jeux_plein_air_interet
        || this.centre_interet_enfant.livre_interet
        || this.centre_interet_enfant.musique_interet
        || this.centre_interet_enfant.pate_modeler_interet
        || this.centre_interet_enfant.promenade_interet
        || this.centre_interet_enfant.sortie_interet
        || this.centre_interet_enfant.restriction_tv)) {
      return true;
    }
    return false;
  }

  hasCentreInteretOrInfosInteret() {
    if (this.centre_interet_enfant
      && (this.hasCentreInteret()
        || this.centre_interet_enfant.info)) {
      return true;
    }
    return false;
  }

  hasSocialEnfant() {
    if (this.social_enfant
      && ((this.social_enfant.activites_extra_scolaire_enfant && this.social_enfant.activites_extra_scolaire_enfant.length > 0)
        || this.social_enfant.informations_complementaires)) {
      return true;
    }
    return false;
  }

  hasAlimentationEnfant() {
    if (this.hasAllaitementEnfant()
      || this.hasNourrissonEnfant()
      || this.hasRepasEnfant()
      || this.hasHorairesRepasEnfant()
      || (this.repas_enfant && this.repas_enfant.repas_degout)
      || (this.repas_enfant && this.repas_enfant.repas_usages)) {
      return true;
    }
    return false;
  }

  hasAllaitementEnfant() {
    if (this.allaitement_enfant && this.allaitement_enfant.allaitement_detail_organisation) {
      return true;
    }
    return false;
  }

  hasNourrissonEnfant() {
    if (this.nourrisson_enfant
      && (this.nourrisson_enfant.nourrisson_preparation_biberon
        || this.nourrisson_enfant.nourrisson_aide_prise_biberon
        || this.nourrisson_enfant.nourrisson_temperature_biberon
        || this.nourrisson_enfant.nourrisson_hygiene_biberon
        || this.nourrisson_enfant.nourrisson_eau_biberon)) {
      return true;
    }
    return false;
  }

  hasRepasEnfant() {
    if (this.repas_enfant
      && (this.repas_enfant.repas_petits_pots
        || this.repas_enfant.repas_enfant_mixe
        || this.repas_enfant.repas_puree_maison
        || this.repas_enfant.repas_petit_morceaux
        || this.repas_enfant.repas_preparation)) {
      return true;
    }
    return false;
  }

  hasHorairesRepasEnfant() {
    if (this.repas_enfant
      && (this.repas_enfant.repas_horaire_petit_dej
        || this.repas_enfant.repas_horaire_dej
        || this.repas_enfant.repas_horaire_gouter
        || this.repas_enfant.repas_horaire_diner
        || this.repas_enfant.repas_aide_prise
        || this.repas_enfant.repas_detail)) {
      return true;
    }
    return false;
  }

  serialize() {
    let serializeObject = classToPlain(this);
    // delete serializeObject['id_personne'];

    if (this.civilite !== null && this.civilite !== undefined) {
      serializeObject['civilite'] = this.civilite.id_parametre;
    }

    if (this.type_complement_adresse !== null && this.type_complement_adresse !== undefined) {
      serializeObject['type_complement_adresse'] = this.type_complement_adresse.id_parametre;
    }

    if (this.lien_specialite !== null && this.lien_specialite !== undefined) {
      serializeObject['lien_specialite'] = this.lien_specialite.id_parametre;
    }

    if (this.type_representant !== null && this.type_representant !== undefined) {
      serializeObject['type_representant'] = this.type_representant.id_parametre;
    }

    serializeObject['organismes_financiers'] = [];
    this.organismes_financiers.forEach(function (organismes_financier) {
      serializeObject['organismes_financiers'].push(organismes_financier.serialize());
    });

    if (this.social_enfant !== null && this.social_enfant !== undefined) {
      serializeObject['social_enfant'] = this.social_enfant.serialize();
    }

    if (this.sante_enfant !== null && this.sante_enfant !== undefined) {
      serializeObject['sante_enfant'] = this.sante_enfant.serialize();
    }

    if (this.sommeil_enfant !== null && this.sommeil_enfant !== undefined) {
      serializeObject['sommeil_enfant'] = this.sommeil_enfant.serialize();
    }

    if (this.hygiene_enfant !== null && this.hygiene_enfant !== undefined) {
      serializeObject['hygiene_enfant'] = this.hygiene_enfant.serialize();
    }

    if (this.rituel_enfant !== null && this.rituel_enfant !== undefined) {
      serializeObject['rituel_enfant'] = this.rituel_enfant.serialize();
    }

    if (this.centre_interet_enfant !== null && this.centre_interet_enfant !== undefined) {
      serializeObject['centre_interet_enfant'] = this.centre_interet_enfant.serialize();
    }

    if (this.allaitement_enfant !== null && this.allaitement_enfant !== undefined) {
      serializeObject['allaitement_enfant'] = this.allaitement_enfant.serialize();
    }

    if (this.nourrisson_enfant !== null && this.nourrisson_enfant !== undefined) {
      serializeObject['nourrisson_enfant'] = this.nourrisson_enfant.serialize();
    }

    if (this.repas_enfant !== null && this.repas_enfant !== undefined) {
      serializeObject['repas_enfant'] = this.repas_enfant.serialize();
    }

    serializeObject['autonomies'] = [];
    this.autonomies.forEach(function (autonomie) {
      serializeObject['autonomies'].push(autonomie.serialize());
    });

    if (this.frequence !== null && this.frequence !== undefined) {
      serializeObject['frequence'] = this.frequence.id_parametre;
    }

    if (this.parent_1 !== null && this.parent_1 !== undefined) {
      serializeObject['parent_1'] = this.parent_1.serialize();
    }

    if (this.parent_2 !== null && this.parent_2 !== undefined) {
      serializeObject['parent_2'] = this.parent_2.serialize();
    }

    if (this.urgence_1 !== null && this.urgence_1 !== undefined) {
      serializeObject['urgence_1'] = this.urgence_1.serialize();
    }

    if (this.urgence_2 !== null && this.urgence_2 !== undefined) {
      serializeObject['urgence_2'] = this.urgence_2.serialize();
    }

    // Conversion des dates en FR
    for (let propertyName in serializeObject) { // On parcourt les proprietes de l'objet
      if (serializeObject[propertyName] && serializeObject[propertyName] instanceof Date) { // Si c'est une date non null
        serializeObject[propertyName] = Globals.getDateWithTimezone(serializeObject[propertyName]);
      }
    }

    return serializeObject;
  }

}
