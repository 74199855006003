import {classToPlain, Type} from 'class-transformer';
import {Globals} from '../utils/globals';
import {Disponibilites} from './disponibilites';
import {Parametre} from './parametre';

export class Creneau {

  id_creneau: number;

  @Type(() => Disponibilites)
  disponibilites: Disponibilites;

  @Type(() => Parametre)
  jour: Parametre;

  @Type(() => Parametre)
  periode: Parametre;


  serialize() {
    let serializeObject = classToPlain(this);

    if (this.jour !== null && this.jour !== undefined) {
      serializeObject['jour'] = this.jour.id_parametre;
    }

    if (this.periode !== null && this.periode !== undefined) {
      serializeObject['periode'] = this.periode.id_parametre;
    }

    // Conversion des dates en FR
    for (let propertyName in serializeObject) { // On parcourt les proprietes de l'objet
      if (serializeObject[propertyName] && serializeObject[propertyName] instanceof Date) { // Si c'est une date non null
        serializeObject[propertyName] = Globals.getDateWithTimezone(serializeObject[propertyName]);
      }
    }

    return serializeObject;
  }
}
