import {FormControl, FormGroup} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {Globals} from '../utils/globals';
import {Alerte} from './alerte';
import {Association} from './association';
import {Federation} from './federation';
import {Parametre} from './parametre';

export class Appreciation {
  id_appreciation: number;

  id_appreciation_philia: number;

  valeur: number;

  @Type(() => Date)
  date_creation: Date = new Date();

  @Type(() => Date)
  created: Date = new Date();

  @Type(() => Parametre)
  client_civilite: Parametre;

  client_nom: string;

  client_prenom: string;

  client_id_philia: string;

  @Type(() => Federation)
  federation: Federation;

  @Type(() => Association)
  association: Association;

  @Type(() => Parametre)
  intervenant_civilite: Parametre;

  intervenant_nom: string;

  intervenant_prenom: string;

  intervenant_id_philia: string;

  statut_verification: number;

  @Type(() => Date)
  date_verification: Date;

  commentaire: string;

  id_rapport_intervention: number;

  @Type(() => Alerte)
  alerte: Alerte;

  static getForm(appreciation = null): FormGroup {
    if (!appreciation) {
      appreciation = new Appreciation();
    }

    return new FormGroup({
      'id_appreciation': new FormControl(appreciation.id_appreciation),
      'id_appreciation_philia': new FormControl(appreciation.id_appreciation_philia),
      'valeur': new FormControl(appreciation.valeur),
      'date_creation': new FormControl(appreciation.date_creation),
      'created': new FormControl(appreciation.created),
      'client_civilite': new FormControl(appreciation.client_civilite),
      'client_nom': new FormControl(appreciation.client_nom),
      'client_prenom': new FormControl(appreciation.client_prenom),
      'client_id_philia': new FormControl(appreciation.client_id_philia),
      'federation': new FormControl(appreciation.federation),
      'association': new FormControl(appreciation.association),
      'intervenant_civilite': new FormControl(appreciation.intervenant_civilite),
      'intervenant_nom': new FormControl(appreciation.intervenant_nom),
      'intervenant_prenom': new FormControl(appreciation.intervenant_prenom),
      'intervenant_id_philia': new FormControl(appreciation.intervenant_id_philia),
      'statut_verification': new FormControl(appreciation.statut_verification),
      'date_verification': new FormControl(appreciation.date_verification),
      'commentaire': new FormControl(appreciation.commentaire),
      'id_rapport_intervention': new FormControl(appreciation.id_rapport_intervention),
      'alerte': new FormControl(appreciation.alerte)
    });
  }

  serialize() {
    let serializeObject = classToPlain(this);
    delete serializeObject['id_appreciation'];
    delete serializeObject['created'];
    delete serializeObject['updated'];

    if (this.client_civilite !== null && this.client_civilite !== undefined) {
      serializeObject['client_civilite'] = this.client_civilite.id_parametre;
    }

    if (this.federation !== null && this.federation !== undefined) {
      serializeObject['federation'] = this.federation.id_federation;
    }

    if (this.association !== null && this.association !== undefined) {
      serializeObject['association'] = this.association.id_association;
    }

    if (this.intervenant_civilite !== null && this.intervenant_civilite !== undefined) {
      serializeObject['intervenant_civilite'] = this.intervenant_civilite.id_parametre;
    }

    if (this.alerte !== null && this.alerte !== undefined) {
      serializeObject['alerte'] = this.alerte.id_alerte;
    }

    // Conversion des dates en FR
    for (let propertyName in serializeObject) { // On parcourt les proprietes de l'objet
      if (serializeObject[propertyName] && serializeObject[propertyName] instanceof Date) { // Si c'est une date non null
        serializeObject[propertyName] = Globals.getDateWithTimezone(serializeObject[propertyName]);
      }
    }

    return serializeObject;
  }

  serializeForExport() {
    let serializeObject = {
      'Date création': this.date_creation ? Globals.formatDate(this.date_creation, true) : '',
      'Nom Individu': this.client_nom ? this.client_nom : '',
      'Prénom Individu': this.client_prenom ? this.client_prenom : '',
      'Id Philia Individu': this.client_id_philia ? this.client_id_philia : '',
      'Association': this.association ? this.association.libelle : '',
      'Fédération': this.federation ? this.federation.libelle : '',
      'Niveau': (this.valeur !== null && this.valeur !== undefined) ? this.valeur : '',
      'Nom Intervenant': this.intervenant_nom ? this.intervenant_nom : '',
      'Prénom Intervenant': this.intervenant_prenom ? this.intervenant_prenom : '',
      'Commentaire': this.commentaire ? this.commentaire : '',
    };

    return serializeObject;
  }

}
