import {FormControl, FormGroup} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {EvaluationContenu} from './evaluation-contenu';
import {Parametre} from './parametre';
import {Personne} from './personne';


export class Autonomie {

  id_autonomie: number;

  @Type(() => Parametre)
  domaine: Parametre;

  @Type(() => Parametre)
  types: Parametre[] = [];

  precaution: string;

  @Type(() => EvaluationContenu)
  evaluation_contenu: EvaluationContenu;

  @Type(() => Personne)
  enfant: Personne;

  get typesLabel() {
    let typesLabel = '';
    this.types.forEach(function (type) {
      if (typesLabel !== '') {
        typesLabel += ', ';
      }
      typesLabel += type.libelle;
    });
    return typesLabel;
  }

  static getForm(autonomie = null): FormGroup {
    if (!autonomie) {
      autonomie = new Autonomie();
    }

    return new FormGroup({
      'id_autonomie': new FormControl(autonomie.id_autonomie),
      'domaine': new FormControl(autonomie.domaine),
      'types': new FormControl(autonomie.types ? autonomie.types : []),
      'precaution': new FormControl(autonomie.precaution),
      'enfant': new FormControl(autonomie.enfant),
    });
  }

  serialize() {
    let serializeObject = classToPlain(this);

    if (this.domaine !== null && this.domaine !== undefined) {
      serializeObject['domaine'] = this.domaine.id_parametre;
    }

    serializeObject['types'] = [];
    this.types.forEach(function (type) {
      serializeObject['types'].push(type.id_parametre);
    });

    if (this.enfant !== null && this.enfant !== undefined) {
      serializeObject['enfant'] = this.enfant.serialize();
    }

    return serializeObject;
  }
}
