import {FormControl, FormGroup} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {Personne} from './personne';


export class NourrissonEnfant {

  id_nourrisson_enfant: number;

  nourrisson_preparation_biberon = false;

  nourrisson_hygiene_biberon: string;

  nourrisson_aide_prise_biberon = false;

  nourrisson_temperature_biberon: string;

  nourrisson_eau_biberon: string;

  @Type(() => Personne)
  enfant: Personne;

  static getForm(nourrisson_enfant = null): FormGroup {
    if (!nourrisson_enfant) {
      nourrisson_enfant = new NourrissonEnfant();
    }

    return new FormGroup({
      'id_nourrisson_enfant': new FormControl(nourrisson_enfant.id_nourrisson_enfant),
      'nourrisson_preparation_biberon': new FormControl(nourrisson_enfant.nourrisson_preparation_biberon),
      'nourrisson_hygiene_biberon': new FormControl(nourrisson_enfant.nourrisson_hygiene_biberon),
      'nourrisson_aide_prise_biberon': new FormControl(nourrisson_enfant.nourrisson_aide_prise_biberon),
      'nourrisson_temperature_biberon': new FormControl(nourrisson_enfant.nourrisson_temperature_biberon),
      'nourrisson_eau_biberon': new FormControl(nourrisson_enfant.nourrisson_eau_biberon),
      'enfant': new FormControl(nourrisson_enfant.enfant),
    });
  }

  serialize() {
    return classToPlain(this);
  }
}
