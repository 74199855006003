<form (ngSubmit)="onSubmit()" #filtreForm="ngForm">
    <div class="ui-grid ui-grid-responsive ui-grid-pad ui-fluid" style="margin: 10px 0px">

        <div class="ui-grid-row">
            <div class="ui-g-6">
              <div class="ui-g-2">
                  <label>Fédération</label>
              </div>
              <div class="ui-g-10">
                  <p-dropdown [options]="federationsList" [(ngModel)]="filtreAction.federation" [readonly]="isFederationReadOnly()" [showClear]="!isFederationReadOnly()"
                              [style]="{'width':'100%'}" [placeholder]="'Fédération...'" (onChange)="onChangeFederation()" #federation="ngModel" name="federation"
                              optionLabel="libelle" dataKey="id_federation" filter="true" emptyFilterMessage="Aucun résultat trouvé"></p-dropdown>
              </div>
            </div>
            <div class="ui-g-6">
              <div class="ui-g-2">
                  <label>Association</label>
              </div>
              <div class="ui-g-10">
                  <p-dropdown [options]="associationsItemsList" [(ngModel)]="filtreAction.association" [readonly]="isAssociationReadOnly()" [showClear]="!isAssociationReadOnly()"
                              [style]="{'width':'100%'}" [placeholder]="'Association'" #association="ngModel" name="association" dataKey="id_association"
                              filter="true" emptyFilterMessage="Aucun résultat trouvé">
                      <ng-template pTemplate="selectedItem">
                        <div *ngIf="filtreAction.association">
                          <span *ngIf="filtreAction.association.disabled" style="font-style: italic;"><i class="pi pi-times" style="color: red;float: left;"></i>{{filtreAction.association.libelle}}</span>
                          <span *ngIf="!filtreAction.association.disabled">{{filtreAction.association.libelle}}</span>
                        </div>
                      </ng-template>
                      <ng-template let-association pTemplate="item">
                        <span *ngIf="association.value.disabled" style="font-style: italic;"><i class="pi pi-times" style="color: red;float: left;"></i>{{association.value.libelle}}</span>
                        <span *ngIf="!association.value.disabled">{{association.value.libelle}}</span>
                      </ng-template>
                  </p-dropdown>
              </div>
            </div>
        </div>

        <div class="ui-grid-row">
            <div class="ui-g-2">
                <div class="ui-g-8">
                    <label>Date de l'action</label>
                </div>
            </div>
            <div class="ui-g-2">
                <div class="ui-g-4">
                    <label>Début</label>
                </div>
                <div class="ui-g-8">
                    <p-calendar [(ngModel)]="filtreAction.created_debut" [dateFormat]="dateFormat" [locale]="locale" name="created_debut"></p-calendar>
                </div>
            </div>
            <div class="ui-g-2">
                <div class="ui-g-4">
                    <label>Fin</label>
                </div>
                <div class="ui-g-8">
                    <p-calendar [(ngModel)]="filtreAction.created_fin" [dateFormat]="dateFormat" [locale]="locale" name="created_fin"></p-calendar>
                </div>
            </div>
            <div class="ui-g-2">
                <div class="ui-g-8">
                    <label>Date d'échéance</label>
                </div>
            </div>
            <div class="ui-g-2">
                <div class="ui-g-4">
                    <label>Début</label>
                </div>
                <div class="ui-g-8">
                    <p-calendar [(ngModel)]="filtreAction.closed_debut" [dateFormat]="dateFormat" [locale]="locale" name="closed_debut"></p-calendar>
                </div>
            </div>
            <div class="ui-g-2">
                <div class="ui-g-4">
                    <label>Fin</label>
                </div>
                <div class="ui-g-8">
                    <p-calendar [(ngModel)]="filtreAction.closed_fin" [dateFormat]="dateFormat" [locale]="locale" name="closed_fin"></p-calendar>
                </div>
            </div>
            <div class="ui-g-3">
              <div class="ui-g-6">
                <label>Statut Échéance</label>
              </div>
              <div class="ui-g-6">
                <p-multiSelect [options]="echeanceList" [(ngModel)]="filtreAction.statut_echeance" #statut_echeance="ngModel"
                               name="statut_echeance" [filter]="false" defaultLabel="Tous" selectedItemsLabel="{0} sélectionnés"></p-multiSelect>
              </div>
            </div>
        </div>

        <div class="ui-grid-row">
            <div class="ui-g-3">
                <div class="ui-g-4">
                    <label>Statut Action</label>
                </div>
                <div class="ui-g-8">
                    <p-multiSelect [options]="statutsList" [(ngModel)]="filtreAction.statut_action" dataKey="id_parametre" #statut_action="ngModel"
                    name="statut_action" optionLabel="libelle" [filter]="false" defaultLabel="Tous" selectedItemsLabel="{0} sélectionnés"></p-multiSelect>
                </div>
            </div>
            <div class="ui-g-3">
                <div class="ui-g-4">
                    <label>Catégorie</label>
                </div>
                <div class="ui-g-8">
                    <p-multiSelect [options]="categoriesList" [(ngModel)]="filtreAction.categorie_echange" dataKey="id_parametre" #categorie_echange="ngModel"
                     name="categorie_echange" optionLabel="libelle" [filter]="false" defaultLabel="Tous" selectedItemsLabel="{0} sélectionnés"></p-multiSelect>
                </div>
            </div>
            <div class="ui-g-3">
                <div class="ui-g-4">
                    <label>Motif</label>
                </div>
                <div class="ui-g-8">
                    <p-multiSelect [options]="motifsList" [(ngModel)]="filtreAction.motif_echange" dataKey="id_parametre"
                    #motif_echange="ngModel" name="motif_echange" optionLabel="libelle" [filter]="false" defaultLabel="Tous" selectedItemsLabel="{0} sélectionnés"></p-multiSelect>
                </div>
            </div>
            <div class="ui-g-3">
              <div class="ui-g-4">
                  <label>G. Obs.</label>
              </div>
              <div class="ui-g-8">
                  <p-multiSelect [options]="groupesList" [(ngModel)]="filtreAction.groupes" dataKey="id_groupe" #groupes="ngModel"
                  name="groupes" optionLabel="libelle" [filter]="false" defaultLabel="Tous" selectedItemsLabel="{0} sélectionnés"></p-multiSelect>
              </div>
          </div>
        </div>

        <div class="ui-grid-row">
          <div class="ui-g-3">
              <div class="ui-g-3">
                  <label>Utilisateur</label>
              </div>
              <div class="ui-g-9">
                  <p-multiSelect [options]="utilisateursList" [(ngModel)]="filtreAction.operateur" dataKey="id_utilisateur" #operateur="ngModel"
                  name="operateur" optionLabel="libelle" defaultLabel="Tous" selectedItemsLabel="{0} sélectionnés"></p-multiSelect>
              </div>
          </div>
          <div class="ui-g-3">
              <div class="ui-g-3">
                  <label>Acteur</label>
              </div>
              <div class="ui-g-9">
                  <p-multiSelect [options]="utilisateursList" [(ngModel)]="filtreAction.responsable" dataKey="id_utilisateur" #responsable="ngModel"
                   name="responsable" optionLabel="libelle" defaultLabel="Tous" selectedItemsLabel="{0} sélectionnés"></p-multiSelect>
              </div>
          </div>
          <div class="ui-g-3">
            <div class="ui-g-3">
                <label>Objet</label>
            </div>
            <div class="ui-g-9">
                <!--<input pInputText type="text" [(ngModel)]="filtreAction.objet_action" #nom="ngModel" name="objet_action" />-->
                <p-autoComplete [(ngModel)]="filtreAction.objet_action" [suggestions]="filteredObjetActionList" (completeMethod)="filterObjetAction($event)" [size]="30"
                  placeholder="Objet" [minLength]="1" name="objet_action"></p-autoComplete>
            </div>
          </div>
          <div class="ui-g-3">
            <div class="ui-g-3">
                <label>Module</label>
            </div>
            <div class="ui-g-9">
                <p-multiSelect [options]="modulesList" [(ngModel)]="filtreAction.module" #module="ngModel" name="model" [filter]="false" defaultLabel="Tous" selectedItemsLabel="{0} sélectionnés"></p-multiSelect>
            </div>
          </div>
        </div>

        <div class="ui-grid-row">
            <div class="ui-g-3">
                <div class="ui-g-3">
                    <label>Nom</label>
                </div>
                <div class="ui-g-9">
                    <input pInputText type="text" [(ngModel)]="filtreAction.personne_sujet_nom" #nom="ngModel" name="nom" />
                </div>
            </div>
            <div class="ui-g-3">
                <div class="ui-g-3">
                    <label>Prénom</label>
                </div>
                <div class="ui-g-9">
                    <input pInputText type="text" [(ngModel)]="filtreAction.personne_sujet_prenom" #prenom="ngModel" name="prenom"/>
                </div>
            </div>
            <div class="ui-g-3">
                <div class="ui-g-3">
                    <label>Téléphone</label>
                </div>
                <div class="ui-g-9">
                    <p-inputMask  type="text" [(ngModel)]="filtreAction.personne_sujet_telephone" mask="99 99 99 99 99" #telephone="ngModel" name="telephone"></p-inputMask>
                </div>
            </div>
        </div>

        <div class="ui-grid-row p-dir-rev">
            <div class="ui-grid-col-2">
              <button pButton type="submit" class="green-button button-with-icon"><span class="ui-button-text-with-icon ui-clickable"><i class="fa fa-search"></i> Rechercher</span></button>
            </div>
            <!--
            <div class="p-col filtre-actions">
                <a (click)="initFilter()">Réinitialiser les filtres</a> | <a (click)="toggleShowFilter()">Masquer les critères</a>
            </div>
            -->
        </div>

    </div>

</form>
