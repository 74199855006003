import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import {plainToClass, classToClass} from 'class-transformer';

import {MessageService} from 'primeng/api';
import {MenuItem} from 'primeng/api';
import {LazyLoadEvent} from 'primeng/api';

import { FiltresActionComponent } from '../filtres-action/filtres-action.component';
import { ActionService } from '../../shared/_services';
import { Action } from '../../shared/_models';
import {Parametre} from '../../shared/_models/parametre';
import {ParametreService} from '../../shared/_services/parametre.service';
import { AuthenticationService } from '../../shared/_services';
import { Utilisateur } from '../../shared/_models';
import {ExportService} from '../../shared/_services';
import {FiltreAction} from '../../shared/utils/filtre-action';

import {Globals} from '../../shared/utils/globals';

@Component({
  selector: 'app-recherche-action',
  templateUrl: './recherche-action.component.html',
  styleUrls: ['./recherche-action.component.css']
})
export class RechercheActionComponent implements OnInit {

  @ViewChild('accordionFilter', { static: true }) accordionFilter;
  @ViewChild('dt', { static: true }) dt; // Composant datatable qui affiche la liste des actions
  @ViewChild(FiltresActionComponent, { static: true }) private filtresActionComponent: FiltresActionComponent; // Composant filtres des actions
  currentUtilisateur: Utilisateur;
  nbTotalActions = 0;
  actionsList: Action[] = [];
  cols: any[];
  filtreAction: FiltreAction;
  loading: boolean;
  rowsPerPageOptions = [20, 50, 100];
  itemsContextMenu: MenuItem[];
  selectedAction: Action;
  statutsEchangeList: Parametre[];
  statutsActionList: Parametre[];
  exportItems: MenuItem[];

  datePipeFormatWithoutTime = Globals.datePipeFormatWithoutTime;
  timeFormat = Globals.timeFormat;

  activeIndexFilter: number;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private actionService: ActionService,
    private parametreService: ParametreService,
    private exportService: ExportService,
    private messageService: MessageService
  ) { }

    ngOnInit() {
      this.authenticationService.currentUtilisateur$.subscribe(utilisateur => {
        if ( utilisateur ) {
          this.currentUtilisateur = this.authenticationService.currentUtilisateurValue;
        }
      });

      this.filtreAction = this.actionService.filtreAction;

      this.getCols();
      this.getStatutsEchange();
      this.getStatutsAction();
      this.getExportItems();
      this.activeIndexFilter = 0;
      this.loading = true;
    }

    getStatutsEchange() {
      this.parametreService.getParametresByParams({type: 'STATUT_E'})
      .subscribe((parametres: Parametre[]) => {
          this.statutsEchangeList = parametres;
      });
    }

    getStatutsAction() {
      this.parametreService.getParametresByParams({type: 'STATUT_A'})
      .subscribe((parametres: Parametre[]) => {
          this.statutsActionList = parametres;
      });
    }

    searchAction(filtreAction) {
      this.loading = true;
      this.actionService.getNbActionsByParams(filtreAction)
      .subscribe((result) => {
        this.nbTotalActions = result.count;
      });

      this.actionService.getActionsByParams(filtreAction)
      .subscribe((actions: Action[]) => {
        this.actionsList = plainToClass(Action, actions);
        this.loading = false;
      });

    }

    loadActionsLazy(event: LazyLoadEvent) {
      // event.first = First row offset
      // event.rows = Number of rows per page
      // event.sortField = Field name to sort with
      // event.sortOrder = Sort order as number, 1 for asc and -1 for dec
      // filters: FilterMetadata object having field as key and filter value, filter matchMode as value
      this.filtreAction.offset = event.first;
      this.filtreAction.limit = event.rows;
      this.filtreAction.sort_property = FiltreAction.getSortProperty(event.sortField);
      this.filtreAction.sort_direction = FiltreAction.getSortOrder(event.sortOrder);

      this.searchAction(this.filtreAction);
  }

  refresh() {
    this.filtresActionComponent.onSubmit();
  }

  getCols(): void {
      this.cols = [
        { field: 'created1', header: 'Date' },
        { field: 'created2', header: 'Heure' },
        { field: 'statut_action', header: 'Statut action' },
        { field: 'redacteur', header: 'Redacteurs' },
        { field: 'personne_sujet_nom', header: 'Usager' },
        { field: 'categorie_echange', header: 'Cat' },
        { field: 'motif_echange', header: 'Motif' },
        { field: 'objet_action', header: 'Objet' },
        { field: 'statut_echeance', header: 'Statut échéance' },
        { field: 'echeance', header: 'Echéance' },
        { field: 'responsable', header: 'Acteur' },
        { field: 'association', header: 'Association' },
    ];
  }

  getClassTrAction(rowData) {
    let classTrAction = 'selectable';

    if (rowData['echange'] && rowData['echange'].priorite === 'U') {
      classTrAction += ' tr-echange-urgent';
    }
    if (rowData['statut_action'].code_parametre === 'F') {
      classTrAction += ' tr-action-closed';
    }
    if (rowData['echeance'] !== null && rowData['echeance'] < Date.now()) {
      classTrAction += ' tr-action-echeancePassed';
    }

    if (rowData['statut_echeance'] !== null && rowData['statut_echeance'] === 'D') {
      classTrAction += ' tr-action-echeancePassed';
    }

    if (rowData['statut_echeance'] !== null && rowData['statut_echeance'] === 'U') {
      classTrAction += ' tr-action-echeanceUrgente';
    }

    return classTrAction;
  }

  getClassTdAction(field) {
    if (field === 'statut_action' || field === 'categorie_echange' || field === 'motif_echange' || field === 'statut_echeance') {
      return 'td-centered';
    }
    return '';
  }

  getRowLabel(field, rowData) {
    if (field === 'statut_action') {
      return rowData['statut_action'] ? rowData['statut_action'].code_parametre : '';
    } else if (field === 'categorie_echange') {
      if (rowData['echange']) {
        return rowData['echange'].categorie_echange ? rowData['echange'].categorie_echange.code_parametre : '';
      } else if (rowData['evaluation_entete']) {
        return rowData['evaluation_entete'].type_public ? rowData['evaluation_entete'].type_public.code_parametre : '';
      } else if (rowData['alerte']) {
        return 'Alerte';
      } else {
        return '';
      }
    } else if (field === 'motif_echange') {
      if (rowData['echange']) {
        return rowData['echange'].motif_echange ? rowData['echange'].motif_echange.code_parametre : '';
      } else if (rowData['evaluation_entete']) {
        return rowData['evaluation_entete'].motif ? rowData['evaluation_entete'].motif.code_parametre : '';
      } else {
        return '';
      }
    } else if (field === 'redacteur' || field === 'responsable') {
      return rowData[field] ? rowData[field].libelle : '';
    } else if (field === 'personne_sujet_nom') {
      let labelSujet = '';
      if (rowData['echange']) {
        if (rowData['echange'].personne_sujet_nom) {
          labelSujet += rowData['echange'].personne_sujet_nom;
        }
        if (rowData['echange'].personne_sujet_prenom) {
          labelSujet += ' ' + rowData['echange'].personne_sujet_prenom;
        }
      } else if (rowData['evaluation_entete']) {
        if (rowData['evaluation_entete'].client && rowData['evaluation_entete'].client.nom) {
          labelSujet += rowData['evaluation_entete'].client.nom;
        }
        if (rowData['evaluation_entete'].client && rowData['evaluation_entete'].client.prenom) {
          labelSujet += ' ' + rowData['evaluation_entete'].client.prenom;
        }
      } else if (rowData['alerte']) {
        if (rowData['alerte'].appreciation && rowData['alerte'].appreciation.client_nom) {
          labelSujet += rowData['alerte'].appreciation.client_nom;
        }
        if (rowData['alerte'].appreciation && rowData['alerte'].appreciation.client_prenom) {
          labelSujet += ' ' + rowData['alerte'].appreciation.client_prenom;
        }
      }
      return labelSujet;
    } else if (field === 'association') {
      if (rowData['echange']) {
        return rowData['echange'].association ? rowData['echange'].association.libelle : '';
      } else if (rowData['evaluation_entete']) {
        return rowData['evaluation_entete'].association ? rowData['evaluation_entete'].association.libelle : '';
      } else if (rowData['alerte']) {
        return rowData['alerte'].association ? rowData['alerte'].association.libelle : '';
      } else {
        return '';
      }
    }

    return rowData[field];
  }

  getTooltip(field, rowData) {
    let tooltipLabel = '';
    if (field === 'personne_sujet_nom') {
      if (rowData['echange']) {
        tooltipLabel = rowData['echange'].objet_echange + '\n';
      } else if (rowData['evaluation_entete']) {
        tooltipLabel = rowData['evaluation_entete'].objet + '\n';
      }

      if (rowData['description_action']) {
        tooltipLabel += rowData['description_action'] + '\n';
      }

      if (rowData['closed']) {
        tooltipLabel += rowData['closed'] + '  /  ';
      }

      if (rowData.isClosed(this.currentUtilisateur) && rowData['utilisateur_fermeture']) {
        tooltipLabel += rowData['utilisateur_fermeture'].libelle;
      }
    } else if (field === 'personne_sujet_nature' && rowData['hasTiers']) {
      if (rowData['personne_tiers_nom']) {
        if (rowData['echange']) {
          tooltipLabel = rowData['personne_tiers_nom'];
        } else if (rowData['evaluation_entete']) {
          tooltipLabel = rowData['evaluation_entete'].contactant ? rowData['evaluation_entete'].contactant.nom + '\n' : '\n';
        }
      }
      if (rowData['personne_tiers_prenom']) {
        if (rowData['echange']) {
          tooltipLabel += ' ' + rowData['personne_tiers_prenom'] + '\n';
        } else if (rowData['evaluation_entete']) {
          tooltipLabel = rowData['evaluation_entete'].contactant ? rowData['evaluation_entete'].contactant.prenom + '\n' : '\n';
        }
      }
    }

    if (tooltipLabel !== null && tooltipLabel !== '') {
      tooltipLabel.substring(0, tooltipLabel.length - 2); // On supprime le dernier retour a la ligne
    }

    return tooltipLabel;
  }

  getSortableColumnDisabled(field) {
    if (field === 'created2' || field === 'statut_action' || field === 'personne_sujet_nom'
    || field === 'objet_action' || field === 'echeance') {
      return true;
    }

    return false;
  }

  customSort(event) {
    this.filtreAction.sort_property = event.field;
    this.filtreAction.sort_direction = event.order;

    this.searchAction(this.filtreAction);
  }

  updateContextMenuAction(event) {
    let selectedAction = event.data;
    let disabledCloseAction = selectedAction.isClosed(this.currentUtilisateur);
    this.itemsContextMenu = [
      { label: 'Fermer l\'action', icon: 'pi pi-times-circle', disabled: disabledCloseAction, command: () => this.closeAction() }
    ];
  }

  closeAction() {
    this.statutsActionList.forEach((statut) => {
      if ( statut.code_parametre === 'F' ) {
          this.selectedAction.statut_action = statut; // On met le statut de l'action a fermé
      }
    });
    // On met à jour l'échange
    this.actionService.updateAction(this.selectedAction)
    .subscribe((actionUpdated: Action) => {
      this.messageService.add({severity: 'success', summary: 'Succès', detail: 'Action fermée avec succès.'});
      this.searchAction(this.filtreAction); // On recharge la liste des actions
    });
  }

  getExportItems() {
    this.exportItems = [
      {
      label: 'Exporter la page',
      icon: 'zmdi zmdi-file',
      items: [
        {label: 'Format Excel', icon: 'zmdi zmdi-file', command: () => {
            this.exportExcel(false);
        }},
        {label: 'Format CSV', icon: 'zmdi zmdi-file-text', command: () => {
            this.exportCsv(false);
        }}
      ]},
      {
        label: 'Exporter toutes les actions',
        icon: 'zmdi zmdi-file-text',
        items: [
          {label: 'Format Excel', icon: 'zmdi zmdi-file', command: () => {
              this.exportExcel(true);
          }},
          {label: 'Format CSV', icon: 'zmdi zmdi-file-text', command: () => {
              this.exportCsv(true);
          }}
        ]}
    ];
  }

  exportExcel(allActions = true) {
    if (allActions) {
      let filtreActionAll = classToClass(this.filtreAction); // On clone le filtre action
      filtreActionAll.limit = null;  // On enleve la limite pour récupérer toutes les actions
      filtreActionAll.offset = null; // On réinitialise l'offset
      this.actionService.getActionsByParams(filtreActionAll)
      .subscribe((actions: Action[]) => {
        this.exportService.exportAsExcelFile(this.getDataForExport(plainToClass(Action, actions)), 'actions');
      });
    } else {
      this.exportService.exportAsExcelFile(this.getDataForExport(), 'actions');
    }
  }

  exportCsv(allActions = true) {
    if (allActions) {
      let filtreActionAll = classToClass(this.filtreAction); // On clone le filtre action
      filtreActionAll.limit = null;  // On enleve la limite pour récupérer toutes les actions
      filtreActionAll.offset = null; // On réinitialise l'offset
      this.actionService.getActionsByParams(filtreActionAll)
      .subscribe((actions: Action[]) => {
        this.exportService.exportAsCsvFile(this.getDataForExport(plainToClass(Action, actions)), 'actions');
      });
    } else {
      this.exportService.exportAsCsvFile(this.getDataForExport(), 'actions');
    }
  }

  getDataForExport(actionsList: Action[] = this.actionsList) {
    let data = [];
    actionsList.forEach(action => {
      data.push(action.serializeForExport());
    });
    return data;
  }

  initFilter() {
    this.filtreAction = new FiltreAction();
    this.filtresActionComponent.initFilter(false);
  }

  toggleShowFilter() {
    if (this.activeIndexFilter === 0) {
      this.activeIndexFilter = -1;
    } else {
      this.activeIndexFilter = 0;
    }
  }

  closeFilters() {
    this.activeIndexFilter = -1;
  }

  goToActionDatas(rowData, event) {
    let isNewTab = false;
    if (event.ctrlKey) { // Si Ctrl + Clic
      isNewTab = true;
    }

    let path = '';
    let action = plainToClass(Action, rowData);

    if (action) {
      if (action.echange && this.currentUtilisateur.acces_echanges) {
        path = '/in/echange/' + action.echange.id_echange;
      }

      if (isNewTab) {
        let url = this.router.createUrlTree([path]);
        window.open(url.toString(), '_blank');
      } else {
        this.router.navigate([path]);
      }
    }
  }

  get toggleShowFilterLabel() {
    if (this.activeIndexFilter === 0) {
      return 'Masquer les critères';
    } else {
      return 'Afficher les critères';
    }
  }

  get currentPage(): number {
    let currentPage = 0;
    if (this.actionsList.length > 0 && this.dt.rows > 0) {
      currentPage = this.dt.first / this.dt.rows + 1;
    }
    return currentPage;
  }

  get nbTotalPages(): number {
    let nbTotalPages = 0;
    if (this.nbTotalActions > 0 && this.actionsList.length > 0 && this.dt.rows > 0) {
      nbTotalPages = Math.ceil(this.nbTotalActions / this.dt.rows); // On arrondi au supérieur
    }
    return nbTotalPages;
  }

  get firstElementNumber() {
    if (!this.nbTotalActions || !this.currentPage) {
      return 0;
    }

    return this.filtreAction.limit * (this.currentPage - 1) + 1;
  }

  get lastElementNumber() {
    if (!this.nbTotalActions || !this.currentPage) {
      return 0;
    }

    let lastElementNumber = this.firstElementNumber + this.filtreAction.limit - 1;

    if (lastElementNumber < this.nbTotalActions) {
      return lastElementNumber;
    }
    return this.nbTotalActions;
  }

}
