<p-accordion #accordionFilter expandIcon="pi pi-fw pi-chevron-down" collapseIcon="pi pi-fw pi-chevron-up"
    [(activeIndex)]="activeIndexFilter">
    <p-accordionTab>
        <p-header>
            <i class="fa fa-search"></i> Paramétrage des marqueurs
        </p-header>
        <div class="ui-grid ui-grid-responsive ui-grid-pad ui-fluid" style="margin: 10px 0px">
            <div class="ui-grid-row">
                <div class="ui-g-7">
                    <div class="ui-g-3">
                        <label>Fédération</label>
                    </div>
                    <div class="ui-g-9">
                        <p-dropdown [options]="federationsList" [style]="{'width':'100%'}" class="app-dropdown"
                            [placeholder]="'Fédération...'" name="federation" optionLabel="libelle" [filter]="true"
                            (onChange)="onChangeFederation()" [(ngModel)]="currentFederation" [disabled]="currentUtilisateur.perimetre != 'National'"></p-dropdown>
                    </div>
                </div>
                <div class="ui-g-5">
                    <div class="ui-g-5">
                      <label>Actifs</label>
                    </div>
                    <div class="ui-g-7">
                      <p-inputSwitch [(ngModel)]="marqueursInactifs" (onChange)="onChangeDisabled()" name="marqueursInactifs"></p-inputSwitch>
                    </div>
                </div>
            </div>
        </div>
    </p-accordionTab>
</p-accordion>
<br />
<p-table [value]="federationsMarqueursList" [paginator]="true" [rows]="10">
    <ng-template pTemplate="header">
        <tr>
            <th scope="col" *ngIf="currentUtilisateur.perimetre == 'National'">FÉDÉRATION</th>
            <th scope="col">ID MARQUEUR</th>
            <th scope="col">CODE MARQUEUR</th>
            <th scope="col">LIBELLÉ MARQUEUR</th>
            <th scope="col">ACTIF</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-federationMarqueur>
        <tr>
            <td *ngIf="currentUtilisateur.perimetre == 'National'">{{federationMarqueur.federation.libelle}}</td>
            <td style="text-align: center;">{{federationMarqueur.id}}</td>
            <td style="text-align: center;"><i
                    [class]="federationMarqueur.marqueur.libelle + ' ' + federationMarqueur.marqueur.code_parametre + ' zmdi-hc-3x'"></i>
            </td>
            <td>
                <input pInputText type="text" [(ngModel)]="federationMarqueur.libelle_marqueur" placeholder="Libellé marqueur à saisir" [readOnly]="!currentFederation" />
            </td>
            <td style="text-align: center;">
                <p-inputSwitch [ngModel]="getSwitchMarqueur(federationMarqueur)" (ngModelChange)="setSwitchMarqueur(federationMarqueur, $event)"></p-inputSwitch>
            </td>
        </tr>
    </ng-template>
</p-table>
<div class="ui-grid ui-grid-responsive ui-grid-pad ui-fluid" style="margin: 10px 0px">
    <div class="ui-grid-row p-dir-rev" *ngIf="selectedFederationMarqueurs != undefined">
        <div class="ui-grid-col-2">
            <button pButton class="green-button button-with-icon" (click)="saveFederationMarqueurs()"><span
                    class="ui-button-text-with-icon ui-clickable"><i class="fa fa-save"></i>
                    Enregistrer</span></button>
        </div>
    </div>
</div>
