import {classToPlain, Type} from 'class-transformer';
import {Globals} from '../utils/globals';
import {Creneau} from './creneau';
import {Utilisateur} from './utilisateur';

export class Disponibilites {

  id_disponibilites: number;

  id_philia: string;

  @Type(() => Date)
  created: Date = new Date();

  @Type(() => Date)
  closed: Date;

  version: number;

  source: string;

  @Type(() => Utilisateur)
  redacteur: Utilisateur;

  disabled: boolean;

  @Type(() => Creneau)
  creneaux: Creneau[] = [];

  get label() {
    let label = '';

    label += this.version + '_' + Globals.formatDate(this.created) + '_' + this.source;

    return label;
  }

  serialize() {
    let serializeObject = classToPlain(this);

    if (this.redacteur !== null && this.redacteur !== undefined) {
      serializeObject['redacteur'] = this.redacteur.id_utilisateur;
    }

    serializeObject['creneaux'] = [];
    this.creneaux.forEach(function (creneau) {
      serializeObject['creneaux'].push(creneau.serialize());
    });

    // Conversion des dates en FR
    for (let propertyName in serializeObject) { // On parcourt les proprietes de l'objet
      if (serializeObject[propertyName] && serializeObject[propertyName] instanceof Date) { // Si c'est une date non null
        serializeObject[propertyName] = Globals.getDateWithTimezone(serializeObject[propertyName]);
      }
    }

    return serializeObject;
  }
}
