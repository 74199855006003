import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import {plainToClass, classToClass} from 'class-transformer';

import {MenuItem} from 'primeng/api';
import {LazyLoadEvent} from 'primeng/api';

import { EvaluationService, ParametreService, AuthenticationService, ExportService } from '../../shared/_services';
import { EvaluationEntete, Parametre, Utilisateur } from '../../shared/_models';

import {Globals} from '../../shared/utils/globals';

import {FiltreEvaluation} from '../utils/filtre-evaluation';
import { environment } from "../../../environments/environment";

@Component({
  selector: 'app-liste-evaluations',
  templateUrl: './liste-evaluations.component.html',
  styleUrls: ['./liste-evaluations.component.css']
})
export class ListeEvaluationsComponent implements OnInit {

  @Input()
  filtreEvaluation: FiltreEvaluation = new FiltreEvaluation(); // propriété d'entrée du composant : le filtre evaluation

  @Input()
  updateFiltre = true; // propriété d'entrée du composant : mise a jour du filtre globale des evaluations

  @Output()
  refreshList = new EventEmitter<any>();

  @ViewChild('dt', { static: true }) dt; // Composant datatable qui affiche la liste des echanges

  currentUtilisateur: Utilisateur;
  nbTotalEvaluations = 0;
  evaluationsEnteteList: EvaluationEntete[] = [];
  cols: any[];
  loading: boolean;
  rowsPerPageOptions = [20, 50, 100];
  itemsContextMenu: MenuItem[];
  selectedEvaluation: EvaluationEntete;
  statutsEvaluationList: Parametre[];
  statutsActionList: Parametre[];
  exportItems: MenuItem[];

  datePipeFormatWithoutTime = Globals.datePipeFormatWithoutTime;
  datePipeFormat = Globals.datePipeFormat;
  timeFormat = Globals.timeFormat;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private evaluationService: EvaluationService,
    private parametreService: ParametreService,
    private exportService: ExportService
    ) { }

    ngOnInit() {
      this.authenticationService.currentUtilisateur$.subscribe(utilisateur => {
        if ( utilisateur ) {
          this.currentUtilisateur = this.authenticationService.currentUtilisateurValue;
        }
      });

      this.getCols();
      this.getExportItems();
    }

    searchEvaluation(filtreEvaluation) {
      this.loading = true;
      this.filtreEvaluation.setDatas(filtreEvaluation);
      this.evaluationService.getNbEvaluationsByParams(filtreEvaluation)
      .subscribe((result) => {
        this.nbTotalEvaluations = result.count;
      });

      this.evaluationService.getEvaluationsByParams(filtreEvaluation, this.updateFiltre)
      .subscribe((evaluations: EvaluationEntete[]) => {
        this.evaluationService.evaluationsEnteteList = plainToClass(EvaluationEntete, evaluations); // On conserve le resultat pour naviguer dans les evaluations sur la page evaluation
        this.evaluationsEnteteList = plainToClass(EvaluationEntete, evaluations);
        this.loading = false;
      },
      error => {
        console.log(error);
        this.loading = false;
      });

    }

    loadEvaluationsLazy(event: LazyLoadEvent) {
      // event.first = First row offset
      // event.rows = Number of rows per page
      // event.sortField = Field name to sort with
      // event.sortOrder = Sort order as number, 1 for asc and -1 for dec
      // filters: FilterMetadata object having field as key and filter value, filter matchMode as value
      this.filtreEvaluation.offset = event.first;
      this.filtreEvaluation.limit = event.rows;
      this.filtreEvaluation.sort_property = FiltreEvaluation.getSortProperty(event.sortField);
      this.filtreEvaluation.sort_direction = FiltreEvaluation.getSortOrder(event.sortOrder);

      this.searchEvaluation(this.filtreEvaluation);
  }

  refresh() {
    this.refreshList.emit();
  }

  resetPagination() {
    this.dt.reset();
  }

  updateFiltreEvaluation(filtreEvaluation: FiltreEvaluation) {
    this.filtreEvaluation = filtreEvaluation;
  }

  getCols(): void {
      this.cols = [
        { field: 'personne_sujet_nom', header: 'Nom/Prénom' },
        { field: 'telephone', header: 'Téléphone' },
        { field: 'type_public', header: 'Type de public' },
        { field: 'created', header: 'Création' },
        { field: 'echeance', header: 'Échéance' },
        { field: 'statut_evaluation', header: 'Statut' },
        { field: 'evaluateur', header: 'Évaluateur' },
        { field: 'synchronisation', header: 'Synchronisation' },
        { field: 'date_evaluation', header: 'Date de visite' },
        { field: 'association', header: 'Association' }
    ];
  }

  getClassTrEvaluation(rowData) {
    let classTrEvaluation = 'selectable';

    if (rowData['priorite'] === 'U') {
      classTrEvaluation += ' tr-evaluation-urgent';
    }
    if (rowData['statut_evaluation'].code_parametre === 'F') {
      classTrEvaluation += ' tr-evaluation-closed';
    }
    if (rowData['firstDateEcheance'] !== null && rowData['firstDateEcheance'] < Date.now()) {
      classTrEvaluation += ' tr-evaluation-echeancePassed';
    }

    return classTrEvaluation;
  }

  getClassTdEvaluation(field) {
    if (field === 'statut_evaluation') {
      return 'td-centered';
    }
    return '';
  }

  getRowLabel(field, rowData) {
    if (field === 'evaluateur' || field === 'personne_sujet_nature' || field === 'association' || field === 'type_public' || field === 'statut_evaluation') {
      return rowData[field] ? rowData[field].libelle : '';
    } else if (field === 'personne_sujet_nom') {
      let labelSujet = '';
      if (rowData['client']) {
        if (rowData['client'].nom) {
          labelSujet += rowData['client'].nom;
        }
        if (rowData['client'].prenom) {
          labelSujet += ' ' + rowData['client'].prenom;
        }
      }

      return labelSujet;
    } else if (field === 'telephone') {
      let labelTelephone = '';
      if (rowData['client']) {
        if (rowData['client'].tel_fixe) {
          labelTelephone += rowData['client'].tel_fixe;
        } else if (rowData['client'].tel_mobile) {
          labelTelephone += ' ' + rowData['client'].tel_mobile;
        }
      }

      return labelTelephone;
    }

    return rowData[field];
  }

  getTooltip(field, rowData) {
    let tooltipLabel = null;

    return tooltipLabel;
  }

  getSortableColumnDisabled(field) {
    if (field === 'telephone' || field === 'statut_evaluation' || field === 'personne_sujet_nom') {
      return true;
    }

    return false;
  }

  customSort(event) {
    this.filtreEvaluation.sort_property = event.field;
    this.filtreEvaluation.sort_direction = event.order;

    this.searchEvaluation(this.filtreEvaluation);
  }

  getExportItems() {
    this.exportItems = [
      {
      label: 'Exporter la page',
      icon: 'zmdi zmdi-file',
      items: [
        {label: 'Format Excel', icon: 'zmdi zmdi-file', command: () => {
            this.exportExcel(false);
        }},
        {label: 'Format CSV', icon: 'zmdi zmdi-file-text', command: () => {
            this.exportCsv(false);
        }}
      ]},
      {
        label: 'Exporter toutes les évaluations',
        icon: 'zmdi zmdi-file-text',
        items: [
          {label: 'Format Excel', icon: 'zmdi zmdi-file', command: () => {
              this.exportExcel(true);
          }},
          {label: 'Format CSV', icon: 'zmdi zmdi-file-text', command: () => {
              this.exportCsv(true);
          }}
        ]}
    ];
  }

  exportExcel(allEvaluations = true) {
    if (allEvaluations) {
      let filtreEvaluationAll = classToClass(this.filtreEvaluation); // On clone le filtre evaluation
      filtreEvaluationAll.limit = null;  // On enleve la limite pour récupérer tous les evaluations
      filtreEvaluationAll.offset = null; // On réinitialise l'offset
      this.evaluationService.getEvaluationsByParams(filtreEvaluationAll)
      .subscribe((evaluations: EvaluationEntete[]) => {
        this.exportService.exportAsExcelFile(this.getDataForExport(plainToClass(EvaluationEntete, evaluations)), 'evaluations');
      });
    } else {
      this.exportService.exportAsExcelFile(this.getDataForExport(), 'evaluations');
    }
  }

  exportCsv(allEvaluations = true) {
    if (allEvaluations) {
      let filtreEvaluationAll = classToClass(this.filtreEvaluation); // On clone le filtre evaluation
      filtreEvaluationAll.limit = null;  // On enleve la limite pour récupérer tous les evaluations
      filtreEvaluationAll.offset = null; // On réinitialise l'offset
      this.evaluationService.getEvaluationsByParams(filtreEvaluationAll)
      .subscribe((evaluations: EvaluationEntete[]) => {
        this.exportService.exportAsCsvFile(this.getDataForExport(plainToClass(EvaluationEntete, evaluations)), 'evaluations');
      });
    } else {
      this.exportService.exportAsCsvFile(this.getDataForExport(), 'evaluations');
    }
  }

  getDataForExport(evaluationsEnteteList: EvaluationEntete[] = this.evaluationsEnteteList) {
    let data = [];
    evaluationsEnteteList.forEach(evaluation => {
      data.push(evaluation.serializeForExport());
    });

    return data;
  }

  editEvaluation(id_evaluation_entete, event) {
    let isNewTab = false;
    if (event.ctrlKey) { // Si Ctrl + Clic
      isNewTab = true;
    }

    let url = environment.appUrlEv + '/in/evaluation/' + id_evaluation_entete;
    if (isNewTab) {
      window.open(url.toString(), '_blank');
    } else {
      window.open(url.toString(), '_self');
    }
  }

  get currentPage(): number {
    let currentPage = 0;
    if (this.evaluationsEnteteList.length > 0 && this.dt.rows) {
      currentPage = this.dt.first / this.dt.rows + 1;
    }
    return currentPage;
  }

  get nbTotalPages(): number {
    let nbTotalPages = 0;
    if (this.nbTotalEvaluations > 0 && this.evaluationsEnteteList.length > 0 && this.dt.rows) {
      nbTotalPages = Math.ceil(this.nbTotalEvaluations / this.dt.rows); // On arrondi au supérieur
    }
    return nbTotalPages;
  }

  get firstElementNumber() {
    if (!this.nbTotalEvaluations || !this.currentPage) {
      return 0;
    }

    return this.filtreEvaluation.limit * (this.currentPage - 1) + 1;
  }

  get lastElementNumber() {
    if (!this.nbTotalEvaluations || !this.currentPage) {
      return 0;
    }

    let lastElementNumber = this.firstElementNumber + this.filtreEvaluation.limit - 1;

    if (lastElementNumber < this.nbTotalEvaluations) {
      return lastElementNumber;
    }
    return this.nbTotalEvaluations;
  }

}
