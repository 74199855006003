import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable, throwError } from 'rxjs';

import { environment } from '../../../environments/environment';

import { FederationMotif } from '../_models/federation-motif';

@Injectable({ providedIn: 'root' })
export class FederationMotifsService {

  constructor(
    private http: HttpClient
  ) {
  }

  /** GET federationsmotifs/all */
  getAll(): Observable<FederationMotif[]> {
    return this.http.get<FederationMotif[]>(`${environment.apiHost}/${environment.apiNamespace}/federationsmotifs/all`);
  }

  /** GET federationsmotifs/:id_federation */
  getFederationsMotifsByFederation(id_federation: number): Observable<FederationMotif[]> {
    return this.http.get<FederationMotif[]>(`${environment.apiHost}/${environment.apiNamespace}/federationsmotifs/${id_federation}`);
  }

  getFederationsMotifsByFederationAndCategorie(id_federation: number, id_categorie: number): Observable<FederationMotif[]> {
    return this.http.get<FederationMotif[]>(`${environment.apiHost}/${environment.apiNamespace}/federationsmotifs/${id_federation}/categorie/${id_categorie}`);
  }

  getFederationMotifsByFederationCategorieAndReclamation(id_federation: number, id_categorie: number, type_reclamation: number): Observable<FederationMotif[]> {
    return this.http.get<FederationMotif[]>(`${environment.apiHost}/${environment.apiNamespace}/federationsmotifs/${id_federation}/categorie/${id_categorie}/reclamation/${type_reclamation}`);
  }

  createFederationMotif(federationMotifData: any): Observable<FederationMotif[]> {
    return this.http.post<FederationMotif[]>(`${environment.apiHost}/${environment.apiNamespace}/federationsmotifs`, federationMotifData);
  }
}
