import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl } from '@angular/forms';

export function departementValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    let valid = false;

    if (control.value) {
      // Département classique de 01 à 95
      if ( !isNaN( Number(control.value) ) && control.value.length === 2 && parseInt(control.value, 10) > 0 && parseInt(control.value, 10) <= 95 && parseInt(control.value, 10) !== 20 ) {
        valid =  true;
      } else if ( control.value.length === 2 && ( control.value === '2A' || control.value === '2B') ) { // Les Corses
        valid = true;
      } else if ( control.value.length === 3 &&  !isNaN( parseInt(control.value, 10) ) && parseInt(control.value, 10) >= 971 && parseInt(control.value, 10) <= 976 ) { // Les DOM/TOM
        valid = true;
      }
    }

    return control.value < 1 || valid || !control.value ? null : {'isDepartement': true};
  };
}

@Directive({
  selector: '[appDepartementValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: DepartementValidatorDirective, multi: true}]
})
export class DepartementValidatorDirective implements Validator {

  constructor() { }

  public validate(control: AbstractControl): {[key: string]: any} {
    return departementValidator()(control);
  }
}
