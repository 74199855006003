import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {SelectAssociationComponent} from './select-association/select-association.component';
import {AuthGuard} from './shared/_guards';

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
  scrollOffset: [0, 94],
  onSameUrlNavigation: 'reload'
};

const routes: Routes = [
  {
    path: '',
    redirectTo: 'in/recherche',
    pathMatch: 'full'
  },
  {
    path: 'in/select-association',
    canActivate: [AuthGuard],
    component: SelectAssociationComponent
  },
  // otherwise redirect to home
  /*{
    path: '**',
    redirectTo: '',
  }*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
