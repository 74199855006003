import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { RechercheActionRoutingModule } from './recherche-action-routing.module';
import { RechercheActionComponent } from './recherche-action/recherche-action.component';
import { FiltresActionComponent } from './filtres-action/filtres-action.component';

@NgModule({
  declarations: [RechercheActionComponent, FiltresActionComponent],
  imports: [
    CommonModule,
    SharedModule,
    RechercheActionRoutingModule
  ]
})
export class RechercheActionModule { }
