import {FormControl, FormGroup} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {Personne} from './personne';

export class SommeilEnfant {

  id_sommeil_enfant: number;

  reveil: string;

  coucher: string;

  siestes: string;

  emplacement: string;

  fermer = false;

  tenue: string;

  info: string;

  @Type(() => Personne)
  enfant: Personne;

  static getForm(sommeilEnfant = null): FormGroup {
    if (!sommeilEnfant) {
      sommeilEnfant = new SommeilEnfant();
    }

    return new FormGroup({
      'id_sommeil_enfant': new FormControl(sommeilEnfant.id_sommeil_enfant),
      'reveil': new FormControl(sommeilEnfant.reveil),
      'coucher': new FormControl(sommeilEnfant.coucher),
      'siestes': new FormControl(sommeilEnfant.siestes),
      'emplacement': new FormControl(sommeilEnfant.emplacement),
      'fermer': new FormControl(sommeilEnfant.fermer),
      'tenue': new FormControl(sommeilEnfant.tenue),
      'info': new FormControl(sommeilEnfant.info),
    });
  }

  serialize() {
    return classToPlain(this);
  }
}
