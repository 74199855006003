<p-accordion #accordionFilter expandIcon="pi pi-fw pi-chevron-down" collapseIcon="pi pi-fw pi-chevron-up" [(activeIndex)]="activeIndexFilter">
    <p-accordionTab>
      <p-header>
        <i class="fa fa-search"></i> Rechercher des actions
      </p-header>
      <app-filtres-action (search)="searchAction($event)" (showFilter)="closeFilters()"></app-filtres-action>
    </p-accordionTab>
</p-accordion>

<div class="ui-grid-row">
  <div class="ui-g-12 filtre-actions">
    <a (click)="initFilter()">Réinitialiser les filtres</a> | <a (click)="toggleShowFilter()">{{toggleShowFilterLabel}}</a>
  </div>
</div>

<div id="panel-liste-echanges" class="ui-grid-row panel-header-category-transparent">
    <div class="ui-g-12 no-padding-left">
      <h2 style="float: left;">
        <i class="zmdi zmdi-view-list"></i>
        Liste des actions (page {{currentPage}} / {{nbTotalPages}}) <button #btnExport type="button" pButton icon="pi pi-file" class="grey-button" style="margin-left: 20px;" label="Exporter" (click)="menuExport.toggle($event)"></button>
      </h2>

      <p-slideMenu #menuExport [model]="exportItems" [popup]="true" [viewportHeight]="150" backLabel="Retour" appendTo="body"></p-slideMenu>
      <h2 style="float: right;">
        Nombre d'éléments {{firstElementNumber}} - {{lastElementNumber}} sur {{currentPage ? nbTotalActions : 0}} <i class="pi pi-refresh" style="margin-left:10px; cursor:pointer;" (click)="refresh()"></i>
      </h2>
    </div>
</div>
<p-table #dt class="table table-hover table-condensed" [columns]="cols" [value]="actionsList" dataKey="id_action" autoLayout="true"
    [lazy]="true" [lazyLoadOnInit]="false" (onLazyLoad)="loadActionsLazy($event)"
    [rows]="filtreAction.limit" [totalRecords]="nbTotalActions" [loading]="loading"
    [paginator]="true" [rowsPerPageOptions]="rowsPerPageOptions" paginatorDropdownAppendTo= "body"
    (sortFunction)="customSort($event)" [customSort]="true"
    stateStorage="session" stateKey="state-actionsList"
    [(contextMenuSelection)]="selectedAction" [contextMenu]="cmAction" [selectionMode]="'single'" (onContextMenuSelect)="updateContextMenuAction($event)">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field" [pSortableColumnDisabled]="getSortableColumnDisabled(col.field)">
                <span>{{col.header}}</span>
                <p-sortIcon *ngIf="!getSortableColumnDisabled(col.field)" [field]="col.field"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
        <tr [class]="getClassTrAction(rowData)" [pSelectableRow]="rowData" [pContextMenuRow]="rowData" (click)="goToActionDatas(rowData, $event)">
            <td *ngFor="let col of columns" [class]="getClassTdAction(col.field)">
                <span *ngIf="col.field ==='created1'" >{{getRowLabel('created', rowData) | date: datePipeFormatWithoutTime}}</span>
                <span *ngIf="col.field ==='created2'">{{getRowLabel('created', rowData) | date: timeFormat}}</span>
                <span *ngIf="col.field ==='statut_action'">{{getRowLabel(col.field, rowData)}}</span>
                <span *ngIf="col.field ==='redacteur'">{{getRowLabel(col.field, rowData)}}</span>
                <span *ngIf="col.field ==='personne_sujet_nom'" [pTooltip]="getTooltip(col.field, rowData)" tooltipStyleClass="tooltip-utilisateurLibelle">{{getRowLabel(col.field, rowData)}}</span>
                <span *ngIf="col.field ==='categorie_echange'">{{getRowLabel(col.field, rowData)}}</span>
                <span *ngIf="col.field ==='motif_echange'">{{getRowLabel(col.field, rowData)}}</span>
                <span *ngIf="col.field ==='objet_action'">{{getRowLabel(col.field, rowData)}}</span>
                <span *ngIf="col.field ==='statut_echeance'">{{getRowLabel(col.field, rowData)}}</span>
                <span *ngIf="col.field ==='echeance'">{{getRowLabel(col.field, rowData) | date: datePipeFormatWithoutTime}}</span>
                <span *ngIf="col.field ==='responsable'">{{getRowLabel(col.field, rowData)}}</span>
                <span *ngIf="col.field ==='association'">{{getRowLabel(col.field, rowData)}}</span>
            </td>
        </tr>
    </ng-template>
</p-table>

<p-contextMenu #cmAction [model]="itemsContextMenu" class="contextMenuListeAction"></p-contextMenu>

