import {FormControl, FormGroup} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {Parametre} from './parametre';

export class Equipement {

  id_equipement: number;

  autre_equipement: string;

  @Type(() => Parametre)
  reference: Parametre;

  static getForm(equipement = null): FormGroup {
    if (!equipement) {
      equipement = new Equipement();
    }

    return new FormGroup({
      'id_equipement': new FormControl(equipement.id_equipement),
      'autre_equipement': new FormControl(equipement.autre_equipement),
      'reference': new FormControl(equipement.reference)
    });
  }

  serialize() {
    let serializeObject = classToPlain(this);

    if (this.reference !== null && this.reference !== undefined) {
      serializeObject['reference'] = this.reference.id_parametre;
    }

    return serializeObject;
  }
}
