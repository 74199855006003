import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-documentation-tableau-bord',
  templateUrl: './documentation-tableau-bord.component.html',
  styleUrls: ['./documentation-tableau-bord.component.css']
})
export class DocumentationTableauBordComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
