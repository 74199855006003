<div class="ui-grid ui-grid-responsive ui-grid-pad ui-fluid">
  <div class="ui-grid-row">
    <div class="ui-g-4">
      <div class="ui-g-2">
        <label>Fédération</label>
      </div>
      <div class="ui-g-10">
        <p-dropdown  [options]="federationsList" [(ngModel)]="federationSelected" dataKey="id_federation" [style]="{'width':'100%'}"
          [readonly]="isFederationReadOnly()" [placeholder]="'Fédération...'" [showClear]="!isFederationReadOnly()"
          (onChange)="onChangeFederation()" #federation="ngModel" name="federation" optionLabel="libelle"></p-dropdown>
      </div>
    </div>
    <div class="ui-g-4">
      <div class="ui-g-2">
        <label>Association</label>
      </div>
      <div class="ui-g-10">
        <p-dropdown  [options]="associationsItemsList" [(ngModel)]="associationSelected" [style]="{'width':'100%'}"
          [readonly]="isAssociationReadOnly()"
          (onChange)="onChangeAssociation()" #association="ngModel" name="association" [showClear]="!isAssociationReadOnly()"
          dataKey="id_association" [placeholder]="'Association...'">
          <ng-template let-association pTemplate="item">
            <span *ngIf="association.value.disabled" style="font-style: italic;"><i class="pi pi-times" style="color: red;float: left;"></i>{{association.value.libelle}}</span>
            <span *ngIf="!association.value.disabled">{{association.value.libelle}}</span>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
    <div class="ui-g-4">
      <div class="ui-g-2">
        <label>Agence</label>
      </div>
      <div class="ui-g-10">
        <p-dropdown [options]="agencesItemsList" [(ngModel)]="agenceSelected" [style]="{'width':'100%'}"
          [readonly]="isAgenceReadOnly()"
          #agence="ngModel" name="agence" [showClear]="!isFederationReadOnly()"
          [placeholder]="'Agence...'">
          <ng-template let-agence pTemplate="item">
            <span *ngIf="agence.value.disabled" style="font-style: italic;"><i class="pi pi-times" style="color: red;float: left;"></i>{{agence.value.libelle}}</span>
            <span *ngIf="!agence.value.disabled">{{agence.value.libelle}}</span>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
  </div>
  <div class="ui-grid-row p-dir-rev">
    <div class="ui-grid-col-2">
      <button pButton type="button" class="grey-button" (click)="getDatas()" label="Actualiser"></button>
    </div>
  </div>
</div>

<div class="panel-white">
  <div  *ngIf="showTuile('echanges_urgents_a_traiter') || showTuile('reclamations_ouvertes') || showTuile('echanges_rem_inf')
              || showTuile('echanges_brouillons') || showTuile('mes_echanges_brouillons') || showTuile('echanges_astreintes')
              || showTuile('mes_echanges_urgents_ouverts') || showTuile('mes_echanges')
              || showTuile('echanges_devis_ouverts') || showTuile('echanges_prospects_ouverts')" class="p-grid">
    <div *ngIf="showTuile('echanges_urgents_a_traiter')" class="p-col-3 panel-tableau-bord green">
      <div class="panel-tableau-bord-body selectable" (click)="goToRechercheEchange('echangesUrgents', $event)">
        <div class="header">
          <span class="title">Echanges urgents à traiter</span>
        </div>
        <div class="indicateur">
          <span *ngIf="nbEchangesUrgents || nbEchangesUrgents === 0" [class]="getClassIndicateur('echangesUrgents')">{{nbEchangesUrgents}}</span>
          <span *ngIf="!nbEchangesUrgents && nbEchangesUrgents !== 0"><i class="pi pi-spin pi-spinner"></i></span>
        </div>
      </div>
    </div>
    <div *ngIf="showTuile('reclamations_ouvertes')" class="p-col-3 panel-tableau-bord green" (click)="goToRechercheEchange('reclamations', $event)">
      <div class="panel-tableau-bord-body selectable">
        <div class="header">
          <span class="title">Réclamations ouvertes</span>
        </div>
        <div class="indicateur">
          <span *ngIf="nbReclamations || nbReclamations === 0" [class]="getClassIndicateur('reclamations')">{{nbReclamations}}</span>
          <span *ngIf="!nbReclamations && nbReclamations !== 0"><i class="pi pi-spin pi-spinner"></i></span>
        </div>
      </div>
    </div>
    <div *ngIf="showTuile('echanges_rem_inf')" class="p-col-3 panel-tableau-bord green">
      <div class="panel-tableau-bord-body selectable" (click)="goToRechercheEchange('echanges_rem_inf', $event)">
        <div class="header">
          <span class="title">Remontées d'informations ouvertes</span>
        </div>
        <div class="indicateur">
          <span *ngIf="nbEchangesRemInf || nbEchangesRemInf === 0" [class]="getClassIndicateur('echanges_rem_inf')">{{nbEchangesRemInf}}</span>
          <span *ngIf="!nbEchangesRemInf && nbEchangesRemInf !== 0"><i class="pi pi-spin pi-spinner"></i></span>
        </div>
      </div>
    </div>
    <div *ngIf="showTuile('echanges_brouillons')" class="p-col-3 panel-tableau-bord green">
      <div class="panel-tableau-bord-body selectable" (click)="goToRechercheEchange('echanges_brouillons', $event)">
        <div class="header">
          <span class="title">Echanges brouillons</span>
        </div>
        <div class="indicateur">
          <span *ngIf="nbBrouillons || nbBrouillons === 0" [class]="getClassIndicateur('echanges_brouillons')">{{nbBrouillons}}</span>
          <span *ngIf="!nbBrouillons && nbBrouillons !== 0"><i class="pi pi-spin pi-spinner"></i></span>
        </div>
      </div>
    </div>
    <div *ngIf="showTuile('mes_echanges_brouillons')" class="p-col-3 panel-tableau-bord green" (click)="goToRechercheEchange('mes_echanges_brouillons', $event)">
      <div class="panel-tableau-bord-body selectable">
        <div class="header">
          <span class="title">Mes échanges brouillons</span>
        </div>
        <div class="indicateur">
          <span *ngIf="nbMesBrouillons || nbMesBrouillons === 0" [class]="getClassIndicateur('mes_echanges_brouillons')">{{nbMesBrouillons}}</span>
          <span *ngIf="!nbMesBrouillons && nbMesBrouillons !== 0"><i class="pi pi-spin pi-spinner"></i></span>
        </div>
      </div>
    </div>
    <div *ngIf="showTuile('echanges_astreintes')" class="p-col-3 panel-tableau-bord green">
      <div class="panel-tableau-bord-body selectable" (click)="goToRechercheEchange('echanges_astreintes', $event)">
        <div class="header">
          <span class="title">Echanges astreintes</span>
        </div>
        <div class="indicateur">
          <span *ngIf="nbAstreintes || nbAstreintes === 0" [class]="getClassIndicateur('echanges_astreintes')">{{nbAstreintes}}</span>
          <span *ngIf="!nbAstreintes && nbAstreintes !== 0"><i class="pi pi-spin pi-spinner"></i></span>
        </div>
      </div>
    </div>
    <div *ngIf="showTuile('mes_echanges_urgents_ouverts')" class="p-col-3 panel-tableau-bord green" (click)="goToRechercheEchange('mes_echanges_urgents_ouverts', $event)">
      <div class="panel-tableau-bord-body selectable">
        <div class="header">
          <span class="title">Mes échanges urgents ouverts</span>
        </div>
        <div class="indicateur">
          <span *ngIf="nbMesEchangesUrgentsOuverts || nbMesEchangesUrgentsOuverts === 0" [class]="getClassIndicateur('mes_echanges_urgents_ouverts')">{{nbMesEchangesUrgentsOuverts}}</span>
          <span *ngIf="!nbMesEchangesUrgentsOuverts && nbMesEchangesUrgentsOuverts !==0"><i class="pi pi-spin pi-spinner"></i></span>
        </div>
      </div>
    </div>
    <div *ngIf="showTuile('mes_echanges')" class="p-col-3 panel-tableau-bord green" (click)="goToRechercheEchange('mes_echanges', $event)">
      <div class="panel-tableau-bord-body selectable">
        <div class="header">
          <span class="title">Mes échanges</span>
        </div>
        <div class="indicateur">
          <span *ngIf="nbMesEchanges || nbMesEchanges === 0" [class]="getClassIndicateur('mes_echanges')">{{nbMesEchanges}}</span>
          <span *ngIf="!nbMesEchanges && nbMesEchanges !== 0"><i class="pi pi-spin pi-spinner"></i></span>
        </div>
      </div>
    </div>
    <div *ngIf="showTuile('echanges_devis_ouverts')" class="p-col-3 panel-tableau-bord green" (click)="goToRechercheEchange('echanges_devis_ouverts', $event)">
      <div class="panel-tableau-bord-body selectable">
        <div class="header">
          <span class="title">Échanges devis ouverts</span>
        </div>
        <div class="indicateur">
          <span *ngIf="nbEchangesDevisOuverts || nbEchangesDevisOuverts === 0" [class]="getClassIndicateur('echanges_devis_ouverts')">{{nbEchangesDevisOuverts}}</span>
          <span *ngIf="!nbEchangesDevisOuverts && nbEchangesDevisOuverts !== 0"><i class="pi pi-spin pi-spinner"></i></span>
        </div>
      </div>
    </div>
    <div *ngIf="showTuile('echanges_prospects_ouverts')" class="p-col-3 panel-tableau-bord green" (click)="goToRechercheEchange('echanges_prospects_ouverts', $event)">
      <div class="panel-tableau-bord-body selectable">
        <div class="header">
          <span class="title">Échanges prospects ouverts</span>
        </div>
        <div class="indicateur">
          <span *ngIf="nbEchangesProspectOuverts || nbEchangesProspectOuverts === 0" [class]="getClassIndicateur('echanges_prospects_ouverts')">{{nbEchangesProspectOuverts}}</span>
          <span *ngIf="!nbEchangesProspectOuverts && nbEchangesProspectOuverts !== 0"><i class="pi pi-spin pi-spinner"></i></span>
        </div>
      </div>
    </div>
  </div>

  <div class="p-grid">
    <div *ngIf="showTuile('echanges_ouverts')" class="p-col-6 panel-tableau-bord green">
      <div class="panel-tableau-bord-body">
        <div class="header">
          <span class="title">Échanges ouverts par catégorie</span>
        </div>
        <div class="content">
          <p-chart type="pie" [data]="dataPieEchanges" [options]="optionsChartPieEchange" (onDataSelect)="selectDataChartEchange($event, 'echangesOuverts')"></p-chart>
        </div>
      </div>
    </div>
    <div *ngIf="showTuile('actions_ouvertes')" class="p-col-6 panel-tableau-bord">
      <div class="panel-tableau-bord-body">
        <div class="header">
          <span class="title">Actions ouvertes</span>
        </div>
        <div class="content">
          <p-chart type="bar" [data]="dataBarActions" [options]="optionsChartBar" (onDataSelect)="selectDataChartAction($event, 'actionsOuvertes')"></p-chart>
        </div>
      </div>
    </div>
    <div *ngIf="showTuile('mes_actions')" class="p-col-6 panel-tableau-bord">
      <div class="panel-tableau-bord-body">
        <div class="header">
          <span class="title">Mes actions ouvertes</span>
        </div>
        <div class="content">
          <p-chart type="pie" [data]="dataPieMesActions" [options]="optionsChartPie" (onDataSelect)="selectDataChartAction($event, 'mesActions')"></p-chart>
        </div>
      </div>
    </div>
  </div>
</div>
