import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {Message} from 'primeng/api';

import { AuthenticationService } from '../shared/_services';
import {Federation, Association, Utilisateur} from '../shared/_models';
import { Globals } from './../shared/utils/globals';

@Component({
  selector: 'app-select-association',
  templateUrl: './select-association.component.html',
  styleUrls: ['./select-association.component.css']
})
export class SelectAssociationComponent implements OnInit {

  appTitle = Globals.appTitle;
  currentUtilisateur: Utilisateur;
  currentFederation: Federation;
  currentAssociation: Association;
  federationsList: Federation[];
  associationsList: Association[];

  federationPlaceholder = 'Fédération';
  associationPlaceholder = 'Sélectionner une fédération';
  loading = false;
  msgs: Message[] = [];

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.authenticationService.currentUtilisateur$.subscribe(utilisateur => {
      if ( utilisateur ) {
        this.currentUtilisateur = this.authenticationService.currentUtilisateurValue;
        this.federationsList = [];
        this.getLists();
        this.initSelectedValue();
      }
    });
  }

  getLists() {
    this.currentFederation = this.currentUtilisateur.federation;
    this.federationsList.push(this.currentFederation);
    this.associationsList = this.currentUtilisateur.multiSiteAssociations;
    this.associationPlaceholder = 'Association';
  }

  initSelectedValue() {
    if (this.authenticationService.currentAssociationForUserMultiSite !== null && this.authenticationService.currentAssociationForUserMultiSite !== undefined) {
      this.currentAssociation = this.authenticationService.currentAssociationForUserMultiSite;
    }
  }

  isFormValid() {
    if (this.currentFederation === null || this.currentFederation === undefined || this.currentAssociation === null || this.currentAssociation === undefined) {
      return false;
    }
    return true;
  }

  // Informe l'utilisateur sur son authentfication.
  setMessage() {
    this.msgs = [];
    if (this.isFormValid) {
      this.msgs.push({severity: 'error', summary: '', detail: 'Veuillez saisir une fédération et une association correcte.'});
    }
  }

  selectAssociation() {
    this.loading = true;
    if (this.isFormValid()) {
      this.authenticationService.currentAssociationForUserMultiSite = this.currentAssociation;
      this.loading = false;

      const redirect = '/in/recherche';
      // Redirige l'utilisateur
      this.router.navigate([redirect]);
    } else {
      this.setMessage();
      this.loading = false;
    }
  }

}
