import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

// Ohter Imports
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import {PdfViewerModule} from 'ng2-pdf-viewer';

// NSI Imports
import {NsiEnteteModule} from 'nsi-entete';
import {NsiIdentificationModule} from 'nsi-identification';
import {NsiPanneauModule} from 'nsi-panneau';
import {NsiPiedDePageModule} from 'nsi-pied-de-page';
// NSI Components
import {NsiAdminFederationsModule} from 'nsi-admin-federations';
import {NsiAdminProfilsModule} from 'nsi-admin-profils';
import {NsiAdminGroupesModule} from 'nsi-admin-groupes';
import {NsiAdminUtilisateursModule} from 'nsi-admin-utilisateurs';
import {NsiAdminAssociationsModule} from 'nsi-admin-associations';
import {NsiAdminAgencesModule} from 'nsi-admin-agences';
import {NsiAdminParametresModule} from 'nsi-admin-parametres';

// PrimeNG Imports
import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {FieldsetModule} from 'primeng/fieldset';
import {FileUploadModule} from 'primeng/fileupload';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {ListboxModule} from 'primeng/listbox';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {MessageModule} from 'primeng/message';
import {MessagesModule} from 'primeng/messages';
import {MultiSelectModule} from 'primeng/multiselect';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {PasswordModule} from 'primeng/password';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {RadioButtonModule} from 'primeng/radiobutton';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {SidebarModule} from 'primeng/sidebar';
import {SlideMenuModule} from 'primeng/slidemenu';
import {SliderModule} from 'primeng/slider';
import {SplitButtonModule} from 'primeng/splitbutton';
import {TableModule} from 'primeng/table';
import {TabMenuModule} from 'primeng/tabmenu';
import {TabViewModule} from 'primeng/tabview';
import {ToastModule} from 'primeng/toast';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {TooltipModule} from 'primeng/tooltip';
import {AlerteValidatorDirective} from './_directives/alerte-validator.directive';
import {DepartementValidatorDirective} from './_directives/departement-validator.directive';
import {EchangeValidatorDirective} from './_directives/echange-validator.directive';
import {EmailValidatorDirective} from './_directives/email-validator.directive';
import {InterventionOperationValidatorDirective} from './_directives/intervention-operation-validator.directive';
import {LoginValidatorDirective} from './_directives/login-validator.directive';
import {OrganismeFinancierValidatorDirective} from './_directives/organisme-financier-validator.directive';
import {PersonneValidatorDirective} from './_directives/personne-validator.directive';
import {PhoneValidatorDirective} from './_directives/phone-validator.directive';
import {CapitalizeFirstPipe} from './_pipes/capitalize-first.pipe';
import {RemoveWhiteSpacesPipe} from './_pipes/remove-white-spaces.pipe';
import {ActionFormDialogComponent} from './action-form-dialog/action-form-dialog.component';
import {EntourageFormDialogComponent} from './entourage-form-dialog/entourage-form-dialog.component';
import {ListeAlertesComponent} from './liste-alertes/liste-alertes.component';
import {ListeAppreciationsComponent} from './liste-appreciations/liste-appreciations.component';
import {ListeEchangesComponent} from './liste-echanges/liste-echanges.component';
import {ListeEvaluationsComponent} from './liste-evaluations/liste-evaluations.component';
import {LoaderComponent} from './loader/loader.component';

// Custom Imports
import {MenuComponent} from './menu/menu.component';
import {
  RechercheTelephoneAssociationComponent
} from './recherche-telephone-association/recherche-telephone-association.component';
import {SelectIconComponent} from './select-icon/select-icon.component';
import { RouterOutletContainerClassPipe } from './_pipes/router-outler-container-class.pipe';

@NgModule({
  declarations: [
    MenuComponent,
    LoaderComponent,
    RechercheTelephoneAssociationComponent,
    SelectIconComponent,
    ActionFormDialogComponent,
    PhoneValidatorDirective,
    EmailValidatorDirective,
    DepartementValidatorDirective,
    LoginValidatorDirective,
    EchangeValidatorDirective,
    EntourageFormDialogComponent,
    InterventionOperationValidatorDirective,
    PersonneValidatorDirective,
    CapitalizeFirstPipe,
    RemoveWhiteSpacesPipe,
    OrganismeFinancierValidatorDirective,
    AlerteValidatorDirective,
    ListeEchangesComponent,
    ListeEvaluationsComponent,
    ListeAlertesComponent,
    ListeAppreciationsComponent,
    RouterOutletContainerClassPipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    SplitButtonModule,
    CalendarModule,
    InputTextModule,
    AutoCompleteModule,
    PasswordModule,
    InputSwitchModule,
    InputTextareaModule,
    InputMaskModule,
    DropdownModule,
    MultiSelectModule,
    CheckboxModule,
    ListboxModule,
    RadioButtonModule,
    TableModule,
    AccordionModule,
    FieldsetModule,
    CardModule,
    PanelModule,
    ScrollPanelModule,
    ConfirmDialogModule,
    DynamicDialogModule,
    DialogModule,
    OverlayPanelModule,
    TooltipModule,
    MenuModule,
    ContextMenuModule,
    PanelMenuModule,
    MenubarModule,
    SlideMenuModule,
    TabMenuModule,
    TabViewModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    SidebarModule,
    ProgressSpinnerModule,
    SliderModule,
    NsiIdentificationModule,
    NsiEnteteModule,
    NsiPiedDePageModule,
    NsiPanneauModule,
    NsiAdminFederationsModule,
    NsiAdminProfilsModule,
    NsiAdminGroupesModule,
    NsiAdminUtilisateursModule,
    NsiAdminAssociationsModule,
    NsiAdminAgencesModule,
    NsiAdminParametresModule,
    ToggleButtonModule,
    FileUploadModule,
    ChartModule,
    ScrollToModule.forRoot(),
    PdfViewerModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    SplitButtonModule,
    CalendarModule,
    InputTextModule,
    AutoCompleteModule,
    PasswordModule,
    InputSwitchModule,
    InputTextareaModule,
    InputMaskModule,
    DropdownModule,
    MultiSelectModule,
    CheckboxModule,
    ListboxModule,
    RadioButtonModule,
    TableModule,
    AccordionModule,
    FieldsetModule,
    CardModule,
    PanelModule,
    ScrollPanelModule,
    ConfirmDialogModule,
    DynamicDialogModule,
    DialogModule,
    OverlayPanelModule,
    TooltipModule,
    MenuModule,
    ContextMenuModule,
    PanelMenuModule,
    MenubarModule,
    SlideMenuModule,
    TabMenuModule,
    TabViewModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    SidebarModule,
    ProgressSpinnerModule,
    SliderModule,
    ToggleButtonModule,
    FileUploadModule,
    ChartModule,
    NsiIdentificationModule,
    NsiEnteteModule,
    NsiPiedDePageModule,
    NsiPanneauModule,
    NsiAdminFederationsModule,
    NsiAdminProfilsModule,
    NsiAdminGroupesModule,
    NsiAdminUtilisateursModule,
    NsiAdminAssociationsModule,
    NsiAdminAgencesModule,
    NsiAdminParametresModule,
    ScrollToModule,
    PdfViewerModule,
    MenuComponent,
    LoaderComponent,
    RechercheTelephoneAssociationComponent,
    SelectIconComponent,
    ActionFormDialogComponent,
    PhoneValidatorDirective,
    EmailValidatorDirective,
    DepartementValidatorDirective,
    LoginValidatorDirective,
    EchangeValidatorDirective,
    PersonneValidatorDirective,
    OrganismeFinancierValidatorDirective,
    EntourageFormDialogComponent,
    CapitalizeFirstPipe,
    RemoveWhiteSpacesPipe,
    ListeEchangesComponent,
    ListeEvaluationsComponent,
    ListeAlertesComponent,
    ListeAppreciationsComponent,
    RouterOutletContainerClassPipe
  ]
})
export class SharedModule {
}
