import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Validators, FormControl, FormGroup, FormBuilder, FormArray} from '@angular/forms';
import { Router } from '@angular/router';

import { forkJoin } from 'rxjs';
import {plainToClass} from 'class-transformer';

import {SelectItem} from 'primeng/api';

import {MessageService, ConfirmationService} from 'primeng/api';

import { AuthenticationService, UtilisateurService, ActionService, EvaluationService, ParametreService, GroupeService } from '../../shared/_services';
import {Utilisateur, Action, EvaluationEntete, Parametre, Groupe, DroitModule} from '../../shared/_models';
import { FiltreEvaluation } from 'src/app/shared/utils/filtre-evaluation';

import {Globals} from '../../shared/utils/globals';
import { HighlightSpanKind } from 'typescript';

@Component({
  selector: 'app-action-form-dialog',
  templateUrl: './action-form-dialog.component.html',
  styleUrls: ['./action-form-dialog.component.css'],
  providers: [ConfirmationService]
})
export class ActionFormDialogComponent implements OnInit {

  @Input()
  action: Action = new Action(); // propriété d'entrée du composant : l'action a editer

  @Input()
  formMode: string; // propriété d'entrée du composant : le mode du formulaire (edit ou create)

  @Input()
  showPopupAction = false; // propriété d'entrée du composant : visibilite de la popup

  @Input()
  saveNew = false; // propriété d'entrée du composant : visibilite de la popup

  @Output()
  cancel = new EventEmitter<any>();

  @Output()
  actionCreated = new EventEmitter<any>();

  @Output()
  actionUpdated = new EventEmitter<any>();

  currentUtilisateur: Utilisateur;
  actionForm: FormGroup;

  datePipeFormat = Globals.datePipeFormat;
  datePipeFormatWithoutTime = Globals.datePipeFormatWithoutTime;
  dateFormat = Globals.dateFormat;
  locale = Globals.dateLocale;

  statutsActionList: Parametre[];
  statutsEvaluationActionList: Parametre[];
  statutsEvaluationActionCreationList: Parametre[];
  responsablesList: Utilisateur[];
  objetsList: SelectItem[];
  typesPublicList: Parametre[];
  groupesList: Groupe[];

  showPopupEvaluation = false;

  statutsActionInitial: Parametre;
  loading: boolean;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private utilisateurService: UtilisateurService,
    private actionService: ActionService,
    private evaluationService: EvaluationService,
    private parametreService: ParametreService,
    private groupeService: GroupeService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.currentUtilisateur = this.authenticationService.currentUtilisateurValue;
    this.getStatutsAction();
    this.getStatutsEvaluationAction();
    this.getResponsables();
    this.getActionObjets();
    this.createActionForm();
    if (this.action.isEvaluation()) {
      this.getTypesPublic();
    } else {
      this.getGroupes();
    }
    this.statutsActionInitial = this.action.statut_action;
    this.loading = false;
  }

  createActionForm() {
    this.actionForm = this.actionService.createActionForm(this.action);
  }

  getStatutsAction() {
      this.parametreService.getParametresByParams({type: 'STATUT_A'})
      .subscribe((parametres: Parametre[]) => {
          this.statutsActionList = parametres;
      });
  }

  getStatutsEvaluationAction() {
    this.parametreService.getParametresByParams({type: 'STATUT_EVA'})
    .subscribe((parametres: Parametre[]) => {
          let statutsEvaluationCreationList = [];
          parametres.forEach(function (statutEvaluation) {
            if (statutEvaluation.code_parametre === 'INI' || statutEvaluation.code_parametre === 'O') {
              statutsEvaluationCreationList.push(statutEvaluation);
            }
          });
          this.statutsEvaluationActionCreationList = statutsEvaluationCreationList;
          this.statutsEvaluationActionList = parametres;
    });
  }

  getResponsables() {
    let associationId = 0;
    if (this.action.echange !== null && this.action.echange !== undefined &&
      this.action.echange.association !== null && this.action.echange.association !== undefined) {
      associationId = this.action.echange.association.id_association;
    } else if (this.action.isAlerte()) {
        associationId = this.action.alerte.association.id_association;
    }

    if (this.action.isEvaluation()) {
      this.utilisateurService.getEvaluateursByParams({association: associationId}, true)
      .subscribe((utilisateurs: Utilisateur[]) => {
          this.responsablesList = plainToClass(Utilisateur, utilisateurs);
      });
    } else {
      this.utilisateurService.getResponsablesByParams({association: associationId}, true)
      .subscribe((utilisateurs: Utilisateur[]) => {
          // Si Utilisateur connecté est de périmètre Fédérations|Association (ni admin, ni périmètre National)
          // Alors filtrer de la liste les administrateurs NSI (admin et National)
          // if ( !(this.currentUtilisateur.est_administrateur === 'O' && this.currentUtilisateur.perimetre === 'National')) {
          //   const filtreUtilisateurs = utilisateurs.filter(utilisateur =>
          //     !(utilisateur.est_administrateur === 'O' && utilisateur.perimetre === 'National')
          //   );
          //   this.responsablesList = plainToClass(Utilisateur, filtreUtilisateurs);
          // } else {
            this.responsablesList = plainToClass(Utilisateur, utilisateurs);
          // }
      });
    }
  }

  getActionObjets() {
    if (this.action.isEvaluation()) {
      this.objetsList = this.evaluationService.getObjets();
    } else if (this.action.isAlerte() || this.action.isReclamation()) {
      let type_parametre = 'OBJET_ALERTE';

      if (this.action.isReclamation()) {
        type_parametre = 'OBJET_RECLAMATION';
      }

      this.parametreService.getParametresByParams({type: type_parametre})
      .subscribe((parametres: Parametre[]) => {
        let objetsList = [];
        parametres.forEach(function (parametre) {
          objetsList.push({label: parametre.libelle, value: parametre.libelle});
        });

        if (this.action.isAlerte()) {
          Action.objetsListeAlerteAuto.forEach(function (objetAlerteAuto) {
            objetsList.push({label: objetAlerteAuto, value: objetAlerteAuto, disabled: true});
          });
        }

        // On trie par ordre alphabétique
        objetsList.sort(function(a, b) {
          if ( a.label < b.label) { return -1; }
          if ( a.label > b.label) { return 1; }
          return 0;
        } );

        this.objetsList = objetsList;
      });
    }
  }

  getTypesPublic() {
    this.parametreService.getParametresByParams({type: 'TYPE_PUBLIC'})
    .subscribe((parametres: Parametre[]) => {
        this.typesPublicList = parametres;
        let self = this;
        if (this.formMode === 'create') {
          this.typesPublicList.forEach(function (typePublic) {
            if (typePublic.is_default) {
                self.actionForm.get('type_public').patchValue(typePublic); // On definit le type public par defaut
            }
        });
        }
    });
  }

  getGroupes() {
    let paramsGroupeFede = {};
    let paramsGroupeAsso = {};
    let modelParent = null;

    if (this.action.echange) {
      modelParent = this.action.echange;
    } else if (this.action.isAlerte()) {
      modelParent = this.action.alerte;
    }

    if (modelParent) {
      paramsGroupeFede['perimetre'] = 'Federation';
      paramsGroupeAsso['perimetre'] = 'Association';

      if (modelParent.federation) {
        paramsGroupeAsso['federation'] = modelParent.federation.id_federation;
        paramsGroupeFede['federation'] = modelParent.federation.id_federation;
      }

      if (modelParent.association) {
        paramsGroupeAsso['association'] = modelParent.association.id_association;
      }

      forkJoin(
        [
          this.groupeService.getGroupesByParams(paramsGroupeFede, false, true, true),
          this.groupeService.getGroupesByParams(paramsGroupeAsso, false, true, true)
        ]
      ).subscribe(([groupesFede, groupesAsso]) => {
        let groupesList = groupesFede.concat(groupesAsso);
        this.groupesList = groupesList;
      });
    }
  }

  submitAction() {
    if (this.action.isEvaluation() && this.formMode === 'create') { // Si c'est une creation d'evaluation, on regarde d'abord si il y a une evaluation deja en cours
      let filtreEvaluation = new FiltreEvaluation();
      if (this.action.isAlerte()) {
        if (this.action.alerte.appreciation && this.action.alerte.appreciation.client_id_philia) {
          filtreEvaluation.client_id_philia = this.action.alerte.appreciation.client_id_philia;
        } else if (this.action.alerte.client_id_philia) {
          filtreEvaluation.client_id_philia = this.action.alerte.client_id_philia;
        }
      } else {
        filtreEvaluation.client_id_philia = this.action.echange.personne_sujet_id_philia;
      }

      filtreEvaluation.type_public = [this.actionForm.value.type_public];

      let statutEvaluationFiltreList = [];
      this.statutsEvaluationActionList.forEach(function (statutsEvaluationAction) { // On filtre les evaluations sur celles qui ne sont pas archivées
        if (statutsEvaluationAction.code_parametre !== 'ARC'
        && statutsEvaluationAction.code_parametre !== 'EDI'
        && statutsEvaluationAction.code_parametre !== 'F') {
          statutEvaluationFiltreList.push(statutsEvaluationAction);
        }
      });
      filtreEvaluation.statut_evaluation = statutEvaluationFiltreList;

      this.loading = true;
      this.evaluationService.getEvaluationsByParams(filtreEvaluation, false)
      .subscribe((evaluationsEntete: EvaluationEntete[]) => {
        this.loading = false;
        evaluationsEntete = plainToClass(EvaluationEntete, evaluationsEntete);
        if (evaluationsEntete.length > 0) { // Il existe déjà une evaluation non archivée
          if (evaluationsEntete[0].statut_evaluation.code_parametre !== 'E') {
            let messageConfirmationEvaluation = 'Il existe déjà une évaluation dont le statut est "' + evaluationsEntete[0].statut_evaluation.libelle + '" pour ce client de type "'
            + this.actionForm.value.type_public.libelle + '"';
            if (evaluationsEntete[0].date_evaluation) {
              messageConfirmationEvaluation += ' en date du ' + Globals.formatDate(evaluationsEntete[0].date_evaluation);
            }

            let questionConfirmation = '.\n\nSouhaitez vous archiver cette évaluation et en créer une nouvelle ?';
            if (evaluationsEntete[0].statut_evaluation.code_parametre === 'O') {
              questionConfirmation = '.\n\nSouhaitez vous annuler cette évaluation et en créer une nouvelle ?';
            }
            messageConfirmationEvaluation += questionConfirmation;

            this.confirmationService.confirm({
              message: messageConfirmationEvaluation,
              accept: () => {
                this.saveAction();
              }
          });
          } else {
            this.showPopupEvaluation = true;
          }
        } else {
          this.saveAction();
        }
      });
    } else {
      this.saveAction();
    }
  }

  saveAction() {
    if (this.actionForm !== null && this.actionForm !== undefined) {
      this.actionForm.patchValue({save_before_close: true});
    }

    // Ajout du tampon
    let now = new Date().toLocaleString();
    let flag = '\n[Modifié par ' + this.currentUtilisateur.login + ' le ' + now + ']\n';
    let description_action_old = this.actionForm.value.description_action;
    this.actionForm.patchValue({description_action: description_action_old + flag});

    let actionValue = plainToClass(Action, this.actionForm.value as Object);

    if ((this.formMode === 'edit' || this.saveNew) && ((this.action.echange && this.action.echange.id_echange) || (this.action.alerte && this.action.alerte.id_alerte))) {
      // Si echange/alerte existant, on enregistre directement l'action
      if (this.action.echange && this.action.echange.id_echange) {
        actionValue.id_echange = this.action.echange.id_echange;
      } else if (this.action.alerte && this.action.alerte.id_alerte) {
        actionValue.id_alerte = this.action.alerte.id_alerte;
      }

      this.loading = true;
      if (this.actionIsNew) {
        this.actionService.createAction(actionValue)
      .subscribe(
        (action: Action) => {
          this.loading = false;
          this.messageService.add({severity: 'success', summary: 'Succès', detail: 'Action créée avec succès.'});
          if ( this.formMode === 'create') {
            this.actionCreated.emit(plainToClass(Action, action));
          } else {
            this.actionUpdated.emit(plainToClass(Action, action));
          }
        },
        error => {
          this.loading = false;
          console.log(error);
          this.messageService.add({severity: 'error', summary: 'Erreur', detail: 'Erreur dans la création de l\'action.'});
        });
      } else {
        this.loading = true;
        this.actionService.updateAction(actionValue)
      .subscribe(
        (action: Action) => {
          this.loading = false;
          this.messageService.add({severity: 'success', summary: 'Succès', detail: 'Action mise à jour avec succès.'});
          this.actionUpdated.emit(plainToClass(Action, action));
        },
        error => {
          this.loading = false;
          console.log(error);
          this.messageService.add({severity: 'error', summary: 'Erreur', detail: 'Erreur dans la mise à jour de l\'action.'});
        });
      }
    } else if (this.formMode === 'edit') {
      this.actionUpdated.emit(plainToClass(Action, actionValue));
    } else {
      this.actionCreated.emit(plainToClass(Action, actionValue));
    }

    // Envoi d'un email ?
    if (this.actionForm.value.send_mail_responsable && this.actionForm.value.statut_action && this.actionForm.value.statut_action.code_parametre !== 'F') {
      this.sendMailResponsable();
    }
  }

  cancelAction() {/*
    if (this.actionIsNew) {
      // let actionsForm = this.echangeForm.get('actions') as FormArray;
      // actionsForm.removeAt(actionsForm.length - 1);
      this.cancel.emit('new');
    } else {
      this.actionForm.reset();
    }*/
    this.cancel.emit(this.formMode);
    // this.showPopupAction = false;
  }

  copyDescriptionInAction() {
    if (this.action.isAlerte()) {
      this.actionForm.patchValue({description_action: this.action.alerte.description});
    } else {
      this.actionForm.patchValue({description_action: this.action.echange.description});
    }
  }

  onCloseActionPopup() {
    if (this.actionForm !== null && this.actionForm !== undefined && this.actionIsNew) {
      if (!this.actionForm.controls['save_before_close'].value) {
        this.actionForm.patchValue({save_before_close: false});
      }
    }
    this.cancelAction();
  }

  sendMailResponsable() {
    let mailActionTemplate = '';
    if (this.action.isAlerte()) {
      mailActionTemplate = Globals.mailActionAlerteTemplate;
    } else {
      mailActionTemplate = Globals.mailActionTemplate;
    }

    let textEmail = mailActionTemplate.replace(/@\(([^)]+)?\)/g, (match, key /*, value*/) => {
      const defaultData = ' - ';
      let data = defaultData;
      if (key === 'action.echeance') {
          data = this.actionForm.value.echeance ? Globals.formatDate(this.actionForm.value.echeance) : '';
      } else if ( key === 'action.objet_action' && this.actionForm.value.objet_action !== null) {
          data = this.actionForm.value.objet_action;
          data = data.substr(0, 50) + (data.length > 50 ? '[...]' : '');
      } else if ( key === 'action.description_action' && this.actionForm.value.description_action !== null) {
          data = this.actionForm.value.description_action;
          data = data.substr(0, 50) + (data.length > 50 ? '[...]' : '');
      } else if ( key === 'action.echange.description' && this.action.echange.description !== null) {
          data = this.action.echange.description;
          data = data.substr(0, 50) + (data.length > 50 ? '[...]' : '');
      } else if ( key === 'action.echange.personne_sujet_civilite.libelle' && this.action.echange.personne_sujet_civilite !== null) {
          data = this.action.echange.personne_sujet_civilite.libelle;
      } else if ( key === 'action.echange.personne_sujet_nom') {
          data = this.action.echange.personne_sujet_nom;
      } else if ( key === 'action.echange.personne_sujet_prenom') {
          data = this.action.echange.personne_sujet_prenom ? this.action.echange.personne_sujet_prenom : '';
      } else if ( key === 'action.echange.personne_sujet_societe') {
          data = this.action.echange.personne_sujet_societe;
      } else if ( key === 'action.echange.personne_sujet_tel_domicile') {
          data = this.action.echange.personne_sujet_tel_domicile;
      } else if ( key === 'action.echange.personne_sujet_tel_travail') {
          data = this.action.echange.personne_sujet_tel_travail;
      } else if ( key === 'action.echange.personne_sujet_tel_mobile') {
          data = this.action.echange.personne_sujet_tel_mobile;
      } else if (key === 'action.echange.created') {
          data = Globals.formatDate(this.action.echange.created);
      } else if ( key === 'action.echange.categorie_echange.libelle' && this.action.echange.categorie_echange !== null) {
          data = this.action.echange.categorie_echange.libelle;
      } else if ( key === 'action.echange.motif_echange.libelle' && this.action.echange.motif_echange) {
          data = this.action.echange.motif_echange.libelle;
      } else if ( key === 'action.echange.association.libelle' && this.action.echange.association.libelle !== null) {
          data = this.action.echange.association.libelle;
      } else if ( key === 'action.echange.id_echange' && this.action.echange !== null) {
        data = this.action.echange.id_echange.toString();
      } else if ( key === 'action.alerte.client_civilite.libelle' && this.action.alerte.client_civilite.libelle !== null) {
        data = this.action.alerte.client_civilite.libelle;
      } else if ( key === 'action.alerte.client_nom') {
        data = this.action.alerte.client_nom;
      } else if ( key === 'action.alerte.client_prenom') {
          data = this.action.alerte.client_prenom ? this.action.alerte.client_prenom : '';
      } else if (key === 'action.alerte.date_creation') {
        data = Globals.formatDate(this.action.alerte.date_creation);
      } else if ( key === 'action.alerte.motif_alerte.libelle' && this.action.alerte.motif_alerte && this.action.alerte.motif_alerte.libelle) {
        data = this.action.alerte.motif_alerte.libelle;
      } else if ( key === 'action.alerte.association.libelle' && this.action.alerte.association.libelle !== null) {
        data = this.action.alerte.association.libelle;
      } else if ( key === 'action.alerte.description' && this.action.alerte.description !== null) {
        data = this.action.alerte.description;
        data = data.substr(0, 50) + (data.length > 50 ? '[...]' : '');
      } else if ( key === 'action.alerte.id_alerte' && this.action.alerte !== null) {
        data = this.action.alerte.id_alerte.toString();
      }

      if (data === null || data === undefined) {
        data = defaultData;
      }

      return data;
    });

    let mailtoURL = '';
    let mail = '';
    if (this.actionForm.value.responsable && this.actionForm.value.responsable.mail) {
      mail = this.actionForm.value.responsable.mail + ';';
    }

    if (this.actionForm.value.groupes && this.actionForm.value.groupes.length > 0) {
      this.actionForm.value.groupes.forEach(function (groupe) {
        if (groupe.mail) {
            mail += groupe.mail + ';';
        } else {
          groupe.utilisateurs.forEach(function (utilisateur) {
            if (utilisateur.mail) {
                mail += utilisateur.mail + ';';
            }
          });
        }
      });
    }

    mailtoURL += 'mailto:' + mail;
    mailtoURL += '?subject=' + encodeURIComponent(Globals.mailActionObjet);
    mailtoURL += '&body=' + encodeURIComponent(textEmail);

    let link = document.createElement('a');
    link.target = '_blank';
    link.href = mailtoURL;


    if (document.createEvent) {
        let event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        link.dispatchEvent(event);
    } else {
        link.click();
    }
  }

  goToEvaluation() {
    this.router.navigate(['/in/evaluation/' + this.action.evaluation_entete.id_evaluation_entete]);
  }

  get actionIsClosed() {
    // Action en lecture seul si action/échange fermé
    let isClosed = false;
    if (this.actionForm !== null && this.actionForm !== undefined) {
      if (this.statutsActionInitial && this.statutsActionInitial.code_parametre === 'F') {
        isClosed = true;
      }
    }

    if (this.action.echange !== null && this.action.echange !== undefined) {
      if (this.action.echange.statut_echange !== null && this.action.echange.statut_echange.code_parametre === 'F') {
        isClosed = true;
      }
    }

    // Vérification des droits
    if (!isClosed) {
      if ((this.action.isEvaluation() && !this.currentUtilisateur.hasFonctionnalite(DroitModule.module_echanges, DroitModule.fonctionnalite_creation_evaluations))) {
        isClosed = true ;
      }
    }
    return isClosed;
  }

  getClassButton() {
    if (this.action.isAlerte()) {
      return 'pink-button';
    } else {
      return 'green-button';
    }
  }

  getClassDropdown() {
    if (this.action.isAlerte()) {
      return 'alerteDropdown';
    } else {
      return 'echangeDropdown';
    }
  }

  getLabelCopyDescription() {
    if (this.action.isAlerte()) {
      return 'Copier le descriptif de l\'alerte';
    } else {
      return 'Copier le descriptif de l\'échange';
    }
  }

  get actionIsNew() {
    if (this.actionForm !== null && this.actionForm !== undefined) {
      if (this.actionForm.controls['id_action'].value === null || this.actionForm.controls['id_action'].value === undefined) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  get showResponsableMail() {
    if (this.actionIsClosed) {
      return false;
    }

    if (this.actionForm.controls['groupes'] !== null && this.actionForm.controls['groupes'].value !== null
        && this.actionForm.controls['groupes'].value.length > 0) {
      return true;
    } else if (this.actionForm !== null && this.actionForm !== undefined
      && this.actionForm.controls['responsable'] !== null && this.actionForm.controls['responsable'].value !== null) {
      let id_responsable = this.actionForm.controls['responsable'].value.id_utilisateur;
      let id_user = this.currentUtilisateur.id_utilisateur;
      let email_responsable = this.actionForm.controls['responsable'].value.mail;

      return id_responsable !== undefined && email_responsable !== undefined && email_responsable !== '' && id_responsable !== id_user;
    }

    return false;
  }

  get showResponsableMailLabel() {
    let label = '';
    if (this.actionForm !== null && this.actionForm !== undefined) {
      if (this.actionForm.controls['groupes'] !== null && this.actionForm.controls['groupes'].value !== null
        && this.actionForm.controls['groupes'].value.length > 0) {
          label = 'Envoyer un email aux observateurs et à l\'acteur';
        } else if (this.actionForm.controls['responsable'] !== null && this.actionForm.controls['responsable'].value !== null) {
        let responsableNom = this.actionForm.controls['responsable'].value.prenom_nom;
        let responsableMail = this.actionForm.controls['responsable'].value.mail;
        label = 'Envoyer un email à l\'acteur (' + responsableNom + ' / ' + responsableMail + ')';
      }
    }
    return label;
  }

  get popupActionTitle() {
    if (this.action.isEvaluation()) {
      if (this.formMode === 'create') {
        return 'Nouvelle Evaluation';
      } else {
        return 'Editer l\'évaluation';
      }
    }

    if (this.formMode === 'create') {
      return 'Nouvelle Action';
    } else {
      return 'Editer l\'action';
    }
  }

  get acteurLabel() {
    if (this.action.isEvaluation()) {
      return 'Evaluateur';
    }
    return 'Acteur';
  }

  get saveActionDisabled() {
    if (!this.actionForm.valid) {
      return true;
    } else if (this.action.is_evaluation) {
      if (!this.currentUtilisateur.hasFonctionnalite(DroitModule.module_echanges, 'init_evaluations')) {
        return true;
      } else if ((this.formMode === 'create' && !this.currentUtilisateur.hasFonctionnalite(DroitModule.module_echanges, 'init_evaluations'))
                || (this.formMode === 'edit'
                    && (!this.currentUtilisateur.hasFonctionnalite(DroitModule.module_echanges, 'init_evaluations')
                        && !this.currentUtilisateur.hasFonctionnalite(DroitModule.module_echanges, 'init_evaluations')))) {
        return true;
      }
    } else if (this.action.isAlerte()) {
      if (!this.currentUtilisateur.hasAccesAlertes
        || !this.currentUtilisateur.hasFonctionnalite(DroitModule.module_vigilance, 'init_evaluations')) {
        return true;
      }
    } else if (this.action.echange) {
      if (!this.currentUtilisateur.acces_echanges
        || !this.currentUtilisateur.hasFonctionnalite('main_courante', 'edit_actions')) {
        return true;
      }
    }
    return false;
  }

}
