import {classToPlain, Type} from 'class-transformer';
import {Agence} from './agence';
import {Federation} from './federation';
import {Groupe} from './groupe';
import {Parametre} from './parametre';
import {Utilisateur} from './utilisateur';

export class Association {
  id_association: number;
  code: string;
  libelle: string;
  id_philia: string;
  id_modus: string;

  @Type(() => Federation)
  federation: Federation;

  telephone: string;
  is_personia = false;
  mail: string;
  mail_personia: string;
  mail_devis: string;
  signature_mail: string;
  // tslint:disable-next-line:max-line-length
  jours_horaires_ouverture: string;
  jours_horaires_fermeture: string;
  id_modus_association?: number;
  id_modus_federation?: number;

  @Type(() => Utilisateur)
  contact: Utilisateur;

  @Type(() => Utilisateur)
  contact_urgence: Utilisateur;

  @Type(() => Parametre)
  services: Parametre[];

  agence_reference: Agence;

  disabled: boolean;

  @Type(() => Groupe)
  groupe_personia: Groupe;

  static getFromOrganisation(org) {
    if (org) {
      let association = new Association();
      association.id_association = org.orgIdFonctionnel;
      association.libelle = org.orgNom;
      association.id_philia = org.id_philia;
      association.id_modus = org.id_modus;

      return association;
    } else {
      return null;
    }
  }

  serialize() {
    let serializeObject = classToPlain(this);
    delete serializeObject['id_association'];

    if (this.federation !== null && this.federation !== undefined) {
      serializeObject['federation'] = this.federation.id_federation;
    }

    if (this.contact !== null && this.contact !== undefined) {
      serializeObject['contact'] = this.contact.id_utilisateur;
    }

    if (this.contact_urgence !== null && this.contact_urgence !== undefined) {
      serializeObject['contact_urgence'] = this.contact_urgence.id_utilisateur;
    }

    serializeObject['services'] = [];
    if (this.services !== null && this.services !== undefined && this.services.length > 0) {
      this.services.forEach(function (service) {
        serializeObject['services'].push(service.id_parametre);
      });
    }

    if (this.agence_reference !== null && this.agence_reference !== undefined) {
      serializeObject['agence_reference'] = this.agence_reference.id_agence;
    }

    if (this.groupe_personia !== null && this.groupe_personia !== undefined) {
      serializeObject['groupe_personia'] = this.groupe_personia.id_groupe;
    }

    if (this.telephone && this.telephone === '__ __ __ __ __') {
      serializeObject['telephone'] = null;
    }

    return serializeObject;
  }
}
