import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import {plainToClass} from 'class-transformer';

import {SelectItem} from 'primeng/api';

import { Parametre } from '../_models';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ParametreService {

    constructor(private http: HttpClient) { }

  /** GET parametres */
  getAll(): Observable<Parametre[]> {
    return this.http.get<Parametre[]>(`${environment.apiHost}/${environment.apiNamespace}/parametres`).pipe(
      tap(_ => console.log(`fetched parametres`)),
      catchError(this.handleError)
    );
  }

  /** GET parametres */
  getParametresByParams(paramsValue: any): Observable<Parametre[]> {
    let params = new HttpParams();
    for (let paramKey in paramsValue) {
      if (paramsValue.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValue[paramKey]);
      }
    }
    return this.http.get<Parametre[]>(`${environment.apiHost}/${environment.apiNamespace}/parametres`, { params: params });
  }

   /** GET parametres/:id */
   getParametre(id_parametre: number): Observable<Parametre> {
    return this.http.get<Parametre>(`${environment.apiHost}/${environment.apiNamespace}/parametres/${id_parametre}`);
  }

  /** POST parametres */
  createParametre(parametre: Parametre): Observable<Parametre> {
    return this.http.post<any>(`${environment.apiHost}/${environment.apiNamespace}/parametres`,
    plainToClass(Parametre, parametre).serialize());
  }

  /** PUT parametres */
  updateParametre(parametre: Parametre): Observable<Parametre> {
    let id_parametre = parametre.id_parametre;
    return this.http.put<any>(`${environment.apiHost}/${environment.apiNamespace}/parametres/${id_parametre}`,
    plainToClass(Parametre, parametre).serialize());
  }

  /** DELETE parametres */
  removeParametre(id_parametre: number): Observable<Parametre> {
    return this.http.delete<any>(`${environment.apiHost}/${environment.apiNamespace}/parametres/${id_parametre}`);
  }

  getParametreTypes(): SelectItem[] {
    let types = [];
    types.push({label: 'Type d\'échange', value: 'TYPE'});
    types.push({label: 'Motif échange', value: 'MOTIF'});
    types.push({label: 'Nature du contact', value: 'NATURE'});
    types.push({label: 'Lien (tiers / personne concernée)', value: 'LIEN'});
    types.push({label: 'Type de voie', value: 'TVOIE'});
    types.push({label: 'Civilité', value: 'CIVILITE'});
    types.push({label: 'Statut d\'échange', value: 'STATUT_E'});
    types.push({label: 'Statut d\'action', value: 'STATUT_A'});
    types.push({label: 'Catégorie', value: 'CATEGORIE'});
    types.push({label: 'Période modification échange', value: 'PERIODE_MODIF_E'});
    types.push({label: 'Préférence de contact', value: 'CONTACT_PREFERENCE'});
    types.push({label: 'Service', value: 'SERVICE'});
    types.push({label: 'Type de droit', value: 'TYPE_DROIT'});
    types.push({label: 'Type de public', value: 'TYPE_PUBLIC'});
    types.push({label: 'Statut évaluation', value: 'STATUT_EVA'});
    types.push({label: 'Activité', value: 'ACTIVITE'});
    types.push({label: 'Activité ménage / linge', value: 'ACTIVITE_MENAGE_LINGE'});
    types.push({label: 'Activité jardinage / bricolage', value: 'ACTIVITE_JARDINAGE_BRICOLAGE'});
    types.push({label: 'Opération aide à la personne', value: 'OPERATION_AIDE_PERSONNE'});
    types.push({label: 'Opération jardinage', value: 'OPERATION_JARDINAGE'});
    types.push({label: 'Opération accompagnement / présence', value: 'OPERATION_ACCOMPAGNEMENT_PRESENCE'});
    types.push({label: 'Opération hygiène', value: 'OPERATION_HYGIENE'});
    types.push({label: 'Opération mobilité intérieure', value: 'OPERATION_MOBILITE_INTERIEURE'});
    types.push({label: 'Opération aide à la toilette', value: 'OPERATION_AIDE_TOILETTE'});
    types.push({label: 'Opération Chambre', value: 'OPERATION_CHAMBRE'});
    types.push({label: 'Opération Cuisine', value: 'OPERATION_CUISINE'});
    types.push({label: 'Opération SdB', value: 'OPERATION_SDB'});
    types.push({label: 'Opération toutes pièces', value: 'OPERATION_TOUTES_PIECES'});
    types.push({label: 'Opération entretien du linge', value: 'OPERATION_ENTRETIEN_LINGE'});
    types.push({label: 'Opération alimentation', value: 'OPERATION_ALIMENTATION'});
    types.push({label: 'Opération bricolage', value: 'OPERATION_BRICOLAGE'});
    types.push({label: 'Opération gardiennage', value: 'OPERATION_GARDIENNAGE'});
    types.push({label: 'Opération problèmes de santé', value: 'OPERATION_PROBLEMES_SANTE'});
    types.push({label: 'Pièce', value: 'PIECE'});
    types.push({label: 'Equipement ménage / linge', value: 'EQUIPEMENT_MENAGE_LINGE'});
    types.push({label: 'Equipement aide à la personne', value: 'EQUIPEMENT_AIDE_PERSONNE'});
    types.push({label: 'Equipement jardinage / bricolage', value: 'EQUIPEMENT_JARDINAGE_BRICOLAGE'});
    types.push({label: 'Equipement alimentation', value: 'EQUIPEMENT_ALIMENTATION'});
    types.push({label: 'Equipement enfance aide à la personne', value: 'EQUIPEMENT_ENFANCE_AIDE_PERSONNE'});
    types.push({label: 'Equipement enfance alimentation', value: 'EQUIPEMENT_ENFANCE_ALIMENTATION'});
    types.push({label: 'Equipement enfance accompagnement / présence', value: 'EQUIPEMENT_ENFANCE_ACCOMPAGNEMENT_PRESENCE'});
    types.push({label: 'Equipement enfance ménage / linge', value: 'EQUIPEMENT_ENFANCE_MENAGE_LINGE'});
    types.push({label: 'Jour', value: 'JOUR'});
    types.push({label: 'Type de rue', value: 'TYPE_RUE'});
    types.push({label: 'Représentant', value: 'REPRESENTANT'});
    types.push({label: 'Spécialité', value: 'SPECIALITE'});
    types.push({label: 'Civilité Pro', value: 'CIVILITE_PRO'});
    types.push({label: 'Unité de temps', value: 'UNITE'});
    types.push({label: 'Paiement', value: 'PAIEMENT'});
    types.push({label: 'Canal', value: 'CANAL'});
    types.push({label: 'Motif évaluation', value: 'MOTIF_EVALUATION'});
    types.push({label: 'Rythme', value: 'RYTHME'});
    types.push({label: 'Période', value: 'PERIODE'});
    types.push({label: 'Centres d\'intérêts', value: 'CENTRES'});
    types.push({label: 'Spécialité enfance', value: 'SPECIALITE_ENFANCE'});
    types.push({label: 'Organisme financeur type d\'aide', value: 'ORGANISME_FINANCEUR_TYPE_AIDE'});
    types.push({label: 'Organisme financeur autre type d\'aide', value: 'ORGANISME_FINANCEUR_TYPE_AIDE_AUTRE'});
    types.push({label: 'Domaine', value: 'DOMAINE'});
    types.push({label: 'Type autonomie appareillages existants', value: 'TYPE_AUTONOMIE_APPAREILLAGES_EXISTANTS'});
    types.push({label: 'Type autonomie handicap', value: 'TYPE_AUTONOMIE_HANDICAP'});
    types.push({label: 'Type autonomie problèmes de santé', value: 'TYPE_AUTONOMIE_PROBLEMES_SANTE'});
    types.push({label: 'Motif Personia', value: 'MOTIF_PERSONIA'});
    types.push({label: 'Mail Personia', value: 'MAIL_PERSONIA'});

    // On trie par ordre alphabétique
    types.sort(function(a, b) {
      if (a.label < b.label) { return -1; }
      if (a.label > b.label) { return 1; }
      return 0;
  });

    return types;
  }

  /* handleError */
  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(error);
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a utilisateur-facing error message
    return throwError(
      error);
  }

}
