import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';
import {plainToClass} from 'class-transformer';
import {Parametre} from '../_models/parametre';

export const echangeValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {

  let valid = true;
  let errors = null;

  if (control && control.get('statut_echange') && control.get('statut_echange').value) {
    const statut_echange = plainToClass(Parametre, control.get('statut_echange').value as Object);
    const actions = control.get('actions');

    // Si le statut de l'echange est fermé et qu'il y a au moins une action ouverte => Invalide
    if (statut_echange.code_parametre === 'F' && actions.value.length > 0) {
      actions.value.forEach(function(action) {
        if (action.statut_action.code_parametre === 'E') {
          valid = false;
          errors = {actionOpen: true};
        }
      });
    }
  }

  /*
  if (valid) {
    if (control && control.get('personne_sujet_nature') && control.get('personne_sujet_nature').value) {
      if (control.get('personne_sujet_nature').value.code_parametre === 'PRO') {
        // Si Prospect, il faut au moins un numero de telephone
        if (control && (control.get('personne_sujet_tel_domicile').invalid || !control.get('personne_sujet_tel_domicile').value)
        && (control.get('personne_sujet_tel_mobile').invalid || !control.get('personne_sujet_tel_mobile').value )
        && (control.get('personne_sujet_tel_travail').invalid || !control.get('personne_sujet_tel_travail').value )) {
          valid = false;
          errors = {onePhoneRequired: true};
        }
      }
    }
  }
  */

  return valid ? null : errors;
};

@Directive({
  selector: '[appEchangeValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: EchangeValidatorDirective, multi: true}]
})
export class EchangeValidatorDirective implements Validator {

  constructor() { }

  public validate(control: FormGroup): {[key: string]: any} {
    return echangeValidator(control);
  }

}
