import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { plainToClass } from 'class-transformer';

import * as FileSaver from 'file-saver';
import * as moment from 'moment';

import { ConfirmationService, MenuItem, MessageService, SelectItem } from 'primeng/api';
import { forkJoin, Observable } from 'rxjs';
import { echangeValidator } from '../../shared/_directives/echange-validator.directive';
import { emailValidator } from '../../shared/_directives/email-validator.directive';
import { phoneValidator } from '../../shared/_directives/phone-validator.directive';
import {
  Action,
  Agence,
  Association,
  Commune,
  DocumentEchange,
  DroitModule,
  Echange,
  EvaluationEntete,
  Federation,
  Individu,
  Parametre,
  Utilisateur
} from '../../shared/_models';

import {
  ActionService,
  AgenceService,
  AssociationService,
  AuthenticationService,
  CommuneService,
  DocumentEchangeService,
  EchangeService,
  ExportService,
  FederationService,
  IndividuService,
  ParametreService,
  UtilisateurService
} from '../../shared/_services';
import { FederationMotifsService } from 'src/app/shared/_services/federation-motifs.service';
import { FiltreIndividu } from '../../shared/utils/filtre-individu';

import { Globals } from '../../shared/utils/globals';
import { FederationMarqueur } from 'src/app/shared/_models/federation-marqueur';
import { EchangeMarqueursService } from 'src/app/shared/_services/echange-marqueurs.service';
import { OngletService } from '../../shared/_services/onglet.service';

@Component({
  selector: 'app-echange-form',
  templateUrl: './echange-form.component.html',
  styleUrls: ['./echange-form.component.css'],
  providers: [ConfirmationService]
})
export class EchangeFormComponent implements OnInit, OnDestroy, AfterContentChecked {

  @Input()
  echange: Echange = new Echange(); // propriété d'entrée du composant : l'echange a editer

  @Input() formMode: string; // propriété d'entrée du composant : le mode du formulaire (edit ou create)

  @Output()
  create = new EventEmitter<any>();

  @Output()
  update = new EventEmitter<any>();

  @Output()
  actionReopened = new EventEmitter<any>();

  isPersonneEditable = true;
  isQualificationEditable = true;
  isEditableAfterDateLimit = true;
  dateLimitEditable: Date;

  datePipeFormat = Globals.datePipeFormat;
  datePipeFormatWithoutTime = Globals.datePipeFormatWithoutTime;
  dateFormat = Globals.dateFormat;
  locale = Globals.dateLocale;

  currentUtilisateur: Utilisateur;
  echangeForm: FormGroup;
  actionForm: FormGroup;

  periodeModif: number;
  civilitesList: Parametre[];
  naturesList: Parametre[];
  naturesTiersConcerneList: Parametre[];
  categoriesList: Parametre[];
  servicesList: Parametre[];
  statutsEchangeList: Parametre[];
  statutsActionList: Parametre[];
  statutsEvaluationActionList: Parametre[];
  typesEchangeList: SelectItem[];
  motifsEchangeList: Parametre[];
  motifsDevisList: Parametre[];
  motifsReclamationsAssociationList: Parametre[];
  motifsReclamationsInterventionList: Parametre[];
  motifsRemonteeInformationsList: Parametre[];
  finalitesDevisList: Parametre[];
  liensList: Parametre[];
  complementsAdresseList: SelectItem[];
  communesList: Commune[];
  communesTiersList: Commune[];
  communesTiersConcerneList: Commune[];
  federationsList: Federation[];
  associationsList: Association[];
  associationsItemsList: SelectItem[];
  agencesList: Agence[];
  agencesItemsList: SelectItem[];
  organismesFinanceursList: Parametre[];
  typesAidesList: Parametre[];
  risquesSalarieList: Parametre[];
  risquesClientList: Parametre[];
  gravitesList: Parametre[];
  frequencesList: Parametre[];

  submittingEchange = false;
  loadingIndividus = false;
  showPopupIndividus = false;
  showPopupIndividusSujet = false;
  showPopupIndividusTiers = false;
  showPopupIndividusTiersConcerne = false;
  isNewIndividu = false;
  createProspect = false;
  individusList: Individu[];
  responsablesList: Utilisateur[];

  statutOuvert: Parametre;
  statutAstreinte: Parametre;

  actionToEdit: Action;
  showPopupAction = false;
  actionFormMode: string;
  popupActionTitle = 'Nouvelle Action';
  indexSelectedAction: number;
  isTiersConcerneShow = false;
  exportItems: MenuItem[];
  agenceRequired = false;

  navigationSubscription;

  maxDateEchange = new Date();

  documentEchangeForm: FormGroup;
  showPopupAjoutDocument = false;
  documentEnregistre = false;
  currentImageSrc: any;
  currentImageExtension: string;
  currentFilename: string;
  selectedMarqueurs: FederationMarqueur[] = [];
  showHistorique = false;
  confirmationFermetureOnglet = false;
  itemsContextMenu: MenuItem[];
  selectedAction: Action;
  displayReactivateEchangeModal: boolean = false;
  motifReactivateAction: string;
  loadingAnnuaireExterne: boolean = false;
  displayAnnuaireExterneModal: boolean = false;
  listeAnnuaireExt: Observable<any> = null;
  selectAnnuaireExtTmp = null;
  expandedRows: { [key: string]: boolean } = {};
  idMotifSupprime: number;

  constructor(
    private cdRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private echangeService: EchangeService,
    private actionService: ActionService,
    private utilisateurService: UtilisateurService,
    private federationService: FederationService,
    private associationService: AssociationService,
    private agenceService: AgenceService,
    private parametreService: ParametreService,
    private communeService: CommuneService,
    private individuService: IndividuService,
    private exportService: ExportService,
    private documentEchangeService: DocumentEchangeService,
    private federationMotifsService: FederationMotifsService,
    private echangeMarqueurService: EchangeMarqueursService,
    private ongletService: OngletService
  ) {
    // Rechargement de la page lorsqu'on navigue sur un autre echange
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.ngOnInit();
      }
    });
  }

  get actionIsClosed() {
    // Action en lecture seul si action/échange fermé
    let isClosed = false;
    if (this.actionForm !== null && this.actionForm !== undefined) {
      if (this.actionForm.controls['statut_action'].value !== null && this.actionForm.controls['statut_action'].value.code_parametre === 'F') {
        isClosed = true;
      }
    }

    if (this.echangeForm !== null && this.echangeForm !== undefined) {
      if (this.echangeForm.controls['statut_echange'].value !== null && this.echangeForm.controls['statut_echange'].value.code_parametre === 'F') {
        isClosed = true;
      }
    }

    // Cas particulier responsable, l'action n'est pas editable si elle ne lui est pas affectée
    if (!isClosed) {
      if (this.actionForm.controls['responsable'].value.id_utilisateur !== this.currentUtilisateur.id_utilisateur) {
        isClosed = true;
      }
    }
    return isClosed;
  }

  get actionIsNew() {
    if (this.actionForm !== null && this.actionForm !== undefined) {
      if (this.actionForm.controls['id_action'].value === null || this.actionForm.controls['id_action'].value === undefined) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  get canAddAction() {
    // Si pas les droits d'édition/création, impossible d'ajouter une action
    if (!this.currentUtilisateur.hasFonctionnalite(DroitModule.module_echanges, DroitModule.fonctionnalite_edit_echanges)
      && !this.currentUtilisateur.hasFonctionnalite(DroitModule.module_echanges, DroitModule.fonctionnalite_edit_actions)) {
      return false;
    } else if (this.echangeForm.controls['federation'].valid && this.echangeForm.controls['association'].valid
      && (this.formMode === 'create' || this.echangeForm.controls['isStatutOpen'].value)) {
      return true;
    }

    return false;
  }

  get canAddActionEvaluation() { // Si le client a un Id Philia et que sa nature est Client, Tiers Client ou Prospect alors on peut creer une evaluation
    if (this.canAddAction && this.echangeForm.controls['personne_sujet_id_philia'].value
      && this.echangeForm.controls['personne_sujet_nature'].value
      && (this.echangeForm.controls['personne_sujet_nature'].value.code_parametre === 'CLI'
        || this.echangeForm.controls['personne_sujet_nature'].value.code_parametre === 'PRO'
        || this.echangeForm.controls['personne_sujet_nature'].value.code_parametre === 'TIE')
      && this.currentUtilisateur.hasFonctionnalite('main_courante', 'init_evaluations')) {
      return true;
    }
    return false;
  }

  get canAddTiersConcerne() {
    // Si pas les droits d'édition/création, on désactive le bouton
    if (!this.currentUtilisateur.hasFonctionnalite(DroitModule.module_echanges, DroitModule.fonctionnalite_edit_echanges)) {
      return false;
    }

    return true;
  }

  /*Permet de savoir si on a toutes les informations pour créer un prospect
    01A La création de prospect n’est possible que si l’utilisateur a procédé à une « Recherche PHILIA »
    01B La création de prospect n’est possible que si l’utilisateur n’a sélectionné personne dans les résultats issus de la « Recherche PHILIA »
    01C La création de prospect n’est possible que si les informations suivantes sont renseignées :
    • NOM
    • PRENOM
    • Un numéro de téléphone (Domicile ou portable ou professionnel) ou l’adresse mail
    • Fédération
    • Association
    01D La création du prospect n’est possible que si la nature dans la qualification de l’échange est « prospect ».
    01E La case à cocher n’apparait que si les règles 01A, 01B, 01C et 01D sont respectées.
    */
  get canCreateProspect() {
    if (this.echangeForm !== null && this.echangeForm !== undefined) {
      if (!Globals.isNullOrUndefined(this.echangeForm.value.personne_sujet_nature)) {
        if (this.echangeForm.value.personne_sujet_nature.code_parametre === 'PRO') { // Si nature = prospect
          if (Globals.isEmpty(this.echangeForm.value.personne_sujet_id_philia) && !Globals.isEmpty(this.echangeForm.value.personne_sujet_nom) && !Globals.isEmpty(this.echangeForm.value.personne_sujet_prenom)) {
            if (!Globals.isNullOrUndefined(this.echangeForm.value.federation) && !Globals.isNullOrUndefined(this.echangeForm.value.association)) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  /**
   * En mode brouillon ou ouvert, la modification de l'astreinte est possible.
   */
  get canEditAstreinte() {
    if (this.echange.statut_echange && (this.echange.statut_echange.code_parametre === 'B' || this.echange.statut_echange.code_parametre === 'O')) {
      return true;
    }
    return false;
  }

  get canEditAgence() {
    let canEditAgence = this.canEditAssociation;

    if (!this.canSaveEchange) {
      canEditAgence = false;
    }

    if (this.formMode === 'create') {
      canEditAgence = true;
    } else if (this.echange.statut_echange && (this.echange.statut_echange.code_parametre === 'A' || this.echange.statut_echange.code_parametre === 'O') && !this.currentUtilisateur.isPersonia) {
      canEditAgence = true;
    } else if (this.echange.statut_echange && this.echange.statut_echange.code_parametre === 'B') {
      canEditAgence = true;
    }

    return canEditAgence;
  }

  get canEditAssociation() {
    let canEditAssociation = false;

    if (!this.canSaveEchange) {
      canEditAssociation = false;
    }

    if (this.formMode === 'create'
      && this.currentUtilisateur.type_acces !== 'monoAsso' && this.currentUtilisateur.type_acces !== 'multiSite'
      && this.currentUtilisateur.perimetre !== 'Agence') {
      canEditAssociation = true;
    } else if (this.echange.categorie_echange && this.echange.categorie_echange.code_parametre === 'DEV'
      && this.echange.type_echange && this.echange.type_echange.code_parametre === 'WEB'
      && !this.echangeHasActions) { // Si Devis sans action, on peut modifier l'asso
      canEditAssociation = true;
    }

    return canEditAssociation;
  }

  get canReopen() {
    if (!this.isClosed || this.formMode === 'create') {
      return true;
    } else if (this.echangeForm !== null && this.echangeForm !== undefined) {
      if ((this.echange.statut_echange && this.echange.statut_echange.code_parametre !== 'F')
        || (this.currentUtilisateur.est_administrateur === 'O' && this.echange.association
          && (this.currentUtilisateur.isInPerimetreAssociation(this.echange.association, this.echange.federation)))) {
        return true;
      }
    }
    return false;
  }

  get canSaveEchange() {
    if (!this.currentUtilisateur.hasFonctionnalite(DroitModule.module_echanges, DroitModule.fonctionnalite_edit_echanges)
      && !this.currentUtilisateur.hasFonctionnalite(DroitModule.module_personia, DroitModule.fonctionnalite_edit_echanges)) {
      return false;
    }
    if (this.echangeForm.valid
      && !this.submittingEchange) {
      return true;
    }
    return false;
  }

  get canSearchIndividuPhilia() {
    // Il faut renseigner la fédération et minimum 3 lettres du nom de la personne recherchée afin de pouvoir effectuer une recherche
    if (this.echangeForm.controls['federation'].valid
      && this.echangeForm.controls['association'].valid
      && this.echangeForm.controls['personne_sujet_nom'].value !== null
      && this.echangeForm.controls['personne_sujet_nom'].value.length >= 3
      && !this.loadingIndividus) {
      return true;
    }
    return false;
  }

  get canSearchAnnuaireExterne() {
    if (this.echangeForm.controls['personne_sujet_societe'].value !== undefined &&
      this.echangeForm.controls['personne_sujet_societe'].value !== null &&
      this.echangeForm.controls['personne_sujet_societe'].value.length >= 3 &&
      this.echangeForm.value.federation != null) {
      return true;
    } else {
      return false;
    }
  }

  get canSearchIndividuTiersConcernePhilia() {
    // Il faut renseigner la fédération et minimum 3 lettres du nom de la personne recherchée afin de pouvoir effectuer une recherche
    if (this.echangeForm.controls['personne_tiers_concerne_nom'].value) {
      if (this.echangeForm.controls['federation'].valid
        && this.echangeForm.controls['personne_tiers_concerne_nom'].value !== null
        && this.echangeForm.controls['personne_tiers_concerne_nom'].value.length >= 3
        && !this.loadingIndividus) {
        return true;
      }
    }
    return false;
  }

  get canSearchIndividuTiersPhilia() {
    // Il faut renseigner la fédération et minimum 3 lettres du nom de la personne recherchée afin de pouvoir effectuer une recherche
    if (this.echangeForm.controls['personne_tiers_nom'].value) {
      if (this.echangeForm.controls['federation'].valid
        && this.echangeForm.controls['personne_tiers_nom'].value !== null
        && this.echangeForm.controls['personne_tiers_nom'].value.length >= 3
        && !this.loadingIndividus) {
        return true;
      }
    }
    return false;
  }

  get canSearchTelephoneAssociation() {
    if (this.currentUtilisateur.perimetre === 'National' && this.currentUtilisateur.isPersonia
      && (!this.echange.statut_echange || (this.echange.statut_echange
        && this.echange.statut_echange.code_parametre !== 'A' && this.echange.statut_echange.code_parametre !== 'O'))) {
      return true;
    }
    return false;
  }

  get canValidEchange() {
    let canValidEchange = ((this.echange.statut_echange.code_parametre === 'B' && this.echangeForm.valid && !Globals.isNullOrUndefined(this.echangeForm.value.agence))
      && (this.currentUtilisateur.id_utilisateur === this.echange.operateur.id_utilisateur || (this.currentUtilisateur.est_administrateur === 'O' && this.currentUtilisateur.hasFonctionnalite(DroitModule.module_echanges, DroitModule.fonctionnalite_edit_echanges)))
      && !this.submittingEchange);
    // || (this.echange.statut_echange.code_parametre === 'A' && !this.currentUtilisateur.isPersonia && this.echangeForm.valid && !this.submittingEchange);


    return canValidEchange;
  }

  get currentKeyEchange(): number {
    let currentKeyEchange = null;
    if (this.echangeService.echangesList !== undefined && this.echangeService.echangesList.length > 0) {
      let echangesRecherche = this.echangeService.echangesList;
      for (let key = 0; key < echangesRecherche.length; key++) {
        if (echangesRecherche[key].id_echange === this.echange.id_echange) {
          currentKeyEchange = key;
        }
      }
    }
    return currentKeyEchange;
  }

  get descriptionSize() {
    if (this.echangeForm.value.description !== null && this.echangeForm.value.description !== undefined) {
      let lines = this.echangeForm.value.description.split('\n');
      return lines.length > 5 ? lines.length : 5;
    }
    return 5;
  }

  get diagnostic() {
    return JSON.stringify(this.echangeForm.value);
  }

  get diagnosticAction() {
    return JSON.stringify(this.actionForm.value);
  }

  get echangeHasActions() {
    let actionsForm = this.echangeForm.get('actions') as FormArray;

    if (actionsForm.length > 0) {
      return true;
    }
    return false;
  }

  get hasSens() {
    if (this.echangeForm.controls['type_echange'].value !== null
      && this.echangeForm.controls['type_echange'].value !== undefined
      && (this.echangeForm.controls['type_echange'].value.code_parametre === 'SYS'
        || this.echangeForm.controls['type_echange'].value.code_parametre === 'PER')) {
      return false;
    }
    return true;
  }

  get isClosed() {
    // return this.echange.isClosed(this.currentUtilisateur);

    if (this.echangeForm !== null && this.echangeForm !== undefined) {
      if (this.echangeForm.controls['statut_echange'].value === null || this.echangeForm.controls['statut_echange'].value.code_parametre !== 'F') {
        return false;
      } else {
        return true;
      }
    } else if (this.echange.statut_echange === null || this.echange.statut_echange === undefined || this.echange.categorie_echange.code_parametre !== 'F') {
      return false;
    } else {
      return true;
    }
  }

  get isDevis() {
    if (this.echangeForm !== null && this.echangeForm !== undefined) {
      if (this.echangeForm.controls['categorie_echange'].value === null || this.echangeForm.controls['categorie_echange'].value.code_parametre !== 'DEV') {
        return false;
      } else {
        return true;
      }
    } else if (this.echange.categorie_echange === null || this.echange.categorie_echange === undefined || this.echange.categorie_echange.code_parametre !== 'DEV') {
      return false;
    } else {
      return true;
    }
  }

  get isReclamation() {
    if (this.echangeForm !== null && this.echangeForm !== undefined) {
      if (this.echangeForm.controls['categorie_echange'].value !== null
        && this.echangeForm.controls['categorie_echange'].value !== undefined
        && (this.echangeForm.controls['categorie_echange'].value.code_parametre === 'REC')) {
        return true;
      } else {
        return false;
      }
    } else if (this.echange.categorie_echange !== null
      && this.echange.categorie_echange !== undefined
      && (this.echange.categorie_echange.code_parametre === 'REC')) {
      return true;
    } else {
      return false;
    }
  }

  get isRemonteeInformations() {
    if (this.echangeForm !== null && this.echangeForm !== undefined) {
      if (this.echangeForm.controls['categorie_echange'].value === null || this.echangeForm.controls['categorie_echange'].value.code_parametre !== 'REMINF') {
        return false;
      } else {
        return true;
      }
    } else if (this.echange.categorie_echange === null || this.echange.categorie_echange === undefined || this.echange.categorie_echange.code_parametre !== 'REMINF') {
      return false;
    } else {
      return true;
    }
  }

  get isSousCategorieAssociation() {
    if (this.echangeForm !== null && this.echangeForm !== undefined) {
      if (this.echangeForm.controls['sous_categorie_echange'].value !== null
        && this.echangeForm.controls['sous_categorie_echange'].value !== undefined
        && (this.echangeForm.controls['sous_categorie_echange'].value === 'association')) {
        return true;
      } else {
        return false;
      }
    } else if (this.echange.sous_categorie_echange !== null
      && this.echange.sous_categorie_echange !== undefined
      && (this.echange.sous_categorie_echange === 'association')) {
      return true;
    } else {
      return false;
    }
  }

  get isSousCategorieIntervention() {
    if (this.echangeForm !== null && this.echangeForm !== undefined) {
      if (this.echangeForm.controls['sous_categorie_echange'].value !== null
        && this.echangeForm.controls['sous_categorie_echange'].value !== undefined
        && (this.echangeForm.controls['sous_categorie_echange'].value === 'intervention')) {
        return true;
      } else {
        return false;
      }
    } else if (this.echange.sous_categorie_echange !== null
      && this.echange.sous_categorie_echange !== undefined
      && (this.echange.sous_categorie_echange === 'intervention')) {
      return true;
    } else {
      return false;
    }
  }

  get isTiers() {
    if (this.echangeForm !== null && this.echangeForm !== undefined) {
      if (this.echangeForm.controls['personne_sujet_nature'].value !== null
        && this.echangeForm.controls['personne_sujet_nature'].value !== undefined
        && (this.echangeForm.controls['personne_sujet_nature'].value.code_parametre === 'TIE' || this.echangeForm.controls['personne_sujet_nature'].value.code_parametre === 'TIN' ||
          this.echangeForm.controls['personne_sujet_nature'].value.code_parametre === 'TPRO')) {
        return true;
      } else {
        return false;
      }
    } else if (this.echange.personne_sujet_nature !== null
      && this.echange.personne_sujet_nature !== undefined
      && (this.echange.personne_sujet_nature.code_parametre === 'TIE' || this.echange.personne_sujet_nature.code_parametre === 'TIN' ||
        this.echange.personne_sujet_nature.code_parametre === 'TPRO')) {
      return true;
    } else {
      return false;
    }
  }

  get isTiersConcerne() {
    if (this.echangeForm !== null && this.echangeForm !== undefined) {
      if (this.echangeForm.controls['categorie_echange'].value !== null
        && this.echangeForm.controls['categorie_echange'].value !== undefined
        && (this.echangeForm.controls['categorie_echange'].value.code_parametre === 'REC' || this.echangeForm.controls['categorie_echange'].value.code_parametre === 'REMINF')) {
        return true;
      } else {
        if (this.echangeForm.controls['personne_tiers_concerne_nom'] === null) {
          this.isTiersConcerneShow = false;
        }
        return false;
      }
    } else if (this.echange.categorie_echange !== null
      && this.echange.categorie_echange !== undefined
      && (this.echange.categorie_echange.code_parametre === 'REC' || this.echange.categorie_echange.code_parametre === 'REMINF')
      || this.echange.personne_tiers_concerne_nom !== null) {
      return true;
    } else {
      return false;
    }
  }

  get nextEchangeId(): number {
    let currentKeyEchange = this.currentKeyEchange;
    if (this.currentKeyEchange !== null) {
      let echangesRecherche = this.echangeService.echangesList;
      if (currentKeyEchange !== (echangesRecherche.length - 1) && echangesRecherche[currentKeyEchange + 1] !== undefined) {
        return echangesRecherche[currentKeyEchange + 1].id_echange;
      }
    }
    return null;
  }

  get placeholderDescription() {
    if (this.isDevis) {
      return 'Précisions supplémentaires concernant la demande...';
    } else {
      return 'Descriptif de l\'échange...';
    }
  }

  get previousEchangeId(): number {
    let currentKeyEchange = this.currentKeyEchange;
    if (this.currentKeyEchange !== null) {
      let echangesRecherche = this.echangeService.echangesList;
      if (currentKeyEchange > 0 && echangesRecherche[currentKeyEchange - 1] !== undefined && echangesRecherche[currentKeyEchange - 1] !== null) {
        return echangesRecherche[currentKeyEchange - 1].id_echange;
      }
    }
    return null;
  }

  get showResponsableMail() {
    if (this.actionIsClosed) {
      return false;
    }

    if (this.actionForm !== null && this.actionForm !== undefined
      && this.actionForm.controls['responsable'] !== null && this.actionForm.controls['responsable'].value !== null) {
      let id_responsable = this.actionForm.controls['responsable'].value.id_utilisateur;
      let id_user = this.currentUtilisateur.id_utilisateur;
      let email_responsable = this.actionForm.controls['responsable'].value.mail;

      return id_responsable !== undefined && email_responsable !== undefined && email_responsable !== '' && id_responsable !== id_user;
    }

    return false;
  }

  canEditEchange() {
    if (!this.currentUtilisateur.hasFonctionnalite(DroitModule.module_echanges, DroitModule.fonctionnalite_edit_echanges)
      && !this.currentUtilisateur.hasFonctionnalite(DroitModule.module_personia, DroitModule.fonctionnalite_edit_echanges)) {
      return false;
    } else if (this.currentUtilisateur.isPersonia && this.echange.statut_echange
      && (this.echange.statut_echange.code_parametre === 'A' || this.echange.statut_echange.code_parametre === 'O')) {
      return false;
    } else {
      return true;
    }
  }

  cancel() {
    this.initDatas();
    this.createForm();
    this.formControlValueChanged();
    this.showHistorique = false;
  }

  cancelAction() {
    if (this.actionIsNew) {
      let actionsForm = this.echangeForm.get('actions') as FormArray;
      actionsForm.removeAt(0);
    } else {
      this.actionForm.reset();
    }
    this.showPopupAction = false;
  }

  copyDescriptionEchangeInAction() {
    this.actionForm.patchValue({ description_action: this.echangeForm.value.description });
  }

  createActionForm(action: Action) {
    return this.actionService.createActionForm(action);
  }

  createForm() {

    this.echangeForm = this.fb.group({
      'personne_sujet_civilite': new FormControl(this.echange.personne_sujet_civilite, Validators.required),
      'personne_sujet_nom': new FormControl(this.echange.personne_sujet_nom, [Validators.required, Validators.pattern(Globals.containsLettersPattern)]),
      'personne_sujet_prenom': new FormControl(this.echange.personne_sujet_prenom, [Validators.required, Validators.pattern(Globals.containsLettersPattern)]),
      'personne_sujet_societe': new FormControl(this.echange.personne_sujet_societe),
      'personne_sujet_tel_domicile': new FormControl(this.echange.personne_sujet_tel_domicile, {
        validators: phoneValidator(),
        updateOn: 'blur'
      }),
      'personne_sujet_tel_travail': new FormControl(this.echange.personne_sujet_tel_travail, {
        validators: phoneValidator(),
        updateOn: 'blur'
      }),
      'personne_sujet_tel_mobile': new FormControl(this.echange.personne_sujet_tel_mobile, {
        validators: phoneValidator(),
        updateOn: 'blur'
      }),
      'personne_sujet_mail': new FormControl(this.echange.personne_sujet_mail, {
        validators: emailValidator(),
        updateOn: 'blur'
      }),
      'personne_sujet_adr_num': new FormControl(this.echange.personne_sujet_adr_num),
      'personne_sujet_adr_complement': new FormControl(this.echange.personne_sujet_adr_complement),
      'personne_sujet_adr_voie': new FormControl(this.echange.personne_sujet_adr_voie),
      'personne_sujet_adr_complement1': new FormControl(this.echange.personne_sujet_adr_complement1),
      'personne_sujet_adr_complement2': new FormControl(this.echange.personne_sujet_adr_complement2),
      'personne_sujet_adr_code_postal': new FormControl(this.echange.personne_sujet_adr_commune ? this.echange.personne_sujet_adr_commune.code_postal : null),
      'personne_sujet_adr_commune': new FormControl(this.echange.personne_sujet_adr_commune),
      'personne_sujet_adr_lieudit': new FormControl(this.echange.personne_sujet_adr_lieudit),
      'personne_sujet_lien': new FormControl({
        value: this.echange.personne_sujet_lien,
        disabled: !this.isTiers
      }, Validators.required),
      'personne_tiers_civilite': new FormControl(this.echange.personne_tiers_civilite),
      'personne_tiers_nom': new FormControl({
        value: this.echange.personne_tiers_nom,
        disabled: !this.isTiers
      }, Validators.required),
      'personne_tiers_prenom': new FormControl(this.echange.personne_tiers_prenom),
      'personne_tiers_societe': new FormControl(this.echange.personne_tiers_societe),
      'personne_tiers_tel_domicile': new FormControl(this.echange.personne_tiers_tel_domicile, {
        validators: phoneValidator(),
        updateOn: 'blur'
      }),
      'personne_tiers_tel_travail': new FormControl(this.echange.personne_tiers_tel_travail, {
        validators: phoneValidator(),
        updateOn: 'blur'
      }),
      'personne_tiers_tel_mobile': new FormControl(this.echange.personne_tiers_tel_mobile, {
        validators: phoneValidator(),
        updateOn: 'blur'
      }),
      'personne_tiers_mail': new FormControl(this.echange.personne_tiers_mail, {
        validators: emailValidator(),
        updateOn: 'blur'
      }),
      'personne_tiers_adr_num': new FormControl(this.echange.personne_tiers_adr_num),
      'personne_tiers_adr_complement': new FormControl(this.echange.personne_tiers_adr_complement),
      'personne_tiers_adr_voie': new FormControl(this.echange.personne_tiers_adr_voie),
      'personne_tiers_adr_complement1': new FormControl(this.echange.personne_tiers_adr_complement1),
      'personne_tiers_adr_complement2': new FormControl(this.echange.personne_tiers_adr_complement2),
      'personne_tiers_adr_code_postal': new FormControl(this.echange.personne_tiers_adr_commune ? this.echange.personne_tiers_adr_commune.code_postal : null),
      'personne_tiers_adr_commune': new FormControl(this.echange.personne_tiers_adr_commune),
      'personne_tiers_adr_lieudit': new FormControl(this.echange.personne_tiers_adr_lieudit),
      'personne_tiers_concerne_civilite': new FormControl(this.echange.personne_tiers_concerne_civilite),
      'personne_tiers_concerne_nature': new FormControl(this.echange.personne_tiers_concerne_nature),
      'personne_tiers_concerne_nom': new FormControl(this.echange.personne_tiers_concerne_nom),
      'personne_tiers_concerne_prenom': new FormControl(this.echange.personne_tiers_concerne_prenom),
      'personne_tiers_concerne_societe': new FormControl(this.echange.personne_tiers_concerne_societe),
      'personne_tiers_concerne_tel_domicile': new FormControl(this.echange.personne_tiers_concerne_tel_domicile, {
        validators: phoneValidator(),
        updateOn: 'blur'
      }),
      'personne_tiers_concerne_tel_travail': new FormControl(this.echange.personne_tiers_concerne_tel_travail, {
        validators: phoneValidator(),
        updateOn: 'blur'
      }),
      'personne_tiers_concerne_tel_mobile': new FormControl(this.echange.personne_tiers_concerne_tel_mobile, {
        validators: phoneValidator(),
        updateOn: 'blur'
      }),
      'personne_tiers_concerne_mail': new FormControl(this.echange.personne_tiers_concerne_mail, {
        validators: emailValidator(),
        updateOn: 'blur'
      }),
      'personne_tiers_concerne_adr_num': new FormControl(this.echange.personne_tiers_concerne_adr_num),
      'personne_tiers_concerne_adr_complement': new FormControl(this.echange.personne_tiers_concerne_adr_complement),
      'personne_tiers_concerne_adr_voie': new FormControl(this.echange.personne_tiers_concerne_adr_voie),
      'personne_tiers_concerne_adr_complement1': new FormControl(this.echange.personne_tiers_concerne_adr_complement1),
      'personne_tiers_concerne_adr_complement2': new FormControl(this.echange.personne_tiers_concerne_adr_complement2),
      'personne_tiers_concerne_adr_code_postal': new FormControl(this.echange.personne_tiers_concerne_adr_commune ? this.echange.personne_tiers_concerne_adr_commune.code_postal : null),
      'personne_tiers_concerne_adr_commune': new FormControl(this.echange.personne_tiers_concerne_adr_commune),
      'personne_tiers_concerne_adr_lieudit': new FormControl(this.echange.personne_tiers_concerne_adr_lieudit),
      'federation': new FormControl(this.echange.federation, Validators.required),
      'association': new FormControl(
        this.echange.association,
        this.currentUtilisateur.type_acces === 'monoAsso' || this.currentUtilisateur.type_acces === 'multiSite'
          ? Validators.required
          : undefined
      ),
      'agence': new FormControl(this.echange.agence,
        (this.echange.statut_echange && this.echange.statut_echange.code_parametre === 'O') ? Validators.required : null
      ),
      'personne_sujet_nature': new FormControl(this.echange.personne_sujet_nature, Validators.required),
      'categorie_echange': new FormControl(this.echange.categorie_echange, Validators.required),
      'created': new FormControl(this.echange.created, Validators.required),
      'operateur': new FormControl(this.echange.operateur),
      'statut_echange': new FormControl(this.echange.statut_echange),
      'isStatutOpen': new FormControl(this.echange.statut_echange ? this.echange.statut_echange.code_parametre === 'O' : false),
      'priorite': new FormControl(this.echange.priorite),
      'isPrioriteUrgent': new FormControl(this.echange.priorite ? this.echange.priorite === 'U' : false),
      'type_echange': new FormControl(this.echange.type_echange, Validators.required),
      'type_echange_sens': new FormControl(this.echange.type_echange_sens),
      // Si l'échange est une demande de devis on enleve le controle sur le motif
      'motif_echange': new FormControl(this.echange.motif_echange, Validators.required),
      'marqueur': new FormControl(this.echange.marqueur),
      'services': new FormControl({ value: this.echange.services, disabled: !this.isDevis }),
      'objet_echange': new FormControl({
        value: this.echange.objet_echange,
        disabled: this.isDevis
      }, Validators.required),
      'description': new FormControl(this.echange.description),
      'description_complement': new FormControl(''),
      'notes_traitement': new FormControl({ value: this.echange.notes_traitement, disabled: !this.isDevis }),
      'personne_sujet_id_philia': new FormControl(this.echange.personne_sujet_id_philia),
      'personne_tiers_id_philia': new FormControl(this.echange.personne_tiers_id_philia),
      'personne_tiers_concerne_id_philia': new FormControl(this.echange.personne_tiers_concerne_id_philia),
      'actions': this.fb.array(this.getActionsForm()),
      'finalite_echange': new FormControl(this.echange.finalite_echange),
      'organisme_financier': new FormControl(this.echange.organisme_financier),
      'type_aide': new FormControl(this.echange.type_aide),
      'date_reouverture': new FormControl(this.echange.date_reouverture),
      'utilisateur_reouverture': new FormControl(this.echange.utilisateur_reouverture),
      'risques_salarie': new FormControl(this.echange.risques_salarie),
      'risques_client': new FormControl(this.echange.risques_client),
      'gravite': new FormControl(this.echange.gravite),
      'frequence': new FormControl(this.echange.frequence),
      'sous_categorie_echange': new FormControl(this.echange.sous_categorie_echange),
      'documents': this.fb.array(this.getDocumentsForm()),
      'astreinte': new FormControl(this.echange.astreinte)
    }, { validators: echangeValidator });
  }

  deleteDocument(rowIndex) {
    (this.echangeForm.get('documents') as FormArray).removeAt(rowIndex);
  }

  disableTiersConcerneControls() {
    this.echangeForm.get('personne_tiers_concerne_nature').disable();
    this.echangeForm.get('personne_tiers_concerne_civilite').disable();
    this.echangeForm.get('personne_tiers_concerne_nom').disable();
    this.echangeForm.get('personne_tiers_concerne_prenom').disable();
    this.echangeForm.get('personne_tiers_concerne_societe').disable();
    this.echangeForm.get('personne_tiers_concerne_tel_domicile').disable();
    this.echangeForm.get('personne_tiers_concerne_tel_travail').disable();
    this.echangeForm.get('personne_tiers_concerne_tel_mobile').disable();
    this.echangeForm.get('personne_tiers_concerne_mail').disable();
    this.echangeForm.get('personne_tiers_concerne_adr_num').disable();
    this.echangeForm.get('personne_tiers_concerne_adr_complement').disable();
    this.echangeForm.get('personne_tiers_concerne_adr_voie').disable();
    this.echangeForm.get('personne_tiers_concerne_adr_complement1').disable();
    this.echangeForm.get('personne_tiers_concerne_adr_complement2').disable();
    this.echangeForm.get('personne_tiers_concerne_adr_code_postal').disable();
    this.echangeForm.get('personne_tiers_concerne_adr_commune').disable();
    this.echangeForm.get('personne_tiers_concerne_adr_lieudit').disable();
  }

  disableTiersControls() {
    this.echangeForm.get('personne_sujet_lien').disable();
    this.echangeForm.get('personne_tiers_civilite').disable();
    this.echangeForm.get('personne_tiers_nom').disable();
    this.echangeForm.get('personne_tiers_prenom').disable();
    this.echangeForm.get('personne_tiers_societe').disable();
    this.echangeForm.get('personne_tiers_tel_domicile').disable();
    this.echangeForm.get('personne_tiers_tel_travail').disable();
    this.echangeForm.get('personne_tiers_tel_mobile').disable();
    this.echangeForm.get('personne_tiers_mail').disable();
    this.echangeForm.get('personne_tiers_adr_num').disable();
    this.echangeForm.get('personne_tiers_adr_complement').disable();
    this.echangeForm.get('personne_tiers_adr_voie').disable();
    this.echangeForm.get('personne_tiers_adr_complement1').disable();
    this.echangeForm.get('personne_tiers_adr_complement2').disable();
    this.echangeForm.get('personne_tiers_adr_code_postal').disable();
    this.echangeForm.get('personne_tiers_adr_commune').disable();
    this.echangeForm.get('personne_tiers_adr_lieudit').disable();
  }

  disabledDeleteDocument(rowIndex) {
    (this.echangeForm.get('documents') as FormArray).at(rowIndex).get('deleted').setValue(null);
    (this.echangeForm.get('documents') as FormArray).at(rowIndex).get('utilisateur_suppression').setValue(null);
    (this.echangeForm.get('documents') as FormArray).at(rowIndex).get('date_suppression').setValue(null);
  }

  downloadDocument(document) {
    console.log(document);
    this.documentEchangeService.getImage(document.id_document_echange).subscribe((blob_document: any) => {
      FileSaver.saveAs(blob_document, document.nom);
    });
  }

  editAction(rowIndex) {
    this.indexSelectedAction = rowIndex;
    this.actionFormMode = 'edit';
    let actionsForm = this.echangeForm.get('actions') as FormArray;
    this.actionForm = actionsForm.at(rowIndex) as FormGroup;
    this.actionToEdit = this.echange.actionsDateDesc[rowIndex];
    this.actionToEdit.echange = plainToClass(Echange, this.echangeForm.value as Object);
    if (this.formMode === 'edit') {
      this.actionToEdit.echange.id_echange = this.echange.id_echange;
    }
    this.popupActionTitle = 'Editer Action';
    this.showPopupAction = true;
  }

  enableTiersConcerneControls() {
    this.echangeForm.get('personne_tiers_concerne_nature').enable();
    this.echangeForm.get('personne_tiers_concerne_civilite').enable();
    this.echangeForm.get('personne_tiers_concerne_nom').enable();
    this.echangeForm.get('personne_tiers_concerne_prenom').enable();
    this.echangeForm.get('personne_tiers_concerne_societe').enable();
    this.echangeForm.get('personne_tiers_concerne_tel_domicile').enable();
    this.echangeForm.get('personne_tiers_concerne_tel_travail').enable();
    this.echangeForm.get('personne_tiers_concerne_tel_mobile').enable();
    this.echangeForm.get('personne_tiers_concerne_mail').enable();
    this.echangeForm.get('personne_tiers_concerne_adr_num').enable();
    this.echangeForm.get('personne_tiers_concerne_adr_complement').enable();
    this.echangeForm.get('personne_tiers_concerne_adr_voie').enable();
    this.echangeForm.get('personne_tiers_concerne_adr_complement1').enable();
    this.echangeForm.get('personne_tiers_concerne_adr_complement2').enable();
    this.echangeForm.get('personne_tiers_concerne_adr_code_postal').enable();
    this.echangeForm.get('personne_tiers_concerne_adr_commune').enable();
    this.echangeForm.get('personne_tiers_concerne_adr_lieudit').enable();
  }

  enableTiersControls() {
    this.echangeForm.get('personne_sujet_lien').enable();
    this.echangeForm.get('personne_tiers_civilite').enable();
    this.echangeForm.get('personne_tiers_nom').enable();
    this.echangeForm.get('personne_tiers_prenom').enable();
    this.echangeForm.get('personne_tiers_societe').enable();
    this.echangeForm.get('personne_tiers_tel_domicile').enable();
    this.echangeForm.get('personne_tiers_tel_travail').enable();
    this.echangeForm.get('personne_tiers_tel_mobile').enable();
    this.echangeForm.get('personne_tiers_mail').enable();
    this.echangeForm.get('personne_tiers_adr_num').enable();
    this.echangeForm.get('personne_tiers_adr_complement').enable();
    this.echangeForm.get('personne_tiers_adr_voie').enable();
    this.echangeForm.get('personne_tiers_adr_complement1').enable();
    this.echangeForm.get('personne_tiers_adr_complement2').enable();
    this.echangeForm.get('personne_tiers_adr_code_postal').enable();
    this.echangeForm.get('personne_tiers_adr_commune').enable();
    this.echangeForm.get('personne_tiers_adr_lieudit').enable();
  }

  exportCsv() {
    let data = this.getExportData();
    this.exportService.exportAsCsvFile(data, 'echange_' + this.echange.id_echange);
  }

  exportExcel() {
    let data = this.getExportData();
    this.exportService.exportAsExcelFile(data, 'echange_' + this.echange.id_echange);
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.echangeForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  // On écoute les changements des valeurs du formulaire
  formControlValueChanged(): void {
    this.echangeForm.get('categorie_echange').valueChanges.subscribe(categorie_echange => {
      if (categorie_echange !== null && categorie_echange !== undefined) {
        if (categorie_echange.code_parametre === 'DEV') { // Demande de devis
          this.echangeForm.get('motif_echange').clearValidators();
          this.echangeForm.get('services').enable();
          this.echangeForm.get('objet_echange').disable();
          this.echangeForm.get('notes_traitement').enable();
          this.echangeForm.get('risques_salarie').disable();
          this.echangeForm.get('risques_client').disable();
          this.echangeForm.get('gravite').disable();
          this.echangeForm.get('frequence').disable();
        } else if (categorie_echange.code_parametre === 'REMINF') { // Remontee d'information
          this.echangeForm.get('risques_salarie').enable();
          this.echangeForm.get('risques_client').enable();
          this.echangeForm.get('gravite').enable();
          this.echangeForm.get('frequence').enable();
        } else {
          if (!this.currentUtilisateur.isPersonia) {
            this.echangeForm.get('motif_echange').setValidators(Validators.required);
          }
          this.echangeForm.get('services').disable();
          this.echangeForm.get('objet_echange').enable();
          this.echangeForm.get('notes_traitement').disable();
          this.echangeForm.get('risques_salarie').disable();
          this.echangeForm.get('risques_client').disable();
          this.echangeForm.get('gravite').disable();
          this.echangeForm.get('frequence').disable();

          if (categorie_echange.code_parametre === 'REC' && !this.echangeForm.get('sous_categorie_echange').value) {
            this.echangeForm.get('sous_categorie_echange').setValue('association');
          }
        }
        this.echangeForm.updateValueAndValidity();
      }
    });

    this.echangeForm.get('motif_echange').valueChanges.subscribe(motif_echange => {
      if (motif_echange !== null && motif_echange !== undefined) {
        if (motif_echange.code_parametre === 'NSPSE' || motif_echange.code_parametre === 'FAUNUM') {
          this.echangeForm.get('personne_sujet_civilite').clearValidators();
          this.echangeForm.get('personne_sujet_nom').clearValidators();
          this.echangeForm.get('personne_sujet_prenom').clearValidators();
        } else {
          this.echangeForm.get('personne_sujet_civilite').setValidators(Validators.required);
          this.echangeForm.get('personne_sujet_nom').setValidators([Validators.required, Validators.pattern(Globals.containsLettersPattern)]);
          this.echangeForm.get('personne_sujet_prenom').setValidators([Validators.required, Validators.pattern(Globals.containsLettersPattern)]);
        }
      }
    });

    this.echangeForm.get('personne_sujet_nature').valueChanges.subscribe(personne_sujet_nature => {
      if (this.isTiers) { // Tiers
        this.enableTiersControls();
      } else {
        this.disableTiersControls();
      }
      this.echangeForm.updateValueAndValidity(); // On active/desactive la partie tiers contactant si la nature change
    });

    this.echangeForm.get('personne_sujet_adr_code_postal').valueChanges.subscribe(personne_sujet_adr_code_postal => {
      this.echangeForm.updateValueAndValidity();
      this.onChangeCodePostal(); // On met à jour la liste des communes si le code postal change
    });

    this.echangeForm.get('personne_tiers_adr_code_postal').valueChanges.subscribe(personne_tiers_adr_code_postal => {
      this.echangeForm.updateValueAndValidity();
      this.onChangeCodePostalTiers(); // On met à jour la liste des communes si le code postal change
    });

    this.echangeForm.get('personne_tiers_concerne_adr_code_postal').valueChanges.subscribe(personne_tiers_concerne_adr_code_postal => {
      this.echangeForm.updateValueAndValidity();
      this.onChangeCodePostalTiersConcerne(); // On met à jour la liste des communes si le code postal change
    });

    this.echangeForm.get('federation').valueChanges.subscribe(federation => {
      this.echangeForm.updateValueAndValidity();
      if (this.currentUtilisateur.perimetre === 'National') {
        this.onChangeFederation(); // On met à jour la liste des associations si la federation change
      }
    });

    this.echangeForm.get('association').valueChanges.subscribe(association => {
      this.echangeForm.updateValueAndValidity();
      this.getResponsables(); // On met à jour la liste des responsables si l'association change
      this.onChangeAssociation();
    });

    // On réinitialise l'id philia si le prenom ou le nom change
    this.echangeForm.get('personne_sujet_prenom').valueChanges.subscribe(association => {
      this.echangeForm.get('personne_sujet_id_philia').patchValue(null);
      this.echangeForm.updateValueAndValidity();
    });

    // On réinitialise l'id philia si le prenom ou le nom change
    this.echangeForm.get('personne_sujet_nom').valueChanges.subscribe(association => {
      this.echangeForm.get('personne_sujet_id_philia').patchValue(null);
      this.echangeForm.updateValueAndValidity();
    });

    // On réinitialise l'id philia si le prenom ou le nom change
    this.echangeForm.get('personne_tiers_prenom').valueChanges.subscribe(association => {
      this.echangeForm.get('personne_tiers_id_philia').patchValue(null);
      this.echangeForm.updateValueAndValidity();
    });

    // On réinitialise l'id philia si le prenom ou le nom change
    this.echangeForm.get('personne_tiers_nom').valueChanges.subscribe(association => {
      this.echangeForm.get('personne_tiers_id_philia').patchValue(null);
      this.echangeForm.updateValueAndValidity();
    });

    // On réinitialise l'id philia si le prenom ou le nom change
    this.echangeForm.get('personne_tiers_concerne_prenom').valueChanges.subscribe(association => {
      this.echangeForm.get('personne_tiers_concerne_id_philia').patchValue(null);
      this.echangeForm.updateValueAndValidity();
    });

    // On réinitialise l'id philia si le prenom ou le nom change
    this.echangeForm.get('personne_tiers_concerne_nom').valueChanges.subscribe(association => {
      this.echangeForm.get('personne_tiers_concerne_id_philia').patchValue(null);
      this.echangeForm.updateValueAndValidity();
    });
  }

  genereNomDocument(document) {
    let nomDocument: string;
    let today = new Date();
    let fileExtension = this.currentFilename.split('.').pop();

    nomDocument = 'echange';

    if (this.echangeForm.get('personne_sujet_nom').value) {
      let nomDocumentSujetNom = this.echangeForm.get('personne_sujet_nom').value.replace(/ /g, '');
      nomDocument += '_' + (nomDocumentSujetNom.substring(0, 3));
    }

    nomDocument += '_' + Globals.formatDateForFilename(today, true);

    if (this.echangeForm.value.id_echange) {
      nomDocument += '_' + this.echangeForm.value.id_echange;
    }
    nomDocument += '.' + fileExtension;

    return nomDocument;
  }

  getActionsForm(): FormGroup[] {
    let actionsForm = [];
    let self = this;

    this.echange.actionsDateDesc.forEach(function (action) {
      actionsForm.push(self.createActionForm(action));
    });

    return actionsForm;
  }

  getAgences() {
    if (this.currentUtilisateur.perimetre === 'Agence') {
      this.agencesList = this.currentUtilisateur.agences;
      this.updateAgencesItems();
      if (this.formMode === 'create') {
        if (this.currentUtilisateur.agences.length === 1) {
          this.echange.agence = this.currentUtilisateur.agences[0];
        } else if (this.currentUtilisateur.association && this.currentUtilisateur.association.agence_reference
          && this.currentUtilisateur.hasAgence(this.currentUtilisateur.association.agence_reference)) {
          this.echange.agence = this.currentUtilisateur.association.agence_reference;
        }
      }
    } else if (this.echange.association !== null && this.echange.association !== undefined) {
      this.agenceService.getAgencesByParams({ association: this.echange.association.id_association })
        .subscribe((agences: Agence[]) => {
          this.agencesList = agences;
          this.updateAgencesItems();
          if (this.formMode === 'create' && this.echange.association.agence_reference) {
            this.echange.agence = this.echange.association.agence_reference;
            if (this.echangeForm !== null && this.echangeForm !== undefined) {
              this.echangeForm.patchValue({ agence: this.echange.association.agence_reference });
            }
          }
        });
    }
  }

  getAssociations() {
    if (this.currentUtilisateur.perimetre !== 'National') {
      if (this.currentUtilisateur.type_acces === 'monoAsso' || (this.currentUtilisateur.perimetre === 'Association' && !this.currentUtilisateur.type_acces)) {
        this.associationsList = this.currentUtilisateur.association ? [this.currentUtilisateur.association] : undefined;
        this.echange.association = this.currentUtilisateur.association;
        this.updateAssociationsItems();
        if (this.echangeForm !== null && this.echangeForm !== undefined) {
          this.echangeForm.patchValue({ association: this.currentUtilisateur.association });
        }
      } else if (this.currentUtilisateur.type_acces === 'multiAsso') {
        this.associationsList = this.currentUtilisateur.multiSiteAssociations;
        this.updateAssociationsItems();
      } else if (this.currentUtilisateur.type_acces === 'multiSite') {
        this.associationsList = [this.authenticationService.currentAssociationForUserMultiSite];
        this.echange.association = this.authenticationService.currentAssociationForUserMultiSite;
        this.updateAssociationsItems();
        if (this.echangeForm !== null && this.echangeForm !== undefined) {
          this.echangeForm.patchValue({ association: this.authenticationService.currentAssociationForUserMultiSite });
        }
      } else if (this.currentUtilisateur.perimetre === 'Federation') {
        this.associationService.getAssociationsByParams({
          federation: this.echange.federation.id_federation,
          restricted_datas: 'true'
        })
          .subscribe((associations: Association[]) => {
            this.associationsList = associations;
            this.updateAssociationsItems();
          });
      } else if (this.currentUtilisateur.perimetre === 'Agence') {
        this.associationsList = this.currentUtilisateur.association ? [this.currentUtilisateur.association] : undefined;
        this.echange.association = this.currentUtilisateur.association;
        this.updateAssociationsItems();
      }
    } else if (this.echange.federation !== null && this.echange.federation !== undefined) {
      this.associationService.getAssociationsByParams({
        federation: this.echange.federation.id_federation,
        restricted_datas: 'true'
      })
        .subscribe((associations: Association[]) => {
          this.associationsList = associations;
          this.updateAssociationsItems();
        });
    }
  }

  getCategories() {
    this.parametreService.getParametresByParams({ type: 'CATEGORIE' })
      .subscribe((parametres: Parametre[]) => {
        this.categoriesList = parametres;
        let self = this;
        if (this.formMode === 'create') {
          parametres.forEach(function (parametre) {
            if (parametre.is_default) {
              self.echange.categorie_echange = parametre; // On definit la nature par defaut
              self.echangeForm.patchValue({ categorie_echange: parametre });
            }
          });
        }
        // peupler la liste de motifs par défaut si pas admin nsi
        if (this.currentUtilisateur.perimetre != 'National' || self.echange.federation) {
          if (self.echange.categorie_echange.code_parametre != "REC") {
            self.federationMotifsService.getFederationsMotifsByFederationAndCategorie(self.echange.federation.id_federation, self.echange.categorie_echange.id_parametre).subscribe(res => {
              self.motifsEchangeList = res.map(fm => {
                return fm.motif;
              }).sort((a, b) => {
                return a.libelle.localeCompare(b.libelle, 'en');
              });
              // gérer motif supprimé
              if (self.motifsEchangeList.find(me => me.id_parametre == self.echange.motif_echange.id_parametre) === undefined) {
                self.parametreService.getParametre(self.echange.motif_echange.id_parametre).subscribe(res => {
                  self.idMotifSupprime = res.id_parametre;
                  self.motifsEchangeList = [...self.motifsEchangeList, res];
                  this.motifsEchangeList.sort((a, b) => a.libelle.localeCompare(b.libelle, 'en'));
                });
              }
            });
          } else {
            self.federationMotifsService.getFederationMotifsByFederationCategorieAndReclamation(self.echange.federation.id_federation, self.echange.categorie_echange.id_parametre, self.echange.sous_categorie_echange == "association" ? 1 : 2).subscribe(res => {
              self.motifsEchangeList = res.map(fm => {
                return fm.motif;
              }).sort((a, b) => {
                return a.libelle.localeCompare(b.libelle, 'en');
              });
              // gérer motif supprimé
              if (self.motifsEchangeList.find(me => me.id_parametre == self.echange.motif_echange.id_parametre) === undefined) {
                self.parametreService.getParametre(self.echange.motif_echange.id_parametre).subscribe(res => {
                  self.idMotifSupprime = res.id_parametre;
                  self.motifsEchangeList = [...self.motifsEchangeList, res];
                  this.motifsEchangeList.sort((a, b) => a.libelle.localeCompare(b.libelle, 'en'));
                });
              }
            });
          }
        }
      });
  }

  getCivilites() {
    this.parametreService.getParametresByParams({ type: 'CIVILITE' })
      .subscribe((parametres: Parametre[]) => {
        this.civilitesList = parametres;
        if (this.formMode === 'create') {
          let self = this;
          parametres.forEach(function (parametre) {
            if (parametre.is_default) {
              self.echange.personne_sujet_civilite = parametre; // On definit la nature par defaut
              self.echangeForm.patchValue({ personne_sujet_civilite: parametre });
            }
          });
        }
      });
  }

  getClassTrIndividu(individu: Individu) {
    let classTrIndividu = 'selectable';

    if (!individu.actif) { // Inactif
      classTrIndividu += ' italic';
    }

    if (individu.nature && individu.nature.code_parametre === 'CLI') { // Client
      classTrIndividu += ' bold';
    }

    return classTrIndividu;
  }

  getCommunes() {
    if (this.echange.personne_sujet_adr_commune !== null
      && this.echange.personne_sujet_adr_commune !== undefined
      && this.echange.personne_sujet_adr_commune.code_postal.length === 5
    ) {
      this.communeService.getCommunesByParams({ code_postal: this.echange.personne_sujet_adr_commune.code_postal })
        .subscribe((communes: Commune[]) => {
          this.communesList = communes;
          if (this.communesList.length === 1) {
            this.echangeForm.value.personne_sujet_adr_commune = this.communesList[0];
          } else if (this.communesList.length === 0) {
            this.echangeForm.value.personne_sujet_adr_commune = null;
          }
        });
    }
  }

  getCommunesTiers() {
    if (this.echange.personne_tiers_adr_commune !== null
      && this.echange.personne_tiers_adr_commune !== undefined
      && this.echange.personne_tiers_adr_commune.code_postal.length === 5
    ) {
      this.communeService.getCommunesByParams({ code_postal: this.echange.personne_tiers_adr_commune.code_postal })
        .subscribe((communes: Commune[]) => {
          this.communesTiersList = communes;
          if (this.communesTiersList.length === 1) {
            this.echangeForm.value.personne_tiers_adr_commune = this.communesTiersList[0];
          } else if (this.communesTiersList.length === 0) {
            this.echangeForm.value.personne_tiers_adr_commune = null;
          }
        });
    }
  }

  getCommunesTiersConcerne() {
    if (this.echange.personne_tiers_concerne_adr_commune !== null
      && this.echange.personne_tiers_concerne_adr_commune !== undefined
      && this.echange.personne_tiers_concerne_adr_commune.code_postal.length === 5
    ) {
      this.communeService.getCommunesByParams({ code_postal: this.echange.personne_tiers_concerne_adr_commune.code_postal })
        .subscribe((communes: Commune[]) => {
          this.communesTiersConcerneList = communes;
          if (this.communesTiersConcerneList.length === 1) {
            this.echangeForm.value.personne_tiers_concerne_adr_commune = this.communesTiersConcerneList[0];
          } else if (this.communesTiersConcerneList.length === 0) {
            this.echangeForm.value.personne_tiers_concerne_adr_commune = null;
          }
        });
    }
  }

  getComplementsAdresse() {
    this.complementsAdresseList = Globals.getComplementsAdresse();
  }

  getDocumentsForm(): FormGroup[] {
    let documentsForm = [];
    let self = this;

    this.echange.documents.forEach(function (document) {
      documentsForm.push(DocumentEchange.getForm(document));
    });

    return documentsForm;
  }

  getExportData() {
    let data = this.echange.serializeForExport(this.echange.categorie_echange.code_parametre);
    if (this.formMode === 'create') {
      let echangeData: Echange = new Echange();
      Object.assign(echangeData, this.echangeForm.value);
      data = echangeData.serializeForExport(this.echange.categorie_echange.code_parametre);
    }
    return data;
  }

  getExportItems() {
    this.exportItems = [
      {
        label: 'Format Excel', icon: 'zmdi zmdi-file', command: () => {
          this.exportExcel();
        }
      },
      {
        label: 'Format CSV', icon: 'zmdi zmdi-file-text', command: () => {
          this.exportCsv();
        }
      }
    ];
  }

  getFederations() {
    if (this.currentUtilisateur.perimetre === 'National') { // Si perimetre National, on cherche toutes les federations
      this.federationService.getAll(true).subscribe(federations => {
        this.federationsList = federations;
      });
    } else {
      this.federationsList = [this.currentUtilisateur.federation];
      this.echange.federation = this.currentUtilisateur.federation;
      if (this.echangeForm !== null && this.echangeForm !== undefined) {
        this.echangeForm.patchValue({ federation: this.currentUtilisateur.federation });
      }
    }
  }

  getFinalitesDevis() {
    this.parametreService.getParametresByParams({ type: 'FINALITE_DEVIS' })
      .subscribe((parametres: Parametre[]) => {
        this.finalitesDevisList = parametres;
      });
  }

  getFrequences() {
    this.parametreService.getParametresByParams({ type: 'FREQUENCE' })
      .subscribe((parametres: Parametre[]) => {
        this.frequencesList = parametres;
      });
  }

  getGravites() {
    this.parametreService.getParametresByParams({ type: 'GRAVITE' })
      .subscribe((parametres: Parametre[]) => {
        this.gravitesList = parametres;
      });
  }

  getLiens() {
    this.parametreService.getParametresByParams({ type: 'LIEN' })
      .subscribe((parametres: Parametre[]) => {
        this.liensList = parametres;
        if (this.formMode === 'create') {
          let self = this;
          parametres.forEach(function (parametre) {
            if (parametre.is_default) {
              self.echange.personne_sujet_lien = parametre; // On definit le type par defaut
              self.echangeForm.patchValue({ personne_sujet_lien: parametre });
            }
          });
        }
      });
  }

  getMotifsDevis() {
    this.parametreService.getParametresByParams({ type: 'MOTIF_DEVIS' })
      .subscribe((parametres: Parametre[]) => {
        this.motifsDevisList = parametres;
      });
  }

  getMotifsEchange() {
    if (this.echangeForm?.value?.federation?.id_federation && this.echangeForm?.value?.categorie_echange?.id_parametre) {
      this.federationMotifsService.getFederationsMotifsByFederationAndCategorie(this.echangeForm.value.federation.id_federation, this.echangeForm.value.categorie_echange.id_parametre).subscribe(res => {
        this.motifsEchangeList = res.map(fm => {
          return fm.motif;
        }).sort((a, b) => {
          return a.libelle.localeCompare(b.libelle, 'en');
        });
      });
    }
  }

  getMotifsReclamationAssociation() {
    this.parametreService.getParametresByParams({ type: 'MOTIF_RECLAMATION_ASSOCIATION' })
      .subscribe((parametres: Parametre[]) => {
        this.motifsReclamationsAssociationList = parametres;
      });
  }

  getMotifsReclamationIntervention() {
    this.parametreService.getParametresByParams({ type: 'MOTIF_RECLAMATION_INTERVENTION' })
      .subscribe((parametres: Parametre[]) => {
        this.motifsReclamationsInterventionList = parametres;
      });
  }

  getMotifsRemonteeInformations() {
    this.parametreService.getParametresByParams({ type: 'MOTIF_REMONTEE_INFORMATIONS' })
      .subscribe((parametres: Parametre[]) => {
        this.motifsRemonteeInformationsList = parametres;
      });
  }

  getNatures() {
    this.parametreService.getParametresByParams({ type: 'NATURE' })
      .subscribe((parametres: Parametre[]) => {
        this.naturesList = parametres;
        if (this.formMode === 'create') {
          let self = this;
          parametres.forEach(function (parametre) {
            if (parametre.is_default) {
              self.echange.personne_sujet_nature = parametre; // On definit la nature par defaut
              self.echangeForm.patchValue({ personne_sujet_nature: parametre });
            }
          });
        }

        // Natures sans "Tiers pour" pour les tiers concernés
        let naturesTiersConcerneList = [];
        parametres.forEach((value, index) => {
          if (value.code_parametre !== 'TIE' && value.code_parametre !== 'TIN') {
            naturesTiersConcerneList.push(value);

          }
        });
        this.naturesTiersConcerneList = naturesTiersConcerneList;
      });
  }

  getOrganismesFinanceurs() {
    this.parametreService.getParametresByParams({ type: 'ORGANISME_FINANCEUR' })
      .subscribe((parametres: Parametre[]) => {
        this.organismesFinanceursList = parametres;
      });
  }

  getResponsables() {
    let associationId = 0;
    if (this.echangeForm !== null && this.echangeForm !== undefined &&
      this.echangeForm.value.association !== null && this.echangeForm.value.association !== undefined) {
      associationId = this.echangeForm.value.association.id_association;
    } else if (this.formMode === 'edit') {
      associationId = this.echange.association.id_association;
    }
    this.utilisateurService.getResponsablesByParams({ association: associationId, module: 'main_courante' }, true)
      .subscribe((utilisateurs: Utilisateur[]) => {
        this.responsablesList = plainToClass(Utilisateur, utilisateurs);
      });
  }

  getRisquesClient() {
    this.parametreService.getParametresByParams({ type: 'RISQUE_CLIENT' })
      .subscribe((parametres: Parametre[]) => {
        this.risquesClientList = parametres;
      });
  }

  getRisquesSalarie() {
    this.parametreService.getParametresByParams({ type: 'RISQUE_SALARIE' })
      .subscribe((parametres: Parametre[]) => {
        this.risquesSalarieList = parametres;
      });
  }

  getServices() {
    this.parametreService.getParametresByParams({ type: 'SERVICE' })
      .subscribe((parametres: Parametre[]) => {
        this.servicesList = parametres;
      });
  }

  getStatutsAction() {
    this.parametreService.getParametresByParams({ type: 'STATUT_A' })
      .subscribe((parametres: Parametre[]) => {
        this.statutsActionList = parametres;
      });
  }

  getStatutsEchange() {
    this.parametreService.getParametresByParams({ type: 'STATUT_E' })
      .subscribe((parametres: Parametre[]) => {
        this.statutsEchangeList = parametres;
        let self = this;
        parametres.forEach(function (parametre) {
          if (self.formMode === 'create' && parametre.code_parametre === 'B') {
            self.echange.statut_echange = parametre; // On definit le statut par defaut
            self.echangeForm.patchValue({ statut_echange: parametre });
          }
          if (parametre.code_parametre === 'O') {
            self.statutOuvert = parametre;
          }
          if (parametre.code_parametre === 'A') {
            self.statutAstreinte = parametre;
          }
        });
      });
  }

  getStatutsEvaluationAction() {
    this.parametreService.getParametresByParams({ type: 'STATUT_EVA' })
      .subscribe((parametres: Parametre[]) => {
        this.statutsEvaluationActionList = parametres;
      });
  }

  getTooltipAction(rowIndex) {
    let action = this.echange.actionsDateDesc[rowIndex];

    if (action) {
      return action.description_action;
    }

    return null;
  }

  getTypesAides() {
    if (this.echangeForm.get('organisme_financier').value) {
      this.parametreService.getParametresByParams({ type: this.echangeForm.get('organisme_financier').value.type_enfant })
        .subscribe((parametres: Parametre[]) => {
          this.typesAidesList = parametres;
        });
    }
  }

  getTypesEchange() {
    this.parametreService.getParametresByParams({ type: 'TYPE' })
      .subscribe((parametres: Parametre[]) => {
        // this.typesEchangeList = parametres;
        let tab = new Array();
        parametres.forEach(function (parametre) {
          let disabled = false;

          if (parametre.code_parametre === 'SYS'
            || parametre.code_parametre === 'WEB'
            || parametre.code_parametre === 'PER') {
            disabled = true;
          }

          let data = {
            label: parametre.libelle,
            value: parametre,
            disabled: disabled
          };

          tab.push(data);
        });

        this.typesEchangeList = tab;

        if (this.formMode === 'create') {
          let self = this;
          parametres.forEach(function (parametre) {
            if (parametre.is_default && !self.currentUtilisateur.isPersonia) {
              self.echange.type_echange = parametre; // On definit le type par defaut
              self.echangeForm.patchValue({ type_echange: parametre });
            } else if (parametre.code_parametre === 'PER' && self.currentUtilisateur.isPersonia) {
              self.echange.type_echange = parametre; // On definit le type par defaut
              self.echangeForm.patchValue({ type_echange: parametre });
              // On met sens entrant par défaut
              self.echangeForm.patchValue({ type_echange_sens: 'entrant' });
            }
          });
        }

      });
  }

  handleFileInput(files: FileList) {
    if (files.length > 0) {
      let fileToUpload = files.item(0);
      this.currentFilename = fileToUpload.name;
      let myReader = new FileReader();

      myReader.onloadend = (e) => {
        this.documentEchangeForm.get('file').setValue(myReader.result);
      };
      myReader.readAsDataURL(fileToUpload);
    } else {
      this.documentEchangeForm.get('file').setValue(null);
    }
    this.documentEchangeForm.updateValueAndValidity();

  }

  hidePopupIndividus() {
    this.showPopupIndividus = false;
    this.showPopupIndividusSujet = false;
    this.showPopupIndividusTiers = false;
    this.showPopupIndividusTiersConcerne = false;
  }

  initDatas() {
    if (this.formMode === 'create') {
      this.echange.operateur = this.currentUtilisateur;
    }

    this.initEditableDatas();
    this.getCivilites();
    this.getNatures();
    this.getCategories();
    this.getComplementsAdresse();
    this.getFederations();
    this.getAssociations();
    this.getAgences();
    this.getStatutsEchange();
    this.getStatutsAction();
    this.getStatutsEvaluationAction();
    this.getTypesEchange();
    this.getMotifsEchange();
    this.getMotifsDevis();
    this.getMotifsReclamationAssociation();
    this.getMotifsReclamationIntervention();
    this.getMotifsRemonteeInformations();
    this.getFinalitesDevis();
    this.getServices();
    this.getOrganismesFinanceurs();
    this.getCommunes();
    this.getCommunesTiers();
    this.getCommunesTiersConcerne();
    this.getLiens();
    this.getResponsables();
    this.getExportItems();
    this.getRisquesSalarie();
    this.getRisquesClient();
    this.getGravites();
    this.getFrequences();
    if (this.echange.id_echange) {
      this.echangeMarqueurService.getEchangesMarqueursByEchange(this.echange.id_echange).subscribe(res => {
        this.selectedMarqueurs = res.map(em => {
          return em.federationMarqueur;
        });
      });
    }
  }

  initEditableDatas() {
    // Si l'echange n'est pas nouveau, on définit si on peut l'éditer
    if (this.formMode === 'edit') {
      if ((!this.currentUtilisateur.hasFonctionnalite(DroitModule.module_echanges, DroitModule.fonctionnalite_edit_echanges) && !this.currentUtilisateur.hasFonctionnalite(DroitModule.module_personia, DroitModule.fonctionnalite_edit_echanges))
        || this.echange.statut_echange.code_parametre === 'F') {
        this.isPersonneEditable = false;
        this.isQualificationEditable = false;
        this.isEditableAfterDateLimit = false;
      } else {
        this.parametreService.getParametresByParams({ type: 'PERIODE_MODIF_E' })
          .subscribe((parametres: Parametre[]) => {
            this.periodeModif = parseInt(parametres[0].libelle, 10);
            let dateLimitEditable = moment(this.echange.date_creation).add(this.periodeModif, 'h'); // On ajoute le nombre d'heures pour obtenir la date limit
            this.dateLimitEditable = dateLimitEditable.toDate();
            let isDateLimitValid = moment(dateLimitEditable).isAfter(moment(new Date()));

            if (!isDateLimitValid) {
              this.isPersonneEditable = false;
              this.isQualificationEditable = false;
            } else if (this.echange.personne_sujet_id_philia !== null && this.echange.personne_sujet_id_philia !== undefined && this.echange.personne_sujet_id_philia !== ''
              && this.echange.statut_echange.code_parametre !== 'B') {
              this.isPersonneEditable = false;
            }
          });
      }
    }
  }

  intervertirIndividus() {
    let communesList = this.communesList;
    this.communesList = this.communesTiersList;
    this.communesTiersList = communesList;

    let personne_sujet_id_philia = this.echangeForm.value.personne_sujet_id_philia;
    this.echangeForm.patchValue({ personne_sujet_id_philia: this.echangeForm.value.personne_tiers_id_philia });
    this.echangeForm.patchValue({ personne_tiers_id_philia: personne_sujet_id_philia });

    let personne_sujet_civilite = this.echangeForm.value.personne_sujet_civilite;
    this.echangeForm.patchValue({ personne_sujet_civilite: this.echangeForm.value.personne_tiers_civilite });
    this.echangeForm.patchValue({ personne_tiers_civilite: personne_sujet_civilite });

    let personne_sujet_nom = this.echangeForm.value.personne_sujet_nom;
    this.echangeForm.patchValue({ personne_sujet_nom: this.echangeForm.value.personne_tiers_nom });
    this.echangeForm.patchValue({ personne_tiers_nom: personne_sujet_nom });

    let personne_sujet_prenom = this.echangeForm.value.personne_sujet_prenom;
    this.echangeForm.patchValue({ personne_sujet_prenom: this.echangeForm.value.personne_tiers_prenom });
    this.echangeForm.patchValue({ personne_tiers_prenom: personne_sujet_prenom });

    let personne_sujet_societe = this.echangeForm.value.personne_sujet_societe;
    this.echangeForm.patchValue({ personne_sujet_societe: this.echangeForm.value.personne_tiers_societe });
    this.echangeForm.patchValue({ personne_tiers_societe: personne_sujet_societe });

    let personne_sujet_tel_domicile = this.echangeForm.value.personne_sujet_tel_domicile;
    this.echangeForm.patchValue({ personne_sujet_tel_domicile: this.echangeForm.value.personne_tiers_tel_domicile });
    this.echangeForm.patchValue({ personne_tiers_tel_domicile: personne_sujet_tel_domicile });

    let personne_sujet_tel_travail = this.echangeForm.value.personne_sujet_tel_travail;
    this.echangeForm.patchValue({ personne_sujet_tel_travail: this.echangeForm.value.personne_tiers_tel_travail });
    this.echangeForm.patchValue({ personne_tiers_tel_travail: personne_sujet_tel_travail });

    let personne_sujet_tel_mobile = this.echangeForm.value.personne_sujet_tel_mobile;
    this.echangeForm.patchValue({ personne_sujet_tel_mobile: this.echangeForm.value.personne_tiers_tel_mobile });
    this.echangeForm.patchValue({ personne_tiers_tel_mobile: personne_sujet_tel_mobile });

    let personne_sujet_mail = this.echangeForm.value.personne_sujet_mail;
    this.echangeForm.patchValue({ personne_sujet_mail: this.echangeForm.value.personne_tiers_mail });
    this.echangeForm.patchValue({ personne_tiers_mail: personne_sujet_mail });

    let personne_sujet_adr_num = this.echangeForm.value.personne_sujet_adr_num;
    this.echangeForm.patchValue({ personne_sujet_adr_num: this.echangeForm.value.personne_tiers_adr_num });
    this.echangeForm.patchValue({ personne_tiers_adr_num: personne_sujet_adr_num });

    let personne_sujet_adr_complement = this.echangeForm.value.personne_sujet_adr_complement;
    this.echangeForm.patchValue({ personne_sujet_adr_complement: this.echangeForm.value.personne_tiers_adr_complement });
    this.echangeForm.patchValue({ personne_tiers_adr_complement: personne_sujet_adr_complement });

    let personne_sujet_adr_voie = this.echangeForm.value.personne_sujet_adr_voie;
    this.echangeForm.patchValue({ personne_sujet_adr_voie: this.echangeForm.value.personne_tiers_adr_voie });
    this.echangeForm.patchValue({ personne_tiers_adr_voie: personne_sujet_adr_voie });

    let personne_sujet_adr_complement1 = this.echangeForm.value.personne_sujet_adr_complement1;
    this.echangeForm.patchValue({ personne_sujet_adr_complement1: this.echangeForm.value.personne_tiers_adr_complement1 });
    this.echangeForm.patchValue({ personne_tiers_adr_complement1: personne_sujet_adr_complement1 });

    let personne_sujet_adr_complement2 = this.echangeForm.value.personne_sujet_adr_complement2;
    this.echangeForm.patchValue({ personne_sujet_adr_complement2: this.echangeForm.value.personne_tiers_adr_complement2 });
    this.echangeForm.patchValue({ personne_tiers_adr_complement2: personne_sujet_adr_complement2 });

    let personne_sujet_adr_code_postal = this.echangeForm.value.personne_sujet_adr_code_postal;
    this.echangeForm.patchValue({ personne_sujet_adr_code_postal: this.echangeForm.value.personne_tiers_adr_code_postal });
    this.echangeForm.patchValue({ personne_tiers_adr_code_postal: personne_sujet_adr_code_postal });

    let personne_sujet_adr_commune = this.echangeForm.value.personne_sujet_adr_commune;
    this.echangeForm.patchValue({ personne_sujet_adr_commune: this.echangeForm.value.personne_tiers_adr_commune });
    this.echangeForm.patchValue({ personne_tiers_adr_commune: personne_sujet_adr_commune });

    let personne_sujet_adr_lieudit = this.echangeForm.value.personne_sujet_adr_lieudit;
    this.echangeForm.patchValue({ personne_sujet_adr_lieudit: this.echangeForm.value.personne_tiers_adr_lieudit });
    this.echangeForm.patchValue({ personne_tiers_adr_lieudit: personne_sujet_adr_lieudit });
  }

  intervertirIndividusConcerne() {
    let communesList = this.communesList;
    this.communesList = this.communesTiersConcerneList;
    this.communesTiersConcerneList = communesList;

    let personne_sujet_id_philia = this.echangeForm.value.personne_sujet_id_philia;
    this.echangeForm.patchValue({ personne_sujet_id_philia: this.echangeForm.value.personne_tiers_concerne_id_philia });
    this.echangeForm.patchValue({ personne_tiers_concerne_id_philia: personne_sujet_id_philia });

    let personne_sujet_civilite = this.echangeForm.value.personne_sujet_civilite;
    this.echangeForm.patchValue({ personne_sujet_civilite: this.echangeForm.value.personne_tiers_concerne_civilite });
    this.echangeForm.patchValue({ personne_tiers_concerne_civilite: personne_sujet_civilite });

    let personne_sujet_nom = this.echangeForm.value.personne_sujet_nom;
    this.echangeForm.patchValue({ personne_sujet_nom: this.echangeForm.value.personne_tiers_concerne_nom });
    this.echangeForm.patchValue({ personne_tiers_concerne_nom: personne_sujet_nom });

    let personne_sujet_prenom = this.echangeForm.value.personne_sujet_prenom;
    this.echangeForm.patchValue({ personne_sujet_prenom: this.echangeForm.value.personne_tiers_concerne_prenom });
    this.echangeForm.patchValue({ personne_tiers_concerne_prenom: personne_sujet_prenom });

    let personne_sujet_societe = this.echangeForm.value.personne_sujet_societe;
    this.echangeForm.patchValue({ personne_sujet_societe: this.echangeForm.value.personne_tiers_concerne_societe });
    this.echangeForm.patchValue({ personne_tiers_concerne_societe: personne_sujet_societe });

    let personne_sujet_tel_domicile = this.echangeForm.value.personne_sujet_tel_domicile;
    this.echangeForm.patchValue({ personne_sujet_tel_domicile: this.echangeForm.value.personne_tiers_concerne_tel_domicile });
    this.echangeForm.patchValue({ personne_tiers_concerne_tel_domicile: personne_sujet_tel_domicile });

    let personne_sujet_tel_travail = this.echangeForm.value.personne_sujet_tel_travail;
    this.echangeForm.patchValue({ personne_sujet_tel_travail: this.echangeForm.value.personne_tiers_concerne_tel_travail });
    this.echangeForm.patchValue({ personne_tiers_concerne_tel_travail: personne_sujet_tel_travail });

    let personne_sujet_tel_mobile = this.echangeForm.value.personne_sujet_tel_mobile;
    this.echangeForm.patchValue({ personne_sujet_tel_mobile: this.echangeForm.value.personne_tiers_concerne_tel_mobile });
    this.echangeForm.patchValue({ personne_tiers_concerne_tel_mobile: personne_sujet_tel_mobile });

    let personne_sujet_mail = this.echangeForm.value.personne_sujet_mail;
    this.echangeForm.patchValue({ personne_sujet_mail: this.echangeForm.value.personne_tiers_concerne_mail });
    this.echangeForm.patchValue({ personne_tiers_concerne_mail: personne_sujet_mail });

    let personne_sujet_adr_num = this.echangeForm.value.personne_sujet_adr_num;
    this.echangeForm.patchValue({ personne_sujet_adr_num: this.echangeForm.value.personne_tiers_concerne_adr_num });
    this.echangeForm.patchValue({ personne_tiers_concerne_adr_num: personne_sujet_adr_num });

    let personne_sujet_adr_complement = this.echangeForm.value.personne_sujet_adr_complement;
    this.echangeForm.patchValue({ personne_sujet_adr_complement: this.echangeForm.value.personne_tiers_concerne_adr_complement });
    this.echangeForm.patchValue({ personne_tiers_adr_complement: personne_sujet_adr_complement });

    let personne_sujet_adr_voie = this.echangeForm.value.personne_sujet_adr_voie;
    this.echangeForm.patchValue({ personne_sujet_adr_voie: this.echangeForm.value.personne_tiers_concerne_adr_voie });
    this.echangeForm.patchValue({ personne_tiers_concerne_adr_voie: personne_sujet_adr_voie });

    let personne_sujet_adr_complement1 = this.echangeForm.value.personne_sujet_adr_complement1;
    this.echangeForm.patchValue({ personne_sujet_adr_complement1: this.echangeForm.value.personne_tiers_concerne_adr_complement1 });
    this.echangeForm.patchValue({ personne_tiers_concerne_adr_complement1: personne_sujet_adr_complement1 });

    let personne_sujet_adr_complement2 = this.echangeForm.value.personne_sujet_adr_complement2;
    this.echangeForm.patchValue({ personne_sujet_adr_complement2: this.echangeForm.value.personne_tiers_concerne_adr_complement2 });
    this.echangeForm.patchValue({ personne_tiers_concerne_adr_complement2: personne_sujet_adr_complement2 });

    let personne_sujet_adr_code_postal = this.echangeForm.value.personne_sujet_adr_code_postal;
    this.echangeForm.patchValue({ personne_sujet_adr_code_postal: this.echangeForm.value.personne_tiers_concerne_adr_code_postal });
    this.echangeForm.patchValue({ personne_tiers_concerne_adr_code_postal: personne_sujet_adr_code_postal });

    let personne_sujet_adr_commune = this.echangeForm.value.personne_sujet_adr_commune;
    this.echangeForm.patchValue({ personne_sujet_adr_commune: this.echangeForm.value.personne_tiers_concerne_adr_commune });
    this.echangeForm.patchValue({ personne_tiers_concerne_adr_commune: personne_sujet_adr_commune });

    let personne_sujet_adr_lieudit = this.echangeForm.value.personne_sujet_adr_lieudit;
    this.echangeForm.patchValue({ personne_sujet_adr_lieudit: this.echangeForm.value.personne_tiers_concerne_adr_lieudit });
    this.echangeForm.patchValue({ personne_tiers_concerne_adr_lieudit: personne_sujet_adr_lieudit });
  }

  newAction() {
    if (this.canAddAction) {
      this.actionToEdit = new Action();
      this.actionToEdit.echange = plainToClass(Echange, this.echangeForm.value as Object);
      this.actionToEdit.redacteur = this.currentUtilisateur;
      this.actionToEdit.responsable = this.currentUtilisateur;
      if (this.formMode === 'edit') {
        this.actionToEdit.echange.id_echange = this.echange.id_echange;
      }
      let self = this;
      this.statutsActionList.forEach(function (statutAction) {
        if (statutAction.is_default) {
          self.actionToEdit.statut_action = statutAction; // On definit le statut par defaut
        }
      });

      if (this.actionToEdit.isReclamation()) {
        this.actionToEdit.objet_action = 'Autre';
      } else {
        this.actionToEdit.objet_action = this.echangeForm.value.objet_echange;
      }

      this.actionForm = this.createActionForm(this.actionToEdit);

      let actionsForm = this.echangeForm.get('actions') as FormArray;
      this.indexSelectedAction = 0;
      actionsForm.insert(0, this.actionForm);
      this.showPopupAction = true;
      this.actionFormMode = 'create';
      this.popupActionTitle = 'Nouvelle Action';
    }
  }

  newActionEvaluation() {
    if (this.canAddActionEvaluation) {
      this.actionToEdit = new Action();
      this.actionToEdit.is_evaluation = true;

      this.actionToEdit.echange = plainToClass(Echange, this.echangeForm.value as Object);
      this.actionToEdit.redacteur = this.currentUtilisateur;
      if (this.formMode === 'edit') {
        this.actionToEdit.echange.id_echange = this.echange.id_echange;
      }
      let self = this;
      this.statutsActionList.forEach(function (statutAction) {
        if (statutAction.is_default) {
          self.actionToEdit.statut_action = statutAction; // On definit le statut par defaut
        }
      });
      this.statutsEvaluationActionList.forEach(function (statutEvaluationAction) {
        if (statutEvaluationAction.code_parametre === 'INI') {
          let evaluation_entete = new EvaluationEntete();
          evaluation_entete.statut_evaluation = statutEvaluationAction;
          self.actionToEdit.evaluation_entete = evaluation_entete; // On definit le statut evaluation a A Realiser
        }
      });
      this.actionToEdit.objet_action = 'Evaluation';

      this.actionForm = this.createActionForm(this.actionToEdit);

      let actionsForm = this.echangeForm.get('actions') as FormArray;
      this.indexSelectedAction = actionsForm.length;
      // Ajout de l'évaluation en début du tableau et non à la fin
      actionsForm.insert(0, this.actionForm);
      this.showPopupAction = true;
      this.actionFormMode = 'create';
      this.popupActionTitle = 'Nouvelle évaluation';
    }
  }

  newDocument() {
    if (this.canEditEchange()) {
      let documentToEdit = new DocumentEchange();
      documentToEdit.date_creation = new Date();
      documentToEdit.utilisateur_creation = this.currentUtilisateur;
      this.documentEchangeForm = DocumentEchange.getForm(documentToEdit);
      this.currentFilename = '';

      this.showPopupAjoutDocument = true;
    }
  }

  newIndividu() {
    if (this.showPopupIndividusSujet) {
      this.isNewIndividu = true;
      this.createProspect = true;
      this.echangeForm.patchValue({ personne_sujet_id_philia: null });
    }

    this.parametreService.getParametresByParams({ type: 'NATURE' })
      .subscribe((parametres: Parametre[]) => {
        let self = this;
        parametres.forEach(function (parametre) {
          if (parametre.code_parametre === 'PRO') {
            self.echange.personne_sujet_nature = parametre; // On definit la nature Prospect
            self.echangeForm.patchValue({ personne_sujet_nature: parametre });
          }
        });
      });
    this.hidePopupIndividus();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    // avoid memory leaks here by cleaning up after ourselves. If we
    // don't then we will continue to run our initialiseInvites()
    // method on every navigationEnd event.
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.currentUtilisateur = this.authenticationService.currentUtilisateurValue;
    this.initDatas();
    this.createForm();
    this.formControlValueChanged();
    this.getTypesAides();

    if (this.echange.personne_tiers_concerne_nom && this.formMode === 'edit') {
      this.isTiersConcerneShow = true;
    }
    // Si pas les droits d'édition/création, on désactive le formulaire
    if (!this.currentUtilisateur.hasFonctionnalite(DroitModule.module_echanges, DroitModule.fonctionnalite_edit_echanges)
      && !this.currentUtilisateur.hasFonctionnalite(DroitModule.module_personia, DroitModule.fonctionnalite_edit_echanges)) {
      //this.echangeForm.disable();
    }

    if (this.currentUtilisateur.isPersonia && this.echange.statut_echange
      && (this.echange.statut_echange.code_parametre === 'A' || this.echange.statut_echange.code_parametre === 'O')) {
      //this.echangeForm.disable();
    }

    // On prérempli Astreinte avec la valeur du sessionStorage contenant le profil de l'utilisateur
    if (this.formMode !== 'edit' && sessionStorage.getItem('switchAstreinte') !== null) {
      this.echangeForm.get('astreinte').setValue(JSON.parse(sessionStorage.getItem('switchAstreinte')));
    }
  }

  onChangeAssociation() {
    this.agencesList = null;
    this.echangeForm.get('agence').setValue(null);
    this.updateAgencesItems();

    if (this.echangeForm.value && this.echangeForm.value.association) {
      let association = plainToClass(Association, this.echangeForm.value.association as Object);

      this.agenceService.getAgencesByParams({ association: this.echangeForm.value.association.id_association })
        .subscribe((agences: Agence[]) => {
          this.agencesList = agences;

          this.updateAgencesItems();

          if (association.agence_reference) {
            this.echangeForm.get('agence').setValue(association.agence_reference);
            this.echangeForm.get('agence').updateValueAndValidity();
          } else if (this.agencesList.length === 1) {
            this.echangeForm.get('agence').setValue(this.agencesList[0]);
            this.echangeForm.get('agence').updateValueAndValidity();
          }
        });
    }
  }

  onChangeCategorieEchange() {
    this.idMotifSupprime = null;
    this.echangeForm.patchValue({ motif_echange: null });
    this.echangeForm.get('motif_echange').updateValueAndValidity();
    if (this.echangeForm.value.categorie_echange.code_parametre != "REC") {
      this.federationMotifsService.getFederationsMotifsByFederationAndCategorie(this.echangeForm.value.federation.id_federation, this.echangeForm.value.categorie_echange.id_parametre).subscribe(res => {
        this.motifsEchangeList = res.map(fm => {
          return fm.motif;
        }).sort((a, b) => {
          return a.libelle.localeCompare(b.libelle, 'en');
        });

      });
    } else {
      this.federationMotifsService.getFederationMotifsByFederationCategorieAndReclamation(this.echangeForm.value.federation.id_federation, this.echangeForm.value.categorie_echange.id_parametre, this.echangeForm.value.sous_categorie_echange == "association" ? 1 : 2).subscribe(res => {
        this.motifsEchangeList = res.map(fm => {
          return fm.motif;
        }).sort((a, b) => {
          return a.libelle.localeCompare(b.libelle, 'en');
        });

      });
    }
  }

  onChangeCodePostal() {
    if (this.echangeForm.controls['personne_sujet_adr_code_postal'].valid) {
      this.communeService.getCommunesByParams({ code_postal: this.echangeForm.value.personne_sujet_adr_code_postal })
        .subscribe((communes: Commune[]) => {
          this.communesList = communes;
          if (this.communesList.length === 1) {
            this.echangeForm.patchValue({ personne_sujet_adr_commune: this.communesList[0] });
          } else if (this.communesList.length === 0) {
            this.echangeForm.patchValue({ personne_sujet_adr_commune: null });
          }
        });
    } else {
      this.communesList = null;
      this.echangeForm.patchValue({ personne_sujet_adr_commune: null });
    }
  }

  onChangeCodePostalTiers() {
    if (this.echangeForm.controls['personne_tiers_adr_code_postal'].valid) {
      this.communeService.getCommunesByParams({ code_postal: this.echangeForm.value.personne_tiers_adr_code_postal })
        .subscribe((communes: Commune[]) => {
          this.communesTiersList = communes;
          if (this.communesTiersList.length === 1) {
            this.echangeForm.patchValue({ personne_tiers_adr_commune: this.communesTiersList[0] });
          } else if (this.communesTiersList.length === 0) {
            this.echangeForm.patchValue({ personne_tiers_adr_commune: null });
          }
        });
    } else {
      this.communesTiersList = null;
      this.echangeForm.patchValue({ personne_tiers_adr_commune: null });
    }
  }

  onChangeCodePostalTiersConcerne() {
    if (this.echangeForm.controls['personne_tiers_concerne_adr_code_postal'].valid) {
      this.communeService.getCommunesByParams({ code_postal: this.echangeForm.value.personne_tiers_concerne_adr_code_postal })
        .subscribe((communes: Commune[]) => {
          this.communesTiersConcerneList = communes;
          if (this.communesTiersConcerneList.length === 1) {
            this.echangeForm.patchValue({ personne_tiers_concerne_adr_commune: this.communesTiersConcerneList[0] });
          } else if (this.communesTiersConcerneList.length === 0) {
            this.echangeForm.patchValue({ personne_tiers_concerne_adr_commune: null });
          }
        });
    } else {
      this.communesTiersConcerneList = null;
      this.echangeForm.patchValue({ personne_tiers_concerne_adr_commune: null });
    }
  }

  onChangeFederation() {
    this.idMotifSupprime = null;
    this.agencesList = null;
    this.echangeForm.get('agence').setValue(null);
    this.updateAgencesItems();

    this.associationsList = null;
    this.echangeForm.get('association').setValue(null);
    this.updateAssociationsItems();
    this.federationMotifsService.getFederationsMotifsByFederationAndCategorie(this.echangeForm.value.federation.id_federation, this.echangeForm.value.categorie_echange.id_parametre).subscribe(res => {
      this.motifsEchangeList = res.map(fm => {
        return fm.motif;
      }).sort((a, b) => {
        return a.libelle.localeCompare(b.libelle, 'en');
      });

    });

    if (this.echangeForm.value.federation) {
      this.associationService.getAssociationsByParams({
        federation: this.echangeForm.value.federation.id_federation,
        restricted_datas: 'true'
      })
        .subscribe((associations: Association[]) => {
          this.associationsList = associations;
          this.updateAssociationsItems();
        });
    }
  }

  onChangeMarqueur(event) {
    this.echangeForm.patchValue({ marqueur: event });
  }

  onChangePrioriteEchange(event) {
    if (event.checked) {
      this.echangeForm.patchValue({ priorite: 'U' });
    } else {
      this.echangeForm.patchValue({ priorite: 'S' });
    }
  }

  onChangeAstreinte(event) {
    if (event.checked) {
      this.echangeForm.patchValue({ astreinte: true });
    } else {
      this.echangeForm.patchValue({ astreinte: false });
    }
  }

  onChangeStatutEchange(event) {
    let self = this;
    this.statutsEchangeList.forEach(function (parametre) {
      if (event.checked && parametre.code_parametre === 'O') {
        self.echangeForm.patchValue({ statut_echange: parametre });
      } else if (!event.checked && parametre.code_parametre === 'F') {
        self.echangeForm.patchValue({ statut_echange: parametre });
      }
    });
    this.echangeForm.get('statut_echange').updateValueAndValidity();
  }

  onClick(event) {
    if (event.code_parametre === 'SYS' || event.code_parametre === 'WEB') {
      event.stopPropagation();
    }
  }

  onCloseActionPopup(event) {
    if (this.actionForm !== null && this.actionForm !== undefined && this.actionIsNew) {
      if (!this.actionForm.controls['save_before_close'].value) {
        this.cancelAction();
        this.actionForm.patchValue({ save_before_close: false });
      }
    }
    this.showPopupAction = false;
  }

  onCreateAction(event) {
    if (this.actionForm !== null && this.actionForm !== undefined) {
      this.actionForm.setValue(this.actionService.createActionForm(event).value);
      (this.echangeForm.get('actions') as FormArray).at(this.indexSelectedAction).setValue(this.actionForm.value);
      this.echange.actions.push(event);
      this.echangeForm.updateValueAndValidity();
      if (this.formMode === 'edit' && event.is_evaluation) { // Si on crée une evaluation, on recharge le page
        this.router.navigate(['/in/echange/' + this.echange.id_echange]);
      }
    }
    this.showPopupAction = false;
  }

  onDescriptionFocused() {
    let objet_echange = this.echangeForm.value.objet_echange;

    if (objet_echange === null || objet_echange === undefined || objet_echange === '') {
      let new_objet_echange = '';

      if (this.echangeForm.value.categorie_echange !== null && this.echangeForm.value.categorie_echange !== undefined) {
        let categorie_echange = this.echangeForm.value.categorie_echange.libelle;
        new_objet_echange = new_objet_echange + categorie_echange;
      }

      if (this.echangeForm.value.motif_echange !== null && this.echangeForm.value.motif_echange !== undefined) {
        let motif_echange = this.echangeForm.value.motif_echange.libelle;
        if (new_objet_echange !== null && new_objet_echange !== undefined && new_objet_echange !== '') {
          new_objet_echange = new_objet_echange + ' '; // On ajoute un espace entre categorie et motif
        }

        new_objet_echange = new_objet_echange + motif_echange;
      }

      this.echangeForm.patchValue({ objet_echange: new_objet_echange });
    }
  }

  onHideOverlayPanel() {
    this.currentImageSrc = null;
  }

  onSubmit(fermerOnglet: boolean = false) {
    if (this.isNewIndividu && this.canCreateProspect && this.createProspect) {
      let individu = new Individu();
      individu.setValuesFromEchangeForm(plainToClass(Echange, this.echangeForm.value as Object));
      this.individuService.createIndividu(individu)
        .subscribe(
          (individuCreated: any) => {
            let result = individuCreated.toString();
            if (isNaN(parseInt(result, 10))) {
              this.messageService.add({
                severity: 'error',
                summary: 'Erreur dans la création du prospect.',
                detail: result
              });
            } else {
              this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Prospect créé avec succès.' });
              this.echangeForm.patchValue({ personne_sujet_id_philia: result });
              this.saveEchange(fermerOnglet);
            }
          },
          error => {
            console.log(error);
            this.messageService.add({
              severity: 'error',
              summary: 'Erreur dans la création du prospect.',
              detail: error
            });
          }
        );
    } else if (this.echangeForm.value.description_complement !== undefined && this.echangeForm.value.description_complement !== '') {
      let description_complement = this.echangeForm.value.description_complement + '\n';
      let now = new Date().toLocaleString();
      let flag = '\n[' + this.currentUtilisateur.login + ' le ' + now + ']\n';
      this.echangeForm.patchValue({ description_complement: flag + ' ' + description_complement });
      this.saveEchange(fermerOnglet);
    } else if (this.echangeForm.value.notes_traitement !== undefined && this.echangeForm.value.notes_traitement !== '') {
      let notes_traitement = this.echangeForm.value.notes_traitement + '\n';
      let now = new Date().toLocaleString();
      let flag = '\n[' + this.currentUtilisateur.login + ' le ' + now + ']\n';
      this.echangeForm.patchValue({ notes_traitement: flag + ' ' + notes_traitement });
      this.saveEchange(fermerOnglet);
    } else {
      this.saveEchange(fermerOnglet);
    }
  }

  onSubmitAction() {

  }

  onUpdateAction(event) {
    if (this.actionForm !== null && this.actionForm !== undefined) {
      let indexActionsDesc = ((this.indexSelectedAction - (this.echangeForm.get('actions') as FormArray).value.length) + 1) * -1; // Récupération index inversé
      this.echange.actions[indexActionsDesc] = event;
      (this.echangeForm.get('actions') as FormArray).at(this.indexSelectedAction).setValue(this.actionService.createActionForm(event).value);
      this.echangeForm.updateValueAndValidity();
    }
    this.showPopupAction = false;
  }

  saveDocument() {
    if (this.documentEchangeForm !== null && this.documentEchangeForm !== undefined) {
      this.documentEchangeForm.get('nom').setValue(this.genereNomDocument(this.documentEchangeForm.value));
      (this.echangeForm.get('documents') as FormArray).push(this.documentEchangeForm);
      this.echangeForm.updateValueAndValidity();
    }

    this.showPopupAjoutDocument = false;
  }

  saveEchange(fermerOnglet: boolean = false) {
    if (this.echangeForm.valid) {
      // On enleve les controles uniquement nécessaires à l'affichage du formulaire
      this.echangeForm.get('isStatutOpen').disable();
      this.echangeForm.get('isPrioriteUrgent').disable();
      this.submittingEchange = true;
      this.echangeForm.patchValue({ marqueur: this.selectedMarqueurs });

      localStorage.setItem('fermerOnglet', 'false');
      if (fermerOnglet) {
        localStorage.setItem('fermerOnglet', 'true');
      }

      if (this.formMode === 'edit') {
        this.update.emit(this.echangeForm.value);
        // On réactive les champs
        this.echangeForm.get('isStatutOpen').enable();
        this.echangeForm.get('isPrioriteUrgent').enable();
      } else { // create mode
        this.create.emit(this.echangeForm.value);
      }
      this.submittingEchange = false;
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Erreur',
        detail: 'Le formulaire de l\'échange est invalide'
      });
    }
  }

  // Type : sujet / tiers / tiers_concerne
  searchIndividuPhilia(type) {
    let filtreIndividu = new FiltreIndividu();
    filtreIndividu.setValuesFromEchangeForm(this.echangeForm.value, type);
    this.loadingIndividus = true;
    if (type === 'sujet') {
      this.isNewIndividu = false;
    }

    this.individusList = [];

    this.showPopupIndividusSujet = false;
    this.showPopupIndividusTiers = false;
    this.showPopupIndividusTiersConcerne = false;

    this.individuService.getIndividusByParams(filtreIndividu, false)
      .subscribe((individus: Individu[]) => {
        this.individusList = plainToClass(Individu, individus);
        this.loadingIndividus = false;
        if (type === 'sujet') {
          this.showPopupIndividusSujet = true;
        } else if (type === 'tiers') {
          this.showPopupIndividusTiers = true;
        } else if (type === 'tiers_concerne') {
          this.showPopupIndividusTiersConcerne = true;
        }
        this.showPopupIndividus = true;
      },
        error => {
          // Aucune résultat trouvé
          this.loadingIndividus = false;
          if (type === 'sujet') {
            this.showPopupIndividusSujet = true;
          } else if (type === 'tiers') {
            this.showPopupIndividusTiers = true;
          } else if (type === 'tiers_concerne') {
            this.showPopupIndividusTiersConcerne = true;
          }
          this.showPopupIndividus = true;
        });

  }

  selectIndividu(individu: Individu) {

    if (this.showPopupIndividusTiers) {
      if (!Globals.isNullOrUndefined(individu.civilite)) {
        this.echangeForm.patchValue({ personne_tiers_civilite: individu.civilite });
      }

      this.echangeForm.patchValue({ personne_tiers_nom: individu.nom });
      this.echangeForm.patchValue({ personne_tiers_prenom: individu.prenom });
      this.echangeForm.patchValue({ personne_tiers_societe: individu.societe });
      this.echangeForm.patchValue({ personne_tiers_tel_domicile: individu.tel_domicile });
      this.echangeForm.patchValue({ personne_tiers_tel_travail: individu.tel_travail });
      this.echangeForm.patchValue({ personne_tiers_tel_mobile: individu.tel_mobile });
      this.echangeForm.patchValue({ personne_tiers_mail: individu.mail });
      this.echangeForm.patchValue({ personne_tiers_adr_num: individu.adr_num });
      this.echangeForm.patchValue({ personne_tiers_adr_complement: individu.adr_complement });
      this.echangeForm.patchValue({ personne_tiers_adr_voie: individu.adr_voie });
      this.echangeForm.patchValue({ personne_tiers_adr_complement1: individu.adr_complement1 });
      this.echangeForm.patchValue({ personne_tiers_adr_complement2: individu.adr_complement2 });
      this.echangeForm.patchValue({ personne_tiers_adr_code_postal: individu.adr_code_postale });

      if (!Globals.isNullOrUndefined(individu.adr_commune)) {
        this.echangeForm.patchValue({ personne_tiers_adr_commune: individu.adr_commune });
      }

      this.echangeForm.patchValue({ association: individu.association });
      this.onChangeAssociation();

      if (individu.association) {
        if (individu.association.agence_reference) {
          this.echangeForm.patchValue({ agence: individu.association.agence_reference });
        } else if (!individu.association.agence_reference) {

        }
      }

      if (!Globals.isNullOrUndefined(individu.nature)) {
        this.echangeForm.patchValue({ personne_tiers_nature: individu.nature });
      }

      this.echangeForm.patchValue({ personne_tiers_id_philia: individu.id_philia });
    } else if (this.showPopupIndividusTiersConcerne) {
      if (!Globals.isNullOrUndefined(individu.civilite)) {
        this.echangeForm.patchValue({ personne_tiers_concerne_civilite: individu.civilite });
      }

      this.echangeForm.patchValue({ personne_tiers_concerne_nom: individu.nom });
      this.echangeForm.patchValue({ personne_tiers_concerne_prenom: individu.prenom });
      this.echangeForm.patchValue({ personne_tiers_concerne_societe: individu.societe });
      this.echangeForm.patchValue({ personne_tiers_concerne_tel_domicile: individu.tel_domicile });
      this.echangeForm.patchValue({ personne_tiers_concerne_tel_travail: individu.tel_travail });
      this.echangeForm.patchValue({ personne_tiers_concerne_tel_mobile: individu.tel_mobile });
      this.echangeForm.patchValue({ personne_tiers_concerne_mail: individu.mail });
      this.echangeForm.patchValue({ personne_tiers_concerne_adr_num: individu.adr_num });
      this.echangeForm.patchValue({ personne_tiers_concerne_adr_complement: individu.adr_complement });
      this.echangeForm.patchValue({ personne_tiers_concerne_adr_voie: individu.adr_voie });
      this.echangeForm.patchValue({ personne_tiers_concerne_adr_complement1: individu.adr_complement1 });
      this.echangeForm.patchValue({ personne_tiers_concerne_adr_complement2: individu.adr_complement2 });
      this.echangeForm.patchValue({ personne_tiers_concerne_adr_code_postal: individu.adr_code_postale });

      if (!Globals.isNullOrUndefined(individu.adr_commune)) {
        this.echangeForm.patchValue({ personne_tiers_concerne_adr_commune: individu.adr_commune });
      }

      this.echangeForm.patchValue({ association: individu.association });

      if (individu.association && individu.association.agence_reference) {
        this.echangeForm.patchValue({ agence: individu.association.agence_reference });
      }

      if (!Globals.isNullOrUndefined(individu.nature)) {
        this.echangeForm.patchValue({ personne_tiers_concerne_nature: individu.nature });
      }

      this.echangeForm.patchValue({ personne_tiers_concerne_id_philia: individu.id_philia });
    } else {
      if (!Globals.isNullOrUndefined(individu.civilite)) {
        this.echangeForm.patchValue({ personne_sujet_civilite: individu.civilite });
      }

      this.echangeForm.patchValue({ personne_sujet_nom: individu.nom });
      this.echangeForm.patchValue({ personne_sujet_prenom: individu.prenom });
      this.echangeForm.patchValue({ personne_sujet_societe: individu.societe });
      this.echangeForm.patchValue({ personne_sujet_tel_domicile: individu.tel_domicile });
      this.echangeForm.patchValue({ personne_sujet_tel_travail: individu.tel_travail });
      this.echangeForm.patchValue({ personne_sujet_tel_mobile: individu.tel_mobile });
      this.echangeForm.patchValue({ personne_sujet_mail: individu.mail });
      this.echangeForm.patchValue({ personne_sujet_adr_num: individu.adr_num });
      this.echangeForm.patchValue({ personne_sujet_adr_complement: individu.adr_complement });
      this.echangeForm.patchValue({ personne_sujet_adr_voie: individu.adr_voie });
      this.echangeForm.patchValue({ personne_sujet_adr_complement1: individu.adr_complement1 });
      this.echangeForm.patchValue({ personne_sujet_adr_complement2: individu.adr_complement2 });
      this.echangeForm.patchValue({ personne_sujet_adr_code_postal: individu.adr_code_postale });
      this.echangeForm.patchValue({ personne_sujet_adr_lieudit: individu.adr_lieudit });

      if (!Globals.isNullOrUndefined(individu.adr_commune)) {
        this.echangeForm.patchValue({ personne_sujet_adr_commune: individu.adr_commune });
      }

      this.echangeForm.patchValue({ association: individu.association });

      if (individu.association && individu.association.agence_reference) {
        this.echangeForm.patchValue({ agence: individu.association.agence_reference });
      }

      if (!Globals.isNullOrUndefined(individu.nature)) {
        this.echangeForm.patchValue({ personne_sujet_nature: individu.nature });
      }

      this.echangeForm.patchValue({ personne_sujet_id_philia: individu.id_philia });
    }

    this.showHistorique = true;
    this.hidePopupIndividus();
  }

  setAssociationFound(event) {
    let association = event[0];
    this.echangeForm.patchValue({ federation: association.federation });
    this.echangeForm.patchValue({ association: association });
  }

  setSensField() {
    this.echangeForm.get('type_echange_sens').enable();
    this.echangeForm.patchValue({ type_echange_sens: 'entrant' });

    /*if (this.echangeForm.controls['type_echange'].value.code_parametre === 'WEB'
    || this.echangeForm.controls['type_echange'].value.code_parametre === 'PAS'
    || this.echangeForm.controls['type_echange'].value.code_parametre === 'REP') {
        this.echangeForm.get('type_echange_sens').disable();

    } else */
    if (this.echangeForm.controls['type_echange'].value.code_parametre === 'SYS'
      || this.echangeForm.controls['type_echange'].value.code_parametre === 'PER') {
      this.echangeForm.patchValue({ type_echange_sens: null });
    }
  }

  submitAndValid(fermerOnglet: boolean = false) {
    this.agenceRequired = false;
    // On initialise le champ tier nom si necessaire et que le nom est vide
    if (this.isTiers) { // Tiers
      if (this.echangeForm.value.personne_tiers_nom.match(/^ *$/)) { // correspond à un espace
        this.echangeForm.patchValue({ personne_tiers_nom: 'Non renseigné' });
      }
    }
    if (this.echangeForm.value && this.echangeForm.value.agence) {
      if (this.echange.statut_echange !== null && this.echange.statut_echange !== undefined) {
        if (this.echange.statut_echange.code_parametre === 'B' || this.echange.statut_echange.code_parametre === 'A') {
          if (this.currentUtilisateur.isPersonia) {
            this.echangeForm.patchValue({ statut_echange: this.statutAstreinte });
          } else {
            this.echangeForm.patchValue({ statut_echange: this.statutOuvert });
          }
        }
      } else {
        if (this.currentUtilisateur.isPersonia) {
          this.echangeForm.patchValue({ statut_echange: this.statutAstreinte });
        } else {
          this.echangeForm.patchValue({ statut_echange: this.statutOuvert });
        }
      }
      this.onSubmit(fermerOnglet);
    } else {
      this.agenceRequired = true;
    }
  }

  toggleCreateProspect(event) {
    this.createProspect = !this.createProspect;
  }

  toggleTiersConcerne() {
    this.isTiersConcerneShow = !this.isTiersConcerneShow;

    if (!this.isTiersConcerneShow) {
      this.disableTiersConcerneControls();
    } else {
      this.enableTiersConcerneControls();
    }
  }

  updateAgencesItems() {
    let agencesItemsList = [];
    if (this.agencesList) {
      this.agencesList.forEach(function (agence) {
        if (agence.disabled) {
          agencesItemsList.push({ label: agence.libelle, value: agence, styleClass: 'italic' });
        } else {
          agencesItemsList.push({ label: agence.libelle, value: agence });
        }
      });
    }
    this.agencesItemsList = agencesItemsList;
  }

  updateAssociationsItems() {
    let associationsItemsList = [];
    if (this.associationsList) {
      this.associationsList.forEach(function (association) {
        if (association.disabled) {
          associationsItemsList.push({ label: association.libelle, value: association, styleClass: 'italic' });
        } else {
          associationsItemsList.push({ label: association.libelle, value: association });
        }
      });
    }

    this.associationsItemsList = associationsItemsList;
  }

  updateSrcDocument(document: DocumentEchange) {
    this.currentImageExtension = document.nom.split('.').pop();
    if (!document.file) {
      if (this.currentImageExtension === 'pdf') {
        this.currentImageSrc = {
          url: this.documentEchangeService.getDocumentImageSrc(document.id_document_echange),
          withCredentials: true,
          httpHeaders: {
            Authorization: `Bearer ${this.currentUtilisateur.token}`
          }
        };
      } else {
        // this.currentImageSrc = this.documentEchangeService.getDocumentImageSrc(document.id_document) + '?token=' + this.currentUtilisateur.token;
        this.documentEchangeService.getImage(document.id_document_echange).subscribe((blob_document: any) => {
          // this.currentImageSrc = 'data:image/' + 'jpeg' + ';base64,' + blob_document;
          let self = this;
          const reader = new FileReader();
          reader.readAsDataURL(blob_document);
          reader.onloadend = function () {
            // result includes identifier 'data:image/png;base64,' plus the base64 data
            self.currentImageSrc = reader.result;
          };
        });
      }
    } else {
      this.currentImageSrc = document.file;
    }

  }

  /**
   * Appel du récapitulatif client pour l'individu donné.
   */
  goHistorique() {
    let idPhilia = this.echangeForm.controls['personne_sujet_id_philia'].value;
    let url = this.router.createUrlTree(['/in/recapitulatif-client/' + idPhilia]);
    window.open(url.toString(), '_blank');
  }

  updateContextMenuAction(event) {
    let selectedAction = event.data;
    let disabledCloseAction = this.actionIsClosedMenu(this.currentUtilisateur, selectedAction)
      && this.echange.statut_echange.code_parametre != 'F'
      && ((this.currentUtilisateur.est_administrateur === 'O') || (selectedAction.redacteur.id_utilisateur == this.currentUtilisateur.id_utilisateur));
    this.itemsContextMenu = [];
    if (disabledCloseAction) {
      this.itemsContextMenu.push({ label: 'Rouvrir l\'action', icon: 'pi pi-replay', command: () => this.openReactivateEchangeModal(selectedAction) });
    } else {
      this.itemsContextMenu.push({ label: 'Aucune action possible' });
    }
  }

  actionIsClosedMenu(currentUtilisateur: Utilisateur, selectedAction) {
    if (!selectedAction.statut_action) {
      return false;
    }

    // Action close ?
    let isClosed = selectedAction.statut_action.code_parametre === 'F';

    // Utilisateur en lecture => Echange clos (pas de modif)
    if (!isClosed
      && ((this.echange && (currentUtilisateur.hasFonctionnalite(DroitModule.module_echanges, DroitModule.fonctionnalite_edit_echanges) || currentUtilisateur.hasFonctionnalite(DroitModule.module_echanges, DroitModule.fonctionnalite_edit_actions)))
      )) {
      isClosed = false;
    } else {
      isClosed = true;
    }

    return isClosed;
  }

  openReactivateEchangeModal(selectedAction) {
    console.clear();
    console.log(selectedAction);
    this.displayReactivateEchangeModal = true;
  }

  cancelReactivateEchangeModal() {
    this.displayReactivateEchangeModal = false;
    this.motifReactivateAction = null;
  }

  saveReactivateEchange() {
    this.actionService.getActionById(this.selectedAction.id_action).subscribe(res => {
      this.parametreService.getParametresByParams({ type: 'STATUT_A' })
        .subscribe((parametres: Parametre[]) => {
          res.echange = this.echange;
          res.statut_action = parametres.find(p => p.code_parametre == 'E');
          res.description_action += "\n"
            + `L’action créée le ${this.formatDate(new Date(res.created))} a été rouverte le ${this.formatDate(new Date())} par l’utilisateur ${this.currentUtilisateur.prenom_nom}`
            + "\n" + this.motifReactivateAction
            + "\n" + `[Modifié par ${this.currentUtilisateur.login} le ${this.formatDate(new Date())}]`;
          this.actionService.updateAction(res).subscribe(res => {
            this.cancelReactivateEchangeModal();
            this.actionReopened.emit();
          });
        });
    });
  }

  formatDate(date: Date): string {
    if (!(date instanceof Date)) {
      throw new TypeError('The provided value is not a Date object.');
    }

    const pad = (n: number) => n.toString().padStart(2, '0');

    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1); // Les mois sont basés sur zéro
    const year = date.getFullYear();

    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }


  searchAnnuaireExterne() {
    this.loadingAnnuaireExterne = true;
    let federationId = 0;
    let raisonSocial = null;
    let nom = null;
    let prenom = null;
    if (this.echangeForm !== null && this.echangeForm !== undefined) {
      // création
      if (this.echangeForm.value.federation !== null && this.echangeForm.value.federation !== undefined) {
        federationId = this.echangeForm.value.federation.id_federation;
      }
      if (this.echangeForm.value.personne_sujet_societe !== null && this.echangeForm.value.personne_sujet_societe !== undefined) {
        raisonSocial = this.echangeForm.value.personne_sujet_societe;
      }
      if (this.echangeForm.value.personne_sujet_nom !== null && this.echangeForm.value.personne_sujet_nom !== undefined) {
        nom = this.echangeForm.value.personne_sujet_nom;
      }
      if (this.echangeForm.value.personne_sujet_prenom !== null && this.echangeForm.value.personne_sujet_prenom !== undefined) {
        prenom = this.echangeForm.value.personne_sujet_prenom;
      }
    } else if (this.formMode === 'edit') {
      // modification
      federationId = this.echange.federation.id_federation;
      raisonSocial = this.echange.personne_sujet_societe;
      nom = this.echange.personne_sujet_nom;
      prenom = this.echange.personne_sujet_prenom;
    }
    let data = {};
    if (federationId != null) {
      data['federation'] = federationId;
    }
    if (raisonSocial != null) {
      data['raisonSocial'] = raisonSocial;
    }
    if (nom != null) {
      data['contactsNom'] = nom;
    }
    if (prenom != null) {
      data['contactsPrenom'] = prenom;
    }
    this.utilisateurService.getPartenairesByParams(data).subscribe(res => {
      this.listeAnnuaireExt = res;
      this.loadingAnnuaireExterne = false;
      if (this.echangeForm.value.personne_sujet_nom || this.echangeForm.value.personne_sujet_prenom) {
        this.expandAllRows();
      }
      this.displayAnnuaireExterneModal = true;
    });
  }

  expandAllRows() {
    this.listeAnnuaireExt.forEach(item => {
      this.expandedRows[item.id] = true;
    });
  }

  selectAnnuaire(entite, parent = null) {
    this.selectAnnuaireExtTmp = entite;
    if (parent) {
      this.selectAnnuaireExtTmp.libelle_parent = parent.raisonSociale;
    }
  }

  closeAnnuaireExterneModal() {
    this.selectAnnuaireExtTmp = null;
  }

  saveAnnuaireExt() {
    if (Object.keys(this.selectAnnuaireExtTmp).includes("raisonSociale")) {
      this.echangeForm.patchValue({ personne_sujet_societe: this.selectAnnuaireExtTmp.raisonSociale });
      this.echangeForm.patchValue({ personne_sujet_tel_travail: this.selectAnnuaireExtTmp.telephone });
      this.echangeForm.patchValue({ personne_sujet_mail: this.selectAnnuaireExtTmp.email });
    } else {
      switch (this.selectAnnuaireExtTmp.civilite) {
        case "mme":
        case "madame":
          this.echangeForm.patchValue({ personne_sujet_civilite: this.civilitesList.find(civ => civ.code_parametre == "MME") });
          break;
        case "monsieur":
          this.echangeForm.patchValue({ personne_sujet_civilite: this.civilitesList.find(civ => civ.code_parametre == "M.") });
          break;

        default:
          this.echangeForm.patchValue({ personne_sujet_civilite: this.civilitesList.find(civ => civ.code_parametre == "AU") });
          break;
      }
      this.echangeForm.patchValue({ personne_sujet_societe: this.selectAnnuaireExtTmp.libelle_parent });
      this.echangeForm.patchValue({ personne_sujet_nom: this.selectAnnuaireExtTmp.nom });
      this.echangeForm.patchValue({ personne_sujet_prenom: this.selectAnnuaireExtTmp.prenom });
      this.echangeForm.patchValue({ personne_sujet_tel_travail: this.selectAnnuaireExtTmp.telephone });
      this.echangeForm.patchValue({ personne_sujet_mail: this.selectAnnuaireExtTmp.email });
    }
    this.echangeForm.patchValue({ personne_sujet_nature: this.naturesList.find(nat => nat.code_parametre == "TIA") });
    this.displayAnnuaireExterneModal = false;
    this.selectAnnuaireExtTmp = null;
  }
}
