import {FormControl, FormGroup, Validators} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {interventionOperationValidator} from '../_directives/intervention-operation-validator.directive';
import {InterventionBesoin} from './intervention-besoin';
import {Parametre} from './parametre';


export class InterventionOperation {

  id_intervention_operation: number;

  @Type(() => Parametre)
  type_operation: Parametre;

  @Type(() => InterventionBesoin)
  intervention_besoin: InterventionBesoin;

  consignes: string;

  aide_petit_dejeuner = false;

  aide_dejeuner = false;

  aide_gouter = false;

  aide_diner = false;

  @Type(() => Parametre)
  piece: Parametre;

  @Type(() => Parametre)
  detail: Parametre;

  @Type(() => Parametre)
  operations: Parametre[] = [];

  get operationsLabel() {
    let operationsLabel = '';
    if (this.operations) {
      this.operations.forEach(function (operation) {
        if (operationsLabel !== '') {
          operationsLabel += ', ';
        }
        operationsLabel += operation.libelle;
      });
    }

    return operationsLabel;
  }

  get operationsAideRepasLabel() {
    let operationsLabel = '';

    if (this.aide_petit_dejeuner) {
      operationsLabel += 'Petit déjeuner, ';
    }
    if (this.aide_dejeuner) {
      operationsLabel += 'Déjeuner, ';
    }
    if (this.aide_gouter) {
      operationsLabel += 'Goûter, ';
    }
    if (this.aide_diner) {
      operationsLabel += 'Dîner, ';
    }

    if (operationsLabel !== '') {
      operationsLabel = operationsLabel.substring(0, operationsLabel.length - 2);
    }

    return operationsLabel;
  }

  static getForm(intervention_operation = null): FormGroup {
    if (!intervention_operation) {
      intervention_operation = new InterventionOperation();
    }

    let form = new FormGroup({
      'id_intervention_operation': new FormControl(intervention_operation.id_intervention_operation),
      'type_operation': new FormControl(intervention_operation.type_operation),
      'intervention_besoin': new FormControl(intervention_operation.intervention_besoin),
      'consignes': new FormControl(intervention_operation.consignes),
      'aide_petit_dejeuner': new FormControl(intervention_operation.aide_petit_dejeuner),
      'aide_dejeuner': new FormControl(intervention_operation.aide_dejeuner),
      'aide_gouter': new FormControl(intervention_operation.aide_gouter),
      'aide_diner': new FormControl(intervention_operation.aide_diner),
      'piece': new FormControl(intervention_operation.piece),
      'detail': new FormControl(intervention_operation.detail),
      'operations': new FormControl(intervention_operation.operations ? intervention_operation.operations : []),
    }, {validators: interventionOperationValidator});

    if (intervention_operation.intervention_besoin && intervention_operation.intervention_besoin.type_besoin) {
      if (intervention_operation.intervention_besoin.type_besoin.code_parametre === 'PIENET') {
        form.get('piece').setValidators(Validators.required);
        form.get('operations').setValidators(Validators.required);
      }
      if (intervention_operation.intervention_besoin.type_besoin.code_parametre === 'AIDPER') {
        form.get('type_operation').setValidators(Validators.required);
      }
      if (intervention_operation.intervention_besoin.type_besoin.code_parametre === 'ALI') {
        form.get('type_operation').setValidators(Validators.required);
      }
      if (intervention_operation.intervention_besoin.type_besoin.code_parametre === 'ACCPRE') {
        form.get('type_operation').setValidators(Validators.required);
      }
      if (intervention_operation.intervention_besoin.type_besoin.code_parametre === 'JAR') {
        form.get('type_operation').setValidators(Validators.required);
      }
      if (intervention_operation.intervention_besoin.type_besoin.code_parametre === 'BRI') {
        form.get('type_operation').setValidators(Validators.required);
      }
    }

    form.updateValueAndValidity();

    return form;
  }

  serialize() {
    let serializeObject = classToPlain(this);

    if (this.type_operation !== null && this.type_operation !== undefined) {
      serializeObject['type_operation'] = this.type_operation.id_parametre;
    }

    if (this.piece !== null && this.piece !== undefined) {
      serializeObject['piece'] = this.piece.id_parametre;
    }

    if (this.detail !== null && this.detail !== undefined) {
      serializeObject['detail'] = this.detail.id_parametre;
    }

    serializeObject['operations'] = [];
    this.operations.forEach(function (operation) {
      serializeObject['operations'].push(operation.id_parametre);
    });

    return serializeObject;
  }

}
