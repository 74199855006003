import {Inject, Injectable} from '@angular/core';
import {AuthConfModel} from '../models/auth-conf.model';
import {AUTH_MODE} from '../auth.token';
import {MethodAuthService} from './method-auth.service';

@Injectable({
  providedIn: 'root'
})
export class MethodIframeService extends MethodAuthService{
  private readonly iframeId = 'auth-iframe';

  constructor(@Inject(AUTH_MODE) protected readonly authConf: AuthConfModel) {
    super();
  }

  protected close(): void {
    const iframe: HTMLElement | null = document.getElementById(this.iframeId);
    if (iframe) {
      iframe.parentNode?.removeChild(iframe);
    }
  }

  protected open(): void {
    const iframe = document.createElement('iframe');
    iframe.id = this.iframeId;
    iframe.src = this.authConf.urlAuth;
    iframe.style.position = 'fixed';
    iframe.style.top = '0';
    iframe.style.left = '0';
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.style.border = 'none';
    document.body.appendChild(iframe);
  }

  protected listen(callback: (message: MessageEvent) => void): void {
    window.addEventListener('message', callback);
  }


}
