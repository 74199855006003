export type TokenModel = string;

export interface TokenWrapperForbidden {
  forbidden: 'no-access-admrconnect' | 'uuid-not-found';
}

export interface TokenWrapperSuccess {
  accessToken: TokenModel;
  refreshToken: TokenModel;
}

export type TokenWrapper = TokenWrapperSuccess | TokenWrapperForbidden;

export function isTokenWrapperSuccess(token: TokenWrapper): token is TokenWrapperSuccess {
  return 'accessToken' in token && 'refreshToken' in token && token.refreshToken !== null && token.accessToken !== null ;
}

export function isTokenWrapperForbidden(token: TokenWrapper): token is TokenWrapperForbidden {
  return 'forbidden' in token;
}
