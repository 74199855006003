import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {AccompagnementActivite} from './accompagnement-activite';
import {EvaluationContenu} from './evaluation-contenu';
import {GroupeEquipement} from './groupe-equipement';
import {InterventionOperation} from './intervention-operation';
import {Parametre} from './parametre';
import {RepasEnfant} from './repas-enfant';


export class InterventionBesoin {

  id_intervention_besoin: number;

  @Type(() => Parametre)
  type_besoin: Parametre;

  commentaire: string;

  capacite_a_rechauffer_le_repas = true;

  depot_repas: boolean;

  surface: string;

  nombre_pieces: string;

  allergie_alimentaire: string;

  regime_alimentaire: string;

  schema_jardin: boolean;

  cles_jardin: boolean;

  conditions_acces: string;

  acces_toilettes: boolean;

  presence_point_eau: boolean;

  electricite_exterieur_prise_terre: boolean;

  dechetterie_proche: string;

  nuisances_sonores: string;

  @Type(() => EvaluationContenu)
  evaluation_contenu: EvaluationContenu;

  debut_matin_nuisance: string;

  fin_matin_nuisance: string;

  debut_apres_midi_nuisance: string;

  fin_apres_midi_nuisance: string;

  capacite_servir_seul = true;

  capacite_manger_seul = true;

  trouble_alimentaire: boolean;

  regime_autre_commentaire: string;

  repas_normal: boolean;

  repas_sans_sucre: boolean;

  repas_sans_sel: boolean;

  repas_sans_sel_strict: boolean;

  repas_sans_porc: boolean;

  repas_sans_oeuf: boolean;

  repas_hypocalorique: boolean;

  repas_sans_gluten: boolean;

  regime_autre: boolean;

  nombre_repas_lundi: number;

  livraison_repas_lundi: boolean;

  nombre_repas_mardi: number;

  livraison_repas_mardi: boolean;

  nombre_repas_mercredi: number;

  livraison_repas_mercredi: boolean;

  nombre_repas_jeudi: number;

  livraison_repas_jeudi: boolean;

  nombre_repas_vendredi: number;

  livraison_repas_vendredi: boolean;

  nombre_repas_samedi: number;

  livraison_repas_samedi: boolean;

  nombre_repas_dimanche: number;

  livraison_repas_dimanche: boolean;

  nombre_repas_jour_ferie: number;

  livraison_repas_jour_ferie: boolean;

  repas_mixe: boolean;

  trouble_deglutition: boolean;

  fausse_route: boolean;

  commentaire_habitude_alimentaire: string;

  commentaire_aide_repas: string;

  commentaire_livraison_repas: string;

  presence_client: boolean;

  clef_tiers: boolean;

  tiers: string;

  presence_haies: boolean;

  type_haies: string;

  presence_potager: boolean;

  surface_potager: string;

  surface_jardin: string;

  surface_pelouse: string;

  nbr_heures: number;

  @Type(() => Parametre)
  frequence: Parametre;

  ranger_linge: boolean;

  laver_linge: boolean;

  repasser_linge: boolean;

  secher_linge: boolean;

  @Type(() => InterventionOperation)
  interventions_operation: InterventionOperation[] = [];

  @Type(() => AccompagnementActivite)
  accompagnements_activite: AccompagnementActivite[] = [];

  @Type(() => GroupeEquipement)
  groupes_equipements: GroupeEquipement[] = [];

  @Type(() => RepasEnfant)
  repas_enfant: RepasEnfant;

  get typeBesoinEquipementLibelle() {
    let intervention_besoin_libelle = '';

    if (this.type_besoin) {
      intervention_besoin_libelle = InterventionBesoin.getTypeBesoinEquipementLibelle(this.type_besoin);
    }
    return intervention_besoin_libelle;
  }

  static getForm(intervention_besoin = null): FormGroup {
    if (!intervention_besoin) {
      intervention_besoin = new InterventionBesoin();
    }

    let interventionsOperationForm = [];
    intervention_besoin.interventions_operation.forEach(function (intervention_operation) {
      interventionsOperationForm.push(InterventionOperation.getForm(intervention_operation));
    });

    let accompagnementForm = [];
    intervention_besoin.accompagnements_activite.forEach(function (accompagnement_activite) {
      accompagnementForm.push(AccompagnementActivite.getForm(accompagnement_activite));
    });

    let groupesEquipementsForm = [];
    intervention_besoin.groupes_equipements.forEach(function (groupe_equipements) {
      groupesEquipementsForm.push(GroupeEquipement.getForm(groupe_equipements));
    });

    return new FormGroup({
      'id_intervention_besoin': new FormControl(intervention_besoin.id_intervention_besoin),
      'type_besoin': new FormControl(intervention_besoin.type_besoin),
      'commentaire': new FormControl(intervention_besoin.commentaire),
      'capacite_a_rechauffer_le_repas': new FormControl(intervention_besoin.capacite_a_rechauffer_le_repas),
      'depot_repas': new FormControl(intervention_besoin.depot_repas),
      'surface': new FormControl(intervention_besoin.surface),
      'nombre_pieces': new FormControl(intervention_besoin.nombre_pieces),
      'allergie_alimentaire': new FormControl(intervention_besoin.allergie_alimentaire),
      'regime_alimentaire': new FormControl(intervention_besoin.regime_alimentaire),
      'schema_jardin': new FormControl(intervention_besoin.schema_jardin),
      'cles_jardin': new FormControl(intervention_besoin.cles_jardin),
      'conditions_acces': new FormControl(intervention_besoin.conditions_acces),
      'acces_toilettes': new FormControl(intervention_besoin.acces_toilettes),
      'presence_point_eau': new FormControl(intervention_besoin.presence_point_eau),
      'electricite_exterieur_prise_terre': new FormControl(intervention_besoin.electricite_exterieur_prise_terre),
      'dechetterie_proche': new FormControl(intervention_besoin.dechetterie_proche),
      'nuisances_sonores': new FormControl(intervention_besoin.nuisances_sonores),
      'evaluation_contenu': new FormControl(intervention_besoin.evaluation_contenu),
      'debut_matin_nuisance': new FormControl(intervention_besoin.debut_matin_nuisance),
      'fin_matin_nuisance': new FormControl(intervention_besoin.fin_matin_nuisance),
      'debut_apres_midi_nuisance': new FormControl(intervention_besoin.debut_apres_midi_nuisance),
      'fin_apres_midi_nuisance': new FormControl(intervention_besoin.fin_apres_midi_nuisance),
      'capacite_servir_seul': new FormControl(intervention_besoin.capacite_servir_seul),
      'capacite_manger_seul': new FormControl(intervention_besoin.capacite_manger_seul),
      'trouble_alimentaire': new FormControl(intervention_besoin.trouble_alimentaire),
      'regime_autre_commentaire': new FormControl(intervention_besoin.regime_autre_commentaire),
      'repas_normal': new FormControl(intervention_besoin.repas_normal),
      'repas_sans_sucre': new FormControl(intervention_besoin.repas_sans_sucre),
      'repas_sans_sel': new FormControl(intervention_besoin.repas_sans_sel),
      'repas_sans_sel_strict': new FormControl(intervention_besoin.repas_sans_sel_strict),
      'repas_sans_porc': new FormControl(intervention_besoin.repas_sans_porc),
      'repas_sans_oeuf': new FormControl(intervention_besoin.repas_sans_oeuf),
      'repas_hypocalorique': new FormControl(intervention_besoin.repas_hypocalorique),
      'repas_sans_gluten': new FormControl(intervention_besoin.repas_sans_gluten),
      'regime_autre': new FormControl(intervention_besoin.regime_autre),
      'nombre_repas_lundi': new FormControl(intervention_besoin.nombre_repas_lundi),
      'livraison_repas_lundi': new FormControl(intervention_besoin.livraison_repas_lundi),
      'nombre_repas_mardi': new FormControl(intervention_besoin.nombre_repas_mardi),
      'livraison_repas_mardi': new FormControl(intervention_besoin.livraison_repas_mardi),
      'nombre_repas_mercredi': new FormControl(intervention_besoin.nombre_repas_mercredi),
      'livraison_repas_mercredi': new FormControl(intervention_besoin.livraison_repas_mercredi),
      'nombre_repas_jeudi': new FormControl(intervention_besoin.nombre_repas_jeudi),
      'livraison_repas_jeudi': new FormControl(intervention_besoin.livraison_repas_jeudi),
      'nombre_repas_vendredi': new FormControl(intervention_besoin.nombre_repas_vendredi),
      'livraison_repas_vendredi': new FormControl(intervention_besoin.livraison_repas_vendredi),
      'nombre_repas_samedi': new FormControl(intervention_besoin.nombre_repas_samedi),
      'livraison_repas_samedi': new FormControl(intervention_besoin.livraison_repas_samedi),
      'nombre_repas_dimanche': new FormControl(intervention_besoin.nombre_repas_dimanche),
      'livraison_repas_dimanche': new FormControl(intervention_besoin.livraison_repas_dimanche),
      'nombre_repas_jour_ferie': new FormControl(intervention_besoin.nombre_repas_jour_ferie),
      'livraison_repas_jour_ferie': new FormControl(intervention_besoin.livraison_repas_jour_ferie),
      'repas_mixe': new FormControl(intervention_besoin.repas_mixe),
      'trouble_deglutition': new FormControl(intervention_besoin.trouble_deglutition),
      'fausse_route': new FormControl(intervention_besoin.fausse_route),
      'commentaire_habitude_alimentaire': new FormControl(intervention_besoin.commentaire_habitude_alimentaire),
      'commentaire_aide_repas': new FormControl(intervention_besoin.commentaire_aide_repas),
      'commentaire_livraison_repas': new FormControl(intervention_besoin.commentaire_livraison_repas),
      'presence_client': new FormControl(intervention_besoin.presence_client),
      'clef_tiers': new FormControl(intervention_besoin.clef_tiers),
      'tiers': new FormControl(intervention_besoin.tiers),
      'presence_haies': new FormControl(intervention_besoin.presence_haies),
      'type_haies': new FormControl(intervention_besoin.type_haies),
      'presence_potager': new FormControl(intervention_besoin.presence_potager),
      'surface_potager': new FormControl(intervention_besoin.surface_potager),
      'surface_jardin': new FormControl(intervention_besoin.surface_jardin),
      'surface_pelouse': new FormControl(intervention_besoin.surface_pelouse),
      'nbr_heures': new FormControl(intervention_besoin.nbr_heures),
      'frequence': new FormControl(intervention_besoin.frequence),
      'ranger_linge': new FormControl(intervention_besoin.ranger_linge),
      'laver_linge': new FormControl(intervention_besoin.laver_linge),
      'repasser_linge': new FormControl(intervention_besoin.repasser_linge),
      'secher_linge': new FormControl(intervention_besoin.secher_linge),
      'interventions_operation': new FormArray(interventionsOperationForm),
      'accompagnements_activite': new FormArray(accompagnementForm),
      'groupes_equipements': new FormArray(groupesEquipementsForm),
      'repas_enfant': RepasEnfant.getForm(intervention_besoin.repas_enfant)
    });
  }

  static getTypeBesoinEquipementLibelle(type_besoin) {
    let intervention_besoin_libelle = '';

    if (type_besoin) {
      intervention_besoin_libelle = type_besoin.libelle;

      if (type_besoin.code_parametre === 'PIENET' || type_besoin.code_parametre === 'ENTLIN') {
        intervention_besoin_libelle = 'Ménage - Linge';
      }

      if (type_besoin.code_parametre === 'JAR' || type_besoin.code_parametre === 'BRI') {
        intervention_besoin_libelle = 'Jardinage - Bricolage';
      }
    }
    return intervention_besoin_libelle;
  }

  hasSaisie() {
    if ((this.interventions_operation && this.interventions_operation.length > 0)
      || this.commentaire) {
      return true;
    }

    if (this.type_besoin.code_parametre === 'ALI') {
      if (this.hasAlimentationSaisie()
        || this.hasCapaciteSaisie()
        || this.hasTroubleAlimentaireSaisie()
        || this.commentaire_habitude_alimentaire
        || this.hasLivraisonRepasSaisie()) {
        return true;
      }
    } else if (this.type_besoin.code_parametre === 'JAR') {
      if (this.cles_jardin
        || this.acces_toilettes
        || this.dechetterie_proche
        || this.presence_point_eau
        || this.electricite_exterieur_prise_terre
        || this.debut_matin_nuisance || this.fin_matin_nuisance
        || this.debut_apres_midi_nuisance || this.fin_apres_midi_nuisance
        || this.schema_jardin || this.surface_jardin || this.surface_pelouse
        || this.presence_haies || this.presence_potager || this.surface_potager) {
        return true;
      }
    } else if (this.type_besoin.code_parametre === 'PIENET') {
      if (this.surface
        || this.nombre_pieces) {
        return true;
      }
    }

    return false;
  }

  hasAlimentationSaisie() {
    if (this.repas_normal
      || this.repas_sans_sucre
      || this.repas_sans_sel
      || this.repas_sans_sel_strict
      || this.repas_sans_porc
      || this.repas_sans_oeuf
      || this.repas_hypocalorique
      || this.repas_sans_gluten
      || this.regime_autre) {
      return true;
    }
    return false;
  }

  hasCapaciteSaisie() {
    if (this.capacite_a_rechauffer_le_repas
      || this.capacite_servir_seul
      || this.capacite_manger_seul) {
      return true;
    }
    return false;
  }

  hasTroubleAlimentaireSaisie() {
    if (this.trouble_alimentaire) {
      return true;
    }
    return false;
  }

  hasLivraisonRepasSaisie() {
    if (this.livraison_repas_lundi
      || this.livraison_repas_mardi
      || this.livraison_repas_mercredi
      || this.livraison_repas_jeudi
      || this.livraison_repas_vendredi
      || this.livraison_repas_samedi
      || this.livraison_repas_dimanche
      || this.livraison_repas_jour_ferie
      || this.commentaire_livraison_repas) {
      return true;
    }
    return false;
  }

  serialize() {
    let serializeObject = classToPlain(this);

    if (this.type_besoin !== null && this.type_besoin !== undefined) {
      serializeObject['type_besoin'] = this.type_besoin.id_parametre;
    }

    if (this.frequence !== null && this.frequence !== undefined) {
      serializeObject['frequence'] = this.frequence.id_parametre;
    }

    serializeObject['interventions_operation'] = [];
    this.interventions_operation.forEach(function (intervention_operation) {
      serializeObject['interventions_operation'].push(intervention_operation.serialize());
    });

    serializeObject['accompagnements_activite'] = [];
    this.accompagnements_activite.forEach(function (accompagnement_activite) {
      serializeObject['accompagnements_activite'].push(accompagnement_activite.serialize());
    });

    serializeObject['groupes_equipements'] = [];
    this.groupes_equipements.forEach(function (groupe_equipements) {
      serializeObject['groupes_equipements'].push(groupe_equipements.serialize());
    });

    if (this.repas_enfant !== null && this.repas_enfant !== undefined) {
      serializeObject['repas_enfant'] = this.repas_enfant.serialize();
    }

    return serializeObject;
  }
}
