import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import {plainToClass} from 'class-transformer';

import {SelectItem} from 'primeng/api';

import { DroitModule } from '../_models';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class DroitModuleService {

    constructor(private http: HttpClient) { }

  /** GET droits_module */
  getAll(): Observable<DroitModule[]> {
    return this.http.get<DroitModule[]>(`${environment.apiHost}/${environment.apiNamespace}/droits_module`).pipe(
      catchError(this.handleError)
    );
  }

  /** GET droitModules */
  getDroitModulesByParams(paramsValue: any): Observable<DroitModule[]> {
    let params = new HttpParams();
    for (let paramKey in paramsValue) {
      if (paramsValue.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValue[paramKey]);
      }
    }
    return this.http.get<DroitModule[]>(`${environment.apiHost}/${environment.apiNamespace}/droits_module`, { params: params });
  }

   /** GET droitModules/:id */
   getDroitModule(id_droitModule: number): Observable<DroitModule> {
    return this.http.get<DroitModule>(`${environment.apiHost}/${environment.apiNamespace}/droits_module/${id_droitModule}`);
  }

  /* handleError */
  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(error);
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a utilisateur-facing error message
    return throwError(
      error);
  }

}
