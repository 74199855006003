import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../shared/_guards';
import {DocumentationGestionComponent} from './documentation-gestion/documentation-gestion.component';
import {DocumentationTableauBordComponent} from './documentation-tableau-bord/documentation-tableau-bord.component';

const documentationRoutes: Routes = [
  {
    path: 'in/documentation/tableau-bord',
    canActivate: [AuthGuard],
    component: DocumentationTableauBordComponent
  },
  {
    path: 'in/documentation/gestion/raccordement-modus',
    canActivate: [AuthGuard],
    component: DocumentationGestionComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(documentationRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class DocumentationRoutingModule {
}
