import {ModuleWithProviders, NgModule} from '@angular/core';
import {JWT_OPTIONS, JwtModule} from '@auth0/angular-jwt';
import {JwtConfig} from '@auth0/angular-jwt/lib/angular-jwt.module';
import {take} from 'rxjs/operators';
import {AUTH_MODE, AUTH_STORAGE} from './auth.token';
import {AuthMode} from './enums/auth-mode.enum';
import {AuthMethod} from './enums/auth-method.enum';
import {MethodAuthService} from './method/method-auth.service';
import {MethodIframeService} from './method/method-iframe.service';
import {MethodRedirectService} from './method/method-redirect.service';
import {MethodTabService} from './method/method-tab.service';
import {AuthConfModel} from './models/auth-conf.model';
import {TokenService} from './token.service';

export function authFactory(
  authService: TokenService,
  conf: AuthConfModel
): JwtConfig {
  switch (conf.mode) {
    case AuthMode.COOKIE:
      return {};
    case AuthMode.JWT:
      return {
        tokenGetter: () =>
          authService.getToken().pipe(take(1)).toPromise() as Promise<string | null>,
        allowedDomains: conf.allowedDomains ?? [],
        disallowedRoutes: [conf.urlRefresh, ...(conf.disallowedRoutes ?? [])],
      };
  }
}

@NgModule({
  imports: [JwtModule.forRoot({})],
})
export class AuthJwtModule {
  static forRoot(conf: AuthConfModel): ModuleWithProviders<AuthJwtModule> {
    return {
      ngModule: AuthJwtModule,
      providers: [
        {provide: AUTH_MODE, useValue: conf},
        {
          provide: AUTH_STORAGE,
          useValue: conf.mode === AuthMode.JWT ? conf.storage : undefined,
        },
        {
          provide: MethodAuthService,
          useClass: conf.method !== AuthMethod.Redirect
            ? conf.method === AuthMethod.Iframe
              ? MethodIframeService
              : MethodTabService
            : MethodRedirectService
        },
        {
          provide: JWT_OPTIONS,
          useFactory: authFactory,
          deps: [TokenService, AUTH_MODE],
        },
      ],
    };
  }
}
