import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {ContactAvecEntourage} from './contact-avec-entourage';
import {EvaluationContenu} from './evaluation-contenu';
import {LoisirsEtActivites} from './loisirs-et-activites';
import {Parametre} from './parametre';
import {RythmeDeVie} from './rythme-de-vie';

export class Habitude {

  id_habitude: number;

  a_des_loisirs: boolean;

  rythme_de_vie: boolean;

  deplacement_exterieur: boolean;

  deplacement_commentaire: string;

  contact_avec_entourage: boolean;

  @Type(() => EvaluationContenu)
  evaluation_contenu: EvaluationContenu;

  medicament_prescription: boolean;

  medicament_precaution: string;

  @Type(() => Parametre)
  medicament_prepare_par: Parametre;

  @Type(() => Parametre)
  medicament_donne_par: Parametre;

  medicament_vigilance_prise: boolean;

  medicament_surveillance_prise: boolean;

  medicament_aide_prise: boolean;

  allergie_commentaire: string;

  medicament_semainier: boolean;

  @Type(() => RythmeDeVie)
  rythmes_de_vie: RythmeDeVie[] = [];

  @Type(() => LoisirsEtActivites)
  loisirs_et_activites: LoisirsEtActivites[] = [];

  @Type(() => ContactAvecEntourage)
  contacts_avec_entourage: ContactAvecEntourage[] = [];

  static getForm(habitude = null): FormGroup {
    if (!habitude) {
      habitude = new Habitude();
    }

    let rythmesDeVieForm = [];
    habitude.rythmes_de_vie.forEach(function (rythmes_de_vie) {
      rythmesDeVieForm.push(RythmeDeVie.getForm(rythmes_de_vie));
    });

    let loisirsEtActivitesForm = [];
    habitude.loisirs_et_activites.forEach(function (loisirs_et_activites) {
      loisirsEtActivitesForm.push(LoisirsEtActivites.getForm(loisirs_et_activites));
    });

    let contactsAvecEntourageForm = [];
    habitude.contacts_avec_entourage.forEach(function (contacts_avec_entourage) {
      contactsAvecEntourageForm.push(ContactAvecEntourage.getForm(contacts_avec_entourage));
    });

    return new FormGroup({
      'id_habitude': new FormControl(habitude.id_habitude),
      'a_des_loisirs': new FormControl(habitude.a_des_loisirs),
      'rythme_de_vie': new FormControl(habitude.rythme_de_vie),
      'deplacement_exterieur': new FormControl(habitude.deplacement_exterieur),
      'deplacement_commentaire': new FormControl(habitude.deplacement_commentaire),
      'contact_avec_entourage': new FormControl(habitude.contact_avec_entourage),
      'evaluation_contenu': new FormControl(habitude.evaluation_contenu),
      'medicament_prescription': new FormControl(habitude.medicament_prescription),
      'medicament_precaution': new FormControl(habitude.medicament_precaution),
      'medicament_prepare_par': new FormControl(habitude.medicament_prepare_par),
      'medicament_donne_par': new FormControl(habitude.medicament_donne_par),
      'medicament_vigilance_prise': new FormControl(habitude.medicament_vigilance_prise),
      'medicament_surveillance_prise': new FormControl(habitude.medicament_surveillance_prise),
      'medicament_aide_prise': new FormControl(habitude.medicament_aide_prise),
      'allergie_commentaire': new FormControl(habitude.allergie_commentaire),
      'medicament_semainier': new FormControl(habitude.medicament_semainier),
      'rythmes_de_vie': new FormArray(rythmesDeVieForm),
      'loisirs_et_activites': new FormArray(loisirsEtActivitesForm),
      'contacts_avec_entourage': new FormArray(contactsAvecEntourageForm),
    });
  }

  hasSaisie(): boolean {
    if (this.a_des_loisirs || this.rythme_de_vie
      || this.deplacement_exterieur || this.deplacement_commentaire
      || this.contact_avec_entourage
      || this.medicament_prescription || this.medicament_precaution || this.medicament_prepare_par || this.medicament_donne_par
      || this.medicament_vigilance_prise || this.medicament_surveillance_prise || this.medicament_aide_prise
      || this.allergie_commentaire || this.medicament_semainier
      || (this.rythmes_de_vie && this.rythmes_de_vie.length > 0)
      || (this.loisirs_et_activites && this.loisirs_et_activites.length > 0)
      || (this.contacts_avec_entourage && this.contacts_avec_entourage.length > 0)) {
      return true;
    }
    return false;
  }

  serialize() {
    let serializeObject = classToPlain(this);

    if (this.medicament_prepare_par !== null && this.medicament_prepare_par !== undefined) {
      serializeObject['medicament_prepare_par'] = this.medicament_prepare_par.id_parametre;
    }

    if (this.medicament_donne_par !== null && this.medicament_donne_par !== undefined) {
      serializeObject['medicament_donne_par'] = this.medicament_donne_par.id_parametre;
    }

    serializeObject['rythmes_de_vie'] = [];
    this.rythmes_de_vie.forEach(function (rythme_de_vie) {
      serializeObject['rythmes_de_vie'].push(rythme_de_vie.serialize());
    });

    serializeObject['loisirs_et_activites'] = [];
    this.loisirs_et_activites.forEach(function (loisir_et_activite) {
      serializeObject['loisirs_et_activites'].push(loisir_et_activite.serialize());
    });

    serializeObject['contacts_avec_entourage'] = [];
    this.contacts_avec_entourage.forEach(function (contact_avec_entourage) {
      serializeObject['contacts_avec_entourage'].push(contact_avec_entourage.serialize());
    });

    return serializeObject;
  }
}
