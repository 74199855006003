<p-dialog *ngIf="currentEntourageForm" [(visible)]="showPopupEntourage" [style]="{maxWidth: '90%', minWidth:'80%'}"
  [contentStyle]="{'max-height':'540px'}" (onHide)="onCloseEntouragePopup($event)" baseZIndex="10001">
  <p-header>
      <div class="ui-g-8" style="font-size: 15px;">Entourage
        <span *ngIf="currentEntourageForm.value.entourage_personnel">Personnel</span>
        <span *ngIf="currentEntourageForm.value.entourage_professionnel">Professionnel</span>
        <span *ngIf="currentEntourageForm.value.enfant">Enfant</span>
      </div>
      <div class="ui-g-3" style="text-align: right;">
        <button pButton type="button" class="white-button small-button" style="width: 150px;" label="Enregistrer"
        (click)="saveEntourage()" [disabled]="!currentEntourageForm.valid"></button>
      </div>
  </p-header>

  <div class="ui-grid-row" *ngIf="typeEntourageSelectable">
    <div style="margin: auto;">
      <p-radioButton name="type_entourage" value="personnel" [(ngModel)]="currentTypeEntourage" (onClick)="setCurrentTypeEntourage('personnel')" label="Personnel"></p-radioButton>&nbsp;
      <p-radioButton name="type_entourage" value="professionnel" [(ngModel)]="currentTypeEntourage" (onClick)="setCurrentTypeEntourage('professionnel')" label="Professionnel"></p-radioButton>
    </div>
  </div>

  <!-- PROFESSIONNEL -->

  <div *ngIf="currentEntourageForm.value.entourage_professionnel">
    <div class="ui-grid-row" [formGroup]="currentEntourageForm">
      <div class="ui-g-2">
        <p-dropdown [options]="civilitesProList" [style]="{width: '100%'}" formControlName="civilite"
          optionLabel="libelle" placeholder="Civilité"></p-dropdown>
      </div>
      <div class="ui-g-4">
        <input pInputText type="text" formControlName="nom" placeholder="Nom*" />
      </div>
      <div class="ui-g-3">
        <input pInputText type="text" formControlName="prenom" placeholder="Prénom" />
      </div>
      <div class="ui-g-1">
        <label>Spécialité</label>
      </div>
      <div class="ui-g-2">
        <p-dropdown [options]="specialitesList" [style]="{width: '100%'}" formControlName="lien_specialite" placeholder="Spécialité"
          optionLabel="libelle"></p-dropdown>
      </div>
    </div>

    <div class="ui-grid-row">
      <div class="ui-g-12" style="text-align: right;">
        <p-toggleButton [(ngModel)]="showAdresse" onLabel="Adresse" offLabel="Adresse" [onIcon]="'pi pi-minus'"
          offIcon="pi pi-plus" [style]="{'width':'150px'}"></p-toggleButton>
      </div>
    </div>

    <div *ngIf="showAdresse" [formGroup]="currentEntourageForm">
      <div class="ui-grid-row">
        <div class="ui-g-12">
          <input pInputText type="text" formControlName="cplt_adresse_1" placeholder="Raison sociale, service" />
        </div>
      </div>
      <div class="ui-grid-row">
        <div class="ui-g-12">
          <input pInputText type="text" formControlName="cplt_adresse_2"
            placeholder="Entrée, Bât, Immeuble, Résidence" />
        </div>
      </div>
      <div class="ui-grid-row">
        <div class="ui-g-1">
          <input pInputText type="text" formControlName="numero_rue" placeholder="N°" />
        </div>
        <div class="ui-g-1">
          <p-dropdown [options]="typesRueList" [style]="{width: '100%'}" formControlName="type_complement_adresse"
            optionLabel="libelle"></p-dropdown>
        </div>
        <div class="ui-g-10">
          <input pInputText type="text" formControlName="rue" placeholder="Nom de la rue" />
        </div>
      </div>
      <div class="ui-grid-row">
        <div class="ui-g-12">
          <input pInputText type="text" formControlName="cplt_adresse_3"
            placeholder="Lieu dit, Ancienne commune" />
        </div>
      </div>
      <div class="ui-grid-row">
        <div class="ui-g-2">
          <input pInputText type="text" formControlName="cp" placeholder="Code postal" />
        </div>
        <div class="ui-g-7">
          <input pInputText type="text" formControlName="ville" placeholder="Ville" />
        </div>
        <div class="ui-g-3">
          <input pInputText type="text" formControlName="pays" placeholder="Pays" />
        </div>
      </div>
    </div>

    <div class="ui-grid-row" [formGroup]="currentEntourageForm">
      <div class="ui-g-6">
        <div class="ui-grid-row">
          <div class="ui-g-6">
            <label>Représentant légal</label>
          </div>
          <div class="ui-g-6">
            <p-inputSwitch formControlName="representant_legal"></p-inputSwitch>
          </div>
        </div>
        <div class="ui-grid-row" *ngIf="currentEntourageForm.value.representant_legal">
          <div class="ui-g-2">
            <label>Type</label>
          </div>
          <div class="ui-g-8">
            <p-dropdown [options]="typesRepresentantList" [style]="{width: '100%'}" formControlName="type_representant"
              optionLabel="libelle"></p-dropdown>
          </div>
        </div>
        <div class="ui-grid-row">
          <div class="ui-g-6">
            <label>A contacter en cas d'urgence</label>
          </div>
          <div class="ui-g-6">
            <p-inputSwitch formControlName="a_contacter_urgence"></p-inputSwitch>
          </div>
        </div>
        <div class="ui-grid-row">
            <div class="ui-g-6">
               <label>Présent lors de la visite</label>
            </div>
            <div class="ui-g-6">
               <p-inputSwitch formControlName="present_visite"></p-inputSwitch>
            </div>
         </div>
         <div *ngIf="evaluationForm.getRawValue().type_public?.code_parametre !== 'ENFPAR'
            && (currentEntourageForm.value.lien_specialite?.code_parametre == 'SOIDOM'
                || currentEntourageForm.value.lien_specialite?.code_parametre == 'INF'
                || currentEntourageForm.value.lien_specialite?.code_parametre == 'SSIAD')"
                class="ui-grid-row">
          <div class="ui-g-3" style="text-align: center; margin-top: 5px">
                <label>Fréquence</label>
             </div>
          <div class="ui-g-2">
             <input type="text" pInputText formControlName="nombre_frequence" />
          </div>
          <div class="ui-g-3" style="text-align: center; margin-top: 5px">
             <p>fois par</p>
          </div>
          <div class="ui-g-4">
             <p-dropdown [options]="unitesTempsList" [placeholder]="'Fréquence'" [style]="{width: '100%'}"
                formControlName="frequence" optionLabel="libelle"></p-dropdown>
          </div>
       </div>
      </div>

      <div class="ui-g-6">
        <div class="ui-grid-row" [formGroup]="currentEntourageForm">
          <div class="ui-g-12">
            <div class="ui-inputgroup">
              <span class="ui-inputgroup-addon"><i class="fa fa-phone"></i></span>
              <input pInputText type="text" formControlName="tel_fixe" placeholder="Fixe"/>
            </div>
          </div>
        </div>
        <div class="ui-grid-row" [formGroup]="currentEntourageForm">
          <div class="ui-g-12">
            <div class="ui-inputgroup">
              <span class="ui-inputgroup-addon"><i class="fa fa-mobile"></i></span>
              <input pInputText type="text" formControlName="tel_mobile" placeholder="Mobile" />
            </div>
          </div>
        </div>
        <div class="ui-grid-row" [formGroup]="currentEntourageForm">
          <div class="ui-g-12">
            <div class="ui-inputgroup">
              <span class="ui-inputgroup-addon"><i class="fa fa-envelope"></i></span>
              <input pInputText type="text" formControlName="email" placeholder="Email" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ui-grid-row">
      <label>Informations complémentaires</label>
    </div>
    <div class="ui-grid-row" [formGroup]="currentEntourageForm">
      <textarea pInputTextarea [rows]="5" formControlName="commentaire"></textarea>
    </div>

    <div class="ui-grid-row" style="color: #C12300">
      <div class="ui-g-12">
        Requis *<br />
      </div>
    </div>

  </div>

  <!-- PERSONNEL -->

  <div *ngIf="currentEntourageForm.value.entourage_personnel">
    <div class="ui-grid-row" [formGroup]="currentEntourageForm">
      <div class="ui-g-2">
        <p-dropdown [options]="civilitesList" [style]="{width: '100%'}" formControlName="civilite"
          optionLabel="libelle" placeholder="Civilité"></p-dropdown>
      </div>
      <div class="ui-g-4">
        <input pInputText type="text" formControlName="nom" placeholder="Nom*" />
      </div>
      <div class="ui-g-3">
        <input pInputText type="text" formControlName="prenom" placeholder="Prénom" />
      </div>
      <div class="ui-g-1">
        <label>Lien :</label>
      </div>
      <div class="ui-g-2">
        <p-dropdown [options]="liensFamilleList" [style]="{width: '100%'}" formControlName="lien_specialite" placeholder="Lien"
          optionLabel="libelle"></p-dropdown>
      </div>
    </div>

    <div class="ui-grid-row">
      <div class="ui-g-3" [formGroup]="currentEntourageForm">
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon"><i class="fa fa-phone"></i></span>
          <input *ngIf="!currentEntourageForm.value.vivant_au_foyer" pInputText type="text" formControlName="tel_fixe" placeholder="Fixe"/>
          <input *ngIf="currentEntourageForm.value.vivant_au_foyer" pInputText type="text" [value]="currentEntourageForm.value.tel_fixe" placeholder="Fixe" disabled="true"/>
        </div>
      </div>
      <div class="ui-g-3" [formGroup]="currentEntourageForm">
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon"><i class="fa fa-mobile"></i></span>
          <input pInputText type="text" formControlName="tel_mobile" placeholder="Mobile" />
        </div>
      </div>
      <div class="ui-g-3" [formGroup]="currentEntourageForm">
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon"><i class="fa fa-envelope"></i></span>
          <input pInputText type="text" formControlName="email" placeholder="Email" />
        </div>
      </div>
      <div class="ui-g-3" style="text-align: right;">
        <p-toggleButton [(ngModel)]="showAdresse" onLabel="Adresse" offLabel="Adresse" [onIcon]="'pi pi-minus'"
          offIcon="pi pi-plus" [style]="{'width':'150px'}"></p-toggleButton>
      </div>
    </div>

    <div *ngIf="showAdresse" [formGroup]="currentEntourageForm">
      <div class="ui-grid-row">
        <div class="ui-g-12">
          <input pInputText type="text" formControlName="cplt_adresse_1" placeholder="N° appt, Etage, Escalier" />
        </div>
      </div>
      <div class="ui-grid-row">
        <div class="ui-g-12">
          <input pInputText type="text" formControlName="cplt_adresse_2"
            placeholder="Entrée, Bât, Immeuble, Résidence" />
        </div>
      </div>
      <div class="ui-grid-row">
        <div class="ui-g-1">
          <input pInputText type="text" formControlName="numero_rue" placeholder="N°" />
        </div>
        <div class="ui-g-1">
          <p-dropdown [options]="typesRueList" [style]="{width: '100%'}" formControlName="type_complement_adresse"
            optionLabel="libelle"></p-dropdown>
        </div>
        <div class="ui-g-10">
          <input pInputText type="text" formControlName="rue" placeholder="Nom de la rue" />
        </div>
      </div>
      <div class="ui-grid-row">
        <div class="ui-g-12">
          <input pInputText type="text" formControlName="cplt_adresse_3"
            placeholder="Lieu dit, Ancienne commune" />
        </div>
      </div>
      <div class="ui-grid-row">
        <div class="ui-g-2">
          <input pInputText type="text" formControlName="cp" placeholder="Code postal" />
        </div>
        <div class="ui-g-7">
          <input pInputText type="text" formControlName="ville" placeholder="Ville" />
        </div>
        <div class="ui-g-3">
          <input pInputText type="text" formControlName="pays" placeholder="Pays" />
        </div>
      </div>
    </div>

    <div class="ui-grid-row" [formGroup]="currentEntourageForm">
      <div class="ui-g-6">
        <div class="ui-grid-row">
          <div class="ui-g-6">
            <label>A contacter en cas d'urgence</label>
          </div>
          <div class="ui-g-6">
            <p-inputSwitch formControlName="a_contacter_urgence"></p-inputSwitch>
          </div>
        </div>
        <div class="ui-grid-row">
          <div class="ui-g-6">
            <label>Représentant légal</label>
          </div>
          <div class="ui-g-6">
            <p-inputSwitch formControlName="representant_legal"></p-inputSwitch>
          </div>
        </div>
        <div class="ui-grid-row" *ngIf="currentEntourageForm.value.representant_legal">
          <div class="ui-g-2">
            <label>Type</label>
          </div>
          <div class="ui-g-8">
            <p-dropdown [options]="typesRepresentantList" [style]="{width: '100%'}" formControlName="type_representant"
              optionLabel="libelle"></p-dropdown>
          </div>
        </div>
        <div class="ui-grid-row" *ngIf="currentEntourageForm.value.representant_legal">
          <div class="ui-g-12">
            <p-checkbox label="Copie du jugement" formControlName="copie_jugement" binary="true"></p-checkbox>
          </div>
        </div>
        <div class="ui-grid-row">
          <div class="ui-g-6">
            <label>Vivant au foyer</label>
          </div>
          <div class="ui-g-6">
            <p-inputSwitch formControlName="vivant_au_foyer" (onChange)="onChangeVivantAuFoyer($event)"></p-inputSwitch>
          </div>
        </div>
        <div class="ui-grid-row" *ngIf="currentEntourageForm.value.vivant_au_foyer">
          <div class="ui-g-12">
            <p-checkbox label="Dépendant" formControlName="dependant" binary="true"></p-checkbox>
          </div>
        </div>
        <div class="ui-grid-row">
            <div class="ui-g-6">
               <label>Présent lors de la visite</label>
            </div>
            <div class="ui-g-6">
               <p-inputSwitch formControlName="present_visite"></p-inputSwitch>
            </div>
         </div>
      </div>

      <div class="ui-g-6">
        <div class="ui-grid-row">
          <div class="ui-g-6">
            <label>Accès aux données</label>
          </div>
          <div class="ui-g-6">
            <p-inputSwitch formControlName="autorisation_acces_donnees"></p-inputSwitch>
          </div>
        </div>
        <div class="ui-grid-row">
          <div class="ui-g-6">
            <label>Aidant</label>
          </div>
          <div class="ui-g-6">
            <p-inputSwitch formControlName="aidant"></p-inputSwitch>
          </div>
        </div>
        <div class="ui-grid-row">
          <div class="ui-g-6">
            <label>Personne de confiance</label>
          </div>
          <div class="ui-g-6">
            <p-inputSwitch formControlName="personne_confiance"></p-inputSwitch>
          </div>
        </div>
        <div class="ui-grid-row" *ngIf="currentEntourageForm.value.personne_confiance">
          <div class="ui-g-12">
            <p-checkbox label="Formulaire de désignation existant" formControlName="formulaire" binary="true">
            </p-checkbox>
          </div>
        </div>
      </div>
    </div>

    <div class="ui-grid-row">
      <label>Informations complémentaires</label>
    </div>
    <div class="ui-grid-row" [formGroup]="currentEntourageForm">
      <textarea pInputTextarea [rows]="5" formControlName="commentaire"></textarea>
    </div>

    <div class="ui-grid-row" style="color: #C12300">
      <div class="ui-g-12">
        Requis *<br />
      </div>
    </div>

  </div>

  <!-- ENFANT -->

  <div *ngIf="currentEntourageForm.value.enfant"
    [formGroup]="currentEntourageForm">
    <div class="ui-grid-row">
      <div class="ui-g-4">
        <input pInputText type="text" formControlName="nom" placeholder="Nom*" />
      </div>
      <div class="ui-g-4">
        <input pInputText type="text" formControlName="prenom" placeholder="Prénom*" />
      </div>
    </div>

    <div class="ui-grid-row">
      <div class="ui-g-2">
        <label>Date de naissance*</label>
      </div>
      <div class="ui-g-2">
          <p-calendar [dateFormat]="dateFormat" [locale]="locale" [maxDate]="today" formControlName="date_de_naissance" appendTo="body" baseZIndex="11001" (onBlur)="updateAge();" (onSelect)="updateAge();"></p-calendar>
          <!--<input type="text" pInputText
          [value]="currentEntourageForm.value.date_de_naissance | date: datePipeFormatWithoutTime" readonly="true" />-->
      </div>
    </div>

    <div class="ui-grid-row">
      <div class="ui-g-4">
        <label>Représentant légal 1</label>
      </div>
      <div class="ui-g-4">
        <p-dropdown [options]="entouragesPersonnelsList" [style]="{width: '100%'}" formControlName="parent_1" placeholder="Aucun"
          [showClear]="true" dataKey="id_personne" optionLabel="nom_prenom"></p-dropdown>
      </div>
      <div class="ui-g-2">
        <p-button icon="pi pi-plus" label="" (click)="newEntourage('perso')"></p-button>
      </div>
    </div>

    <div class="ui-grid-row">
      <div class="ui-g-4">
        <label>Représentant légal 2</label>
      </div>
      <div class="ui-g-4">
        <p-dropdown [options]="entouragesPersonnelsList" [style]="{width: '100%'}" formControlName="parent_2" placeholder="Aucun"
          [showClear]="true" dataKey="id_personne" optionLabel="nom_prenom"></p-dropdown>
      </div>
      <div class="ui-g-2">
        <p-button icon="pi pi-plus" label="" (click)="newEntourage('perso')"></p-button>
      </div>
    </div>

    <div class="ui-grid-row">
      <div class="ui-g-4">
        <label>A contacter en cas d'urgence 1 **</label>
      </div>
      <div class="ui-g-4">
        <p-dropdown [options]="entouragesPersonnelsList" [style]="{width: '100%'}" formControlName="urgence_1" placeholder="Aucun"
          [showClear]="true" dataKey="id_personne" optionLabel="nom_prenom"></p-dropdown>
      </div>
      <div class="ui-g-2">
        <p-button icon="pi pi-plus" label="" (click)="newEntourage('perso')"></p-button>
      </div>
    </div>

    <div class="ui-grid-row">
      <div class="ui-g-4">
        <label>A contacter en cas d'urgence 2 **</label>
      </div>
      <div class="ui-g-4">
        <p-dropdown [options]="entouragesPersonnelsList" [style]="{width: '100%'}" formControlName="urgence_2" placeholder="Aucun"
          [showClear]="true" dataKey="id_personne" optionLabel="nom_prenom"></p-dropdown>
      </div>
      <div class="ui-g-2">
        <p-button icon="pi pi-plus" label="" (click)="newEntourage('perso')"></p-button>
      </div>
    </div>

    <div class="ui-grid-row" style="color: #C12300">
      <div class="ui-g-12">
        Requis *<br />
        Personnes à prévenir en cas d'urgence et autorisées à s'occuper des enfants si les parents sont injoignables **
      </div>
    </div>
  </div>

</p-dialog>
