<!--<header *ngIf="isVisible()">-->
<!--  <div id="header-content" class="p-grid">-->
<!--      <div class="p-col">-->
<!--          <button pButton type="button" class="burger-button" (click)="toggleShowLeftSideBar()" icon="pi pi-bars"></button>-->
<!--      </div>-->
<!--      <div id="header-bar-logo" class="p-col p-justify-center">-->
<!--        <a routerLink="/in/recherche">-->
<!--          <img src="assets/img/admr_logo.png" alt="admr_logo" class="p-justify-center" width="100" height="50">-->
<!--          <span id="header-bar-title" style="vertical-align: middle;margin-left: 10px;" [class]="titleClass">{{appTitle}}</span>-->
<!--        </a>-->
<!--      </div>-->
<!--      <div id="header-bar-logout" class="p-col">-->
<!--          <p id="header-bar-logout-libelle"><span (click)="toggleShowRightSideBar()" class="cursor-pointer">{{ currentUtilisateur.libelle }} <i class="pi pi-caret-down"></i></span> | <button id="header-logout-button" pButton type="button" class="logout-button"  (click)="logout()" icon="pi pi-power-off"></button></p>-->

<!--      </div>-->
<!--  </div>-->
<!--</header>-->

<!--<p-sidebar id="leftSideBar" [(visible)]="showLeftSidebar" [baseZIndex]="10000" [style]="{top: '65px', padding: '0px', border: 'none' }" [showCloseIcon]=false>-->
<!--    <div id="leftSideBar-container" class="p-grid p-align-stretch vertical-container">-->
<!--        <div class="p-col-12" (click)="toggleShowLeftSideBar()">-->
<!--            <div class="box box box-stretched profile-menu">-->
<!--              <a id="a-profile-menu" style="touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;" href="/in/recherche">-->
<!--                  <div class="profile-pic">-->
<!--                      <img src="assets/img/profile-pics/anonymous.jpg" alt="">-->
<!--                  </div>-->
<!--                  <div class="profile-info">-->
<!--                      {{ currentUtilisateur.libelle }}-->
<!--                      <span *ngIf="currentUtilisateur.isMultiSite">-->
<!--                        <br>{{libelleUtilisateurMultiSite}}-->
<!--                      </span>-->
<!--                  </div>-->
<!--              </a>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="p-col-12">-->
<!--            <p-panelMenu [model]="items" [style]="{width: '100%', border: 'none' }"></p-panelMenu>-->
<!--        </div>-->
<!--        <hr>-->
<!--        <div class="p-col-12 side-bar-version" (click)="toggleShowLeftSideBar()">-->
<!--            <div class="p-grid p-align-end vertical-container">-->
<!--                <div class="p-col p-justify-center">-->
<!--                    <div class="box app-version-box">Version {{appVersion}}</div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</p-sidebar>-->

<!--<p-sidebar id="rightSideBar" [(visible)]="showRightSidebar" position="right" [baseZIndex]="10000" [style]="{top: '65px', height:'40px', padding: '0px', border: 'none' }" [showCloseIcon]=false>-->
<!--    <div id="rightSideBar-container" class="p-grid p-align-stretch vertical-container">-->
<!--        <div class="p-col-12">-->
<!--            <p-panelMenu [model]="itemsProfile" [style]="{width: '100%', border: 'none' }"></p-panelMenu>-->
<!--        </div>-->
<!--    </div>-->
<!--</p-sidebar>-->

<!-- // -TODO- Faire la modification sur le champ federation quand le composant aura été mis à jour -->
<nsi-entete *ngIf="isVisible()"
            [title]="appTitle"
            [items]="items"
            [federation]="libelleUtilisateurMultiSite"
            [utilisateur]="currentUtilisateur.libelle"
            [paramUser]="itemsProfile"
            (onHeaderClick)="logoClick()"></nsi-entete>

<p-dialog *ngIf="currentUtilisateur" header="Préférences d'affichage" [(visible)]="showPreferencesAffichage" [styleClass]="'menu_dialog'" modal="true">
    <div class="ui-grid-row">
        <div class="ui-g-12">
            <label>Ecran par défaut</label>
        </div>
        <div class="ui-g-12">
            <p-dropdown [options]="preferencesAffichageList" [placeholder]="'Préférence d\'affichage'" [style]="{width: '100%'}"
                        [(ngModel)]="currentUtilisateur.preference_affichage" appendTo="body" (onChange)="onChangePreferenceAffichage()"></p-dropdown>
        </div>
    </div>
</p-dialog>

<p-dialog *ngIf="currentUtilisateur" header="Préférences d'astreinte" [(visible)]="showPreferencesAstreinte" [styleClass]="'menu_dialog'" modal="true">
  <div class="ui-grid-row">
    <div class="ui-g-12">
      <label>Préférence d’astreinte pour les nouveaux échanges :</label>
    </div>
    <div class="ui-g-12">
      <p-inputSwitch [(ngModel)]="profilAstreinte" (onChange)="onChangePreferenceAstreinte()"></p-inputSwitch>
    </div>
  </div>
</p-dialog>


<p-dialog *ngIf="currentUtilisateur" [style]="{maxWidth: '600px'}"
  [(visible)]="showPreferencesTableauBord" [styleClass]="'menu_dialog'" modal="true">
  <p-header>
    <div class="ui-g-8" style="font-size: 15px;">Préférences tableau de bord</div>
    <div class="ui-g-3" style="text-align: right;">
      <button pButton type="button" class="white-button small-button" style="width: 150px;" label="Enregistrer"
      (click)="saveProfile()"></button>
    </div>
  </p-header>
  <div class="ui-g">
      <div class="ui-g-12">
          <label>Tuiles affichées</label>
      </div>
      <div class="ui-g-12" style="text-align: center;">
        <a (click)="addAllPreferencesTableauBord()">Tout cocher</a> | <a (click)="removeAllPreferencesTableauBord()">Tout décocher</a>
      </div>
      <div *ngIf="showCheckbox('echanges_urgents_a_traiter')" class="ui-g-12">
        <p-checkbox name="preferences_tableau_bord" value="echanges_urgents_a_traiter"
        label="Echanges urgents à traiter" [(ngModel)]="currentUtilisateur.preferences_tableau_bord"></p-checkbox>
      </div>
      <div *ngIf="showCheckbox('reclamations_ouvertes')" class="ui-g-12">
        <p-checkbox name="preferences_tableau_bord" value="reclamations_ouvertes"
        label="Réclamations ouvertes" [(ngModel)]="currentUtilisateur.preferences_tableau_bord"></p-checkbox>
      </div>
      <div *ngIf="showCheckbox('echanges_brouillons')" class="ui-g-12">
        <p-checkbox name="preferences_tableau_bord" value="echanges_brouillons"
        label="Echanges brouillons" [(ngModel)]="currentUtilisateur.preferences_tableau_bord"></p-checkbox>
      </div>
      <div *ngIf="showCheckbox('mes_echanges_brouillons')" class="ui-g-12">
        <p-checkbox name="preferences_tableau_bord" value="mes_echanges_brouillons"
        label="Mes échanges brouillons" [(ngModel)]="currentUtilisateur.preferences_tableau_bord"></p-checkbox>
      </div>
      <div *ngIf="showCheckbox('echanges_rem_inf')" class="ui-g-12">
        <p-checkbox name="" value="echanges_rem_inf"
        label="Remontées d'informations ouvertes" [(ngModel)]="currentUtilisateur.preferences_tableau_bord"></p-checkbox>
      </div>
      <div *ngIf="showCheckbox('echanges_ouverts')" class="ui-g-12">
        <p-checkbox name="" value="echanges_ouverts"
        label="Échanges ouverts par catégorie" [(ngModel)]="currentUtilisateur.preferences_tableau_bord"></p-checkbox>
      </div>
      <div *ngIf="showCheckbox('actions_ouvertes')" class="ui-g-12">
        <p-checkbox name="preferences_tableau_bord" value="actions_ouvertes"
        label="Actions ouvertes" [(ngModel)]="currentUtilisateur.preferences_tableau_bord"></p-checkbox>
      </div>
      <div *ngIf="showCheckbox('mes_actions')" class="ui-g-12">
        <p-checkbox name="preferences_tableau_bord" value="mes_actions"
        label="Mes actions" [(ngModel)]="currentUtilisateur.preferences_tableau_bord"></p-checkbox>
      </div>
      <div *ngIf="showCheckbox('echanges_astreintes')" class="ui-g-12">
        <p-checkbox name="preferences_tableau_bord" value="echanges_astreintes"
        label="Echanges astreintes" [(ngModel)]="currentUtilisateur.preferences_tableau_bord"></p-checkbox>
      </div>
      <div *ngIf="showCheckbox('mes_echanges_urgents_ouverts')" class="ui-g-12">
        <p-checkbox name="preferences_tableau_bord" value="mes_echanges_urgents_ouverts"
        label="Mes échanges urgents ouverts" [(ngModel)]="currentUtilisateur.preferences_tableau_bord"></p-checkbox>
      </div>
      <div *ngIf="showCheckbox('mes_echanges')" class="ui-g-12">
        <p-checkbox name="preferences_tableau_bord" value="mes_echanges"
        label="Mes échanges" [(ngModel)]="currentUtilisateur.preferences_tableau_bord"></p-checkbox>
      </div>
      <div *ngIf="showCheckbox('echanges_devis_ouverts')" class="ui-g-12">
        <p-checkbox name="preferences_tableau_bord" value="echanges_devis_ouverts"
        label="Échanges devis ouverts" [(ngModel)]="currentUtilisateur.preferences_tableau_bord"></p-checkbox>
      </div>
      <div *ngIf="showCheckbox('echanges_prospects_ouverts')" class="ui-g-12">
        <p-checkbox name="preferences_tableau_bord" value="echanges_prospects_ouverts"
        label="Échanges prospects ouverts" [(ngModel)]="currentUtilisateur.preferences_tableau_bord"></p-checkbox>
      </div>
  </div>
</p-dialog>

<div id="items" *ngIf="showBackButton || showNewEchangeButton">
    <div id="items-btn">
        <div id="back-item-btn" *ngIf="showBackButton" >
            <button *ngIf="!isOnEchangePage && currentUtilisateur.acces_echanges" id="back-item-buttton" [class]="buttonClass" [routerLink]="['/in/recherche']" pTooltip="Liste des échanges" tooltipZIndex="10000">
                <i class="back-item-btn-ico pi pi-step-backward"></i>
            </button>
        </div>

        <div id="add-item-btn" *ngIf="showNewEchangeButton">
            <button type="button" class="btn-float" style="background-color: transparent;border: none;cursor: pointer;" pTooltip="Nouvel échange" tooltipZIndex="10000" (click)="goToNewEchange()">
                <img src="assets/img/logo-plus-echange.png" alt="Nouvel échange" class="p-justify-center" width="50" height="50">
            </button>
        </div>
    </div>
</div>
