import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import {plainToClass} from 'class-transformer';

import { Droit, Association } from '../_models';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class DroitService {

    constructor(private http: HttpClient) { }

  /** GET droits */
  getAll(): Observable<Droit[]> {
    return this.http.get<Droit[]>(`${environment.apiHost}/${environment.apiNamespace}/droits`).pipe(
      tap(_ => console.log(`fetched droits`)),
      catchError(this.handleError)
    );
  }

  /** GET parametres */
  getDroitsByParams(paramsValue: any): Observable<Droit[]> {
    let params = new HttpParams();
    for (let paramKey in paramsValue) {
      if (paramsValue.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValue[paramKey]);
      }
    }
    return this.http.get<Droit[]>(`${environment.apiHost}/${environment.apiNamespace}/droits`, { params: params });
  }

   /** GET droits/:id */
   getDroit(id_droit: number): Observable<Droit> {
    return this.http.get<Droit>(`${environment.apiHost}/${environment.apiNamespace}/droits/${id_droit}`);
  }

  /** POST droits */
  createDroit(droit: Droit): Observable<Droit> {
    return this.http.post<any>(
      `${environment.apiHost}/${environment.apiNamespace}/droits`,
      plainToClass(Droit, droit).serialize()
     );
  }

  /** POST droits */
  createDroits(id_utilisateur: number, associationsList: Association[]): Observable<Droit> {
    return this.http.post<any>(
      `${environment.apiHost}/${environment.apiNamespace}/multiples/droits`,
      Droit.serializeToApiObject(id_utilisateur, associationsList)
     );
  }

  /** PUT droits */
  updateDroit(droit: Droit): Observable<Droit> {
    return this.http.put<any>(
      `${environment.apiHost}/${environment.apiNamespace}/droits/${droit.id_droit}`,
      plainToClass(Droit, droit).serialize()
    );
  }

  /** DELETE droits */
  removeDroit(id_droit: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiHost}/${environment.apiNamespace}/droits/${id_droit}`,
    { observe: 'response' }).pipe(
      catchError(this.handleError)
    );
  }

  /* handleError */
  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(error);
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a utilisateur-facing error message
    return throwError(
      error);
  }

}
