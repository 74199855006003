import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';

import { plainToClass } from 'class-transformer';

import { MessageService, SelectItem } from 'primeng/api';

import { AuthenticationService } from '../../shared/_services';
import { Utilisateur, Association, Federation, Individu, Echange, EvaluationEntete, Alerte, Appreciation, Disponibilites, Parametre, Creneau, Contact, InfosEntourage } from '../../shared/_models';
import {
  UtilisateurService, AssociationService, FederationService, ParametreService, AlerteService,
  IndividuService, EchangeService, EvaluationService, AppreciationService, DisponibilitesService, ContactService, InfosEntourageService
} from '../../shared/_services';

import { FiltreIndividu } from 'src/app/shared/utils/filtre-individu';
import { FiltreEchange } from '../../shared/utils/filtre-echange';
import { FiltreEvaluation } from 'src/app/shared/utils/filtre-evaluation';
import { FiltreAlerte } from '../../shared/utils/filtre-alerte';
import { FiltreAppreciation } from '../../shared/utils/filtre-appreciation';
import { Globals } from '../../shared/utils/globals';
import { ListeEchangesComponent } from 'src/app/shared/liste-echanges/liste-echanges.component';
import { ListeEvaluationsComponent } from 'src/app/shared/liste-evaluations/liste-evaluations.component';
import { ListeAlertesComponent } from 'src/app/shared/liste-alertes/liste-alertes.component';
import { ListeAppreciationsComponent } from 'src/app/shared/liste-appreciations/liste-appreciations.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-recapitulatif-client',
  templateUrl: './recapitulatif-client.component.html',
  styleUrls: ['./recapitulatif-client.component.css']
})
export class RecapitulatifClientComponent implements OnInit, AfterViewInit {

  @ViewChild(ListeEchangesComponent) private listeEchangesComponent: ListeEchangesComponent; // Composant qui affiche la liste des echanges
  @ViewChild(ListeEvaluationsComponent) private listeEvaluationsComponent: ListeEvaluationsComponent; // Composant qui affiche la liste des evaluations
  @ViewChild(ListeAlertesComponent) private listeAlertesComponent: ListeAlertesComponent; // Composant qui affiche la liste des alertes
  @ViewChild(ListeAppreciationsComponent) private listeAppreciationsComponent: ListeAppreciationsComponent; // Composant qui affiche la liste des appreciations

  federationsList: Federation[];
  associationsList: Association[];
  associationsItemsList: SelectItem[];

  currentUtilisateur: Utilisateur;

  dateFormat = Globals.dateFormat;
  locale = Globals.dateLocale;
  datePipeFormatWithoutTime = Globals.datePipeFormatWithoutTime;
  datePipeFormatYMD = Globals.datePipeFormatYMD;
  datePipeFormat = Globals.datePipeFormat;

  accordionFilterOpen = true;

  filtreIndividu: FiltreIndividu;
  filtreEchange = new FiltreEchange();
  filtreEvaluation = new FiltreEvaluation();
  filtreAlerte = new FiltreAlerte();
  filtreAppreciation = new FiltreAppreciation();
  individusList: Individu[];
  selectedIndividu: Individu;
  loadingIndividus = false;
  showPopupIndividus = false;
  loadingEchanges = false;
  loadingEvaluations = false;
  loadingAlertes = false;
  loadingAppreciations = false;
  loadingDisponibilites = false;
  loadingContacts = false;
  echangesList: Echange[] = [];
  evaluationsList: EvaluationEntete[] = [];
  alertesList: Alerte[] = [];
  appreciationsList: Appreciation[] = [];
  disponibilitesListItem: Disponibilites[] = [];
  contactsList: Contact[] = [];
  echangesListLoaded = false;
  evaluationsListLoaded = false;
  alertesListLoaded = false;
  appreciationsListLoaded = false;
  disponibilitesListLoaded = false;
  contactsListLoaded = false;
  disponibilitesSelected: Disponibilites = new Disponibilites();
  isDisponibilitesEditable: boolean;
  showEntourageInactif = false;

  joursList: Parametre[];
  periodesList: Parametre[];
  typesEntourageList: any[];
  typesRueList: Parametre[];
  typesRepresentantList: Parametre[];
  civilitesList: Parametre[];
  civilitesProList: Parametre[];
  liensFamilleList: Parametre[];
  specialitesList: Parametre[];
  unitesTempsList: Parametre[];
  checkBoxDisponibilitesValues: string[];

  showPopupEntourage = false;
  currentEntourageFormMode: string;
  currentEntourageForm: FormGroup;
  currentTypeEntourage: string;
  showAdresse: boolean;
  index = 0;
  rafraichirOnglet = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private utilisateurService: UtilisateurService,
    private federationService: FederationService,
    private associationService: AssociationService,
    private parametreService: ParametreService,
    private echangeService: EchangeService,
    private evaluationService: EvaluationService,
    private alerteService: AlerteService,
    private appreciationService: AppreciationService,
    private individuService: IndividuService,
    private disponibilitesService: DisponibilitesService,
    private contactService: ContactService,
    private infosEntourageService: InfosEntourageService
  ) { }

  async ngOnInit() {
    this.currentUtilisateur = this.authenticationService.currentUtilisateurValue;
    await this.test();
    // this.currentUtilisateur = this.utilisateurService.getDroitsModules(this.currentUtilisateur);
    console.clear();
    console.log(this.currentUtilisateur);
    this.filtreIndividu = this.individuService.filtreIndividu;
    this.selectedIndividu = this.individuService.selectedIndividu;

    this.getFederations();
    this.getAssociations();
    this.getJoursList();
    this.getPeriodesList();
    this.getTypesEntouragesList();
    this.getTypesRue();
    this.getTypesRepresentant();
    this.getCivilites();
    this.getCivilitesPro();
    this.getLiensFamille();
    this.getSpecialites();
    this.getUnitesTempsList();
    this.getCheckBoxDisponibilitesValues();
    this.route.params.subscribe(params => {
      const idPhiliaParam = params['idPhilia'];
      if (idPhiliaParam !== undefined) {
        // Récupère l'idPhilia et charge les échanges pour l'individu
        const idPhilia = idPhiliaParam;
        if (idPhilia) {
          this.filtreIdPhilia(idPhilia);
        }
      } else {
        this.updateFiltres();
      }
    });
  }

  async test() {
    try {
      this.currentUtilisateur = await this.utilisateurService.getDroitsModules(this.currentUtilisateur);
    } catch (error) {
      console.error('Error occurred:', error);
    }
  }


  getFederations() {
    if (this.currentUtilisateur.perimetre === 'National') { // Si perimetre National, on cherche toutes les federations
      this.federationService.getAll().subscribe(federations => {
        this.federationsList = federations;
      });
    } else {
      this.federationsList = [this.currentUtilisateur.federation];
      this.filtreIndividu.federation = this.currentUtilisateur.federation;
    }
  }

  getAssociations() {
    if (this.currentUtilisateur.perimetre !== 'National') {
      if (this.currentUtilisateur.type_acces === 'monoAsso' || (this.currentUtilisateur.perimetre === 'Association' && !this.currentUtilisateur.type_acces)) {
        this.associationsList = [this.currentUtilisateur.association];
        this.filtreIndividu.association = this.currentUtilisateur.association;
        this.updateAssociationsItems();
      } else if (this.currentUtilisateur.type_acces === 'multiAsso') {
        this.associationsList = this.currentUtilisateur.multiSiteAssociations;
        this.updateAssociationsItems();
      } else if (this.currentUtilisateur.type_acces === 'multiSite') {
        this.associationsList = [this.authenticationService.currentAssociationForUserMultiSite];
        this.filtreIndividu.association = this.authenticationService.currentAssociationForUserMultiSite;
        this.updateAssociationsItems();
      } else if (this.currentUtilisateur.perimetre === 'Federation') {
        this.associationService.getAssociationsByParams({ federation: this.currentUtilisateur.federation.id_federation })
          .subscribe((associations: Association[]) => {
            this.associationsList = associations;
            this.updateAssociationsItems();
          });
      } else if (this.currentUtilisateur.perimetre === 'Agence') {
        this.associationsList = [this.currentUtilisateur.association];
        this.filtreIndividu.association = this.currentUtilisateur.association;
        this.updateAssociationsItems();
      }
    } else if (this.filtreIndividu.federation) {
      this.associationService.getAssociationsByParams({ federation: this.filtreIndividu.federation.id_federation })
        .subscribe((associations: Association[]) => {
          this.associationsList = associations;
          this.updateAssociationsItems();
        });
    }
  }

  getJoursList() {
    this.parametreService.getParametresByParams({ type: 'JOUR', sort_property: 'id_parametre', sort_direction: 'ASC' })
      .subscribe((parametres: Parametre[]) => {
        this.joursList = parametres;
      });
  }

  getPeriodesList() {
    this.parametreService.getParametresByParams({ type: 'PERIODE', sort_property: 'id_parametre', sort_direction: 'ASC' })
      .subscribe((parametres: Parametre[]) => {
        this.periodesList = parametres;
      });
  }

  getTypesEntouragesList() {
    let typesEntourageList = [];
    this.parametreService.getParametresByParams({ type: 'TYPE_PERSONNE' })
      .subscribe((parametres: Parametre[]) => {
        parametres.forEach(function (parametre) {
          typesEntourageList[parametre.code_parametre] = parametre;
        });

        this.typesEntourageList = typesEntourageList;
      });
  }

  getTypesRue() {
    this.parametreService.getParametresByParams({ type: 'TYPE_RUE' })
      .subscribe((parametres: Parametre[]) => {
        this.typesRueList = parametres;
      });
  }

  getTypesRepresentant() {
    this.parametreService.getParametresByParams({ type: 'REPRESENTANT' })
      .subscribe((parametres: Parametre[]) => {
        this.typesRepresentantList = parametres;
      });
  }

  getCivilites() {
    this.parametreService.getParametresByParams({ type: 'CIVILITE' })
      .subscribe((parametres: Parametre[]) => {
        this.civilitesList = parametres;
      });
  }

  getCivilitesPro() {
    this.parametreService.getParametresByParams({ type: 'CIVILITE_PRO' })
      .subscribe((parametres: Parametre[]) => {
        this.civilitesProList = parametres;
      });
  }

  getLiensFamille() {
    this.parametreService.getParametresByParams({ type: 'LIEN' })
      .subscribe((parametres: Parametre[]) => {
        this.liensFamilleList = parametres;
      });
  }

  getSpecialites() {
    this.parametreService.getParametresByParams({ type: 'SPECIALITE' })
      .subscribe((parametres: Parametre[]) => {
        this.specialitesList = parametres;
      });
  }

  getUnitesTempsList() {
    this.parametreService.getParametresByParams({ type: 'UNITE' })
      .subscribe((parametres: Parametre[]) => {
        this.unitesTempsList = parametres;
      });
  }

  getCheckBoxDisponibilitesValue(jour, periode) {
    if (this.disponibilitesSelected) {
      this.disponibilitesSelected.creneaux.forEach(function (creneau) {
        if (creneau.jour && creneau.jour.id_parametre === jour.id_parametre
          && creneau.periode && creneau.periode.id_parametre === periode.id_parametre) {
          return true;
        }
      });
    }
    return false;
  }

  getCheckBoxDisponibilitesValues() {
    let checkBoxDisponibilitesValues = [];
    if (this.disponibilitesSelected) {
      this.disponibilitesSelected.creneaux.forEach(function (creneau) {
        checkBoxDisponibilitesValues.push(creneau.jour.id_parametre + '_' + creneau.periode.id_parametre);
      });
    }
    this.checkBoxDisponibilitesValues = checkBoxDisponibilitesValues;
  }

  updateDisponibilitesValues(eventValue, jour, periode) {
    if (this.disponibilitesSelected) {
      if (!eventValue) { // Suppression
        for (let index = 0; index < (this.disponibilitesSelected.creneaux.length); index++) {
          let currentCreneau = this.disponibilitesSelected.creneaux[index];
          if (currentCreneau.jour.id_parametre === jour.id_parametre && currentCreneau.periode.id_parametre === periode.id_parametre) {
            this.disponibilitesSelected.creneaux.splice(index, 1);
          }
        }
      } else { // Ajout
        let newCreneau = new Creneau();
        newCreneau.jour = jour;
        newCreneau.periode = periode;

        this.disponibilitesSelected.creneaux.push(newCreneau);
      }
    }
  }

  onChangeFederation() {
    if (this.filtreIndividu.federation) {
      this.associationService.getAssociationsByParams({ federation: this.filtreIndividu.federation.id_federation })
        .subscribe((associations: Association[]) => {
          this.associationsList = associations;
          this.updateAssociationsItems();
        });
    } else {
      this.associationsList = null;
      this.filtreIndividu.association = null;
      this.updateAssociationsItems();
    }
  }

  searchIndividuPhilia() {
    this.loadingIndividus = true;
    this.individusList = [];

    this.individuService.getIndividusByParams(this.filtreIndividu)
      .subscribe((individus: Individu[]) => {
        this.individusList = plainToClass(Individu, individus);
        this.loadingIndividus = false;
        this.showPopupIndividus = true;
      },
        error => {
          // Aucun résultat trouvé
          this.loadingIndividus = false;
          this.showPopupIndividus = true;
        });

  }

  selectIndividu(individu: Individu) {
    this.selectedIndividu = individu;
    this.individuService.selectedIndividu = individu;

    this.updateFiltres();
    this.searchEchange();
    this.searchEvaluation();
    this.searchAlerte();
    this.searchAppreciation();

    this.refreshAllLists();

    this.accordionFilterOpen = false;
  }

  updateFiltres() {
    if (this.selectedIndividu) {
      let filtreEchange = new FiltreEchange();
      filtreEchange.personne_sujet_id_philia = this.selectedIndividu.id_philia;
      this.filtreEchange = filtreEchange;
      this.searchEchange();

      let filtreEvaluation = new FiltreEvaluation();
      filtreEvaluation.client_id_philia = this.selectedIndividu.id_philia;
      this.filtreEvaluation = filtreEvaluation;
      this.searchEvaluation();

      let filtreAlerte = new FiltreAlerte();
      filtreAlerte.client_id_philia = this.selectedIndividu.id_philia;
      this.filtreAlerte = filtreAlerte;
      this.searchAlerte();

      let filtreAppreciation = new FiltreAppreciation();
      filtreAppreciation.client_id_philia = this.selectedIndividu.id_philia;
      this.filtreAppreciation = filtreAppreciation;
      this.searchAppreciation();

      this.searchDisponibilites(this.selectedIndividu.id_philia);
    }
  }

  searchEchange() {
    this.loadingEchanges = true;

    this.echangeService.getEchangesByParams(this.filtreEchange, false)
      .subscribe((echanges: Echange[]) => {
        this.echangesList = plainToClass(Echange, echanges);
        this.loadingEchanges = false;
      },
        error => {
          console.log(error);
          this.loadingEchanges = false;
        });
  }

  searchEvaluation() {
    this.loadingEvaluations = true;

    this.evaluationService.getEvaluationsByParams(this.filtreEvaluation, false)
      .subscribe((evaluations: EvaluationEntete[]) => {
        this.evaluationsList = plainToClass(EvaluationEntete, evaluations);
        this.loadingEvaluations = false;
      },
        error => {
          console.log(error);
          this.loadingEvaluations = false;
        });
  }

  searchAlerte() {
    this.loadingAlertes = true;

    this.alerteService.getAlertesByParams(this.filtreAlerte, false)
      .subscribe((alertes: Alerte[]) => {
        this.alertesList = plainToClass(Alerte, alertes);
        this.loadingAlertes = false;
      },
        error => {
          console.log(error);
          this.loadingAlertes = false;
        });
  }

  searchAppreciation() {
    this.loadingAppreciations = true;

    this.appreciationService.getAppreciationsByParams(this.filtreAppreciation, false)
      .subscribe((appreciations: Appreciation[]) => {
        this.appreciationsList = plainToClass(Appreciation, appreciations);
        this.loadingAppreciations = false;
      },
        error => {
          console.log(error);
          this.loadingAppreciations = false;
        });
  }

  searchDisponibilites(id_philia) {
    this.loadingDisponibilites = true;

    this.disponibilitesService.getDisponibilitesByParams({ id_philia: id_philia })
      .subscribe((disponibilites: Disponibilites[]) => {
        let disponibilitesListItem = [];
        let disponibilitesList = plainToClass(Disponibilites, disponibilites);
        this.loadingDisponibilites = false;

        if (disponibilitesList && disponibilitesList.length > 0) {
          this.disponibilitesSelected = disponibilitesList[0];

          disponibilitesList.forEach(function (dispo) {
            disponibilitesListItem.push({ label: dispo.label, value: dispo });
          });
          this.disponibilitesListItem = disponibilitesListItem;
        }
        this.getCheckBoxDisponibilitesValues();
      },
        error => {
          console.log(error);
          this.loadingDisponibilites = false;
        });
  }

  searchContact(id_philia) {
    this.loadingContacts = true;

    this.contactService.getContactsByParams({ id_philia_client: id_philia })
      .subscribe((contacts: Contact[]) => {
        this.contactsList = plainToClass(Contact, contacts);
        this.loadingContacts = false;
      },
        error => {
          console.log(error);
          this.loadingContacts = false;
        });
  }

  goToLastEchange() {
    if (this.echangesList[0]) {
      let url = environment.appUrl + '/in/echange/' + this.echangesList[0].id_echange;
      window.open(url.toString(), '_blank');
    }
  }

  goToLastEvaluation() {
    if (this.evaluationsList[0]) {
      let url = environment.appUrlEv + '/in/evaluation/' + this.evaluationsList[0].id_evaluation_entete;
      window.open(url.toString(), '_blank');
    }
  }

  goToLastAlerte() {
    if (this.alertesList[0]) {
      let url = environment.appUrlVi + '/in/alerte/' + this.alertesList[0].id_alerte;
      window.open(url.toString(), '_blank');
    }
  }

  handleChangeTabView(event) {
    if (this.selectedIndividu) {
      if (event.index === 1 && !this.echangesListLoaded) {
        this.refreshList('echange');
      } else if (event.index === 2 && !this.evaluationsListLoaded) {
        this.refreshList('evaluation');
      } else if (event.index === 3 && !this.alertesListLoaded) {
        this.refreshList('alerte');
      } else if (event.index === 4 && !this.appreciationsListLoaded) {
        this.refreshList('appreciation');
      }
    }
  }

  refreshAllLists() {
    this.refreshList('echange');
    this.refreshList('evaluation');
    this.refreshList('alerte');
    this.refreshList('appreciation');
    this.refreshList('disponibilites');
    this.refreshList('contact');
  }

  refreshList(list) {
    if (list === 'echange' && this.listeEchangesComponent) {
      this.listeEchangesComponent.searchEchange(this.filtreEchange);
      this.echangesListLoaded = true;
    } else if (list === 'evaluation' && this.listeEvaluationsComponent) {
      this.listeEvaluationsComponent.searchEvaluation(this.filtreEvaluation);
      this.evaluationsListLoaded = true;
    } else if (list === 'alerte' && this.listeAlertesComponent) {
      this.listeAlertesComponent.searchAlerte(this.filtreAlerte);
      this.alertesListLoaded = true;
    } else if (list === 'appreciation' && this.listeAppreciationsComponent) {
      this.listeAppreciationsComponent.searchAppreciation(this.filtreAppreciation);
      this.appreciationsListLoaded = true;
    } else if (list === 'disponibilites' && this.selectedIndividu) {
      this.searchDisponibilites(this.selectedIndividu.id_philia);
      this.disponibilitesListLoaded = true;
    } else if (list === 'contact' && this.selectedIndividu) {
      this.searchContact(this.selectedIndividu.id_philia);
      this.contactsListLoaded = true;
    }
  }

  selectAllPeriodes() {
    let self = this;
    this.joursList.forEach(function (jour) {
      self.periodesList.forEach(function (periode) {
        self.updateDisponibilitesValues(true, jour, periode);
      });
    });
    // On met a jour les checkbox
    this.getCheckBoxDisponibilitesValues();
  }

  unselectAllPeriodes() {
    let self = this;
    this.joursList.forEach(function (jour) {
      self.periodesList.forEach(function (periode) {
        self.updateDisponibilitesValues(false, jour, periode);
      });
    });
    // On met a jour les checkbox
    this.getCheckBoxDisponibilitesValues();
  }

  selectPeriode(periode) {
    let self = this;
    this.joursList.forEach(function (jour) {
      self.updateDisponibilitesValues(true, jour, periode);
    });
    // On met a jour les checkbox
    this.getCheckBoxDisponibilitesValues();
  }

  selectJour(jour) {
    let self = this;
    this.periodesList.forEach(function (periode) {
      self.updateDisponibilitesValues(true, jour, periode);
    });

    // On met a jour les checkbox
    this.getCheckBoxDisponibilitesValues();
  }

  saveDispos() {
    this.disponibilitesSelected.id_philia = this.selectedIndividu.id_philia;
    if (this.disponibilitesSelected.id_disponibilites) {
      this.disponibilitesService.updateDisponibilites(this.disponibilitesSelected)
        .subscribe((disponibilites: Disponibilites) => {
          this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Disponibilités mises à jour avec succès.' });
          this.isDisponibilitesEditable = false;
          this.searchDisponibilites(this.disponibilitesSelected.id_philia);
        },
          error => {
            console.log(error);
            this.messageService.add({ severity: 'error', summary: 'Erreur dans la mise à jour des disponibilités.', detail: error });
          });
    } else {
      this.disponibilitesService.createDisponibilites(this.disponibilitesSelected)
        .subscribe((disponibilites: Disponibilites) => {
          this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Disponibilités mises à jour avec succès.' });
          this.isDisponibilitesEditable = false;
          this.searchDisponibilites(this.disponibilitesSelected.id_philia);
        },
          error => {
            console.log(error);
            this.messageService.add({ severity: 'error', summary: 'Erreur dans la mise à jour des disponibilités.', detail: error });
          });
    }
  }

  createEntourage() {

    let newInfosEntourage = new InfosEntourage();
    newInfosEntourage.id_philia_client = this.selectedIndividu.id_philia;
    newInfosEntourage.type_personne = this.typesEntourageList['ENT_PERSO']; // Initialisation avec entourage perso
    newInfosEntourage.initDatas(this.currentUtilisateur);
    this.currentEntourageForm = InfosEntourage.getForm(newInfosEntourage);
    this.updateTypeEntourageForm();

    this.currentEntourageFormMode = 'create';
    this.showPopupEntourage = true;
  }

  editEntourage(entourage, contact) {
    // A cause de la dependance circulaire on doit remettre contact
    entourage.contact = contact;
    entourage.source_maj = 'Tracéo';
    this.currentEntourageForm = InfosEntourage.getForm(entourage);

    this.updateTypeEntourageForm();

    this.currentEntourageFormMode = 'edit';
    this.showPopupEntourage = true;
  }

  updateTypeEntourageForm() {
    if (this.currentEntourageForm.value.type_personne && this.currentEntourageForm.value.type_personne.code_parametre === 'ENT_PERSO') {
      this.currentTypeEntourage = 'personnel';
    } else if (this.currentEntourageForm.value.type_personne && this.currentEntourageForm.value.type_personne.code_parametre === 'ENT_PRO') {
      this.currentTypeEntourage = 'professionnel';
    }
  }

  setCurrentTypeEntourage(typeEntourage) {
    this.currentEntourageForm.get('type_personne').patchValue(this.typesEntourageList[typeEntourage]);
  }

  saveEntourage() {
    if (this.currentEntourageForm !== null && this.currentEntourageForm !== undefined) {
      if (this.currentEntourageFormMode === 'edit') { // Edition
        this.infosEntourageService.updateInfosEntourage(this.currentEntourageForm.getRawValue())
          .subscribe((infosEntourage: InfosEntourage) => {
            this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Entourage mis à jour avec succès.' });

            this.refreshList('contact');

            this.showPopupEntourage = false;
          },
            error => {
              console.log(error);
              this.messageService.add({ severity: 'error', summary: 'Erreur dans la mise à jour de l\'entourage.', detail: error });
              this.showPopupEntourage = false;
            });
      } else { // Creation
        this.infosEntourageService.createInfosEntourage(this.currentEntourageForm.getRawValue())
          .subscribe((infosEntourage: InfosEntourage) => {
            this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Entourage créé avec succès.' });

            this.refreshList('contact');

            this.showPopupEntourage = false;
          },
            error => {
              console.log(error);
              this.messageService.add({ severity: 'error', summary: 'Erreur dans la création de l\'entourage.', detail: error });
              this.showPopupEntourage = false;
            });
      }
    }
  }

  onChangeRepresentantLegal(event) {
    if (event.checked) {
      // Si représentant légal, on met par défaut le premier type de représentant
      this.currentEntourageForm.get('type_representant').setValue(this.typesRepresentantList[0]);
    } else {
      this.currentEntourageForm.get('type_representant').setValue(null);
    }
    this.currentEntourageForm.updateValueAndValidity();
  }

  onChangeVivantAuFoyer(event) {
    if (event.checked) {
      // Vivant au foyer doit propager les coordonnées du client pour téléphone fixe et adresse et empêcher la saisie de ces champs
      this.currentEntourageForm.get('contact').get('tel_fixe').setValue(this.selectedIndividu.tel_domicile);
      this.copyAdresseClient();
    } else {
      this.currentEntourageForm.get('contact').get('tel_fixe').setValue(null);
      this.resetAdresseEntourage();
    }
    this.currentEntourageForm.updateValueAndValidity();
  }

  copyAdresseClient() {
    this.currentEntourageForm.get('contact').get('numero_rue').setValue(this.selectedIndividu.adr_num ? this.selectedIndividu.adr_num : null);
    this.currentEntourageForm.get('contact').get('type_complement_adresse').setValue(this.selectedIndividu.adr_complement ? this.selectedIndividu.adr_complement : null);
    this.currentEntourageForm.get('contact').get('rue').setValue(this.selectedIndividu.adr_voie ? this.selectedIndividu.adr_voie : null);
    this.currentEntourageForm.get('contact').get('ville').setValue(this.selectedIndividu.adr_ville ? this.selectedIndividu.adr_ville : null);
    this.currentEntourageForm.get('contact').get('cp').setValue(this.selectedIndividu.adr_code_postale ? this.selectedIndividu.adr_code_postale : null);
    this.currentEntourageForm.get('contact').get('pays').setValue('France');
    this.currentEntourageForm.get('contact').get('cplt_adresse_1').setValue(this.selectedIndividu.adr_complement1 ? this.selectedIndividu.adr_complement1 : null);
    this.currentEntourageForm.get('contact').get('cplt_adresse_2').setValue(this.selectedIndividu.adr_complement2 ? this.selectedIndividu.adr_complement2 : null);
    this.currentEntourageForm.get('contact').get('cplt_adresse_3').setValue(this.selectedIndividu.adr_lieudit ? this.selectedIndividu.adr_lieudit : null);
  }

  resetAdresseEntourage() {
    this.currentEntourageForm.get('contact').get('numero_rue').setValue(null);
    this.currentEntourageForm.get('contact').get('type_complement_adresse').setValue(null);
    this.currentEntourageForm.get('contact').get('rue').setValue(null);
    this.currentEntourageForm.get('contact').get('ville').setValue(null);
    this.currentEntourageForm.get('contact').get('cp').setValue(null);
    this.currentEntourageForm.get('contact').get('pays').setValue(null);
    this.currentEntourageForm.get('contact').get('cplt_adresse_1').setValue(null);
    this.currentEntourageForm.get('contact').get('cplt_adresse_2').setValue(null);
    this.currentEntourageForm.get('contact').get('cplt_adresse_3').setValue(null);
  }

  onCloseEntouragePopup(event) {
    /*if (this.currentEntourageForm !== null && this.currentEntourageForm !== undefined && this.entourageIsNew && !this.entourageisSaved) {
      this.cancelEntourage();
    }*/
    this.showPopupEntourage = false;
    // this.entourageisSaved = false;
  }

  onEntourageCreated(event) {/*
    if (this.currentEntourageForm !== null && this.currentEntourageForm !== undefined) {
        this.currentEntourageForm.setValue(Personne.getForm(this.entourageToEdit).value);
        (this.evaluationForm.get('evaluation_contenu').get('entourages') as FormArray).at(this.indexSelectedEntourage).setValue(this.currentEntourageForm.value);

        this.evaluationForm.updateValueAndValidity();
    }*/
    this.showPopupEntourage = false;
  }

  showContact(contact, type_personne) {
    let entourage = contact.getEntouragesActuelsByType(type_personne);

    if (entourage !== null) {
      if (this.showEntourageInactif) {
        return true;
      } else if (entourage.actif) {
        return true;
      }
    }
    return false;
  }

  isAssociationReadOnly() {
    if ((this.currentUtilisateur.perimetre === 'Association' && (!this.currentUtilisateur.type_acces || this.currentUtilisateur.type_acces === 'multiSite' || this.currentUtilisateur.type_acces === 'monoAsso'))
      || this.currentUtilisateur.perimetre === 'Agence') {
      return true;
    }
    return false;
  }

  updateAssociationsItems() {
    let associationsItemsList = [];
    if (this.associationsList) {
      this.associationsList.forEach(function (association) {
        if (association.disabled) {
          associationsItemsList.push({ label: association.libelle, value: association, styleClass: 'italic' });
        } else {
          associationsItemsList.push({ label: association.libelle, value: association });
        }
      });
    }

    this.associationsItemsList = associationsItemsList;
  }

  get canSearchIndividuPhilia() {
    // Il faut renseigner la fédération et minimum 3 lettres du nom de la personne recherchée afin de pouvoir effectuer une recherche
    if (this.filtreIndividu.federation
      && this.filtreIndividu.nom
      && this.filtreIndividu.nom.length >= 3
      && !this.loadingIndividus) {
      return true;
    }
    return false;
  }

  get nomNaissanceClient(): string {
    if (this.evaluationsList.length > 0) {
      return this.evaluationsList[0].client.nom_jeune_fille;
    }
    return null;
  }

  get dateNaissanceClient(): Date {
    if (this.evaluationsList.length > 0) {
      return this.evaluationsList[0].client.date_de_naissance;
    }
    return null;
  }

  /**
   * Charge l'onglet Echanges en tenant compte de l'IdPhilia en paramètre.
   * @param idPhilia Identifiant IdPhilia
   */
  filtreIdPhilia(idPhilia: string): void {
    // Individu avec IdPhilia
    let individu = new Individu();
    individu.id_philia = idPhilia;
    // Filtre les échanges selon Individu
    this.selectIndividu(individu);
    // On se positionne sur le bon onglet (dépend des droits sur les onglets précédents)
    let index = 1;
    if (this.currentUtilisateur.acces_evaluations) {
      index += 2;
    }
    this.index = index;
    this.rafraichirOnglet = true;
  }

  ngAfterViewInit() {
    if (this.rafraichirOnglet) {
      this.listeEchangesComponent.refresh();
      this.rafraichirOnglet = false;
    }
  }

}
