import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';

export const personneValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {

  let valid = true;
  let errors = null;

  if (control) {
    // Enfant
    if (control.value.enfant) {
      // Si aucun représentant légale sélectionné (parent1 ou parent2) => Invalide
      if (!control.get('parent_1').value && !control.get('parent_2').value) {
        valid = false;
        errors = {parentRequired: true};
      }
    }/* else if (!control.value.client && !control.get('tel_fixe').value && !control.get('tel_mobile').value && !control.get('email').value) {
      valid = false;
      errors = {moyenCommunicationRequired: true};
    }*/
  }

  return valid ? null : errors;
};

@Directive({
  selector: '[appPersonneValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: PersonneValidatorDirective, multi: true}]
})
export class PersonneValidatorDirective implements Validator {

  constructor() { }

  public validate(control: FormGroup): {[key: string]: any} {
    return personneValidator(control);
  }

}
