import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {catchError, concatMap, map, share, tap,} from 'rxjs/operators';
import {AUTH_MODE} from './auth.token';
import {MethodAuthService} from './method/method-auth.service';
import {AuthConfModel} from './models/auth-conf.model';
import {isTokenWrapperSuccess, TokenWrapper, TokenWrapperSuccess} from './models/token.model';
import {JwtStorageService} from './storage/jwt-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private readonly _token$: Observable<string | null>;
  private readonly _token: BehaviorSubject<TokenWrapperSuccess | null> =
    new BehaviorSubject<TokenWrapperSuccess | null>(null);
  private readonly jwtService: JwtHelperService = new JwtHelperService();

  constructor(
    private readonly jwtStorageService: JwtStorageService,
    @Inject(AUTH_MODE) private readonly authConf: AuthConfModel,
    private readonly httpClient: HttpClient,
    private readonly methodService: MethodAuthService
  ) {
    this._token.next(this.jwtStorageService.getToken());

    this._token$ = this._token.asObservable().pipe(
      concatMap((token: TokenWrapperSuccess | null) => this.loadTokenIfNeeded(token)),
      concatMap((token: TokenWrapperSuccess | null) => this.refreshTokenIfNeeded(token)),
      tap((token: TokenWrapperSuccess | null) => this.saveTokenIfNew(token)),
      map((token: TokenWrapperSuccess | null) => token?.accessToken ?? null),
      share(),
    );
  }

  private static isNotLoaded(tokenWrapper: TokenWrapper | null): boolean {
    const notLoaded = !tokenWrapper || !isTokenWrapperSuccess(tokenWrapper);

    if (notLoaded) {
      console.warn('TokenModel not loaded');
    }

    return notLoaded;
  }

  getToken(): Observable<string | null> {
    return this._token$;
  }

  private getNewTokenFromApi(): Observable<TokenWrapperSuccess | null> {
    const token$: Subject<TokenWrapperSuccess> = new Subject<TokenWrapperSuccess>();
    this.methodService.start((token: TokenWrapperSuccess) => token$.next(token), () => this.authConf.forbidden());

    return token$
      .asObservable()
      .pipe(tap(() => console.log('New token loaded')));
  }

  private isExpired(token: TokenWrapperSuccess | null): boolean {
    const expired: boolean = this.jwtService.isTokenExpired(
      token?.accessToken ?? null,
      this.authConf.secondsBeforeRefresh
    );

    if (expired) {
      console.warn('The token has expired');
    }

    return expired;
  }

  private loadNewTokenIfError(
    error: unknown
  ): Observable<TokenWrapperSuccess | null> {
    console.error('Token error : ', error);
    return this.getNewTokenFromApi();
  }

  private loadTokenIfNeeded(token: TokenWrapperSuccess | null): Observable<TokenWrapperSuccess | null> {
    return !TokenService.isNotLoaded(token) ? of(token) : this.getNewTokenFromApi();
  }

  private refreshTokenIfNeeded(token: TokenWrapperSuccess | null): Observable<TokenWrapperSuccess | null> {
    return !this.isExpired(token)
      ? of(token)
      : this.httpClient.get<TokenWrapperSuccess>(
        this.authConf.urlRefresh,
        {headers: {Authorization: `Bearer ${token?.refreshToken}`}}
      ).pipe(
        tap(() => console.log('TokenModel refreshed')),
        catchError((err: HttpErrorResponse) => this.loadNewTokenIfError(err.message)),
      );
  }

  private saveTokenIfNew(token: TokenWrapperSuccess | null): void {
    if (token?.accessToken !== this._token.value?.accessToken) {
      this.jwtStorageService.setToken(token);
      this._token.next(token);
    }
  }
}
