import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { forkJoin } from 'rxjs';
import {SelectItem} from 'primeng/api';
import {plainToClass} from 'class-transformer';

import { AuthenticationService, UtilisateurService, AssociationService, FederationService, ParametreService, ActionService, GroupeService } from '../../shared/_services';
import {Utilisateur, Association, Federation, Parametre, Action, Groupe} from '../../shared/_models';

import {FiltreAction} from '../../shared/utils/filtre-action';
import {Globals} from '../../shared/utils/globals';

@Component({
  selector: 'app-filtres-action',
  templateUrl: './filtres-action.component.html',
  styleUrls: ['./filtres-action.component.css']
})
export class FiltresActionComponent implements OnInit {

  @Output()
  search = new EventEmitter<any>();

  @Output()
  showFilter = new EventEmitter<any>();

  queryParams: ParamMap;

  federationsList: Federation[];
  associationsList: Association[];
  associationsItemsList: SelectItem[];
  utilisateursList: Utilisateur[];
  statutsList: Parametre[];
  naturesList: Parametre[];
  typesList: Parametre[];
  categoriesList: Parametre[];
  motifsList: Parametre[];
  objetActionList: string[];
  modulesList: SelectItem[];
  groupesList: Groupe[];
  filteredObjetActionList: string[];

  currentUtilisateur: Utilisateur;

  dateFormat = Globals.dateFormat;
  locale = Globals.dateLocale;
  echeanceList = Globals.echeanceList;

  filtreAction: FiltreAction;

  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private utilisateurService: UtilisateurService,
    private federationService: FederationService,
    private associationService: AssociationService,
    private parametreService: ParametreService,
    private actionService: ActionService,
    private groupeService: GroupeService
  ) { }

  ngOnInit() {

    this.currentUtilisateur = this.authenticationService.currentUtilisateurValue;
    this.filtreAction = this.actionService.filtreAction;

    this.route.queryParamMap.subscribe(
      (params: ParamMap) => {
        this.queryParams = params;

        this.getNatures();
        this.getTypes();
        this.getCategories();
        this.getMotifs();
        this.getGroupes();
        this.getUtilisateurs();

        this.updateFilter(params);

        this.getFederations();
        this.getAssociations();
        this.getObjets();
        this.getModules();

        this.getStatuts();
      }
    );
  }

  getFederations() {
    if (this.currentUtilisateur.perimetre === 'National') { // Si perimetre National, on cherhe toutes les federations
      this.federationService.getAll().subscribe(federations => {
        this.federationsList = federations;
      });
    } else {
      this.federationsList = [this.currentUtilisateur.federation];
      this.filtreAction.federation = this.currentUtilisateur.federation;
    }
  }

  getAssociations() {
    if (this.currentUtilisateur.perimetre !== 'National') {
      if (this.currentUtilisateur.type_acces === 'monoAsso' || (this.currentUtilisateur.perimetre === 'Association' && !this.currentUtilisateur.type_acces)) {
        this.associationsList = this.currentUtilisateur.association ? [this.currentUtilisateur.association] : undefined;
        this.filtreAction.association = this.currentUtilisateur.association;
        this.updateAssociationsItems();
      } else if (this.currentUtilisateur.type_acces === 'multiAsso') {
        this.associationsList = this.currentUtilisateur.multiSiteAssociations;
        this.updateAssociationsItems();
      } else if (this.currentUtilisateur.type_acces === 'multiSite') {
        this.associationsList = [this.authenticationService.currentAssociationForUserMultiSite];
        this.filtreAction.association = this.authenticationService.currentAssociationForUserMultiSite;
        this.updateAssociationsItems();
      } else if (this.currentUtilisateur.perimetre === 'Federation') {
        this.associationService.getAssociationsByParams({federation: this.currentUtilisateur.federation.id_federation})
          .subscribe((associations: Association[]) => {
            this.associationsList = associations;
            this.updateAssociationsItems();
        });
      } else if (this.currentUtilisateur.perimetre === 'Agence') {
        this.associationsList = this.currentUtilisateur.association ? [this.currentUtilisateur.association] : undefined;
        this.filtreAction.association = this.currentUtilisateur.association;
        this.updateAssociationsItems();
      }
    } else if (this.filtreAction.federation) {
      this.associationService.getAssociationsByParams({federation: this.filtreAction.federation.id_federation})
      .subscribe((associations: Association[]) => {
        this.associationsList = associations;
        this.updateAssociationsItems();
      });
    }
  }

  onSubmit() {
    this.search.emit(this.filtreAction);
  }

  onChangeFederation() {
    if (this.filtreAction.federation) {
        this.associationService.getAssociationsByParams({federation: this.filtreAction.federation.id_federation})
        .subscribe((associations: Association[]) => {
          this.associationsList = associations;
          this.updateAssociationsItems();
      });
    } else {
      this.associationsList = null;
      this.filtreAction.association = null;
      this.updateAssociationsItems();
    }
  }

  getUtilisateurs() {
    this.utilisateurService.getAll(true)
      .subscribe((utilisateurs: Utilisateur[]) => {
          this.utilisateursList = plainToClass(Utilisateur, utilisateurs);
      });
  }

  getStatuts() {
    this.parametreService.getParametresByParams({type: 'STATUT_A'})
    .subscribe((parametres: Parametre[]) => {
        this.statutsList = parametres;
        if (this.filtreAction.statut_action.length === 0 && !this.queryParams.get('statut_action')) {
          // Si un statut n'a pas déjà été sélectionné auparavant et pas dans les queryParams
          let self = this;
          self.filtreAction.statut_action = []; // On réinitialise les statuts
          parametres.forEach(function(parametre) {
            if (parametre.is_default) {
              self.filtreAction.statut_action.push(parametre); // On definit le statut par defaut
            }
          });
        } else if (this.queryParams.get('statut_action')) {
          let self = this;
          self.filtreAction.statut_action = []; // On réinitialise les statuts
          parametres.forEach(function(parametre) {
            if (parametre.code_parametre === self.queryParams.get('statut_action')) {
              self.filtreAction.statut_action.push(parametre); // On definit le statut du queryParam
            }
          });
        }
        this.onSubmit();
    });
  }

  getNatures() {
    this.parametreService.getParametresByParams({type: 'NATURE'})
    .subscribe((parametres: Parametre[]) => {
        this.naturesList = parametres;
    });
  }

  getTypes() {
    this.parametreService.getParametresByParams({type: 'TYPE'})
    .subscribe((parametres: Parametre[]) => {
        this.typesList = parametres;
    });
  }

  getCategories() {
    this.parametreService.getParametresByParams({type: 'CATEGORIE'})
    .subscribe((parametres: Parametre[]) => {
        this.categoriesList = parametres;
    });
  }

  getMotifs() {
    this.parametreService.getParametresByParams({type: 'MOTIF'})
    .subscribe((parametres: Parametre[]) => {
        this.motifsList = parametres;
    });
  }

  getGroupes() {
    this.groupeService.getAll(true)
    .subscribe((groupes: Groupe[]) => {
        this.groupesList = groupes;
    });
  }

  getModules() {
    let modulesList = [];

    if (this.currentUtilisateur.acces_echanges) {
      modulesList.push({label: 'Tracéo', value: 'echange'});
    }
    if (this.currentUtilisateur.hasAccesEvaluations) {
      modulesList.push({label: 'Evaluations', value: 'evaluation_entete'});
    }

    this.modulesList = modulesList;
  }

  initFilter(updateLimit = true) {
    let limitOld = this.filtreAction.limit;
    this.filtreAction = new FiltreAction();
    if (!updateLimit) {
      this.filtreAction.limit = limitOld;
    }

    this.getFederations(); // On initialise les federations par defaut (pour utilisateur multiSite ou monoAsso)
    this.getAssociations(); // On initialise les associations par defaut (pour utilisateur multiSite ou monoAsso)
    this.getStatuts(); // On initialise le statut par défaut
  }

  updateFilter(params: ParamMap) {

    if (params.keys.length > 0) {
      this.filtreAction = new FiltreAction();
    }

    if (params.has('responsable')) {
      this.filtreAction.responsable = []; // On réinitialise les responsables
      let responsableFiltre = new Utilisateur();
      responsableFiltre.id_utilisateur = Number(params.get('responsable'));
      this.filtreAction.responsable.push(responsableFiltre);
    }

    if (params.has('closed_debut')) {
      let closed_debut = new Date();
      closed_debut.setTime(parseInt(params.get('closed_debut'), 10));
      this.filtreAction.closed_debut = closed_debut;
    }

    if (params.has('closed_fin')) {
      let closed_fin = new Date();
      closed_fin.setTime(parseInt(params.get('closed_fin'), 10));
      this.filtreAction.closed_fin = closed_fin;
    }

    if (params.has('objet_action')) {
      this.filtreAction.objet_action = params.get('objet_action');
    }

    if (params.has('statut_echeance')) {
      this.filtreAction.statut_echeance = [params.get('statut_echeance')];
    }
  }

  isFederationReadOnly() {
    if (this.currentUtilisateur.perimetre !== 'National') {
      return true;
    }
    return false;
  }

  isAssociationReadOnly() {
    if ((this.currentUtilisateur.perimetre === 'Association' && (!this.currentUtilisateur.type_acces || this.currentUtilisateur.type_acces === 'multiSite' || this.currentUtilisateur.type_acces === 'monoAsso'))
    || this.currentUtilisateur.perimetre === 'Agence') {
      return true;
    }
    return false;
  }

  toggleShowFilter() {
    this.showFilter.emit();
  }

  getObjets() {
    let objetsList = [];
    objetsList = objetsList.concat(Action.objetsListeAlerteAuto);
    objetsList = objetsList.concat(Action.objetsListeEvaluation);

    this.parametreService.getParametresByParams({type: 'OBJET_ALERTE'})
      .subscribe((parametres: Parametre[]) => {

        parametres.forEach(function (parametre) {
          objetsList.push(parametre.libelle);
        });

        // On trie par ordre alphabétique
        objetsList.sort(function(a, b) {
          if ( a < b) { return -1; }
          if ( a > b) { return 1; }
          return 0;
        } );

        this.objetActionList = objetsList;
      });
  }

  filterObjetAction(event) {
    let query = event.query;
    let filtered: any[] = [];

    for (let i = 0; i < this.objetActionList.length; i++) {
      let objet_action = this.objetActionList[i];

      if (objet_action.toLowerCase().indexOf(query.toLowerCase()) === 0) {
          filtered.push(objet_action);
      }
    }
    this.filteredObjetActionList = filtered;
  }

  updateAssociationsItems() {
    let associationsItemsList = [];
    if (this.associationsList) {
      this.associationsList.forEach(function(association) {
        if (association.disabled) {
          associationsItemsList.push({label: association.libelle, value: association, styleClass: 'italic'});
        } else {
          associationsItemsList.push({label: association.libelle, value: association});
        }
      });
    }

    this.associationsItemsList = associationsItemsList;
  }

}
