import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {plainToClass} from 'class-transformer';

import {MessageService} from 'primeng/api';

import {Action, Echange} from '../../shared/_models';
import {ActionService, EchangeService} from '../../shared/_services';
import {OngletService} from '../../shared/_services/onglet.service';

@Component({
    selector: 'app-echange-new',
    templateUrl: './echange-new.component.html',
    styleUrls: ['./echange-new.component.css']
})
export class EchangeNewComponent implements OnInit, OnDestroy {

    isCreating = false;

    constructor(
        private router: Router,
        private messageService: MessageService,
        private echangeService: EchangeService,
        private actionService: ActionService,
        private ongletService: OngletService
    ) {
    }

    ngOnInit() {
      this.ongletService.enable('Échange en cours de création, non validé ou enregistré comme brouillon. Confirmez-vous la fermeture de l’onglet et la perte des données saisies ?');
    }

    onEchangeCreated(event) {
        this.isCreating = true;
        this.echangeService.createEchange(event)
            .subscribe(
                (echange: Echange) => {
                    console.log('creation echange');
                    let actionsForm = event.actions;
                    let nbActionsCreated = 0;
                    if (actionsForm.length > 0) { // Si il y a des actions a créer
                        let self = this;
                        actionsForm.forEach(function (actionObj) {
                            let actionValue = plainToClass(Action, actionObj as Object);
                            actionValue.echange = echange;
                            self.actionService.createAction(actionValue)
                                .subscribe(
                                    (action: Action) => {
                                        console.log('creation action [Id: ' + action.id_action + ']');
                                        nbActionsCreated++;
                                        if (actionsForm.length === nbActionsCreated) { // Si toutes les actions sont créées, on redirige
                                            self.isCreating = false;
                                            self.messageService.add({severity: 'success', summary: 'Succès', detail: 'Echange créé avec succès.'});
                                            if (localStorage.getItem('fermerOnglet') !== 'true') {
                                              self.router.navigate(['/in/echange/' + echange.id_echange]);
                                            } else {
                                              self.ongletService.disable();
                                              window.close();
                                            }
                                        }
                                    },
                                    error => {
                                        console.log(error);
                                        self.messageService.add({severity: 'error', summary: 'Erreur', detail: 'Erreur dans la création de l\'action.'});
                                    });
                        });
                    } else {
                        this.isCreating = false;
                        this.messageService.add({severity: 'success', summary: 'Succès', detail: 'Echange créé avec succès.'});
                        if (localStorage.getItem('fermerOnglet') !== 'true') {
                          this.router.navigate(['/in/echange/' + echange.id_echange]);
                        } else {
                          this.ongletService.disable();
                          window.close();
                        }
                    }
                },
                error => {
                    console.log(error);
                    this.isCreating = false;
                    this.messageService.add({severity: 'error', summary: 'Erreur', detail: 'Erreur dans la création de l\'echange.'});
                }
            );
    }

  ngOnDestroy(): void {
    this.ongletService.disable();
  }

}
