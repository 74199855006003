import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard} from '../shared/_guards';

import {RechercheEchangeComponent} from './recherche-echange/recherche-echange.component';

const rechercheEchangeRoutes: Routes = [
  {
    path: 'in/recherche',
    canActivate: [AuthGuard],
    component: RechercheEchangeComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(rechercheEchangeRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class RechercheEchangeRoutingModule {
}
