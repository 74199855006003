<p-toast [style]="{marginTop: '80px'}"></p-toast>

<div class="anchor-bar ui-grid-row">
  <div style="float:left;">
    <a [ngxScrollTo]="'panelPersonne'" [ngxScrollToOffset]="-94">Personne</a> |
    <a [ngxScrollTo]="'panelQualification'" [ngxScrollToOffset]="-94">Qualification</a> |
    <a [ngxScrollTo]="'panelActions'" [ngxScrollToOffset]="-94">Actions</a>
  </div>

  <div *ngIf="formMode == 'edit'" class="navigation-echanges ui-g-6" style="text-align: right;float:right;">
    <a *ngIf="previousEchangeId" [routerLink]="['/in/echange', previousEchangeId]">Echange précédent</a> <span
      *ngIf="previousEchangeId && nextEchangeId"> | </span>
    <a *ngIf="nextEchangeId" [routerLink]="['/in/echange', nextEchangeId]">Echange suivant</a>
  </div>
</div>
<p-panel [style]="{marginTop: '22px'}" [showHeader]="false">
  <p-header>
    <div class="ui-helper-clearfix">
      <span class="ui-panel-title" style="font-size:16px;display:inline-block;margin-top:2px">
        <span *ngIf="formMode == 'create'"><i class="fa fa-plus-circle" style="margin-right: 5px;"></i>Créer un nouvel
          échange</span>
        <span *ngIf="formMode == 'edit'"><i class="fa fa-pencil" style="margin-right: 5px;"></i>Editer l'échange
          (id:{{echange.id_echange}})</span>
      </span>
    </div>
  </p-header>
  <form *ngIf="echangeForm" [formGroup]="echangeForm" (ngSubmit)="onSubmit()">
    <div class="ui-grid ui-grid-responsive ui-grid-pad ui-fluid">
      <p-accordion #accordionEchange expandIcon="pi pi-fw pi-chevron-down" collapseIcon="pi pi-fw pi-chevron-up"
        [multiple]="true">
        <p-accordionTab class="accordionTab-panel-header-category" [selected]="true" id="panelPersonne" #panelPersonne>
          <p-header>
            <i class="fa fa-user-circle" style="font-size: 17px;"></i>
            Personne
          </p-header>

          <div class="ui-grid-row">
            <div class="ui-g-3">
              <div class="ui-g-12">
                <p-dropdown [options]="civilitesList" [class]="!isPersonneEditable ? 'disabled' : ''"
                  dataKey="id_parametre" [placeholder]="'Civilité...'" [style]="{width: '100%'}"
                  [readonly]="!isPersonneEditable" formControlName="personne_sujet_civilite" optionLabel="libelle">
                </p-dropdown>
              </div>
              <div class="ui-g-12"
                *ngIf="echangeForm.controls['personne_sujet_civilite'].invalid && (echangeForm.controls['personne_sujet_civilite'].dirty || echangeForm.controls['personne_sujet_civilite'].touched)">
                <p-message severity="error" text="La civilité est invalide"></p-message>
              </div>
            </div>
            <div class="ui-g-3">
              <div class="ui-g-12">
                <input pInputText type="text" formControlName="personne_sujet_nom" placeholder="Nom"
                  [readonly]="!isPersonneEditable" pTooltip="Nom" />
              </div>
              <div class="ui-g-12"
                *ngIf="echangeForm.controls['personne_sujet_nom'].invalid && (echangeForm.controls['personne_sujet_nom'].dirty || echangeForm.controls['personne_sujet_nom'].touched)">
                <p-message severity="error" text="Le nom du sujet de l'échange est obligatoire"></p-message>
              </div>
            </div>
            <div class="ui-g-3">
              <div class="ui-g-12">
                <input pInputText type="text" formControlName="personne_sujet_prenom" placeholder="Prénom"
                  [readonly]="!isPersonneEditable" pTooltip="Prénom" />
              </div>
              <div class="ui-g-12"
                *ngIf="echangeForm.controls['personne_sujet_prenom'].invalid && (echangeForm.controls['personne_sujet_prenom'].dirty || echangeForm.controls['personne_sujet_prenom'].touched)">
                <p-message severity="error" text="Le prénom du sujet de l'échange est obligatoire"></p-message>
              </div>
            </div>
            <div class="ui-g-3">
              <div class="ui-g-12">
                <input pInputText type="text" formControlName="personne_sujet_societe" placeholder="Société"
                  [readonly]="!isPersonneEditable" pTooltip="Société" />
              </div>
              <div class="ui-g-12"
                *ngIf="echangeForm.controls['personne_sujet_societe'].invalid && (echangeForm.controls['personne_sujet_societe'].dirty || echangeForm.controls['personne_sujet_societe'].touched)">
                <p-message severity="error" text="La société du sujet de l'échange est invalide"></p-message>
              </div>
            </div>
          </div>

          <div class="ui-grid-row">
            <div class="ui-g-3">
              <div class="ui-g-12">
                <div class="ui-inputgroup">
                  <span class="ui-inputgroup-addon"><i class="fa fa-home"></i></span>
                  <input pInputText type="text" formControlName="personne_sujet_tel_domicile"
                    placeholder="Tél. domicile" [readonly]="!isPersonneEditable" />
                  <!--<p-inputMask  type="text" mask="99 99 99 99 99" [autoClear]="false" formControlName="personne_sujet_tel_domicile" placeholder="Tél. domicile" [readonly]="!isPersonneEditable"></p-inputMask>-->
                  <span
                    *ngIf="echangeForm.controls['personne_sujet_nature'].value && echangeForm.controls['personne_sujet_nature'].value.code_parametre === 'PRO'"
                    class="ui-inputgroup-addon">*</span>
                </div>
              </div>
              <div class="ui-g-12"
                *ngIf="echangeForm.controls['personne_sujet_tel_domicile'].invalid && (echangeForm.controls['personne_sujet_tel_domicile'].dirty || echangeForm.controls['personne_sujet_tel_domicile'].touched)">
                <p-message severity="error" text="Le téléphone domicile est invalide"></p-message>
              </div>
            </div>
            <div class="ui-g-3">
              <div class="ui-g-12">
                <div class="ui-inputgroup">
                  <span class="ui-inputgroup-addon"><i class="fa fa-phone"></i></span>
                  <input pInputText type="text" formControlName="personne_sujet_tel_travail"
                    placeholder="Tél. professionnel" [readonly]="!isPersonneEditable" />
                  <!--<p-inputMask  type="text" mask="99 99 99 99 99" [autoClear]="false" formControlName="personne_sujet_tel_travail" placeholder="Tél. professionnel" [readonly]="!isPersonneEditable"></p-inputMask>-->
                </div>
              </div>
              <div class="ui-g-12"
                *ngIf="echangeForm.controls['personne_sujet_tel_travail'].invalid && (echangeForm.controls['personne_sujet_tel_travail'].dirty || echangeForm.controls['personne_sujet_tel_travail'].touched)">
                <p-message severity="error" text="Le téléphone professionnel est invalide"></p-message>
              </div>
            </div>
            <div class="ui-g-3">
              <div class="ui-g-12">
                <div class="ui-inputgroup">
                  <span class="ui-inputgroup-addon"><i class="fa fa-mobile"></i></span>
                  <input pInputText type="text" formControlName="personne_sujet_tel_mobile" placeholder="Tél. portable"
                    [readonly]="!isPersonneEditable" />
                  <!--<p-inputMask  type="text" mask="99 99 99 99 99" [autoClear]="false" formControlName="personne_sujet_tel_mobile" placeholder="Tél. portable" [readonly]="!isPersonneEditable"></p-inputMask>-->
                  <span
                    *ngIf="echangeForm.controls['personne_sujet_nature'].value && echangeForm.controls['personne_sujet_nature'].value.code_parametre === 'PRO'"
                    class="ui-inputgroup-addon">*</span>
                </div>
              </div>
              <div class="ui-g-12"
                *ngIf="echangeForm.controls['personne_sujet_tel_mobile'].invalid && (echangeForm.controls['personne_sujet_tel_mobile'].dirty || echangeForm.controls['personne_sujet_tel_mobile'].touched)">
                <p-message severity="error" text="Le téléphone mobile est invalide"></p-message>
              </div>
            </div>
            <div class="ui-g-3">
              <div class="ui-g-12">
                <div class="ui-inputgroup">
                  <span class="ui-inputgroup-addon"><i class="fa fa-envelope"></i></span>
                  <input type="text" pInputText placeholder="Email" formControlName="personne_sujet_mail"
                    [readonly]="!isPersonneEditable">
                </div>
              </div>
              <div class="ui-g-12"
                *ngIf="echangeForm.controls['personne_sujet_mail'].invalid && (echangeForm.controls['personne_sujet_mail'].dirty || echangeForm.controls['personne_sujet_mail'].touched)">
                <p-message severity="error" text="L'email du sujet de l'échange est invalide"></p-message>
              </div>
            </div>
          </div>
          <div class="ui-grid-row p-dir-rev">
            <div *ngIf="!isClosed && canEditEchange()" class="ui-grid-col-4">
              <button *ngIf="!loadingAnnuaireExterne" pButton type="button" class="green-button" label="Recherche Annuaire"
                (click)="searchAnnuaireExterne()" icon="pi pi-search"
                [disabled]="!canSearchAnnuaireExterne"></button>
              <button *ngIf="loadingAnnuaireExterne" pButton type="button" class="green-button" label="Recherche Annuaire"
                icon="pi pi-spin pi-spinner" (click)="searchAnnuaireExterne()"
                [disabled]="!canSearchAnnuaireExterne"></button>
            </div>
          </div>

          <p-dialog [(visible)]="displayAnnuaireExterneModal" [style]="{maxWidth: '90%', minWidth:'50%'}" [modal]="true"
            [contentStyle]="{'max-height':'600px'}" (onHide)="closeAnnuaireExterneModal()">
            <p-header>
              Recherche annuaire
            </p-header>
            <div class="ui-grid ui-grid-responsive ui-grid-pad ui-fluid" style="margin: 10px 0px">
              <p-table [value]="listeAnnuaireExt" dataKey="id" class="table-hover" [selectionMode]="'single'" [paginator]="true" [rows]="10" [expandedRowKeys]="expandedRows">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th style="width: 3em"></th>
                        <th style="width: 7em">Sélection</th>
                        <th>
                            Nom
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns" style="background-color: red;">
                    <tr>
                        <td>
                            <a href="#" [pRowToggler]="rowData">
                                <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                            </a>
                        </td>
                        <td>
                          <p-radioButton name="annuaireExt" (onClick)="selectAnnuaire(rowData)"></p-radioButton>
                        </td>
                        <td>
                            <b>{{rowData['raisonSociale']}}</b>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
                    <tr *ngFor="let contact of rowData.contacts">
                        <td style="width: 3em"></td>
                        <td style="width: 7em"><p-radioButton name="annuaireExt" (onClick)="selectAnnuaire(contact, rowData)"></p-radioButton></td>
                        <td style="padding-left: 3em;">
                          {{contact.nom + " " + contact.prenom}}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="3">
                      <p>Aucun contact correspondant à vos critères de recherche n'a été trouvé</p>
                    </td>
                  </tr>
                </ng-template>
            </p-table>
            </div>
            <p-footer>
              <button type="button" pButton class="green-button" icon="pi pi-check" label="Valider" (click)="saveAnnuaireExt()" *ngIf="selectAnnuaireExtTmp"></button>
            </p-footer>
          </p-dialog>

          <p-accordion class="accordion-echange-adresse" expandIcon="pi pi-fw pi-chevron-down"
            collapseIcon="pi pi-fw pi-chevron-up">
            <p-accordionTab header="Adresse" [selected]="true" style="padding:0px;">
              <div class="ui-grid-row">
                <div class="ui-g-3">
                  <div class="ui-g-12">
                    <input pInputText type="text" formControlName="personne_sujet_adr_num" placeholder="Numéro voie"
                      [readonly]="!isPersonneEditable" />
                  </div>
                  <div class="ui-g-12"
                    *ngIf="echangeForm.controls['personne_sujet_adr_num'].invalid && (echangeForm.controls['personne_sujet_adr_num'].dirty || echangeForm.controls['personne_sujet_adr_num'].touched)">
                    <p-message severity="error"
                      text="Le numéro de voie doit être un entier ne dépassant pas 4 caractères"></p-message>
                  </div>
                </div>
                <div class="ui-g-3">
                  <div class="ui-g-12">
                    <p-dropdown [options]="complementsAdresseList" [class]="!isPersonneEditable ? 'disabled' : ''"
                      [placeholder]="'Complément...'" [style]="{width: '100%'}"
                      formControlName="personne_sujet_adr_complement" [readonly]="!isPersonneEditable"></p-dropdown>
                  </div>
                  <div class="ui-g-12"
                    *ngIf="echangeForm.controls['personne_sujet_adr_complement'].invalid && (echangeForm.controls['personne_sujet_adr_complement'].dirty || echangeForm.controls['personne_sujet_adr_complement'].touched)">
                    <p-message severity="error" text="Le complément d'adresse est invalide"></p-message>
                  </div>
                </div>
                <div class="ui-g-6">
                  <div class="ui-g-12">
                    <input pInputText type="text" formControlName="personne_sujet_adr_voie" placeholder="Libellé voie"
                      [readonly]="!isPersonneEditable" />
                  </div>
                  <div class="ui-g-12"
                    *ngIf="echangeForm.controls['personne_sujet_adr_voie'].invalid && (echangeForm.controls['personne_sujet_adr_voie'].dirty || echangeForm.controls['personne_sujet_adr_voie'].touched)">
                    <p-message severity="error" text="Le téléphone mobile est invalide"></p-message>
                  </div>
                </div>
              </div>
              <div class="ui-grid-row">
                <div class="ui-g-6">
                  <div class="ui-g-12">
                    <input pInputText type="text" formControlName="personne_sujet_adr_complement1"
                      placeholder="N° d'appartement ou de boite à lettres, étage, couloir, escaliers"
                      [readonly]="!isPersonneEditable" />
                  </div>
                  <div class="ui-g-12"
                    *ngIf="echangeForm.controls['personne_sujet_adr_complement1'].invalid && (echangeForm.controls['personne_sujet_adr_complement1'].dirty || echangeForm.controls['personne_sujet_adr_complement1'].touched)">
                    <p-message severity="error" text="Le complément 1 est invalide"></p-message>
                  </div>
                </div>
                <div class="ui-g-6">
                  <div class="ui-g-12">
                    <input pInputText type="text" formControlName="personne_sujet_adr_complement2"
                      placeholder="Entrée, bâtiment, immeuble, résidence" [readonly]="!isPersonneEditable" />
                  </div>
                  <div class="ui-g-12"
                    *ngIf="echangeForm.controls['personne_sujet_adr_complement2'].invalid && (echangeForm.controls['personne_sujet_adr_complement2'].dirty || echangeForm.controls['personne_sujet_adr_complement2'].touched)">
                    <p-message severity="error" text="Le complément 2 est invalide"></p-message>
                  </div>
                </div>
              </div>
              <div class="ui-grid-row">
                <div class="ui-g-2">
                  <div class="ui-g-12">
                    <input pInputText type="text" formControlName="personne_sujet_adr_code_postal"
                      placeholder="Code postal" [readonly]="!isPersonneEditable" />
                    <!--<p-inputMask  type="text" mask="99999" formControlName="personne_sujet_adr_code_postal" placeholder="Code postal" [readonly]="!isPersonneEditable"></p-inputMask>-->
                  </div>
                  <div class="ui-g-12"
                    *ngIf="echangeForm.controls['personne_sujet_adr_code_postal'].invalid && (echangeForm.controls['personne_sujet_adr_code_postal'].dirty || echangeForm.controls['personne_sujet_adr_code_postal'].touched)">
                    <p-message severity="error" text="Le code postal est invalide"></p-message>
                  </div>
                </div>
                <div class="ui-g-4">
                  <div class="ui-g-12">
                    <p-dropdown [options]="communesList" [class]="!isPersonneEditable ? 'disabled' : ''"
                      dataKey="id_commune" [placeholder]="'Commune...'" [style]="{width: '100%'}"
                      formControlName="personne_sujet_adr_commune" optionLabel="nom_commune"
                      [readonly]="!isPersonneEditable"></p-dropdown>
                  </div>
                  <div class="ui-g-12"
                    *ngIf="echangeForm.controls['personne_sujet_adr_commune'].invalid && (echangeForm.controls['personne_sujet_adr_commune'].dirty || echangeForm.controls['personne_sujet_adr_commune'].touched)">
                    <p-message severity="error" text="La commune est invalide"></p-message>
                  </div>
                </div>
                <div class="ui-g-6">
                  <div class="ui-g-12">
                    <input pInputText type="text" formControlName="personne_sujet_adr_lieudit"
                      placeholder="Lieu dit, Ancienne commune, ..." [readonly]="!isPersonneEditable" />
                  </div>
                  <div class="ui-g-12"
                    *ngIf="echangeForm.controls['personne_sujet_adr_lieudit'].invalid && (echangeForm.controls['personne_sujet_adr_lieudit'].dirty || echangeForm.controls['personne_sujet_adr_lieudit'].touched)">
                    <p-message severity="error" text="Le lieu dit est invalide"></p-message>
                  </div>
                </div>
              </div>
            </p-accordionTab>
          </p-accordion>

          <div class="ui-grid-row">
            <div class="ui-g-4">
              <div class="ui-g-3">
                <label>Fédération</label>
              </div>
              <div class="ui-g-9">
                <p-dropdown [options]="federationsList" [style]="{'width':'100%'}"
                  [class]="(this.currentUtilisateur.perimetre !== 'National' || !isPersonneEditable) ? 'disabled' : ''"
                  [placeholder]="'Fédération...'"
                  [readonly]="this.currentUtilisateur.perimetre !== 'National' || !isPersonneEditable"
                  formControlName="federation" optionLabel="libelle" dataKey="id_federation" filter="true"
                  emptyFilterMessage="Aucun résultat trouvé" (onChange)="onChangeFederation()"></p-dropdown>
              </div>
              <div class="ui-g-12"
                *ngIf="echangeForm.controls['federation'].invalid && (echangeForm.controls['federation'].dirty || echangeForm.controls['federation'].touched)">
                <p-message severity="error" text="La fédération est obligatoire"></p-message>
              </div>
            </div>
            <div class="ui-g-4">
              <div class="ui-g-3">
                <label>Association</label>
              </div>
              <div class="ui-g-9">
                <!--
                            <p-dropdown [options]="associationsList" [style]="{'width':'100%'}"
                            [class]="(this.currentUtilisateur.type_acces === 'monoAsso' || this.currentUtilisateur.type_acces === 'multiSite' || !isPersonneEditable) ? 'disabled' : ''"
                            [placeholder]="'Association'" [readonly]="this.currentUtilisateur.type_acces === 'monoAsso' || this.currentUtilisateur.type_acces === 'multiSite' || !isPersonneEditable"  formControlName="association" optionLabel="libelle" dataKey="id_association"></p-dropdown>
                          -->
                <p-dropdown [options]="associationsList" [style]="{'width':'100%'}"
                  [class]="(this.currentUtilisateur.type_acces === 'monoAsso' || this.currentUtilisateur.type_acces === 'multiSite' || !isPersonneEditable) && !canEditAssociation ? 'disabled' : ''"
                  [placeholder]="'Association'"
                  [readonly]="(this.currentUtilisateur.type_acces === 'monoAsso' || this.currentUtilisateur.type_acces === 'multiSite' || !isPersonneEditable) && !canEditAssociation"
                  [showClear]="(this.currentUtilisateur.type_acces !== 'monoAsso' && this.currentUtilisateur.type_acces !== 'multiSite' && isPersonneEditable) || canEditAssociation"
                  formControlName="association" dataKey="id_association" filter="true"
                  emptyFilterMessage="Aucun résultat trouvé" optionLabel="libelle">
                  <ng-template let-item pTemplate="selectedItem">
                    <div *ngIf="item.value">
                      <span *ngIf="item.value.disabled" style="font-style: italic;"><i class="pi pi-times"
                          style="color: red;float: left;"></i>{{item.value.libelle}}</span>
                      <span *ngIf="!item.value.disabled">{{item.value.libelle}}</span>
                    </div>
                  </ng-template>
                  <ng-template let-association pTemplate="item">
                    <span *ngIf="association.value.disabled" style="font-style: italic;"><i class="pi pi-times"
                        style="color: red;float: left;"></i>{{association.value.libelle}}</span>
                    <span *ngIf="!association.value.disabled">{{association.value.libelle}}</span>
                  </ng-template>
                </p-dropdown>
              </div>
              <div class="ui-g-12"
                *ngIf="echangeForm.controls['association'].invalid && (echangeForm.controls['association'].dirty || echangeForm.controls['association'].touched)">
                <p-message severity="error" text="L'association est obligatoire"></p-message>
              </div>
            </div>
            <div class="ui-g-4">
              <div class="ui-g-2">
                <label>*Agence</label>
              </div>
              <div class="ui-g-9">
                <!--
                            <p-dropdown [options]="agencesList" [style]="{'width':'100%'}"
                            [class]="(this.currentUtilisateur.type_acces === 'monoAsso' || this.currentUtilisateur.type_acces === 'multiSite' || !isPersonneEditable) && !canEditAgence ? 'disabled' : ''"
                            [placeholder]="'Agence'" [readonly]="(this.currentUtilisateur.type_acces === 'monoAsso' || this.currentUtilisateur.type_acces === 'multiSite' || !isPersonneEditable) && !canEditAgence"  formControlName="agence" optionLabel="libelle" dataKey="id_agence"></p-dropdown>
                          -->
                <p-dropdown [options]="agencesList" [style]="{'width':'100%'}"
                  [class]="(this.currentUtilisateur.type_acces === 'monoAsso' || this.currentUtilisateur.type_acces === 'multiSite' || !isPersonneEditable) && !canEditAgence ? 'disabled' : ''"
                  [placeholder]="'Agence'"
                  [readonly]="(this.currentUtilisateur.type_acces === 'monoAsso' || this.currentUtilisateur.type_acces === 'multiSite' || !isPersonneEditable) && !canEditAgence"
                  formControlName="agence" dataKey="id_agence" filter="true" emptyFilterMessage="Aucun résultat trouvé"
                  optionLabel="libelle">
                  <ng-template let-item pTemplate="selectedItem">
                    <div *ngIf="item.value">
                      <span *ngIf="item.value.disabled" style="font-style: italic;"><i class="pi pi-times"
                          style="color: red;float: left;"></i>{{item.value.libelle}}</span>
                      <span *ngIf="!item.value.disabled">{{item.value.libelle}}</span>
                    </div>
                  </ng-template>
                  <ng-template let-agence pTemplate="item">
                    <span *ngIf="agence.value.disabled" style="font-style: italic;"><i class="pi pi-times"
                        style="color: red;float: left;"></i>{{agence.value.libelle}}</span>
                    <span *ngIf="!agence.value.disabled">{{agence.value.libelle}}</span>
                  </ng-template>
                </p-dropdown>
              </div>
              <div class="ui-g-12"
                *ngIf="agenceRequired || (echangeForm.controls['statut_echange'].value && echangeForm.controls['statut_echange'].value.code_parametre !== 'B' && echangeForm.controls['agence'].invalid && (echangeForm.controls['agence'].dirty || echangeForm.controls['agence'].touched))">
                <p-message severity="error" text="L'agence est obligatoire"></p-message>
              </div>
            </div>
          </div>

          <div class="ui-grid-row">
            <div class="ui-g-4">
              <div class="ui-g-2">
                <label>Nature</label>
              </div>
              <div class="ui-g-8">
                <p-dropdown [options]="naturesList" [class]="!isPersonneEditable ? 'disabled' : ''"
                  dataKey="id_parametre" [placeholder]="'Nature...'" [style]="{width: '100%'}"
                  formControlName="personne_sujet_nature" optionLabel="libelle" [readonly]="!isPersonneEditable">
                </p-dropdown>
              </div>
              <div class="ui-g-12"
                *ngIf="echangeForm.controls['personne_sujet_nature'].invalid && (echangeForm.controls['personne_sujet_nature'].dirty || echangeForm.controls['personne_sujet_nature'].touched)">
                <p-message severity="error" text="La nature est invalide"></p-message>
              </div>
            </div>
            <div class="ui-g-4">
              <p-checkbox *ngIf="canCreateProspect && isNewIndividu" binary="true" label="Créer le prospect dans Philia"
                [(ngModel)]="createProspect" [ngModelOptions]="{standalone: true}"></p-checkbox>
            </div>
            <div class="ui-g-4" style="font-style: italic;">
              <p>* Obligatoire pour la validation</p>
            </div>
          </div>
          <div class="ui-grid-row panel-footer">
            <div class="ui-grid-col-6"></div>
            <div class="ui-grid-col-2">
              <div *ngIf="echangeForm.value.personne_sujet_id_philia" class="ui-grid-col-6">
                <label>Id Philia : </label>
              </div>
              <div *ngIf="echangeForm.value.personne_sujet_id_philia" class="ui-grid-col-6" style="line-height: 32px;">
                {{echangeForm.value.personne_sujet_id_philia}}
              </div>
            </div>
            <div *ngIf="!isClosed && canEditEchange()" class="ui-grid-col-4">
              <button *ngIf="!loadingIndividus" pButton type="button" class="green-button" label="Recherche Philia"
                (click)="searchIndividuPhilia('sujet')" icon="pi pi-search"
                [disabled]="!canSearchIndividuPhilia"></button>
              <button *ngIf="loadingIndividus" pButton type="button" class="green-button" label="Recherche Philia"
                icon="pi pi-spin pi-spinner" (click)="searchIndividuPhilia('sujet')"
                [disabled]="!canSearchIndividuPhilia"></button>
            </div>
          </div>
        </p-accordionTab>
        <div *ngIf="isTiers" class="ui-grid ui-grid-responsive ui-grid-pad ui-fluid" style="margin: 10px 0px">
          <p-accordionTab class="accordionTab-panel-header-category" [selected]="true">
            <p-header style="width:96%">
              <span>
                <i class="pi pi-users" style="font-size: 17px;"></i>
                Tiers contactant
              </span>
              <span style="float:right" (click)="$event.stopPropagation();intervertirIndividus()">
                <i class="fa fa-exchange" style="font-size: 17px;"></i>
                Inverser
              </span>
            </p-header>
            <!--
              <div id="panel-personne" class="ui-grid-row panel-header-category">
                  <div class="ui-g-10">
                    <h2>
                      <i class="pi pi-users" style="font-size: 17px;"></i>
                      Tiers contactant
                    </h2>
                  </div>
                  <div class="ui-g-2">
                    <button pButton type="button" label="Inverser" icon="fa fa-exchange" iconPos="right" class="grey-button" (click)="intervertirIndividus()"></button>
                  </div>
              </div>-->
            <div class="ui-grid-row">
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <p-dropdown [options]="civilitesList" [class]="!isPersonneEditable ? 'disabled' : ''"
                    dataKey="id_parametre" [placeholder]="'Civilité...'" [style]="{width: '100%'}"
                    formControlName="personne_tiers_civilite" optionLabel="libelle" [readonly]="!isPersonneEditable">
                  </p-dropdown>
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['personne_tiers_civilite'].invalid && (echangeForm.controls['personne_tiers_civilite'].dirty || echangeForm.controls['personne_tiers_civilite'].touched)">
                  <p-message severity="error" text="La civilité est invalide"></p-message>
                </div>
              </div>
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <input pInputText type="text" formControlName="personne_tiers_nom" placeholder="Nom"
                    [readonly]="!isPersonneEditable" />
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['personne_tiers_nom'].invalid && (echangeForm.controls['personne_tiers_nom'].dirty || echangeForm.controls['personne_tiers_nom'].touched)">
                  <p-message severity="error" text="Le nom du tiers de l'échange est obligatoire"></p-message>
                </div>
              </div>
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <input pInputText type="text" formControlName="personne_tiers_prenom" placeholder="Prénom"
                    [readonly]="!isPersonneEditable" />
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['personne_tiers_prenom'].invalid && (echangeForm.controls['personne_tiers_prenom'].dirty || echangeForm.controls['personne_tiers_prenom'].touched)">
                  <p-message severity="error" text="Le prénom du tiers de l'échange est obligatoire"></p-message>
                </div>
              </div>
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <input pInputText type="text" formControlName="personne_tiers_societe" placeholder="Société"
                    [readonly]="!isPersonneEditable" />
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['personne_tiers_societe'].invalid && (echangeForm.controls['personne_tiers_societe'].dirty || echangeForm.controls['personne_tiers_societe'].touched)">
                  <p-message severity="error" text="La société du tiers de l'échange est invalide"></p-message>
                </div>
              </div>
            </div>

            <div class="ui-grid-row">
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <div class="ui-inputgroup">
                    <span class="ui-inputgroup-addon"><i class="fa fa-home"></i></span>
                    <input pInputText type="text" formControlName="personne_tiers_tel_domicile"
                      placeholder="Tél. domicile" [readonly]="!isPersonneEditable" />
                    <!--<p-inputMask  type="text" mask="99 99 99 99 99" [autoClear]="false" formControlName="personne_tiers_tel_domicile" placeholder="Tél. domicile" [readonly]="!isPersonneEditable"></p-inputMask>-->
                  </div>
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['personne_tiers_tel_domicile'].invalid && (echangeForm.controls['personne_tiers_tel_domicile'].dirty || echangeForm.controls['personne_tiers_tel_domicile'].touched)">
                  <p-message severity="error" text="Le téléphone domicile est invalide"></p-message>
                </div>
              </div>
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <div class="ui-inputgroup">
                    <span class="ui-inputgroup-addon"><i class="fa fa-phone"></i></span>
                    <input pInputText type="text" formControlName="personne_tiers_tel_travail"
                      placeholder="Tél. professionnel" [readonly]="!isPersonneEditable" />
                    <!--<p-inputMask  type="text" mask="99 99 99 99 99" [autoClear]="false" formControlName="personne_tiers_tel_travail" placeholder="Tél. professionnel" [readonly]="!isPersonneEditable"></p-inputMask>-->
                  </div>
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['personne_tiers_tel_travail'].invalid && (echangeForm.controls['personne_tiers_tel_travail'].dirty || echangeForm.controls['personne_tiers_tel_travail'].touched)">
                  <p-message severity="error" text="Le téléphone professionnel est invalide"></p-message>
                </div>
              </div>
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <div class="ui-inputgroup">
                    <span class="ui-inputgroup-addon"><i class="fa fa-mobile"></i></span>
                    <input pInputText type="text" formControlName="personne_tiers_tel_mobile"
                      placeholder="Tél. portable" [readonly]="!isPersonneEditable" />
                    <!--<p-inputMask  type="text" mask="99 99 99 99 99" [autoClear]="false" formControlName="personne_tiers_tel_mobile" placeholder="Tél. portable" [readonly]="!isPersonneEditable"></p-inputMask>-->
                  </div>
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['personne_tiers_tel_mobile'].invalid && (echangeForm.controls['personne_tiers_tel_mobile'].dirty || echangeForm.controls['personne_tiers_tel_mobile'].touched)">
                  <p-message severity="error" text="Le téléphone mobile est invalide"></p-message>
                </div>
              </div>
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <input pInputText type="text" formControlName="personne_tiers_mail" placeholder="Adresse email"
                    [readonly]="!isPersonneEditable" />
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['personne_tiers_mail'].invalid && (echangeForm.controls['personne_tiers_mail'].dirty || echangeForm.controls['personne_tiers_mail'].touched)">
                  <p-message severity="error" text="L'email du tiers de l'échange est invalide"></p-message>
                </div>
              </div>
            </div>
            <div class="ui-grid-row">
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <p-dropdown [options]="liensList" [class]="!isPersonneEditable ? 'disabled' : ''"
                    dataKey="id_parametre" [placeholder]="'Lien social...'" [style]="{width: '100%'}"
                    formControlName="personne_sujet_lien" optionLabel="libelle" [readonly]="!isPersonneEditable">
                  </p-dropdown>
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['personne_sujet_lien'].invalid && (echangeForm.controls['personne_sujet_lien'].dirty || echangeForm.controls['personne_sujet_lien'].touched)">
                  <p-message severity="error" text="Le lien social est invalide"></p-message>
                </div>
              </div>
            </div>

            <p-accordion class="accordion-echange-adresse" expandIcon="pi pi-fw pi-chevron-down"
              collapseIcon="pi pi-fw pi-chevron-up">
              <p-accordionTab header="Adresse" [selected]="true" style="padding:0px;">
                <div class="ui-grid-row">
                  <div class="ui-g-3">
                    <div class="ui-g-12">
                      <input pInputText type="text" formControlName="personne_tiers_adr_num" placeholder="Numéro voie"
                        [readonly]="!isPersonneEditable" />
                    </div>
                    <div class="ui-g-12"
                      *ngIf="echangeForm.controls['personne_tiers_adr_num'].invalid && (echangeForm.controls['personne_tiers_adr_num'].dirty || echangeForm.controls['personne_tiers_adr_num'].touched)">
                      <p-message severity="error"
                        text="Le numéro de voie doit être un entier ne dépassant pas 4 caractères"></p-message>
                    </div>
                  </div>
                  <div class="ui-g-3">
                    <div class="ui-g-12">
                      <p-dropdown [options]="complementsAdresseList" [class]="!isPersonneEditable ? 'disabled' : ''"
                        [placeholder]="'Complément...'" [style]="{width: '100%'}"
                        formControlName="personne_tiers_adr_complement" [readonly]="!isPersonneEditable"></p-dropdown>
                    </div>
                    <div class="ui-g-12"
                      *ngIf="echangeForm.controls['personne_tiers_adr_complement'].invalid && (echangeForm.controls['personne_tiers_adr_complement'].dirty || echangeForm.controls['personne_tiers_adr_complement'].touched)">
                      <p-message severity="error" text="Le complément d'adresse est invalide"></p-message>
                    </div>
                  </div>
                  <div class="ui-g-6">
                    <div class="ui-g-12">
                      <input pInputText type="text" formControlName="personne_tiers_adr_voie" placeholder="Libellé voie"
                        [readonly]="!isPersonneEditable" />
                    </div>
                    <div class="ui-g-12"
                      *ngIf="echangeForm.controls['personne_tiers_adr_voie'].invalid && (echangeForm.controls['personne_tiers_adr_voie'].dirty || echangeForm.controls['personne_tiers_adr_voie'].touched)">
                      <p-message severity="error" text="Le libellé voie est invalide"></p-message>
                    </div>
                  </div>
                </div>
                <div class="ui-grid-row">
                  <div class="ui-g-6">
                    <div class="ui-g-12">
                      <input pInputText type="text" formControlName="personne_tiers_adr_complement1"
                        placeholder="N° d'appartement ou de boite à lettres, étage, couloir, escaliers"
                        [readonly]="!isPersonneEditable" />
                    </div>
                    <div class="ui-g-12"
                      *ngIf="echangeForm.controls['personne_tiers_adr_complement1'].invalid && (echangeForm.controls['personne_tiers_adr_complement1'].dirty || echangeForm.controls['personne_tiers_adr_complement1'].touched)">
                      <p-message severity="error" text="Le complément 1 est invalide"></p-message>
                    </div>
                  </div>
                  <div class="ui-g-6">
                    <div class="ui-g-12">
                      <input pInputText type="text" formControlName="personne_tiers_adr_complement2"
                        placeholder="Entrée, bâtiment, immeuble, résidence" [readonly]="!isPersonneEditable" />
                    </div>
                    <div class="ui-g-12"
                      *ngIf="echangeForm.controls['personne_tiers_adr_complement2'].invalid && (echangeForm.controls['personne_tiers_adr_complement2'].dirty || echangeForm.controls['personne_tiers_adr_complement2'].touched)">
                      <p-message severity="error" text="Le complément 2 est invalide"></p-message>
                    </div>
                  </div>
                </div>
                <div class="ui-grid-row">
                  <div class="ui-g-2">
                    <div class="ui-g-12">
                      <input pInputText type="text" formControlName="personne_tiers_adr_code_postal"
                        placeholder="Code postal" [readonly]="!isPersonneEditable" />
                      <!-- <p-inputMask  type="text" mask="99999" formControlName="personne_tiers_adr_code_postal" placeholder="Code postal" [readonly]="!isPersonneEditable"></p-inputMask> -->
                    </div>
                    <div class="ui-g-12"
                      *ngIf="echangeForm.controls['personne_tiers_adr_code_postal'].invalid && (echangeForm.controls['personne_tiers_adr_code_postal'].dirty || echangeForm.controls['personne_tiers_adr_code_postal'].touched)">
                      <p-message severity="error" text="Le code postal est invalide"></p-message>
                    </div>
                  </div>
                  <div class="ui-g-4">
                    <div class="ui-g-12">
                      <p-dropdown [options]="communesTiersList" [class]="!isPersonneEditable ? 'disabled' : ''"
                        dataKey="id_commune" [placeholder]="'Commune...'" [style]="{width: '100%'}"
                        formControlName="personne_tiers_adr_commune" optionLabel="nom_commune"
                        [readonly]="!isPersonneEditable"></p-dropdown>
                    </div>
                    <div class="ui-g-12"
                      *ngIf="echangeForm.controls['personne_tiers_adr_commune'].invalid && (echangeForm.controls['personne_tiers_adr_commune'].dirty || echangeForm.controls['personne_tiers_adr_commune'].touched)">
                      <p-message severity="error" text="La commune est invalide"></p-message>
                    </div>
                  </div>
                  <div class="ui-g-6">
                    <div class="ui-g-12">
                      <input pInputText type="text" formControlName="personne_tiers_adr_lieudit"
                        placeholder="Lieu dit, Ancienne commune, ..." [readonly]="!isPersonneEditable" />
                    </div>
                    <div class="ui-g-12"
                      *ngIf="echangeForm.controls['personne_tiers_adr_lieudit'].invalid && (echangeForm.controls['personne_tiers_adr_lieudit'].dirty || echangeForm.controls['personne_tiers_adr_lieudit'].touched)">
                      <p-message severity="error" text="Le lieu dit est invalide"></p-message>
                    </div>
                  </div>
                </div>
                <div class="ui-grid-row panel-footer">
                  <div class="ui-grid-col-6"></div>
                  <div class="ui-grid-col-2">
                    <div *ngIf="echangeForm.value.personne_tiers_id_philia" class="ui-grid-col-6">
                      <label>Id Philia : </label>
                    </div>
                    <div *ngIf="echangeForm.value.personne_tiers_id_philia" class="ui-grid-col-6"
                      style="line-height: 32px;">
                      {{echangeForm.value.personne_tiers_id_philia}}
                    </div>
                  </div>
                  <div *ngIf="!isClosed && canEditEchange()" class="ui-grid-col-4">
                    <button *ngIf="!loadingIndividus" pButton type="button" class="green-button"
                      label="Recherche Philia" (click)="searchIndividuPhilia('tiers')" icon="pi pi-search"
                      [disabled]="!canSearchIndividuTiersPhilia"></button>
                    <button *ngIf="loadingIndividus" pButton type="button" class="green-button" label="Recherche Philia"
                      icon="pi pi-spin pi-spinner" (click)="searchIndividuPhilia('tiers')"
                      [disabled]="!canSearchIndividuTiersPhilia"></button>
                  </div>
                </div>
              </p-accordionTab>
            </p-accordion>
          </p-accordionTab>
        </div>
        <div class="ui-grid ui-grid-responsive ui-grid-pad ui-fluid" style="margin: 10px 0px">
          <p-accordionTab class="accordionTab-panel-header-category" [selected]="true">
            <p-header>
              <i class="pi pi-comments" style="font-size: 17px;"></i>
              Catégorie de l'échange
            </p-header>
            <div class="ui-grid-row">
              <div class="ui-g-3">
                <div class="ui-g-4">
                  <label>Catégorie</label>
                </div>
                <div class="ui-g-8">
                  <p-dropdown [options]="categoriesList" [class]="!isQualificationEditable ? 'disabled' : ''"
                    (onChange)="onChangeCategorieEchange()" dataKey="id_parametre" [placeholder]="'Catégorie...'"
                    [style]="{width: '100%'}" formControlName="categorie_echange" optionLabel="libelle"
                    [readonly]="!isQualificationEditable"></p-dropdown>
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['categorie_echange'].invalid && (echangeForm.controls['categorie_echange'].dirty || echangeForm.controls['categorie_echange'].touched)">
                  <p-message severity="error" text="La catégorie est invalide"></p-message>
                </div>
              </div>
              <div *ngIf="isReclamation" class="ui-g-4">
                <div class="ui-g-12">
                  <p-radioButton name="sous_categorie_echange" value="association" label="Association"
                    formControlName="sous_categorie_echange" [disabled]="!isQualificationEditable" (click)="onChangeCategorieEchange()"></p-radioButton>
                  <p-radioButton name="sous_categorie_echange" value="intervention" label="Intervention"
                    formControlName="sous_categorie_echange" [disabled]="!isQualificationEditable" (click)="onChangeCategorieEchange()"></p-radioButton>
                </div>
              </div>
              <div *ngIf="isTiersConcerne && canAddTiersConcerne" class="ui-g-2">
                <button pButton type="button" class="green-button" label="Ajouter un tiers concerné"
                  (click)="toggleTiersConcerne()" icon="pi pi-person" [disabled]="!isPersonneEditable"></button>
              </div>
            </div>
          </p-accordionTab>
        </div>

        <div *ngIf="isTiersConcerne && isTiersConcerneShow" class="ui-grid ui-grid-responsive ui-grid-pad ui-fluid"
          style="margin: 10px 0px">
          <p-accordionTab class="accordionTab-panel-header-category" [selected]="true">
            <p-header style="width:96%">
              <span>
                <i class="pi pi-users" style="font-size: 17px;"></i>
                Tiers concerné
              </span>
              <span style="float:right" (click)="$event.stopPropagation();intervertirIndividusConcerne()">
                <i class="fa fa-exchange" style="font-size: 17px;"></i>
                Inverser
              </span>
            </p-header>
            <div class="ui-grid-row">
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <p-dropdown [options]="civilitesList" [class]="!isPersonneEditable ? 'disabled' : ''"
                    dataKey="id_parametre" [placeholder]="'Civilité...'" [style]="{width: '100%'}"
                    formControlName="personne_tiers_concerne_civilite" optionLabel="libelle"
                    [readonly]="!isPersonneEditable"></p-dropdown>
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['personne_tiers_concerne_civilite'].invalid && (echangeForm.controls['personne_tiers_concerne_civilite'].dirty || echangeForm.controls['personne_tiers_concerne_civilite'].touched)">
                  <p-message severity="error" text="La civilité est invalide"></p-message>
                </div>
              </div>
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <input pInputText type="text" formControlName="personne_tiers_concerne_nom" placeholder="Nom"
                    [readonly]="!isPersonneEditable" />
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['personne_tiers_concerne_nom'].invalid && (echangeForm.controls['personne_tiers_concerne_nom'].dirty || echangeForm.controls['personne_tiers_concerne_nom'].touched)">
                  <p-message severity="error" text="Le nom du tiers de l'échange est obligatoire"></p-message>
                </div>
              </div>
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <input pInputText type="text" formControlName="personne_tiers_concerne_prenom" placeholder="Prénom"
                    [readonly]="!isPersonneEditable" />
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['personne_tiers_concerne_prenom'].invalid && (echangeForm.controls['personne_tiers_concerne_prenom'].dirty || echangeForm.controls['personne_tiers_concerne_prenom'].touched)">
                  <p-message severity="error" text="Le prénom du tiers de l'échange est obligatoire"></p-message>
                </div>
              </div>
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <input pInputText type="text" formControlName="personne_tiers_concerne_societe" placeholder="Société"
                    [readonly]="!isPersonneEditable" />
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['personne_tiers_concerne_societe'].invalid && (echangeForm.controls['personne_tiers_concerne_societe'].dirty || echangeForm.controls['personne_tiers_concerne_societe'].touched)">
                  <p-message severity="error" text="La société du tiers de l'échange est invalide"></p-message>
                </div>
              </div>
            </div>

            <div class="ui-grid-row">
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <div class="ui-inputgroup">
                    <span class="ui-inputgroup-addon"><i class="fa fa-home"></i></span>
                    <input pInputText type="text" formControlName="personne_tiers_concerne_tel_domicile"
                      placeholder="Tél. domicile" [readonly]="!isPersonneEditable" />
                    <!--<p-inputMask  type="text" mask="99 99 99 99 99" [autoClear]="false" formControlName="personne_tiers_concerne_tel_domicile" placeholder="Tél. domicile" [readonly]="!isPersonneEditable"></p-inputMask>-->
                  </div>
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['personne_tiers_concerne_tel_domicile'].invalid && (echangeForm.controls['personne_tiers_concerne_tel_domicile'].dirty || echangeForm.controls['personne_tiers_concerne_tel_domicile'].touched)">
                  <p-message severity="error" text="Le téléphone domicile est invalide"></p-message>
                </div>
              </div>
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <div class="ui-inputgroup">
                    <span class="ui-inputgroup-addon"><i class="fa fa-phone"></i></span>
                    <input pInputText type="text" formControlName="personne_tiers_concerne_tel_travail"
                      placeholder="Tél. professionnel" [readonly]="!isPersonneEditable" />
                    <!--<p-inputMask  type="text" mask="99 99 99 99 99" [autoClear]="false" formControlName="personne_tiers_concerne_tel_travail" placeholder="Tél. professionnel" [readonly]="!isPersonneEditable"></p-inputMask>-->
                  </div>
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['personne_tiers_concerne_tel_travail'].invalid && (echangeForm.controls['personne_tiers_concerne_tel_travail'].dirty || echangeForm.controls['personne_tiers_concerne_tel_travail'].touched)">
                  <p-message severity="error" text="Le téléphone professionnel est invalide"></p-message>
                </div>
              </div>
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <div class="ui-inputgroup">
                    <span class="ui-inputgroup-addon"><i class="fa fa-mobile"></i></span>
                    <input pInputText type="text" formControlName="personne_tiers_concerne_tel_mobile"
                      placeholder="Tél. portable" [readonly]="!isPersonneEditable" />
                    <!--<p-inputMask  type="text" mask="99 99 99 99 99" [autoClear]="false" formControlName="personne_tiers_concerne_tel_mobile" placeholder="Tél. portable" [readonly]="!isPersonneEditable"></p-inputMask>-->
                  </div>
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['personne_tiers_concerne_tel_mobile'].invalid && (echangeForm.controls['personne_tiers_concerne_tel_mobile'].dirty || echangeForm.controls['personne_tiers_concerne_tel_mobile'].touched)">
                  <p-message severity="error" text="Le téléphone mobile est invalide"></p-message>
                </div>
              </div>
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <input pInputText type="text" formControlName="personne_tiers_concerne_mail"
                    placeholder="Adresse email" [readonly]="!isPersonneEditable" />
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['personne_tiers_concerne_mail'].invalid && (echangeForm.controls['personne_tiers_concerne_mail'].dirty || echangeForm.controls['personne_tiers_concerne_mail'].touched)">
                  <p-message severity="error" text="L'email du tiers de l'échange est invalide"></p-message>
                </div>
              </div>
            </div>
            <div class="ui-grid-row">
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <p-dropdown [options]="naturesTiersConcerneList" [class]="!isPersonneEditable ? 'disabled' : ''"
                    dataKey="id_parametre" [placeholder]="'Nature...'" [style]="{width: '100%'}"
                    formControlName="personne_tiers_concerne_nature" optionLabel="libelle"
                    [readonly]="!isPersonneEditable"></p-dropdown>
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['personne_tiers_concerne_nature'].invalid && (echangeForm.controls['personne_tiers_concerne_nature'].dirty || echangeForm.controls['personne_tiers_concerne_nature'].touched)">
                  <p-message severity="error" text="La nature est invalide"></p-message>
                </div>
              </div>
            </div>

            <p-accordion class="accordion-echange-adresse" expandIcon="pi pi-fw pi-chevron-down"
              collapseIcon="pi pi-fw pi-chevron-up">
              <p-accordionTab header="Adresse" [selected]="true" style="padding:0px;">
                <div class="ui-grid-row">
                  <div class="ui-g-3">
                    <div class="ui-g-12">
                      <input pInputText type="text" formControlName="personne_tiers_concerne_adr_num"
                        placeholder="Numéro voie" [readonly]="!isPersonneEditable" />
                    </div>
                    <div class="ui-g-12"
                      *ngIf="echangeForm.controls['personne_tiers_concerne_adr_num'].invalid && (echangeForm.controls['personne_tiers_concerne_adr_num'].dirty || echangeForm.controls['personne_tiers_concerne_adr_num'].touched)">
                      <p-message severity="error"
                        text="Le numéro de voie doit être un entier ne dépassant pas 4 caractères"></p-message>
                    </div>
                  </div>
                  <div class="ui-g-3">
                    <div class="ui-g-12">
                      <p-dropdown [options]="complementsAdresseList" [class]="!isPersonneEditable ? 'disabled' : ''"
                        [placeholder]="'Complément...'" [style]="{width: '100%'}"
                        formControlName="personne_tiers_concerne_adr_complement" [readonly]="!isPersonneEditable">
                      </p-dropdown>
                    </div>
                    <div class="ui-g-12"
                      *ngIf="echangeForm.controls['personne_tiers_concerne_adr_complement'].invalid && (echangeForm.controls['personne_tiers_concerne_adr_complement'].dirty || echangeForm.controls['personne_tiers_concerne_adr_complement'].touched)">
                      <p-message severity="error" text="Le complément d'adresse est invalide"></p-message>
                    </div>
                  </div>
                  <div class="ui-g-6">
                    <div class="ui-g-12">
                      <input pInputText type="text" formControlName="personne_tiers_concerne_adr_voie"
                        placeholder="Libellé voie" [readonly]="!isPersonneEditable" />
                    </div>
                    <div class="ui-g-12"
                      *ngIf="echangeForm.controls['personne_tiers_concerne_adr_voie'].invalid && (echangeForm.controls['personne_tiers_concerne_adr_voie'].dirty || echangeForm.controls['personne_tiers_concerne_adr_voie'].touched)">
                      <p-message severity="error" text="Le libellé voie est invalide"></p-message>
                    </div>
                  </div>
                </div>
                <div class="ui-grid-row">
                  <div class="ui-g-6">
                    <div class="ui-g-12">
                      <input pInputText type="text" formControlName="personne_tiers_concerne_adr_complement1"
                        placeholder="N° d'appartement ou de boite à lettres, étage, couloir, escaliers"
                        [readonly]="!isPersonneEditable" />
                    </div>
                    <div class="ui-g-12"
                      *ngIf="echangeForm.controls['personne_tiers_concerne_adr_complement1'].invalid && (echangeForm.controls['personne_tiers_concerne_adr_complement1'].dirty || echangeForm.controls['personne_tiers_concerne_adr_complement1'].touched)">
                      <p-message severity="error" text="Le complément 1 est invalide"></p-message>
                    </div>
                  </div>
                  <div class="ui-g-6">
                    <div class="ui-g-12">
                      <input pInputText type="text" formControlName="personne_tiers_concerne_adr_complement2"
                        placeholder="Entrée, bâtiment, immeuble, résidence" [readonly]="!isPersonneEditable" />
                    </div>
                    <div class="ui-g-12"
                      *ngIf="echangeForm.controls['personne_tiers_concerne_adr_complement2'].invalid && (echangeForm.controls['personne_tiers_concerne_adr_complement2'].dirty || echangeForm.controls['personne_tiers_concerne_adr_complement2'].touched)">
                      <p-message severity="error" text="Le complément 2 est invalide"></p-message>
                    </div>
                  </div>
                </div>
                <div class="ui-grid-row">
                  <div class="ui-g-2">
                    <div class="ui-g-12">
                      <input pInputText type="text" formControlName="personne_tiers_concerne_adr_code_postal"
                        placeholder="Code postal" [readonly]="!isPersonneEditable" />
                      <!-- <p-inputMask  type="text" mask="99999" formControlName="personne_tiers_concerne_adr_code_postal" placeholder="Code postal" [readonly]="!isPersonneEditable"></p-inputMask> -->
                    </div>
                    <div class="ui-g-12"
                      *ngIf="echangeForm.controls['personne_tiers_concerne_adr_code_postal'].invalid && (echangeForm.controls['personne_tiers_concerne_adr_code_postal'].dirty || echangeForm.controls['personne_tiers_concerne_adr_code_postal'].touched)">
                      <p-message severity="error" text="Le code postal est invalide"></p-message>
                    </div>
                  </div>
                  <div class="ui-g-4">
                    <div class="ui-g-12">
                      <p-dropdown [options]="communesTiersConcerneList" [class]="!isPersonneEditable ? 'disabled' : ''"
                        dataKey="id_commune" [placeholder]="'Commune...'" [style]="{width: '100%'}"
                        formControlName="personne_tiers_concerne_adr_commune" optionLabel="nom_commune"
                        [readonly]="!isPersonneEditable"></p-dropdown>
                    </div>
                    <div class="ui-g-12"
                      *ngIf="echangeForm.controls['personne_tiers_concerne_adr_commune'].invalid && (echangeForm.controls['personne_tiers_concerne_adr_commune'].dirty || echangeForm.controls['personne_tiers_concerne_adr_commune'].touched)">
                      <p-message severity="error" text="La commune est invalide"></p-message>
                    </div>
                  </div>
                  <div class="ui-g-6">
                    <div class="ui-g-12">
                      <input pInputText type="text" formControlName="personne_tiers_concerne_adr_lieudit"
                        placeholder="Lieu dit, Ancienne commune, ..." [readonly]="!isPersonneEditable" />
                    </div>
                    <div class="ui-g-12"
                      *ngIf="echangeForm.controls['personne_tiers_concerne_adr_lieudit'].invalid && (echangeForm.controls['personne_tiers_concerne_adr_lieudit'].dirty || echangeForm.controls['personne_tiers_concerne_adr_lieudit'].touched)">
                      <p-message severity="error" text="Le lieu dit est invalide"></p-message>
                    </div>
                  </div>
                </div>
                <div class="ui-grid-row panel-footer">
                  <div class="ui-grid-col-6"></div>
                  <div class="ui-grid-col-2">
                    <div *ngIf="echangeForm.value.personne_tiers_concerne_id_philia" class="ui-grid-col-6">
                      <label>Id Philia : </label>
                    </div>
                    <div *ngIf="echangeForm.value.personne_tiers_concerne_id_philia" class="ui-grid-col-6"
                      style="line-height: 32px;">
                      {{echangeForm.value.personne_tiers_concerne_id_philia}}
                    </div>
                  </div>
                  <div *ngIf="!isClosed && canEditEchange()" class="ui-grid-col-4">
                    <button *ngIf="!loadingIndividus" pButton type="button" class="green-button"
                      label="Recherche Philia" (click)="searchIndividuPhilia('tiers_concerne')" icon="pi pi-search"
                      [disabled]="!canSearchIndividuTiersConcernePhilia"></button>
                    <button *ngIf="loadingIndividus" pButton type="button" class="green-button" label="Recherche Philia"
                      icon="pi pi-spin pi-spinner" (click)="searchIndividuPhilia('tiers_concerne')"
                      [disabled]="!canSearchIndividuTiersConcernePhilia"></button>
                  </div>
                </div>
              </p-accordionTab>
            </p-accordion>
          </p-accordionTab>
        </div>


        <div class="ui-grid ui-grid-responsive ui-grid-pad ui-fluid" style="margin: 10px 0px">
          <p-accordionTab class="accordionTab-panel-header-category" [selected]="true" id="panelQualification"
            #panelQualification>
            <p-header>
              <i class="pi pi-comments" style="font-size: 17px;"></i>
              <span *ngIf="!isDevis">Qualification de l'échange</span>
              <span *ngIf="isDevis">Qualification de la demande de devis</span>
            </p-header>
            <div class="ui-grid-row">
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <p-calendar *ngIf="isQualificationEditable" [dateFormat]="dateFormat" [locale]="locale"
                    showTime="true" formControlName="created" [maxDate]="maxDateEchange"></p-calendar>
                  <input *ngIf="!isQualificationEditable" type="text" pInputText
                    [value]="echangeForm.value.created | date: datePipeFormat" readonly="true" />
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['created'].invalid && (echangeForm.controls['created'].dirty || echangeForm.controls['created'].touched)">
                  <p-message severity="error" text="La date de l'échange est invalide"></p-message>
                </div>
              </div>
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <input type="text" pInputText pTooltip="Utilisateur" [value]="echangeForm.value.operateur.libelle"
                    readonly="true" />
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['operateur'].invalid && (echangeForm.controls['operateur'].dirty || echangeForm.controls['operateur'].touched)">
                  <p-message severity="error" text="L'utilisateur de l'échange est invalide"></p-message>
                </div>
              </div>
              <div
                *ngIf="echangeForm.controls['statut_echange'].value && echangeForm.controls['statut_echange'].value.code_parametre !== 'B' && echangeForm.controls['statut_echange'].value.code_parametre !== 'A'"
                class="ui-g-3">
                <div class="ui-g-2">
                  <p-inputSwitch (onChange)="onChangeStatutEchange($event)" formControlName="isStatutOpen"
                    [readonly]="!canReopen"></p-inputSwitch>
                </div>
                <div class="ui-g-3">
                  <label>Ouvert </label>
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['statut_echange'].invalid && (echangeForm.controls['statut_echange'].dirty || echangeForm.controls['statut_echange'].touched)">
                  <p-message severity="error" text="Le statut de l'échange est invalide"></p-message>
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.errors?.actionOpen && (echangeForm.dirty || echangeForm.touched)">
                  <p-message severity="error"
                    text="Toutes les actions en cours doivent être fermées afin de pouvoir clôturer l'échange">
                  </p-message>
                </div>
              </div>
              <div class="ui-g-3">
                <div class="ui-g-2">
                  <p-inputSwitch (onChange)="onChangePrioriteEchange($event)" formControlName="isPrioriteUrgent"
                    [readonly]="!isQualificationEditable"></p-inputSwitch>
                </div>
                <div class="ui-g-3">
                  <label>Urgent</label>
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['priorite'].invalid && (echangeForm.controls['priorite'].dirty || echangeForm.controls['priorite'].touched)">
                  <p-message severity="error" text="La priorite est invalide"></p-message>
                </div>
              </div>
              <div class="ui-g-3">
                <div class="ui-g-2">
                  <p-inputSwitch (onChange)="onChangeAstreinte($event)" formControlName="astreinte"
                                 [readonly]="!canEditAstreinte"
                  [class]="canEditAstreinte ? 'disabled' : ''"></p-inputSwitch>
                </div>
                <div class="ui-g-3">
                  <label>Astreinte</label>
                </div>

              </div>
            </div>
            <div class="ui-grid-row">
              <div class="ui-g-3">
              <div
                class="ui-g-12">
                <p-dropdown [options]="typesEchangeList" [class]="!isQualificationEditable ? 'disabled' : ''"
                            [placeholder]="'Type...'" [style]="{width: '100%'}" pTooltip="Type de l'échange"
                            formControlName="type_echange" dataKey="id_parametre" (onChange)="setSensField()"
                            [readonly]="!isQualificationEditable">
                  <ng-template let-item pTemplate="item">
                      <span
                        [ngStyle]="item.disabled ? {'color': '#ccc', 'cursor': 'default'} : ''">{{item.value.libelle}}</span>
                  </ng-template>
                </p-dropdown>
              </div>
              </div>
              <div *ngIf="hasSens" class="ui-g-3">
                <div class="ui-g-12">
                  <p-radioButton name="type_echange_sens" value="entrant" label="Entrant"
                                 formControlName="type_echange_sens" [disabled]="!isQualificationEditable"></p-radioButton>&nbsp;
                  <p-radioButton name="type_echange_sens" value="sortant" label="Sortant"
                                 formControlName="type_echange_sens" [disabled]="!isQualificationEditable"></p-radioButton>
                </div>
              </div>
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <p-dropdown
                              [options]="motifsEchangeList" [class]="!isEditableAfterDateLimit ? 'disabled' : ''"
                              [placeholder]="'Motif...'" [style]="{width: '100%'}" pTooltip="Motif de l'échange"
                              formControlName="motif_echange" optionLabel="libelle" dataKey="id_parametre"
                              [readonly]="!isEditableAfterDateLimit">
                              <ng-template let-motif pTemplate="item">
                                <span *ngIf="motif.value.id_parametre === idMotifSupprime" style="font-style: italic;"><i class="pi pi-times" style="color: red;float: left;"></i>{{motif.value.libelle}}</span>
                                <span *ngIf="motif.value.id_parametre !== idMotifSupprime">{{motif.value.libelle}}</span>
                              </ng-template>
                            </p-dropdown>
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['type_echange'].invalid && (echangeForm.controls['type_echange'].dirty || echangeForm.controls['type_echange'].touched)">
                  <p-message severity="error" text="Le type de l'échange est invalide"></p-message>
                </div>
              </div>
              <div class="ui-g-3">

                <!--
                    <div class="ui-g-12" *ngIf="echangeForm.controls['motif_echange'].invalid && (echangeForm.controls['motif_echange'].dirty || echangeForm.controls['motif_echange'].touched)">
                        <p-message severity="error" text="Le motif de l'échange est invalide"></p-message>
                    </div>
                    -->
              </div>
              <div class="ui-g-3">
                <div class="ui-g-12 ui-xl-8">
                  <app-select-icon [value]="selectedMarqueurs" [deletable]="'true'" [federation]="echangeForm.value.federation"
                    (iconSelected)="onChangeMarqueur($event)"></app-select-icon>
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['marqueur'].invalid && (echangeForm.controls['marqueur'].dirty || echangeForm.controls['marqueur'].touched)">
                  <p-message severity="error" text="Le marqueur de l'échange est invalide"></p-message>
                </div>
              </div>
            </div>
            <div *ngIf="isDevis" class="ui-grid-row">
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <label>La demande de devis porte sur les services suivants :</label>
                </div>
                <div class="ui-g-12">
                  <p-multiSelect [options]="servicesList" [class]="!isEditableAfterDateLimit ? 'disabled' : ''"
                    dataKey="id_parametre" optionLabel="libelle" [filter]="false" defaultLabel="Aucun"
                    selectedItemsLabel="{0} sélectionnés" formControlName="services"
                    [readonly]="!isEditableAfterDateLimit" showClear="true"></p-multiSelect>
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['services'].invalid && (echangeForm.controls['services'].dirty || echangeForm.controls['services'].touched)">
                  <p-message severity="error" text="Les services sont invalides"></p-message>
                </div>
              </div>
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <label>Organisme financeur</label>
                </div>
                <div class="ui-g-12">
                  <p-dropdown [options]="organismesFinanceursList" (onChange)="getTypesAides()" optionLabel="libelle"
                    [class]="!isEditableAfterDateLimit ? 'disabled' : ''" dataKey="id_parametre" placeholder="--"
                    appendTo="body" formControlName="organisme_financier" [showClear]="isEditableAfterDateLimit"
                    [readonly]="!isEditableAfterDateLimit"></p-dropdown>
                </div>
              </div>
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <label>Type d'aide</label>
                </div>
                <div class="ui-g-12">
                  <p-dropdown [options]="typesAidesList" optionLabel="libelle" appendTo="body" placeholder="--"
                    [class]="!isEditableAfterDateLimit ? 'disabled' : ''" dataKey="id_parametre"
                    formControlName="type_aide" [showClear]="isEditableAfterDateLimit"
                    [readonly]="!isEditableAfterDateLimit"></p-dropdown>
                </div>
              </div>
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <label>Finalité</label>
                </div>
                <div class="ui-g-12">
                  <p-dropdown [options]="finalitesDevisList" [class]="!isEditableAfterDateLimit ? 'disabled' : ''"
                    [placeholder]="'Finalité...'" pTooltip="Finalité du devis" [readonly]="!isEditableAfterDateLimit"
                    formControlName="finalite_echange" optionLabel="libelle" dataKey="id_parametre"
                    [showClear]="isEditableAfterDateLimit"></p-dropdown>
                </div>
              </div>
            </div>
            <div *ngIf="!isDevis" class="ui-grid-row">
              <div class="ui-g-12">
                <div class="ui-g-12">
                  <input pInputText type="text" formControlName="objet_echange" placeholder="Sujet de l'échange"
                    [readonly]="!isQualificationEditable" />
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['objet_echange'].invalid && (echangeForm.controls['objet_echange'].dirty || echangeForm.controls['objet_echange'].touched)">
                  <p-message severity="error" text="Le sujet est invalide"></p-message>
                </div>
              </div>
            </div>
            <div *ngIf="isRemonteeInformations" class="ui-grid-row">
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <label>Risques salarié</label>
                </div>
                <div class="ui-g-12">
                  <p-multiSelect [options]="risquesSalarieList" [class]="!isEditableAfterDateLimit ? 'disabled' : ''"
                    [readonly]="!isEditableAfterDateLimit" dataKey="id_parametre" optionLabel="libelle" [filter]="false"
                    defaultLabel="Aucun" selectedItemsLabel="{0} sélectionnés" formControlName="risques_salarie">
                  </p-multiSelect>
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['risques_salarie'].invalid && (echangeForm.controls['risques_salarie'].dirty || echangeForm.controls['risques_salarie'].touched)">
                  <p-message severity="error" text="Les risques salarié sont invalides"></p-message>
                </div>
              </div>
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <label>Risques client</label>
                </div>
                <div class="ui-g-12">
                  <p-multiSelect [options]="risquesClientList" [class]="!isEditableAfterDateLimit ? 'disabled' : ''"
                    dataKey="id_parametre" optionLabel="libelle" [filter]="false" defaultLabel="Aucun"
                    selectedItemsLabel="{0} sélectionnés" formControlName="risques_client"
                    [readonly]="!isEditableAfterDateLimit"></p-multiSelect>
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['risques_client'].invalid && (echangeForm.controls['risques_client'].dirty || echangeForm.controls['risques_client'].touched)">
                  <p-message severity="error" text="Les risques client sont invalides"></p-message>
                </div>
              </div>
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <label>Gravité</label>
                </div>
                <div class="ui-g-12">
                  <p-dropdown [options]="gravitesList" optionLabel="libelle" appendTo="body" placeholder="--"
                    [class]="!isEditableAfterDateLimit ? 'disabled' : ''" dataKey="id_parametre"
                    formControlName="gravite" [showClear]="isEditableAfterDateLimit"
                    [readonly]="!isEditableAfterDateLimit"></p-dropdown>
                </div>
              </div>
              <div class="ui-g-3">
                <div class="ui-g-12">
                  <label>Fréquence</label>
                </div>
                <div class="ui-g-12">
                  <p-dropdown [options]="frequencesList" optionLabel="libelle" appendTo="body" placeholder="--"
                    [class]="!isEditableAfterDateLimit ? 'disabled' : ''" dataKey="id_parametre"
                    formControlName="frequence" [showClear]="isEditableAfterDateLimit"
                    [readonly]="!isEditableAfterDateLimit"></p-dropdown>
                </div>
              </div>
            </div>
            <div class="ui-grid-row">
              <div class="ui-g-12">
                <div class="ui-g-12">
                  <textarea pInputTextarea [rows]="descriptionSize" [placeholder]="placeholderDescription"
                    [readonly]="formMode === 'edit' && echangeForm.controls['statut_echange'].value.code_parametre !== 'B'"
                    formControlName="description" (focus)="onDescriptionFocused()"></textarea>
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['description'].invalid && (echangeForm.controls['description'].dirty || echangeForm.controls['description'].touched)">
                  <p-message severity="error" text="Une description pour l'échange est obligatoire"></p-message>
                </div>
              </div>
            </div>
            <div
              *ngIf="!isDevis && !isClosed && canEditEchange() && formMode !== 'create' && echangeForm.controls['statut_echange'].value.code_parametre !== 'B'"
              class="ui-grid-row">
              <div class="ui-g-12">
                <div class="ui-g-12">
                  <textarea pInputTextarea [rows]="5" placeholder="Complément descriptif..."
                    formControlName="description_complement" [readonly]="isClosed || !canEditEchange()"></textarea>
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['description_complement'].invalid && (echangeForm.controls['description_complement'].dirty || echangeForm.controls['description_complement'].touched)">
                  <p-message severity="error" text="Le complément descriptif est invalide"></p-message>
                </div>
              </div>
            </div>
            <div *ngIf="isDevis && echangeForm.controls['statut_echange'].value.code_parametre !== 'B'"
              class="ui-grid-row">
              <div class="ui-g-12">
                <div class="ui-g-12">
                  <textarea pInputTextarea [rows]="5" placeholder="Notes de traitements..."
                    formControlName="notes_traitement" [readonly]="isClosed || !canEditEchange()"></textarea>
                </div>
                <div class="ui-g-12"
                  *ngIf="echangeForm.controls['notes_traitement'].invalid && (echangeForm.controls['notes_traitement'].dirty || echangeForm.controls['notes_traitement'].touched)">
                  <p-message severity="error" text="Les notes de traitement sont invalides"></p-message>
                </div>
              </div>
            </div>
            <div *ngIf="echangeForm.value.utilisateur_reouverture || echangeForm.value.date_reouverture"
              class="ui-grid-row">
              <div class="ui-g-3">
                <label>Réouverture</label>
              </div>
              <div *ngIf="echangeForm.value.utilisateur_reouverture" class="ui-g-3">
                <div class="ui-g-12">
                  <input type="text" pInputText pTooltip="Utilisateur réouverture"
                    [value]="echangeForm.value.utilisateur_reouverture.libelle" readonly="true" />
                </div>
              </div>
              <div *ngIf="echangeForm.value.date_reouverture" class="ui-g-3">
                <div class="ui-g-12">
                  <input type="text" pInputText pTooltip="Date réouverture"
                    [value]="echangeForm.value.date_reouverture | date: datePipeFormat" readonly="true" />
                </div>
              </div>
            </div>

          </p-accordionTab>
        </div>
        <div *ngIf="canReopen" class="ui-grid-row panel-footer">
          <div class="ui-grid-col-8"></div>
          <div class="ui-grid-col-2" style="margin-right: 10px;">
            <button *ngIf="formMode === 'create'" pButton type="button" class="grey-button" label="Annuler"
              icon="zmdi zmdi-format-clear" (click)="cancel()"></button>
            <div *ngIf="formMode !== 'create'">
              <p-slideMenu #menuExport [model]="exportItems" [popup]="true" [viewportHeight]="120" backLabel="Retour">
              </p-slideMenu>
              <button #btnExport type="button" pButton icon="pi pi-file" class="grey-button" label="Exporter"
                (click)="menuExport.toggle($event)"></button>
            </div>
          </div>
          <div
            *ngIf="echangeForm.controls['statut_echange'].value && echangeForm.controls['statut_echange'].value.code_parametre == 'B'"
            class="ui-grid-col-3" style="margin-right: 10px;">
            <button *ngIf="!submittingEchange" pButton type="submit" class="green-button"
              label="Enregistrer comme brouillon" icon="pi pi-save"
              [disabled]="!canSaveEchange || echangeHasActions"></button>
            <button *ngIf="submittingEchange" pButton type="submit" class="green-button"
              label="Enregistrer comme brouillon" icon="pi pi-spin pi-spinner" iconPos="right"
              [disabled]="!canSaveEchange || echangeHasActions"></button>
          </div>
          <div
            *ngIf="echangeForm.controls['statut_echange'].value && echangeForm.controls['statut_echange'].value.code_parametre == 'B'"
            class="ui-grid-col-2" style="margin-right: 10px;">
            <button *ngIf="!submittingEchange" pButton type="button" class="green-button" label="Valider"
              icon="pi pi-save" (click)="submitAndValid()" [disabled]="!canValidEchange"></button>
            <button *ngIf="submittingEchange" pButton type="button" class="green-button" label="Valider"
              icon="pi pi-spin pi-spinner" (click)="submitAndValid()" iconPos="right"
              [disabled]="!canValidEchange"></button>
          </div>
          <div
            *ngIf="echangeForm.controls['statut_echange'].value && echangeForm.controls['statut_echange'].value.code_parametre == 'B'"
            class="ui-grid-col-3">
            <button *ngIf="!submittingEchange" pButton type="button" class="green-button" label="Valider et fermer l’onglet"
              icon="pi pi-save" (click)="submitAndValid(true)" [disabled]="!canValidEchange"></button>
            <button *ngIf="submittingEchange" pButton type="button" class="green-button" label="Valider et fermer l’onglet"
              icon="pi pi-spin pi-spinner" (click)="submitAndValid(true)" iconPos="right"
              [disabled]="!canValidEchange"></button>
          </div>
          <div
            *ngIf="echangeForm.controls['statut_echange'].value && echangeForm.controls['statut_echange'].value.code_parametre !== 'B'"
            class="ui-grid-col-2">
            <button *ngIf="!submittingEchange" pButton type="button" class="green-button" label="Enregistrer"
              icon="pi pi-save" (click)="submitAndValid()" [disabled]="!canSaveEchange"></button>
            <button *ngIf="submittingEchange" pButton type="button" class="green-button" label="Enregistrer"
              icon="pi pi-spin pi-spinner" (click)="submitAndValid()" iconPos="right"
              [disabled]="!canSaveEchange"></button>
          </div>
        </div>
        <div *ngIf="!currentUtilisateur.isPersonia" formArrayName="actions"
          class="ui-grid ui-grid-responsive ui-grid-pad ui-fluid" style="margin: 10px 0px">
          <p-accordionTab class="accordionTab-panel-header-category" [selected]="true" id="panelActions" #panelActions>
            <p-header>
              <i class="pi pi-calendar" style="font-size: 17px;"></i>
              Actions
            </p-header>

            <div
              *ngIf="(formMode == 'create' && echangeHasActions) || (formMode == 'edit' && echangeForm.controls['statut_echange'].value.code_parametre == 'B') "
              class="ui-grid-col-12" style="color: #C12300;">
              <label>L'échange doit être validé pour enregistrer une action</label>
            </div>
            <p-contextMenu #cmAction [model]="itemsContextMenu" class="contextMenuListeAction" [target]="tableaction"></p-contextMenu>
            <p-table #tableaction class="table-hover" [value]="echangeForm.get('actions').value" [responsive]="true"  [(contextMenuSelection)]="selectedAction" [contextMenu]="cmAction" [selectionMode]="'single'" (onContextMenuSelect)="updateContextMenuAction($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th>Date/Heure Créa.</th>
                  <th>Rédacteur</th>
                  <th>Objet</th>
                  <th>Echéance</th>
                  <th>Acteur</th>
                  <th>Date/Heure Maj</th>
                  <th>Statut de l'action</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="7" class="table-emptymessage">Aucune action saisie pour cet échange.</td>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-action let-rowIndex="rowIndex">
                <tr class="selectable" (click)="editAction(rowIndex)" [pTooltip]="getTooltipAction(rowIndex)" [pContextMenuRow]="action">
                  <td>{{action.created | date: datePipeFormat }}</td>
                  <td>{{action.redacteur.libelle}}</td>
                  <td>{{action.objet_action}}</td>
                  <td>{{action.echeance | date: datePipeFormatWithoutTime}}</td>
                  <td>
                    <span *ngIf="action.responsable">{{action.responsable.libelle}}</span>
                    <span *ngIf="!action.responsable">Aucun</span>
                  </td>
                  <td>{{action.updated | date: datePipeFormat }}</td>
                  <td>{{action.statut_action.libelle}}</td>
                </tr>
              </ng-template>
            </p-table>
          
          </p-accordionTab>
        </div>
        <div class="ui-grid-row panel-footer ui-rtl" dir="rtl">
          <div *ngIf="!isClosed && canEditEchange() && !currentUtilisateur.isPersonia" class="ui-grid-col-3">
            <button pButton type="button" label="Nouvelle Action" icon="fa fa-plus-circle" class="green-button"
              [disabled]="!canAddAction" (click)="newAction()"></button>
          </div>
          <div *ngIf="!isClosed && canEditEchange() && canAddActionEvaluation" class="ui-grid-col-3"
            style="margin-right: 10px;">
            <button pButton type="button" label="Nouvelle Evaluation" icon="fa fa-plus-circle" class="green-button"
              [disabled]="!canAddActionEvaluation" (click)="newActionEvaluation()"></button>
          </div>
        </div>
        <!-- PIECES JOINTES -->
        <!--
        <p-accordionTab class="accordionTab-panel-header-category" id="panelDocuments" #panelDocuments [selected]="true">
          <p-header class="withActions">
            Documents
          </p-header>

          <div class="ui-grid-row" #documentsTable>
            <p-table class="table-hover full-width"
              [value]="echangeForm.get('documents').value" autoLayout="true">
              <ng-template pTemplate="header">
                <tr>
                  <th>Visionner / Télécharger</th>
                  <th>Titre</th>
                  <th>Date</th>
                  <th>Utilisateur</th>
                  <th>Nom fichier</th>
                  <th *ngIf="canEditEchange"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td *ngIf="canEditEchange" colspan="6" class="table-emptymessage">Aucun document pour cet échange
                  </td>
                  <td *ngIf="!canEditEchange" colspan="5" class="table-emptymessage">Aucun document pour cet échange
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-document let-rowIndex="rowIndex">
                <tr class="selectable">
                  <td style="text-align: center">
                    <i class="pi pi-eye" style="font-size:30px"
                      (click)="updateSrcDocument(document);op.toggle($event)"></i>
                    <i class="pi pi-download" style="font-size:30px" (click)="downloadDocument(document)"></i>
                  </td>
                  <td>{{document.titre}}</td>
                  <td>{{document.date_creation | date: datePipeFormatWithoutTime}}</td>
                  <td>{{document.utilisateur_creation.libelle}}</td>
                  <td>{{document.nom}}</td>
                  <td *ngIf="canEditEchange" style="text-align: center;">
                    <i class="pi pi-times" (click)="$event.stopPropagation();deleteDocument(rowIndex)"></i>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <div class="ui-grid-row panel-footer ui-rtl" dir="rtl">
            <div class="ui-grid-col-3" style="text-align: right;">
              <button pButton type="button" icon="pi pi-plus" class="green-button" label="Ajouter" (click)="newDocument()" [disabled]="!canEditEchange"></button>
            </div>
          </div>
        </p-accordionTab>
        -->
      </p-accordion>
    </div>
  </form>
</p-panel>

<p-dialog [(visible)]="showPopupIndividus" [style]="{maxWidth: '90%', minWidth:'50%'}"
  [contentStyle]="{'max-height':'600px'}">
  <p-header>
    Sélection personne
  </p-header>
  <div class="ui-grid ui-grid-responsive ui-grid-pad ui-fluid" style="margin: 10px 0px">
    <div id="panel-selection-individus" class="ui-grid-row panel-header-category">
      <div class="ui-g-12">
        <h2 *ngIf="showPopupIndividusSujet">
          Résultats pour {{echangeForm.value.personne_sujet_nom}} {{echangeForm.value.personne_sujet_prenom}}
        </h2>
        <h2 *ngIf="showPopupIndividusTiers">
          Résultats pour {{echangeForm.value.personne_tiers_nom}} {{echangeForm.value.personne_tiers_prenom}}
        </h2>
        <h2 *ngIf="showPopupIndividusTiersConcerne">
          Résultats pour {{echangeForm.value.personne_tiers_concerne_nom}}
          {{echangeForm.value.personne_tiers_concerne_prenom}}
        </h2>
      </div>
    </div>
    <p-table class="table-hover" [value]="individusList" autoLayout="true">
      <ng-template pTemplate="header">
        <tr>
          <th>Association</th>
          <th>Nature</th>
          <th>Nom</th>
          <th>Prénom</th>
          <th>Adresse</th>
          <th><i class="pi pi-info-circle"></i></th>
          <th>Actif</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7" class="table-emptymessage">Aucune personne correspondant à vos critères de recherche n'a été
            trouvée.</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-individu>
        <tr [class]="getClassTrIndividu(individu)" (click)="selectIndividu(individu)">
          <td>
            <span *ngIf="individu.association">{{individu.association.libelle}}</span>
            <span *ngIf="!individu.association">Inconnue</span>
          </td>
          <td>
            <span *ngIf="individu.nature">{{individu.nature.libelle}}</span>
            <span *ngIf="!individu.nature">Inconnue</span>
          </td>
          <td>{{individu.nomSecure}}</td>
          <td>{{individu.prenomSecure}}</td>
          <td>{{individu.adresseSecure}}</td>
          <td><i class="pi pi-info-circle" style="color:#17A345" pTooltip={{individu.infoTelephoneEmail}}
              tooltipStyleClass="tooltip-selection-individu"></i></td>
          <td>
            <span *ngIf="individu.actif" style="color:green">Oui</span>
            <span *ngIf="!individu.actif" style="color:red">Non</span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <p-footer>
    <div class="ui-rtl" dir="rtl">
      <button *ngIf="showPopupIndividusSujet" pButton type="button" class="green-button" icon="fa fa-plus-circle"
        label="Nouveau prospect Philia" (click)="newIndividu()"></button>
      <button pButton type="button" class="grey-button" label="Fermer" (click)="hidePopupIndividus()"></button>
    </div>
  </p-footer>
</p-dialog>

<app-action-form-dialog *ngIf="actionToEdit && showPopupAction" [action]="actionToEdit"
  [showPopupAction]="showPopupAction" [formMode]="actionFormMode" [saveNew]="formMode === 'edit'"
  (cancel)="onCloseActionPopup($event)" (actionCreated)="onCreateAction($event)"
  (actionUpdated)="onUpdateAction($event)"></app-action-form-dialog>

<div class="div-action-buttons">
  <div *ngIf="canReopen">
    <button *ngIf="formMode === 'create'" class="grey-button btn btn-rounded" pTooltip="Annuler" tooltipZIndex="10000"
      (click)="cancel()">
      <i class="fas fa-times"></i>
    </button>
    <div *ngIf="formMode !== 'create'">
      <p-slideMenu #menuExport2 [model]="exportItems" [popup]="true" [viewportHeight]="120" backLabel="Retour"
        appendTo="body"></p-slideMenu>
      <button #btnExport2 class="grey-button btn btn-rounded" pTooltip="Exporter" tooltipZIndex="10000"
        (click)="menuExport2.toggle($event)">
        <i class="pi pi-file"></i>
      </button>
    </div>
    <div
      *ngIf="echangeForm.controls['statut_echange'].value && echangeForm.controls['statut_echange'].value.code_parametre == 'B' && canSaveEchange">
      <button type="submit" class="green-button btn btn-rounded" pTooltip="Enregistrer comme brouillon"
        tooltipZIndex="10000" [disabled]="!canSaveEchange || echangeHasActions" (click)="onSubmit()">
        <i class="pi pi-save"></i>
      </button>
    </div>
    <div
      *ngIf="echangeForm.controls['statut_echange'].value && echangeForm.controls['statut_echange'].value.code_parametre == 'B' && canValidEchange">
      <button type="button" class="green-button btn btn-rounded" pTooltip="Valider" tooltipZIndex="10000"
        (click)="submitAndValid()" [disabled]="!canSaveEchange">
        <i class="pi pi-check"></i>
      </button>
    </div>
    <div
      *ngIf="echangeForm.controls['statut_echange'].value && echangeForm.controls['statut_echange'].value.code_parametre !== 'B' && canSaveEchange">
      <button type="button" class="green-button btn btn-rounded" pTooltip="Enregistrer" tooltipZIndex="10000"
        (click)="submitAndValid()" [disabled]="!canSaveEchange">
        <i class="pi pi-save"></i>
      </button>
    </div>
    <div>
      <button type="button" [ngClass]="{'green-button': showHistorique, 'grey-button': !showHistorique}" class="btn btn-rounded"
              pTooltip="Historique échange client" tooltipZIndex="10000" (click)="goHistorique()" [disabled]="!showHistorique">
        <i class="pi pi-clock" style="font-size: 2em"></i>
      </button>
    </div>
  </div>
  <div style="margin-top: 20px;">
    <div *ngIf="!isClosed && canEditEchange() && canAddAction">
      <button type="button" class="btn-rounded" style="background-color: transparent;border: none;cursor: pointer;"
        pTooltip="Nouvelle action" tooltipZIndex="10000" [disabled]="!canAddAction" (click)="newAction()">
        <img src="assets/img/+A.png" alt="Nouvelle action" class="p-justify-center" width="40" height="40">
      </button>
    </div>
    <div *ngIf="!isClosed && canEditEchange() && currentUtilisateur.acces_evaluations && canAddActionEvaluation">
      <button type="button" class="btn-rounded" style="background-color: transparent;border: none;cursor: pointer;"
        pTooltip="Nouvelle Evaluation" tooltipZIndex="10000" [disabled]="!canAddActionEvaluation"
        (click)="newActionEvaluation()">
        <img src="assets/img/+E.png" alt="Nouvelle Evaluation" class="p-justify-center" width="40" height="40">
      </button>
    </div>
  </div>
</div>

<p-overlayPanel (onHide)="onHideOverlayPanel()" styleClass="overlayPanel-imgViewer" #op>
  <pdf-viewer *ngIf="currentImageSrc && currentImageExtension === 'pdf'" [src]="currentImageSrc" [original-size]="false" [render-text]="true" style="display: block;">
  </pdf-viewer>
  <img *ngIf="currentImageSrc && currentImageExtension !== 'pdf'" [src]="currentImageSrc">
</p-overlayPanel>

<p-confirmDialog #cd header="Confirmation" icon="pi pi-exclamation-triangle" baseZIndex="1000000">
  <p-footer>
    <button type="button" class="grey-button" pButton icon="pi pi-times" label="Non" (click)="cd.reject()"></button>
    <button type="button" pButton class="blue-button" icon="pi pi-check" label="Oui" (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>

<p-dialog [(visible)]="displayReactivateEchangeModal" [style]="{maxWidth: '90%', minWidth:'50%'}"
  [contentStyle]="{'max-height':'600px'}">
  <p-header>
    Motif de la demande
  </p-header>
  <div class="ui-grid ui-grid-responsive ui-grid-pad ui-fluid" style="margin: 10px 0px">
    <textarea [rows]="5" pInputTextarea autoResize="autoResize" [(ngModel)]="motifReactivateAction"></textarea>
  </div>
  <p-footer>
    <button type="button" pButton class="green-button" icon="pi pi-check" label="Enregistrer" (click)="saveReactivateEchange()" *ngIf="motifReactivateAction"></button>
    <button type="button" class="grey-button" pButton icon="pi pi-times" label="Fermer" (click)="cancelReactivateEchangeModal()"></button>
  </p-footer>
</p-dialog>