import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { plainToClass } from 'class-transformer';

import { MenuItem, MessageService, SelectItem } from 'primeng/api';
import { DroitModule, PerimetreEnum, Utilisateur } from '../../shared/_models';

import {
  ActionService,
  AuthenticationService,
  EchangeService,
  UtilisateurService
} from '../../shared/_services';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  currentUtilisateur: Utilisateur;
  @Input() appVersion: string;
  @Input() title: string;
  showLeftSidebar: boolean;
  showRightSidebar: boolean;
  showPreferencesAffichage: boolean;
  showPreferencesAstreinte: boolean;
  showPreferencesTableauBord: boolean;
  items: MenuItem[];
  itemsProfile: MenuItem[];
  libelleUtilisateurMultiSite = '';
  preferencesAffichageList: SelectItem[];
  preferencesTableauBordList: SelectItem[];
  logoUrl: string;
  profilAstreinte: string;

  constructor(
    public router: Router,
    private location: Location,
    public authenticationService: AuthenticationService,
    private utilisateurService: UtilisateurService,
    private messageService: MessageService,
    private echangeService: EchangeService,
    private actionService: ActionService
  ) {
  }

  get isOnEchangePage(): boolean {
    return this.router.url.includes('/in/echange');
  }

  get appTitle(): string {
    if (this.router.url.includes('/in/echange') || this.router.url === '/in/recherche' || this.router.url.includes('/in/recherche?')) {
      this.logoUrl = '/in/recherche';
      return 'Tracéo';
    } else if (this.router.url.includes('/in/recherche-action')) {
      this.logoUrl = '/in/recherche-action';
      return 'Actions';
    } else if (this.router.url === '/in/admin') {
      this.logoUrl = '/in/admin';
      return 'Paramètres';
    } else if (this.router.url.includes('/in/admin/utilisateur')) {
      this.logoUrl = '/in/admin/utilisateur';
      return 'Utilisateurs';
    } else if (this.router.url.includes('/in/admin/federation')) {
      this.logoUrl = '/in/admin/federation';
      return 'Fédérations';
    } else if (this.router.url.includes('/in/admin/association')) {
      this.logoUrl = '/in/admin/association';
      return 'Associations';
    } else if (this.router.url.includes('/in/admin/agence')) {
      this.logoUrl = '/in/admin/agence';
      return 'Agences';      
    } else if (this.router.url.includes('/in/admin/parametre')) {
      this.logoUrl = '/in/admin/parametre';
      return 'Paramètres application';
    } else if (this.router.url.includes('/in/admin/personia')) {
      this.logoUrl = '/in/admin/personia';
      return 'Personia';
    } else if (this.router.url === '/in/tableau-bord') {
      this.logoUrl = '/in/tableau-bord';
      return 'Tableau de bord';
    } else if (this.router.url === '/in/recapitulatif-client') {
      this.logoUrl = '/in/recapitulatif-client';
      return 'Récapitulatif client';
    } else if (this.router.url.includes('/in/admin/profil')) {
      this.logoUrl = '/in/admin/profil';
      return 'Profils';
    } else if (this.router.url.includes('/in/admin/groupe')) {
      this.logoUrl = '/in/admin/groupe';
      return 'Groupes';
    } else if (this.router.url.includes('/in/admin/marqueurs')) {
      this.logoUrl = '/in/admin/marqueurs';
      return 'Marqueurs';
    } else if (this.router.url.includes('/in/admin/motifs')) {
      this.logoUrl = '/in/admin/motifs';
      return 'Motifs';
    } else {
      this.logoUrl = '';
      return this.title;
    }
  }

  get titleClass(): string {
    if (this.router.url.includes('/in/echange') || this.router.url === '/in/recherche' || this.router.url === '/in/recherche-action') {
      return 'echangeTitle';
    } else {
      return 'defaultTitle';
    }
  }

  get buttonClass(): string {
    return 'green-button btn btn-round';
  }

  get showBackButton(): boolean {
    if (this.currentUtilisateur && this.router.url !== '/in/select-association' && this.router.url !== '/login' && this.router.url !== '/in/recherche'
      && this.router.url !== '/in/recherche-evaluation' && this.currentUtilisateur) {
      return true;
    }
    return false;
  }

  get showNewEchangeButton(): boolean {
    if (this.currentUtilisateur && this.router.url !== '/in/select-association' && this.router.url !== '/login' && this.router.url !== '/in/select-association'
      && (this.currentUtilisateur.hasFonctionnalite(DroitModule.module_echanges, DroitModule.fonctionnalite_edit_echanges) || this.currentUtilisateur.hasFonctionnalite(DroitModule.module_personia, DroitModule.fonctionnalite_edit_echanges))
    ) {
      return true;
    }
    return false;
  }

  ngOnInit() {
    this.items = [];
    this.showLeftSidebar = false;

    this.authenticationService.currentUtilisateur$.subscribe(utilisateur => {
      if (utilisateur) {
        this.currentUtilisateur = plainToClass(Utilisateur, utilisateur);
        this.initLibelleUtilisateurMultiSite();
        this.initMenu();
        this.initMenuProfile();
        this.getPreferencesAffichageList();
      }
    });

    if (sessionStorage.getItem('switchAstreinte') !== null) {
      this.profilAstreinte = JSON.parse(sessionStorage.getItem('switchAstreinte'));
    }
  }

  logout() {
    this.authenticationService.logout();
  }

  initLibelleUtilisateurMultiSite() {
    if (this.currentUtilisateur.isMultiSite) {
      if (this.currentUtilisateur.type_acces === 'multiAsso') {
        this.libelleUtilisateurMultiSite = `Fédération : ${this.currentUtilisateur.federation.libelle}`;
      } else if (this.authenticationService.currentAssociationForUserMultiSite) {
        this.libelleUtilisateurMultiSite = `Association : ${this.authenticationService.currentAssociationForUserMultiSite.libelle}`;
      } else {
        this.libelleUtilisateurMultiSite = 'Association : Non définie';
      }
    } else if (this.currentUtilisateur.federation) {
      if (this.currentUtilisateur.perimetre === 'National') {
        this.libelleUtilisateurMultiSite = this.currentUtilisateur.federation.libelle;
      } else {
        this.libelleUtilisateurMultiSite = `Fédération : ${this.currentUtilisateur.federation.libelle}`;
      }
    } else if (this.currentUtilisateur.perimetre === 'National') {
      this.libelleUtilisateurMultiSite = 'National';
    }
  }

  initMenu() {
    this.items = [];
    if (this.currentUtilisateur !== undefined) {
      this.items = [
        {
          label: 'Tableau de bord', routerLink: ['/in/tableau-bord'], command: (event: any) => {
            this.toggleShowLeftSideBar();
          }
        },
        {
          label: 'Liste des échanges', routerLink: ['/in/recherche'], command: (event: any) => {
            this.toggleShowLeftSideBar();
          },
          visible: (this.currentUtilisateur.acces_echanges === true || this.currentUtilisateur.acces_personia === true)
        },
        {
          label: 'Liste des actions',
          routerLink: ['/in/recherche-action'],
          visible: (!this.currentUtilisateur.isPersonia),
          command: (event: any) => {
            this.toggleShowLeftSideBar();
          }
        },
        {
          label: 'Récapitulatif client',
          routerLink: ['/in/recapitulatif-client'],
          visible: (!this.currentUtilisateur.isPersonia),
          command: (event: any) => {
            this.toggleShowLeftSideBar();
          }
        },
        {
          label: 'Changer d\'association',
          routerLink: ['/in/select-association'],
          visible: (this.currentUtilisateur.type_acces === 'multiSite' && this.currentUtilisateur.perimetre === 'Association'),
          command: (event: any) => {
            this.toggleShowLeftSideBar();
          }
        },
        {
          label: 'Paramètres', routerLink: ['/in/admin'],
          visible: (this.currentUtilisateur.est_administrateur === 'O' && !this.currentUtilisateur.isPersonia),
          command: (event: any) => {
            this.toggleShowLeftSideBar();
          }
        },
        // {
        //   label: 'Gestion des utilisateurs',
        //   routerLink: ['/in/admin/utilisateur'],
        //   visible: (this.currentUtilisateur.est_administrateur === 'O' && this.currentUtilisateur.perimetre !== 'National'),
        //   command: (event: any) => {this.toggleShowLeftSideBar(); }},
      ];
    }
  }

  initMenuProfile() {
    this.itemsProfile = [];
    if (this.currentUtilisateur !== undefined) {
      this.itemsProfile = [{
        label: 'Informations utilisateur',
        items: [
          {
            label: 'Mon profil', icon: 'fa fa-user-circle', command: (event: any) => {
              this.goToMyProfile();
            }
          },
          {
            label: 'Préférence d’astreinte', icon: 'fa fa-phone', command: (event: any) => {
              this.showPreferenceAstreinteDialog();
            }
          },
          {
            label: 'Préférences d\'affichage', icon: 'fa fa-window-maximize', command: (event: any) => {
              this.showPreferenceAffichageDialog();
            }
          },
          {
            label: 'Préférences tableau de bord', icon: 'fa fa-clipboard-list', command: (event: any) => {
              this.showPreferenceTableauBordDialog();
            }
          },
          {
            label: 'Quitter l\'application',
            icon: 'fa fa-sign-out-alt',
            command: (event: Event) => this.logout()
          }
        ]
      }];
    }
  }

  isVisible() {
    return this.authenticationService.isLoggedIn();
  }

  toggleShowLeftSideBar(): void {
    this.showLeftSidebar = !this.showLeftSidebar;
  }

  goBack() {
    this.location.back();
  }

  goToListe() {
    if (this.isOnEchangePage) {
      this.router.navigate(['/in/recherche']);
    } else {
      this.goBack();
    }
  }

  goToNewEchange() {
    let url = this.router.createUrlTree(['/in/echange/new']);
    window.open(url.toString(), '_blank');
  }

  goToMyProfile() {
    this.router.navigate(['/in/admin/utilisateur/edit/' + this.currentUtilisateur.id_utilisateur]);
  }

  showPreferenceAffichageDialog() {
    this.showPreferencesAffichage = true;
  }

  showPreferenceAstreinteDialog() {
    this.showPreferencesAstreinte = true;
  }

  getPreferencesAffichageList() {
    let preferencesAffichage = [];
    preferencesAffichage.push({ label: 'Tableau de bord', value: 'tableau_bord' });
    preferencesAffichage.push({ label: 'Tracéo', value: 'main_courante' });

    this.preferencesAffichageList = preferencesAffichage;
  }

  showPreferenceTableauBordDialog() {
    this.showPreferencesTableauBord = true;
  }

  onChangePreferenceAffichage() {
    this.saveProfile();
  }

  onChangePreferenceAstreinte() {
    if (sessionStorage.getItem('switchAstreinte') !== null) {
      let currentValue = JSON.parse(sessionStorage.getItem('switchAstreinte'));
      sessionStorage.setItem('switchAstreinte', JSON.stringify(!currentValue));
    }
  }

  saveProfile() {
    this.utilisateurService.updateProfile(this.currentUtilisateur)
      .subscribe(
        (utilisateur: Utilisateur) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Succès',
            detail: 'Votre profil a été mis à jour avec succès.'
          });
          this.showPreferencesTableauBord = false;
        },
        error => {
          console.log(error);
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur',
            detail: 'Erreur dans la mise à jour de votre profil.'
          });
          this.showPreferencesTableauBord = false;
        }
      );
  }

  public logoClick() {
    if (this.router.url.includes('/in/echange') || this.router.url === '/in/recherche' || this.router.url.includes('/in/recherche?')) {
      this.echangeService.initFiltreEchange();
    } else if (this.router.url.includes('/in/recherche-action')) {
      this.actionService.initFiltreAction();
    }

    if (this.router.url.includes('/in/recherche') || this.router.url.includes('/in/recherche-action')
      || this.router.url.includes('/in/recherche-evaluation') || this.router.url.includes('/in/recherche-alerte')) {
      window.location.reload();
    }

    this.router.navigateByUrl(this.logoUrl, { skipLocationChange: true });
  }

  addAllPreferencesTableauBord() {
    let preferences_tableau_bord = [];
    preferences_tableau_bord.push('echanges_urgents_a_traiter');
    preferences_tableau_bord.push('reclamations_ouvertes');
    preferences_tableau_bord.push('evaluations_en_cours');
    preferences_tableau_bord.push('nouvelles_alertes_vigilances');
    preferences_tableau_bord.push('alertes_risque_infectieux');
    preferences_tableau_bord.push('actions_ouvertes');
    preferences_tableau_bord.push('mes_actions');
    preferences_tableau_bord.push('evaluations_non_realisees');
    preferences_tableau_bord.push('mes_evaluations_a_realiser');
    preferences_tableau_bord.push('actions_suivi_ouvertes');
    preferences_tableau_bord.push('mes_actions_suivi');
    preferences_tableau_bord.push('echanges_brouillons');
    preferences_tableau_bord.push('mes_echanges_brouillons');
    preferences_tableau_bord.push('echanges_rem_inf');
    preferences_tableau_bord.push('echanges_ouverts');
    preferences_tableau_bord.push('echanges_astreintes');
    preferences_tableau_bord.push('mes_echanges_urgents_ouverts');
    preferences_tableau_bord.push('mes_echanges');
    preferences_tableau_bord.push('echanges_devis_ouverts');
    preferences_tableau_bord.push('echanges_prospects_ouverts');


    this.currentUtilisateur.preferences_tableau_bord = preferences_tableau_bord;
  }

  removeAllPreferencesTableauBord() {
    this.currentUtilisateur.preferences_tableau_bord = [];
  }

  showCheckbox(tuile: string): boolean {
    if ((tuile === 'echanges_urgents_a_traiter' || tuile === 'reclamations_ouvertes' || tuile === 'echanges_brouillons'
      || tuile === 'mes_echanges_brouillons' || tuile === 'echanges_rem_inf' || tuile === 'echanges_astreintes')
      && !this.currentUtilisateur.acces_echanges) {
      return false;
    } else if ((tuile === 'evaluations_en_cours' || tuile === 'evaluations_non_realisees' || tuile === 'mes_evaluations_a_realiser')
      && !this.currentUtilisateur.acces_evaluations) {
      return false;
    } else if ((tuile === 'nouvelles_alertes_vigilances' || tuile === 'alertes_risque_infectieux'
      || tuile === 'actions_suivi_ouvertes' || tuile === 'mes_actions_suivi')
      && !this.currentUtilisateur.acces_alertes) {
      return false;
    } else if ((tuile === 'mes_actions' || tuile === 'actions_ouvertes')
      && !this.currentUtilisateur.acces_alertes
      && !this.currentUtilisateur.acces_echanges) {
      return false;
    } else if ((tuile === 'echanges_ouverts')
      && !this.currentUtilisateur.hasFonctionnalite(DroitModule.module_personia, DroitModule.fonctionnalite_edit_echanges)
      && !this.currentUtilisateur.acces_echanges) {
      return false;
    } else if ((tuile === 'mes_echanges_brouillons' || tuile === 'mes_echanges' || tuile === 'mes_echanges_urgents_ouverts')
      && !this.currentUtilisateur.hasFonctionnalite(DroitModule.module_echanges, DroitModule.fonctionnalite_edit_echanges)
      && !this.currentUtilisateur.hasFonctionnalite(DroitModule.module_personia, DroitModule.fonctionnalite_edit_echanges)) {
      return false;
    }

    if (tuile === 'mes_evaluations_a_realiser'
      && !this.currentUtilisateur.hasFonctionnalite(DroitModule.module_evaluations, DroitModule.fonctionnalite_creation_evaluations)
      && !this.currentUtilisateur.hasFonctionnalite(DroitModule.module_evaluations, DroitModule.fonctionnalite_edit_evaluations)) {
      return false;
    } else if (tuile === 'mes_actions_suivi'
      && !this.currentUtilisateur.hasFonctionnalite(DroitModule.module_vigilance, DroitModule.fonctionnalite_edit_alertes)
      && !this.currentUtilisateur.hasFonctionnalite(DroitModule.module_vigilance, DroitModule.fonctionnalite_edit_actions_alertes)) {
      return false;
    } else if (tuile === 'mes_actions' && this.currentUtilisateur.isPersonia) {
      return false;
    } else if (tuile === 'actions_ouvertes' && this.currentUtilisateur.isPersonia) {
      return false;
    }

    return true;
  }

  private openBrowserTab(url: string) {
    window.open(url);
  }

}
