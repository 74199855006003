import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import {plainToClass} from 'class-transformer';

import {SelectItem} from 'primeng/api';

import { Profil } from '../_models';

import { environment } from '../../../environments/environment';
import { FiltreProfil } from '../utils/filtre-profil';

@Injectable({ providedIn: 'root' })
export class ProfilService {

  filtreProfil = new FiltreProfil();

  constructor(private http: HttpClient) { }

  /** GET profils */
  getAll(): Observable<Profil[]> {
    return this.http.get<Profil[]>(`${environment.apiHost}/${environment.apiNamespace}/profils`).pipe(
      tap(_ => console.log(`fetched profils`)),
      catchError(this.handleError)
    );
  }

  /** GET profils */
  getProfilsByParams(paramsValue: any = null, restricted_datas = false, profil_droits= false): Observable<Profil[]> {
    let params = new HttpParams();
    if (paramsValue) {
      for (let paramKey in paramsValue) {
        if (paramsValue.hasOwnProperty(paramKey)) {
          params = params.append(paramKey, paramsValue[paramKey]);
        }
      }
    }

    if (restricted_datas) {
      params = params.append('restricted_datas', 'true');
    }
    if (profil_droits) {
      params = params.append('profil_droits', 'true');
    }
    return this.http.get<Profil[]>(`${environment.apiHost}/${environment.apiNamespace}/profils`, { params: params });
  }

  /** GET profils/count */
  getNbProfilsByParams(paramsValue: FiltreProfil): Observable<any> {
    let paramsValueSerialized = paramsValue.serialize();
    let params = new HttpParams();
    for (let paramKey in paramsValueSerialized) {
      if (paramsValueSerialized.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValueSerialized[paramKey]);
      }
    }
    return this.http.get<any>(`${environment.apiHost}/${environment.apiNamespace}/profils/count`, { params: params });
  }

   /** GET profils/:id */
   getProfil(id_profil: number): Observable<Profil> {
    return this.http.get<Profil>(`${environment.apiHost}/${environment.apiNamespace}/profils/${id_profil}`);
  }

  /** POST profils */
  createProfil(profil: Profil): Observable<Profil> {
    return this.http.post<any>(`${environment.apiHost}/${environment.apiNamespace}/profils`,
    plainToClass(Profil, profil).serialize());
  }

  /** PUT profils */
  updateProfil(profil: Profil): Observable<Profil> {
    let id_profil = profil.id_profil;
    return this.http.put<any>(`${environment.apiHost}/${environment.apiNamespace}/profils/${id_profil}`,
    plainToClass(Profil, profil).serialize());
  }

  /** DELETE profils */
  removeProfil(id_profil: number): Observable<Profil> {
    return this.http.delete<any>(`${environment.apiHost}/${environment.apiNamespace}/profils/${id_profil}`);
  }

  initFiltreProfil() {
    this.filtreProfil = new FiltreProfil();
  }

  /* handleError */
  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(error);
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a utilisateur-facing error message
    return throwError(
      error);
  }

}
