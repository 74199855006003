import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {SharedModule} from '../shared/shared.module';
import {AdminHomeComponent} from './admin-home/admin-home.component';

import {AdminRoutingModule} from './admin-routing.module';
import { AdminMotifsComponent } from './admin-motifs/admin-motifs.component';
import { AdminMarqueursComponent } from './admin-marqueurs/admin-marqueurs.component';

@NgModule({
  declarations: [
    AdminHomeComponent,
    AdminMotifsComponent,
    AdminMarqueursComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AdminRoutingModule,
  ]
})
export class AdminModule {
}
