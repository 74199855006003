import {FormControl, FormGroup, Validators} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {Globals} from '../utils/globals';
import {Alerte} from './alerte';
import {DroitModule} from './droit-module';
import {Echange} from './echange';
import {EvaluationEntete} from './evaluation-entete';
import {Groupe} from './groupe';
import {Parametre} from './parametre';
import {Utilisateur} from './utilisateur';

export class Action {
  static objetsListeEvaluation = ['Evaluation', 'Réévaluation'];
  static objetsListeAlerteAuto = ['Justification', 'Qualification', 'Suivi'];

  id_action: number;

  @Type(() => Echange)
  echange: Echange;
  id_echange: number;

  @Type(() => Date)
  created: Date = new Date();

  @Type(() => Date)
  updated: Date = new Date();

  @Type(() => Date)
  closed: Date;

  @Type(() => Utilisateur)
  redacteur: Utilisateur;

  @Type(() => Utilisateur)
  utilisateur_fermeture: Utilisateur;

  objet_action: string;

  @Type(() => Utilisateur)
  responsable: Utilisateur;

  description_action: string;

  @Type(() => Date)
  echeance: Date;

  @Type(() => Parametre)
  statut_action: Parametre;

  @Type(() => EvaluationEntete)
  evaluation_entete: EvaluationEntete;

  @Type(() => Parametre)
  type_public: Parametre;

  is_evaluation = false;

  @Type(() => Alerte)
  alerte: Alerte;
  id_alerte: number;

  @Type(() => Groupe)
  groupes: Groupe[] = [];

  statut_echeance: string;

  get nom_individu() {
    if (this.echange && this.echange.personne_sujet_nom) {
      return this.echange.personne_sujet_nom;
    } else if (this.alerte && this.alerte.appreciation && this.alerte.appreciation.client_nom) {
      return this.alerte.appreciation.client_nom;
    }
    return '';
  }

  get prenom_individu() {
    if (this.echange && this.echange.personne_sujet_prenom) {
      return this.echange.personne_sujet_prenom;
    } else if (this.alerte && this.alerte.appreciation && this.alerte.appreciation.client_prenom) {
      return this.alerte.appreciation.client_prenom;
    }
    return '';
  }

  get federation() {
    if (this.echange && this.echange.federation) {
      return this.echange.federation;
    } else if (this.alerte && this.alerte.federation) {
      return this.alerte.federation;
    }
    return '';
  }

  get association() {
    if (this.echange && this.echange.association) {
      return this.echange.association;
    } else if (this.alerte && this.alerte.association) {
      return this.alerte.association;
    }
    return '';
  }

  static getForm(action = null): FormGroup {
    if (!action) {
      action = new Action();
    }

    let actionForm = new FormGroup({
      'id_action': new FormControl(action.id_action),
      'created': new FormControl(action.created),
      'redacteur': new FormControl(action.redacteur),
      'objet_action': new FormControl(action.objet_action, [Validators.required, Validators.pattern(Globals.containsLettersPattern)]),
      'echeance': new FormControl(action.echeance),
      'responsable': new FormControl(action.responsable),
      'description_action': new FormControl(action.description_action, Validators.required),
      'updated': new FormControl(action.updated),
      'statut_action': new FormControl(action.statut_action, Validators.required),
      'send_mail_responsable': new FormControl(false),
      'save_before_close': new FormControl(false),
      'type_public': new FormControl(action.type_public),
      'is_evaluation': new FormControl(false),
      'evaluation_entete': new FormControl(null),
      'groupes': new FormControl(action.groupes)
    });

    if (action.isEvaluation()) { // Action evaluation
      actionForm.get('echeance').setValidators(Validators.required);
      actionForm.get('type_public').setValidators(Validators.required);
      actionForm.get('is_evaluation').setValue(true);

      if (action.evaluation_entete && action.evaluation_entete.statut_evaluation) {
        let evaluationActionForm = new FormGroup({
          'statut_evaluation': new FormControl(action.evaluation_entete.statut_evaluation)
        });
        actionForm.setControl('evaluation_entete', evaluationActionForm);
      }
    }

    return actionForm;
  }

  isClosed(currentUtilisateur: Utilisateur) {
    if (!this.statut_action) {
      return false;
    }

    // Action close ?
    let isClosed = this.statut_action.code_parametre === 'F';

    // Utilisateur en lecture => Echange clos (pas de modif)
    if (!isClosed
      && ((this.echange && (currentUtilisateur.hasFonctionnalite(DroitModule.module_echanges, DroitModule.fonctionnalite_edit_echanges) || currentUtilisateur.hasFonctionnalite(DroitModule.module_echanges, DroitModule.fonctionnalite_edit_actions)))
        || (this.evaluation_entete && (currentUtilisateur.hasFonctionnalite(DroitModule.module_evaluations, DroitModule.fonctionnalite_creation_evaluations) || currentUtilisateur.hasFonctionnalite(DroitModule.module_evaluations, DroitModule.fonctionnalite_edit_evaluations)))
        || (this.alerte && (currentUtilisateur.hasFonctionnalite(DroitModule.module_vigilance, DroitModule.fonctionnalite_edit_alertes) || currentUtilisateur.hasFonctionnalite(DroitModule.module_vigilance, DroitModule.fonctionnalite_edit_actions_alertes)))
      )) {
      isClosed = false;
    } else {
      isClosed = true;
    }

    return isClosed;
  }

  isEvaluation() {
    return !!(this.is_evaluation || this.evaluation_entete);
  }

  isAlerte() {
    return !!this.alerte;
  }

  isReclamation() {
    return this.echange && this.echange.categorie_echange && this.echange.categorie_echange.code_parametre === 'REC';
  }

  serialize() {
    let serializeObject = classToPlain(this);
    delete serializeObject['id_action'];
    delete serializeObject['created'];
    delete serializeObject['updated'];
    delete serializeObject['id_echange'];
    delete serializeObject['id_alerte'];

    if (serializeObject.hasOwnProperty('send_mail_responsable')) {
      delete serializeObject['send_mail_responsable'];
    }

    if (serializeObject.hasOwnProperty('save_before_close')) {
      delete serializeObject['save_before_close'];
    }

    if (this.echange !== null && this.echange !== undefined) {
      serializeObject['echange'] = this.echange.id_echange;
    } else if (this.id_echange !== null && this.id_echange !== undefined) {
      serializeObject['echange'] = this.id_echange;
    }

    if (this.redacteur !== null && this.redacteur !== undefined) {
      serializeObject['redacteur'] = this.redacteur.id_utilisateur;
    }

    if (this.responsable !== null && this.responsable !== undefined) {
      serializeObject['responsable'] = this.responsable.id_utilisateur;
    }

    if (this.statut_action !== null && this.statut_action !== undefined) {
      serializeObject['statut_action'] = this.statut_action.id_parametre;
    }

    if (this.evaluation_entete !== null && this.evaluation_entete !== undefined) {
      serializeObject['evaluation_entete'] = this.evaluation_entete.id_evaluation_entete;
    }

    if (this.type_public !== null && this.type_public !== undefined) {
      serializeObject['type_public'] = this.type_public.id_parametre;
    }

    if (this.evaluation_entete !== null && this.evaluation_entete !== undefined) {
      serializeObject['statut_evaluation'] = this.evaluation_entete.statut_evaluation.id_parametre;
    }

    if (this.alerte !== null && this.alerte !== undefined) {
      serializeObject['alerte'] = this.alerte.id_alerte;
    } else if (this.id_alerte !== null && this.id_alerte !== undefined) {
      serializeObject['alerte'] = this.id_alerte;
    }

    if (this.groupes !== null && this.groupes !== undefined) {
      serializeObject['groupes'] = [];
      this.groupes.forEach(function (groupe) {
        serializeObject['groupes'].push(groupe.id_groupe);
      });
    }

    serializeObject['is_evaluation'] = this.isEvaluation();

    // Conversion des dates en FR
    for (let propertyName in serializeObject) { // On parcourt les proprietes de l'objet
      if (serializeObject[propertyName] && serializeObject[propertyName] instanceof Date) { // Si c'est une date non null
        serializeObject[propertyName] = Globals.getDateWithTimezone(serializeObject[propertyName]);
      }
    }

    return serializeObject;
  }

  serializeForExport() {
    let serializeObject = {
      'Date création': this.created ? Globals.formatDate(this.created, true) : '',
      'Statut': this.statut_action ? this.statut_action.libelle : '',
      'Rédacteur': this.redacteur ? this.redacteur.libelle : '',
      'Nom Individu': this.nom_individu,
      'Prénom Individu': this.prenom_individu,
      'Catégorie échange': this.echange && this.echange.categorie_echange ? this.echange.categorie_echange.libelle : '',
      'Motif échange': this.echange && this.echange.motif_echange ? this.echange.motif_echange.libelle : '',
      'Objet': this.objet_action ? this.objet_action : '',
      'Echéance': this.echeance ? Globals.formatDate(this.echeance) : '',
      'Acteur': this.responsable ? this.responsable.libelle : '',
      'Association': this.association,
      'Fédération': this.federation,
      'Date MAJ': this.updated ? Globals.formatDate(this.updated, true) : '',
      'Description': this.description_action ? this.description_action : '',
      'Date clotûre': this.closed ? Globals.formatDate(this.closed, true) : '',
      'Utilisateur clotûre': this.utilisateur_fermeture ? this.utilisateur_fermeture.libelle : '',
    };

    return serializeObject;
  }
}
