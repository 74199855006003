import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/_guards';

import { TableauBordComponent } from './tableau-bord/tableau-bord.component';
import { RecapitulatifClientComponent } from './recapitulatif-client/recapitulatif-client.component';

const tableauBordRoutes: Routes = [
  {
    path: 'in/tableau-bord',
    canActivate: [AuthGuard],
    component: TableauBordComponent
  },
  {
    path: 'in/recapitulatif-client/:idPhilia',
    canActivate: [AuthGuard],
    component: RecapitulatifClientComponent
  },
  {
    path: 'in/recapitulatif-client',
    canActivate: [AuthGuard],
    component: RecapitulatifClientComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(tableauBordRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class TableauBordRoutingModule { }
