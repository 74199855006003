import {Type} from 'class-transformer';
import {Association} from './association';
import {Commune} from './commune';
import {Federation} from './federation';
import {Parametre} from './parametre';

export class Individu {
  id_philia: string;

  @Type(() => Parametre)
  civilite: Parametre;

  nom: string;
  prenom: string;
  societe: string;
  tel_domicile: string;
  tel_travail: string;
  tel_mobile: string;
  mail: string;
  adr_num: string;
  adr_complement: string;
  adr_complement1: string;
  adr_complement2: string;
  adr_voie: string;
  adr_code_postale: string;
  adr_ville: string;
  adr_lieudit: string;

  @Type(() => Commune)
  adr_commune: Commune;

  @Type(() => Parametre)
  nature: Parametre;

  @Type(() => Federation)
  federation: Federation;

  @Type(() => Association)
  association: Association;

  actif: boolean;

  get civilite_nom_prenom() {
    let civilite_nom_prenom = '';

    if (this.civilite) {
      civilite_nom_prenom = this.civilite.libelle;
    }

    if (civilite_nom_prenom !== '') {
      civilite_nom_prenom += ' ';
    }

    if (this.nom) {
      if (civilite_nom_prenom !== '') {
        civilite_nom_prenom += ' ';
      }
      civilite_nom_prenom += this.nom;
    }

    if (this.prenom) {
      if (civilite_nom_prenom !== '') {
        civilite_nom_prenom += ' ';
      }
      civilite_nom_prenom += this.prenom;
    }

    return civilite_nom_prenom;
  }

  get infoTelephoneEmail() {
    let lib = '';

    lib += 'Tél. domicile : ';
    lib += (this.tel_domicile === undefined ? 'Inconnu' : this.tel_domicile) + '\n';

    lib += 'Tél. prof : ';
    lib += (this.tel_travail === undefined ? 'Inconnu' : this.tel_travail) + '\n';

    lib += 'Tél. portable : ';
    lib += (this.tel_mobile === undefined ? 'Inconnu' : this.tel_mobile) + '\n';

    lib += 'E-mail : ';
    lib += (this.mail === undefined ? 'Inconnu' : this.mail) + '\n';

    return lib;
  }

  get nomSecure() {
    if (this.nom !== null && this.nom !== undefined && this.nom !== '') {
      return this.nom.substring(0, 32);
    }
    return '';
  }

  get prenomSecure() {
    if (this.prenom !== null && this.prenom !== undefined && this.prenom !== '') {
      return this.prenom.substring(0, 32);
    }
    return '';
  }

  get adresseSecure() {
    let adr = '';

    if (this.adr_num !== null && this.adr_num !== undefined && this.adr_num !== '') {
      adr += this.adr_num + ' ';
    }

    if (this.adr_complement !== null && this.adr_complement !== undefined && this.adr_complement !== '') {
      adr += this.adr_complement + ' ';
    }

    if (this.adr_voie !== null && this.adr_voie !== undefined && this.adr_voie !== '') {
      adr += this.adr_voie + ' ';
    }

    if (this.adr_code_postale !== null && this.adr_code_postale !== undefined && this.adr_code_postale !== '') {
      adr += this.adr_code_postale + ' ';
    }

    if (this.adr_ville !== null && this.adr_ville !== undefined && this.adr_ville !== '') {
      adr += this.adr_ville + ' ';
    }

    return adr.substring(0, 44);
  }

  isSalarie() {
    if (this.nature && this.nature.code_parametre === 'INT') {
      return true;
    }
    return false;
  }

  setValuesFromEchangeForm(echangeForm) {
    if (echangeForm.personne_sujet_civilite !== null && echangeForm.personne_sujet_civilite !== undefined) {
      this.civilite = echangeForm.personne_sujet_civilite.id_philia;
    }

    this.nom = echangeForm.personne_sujet_nom;
    this.prenom = echangeForm.personne_sujet_prenom;

    if (echangeForm.federation !== null && echangeForm.federation !== undefined) {
      this.federation = echangeForm.federation;
    }

    if (echangeForm.association !== null && echangeForm.association !== undefined) {
      this.association = echangeForm.association;
    }

    this.adr_num = echangeForm.personne_sujet_adr_num;
    this.adr_complement = echangeForm.personne_sujet_adr_complement;
    this.adr_voie = echangeForm.personne_sujet_adr_voie;
    this.adr_lieudit = echangeForm.personne_sujet_adr_lieudit;

    if (echangeForm.personne_sujet_adr_commune !== null && echangeForm.personne_sujet_adr_commune !== undefined) {
      this.adr_commune = echangeForm.personne_sujet_adr_commune;
      this.adr_ville = echangeForm.personne_sujet_adr_commune.nom_commune;
      this.adr_code_postale = echangeForm.personne_sujet_adr_commune.code_postal;
    }

    this.adr_complement1 = echangeForm.personne_sujet_adr_complement1;
    this.adr_complement2 = echangeForm.personne_sujet_adr_complement2;
    this.tel_domicile = echangeForm.personne_sujet_tel_domicile;
    this.tel_mobile = echangeForm.personne_sujet_tel_mobile;
    this.tel_travail = echangeForm.personne_sujet_tel_travail;
    this.mail = echangeForm.personne_sujet_mail;
  }

  serialize() {
    let serializeObject = {
      civilite: this.civilite,
      nom: this.nom,
      prenom: this.prenom,
      type: 1, // Type d'individu : 1 = prospect, 2 = client, 3 = intervenant
      federation: this.federation.id_philia,
      association: this.association.id_philia,
      adresseNum: this.adr_num,
      adresseComplement: this.adr_complement,
      adresseVoie: this.adr_voie,
      adresseLieuDit: this.adr_lieudit,
      adresseCodePostal: this.adr_code_postale,
      adresseCommune: this.adr_ville,
      adresseComplement1: this.adr_complement1,
      adresseComplement2: this.adr_complement2,
      telDomicile: this.tel_domicile,
      telMobile: this.tel_mobile,
      telTravail: this.tel_travail,
      mail: this.mail,
    };

    return serializeObject;
  }
}
