import {FormControl, FormGroup} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {Globals} from '../utils/globals';
import {Echange} from './echange';
import {Utilisateur} from './utilisateur';

export class DocumentEchange {

  id_document_echange: number;

  titre: string;

  nom: string;

  path: string;

  @Type(() => Date)
  date_creation: Date;

  @Type(() => Utilisateur)
  utilisateur_creation: Utilisateur;

  @Type(() => Date)
  date_suppression: Date;

  @Type(() => Utilisateur)
  utilisateur_suppression: Utilisateur;

  @Type(() => Echange)
  echange: Echange;

  file: any;

  deleted: boolean;

  static getForm(document_echange = null): FormGroup {
    if (!document) {
      document_echange = new DocumentEchange();
    }

    return new FormGroup({
      'id_document_echange': new FormControl(document_echange.id_document_echange),
      'titre': new FormControl(document_echange.titre),
      'nom': new FormControl(document_echange.nom),
      'path': new FormControl(document_echange.path),
      'date_creation': new FormControl(document_echange.date_creation),
      'utilisateur_creation': new FormControl(document_echange.utilisateur_creation),
      'date_suppression': new FormControl(document_echange.date_suppression),
      'utilisateur_suppression': new FormControl(document_echange.utilisateur_suppression),
      'file': new FormControl(null),
      'deleted': new FormControl(document_echange.deleted),
    });
  }

  serialize() {
    let serializeObject = classToPlain(this);

    if (this.utilisateur_creation !== null && this.utilisateur_creation !== undefined) {
      serializeObject['utilisateur_creation'] = this.utilisateur_creation.id_utilisateur;
    }

    if (this.utilisateur_suppression !== null && this.utilisateur_suppression !== undefined) {
      serializeObject['utilisateur_suppression'] = this.utilisateur_suppression.id_utilisateur;
    }

    // Conversion des dates en FR
    for (let propertyName in serializeObject) { // On parcourt les proprietes de l'objet
      if (serializeObject[propertyName] && serializeObject[propertyName] instanceof Date) { // Si c'est une date non null
        serializeObject[propertyName] = Globals.getDateWithTimezone(serializeObject[propertyName]);
      }
    }

    return serializeObject;
  }
}
