import { Component, OnInit } from '@angular/core';
import { Federation, Parametre, Utilisateur } from 'src/app/shared/_models';
import { AuthenticationService, FederationService, ParametreService } from '../../shared/_services';
import { FederationMarqueursService } from 'src/app/shared/_services/federation-marqueurs.service';
import { FederationMarqueur } from 'src/app/shared/_models/federation-marqueur';
import { MessageService } from 'primeng';

@Component({
  selector: 'app-admin-marqueurs',
  templateUrl: './admin-marqueurs.component.html',
  styleUrls: ['./admin-marqueurs.component.css']
})
export class AdminMarqueursComponent implements OnInit {
  // Variables
  activeIndexFilter: number;
  federationsList: Federation[];
  currentUtilisateur: Utilisateur;
  currentFederation: Federation;
  federationsMarqueursList: FederationMarqueur[];
  marqueursList: Parametre[];
  selectedFederationMarqueurs: Parametre[];
  marqueursInactifs  = true;

  constructor(
    private authenticationService: AuthenticationService,
    private federationService: FederationService,
    private parametreService: ParametreService,
    private federationMarqueursService: FederationMarqueursService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.activeIndexFilter = 0;
    this.currentUtilisateur = this.authenticationService.currentUtilisateurValue;
    this.getFederations();
    if (this.currentUtilisateur.perimetre === 'National') {
      this.getAllFederationsMarqueurs();
    } else {
      this.currentFederation = this.currentUtilisateur.federation;
      this.onChangeFederation();
    }
    this.getMarqueurs();
  }

  getFederations() {
    if (this.currentUtilisateur.perimetre === 'National') { // Si perimetre National, on cherche toutes les federations
      this.federationService.getAll().subscribe(federations => {
        this.federationsList = federations;
      });
    } else {
      this.federationsList = [this.currentUtilisateur.federation];
    }
  }

  onChangeFederation() {
    if (this.currentFederation && this.currentFederation.id_federation) {
      this.federationMarqueursService.getFederationsMarqueursByFederation(this.currentFederation.id_federation).subscribe(res => {
        this.filtre(res);
      });
    } else { // si pas de fédé choisie
      this.getAllFederationsMarqueurs();
    }
  }

  filtre(res) {
    // disabled en base (1 si inactif) et le contraire à l'écran (actif=1) : les tests sont inversés
    const filteredMarqueurs = res.filter(fm => !this.marqueursInactifs ? fm.disabled : !fm.disabled);
    this.federationsMarqueursList = filteredMarqueurs;
    this.selectedFederationMarqueurs = filteredMarqueurs.map(fm => fm.marqueur);
  }

  onChangeDisabled() {
    if (this.currentFederation && this.currentFederation.id_federation) {
      this.onChangeFederation(); // pour éviter de dupliquer le code
    } else { // si pas de fédé choisie
      this.getAllFederationsMarqueurs();
    }
  }

  getAllFederationsMarqueurs() {
    // disabled en base (1 si inactif) et le contraire à l'écran (actif=1) : les tests sont inversés
    this.federationMarqueursService.getAll().subscribe(res => {
      this.federationsMarqueursList = res.filter(fm => !this.marqueursInactifs ? fm.disabled : !fm.disabled);
    });
  }

  getMarqueurs() {
    this.parametreService.getParametresByParams({ type: 'MARQUEUR' })
      .subscribe((parametres: Parametre[]) => {
        this.marqueursList = parametres;
        // On trie par ordre alphabétique
        this.marqueursList.sort(function (a, b) {
          if (a.libelle < b.libelle) { return -1; }
          if (a.libelle > b.libelle) { return 1; }
          return 0;
        });
      });
  }

  saveFederationMarqueurs() {
    let data = {
      'federationId': this.currentFederation.id_federation,
      'marqueursIds': this.federationsMarqueursList
    };

    this.federationMarqueursService.saveFederationMarqueur(data).subscribe({
      next: res => {
        this.messageService.add({ severity: 'success', summary: 'Enregistrement', detail: 'Enregistrement réussi' });
      },
      error: err => {
        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: err.message });
      }
    });
  }

  getSwitchMarqueur(federationMarqueur: any): boolean {
    return !federationMarqueur.disabled;
  }

  setSwitchMarqueur(federationMarqueur: any, value: boolean) {
    federationMarqueur.disabled = !value;
  }
}
