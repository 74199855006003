import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {SelectItem} from 'primeng/api';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
// import { plainToClass } from 'class-transformer';

import { AssociationService, FederationService, AgenceService, ParametreService, EchangeService, ActionService, AuthenticationService } from 'src/app/shared/_services';
import { Federation, Association, Agence, Parametre, /*Action,*/ Utilisateur, DroitModule } from 'src/app/shared/_models';
import {FiltreEchange} from '../../shared/utils/filtre-echange';
import {FiltreAction} from '../../shared/utils/filtre-action';
import { Globals } from './../../shared/utils/globals';

@Component({
  selector: 'app-tableau-bord',
  templateUrl: './tableau-bord.component.html',
  styleUrls: ['./tableau-bord.component.css']
})
export class TableauBordComponent implements OnInit {

  currentUtilisateur: Utilisateur;

  federationsList: Federation[];
  federationSelected: Federation;
  associationsList: Association[];
  associationsItemsList: SelectItem[];
  associationSelected: Association;
  agencesList: Agence[];
  agencesItemsList: SelectItem[];
  agenceSelected: Agence;

  statutEchangeOuvert: Parametre;
  statutEchangeBrouillon: Parametre;
  statutEchangeAstreinte: Parametre;
  categorieReclamation: Parametre;
  categorieAlerte: Parametre;
  categorieRemonteeInformation: Parametre;
  categorieContact: Parametre;
  categorieDevis: Parametre;
  statutActionEncours: Parametre;
  categoriesList: Parametre[];
  typeInternet: Parametre;
  typeAppel: Parametre;
  typeCourrier: Parametre;
  typeMessagesMails: Parametre;
  typePassage: Parametre;
  natureProspect: Parametre;
  natureClient: Parametre;


  seuilEchangesUrgents = 10;
  nbEchangesUrgents: number;
  seuilReclamations = 10;
  nbReclamations: number;
  seuilBrouillons = 10;
  nbBrouillons: number;
  seuilAstreintes = 10;
  nbAstreintes: number;
  seuilMesEchanges = 10;
  nbMesEchanges: number;
  seuilMesBrouillons = 10;
  nbMesBrouillons: number;
  seuilEchangesRemInf = 10;
  nbEchangesRemInf: number;
  seuilMesEchangesUrgentsOuverts = 10;
  nbMesEchangesUrgentsOuverts: number;
  seuilEchangesDevisOuverts = 10;
  nbEchangesDevisOuverts: number;
  seuilEchangesProspectOuverts = 10;
  nbEchangesProspectOuverts: number;

  dataBarActions: any;
  dataPieMesActions: any;
  dataBarActionsSuivi: any;
  dataPieMesActionsSuivi: any;

  dataPieEchanges: any;

  optionsChartBar = Globals.optionsChartBar;
  optionsChartPie: any;
  optionsChartPieEchange: any;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private federationService: FederationService,
    private associationService: AssociationService,
    private agenceService: AgenceService,
    private parametreService: ParametreService,
    private echangeService: EchangeService,
    private actionService: ActionService
  ) { }

  ngOnInit() {
    this.currentUtilisateur = this.authenticationService.currentUtilisateurValue;
    this.getFederations();
    this.getAssociations();
    this.getAgences();
    this.initChartOptions();

    // On récupère les parametres (Catégorie et Statut) avant de faire les requetes
    forkJoin(
      [
        this.parametreService.getParametresByParams({type: 'STATUT_E'}),
        this.parametreService.getParametresByParams({type: 'CATEGORIE'}),
        this.parametreService.getParametresByParams({type: 'TYPE'}),
        this.parametreService.getParametresByParams({type: 'NATURE'})
        // this.parametreService.getParametresByParams({type: 'STATUT_EVA'})
      ]

    ).subscribe(([statutEList, categorieList, typeList, natureList]) => {

      this.categoriesList = categorieList;

      let self = this;
      statutEList.forEach(function(parametreStatutE) {
        if (parametreStatutE.code_parametre === 'O') {
          self.statutEchangeOuvert = parametreStatutE; // On definit le statut echange ouvert
        } else if (parametreStatutE.code_parametre === 'B') {
          self.statutEchangeBrouillon = parametreStatutE; // On definit le statut echange brouillon
        } else if (parametreStatutE.code_parametre === 'A') {
          self.statutEchangeAstreinte = parametreStatutE; // On definit le statut echange astreinte
        }
      });

      categorieList.forEach(function(parametreCategorie) {
        if (parametreCategorie.code_parametre === 'ALRT') {
          self.categorieAlerte = parametreCategorie; // On definit la categorie Alerte
        } else if (parametreCategorie.code_parametre === 'REC') {
          self.categorieReclamation = parametreCategorie; // On definit la categorie Reclamation
        } else if (parametreCategorie.code_parametre === 'REMINF') {
          self.categorieRemonteeInformation = parametreCategorie; // On definit la categorie Remontee Information
        } else if (parametreCategorie.code_parametre === 'CONT') {
          self.categorieContact = parametreCategorie; // On definit la categorie Contact
        } else if (parametreCategorie.code_parametre === 'DEV') {
          self.categorieDevis = parametreCategorie; // On definit la categorie Devis
      }
      });

      typeList.forEach(function(parametreType) {
          if (parametreType.code_parametre === 'WEB') {
            self.typeInternet = parametreType;
          } else if (parametreType.code_parametre === 'APL') {
            self.typeAppel = parametreType;
          } else if (parametreType.code_parametre === 'COU') {
            self.typeCourrier = parametreType;
          } else if (parametreType.code_parametre === 'MESS') {
            self.typeMessagesMails = parametreType;
          } else if (parametreType.code_parametre === 'PAS') {
            self.typePassage = parametreType;
          }
      });

      natureList.forEach(function(parametreNature) {
          if (parametreNature.code_parametre === 'PRO') {
            self.natureProspect = parametreNature;
          } else if (parametreNature.code_parametre === 'CLI') {
            self.natureClient = parametreNature;
          }
      });

      this.getDatas();

    });
  }

  getFederations() {
    if (this.currentUtilisateur.perimetre === 'National') { // Si perimetre National, on cherche toutes les federations
      this.federationService.getAll().subscribe(federations => {
        this.federationsList = federations;
      });
    } else {
      this.federationsList = [this.currentUtilisateur.federation];
      this.federationSelected = this.currentUtilisateur.federation;
    }
  }

  getAssociations() {
    if (this.currentUtilisateur.perimetre !== 'National') {
      if (this.currentUtilisateur.type_acces === 'monoAsso' || (this.currentUtilisateur.perimetre === 'Association' && !this.currentUtilisateur.type_acces)) {
        this.associationsList = this.currentUtilisateur.association ? [this.currentUtilisateur.association] : undefined;
        this.associationSelected = this.currentUtilisateur.association;
      } else if (this.currentUtilisateur.type_acces === 'multiAsso') {
        this.associationsList = this.currentUtilisateur.multiSiteAssociations;
      } else if (this.currentUtilisateur.type_acces === 'multiSite') {
        this.associationsList = [this.authenticationService.currentAssociationForUserMultiSite];
        this.associationSelected = this.authenticationService.currentAssociationForUserMultiSite;
      } else if (this.currentUtilisateur.perimetre === 'Federation') {
        this.associationService.getAssociationsByParams({federation: this.currentUtilisateur.federation.id_federation})
          .subscribe((associations: Association[]) => {
            this.associationsList = associations;
            this.updateAssociationsItems();
        });
      } else if (this.currentUtilisateur.perimetre === 'Agence') {
        this.associationsList = this.currentUtilisateur.association ? [this.currentUtilisateur.association] : undefined;
        this.associationSelected = this.currentUtilisateur.association;
      }
    } else if (this.federationSelected) {
      this.associationService.getAssociationsByParams({federation: this.federationSelected.id_federation})
      .subscribe((associations: Association[]) => {
        this.associationsList = associations;
        this.updateAssociationsItems();
      });
    }

    if (this.associationsList) {
      this.updateAssociationsItems();
    }

  }

  getAgences() {
    if (this.currentUtilisateur.agences && this.currentUtilisateur.agences.length > 0) {
      this.agencesList = this.currentUtilisateur.agences;
      this.updateAgencesItems();
    } else if (this.associationSelected) {
      this.agenceService.getAgencesByParams({association: this.associationSelected.id_association})
      .subscribe((agences: Agence[]) => {
        this.agencesList = agences;
        this.updateAgencesItems();
      });
    }
  }

  onChangeFederation() {
    if (this.federationSelected) {
        this.associationService.getAssociationsByParams({federation: this.federationSelected.id_federation})
        .subscribe((associations: Association[]) => {
          this.associationsList = associations;
          this.updateAssociationsItems();
        });
    } else {
      this.associationsList = null;
      this.associationSelected = null;
      this.updateAssociationsItems();
    }
  }

  onChangeAssociation() {
    if (this.associationSelected) {
        this.agenceService.getAgencesByParams({association: this.associationSelected.id_association})
        .subscribe((agences: Agence[]) => {
          this.agencesList = agences;
          this.updateAgencesItems();
      });
    } else {
      this.agencesList = null;
      this.agenceSelected = null;
      this.updateAgencesItems();
    }
  }

  getClassIndicateur(indicateur) {
    let seuil = 10;
    let nbIndicateur = 0;
    let classIndicateur = '';

    if (indicateur === 'echangesUrgents') {
      seuil = this.seuilEchangesUrgents;
      nbIndicateur = this.nbEchangesUrgents;
    } else if (indicateur === 'reclamations') {
      seuil = this.seuilReclamations;
      nbIndicateur = this.nbReclamations;
    } else if (indicateur === 'echanges_brouillons') {
      seuil = this.seuilBrouillons;
      nbIndicateur = this.nbBrouillons;
    } else if (indicateur === 'echanges_rem_inf') {
      seuil = this.seuilEchangesRemInf;
      nbIndicateur = this.nbEchangesRemInf;
    } else if (indicateur === 'echanges_astreintes') {
      seuil = this.seuilAstreintes;
      nbIndicateur = this.nbAstreintes;
    } else if (indicateur === 'mes_echanges') {
      seuil = this.seuilMesEchanges;
      nbIndicateur = this.nbMesEchanges;
    } else if (indicateur === 'mes_echanges_brouillons') {
      seuil = this.seuilMesBrouillons;
      nbIndicateur = this.nbMesBrouillons;
    } else if (indicateur === 'mes_echanges_urgents_ouverts') {
      seuil = this.seuilMesEchangesUrgentsOuverts;
      nbIndicateur = this.nbMesEchangesUrgentsOuverts;
    } else if (indicateur === 'echanges_devis_ouverts') {
      seuil = this.seuilEchangesDevisOuverts;
      nbIndicateur = this.nbEchangesDevisOuverts;
    } else if (indicateur === 'echanges_prospects_ouverts') {
      seuil = this.seuilEchangesProspectOuverts;
      nbIndicateur = this.nbEchangesProspectOuverts;
    }

    if (nbIndicateur >= seuil) {
      classIndicateur = 'indicateur-alerte';
    }

    return classIndicateur;
  }

  initChartOptions() {
    this.optionsChartBar.title =  {
      display: true,
      text: 'Echéance'
    };

    this.optionsChartPie = {
      title: {
        display: true,
        text: 'Echéance'
      },
      hover: {
        onHover: function(e) {
            let point = this.getElementAtEvent(e);
            if (point.length) {
              e.target.style.cursor = 'pointer';
            } else {
              e.target.style.cursor = 'default';
            }
        }
      }
    };

    this.optionsChartPieEchange = {
      // title: {
      //   display: true,
      //   text: 'Catégories'
      // },
      legend: {
        position: 'left'
      },
      hover: {
        onHover: function(e) {
            let point = this.getElementAtEvent(e);
            if (point.length) {
              e.target.style.cursor = 'pointer';
            } else {
              e.target.style.cursor = 'default';
            }
        }
      }
    };
  }

  getDatas() {
    this.getIndicateurs();
    this.getDataActions();

    if (this.currentUtilisateur.acces_echanges || this.currentUtilisateur.acces_personia) {
      this.getDataEchanges();
    }
  }

  getIndicateurs() {
    this.initIndicateurs();
    if (this.currentUtilisateur.acces_echanges) {
      let filtreEchangeUrgent = this.getNewFiltreEchange();
      filtreEchangeUrgent.statut_echange.push(this.statutEchangeOuvert);
      filtreEchangeUrgent.priorite = ['U'];
      this.getNbEchangesUrgents(filtreEchangeUrgent);

      let filtreEchangeReclamation = this.getNewFiltreEchange();
      filtreEchangeReclamation.statut_echange.push(this.statutEchangeOuvert);
      filtreEchangeReclamation.categorie_echange = [this.categorieReclamation];
      this.getNbReclamations(filtreEchangeReclamation);

      let filtreEchangeBrouillon = this.getNewFiltreEchange();
      filtreEchangeBrouillon.statut_echange.push(this.statutEchangeBrouillon);
      this.getNbBrouillons(filtreEchangeBrouillon);

      let filtreEchangeRemInf = this.getNewFiltreEchange();
      filtreEchangeRemInf.statut_echange.push(this.statutEchangeOuvert);
      filtreEchangeRemInf.categorie_echange = [this.categorieRemonteeInformation];
      this.getNbEchangesRemInf(filtreEchangeRemInf);

      let filtreEchangeAstreinte = this.getNewFiltreEchange();
      filtreEchangeAstreinte.statut_echange.push(this.statutEchangeBrouillon);
      filtreEchangeAstreinte.statut_echange.push(this.statutEchangeOuvert);
      filtreEchangeAstreinte.astreinte = ['true'];
      this.getNbAstreintes(filtreEchangeAstreinte);

      let filtreEchangeMesBrouillons = this.getNewFiltreEchange();
      filtreEchangeMesBrouillons.statut_echange.push(this.statutEchangeBrouillon);
      filtreEchangeMesBrouillons.operateur = [this.currentUtilisateur];
      this.getNbMesBrouillons(filtreEchangeMesBrouillons);

      let filtreEchangeDevisOuverts = this.getNewFiltreEchange();
      filtreEchangeDevisOuverts.personne_sujet_nature.push(this.natureProspect);
      filtreEchangeDevisOuverts.personne_sujet_nature.push(this.natureClient);
      filtreEchangeDevisOuverts.statut_echange.push(this.statutEchangeOuvert);
      filtreEchangeDevisOuverts.statut_echange.push(this.statutEchangeBrouillon);
      filtreEchangeDevisOuverts.categorie_echange = [this.categorieDevis];
      this.getNbEchangesDevisOuverts(filtreEchangeDevisOuverts);

      let filtreEchangeProspectsOuverts = this.getNewFiltreEchange();
      filtreEchangeProspectsOuverts.personne_sujet_nature.push(this.natureProspect);
      filtreEchangeProspectsOuverts.type_echange.push(this.typeAppel);
      filtreEchangeProspectsOuverts.type_echange.push(this.typeCourrier);
      filtreEchangeProspectsOuverts.type_echange.push(this.typeMessagesMails);
      filtreEchangeProspectsOuverts.type_echange.push(this.typePassage);
      filtreEchangeProspectsOuverts.statut_echange.push(this.statutEchangeOuvert);
      filtreEchangeProspectsOuverts.statut_echange.push(this.statutEchangeBrouillon);
      this.getNbEchangesProspectsOuverts(filtreEchangeProspectsOuverts);
    }

    if (this.currentUtilisateur.acces_echanges || this.currentUtilisateur.acces_personia) {
      let filtreEchangeMesEchanges = this.getNewFiltreEchange();
      filtreEchangeMesEchanges.operateur = [this.currentUtilisateur];
      this.getNbMesEchanges(filtreEchangeMesEchanges);

      let filtreEchangeMesEchangesUrgentsOuverts = this.getNewFiltreEchange();
      filtreEchangeMesEchangesUrgentsOuverts.statut_echange.push(this.statutEchangeOuvert);
      filtreEchangeMesEchangesUrgentsOuverts.operateur = [this.currentUtilisateur];
      filtreEchangeMesEchangesUrgentsOuverts.priorite = ['U'];
      this.getNbMesEchangesUrgentsOuverts(filtreEchangeMesEchangesUrgentsOuverts);
    }
  }

  getNbEchangesUrgents(filtreEchange: FiltreEchange) {
    this.echangeService.getNbEchangesByParams(filtreEchange)
      .subscribe((result) => {
        this.nbEchangesUrgents = result.count;
    });
  }

  getNbReclamations(filtreEchange: FiltreEchange) {
    this.echangeService.getNbEchangesByParams(filtreEchange)
      .subscribe((result) => {
        this.nbReclamations = result.count;
    });
  }


  getNbMesEchanges(filtreEchange: FiltreEchange) {
    this.echangeService.getNbEchangesByParams(filtreEchange)
      .subscribe((result) => {
        this.nbMesEchanges = result.count;
    });
  }

  getNbBrouillons(filtreEchange: FiltreEchange) {
    this.echangeService.getNbEchangesByParams(filtreEchange)
      .subscribe((result) => {
        this.nbBrouillons = result.count;
    });
  }

  getNbEchangesRemInf(filtreEchange: FiltreEchange) {
    this.echangeService.getNbEchangesByParams(filtreEchange)
      .subscribe((result) => {
        this.nbEchangesRemInf = result.count;
    });
  }

  getNbAstreintes(filtreEchange: FiltreEchange) {
    this.echangeService.getNbEchangesByParams(filtreEchange)
      .subscribe((result) => {
        this.nbAstreintes = result.count;
    });
  }

  getNbMesBrouillons(filtreEchange: FiltreEchange) {
    this.echangeService.getNbEchangesByParams(filtreEchange)
      .subscribe((result) => {
        this.nbMesBrouillons = result.count;
    });
  }

  getNbMesEchangesUrgentsOuverts(filtreEchange: FiltreEchange) {
    this.echangeService.getNbEchangesByParams(filtreEchange)
      .subscribe((result) => {
        this.nbMesEchangesUrgentsOuverts = result.count;
    });
  }

  getNbEchangesDevisOuverts(filtreEchange: FiltreEchange) {
    this.echangeService.getNbEchangesByParams(filtreEchange)
      .subscribe((result) => {
        this.nbEchangesDevisOuverts = result.count;
      });
  }

  getNbEchangesProspectsOuverts(filtreEchange: FiltreEchange) {
    this.echangeService.getNbEchangesByParams(filtreEchange)
      .subscribe((result) => {
        this.nbEchangesProspectOuverts = result.count;
      });
  }

  getDataActions() {
    // On récupère les parametres (Statut) avant de faire les requetes
    forkJoin(
      [
        this.parametreService.getParametresByParams({type: 'STATUT_A'})
      ]

    ).subscribe(([statutActionList]) => {
      let self = this;

      statutActionList.forEach(function(parametreStatutA) {
        if (parametreStatutA.code_parametre === 'E') {
          self.statutActionEncours = parametreStatutA; // On definit le statut action en cours
        }
      });

      this.getDataActionsOuvertes();
      this.getDataMesActions();
      this.getDataActionsSuiviOuvertes();
      this.getDataMesActionsSuivi();
    });
  }

  getDataActionsOuvertes() {
    this.dataBarActions = null;

    let filtreActionsOuvertesDepassees = this.getNewFiltreAction();
    filtreActionsOuvertesDepassees.statut_action.push(this.statutActionEncours);
    filtreActionsOuvertesDepassees.closed_debut = null;
    filtreActionsOuvertesDepassees.closed_fin = moment(new Date()).subtract(1, 'days').toDate(); // Date = hier
    filtreActionsOuvertesDepassees.limit = null;

    let filtreActionsOuvertesJour = this.getNewFiltreAction();
    filtreActionsOuvertesJour.statut_action.push(this.statutActionEncours);
    filtreActionsOuvertesJour.closed_debut = new Date();
    filtreActionsOuvertesJour.closed_fin = new Date();
    filtreActionsOuvertesJour.limit = null;

    let filtreActionsOuvertesSemaine = this.getNewFiltreAction();
    filtreActionsOuvertesSemaine.statut_action.push(this.statutActionEncours);
    filtreActionsOuvertesSemaine.closed_debut = new Date();
    filtreActionsOuvertesSemaine.closed_fin = Globals.addDays(new Date(), 7);
    filtreActionsOuvertesSemaine.limit = null;

    let filtreActionsOuvertesMois = this.getNewFiltreAction();
    filtreActionsOuvertesMois.statut_action.push(this.statutActionEncours);
    filtreActionsOuvertesMois.closed_debut = new Date();
    filtreActionsOuvertesMois.closed_fin = Globals.addDays(new Date(), 30);
    filtreActionsOuvertesMois.limit = null;

    let filtreActionsOuvertesSansEcheance = this.getNewFiltreAction();
    filtreActionsOuvertesSansEcheance.statut_action.push(this.statutActionEncours);
    filtreActionsOuvertesSansEcheance.no_echeance = 'O';
    filtreActionsOuvertesSansEcheance.limit = null;

    forkJoin(
      [
        this.actionService.getNbActionsByParams(filtreActionsOuvertesDepassees),
        this.actionService.getNbActionsByParams(filtreActionsOuvertesJour),
        this.actionService.getNbActionsByParams(filtreActionsOuvertesSemaine),
        this.actionService.getNbActionsByParams(filtreActionsOuvertesMois),
        this.actionService.getNbActionsByParams(filtreActionsOuvertesSansEcheance)
      ]

    ).subscribe(([nbActionsOuvertesDepassees, nbActionsOuvertesJour, nbActionsOuvertesSemaine, nbActionsOuvertesMois, nbActionsOuvertesSansEcheance]) => {
      let nbActionsDepassees = nbActionsOuvertesDepassees.count;
      let nbActionsJour = nbActionsOuvertesJour.count;
      let nbActionsSemaine = nbActionsOuvertesSemaine.count;
      let nbActionsMois = nbActionsOuvertesMois.count;
      let nbActionsSansEcheance = nbActionsOuvertesSansEcheance.count;

      this.dataBarActions = {
        labels: [''],
        datasets: [
            {
                label: 'Mois (J+30)',
                backgroundColor: '#24AB34',
                borderColor: '#24AB34',
                data: [nbActionsMois]
            },
            {
                label: 'Semaine (J+7)',
                backgroundColor: '#D95F02',
                borderColor: '#D95F02',
                data: [nbActionsSemaine]
            },
            {
                label: 'Jour',
                backgroundColor: '#fc7e7e',
                borderColor: '#fc7e7e',
                data: [nbActionsJour]
            },
            {
                label: 'Dépassée',
                backgroundColor: '#C00000',
                borderColor: '#C00000',
                data: [nbActionsDepassees]
            },
            {
                label: 'Sans échéance',
                backgroundColor: '#CBCBCB',
                borderColor: '#CBCBCB',
                data: [nbActionsSansEcheance]
            }
        ]
      };
    });
  }

  getDataEchanges() {
      this.dataPieEchanges = null;

      let filtreEchangesAlerte = this.getNewFiltreEchange();
      filtreEchangesAlerte.statut_echange.push(this.statutEchangeOuvert);
      if (this.currentUtilisateur.hasFonctionnalite(DroitModule.module_personia, DroitModule.fonctionnalite_edit_echanges)) {
        filtreEchangesAlerte.statut_echange.push(this.statutEchangeAstreinte);
      }
      filtreEchangesAlerte.categorie_echange = [this.categorieAlerte];
      filtreEchangesAlerte.limit = null;

      let filtreEchangesRemonteeInformation = this.getNewFiltreEchange();
      filtreEchangesRemonteeInformation.statut_echange.push(this.statutEchangeOuvert);
      if (this.currentUtilisateur.hasFonctionnalite(DroitModule.module_personia, DroitModule.fonctionnalite_edit_echanges)) {
        filtreEchangesRemonteeInformation.statut_echange.push(this.statutEchangeAstreinte);
      }
      filtreEchangesRemonteeInformation.categorie_echange = [this.categorieRemonteeInformation];
      filtreEchangesRemonteeInformation.limit = null;

      let filtreEchangesContact = this.getNewFiltreEchange();
      filtreEchangesContact.statut_echange.push(this.statutEchangeOuvert);
      if (this.currentUtilisateur.hasFonctionnalite(DroitModule.module_personia, DroitModule.fonctionnalite_edit_echanges)) {
        filtreEchangesContact.statut_echange.push(this.statutEchangeAstreinte);
      }
      filtreEchangesContact.categorie_echange = [this.categorieContact];
      filtreEchangesContact.limit = null;

      let filtreEchangesDevis = this.getNewFiltreEchange();
      filtreEchangesDevis.statut_echange.push(this.statutEchangeOuvert);
      if (this.currentUtilisateur.hasFonctionnalite(DroitModule.module_personia, DroitModule.fonctionnalite_edit_echanges)) {
        filtreEchangesDevis.statut_echange.push(this.statutEchangeAstreinte);
      }
      filtreEchangesDevis.categorie_echange = [this.categorieDevis];
      filtreEchangesDevis.limit = null;

      let filtreEchangesReclamation = this.getNewFiltreEchange();
      filtreEchangesReclamation.statut_echange.push(this.statutEchangeOuvert);
      if (this.currentUtilisateur.hasFonctionnalite(DroitModule.module_personia, DroitModule.fonctionnalite_edit_echanges)) {
        filtreEchangesReclamation.statut_echange.push(this.statutEchangeAstreinte);
      }
      filtreEchangesReclamation.categorie_echange = [this.categorieReclamation];
      filtreEchangesReclamation.limit = null;

      forkJoin(
        [
          this.echangeService.getNbEchangesByParams(filtreEchangesAlerte),
          this.echangeService.getNbEchangesByParams(filtreEchangesRemonteeInformation),
          this.echangeService.getNbEchangesByParams(filtreEchangesContact),
          this.echangeService.getNbEchangesByParams(filtreEchangesDevis),
          this.echangeService.getNbEchangesByParams(filtreEchangesReclamation)
        ]

      ).subscribe(([echangesAlerte, echangesRemonteeInformation, echangesContact, echangesDevis, echangesReclamation]) => {

        let nbEchangesAlerte = echangesAlerte.count;
        let nbEchangesRemonteeInformation = echangesRemonteeInformation.count;
        let nbEchangesContact = echangesContact.count;
        let nbEchangesDevis = echangesDevis.count;
        let nbEchangesReclamation = echangesReclamation.count;

        this.dataPieEchanges = {
          labels: ['Alertes', 'Remontées d\'informations', 'Contacts', 'Devis', 'Réclamations'],
          datasets: [{
            data: [nbEchangesAlerte, nbEchangesRemonteeInformation, nbEchangesContact, nbEchangesDevis, nbEchangesReclamation],
            backgroundColor: [
              '#EF476F',
              '#FFD166',
              '#06D6A0',
              '#118AB2',
              '#073B4C'
            ],
            hoverBackgroundColor: [
              '#EF476F',
              '#FFD166',
              '#06D6A0',
              '#118AB2',
              '#073B4C'
            ]
          }]
        };
      });
  }

  selectDataChartEchange(event, indicateur: string) {
    let datasetIndex = indicateur === 'echangesOuverts' ? event.element._index : event.element._datasetIndex;
    let selectedLabel = indicateur === 'echangesOuverts' ? this.dataPieEchanges.labels[datasetIndex] : this.dataPieEchanges.datasets[datasetIndex].label;

    this.goToRechercheEchange(indicateur, event.originalEvent, selectedLabel);
  }

  getDataMesActions() {
    this.dataPieMesActions = null;

    let filtreMesActionsDepassees = this.getNewFiltreAction();
    filtreMesActionsDepassees.statut_action.push(this.statutActionEncours);
    filtreMesActionsDepassees.closed_debut = null;
    filtreMesActionsDepassees.closed_fin = moment(new Date()).subtract(1, 'days').toDate(); // Date = hier
    filtreMesActionsDepassees.responsable = [this.currentUtilisateur];
    filtreMesActionsDepassees.limit = null;

    let filtreMesActionsJour = this.getNewFiltreAction();
    filtreMesActionsJour.statut_action.push(this.statutActionEncours);
    filtreMesActionsJour.closed_debut = new Date();
    filtreMesActionsJour.closed_fin = new Date();
    filtreMesActionsJour.responsable = [this.currentUtilisateur];
    filtreMesActionsJour.limit = null;

    let filtreMesActionsSemaine = this.getNewFiltreAction();
    filtreMesActionsSemaine.statut_action.push(this.statutActionEncours);
    filtreMesActionsSemaine.closed_debut = new Date();
    filtreMesActionsSemaine.closed_fin = Globals.addDays(new Date(), 7);
    filtreMesActionsSemaine.responsable = [this.currentUtilisateur];
    filtreMesActionsSemaine.limit = null;

    let filtreMesActionsMois = this.getNewFiltreAction();
    filtreMesActionsMois.statut_action.push(this.statutActionEncours);
    filtreMesActionsMois.closed_debut = new Date();
    filtreMesActionsMois.closed_fin = Globals.addDays(new Date(), 30);
    filtreMesActionsMois.responsable = [this.currentUtilisateur];
    filtreMesActionsMois.limit = null;

    let filtreMesActionsSansEcheance = this.getNewFiltreAction();
    filtreMesActionsSansEcheance.statut_action.push(this.statutActionEncours);
    filtreMesActionsSansEcheance.no_echeance = 'O';
    filtreMesActionsSansEcheance.responsable = [this.currentUtilisateur];
    filtreMesActionsSansEcheance.limit = null;

    forkJoin(
      [
        this.actionService.getNbActionsByParams(filtreMesActionsDepassees),
        this.actionService.getNbActionsByParams(filtreMesActionsJour),
        this.actionService.getNbActionsByParams(filtreMesActionsSemaine),
        this.actionService.getNbActionsByParams(filtreMesActionsMois),
        this.actionService.getNbActionsByParams(filtreMesActionsSansEcheance)
      ]

    ).subscribe(([nbMesActionsDepassees, nbMesActionsJour, nbMesActionsSemaine, nbMesActionsMois, nbMesActionsSansEcheance]) => {
      let nbActionsDepassees = nbMesActionsDepassees.count;
      let nbActionsJour = nbMesActionsJour.count;
      let nbActionsSemaine = nbMesActionsSemaine.count;
      let nbActionsMois = nbMesActionsMois.count;
      let nbActionsSansEcheance = nbMesActionsSansEcheance.count;

      this.dataPieMesActions = {
        labels: ['Mois (J+30)', 'Semaine (J+7)', 'Jour', 'Dépassée', 'Sans échéance'],
        datasets: [
            {
                data: [nbActionsMois, nbActionsSemaine, nbActionsJour, nbActionsDepassees, nbActionsSansEcheance],
                backgroundColor: [
                    '#24AB34',
                    '#D95F02',
                    '#fc7e7e',
                    '#C00000',
                    '#CBCBCB'
                ],
                hoverBackgroundColor: [
                    '#24AB34',
                    '#D95F02',
                    '#fc7e7e',
                    '#C00000',
                    '#CBCBCB'
                ]
            }]
        };
    });
  }

  getDataActionsSuiviOuvertes() {
    this.dataBarActionsSuivi = null;

    let filtreActionsSuiviOuvertesDepassees = this.getNewFiltreAction();
    filtreActionsSuiviOuvertesDepassees.statut_action.push(this.statutActionEncours);
    filtreActionsSuiviOuvertesDepassees.closed_debut = null;
    filtreActionsSuiviOuvertesDepassees.closed_fin = moment(new Date()).subtract(1, 'days').toDate(); // Date = hier
    filtreActionsSuiviOuvertesDepassees.limit = null;
    filtreActionsSuiviOuvertesDepassees.objet_action = 'Suivi';

    let filtreActionsSuiviOuvertesJour = this.getNewFiltreAction();
    filtreActionsSuiviOuvertesJour.statut_action.push(this.statutActionEncours);
    filtreActionsSuiviOuvertesJour.closed_debut = new Date();
    filtreActionsSuiviOuvertesJour.closed_fin = new Date();
    filtreActionsSuiviOuvertesJour.limit = null;
    filtreActionsSuiviOuvertesJour.objet_action = 'Suivi';

    let filtreActionsSuiviOuvertesSemaine = this.getNewFiltreAction();
    filtreActionsSuiviOuvertesSemaine.statut_action.push(this.statutActionEncours);
    filtreActionsSuiviOuvertesSemaine.closed_debut = new Date();
    filtreActionsSuiviOuvertesSemaine.closed_fin = Globals.addDays(new Date(), 7);
    filtreActionsSuiviOuvertesSemaine.limit = null;
    filtreActionsSuiviOuvertesSemaine.objet_action = 'Suivi';

    let filtreActionsSuiviOuvertesMois = this.getNewFiltreAction();
    filtreActionsSuiviOuvertesMois.statut_action.push(this.statutActionEncours);
    filtreActionsSuiviOuvertesMois.closed_debut = new Date();
    filtreActionsSuiviOuvertesMois.closed_fin = Globals.addDays(new Date(), 30);
    filtreActionsSuiviOuvertesMois.limit = null;
    filtreActionsSuiviOuvertesMois.objet_action = 'Suivi';

    let filtreActionsSuiviOuvertesSansEcheance = this.getNewFiltreAction();
    filtreActionsSuiviOuvertesSansEcheance.statut_action.push(this.statutActionEncours);
    filtreActionsSuiviOuvertesSansEcheance.no_echeance = 'O';
    filtreActionsSuiviOuvertesSansEcheance.limit = null;
    filtreActionsSuiviOuvertesSansEcheance.objet_action = 'Suivi';

    forkJoin(
      [
        this.actionService.getNbActionsByParams(filtreActionsSuiviOuvertesDepassees),
        this.actionService.getNbActionsByParams(filtreActionsSuiviOuvertesJour),
        this.actionService.getNbActionsByParams(filtreActionsSuiviOuvertesSemaine),
        this.actionService.getNbActionsByParams(filtreActionsSuiviOuvertesMois),
        this.actionService.getNbActionsByParams(filtreActionsSuiviOuvertesSansEcheance)
      ]

    ).subscribe(([nbActionsOuvertesDepassees, nbActionsOuvertesJour, nbActionsOuvertesSemaine, nbActionsOuvertesMois, nbActionsOuvertesSansEcheance]) => {
      let nbActionsDepassees = nbActionsOuvertesDepassees.count;
      let nbActionsJour = nbActionsOuvertesJour.count;
      let nbActionsSemaine = nbActionsOuvertesSemaine.count;
      let nbActionsMois = nbActionsOuvertesMois.count;
      let nbActionsSansEcheance = nbActionsOuvertesSansEcheance.count;

      this.dataBarActionsSuivi = {
        labels: [''],
        datasets: [
            {
                label: 'Mois (J+30)',
                backgroundColor: '#24AB34',
                borderColor: '#24AB34',
                data: [nbActionsMois]
            },
            {
                label: 'Semaine (J+7)',
                backgroundColor: '#D95F02',
                borderColor: '#D95F02',
                data: [nbActionsSemaine]
            },
            {
                label: 'Jour',
                backgroundColor: '#fc7e7e',
                borderColor: '#fc7e7e',
                data: [nbActionsJour]
            },
            {
                label: 'Dépassée',
                backgroundColor: '#C00000',
                borderColor: '#C00000',
                data: [nbActionsDepassees]
            },
            {
              label: 'Sans échéance',
              backgroundColor: '#CBCBCB',
              borderColor: '#CBCBCB',
              data: [nbActionsSansEcheance]
            }
        ]
      };
    });
  }

  getDataMesActionsSuivi() {
    this.dataPieMesActionsSuivi = null;

    let filtreMesActionsSuiviDepassees = this.getNewFiltreAction();
    filtreMesActionsSuiviDepassees.statut_action.push(this.statutActionEncours);
    filtreMesActionsSuiviDepassees.closed_debut = null;
    filtreMesActionsSuiviDepassees.closed_fin = moment(new Date()).subtract(1, 'days').toDate(); // Date = hier
    filtreMesActionsSuiviDepassees.responsable = [this.currentUtilisateur];
    filtreMesActionsSuiviDepassees.limit = null;
    filtreMesActionsSuiviDepassees.objet_action = 'Suivi';

    let filtreMesActionsSuiviJour = this.getNewFiltreAction();
    filtreMesActionsSuiviJour.statut_action.push(this.statutActionEncours);
    filtreMesActionsSuiviJour.closed_debut = new Date();
    filtreMesActionsSuiviJour.closed_fin = new Date();
    filtreMesActionsSuiviJour.responsable = [this.currentUtilisateur];
    filtreMesActionsSuiviJour.limit = null;
    filtreMesActionsSuiviJour.objet_action = 'Suivi';

    let filtreMesActionsSuiviSemaine = this.getNewFiltreAction();
    filtreMesActionsSuiviSemaine.statut_action.push(this.statutActionEncours);
    filtreMesActionsSuiviSemaine.closed_debut = new Date();
    filtreMesActionsSuiviSemaine.closed_fin = Globals.addDays(new Date(), 7);
    filtreMesActionsSuiviSemaine.responsable = [this.currentUtilisateur];
    filtreMesActionsSuiviSemaine.limit = null;
    filtreMesActionsSuiviSemaine.objet_action = 'Suivi';

    let filtreMesActionsSuiviMois = this.getNewFiltreAction();
    filtreMesActionsSuiviMois.statut_action.push(this.statutActionEncours);
    filtreMesActionsSuiviMois.closed_debut = new Date();
    filtreMesActionsSuiviMois.closed_fin = Globals.addDays(new Date(), 30);
    filtreMesActionsSuiviMois.responsable = [this.currentUtilisateur];
    filtreMesActionsSuiviMois.limit = null;
    filtreMesActionsSuiviMois.objet_action = 'Suivi';

    let filtreMesActionsSuiviSansEcheance = this.getNewFiltreAction();
    filtreMesActionsSuiviSansEcheance.statut_action.push(this.statutActionEncours);
    filtreMesActionsSuiviSansEcheance.no_echeance = 'O';
    filtreMesActionsSuiviSansEcheance.responsable = [this.currentUtilisateur];
    filtreMesActionsSuiviSansEcheance.limit = null;
    filtreMesActionsSuiviSansEcheance.objet_action = 'Suivi';

    forkJoin(
      [
        this.actionService.getNbActionsByParams(filtreMesActionsSuiviDepassees),
        this.actionService.getNbActionsByParams(filtreMesActionsSuiviJour),
        this.actionService.getNbActionsByParams(filtreMesActionsSuiviSemaine),
        this.actionService.getNbActionsByParams(filtreMesActionsSuiviMois),
        this.actionService.getNbActionsByParams(filtreMesActionsSuiviSansEcheance)
      ]

    ).subscribe(([nbMesActionsDepassees, nbMesActionsJour, nbMesActionsSemaine, nbMesActionsMois, nbMesActionsSansEcheance]) => {
      let nbActionsDepassees = nbMesActionsDepassees.count;
      let nbActionsJour = nbMesActionsJour.count;
      let nbActionsSemaine = nbMesActionsSemaine.count;
      let nbActionsMois = nbMesActionsMois.count;
      let nbActionsSansEcheance = nbMesActionsSansEcheance.count;

      this.dataPieMesActionsSuivi = {
        labels: ['Mois (J+30)', 'Semaine (J+7)', 'Jour', 'Dépassée', 'Sans échéance'],
        datasets: [
            {
                data: [nbActionsMois, nbActionsSemaine, nbActionsJour, nbActionsDepassees, nbActionsSansEcheance],
                backgroundColor: [
                    '#24AB34',
                    '#D95F02',
                    '#fc7e7e',
                    '#C00000',
                    '#CBCBCB'
                ],
                hoverBackgroundColor: [
                    '#24AB34',
                    '#D95F02',
                    '#fc7e7e',
                    '#C00000',
                    '#CBCBCB'
                ]
            }]
        };
    });
  }

  selectDataChartAction(event, indicateur: string) {
    let datasetIndex = event.element._datasetIndex;
    // let index = event.element._index;
    // let selectedValue = this.dataBarActions.datasets[datasetIndex].data[index];
    let selectedLabel = this.dataBarActions.datasets[datasetIndex].label;

    this.goToRechercheAction(event.originalEvent, indicateur, selectedLabel);
  }

  goToRechercheEchange(indicateur: string, event, legende = '') {

    let path = '/in/recherche';
    let queryParamsValue = {};
    let isNewTab = false;
    if (event.ctrlKey) { // Si Ctrl + Clic
      isNewTab = true;
    }

    if (indicateur === 'echangesUrgents') {
      queryParamsValue = { statut_echange: this.statutEchangeOuvert.id_parametre, priorite: 'U' };
    } else if (indicateur === 'reclamations') {
      queryParamsValue = { statut_echange: this.statutEchangeOuvert.id_parametre, categorie_echange: this.categorieReclamation.id_parametre};
    } else if (indicateur === 'echanges_astreintes') {
      let statutEchange = [this.statutEchangeOuvert.id_parametre, this.statutEchangeBrouillon.id_parametre];
      queryParamsValue = {
        statut_echange: statutEchange,
        astreinte: true};
    } else if (indicateur === 'echanges_brouillons') {
      queryParamsValue = { statut_echange: this.statutEchangeBrouillon.id_parametre};
    } else if (indicateur === 'echanges_rem_inf') {
      queryParamsValue = { statut_echange: this.statutEchangeOuvert.id_parametre, categorie_echange: this.categorieRemonteeInformation.id_parametre};
    } else if (indicateur === 'mes_echanges') {
      queryParamsValue = { operateur: 'me'};
    } else if (indicateur === 'mes_echanges_brouillons') {
      queryParamsValue = { statut_echange: this.statutEchangeBrouillon.id_parametre, operateur: 'me'};
    } else if (indicateur === 'echanges_devis_ouverts') {
      let natureEchange = [this.natureProspect.id_parametre, this.natureClient.id_parametre];
      let statutEchange = [this.statutEchangeOuvert.id_parametre, this.statutEchangeBrouillon.id_parametre];
      queryParamsValue = {
        personne_sujet_nature: natureEchange,
        statut_echange: statutEchange,
        categorie_echange: this.categorieDevis.id_parametre
      };
    } else if (indicateur === 'echanges_prospects_ouverts') {
      let statutEchange = [this.statutEchangeOuvert.id_parametre, this.statutEchangeBrouillon.id_parametre];
      let typeEchange = [
        this.typeAppel.id_parametre,
        this.typeCourrier.id_parametre,
        this.typeMessagesMails.id_parametre,
        this.typePassage.id_parametre
      ];
      queryParamsValue = {
        personne_sujet_nature: this.natureProspect.id_parametre,
        statut_echange: statutEchange,
        type_echange: typeEchange
      };
    } else if (indicateur === 'mes_echanges_urgents_ouverts') {
      queryParamsValue = { statut_echange: this.statutEchangeOuvert.id_parametre, operateur: 'me', priorite: 'U'};
    } else if (indicateur === 'echangesOuverts') {
      if (legende === 'Remontées d\'informations') {
        queryParamsValue = { statut_echange: this.statutEchangeOuvert.id_parametre, categorie_echange: this.categorieRemonteeInformation.id_parametre};
      } else if (legende === 'Contacts') {
        queryParamsValue = { statut_echange: this.statutEchangeOuvert.id_parametre, categorie_echange: this.categorieContact.id_parametre};
      } else if (legende === 'Devis') {
        queryParamsValue = { statut_echange: this.statutEchangeOuvert.id_parametre, categorie_echange: this.categorieDevis.id_parametre};
      } else if (legende === 'Réclamations') {
        queryParamsValue = { statut_echange: this.statutEchangeOuvert.id_parametre, categorie_echange: this.categorieReclamation.id_parametre};
      }
    }

    // On récupère la fédération du filtre précedent
    let queryParamsValueFede = {};
    if ( this.federationSelected && this.federationSelected.id_federation ) {
      queryParamsValueFede = {federation : this.federationSelected.id_federation };
      queryParamsValue = Object.assign(queryParamsValue, queryParamsValueFede);
    }

    // On récupère l'association du filtre précedent
    let queryParamsValueAsso = {};
    if ( this.associationSelected && this.associationSelected.id_association ) {
      queryParamsValueAsso = {association : this.associationSelected.id_association };
      queryParamsValue = Object.assign(queryParamsValue, queryParamsValueAsso);
    }

    // On récupère l'agence du filtre précedent
    let queryParamsValueAg = {};
    if ( this.agenceSelected && this.agenceSelected.id_agence ) {
      queryParamsValueAg = {agence : this.agenceSelected.id_agence };
      queryParamsValue = Object.assign(queryParamsValue, queryParamsValueAg);
    }

    if (isNewTab) {
      let url = this.router.createUrlTree([path], { queryParams: queryParamsValue});
      window.open(url.toString(), '_blank');
    } else {
      this.router.navigate([path], { queryParams: queryParamsValue });
    }
  }

  goToRechercheAction(event, indicateur: string, legende: string) {
    let path = '/in/recherche-action';
    let queryParamsValue = {};
    let isNewTab = false;
    if (event.ctrlKey) { // Si Ctrl + Clic
      isNewTab = true;
    }

    let today = new Date().getTime();
    let yesterday = moment(new Date()).subtract(1, 'days').toDate().getTime(); // Date = hier
    let lastDateOfMonth = Globals.addDays(new Date(), 30).getTime();
    let sundayOfCurrentWeek = Globals.addDays(new Date(), 7).getTime();

    if (indicateur === 'actionsOuvertes') {
      if (legende === 'Mois (J+30)') {
        queryParamsValue = { statut_action: this.statutActionEncours.code_parametre, closed_debut: today, closed_fin: lastDateOfMonth};
      } else if (legende === 'Semaine (J+7)') {
        queryParamsValue = { statut_action: this.statutActionEncours.code_parametre, closed_debut: today, closed_fin: sundayOfCurrentWeek};
      } else if (legende === 'Jour') {
        queryParamsValue = { statut_action: this.statutActionEncours.code_parametre, closed_debut: today, closed_fin: today};
      } else if (legende === 'Dépassée') {
        queryParamsValue = { statut_action: this.statutActionEncours.code_parametre, closed_fin: yesterday};
      } else if (legende === 'Sans échéance') {
        queryParamsValue = { statut_action: this.statutActionEncours.code_parametre, no_echeance: 'O'};
      }
    } else if (indicateur === 'mesActions') {
      if (legende === 'Mois (J+30)') {
        queryParamsValue = { statut_action: this.statutActionEncours.code_parametre, closed_debut: today, closed_fin: lastDateOfMonth, responsable: this.currentUtilisateur.id_utilisateur};
      } else if (legende === 'Semaine (J+7)') {
        queryParamsValue = { statut_action: this.statutActionEncours.code_parametre, closed_debut: today, closed_fin: sundayOfCurrentWeek, responsable: this.currentUtilisateur.id_utilisateur};
      } else if (legende === 'Jour') {
        queryParamsValue = { statut_action: this.statutActionEncours.code_parametre, closed_debut: today, closed_fin: today, responsable: this.currentUtilisateur.id_utilisateur};
      } else if (legende === 'Dépassée') {
        queryParamsValue = { statut_action: this.statutActionEncours.code_parametre, closed_fin: yesterday, responsable: this.currentUtilisateur.id_utilisateur};
      } else if (legende === 'Sans échéance') {
        queryParamsValue = { statut_action: this.statutActionEncours.code_parametre, no_echeance: 'O', responsable: this.currentUtilisateur.id_utilisateur};
      }
    } else if (indicateur === 'actionsSuiviOuvertes') {
      if (legende === 'Mois (J+30)') {
        queryParamsValue = { statut_action: this.statutActionEncours.code_parametre, closed_debut: today, closed_fin: lastDateOfMonth, objet_action: 'Suivi'};
      } else if (legende === 'Semaine (J+7)') {
        queryParamsValue = { statut_action: this.statutActionEncours.code_parametre, closed_debut: today, closed_fin: sundayOfCurrentWeek, objet_action: 'Suivi'};
      } else if (legende === 'Jour') {
        queryParamsValue = { statut_action: this.statutActionEncours.code_parametre, closed_debut: today, closed_fin: today, objet_action: 'Suivi'};
      } else if (legende === 'Dépassée') {
        queryParamsValue = { statut_action: this.statutActionEncours.code_parametre, closed_fin: yesterday, objet_action: 'Suivi'};
      } else if (legende === 'Sans échéance') {
        queryParamsValue = { statut_action: this.statutActionEncours.code_parametre, no_echeance: 'O', objet_action: 'Suivi'};
      }
    } else if (indicateur === 'mesActionsSuivi') {
      if (legende === 'Mois (J+30)') {
        queryParamsValue = { statut_action: this.statutActionEncours.code_parametre, closed_debut: today, closed_fin: lastDateOfMonth, responsable: this.currentUtilisateur.id_utilisateur, objet_action: 'Suivi'};
      } else if (legende === 'Semaine (J+7)') {
        queryParamsValue = { statut_action: this.statutActionEncours.code_parametre, closed_debut: today, closed_fin: sundayOfCurrentWeek, responsable: this.currentUtilisateur.id_utilisateur, objet_action: 'Suivi'};
      } else if (legende === 'Jour') {
        queryParamsValue = { statut_action: this.statutActionEncours.code_parametre, closed_debut: today, closed_fin: today, responsable: this.currentUtilisateur.id_utilisateur, objet_action: 'Suivi'} ;
      } else if (legende === 'Dépassée') {
        queryParamsValue = { statut_action: this.statutActionEncours.code_parametre, closed_fin: yesterday, responsable: this.currentUtilisateur.id_utilisateur, objet_action: 'Suivi'};
      } else if (legende === 'Sans échéance') {
        queryParamsValue = { statut_action: this.statutActionEncours.code_parametre, no_echeance: 'O', responsable: this.currentUtilisateur.id_utilisateur, objet_action: 'Suivi'};
      }
    }

    if (isNewTab) {
      let url = this.router.createUrlTree([path], { queryParams: queryParamsValue});
      window.open(url.toString(), '_blank');
    } else {
      this.router.navigate([path], { queryParams: queryParamsValue });
    }
  }

  showTuile(tuile: string): boolean {
    if ((tuile === 'echanges_urgents_a_traiter' || tuile === 'reclamations_ouvertes' || tuile === 'echanges_brouillons'
    || tuile === 'mes_echanges_brouillons' || tuile === 'echanges_rem_inf' || tuile === 'echanges_astreintes')
        && !this.currentUtilisateur.acces_echanges) {
      return false;
    } else if ((tuile === 'nouvelles_alertes_vigilances' || tuile === 'alertes_risque_infectieux'
    || tuile === 'actions_suivi_ouvertes' || tuile === 'mes_actions_suivi')
        && !this.currentUtilisateur.acces_alertes) {
      return false;
    } else if ( (tuile === 'mes_actions' || tuile === 'actions_ouvertes')
        && !this.currentUtilisateur.acces_alertes
        && !this.currentUtilisateur.acces_echanges) {
      return false;
    } else if ( (tuile === 'echanges_ouverts')
        && !this.currentUtilisateur.hasFonctionnalite(DroitModule.module_personia, DroitModule.fonctionnalite_edit_echanges)
        && !this.currentUtilisateur.acces_echanges) {
      return false;
    } else if ((tuile === 'mes_echanges_brouillons' || tuile === 'mes_echanges' || tuile === 'mes_echanges_urgents_ouverts')
        && !this.currentUtilisateur.hasFonctionnalite(DroitModule.module_echanges, DroitModule.fonctionnalite_edit_echanges)
        && !this.currentUtilisateur.hasFonctionnalite(DroitModule.module_personia, DroitModule.fonctionnalite_edit_echanges)) {
      return false;
    }

    if (tuile === 'mes_evaluations_a_realiser'
        && !this.currentUtilisateur.hasFonctionnalite(DroitModule.module_evaluations, DroitModule.fonctionnalite_creation_evaluations)
        && !this.currentUtilisateur.hasFonctionnalite(DroitModule.module_evaluations, DroitModule.fonctionnalite_edit_evaluations)) {
      return false;
    } else if (tuile === 'mes_actions' && this.currentUtilisateur.isPersonia) {
      return false;
    } else if (tuile === 'actions_ouvertes' && this.currentUtilisateur.isPersonia) {
      return false;
    }

    // On regarde les preferences d'affichage du tableau de bord
    if (!this.currentUtilisateur.preferences_tableau_bord
        || this.currentUtilisateur.preferences_tableau_bord.length === 0
        || this.currentUtilisateur.preferences_tableau_bord.includes(tuile)) {
      return true;
    }

    return false;
  }

  isFederationReadOnly() {
    if (this.currentUtilisateur.perimetre !== 'National') {
      return true;
    }
    return false;
  }

  isAssociationReadOnly() {
    if ((this.currentUtilisateur.perimetre === 'Association' && (!this.currentUtilisateur.type_acces || this.currentUtilisateur.type_acces === 'multiSite' || this.currentUtilisateur.type_acces === 'monoAsso'))
    || this.currentUtilisateur.perimetre === 'Agence') {
      return true;
    }
    return false;
  }

  isAgenceReadOnly() {
    if (this.currentUtilisateur.perimetre === 'Agence') {
      return true;
    }
    return false;
  }

  updateAssociationsItems() {
    let associationsItemsList = [];
    if (this.associationsList) {
      this.associationsList.forEach(function(association) {
        if (association.disabled) {
          associationsItemsList.push({label: association.libelle, value: association, styleClass: 'italic'});
        } else {
          associationsItemsList.push({label: association.libelle, value: association});
        }
      });
    }

    this.associationsItemsList = associationsItemsList;
  }

  updateAgencesItems() {
    let agencesItemsList = [];
    if (this.agencesList) {
      this.agencesList.forEach(function(agence) {
        if (agence.disabled) {
          agencesItemsList.push({label: agence.libelle, value: agence, styleClass: 'italic'});
        } else {
          agencesItemsList.push({label: agence.libelle, value: agence});
        }
      });
    }

    this.agencesItemsList = agencesItemsList;
  }

  getNewFiltreEchange() {
    let filtreEchange = new FiltreEchange();
    if (this.federationSelected) {
      filtreEchange.federation = [this.federationSelected];
    }
    if (this.associationSelected) {
      filtreEchange.association = [this.associationSelected];
    }
    if (this.agenceSelected) {
      filtreEchange.agence = [this.agenceSelected];
    }

    return filtreEchange;
  }


  getNewFiltreAction() {
    let filtreAction = new FiltreAction();
    if (this.federationSelected) {
      filtreAction.federation = this.federationSelected;
    }
    if (this.associationSelected) {
      filtreAction.association = this.associationSelected;
    }

    return filtreAction;
  }

  initIndicateurs() {
    this.nbEchangesUrgents = null;
    this.nbReclamations = null;
    this.nbBrouillons = null;
    this.nbAstreintes = null;
    this.nbMesEchanges = null;
    this.nbMesBrouillons = null;
    this.nbEchangesRemInf = null;
    this.nbMesEchangesUrgentsOuverts = null;
    this.nbEchangesDevisOuverts = null;
    this.nbEchangesProspectOuverts = null;
  }

}
