import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const EXCEL_EXTENSION = '.xlsx';

@Injectable({ providedIn: 'root' })
export class ExportService {

  constructor() { }

  exportAsExcelFile(datas: any[], excelFileName: string, hasMultipleHeaders = false): void {

    let excelBuffer: any;
    if (!hasMultipleHeaders) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(datas);
      let workbook: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, excelFileName);

      excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    } else {
      let worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(datas[0]);
      let originIndex = datas[0].length + 2; // On deplace le curseur du nombre d'élément + 2 (le header + ligne vide)
      for (let i = 1; i < datas.length; i++) {
        XLSX.utils.sheet_add_json(worksheet, datas[i], {origin: originIndex});
        originIndex += datas[i].length + 2; // On deplace le curseur du nombre d'élément + 2 (le header + ligne vide)
      }

      let workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, excelFileName);

      excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    }

    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});

    FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
  }

  exportAsCsvFile(datas: any, csvFileName: string, hasMultipleHeaders = false) {
    const replacer = (key, value) => value === null ? '' : value === true ? 'Oui' : value === false ? 'Non' : value; // specify how you want to handle null values here

    let csv = [];
    let csvArray = '';

    if (!hasMultipleHeaders) {
      const header = Object.keys(datas[0]);
      csv = datas.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(';'));
      csv.unshift(header.join(';'));
      csvArray = csv.join('\r\n');
    } else {
      let header = [];

      datas.forEach(function(data) {
        header = Object.keys(data[0]);
        csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(';'));
        csv.unshift(header.join(';'));
        csvArray += csv.join('\r\n') + '\r\n\r\n'; // Saut de ligne
      });
    }

    this.saveAsCsvFile(csvArray, csvFileName);
  }

  private saveAsCsvFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob(['\ufeff' + buffer], {type: 'text/csv;charset=utf-8;'}); // On ajoute le BOM au début du fichier pour encodage UTF-8

    FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + '.csv');
  }
}
