import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {Validators, FormControl, FormGroup, FormBuilder} from '@angular/forms';

import {plainToClass} from 'class-transformer';
import {SelectItem} from 'primeng/api';

import { EvaluationEntete, Action, Parametre } from '../_models';
import { FiltreEvaluation } from 'src/app/shared/utils/filtre-evaluation';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class EvaluationService {

  filtreEvaluation = new FiltreEvaluation();
  evaluationsEnteteList: EvaluationEntete[]; // Le derniers resultat de la recherche des evaluations

  constructor(private http: HttpClient,
              private fb: FormBuilder) { }

  /** GET evaluations */
  getAll(): Observable<EvaluationEntete[]> {
    return this.http.get<EvaluationEntete[]>(`${environment.apiHost}/${environment.apiNamespace}/evaluations`);
  }

  /** GET evaluations/:id */
  getEvaluationEntete(id_evaluation_entete: number): Observable<EvaluationEntete> {
    return this.http.get<EvaluationEntete>(`${environment.apiHost}/${environment.apiNamespace}/evaluations/${id_evaluation_entete}`);
  }

  /** GET evaluations */
  getEvaluationsByParams(paramsValue: FiltreEvaluation, updateFiltre = true): Observable<EvaluationEntete[]> {
    if (updateFiltre) {
      this.filtreEvaluation = paramsValue;
    }

    let paramsValueSerialized = paramsValue.serialize();
    let params = new HttpParams();
    for (let paramKey in paramsValueSerialized) {
      if (paramsValueSerialized.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValueSerialized[paramKey]);
      }
    }
    return this.http.get<EvaluationEntete[]>(`${environment.apiHost}/${environment.apiNamespace}/evaluations`, { params: params });
  }

  /** GET evaluations/count */
  getNbEvaluationsByParams(paramsValue: FiltreEvaluation): Observable<any> {
    let paramsValueSerialized = paramsValue.serialize();
    let params = new HttpParams();
    for (let paramKey in paramsValueSerialized) {
      if (paramsValueSerialized.hasOwnProperty(paramKey)) {
        params = params.append(paramKey, paramsValueSerialized[paramKey]);
      }
    }
    return this.http.get<any>(`${environment.apiHost}/${environment.apiNamespace}/evaluations/count`, { params: params });
  }

  /** POST evaluations */
  createEvaluationEntete(evaluation_entete: EvaluationEntete): Observable<EvaluationEntete> {
    return this.http.post<EvaluationEntete>(
      `${environment.apiHost}/${environment.apiNamespace}/evaluations`,
      plainToClass(EvaluationEntete, evaluation_entete).serialize()
     );
  }

  /** PUT evaluations/:id */
  updateEvaluationEntete(evaluation_entete: EvaluationEntete): Observable<EvaluationEntete> {console.log('updateEvaluationEntete');
    return this.http.put<EvaluationEntete>(
      `${environment.apiHost}/${environment.apiNamespace}/evaluations/${evaluation_entete.id_evaluation_entete}`,
      plainToClass(EvaluationEntete, evaluation_entete).serialize()
    );
  }

  /** PUT validateSaisieEvaluation/:id */
  validateSaisieEvaluationEntete(evaluation_entete: EvaluationEntete): Observable<EvaluationEntete> {
    return this.http.put<EvaluationEntete>(
      `${environment.apiHost}/${environment.apiNamespace}/validateSaisieEvaluation/${evaluation_entete.id_evaluation_entete}`,
      plainToClass(EvaluationEntete, evaluation_entete).serialize()
    );
  }

  /** GET assignEvaluation/:id */
  assignEvaluation(id_evaluation_entete: number): Observable<EvaluationEntete> {
    return this.http.get<EvaluationEntete>(`${environment.apiHost}/${environment.apiNamespace}/assignEvaluation/${id_evaluation_entete}`);
  }

  /** GET releaseEvaluation/:id */
  releaseEvaluation(id_evaluation_entete: number): Observable<EvaluationEntete> {
    return this.http.get<EvaluationEntete>(`${environment.apiHost}/${environment.apiNamespace}/releaseEvaluation/${id_evaluation_entete}`);
  }

  /** PUT cancelEvaluation/:id */
  cancelEvaluation(id_evaluation_entete: number, motif_annulation: Parametre): Observable<EvaluationEntete> {
    let params = {};
    params['motif_annulation'] = motif_annulation.id_parametre;
    return this.http.put<EvaluationEntete>(`${environment.apiHost}/${environment.apiNamespace}/cancelEvaluation/${id_evaluation_entete}`, params);
  }

  /** GET evaluationReadyToRealize/:id */
  readyToRealize(id_evaluation_entete: number): Observable<EvaluationEntete> {
    return this.http.get<EvaluationEntete>(`${environment.apiHost}/${environment.apiNamespace}/evaluationReadyToRealize/${id_evaluation_entete}`);
  }

  /** GET evaluationInitialize/:id */
  initializeEvaluation(id_evaluation_entete: number): Observable<EvaluationEntete> {
    return this.http.get<EvaluationEntete>(`${environment.apiHost}/${environment.apiNamespace}/evaluationInitialize/${id_evaluation_entete}`);
  }

  /** GET evaluationPreRemplir/:id */
  preRemplir(id_evaluation_entete: number): Observable<EvaluationEntete> {
    return this.http.get<EvaluationEntete>(`${environment.apiHost}/${environment.apiNamespace}/evaluationPreRemplir/${id_evaluation_entete}`);
  }

  /** GET evaluationCancelPreRemplir/:id */
  cancelPreRemplir(id_evaluation_entete: number): Observable<EvaluationEntete> {
    return this.http.get<EvaluationEntete>(`${environment.apiHost}/${environment.apiNamespace}/evaluationCancelPreRemplir/${id_evaluation_entete}`);
  }

  /** GET evaluationCreateAvenant/:id */
  createAvenant(id_evaluation_entete: number): Observable<EvaluationEntete> {
    return this.http.get<EvaluationEntete>(`${environment.apiHost}/${environment.apiNamespace}/evaluationCreateAvenant/${id_evaluation_entete}`);
  }

  /** GET evaluationCancelAvenant/:id */
  cancelAvenant(id_evaluation_entete: number): Observable<EvaluationEntete> {
    return this.http.get<EvaluationEntete>(`${environment.apiHost}/${environment.apiNamespace}/evaluationCancelAvenant/${id_evaluation_entete}`);
  }

  /** GET historiqueEvaluation/:id_philia_client */
  getEvaluationsHistoriqueByIdPhilia(id_philia: number): Observable<EvaluationEntete[]> {
    return this.http.get<EvaluationEntete[]>(`${environment.apiHost}/${environment.apiNamespace}/historiqueEvaluation/${id_philia}`);
  }

  /** GET evaluationSaisir/:id */
  saisirEvaluation(id_evaluation_entete: number): Observable<EvaluationEntete> {
    return this.http.get<EvaluationEntete>(`${environment.apiHost}/${environment.apiNamespace}/evaluationSaisir/${id_evaluation_entete}`);
  }

  /** PATCH evaluationSaisir/:id */
  saveEcheance(evaluation_entete: EvaluationEntete): Observable<EvaluationEntete> {
    return this.http.patch<EvaluationEntete>(
      `${environment.apiHost}/${environment.apiNamespace}/evaluations/${evaluation_entete.id_evaluation_entete}`,
      {echeance: plainToClass(EvaluationEntete, evaluation_entete).serialize()['echeance']}
     );
  }

  initFiltreEvaluation() {
    this.filtreEvaluation = new FiltreEvaluation();
  }

  createEvaluationForm(evaluationEntete: EvaluationEntete): FormGroup {
      let evaluationForm = EvaluationEntete.getForm(evaluationEntete);

      evaluationForm.get('client').get('id_philia').setValidators(Validators.required);
      evaluationForm.get('client').get('nom').setValidators(Validators.required);
      evaluationForm.get('client').get('client').patchValue(true);

      return evaluationForm;
  }

  getObjets(): SelectItem[] {
    let objets = [];
    let objetsListeEvaluation = Action.objetsListeEvaluation;

    for (let i = 0; i < objetsListeEvaluation.length; i++) {
      objets.push({label: objetsListeEvaluation[i], value: objetsListeEvaluation[i]});
    }

    // On trie par ordre alphabétique
    objets.sort(function(a, b) {
      if ( a.label < b.label) { return -1; }
      if ( a.label > b.label) { return 1; }
      return 0;
    } );

    return objets;
  }

  /** GET evaluations/reevaluate/:id */
  reevaluateEvaluation(id_evaluation_entete: number): Observable<any> {
    return this.http.get<any>(`${environment.apiHost}/${environment.apiNamespace}/evaluations/reevaluateEvaluation/${id_evaluation_entete}`);
  }

  createDemandeIntervention(id_evaluation_entete: number) {
    return this.http.get<any>(`${environment.apiHost}/${environment.apiNamespace}/evaluations/createDemandeIntervention/${id_evaluation_entete}`);
  }

  /* handleError */
  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(error);
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a utilisateur-facing error message
    return throwError(
      error);
  }

}
