import {FormControl, FormGroup} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {InterventionBesoin} from './intervention-besoin';

export class AccompagnementActivite {

  id_accompagnement_activite: number;

  description: string;

  @Type(() => InterventionBesoin)
  intervention_besoin: InterventionBesoin;

  activite: string;

  static getForm(accompagnement_activite = null): FormGroup {
    if (!accompagnement_activite) {
      accompagnement_activite = new AccompagnementActivite();
    }

    return new FormGroup({
      'id_accompagnement_activite': new FormControl(accompagnement_activite.id_accompagnement_activite),
      'description': new FormControl(accompagnement_activite.description),
      'intervention_besoin': new FormControl(accompagnement_activite.intervention_besoin),
      'activite': new FormControl(accompagnement_activite.activite),
    });
  }

  serialize() {
    return classToPlain(this);
  }

}
