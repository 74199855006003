import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {deserialize, plainToClass} from 'class-transformer';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../../environments/environment';
import {Association, Utilisateur} from '../_models';
import {ActionService} from './action.service';
import {AgenceService} from './agence.service';
import {AlerteService} from './alerte.service';
import {AppreciationService} from './appreciation.service';
import {AssociationService} from './association.service';
import {EchangeService} from './echange.service';
import {EvaluationService} from './evaluation.service';
import {FederationService} from './federation.service';
import {GroupeService} from './groupe.service';
import {ProfilService} from './profil.service';

@Injectable({providedIn: 'root'})
export class AuthenticationService {

  currentUtilisateur$: Observable<Utilisateur | null>;
  private currentUtilisateurSubject: BehaviorSubject<Utilisateur | null>;

  constructor(
    private http: HttpClient,
    private echangeService: EchangeService,
    private actionService: ActionService,
    private evaluationService: EvaluationService,
    private alerteService: AlerteService,
    private appreciationService: AppreciationService,
    private federationService: FederationService,
    private associationService: AssociationService,
    private agenceService: AgenceService,
    private profilService: ProfilService,
    private groupeService: GroupeService
  ) {
    this.currentUtilisateurSubject = new BehaviorSubject<Utilisateur | null>(null);
    this.currentUtilisateur$ = this.currentUtilisateurSubject.asObservable();
  }

  get currentAssociationForUserMultiSite(): Association {
    if (sessionStorage.getItem('currentAssociationForUserMultiSite') === null
      || sessionStorage.getItem('currentAssociationForUserMultiSite') === undefined) {
      return null;
    }
    return deserialize(Association, sessionStorage.getItem('currentAssociationForUserMultiSite'));
  }

  set currentAssociationForUserMultiSite(currentAssociation: Association) {
    if (currentAssociation) {
      this.http.post<any>(`${environment.apiHost}/${environment.apiNamespace}/set_session_association`, {association: currentAssociation.id_association}).subscribe();
      sessionStorage.setItem('currentAssociationForUserMultiSite', JSON.stringify(currentAssociation));
    }
  }

  get currentUtilisateurValue(): Utilisateur {
    return plainToClass(Utilisateur, this.currentUtilisateurSubject.value);
  }

  set currentUtilisateurValue(utilisateur: Utilisateur) {
    this.currentUtilisateurSubject.next(utilisateur);
  }

  initFiltres() {
    this.echangeService.initFiltreEchange();
    this.actionService.initFiltreAction();
    this.evaluationService.initFiltreEvaluation();
    this.alerteService.initFiltreAlerte();
    this.appreciationService.initFiltreAppreciation();
    this.federationService.initFiltreFederation();
    this.associationService.initFiltreAssociation();
    this.agenceService.initFiltreAgence();
    this.profilService.initFiltreProfil();
    this.groupeService.initFiltreGroupe();
  }

  isLoggedIn() {
    return !!this.currentUtilisateurSubject.value;
  }

  login(): Observable<Utilisateur> {
    return this.http.get<Utilisateur>(`${environment.apiHost}/${environment.apiNamespace}/utilisateur/connecte`).pipe(
      map(utilisateur => {
        utilisateur = plainToClass(Utilisateur, utilisateur);
        utilisateur.setMultiSiteAssociations(utilisateur.droits); // On initialise les associations si c'est un utilisateur multi site / multi asso

        this.currentUtilisateurSubject.next(utilisateur);

        // On réinitialise les filtres
        this.initFiltres();

        // Place le profil astreinte dans le SessionStorage (utilisé pour le switch Astreinte)
        let switchAstreinte = false;
        if (utilisateur.profil !== null) {
          switchAstreinte = (utilisateur.profil.libelle === 'Astreinte');
        }
        if (sessionStorage.getItem('switchAstreinte') === null) {
          sessionStorage.setItem('switchAstreinte', JSON.stringify(switchAstreinte));
        }

        return utilisateur;
      }),
    );
  }

  logout(): void {
    sessionStorage.clear();
    window.location.replace(environment.keycloak.disconnectUrl);
  }
}
