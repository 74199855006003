<p-confirmDialog #cd header="Confirmation" icon="pi pi-exclamation-triangle" baseZIndex="1000000">
  <p-footer>
    <button type="button" class="grey-button" pButton icon="pi pi-times" label="Non" (click)="cd.reject()"></button>
    <button type="button" pButton [class]="getClassButton()" icon="pi pi-check" label="Oui" (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>

<p-dialog [(visible)]="showPopupEvaluation" [style]="{maxWidth: '900px'}" [contentStyle]="{'max-height':'600px'}"
  (onHide)="showPopupEvaluation=false" baseZIndex="100001" modal="true">
  <p-header>
    Impossible de créer l'évaluation
  </p-header>
  <div class="ui-grid ui-grid-responsive ui-grid-pad ui-fluid" style="margin: 10px 0px">
    Une évaluation du même type pour ce client est déjà en cours.
  </div>
  <p-footer>
    <div class="ui-grid-row" style="text-align:center;">
      <button pButton type="button" class="grey-button" label="Ok" (click)="showPopupEvaluation=false"></button>
    </div>
  </p-footer>
</p-dialog>

<p-dialog *ngIf="actionForm" [(visible)]="showPopupAction" [style]="{maxWidth: '900px'}"
  [contentStyle]="{'max-height':'600px'}" (onHide)="onCloseActionPopup()" baseZIndex="10001" modal="true">
  <p-header>
    <div class="ui-g-8" style="font-size: 15px;">{{popupActionTitle}}</div>
    <div class="ui-g-3" style="text-align: right;">
      <button pButton type="button" class="white-button small-button" style="width: 150px;" label="Enregistrer"
      (click)="submitAction()" [disabled]="saveActionDisabled"></button>
    </div>
  </p-header>
  <app-loader *ngIf="loading"></app-loader>
  <div *ngIf="!loading" class="ui-grid ui-grid-responsive ui-grid-pad ui-fluid" style="margin: 10px 0px">
    <form [formGroup]="actionForm">
      <div class="ui-grid-row">
        <div class="ui-g-4">
          <div class="ui-g-12">
            <label>Date / Heure création</label>
          </div>
          <div class="ui-g-8">
            <input type="text" pInputText [value]="actionForm.value.created | date: datePipeFormat" readonly="true" />
          </div>
          <div class="ui-g-12" *ngIf="!actionForm.controls['created'].valid && actionForm.controls['created'].dirty">
            <p-message severity="error" text="La date de création est invalide"></p-message>
          </div>
        </div>
        <div class="ui-g-4">
          <div class="ui-g-12">
            <label>Rédacteur</label>
          </div>
          <div class="ui-g-12">
            <input type="text" pInputText [value]="actionForm.value.redacteur?.libelle" readonly="true" />
          </div>
          <div class="ui-g-12"
            *ngIf="actionForm.controls['redacteur'].invalid && (actionForm.controls['redacteur'].dirty || actionForm.controls['redacteur'].touched)">
            <p-message severity="error" text="Le rédacteur est invalide"></p-message>
          </div>
        </div>
        <div class="ui-g-4">
          <div class="ui-g-12">
            <label>Objet</label>
          </div>
          <div class="ui-g-12">
            <input *ngIf="!action.isEvaluation() && !action.isAlerte() && !action.isReclamation()" type="text" pInputText [readonly]="actionIsClosed || !actionIsNew"
              formControlName="objet_action" />
            <p-dropdown *ngIf="action.isEvaluation() || action.isAlerte() || action.isReclamation()" [options]="objetsList" formControlName="objet_action" placeholder="Aucun">
            </p-dropdown>
          </div>
          <div class="ui-g-12"
            *ngIf="actionForm.controls['objet_action'].invalid && (actionForm.controls['objet_action'].dirty || actionForm.controls['objet_action'].touched)">
            <p-message severity="error" text="L'objet est invalide"></p-message>
          </div>
        </div>
      </div>
      <div class="ui-grid-row">
        <div class="ui-g-4">
          <div class="ui-g-12">
            <label>Echéance</label>
          </div>
          <div class="ui-g-12">
            <p-calendar *ngIf="actionIsNew || !actionIsClosed" [dateFormat]="dateFormat" [locale]="locale"
              formControlName="echeance" baseZIndex="11001"></p-calendar>
            <input *ngIf="!actionIsNew && actionIsClosed" type="text" pInputText
              [value]="actionForm.value.echeance | date: datePipeFormatWithoutTime" readonly="true" />
          </div>
          <div class="ui-g-12"
            *ngIf="actionForm.controls['echeance'].invalid && (actionForm.controls['echeance'].dirty || actionForm.controls['echeance'].touched)">
            <p-message severity="error" text="La date d'échéance est invalide"></p-message>
          </div>
        </div>
        <div class="ui-g-4">
          <div class="ui-g-12">
            <label>{{acteurLabel}}</label>
          </div>
          <div class="ui-g-12">
            <p-dropdown [options]="responsablesList" [class]="actionIsClosed ? 'disabled' : ''"
              [placeholder]="'Aucun'" [style]="{'width':'100%'}"
              [panelStyleClass]="getClassDropdown()" [readonly]="actionIsClosed"
              formControlName="responsable" optionLabel="libelle" dataKey="id_utilisateur" filter="true"
              baseZIndex="11001" [showClear]="!actionIsClosed"></p-dropdown>
          </div>
          <div class="ui-g-12"
            *ngIf="actionForm.controls['responsable'].invalid && (actionForm.controls['responsable'].dirty || actionForm.controls['responsable'].touched)">
            <p-message severity="error" text="L'acteur est invalide"></p-message>
          </div>
        </div>
        <div *ngIf="action.isEvaluation()" class="ui-g-4">
          <div class="ui-g-12">
            <label>Type de public</label>
          </div>
          <div class="ui-g-12">
            <p-dropdown [options]="typesPublicList" [class]="(!actionIsNew || actionIsClosed) ? 'disabled' : ''"
              [placeholder]="'Aucun'" [style]="{'width':'100%'}"
              [panelStyleClass]="getClassDropdown()" [readonly]="!actionIsNew || actionIsClosed "
              formControlName="type_public" optionLabel="libelle" dataKey="id_parametre" appendTo="body"
              baseZIndex="11001"></p-dropdown>
          </div>
          <div class="ui-g-12"
            *ngIf="actionForm.controls['type_public'].invalid && (actionForm.controls['type_public'].dirty || actionForm.controls['type_public'].touched)">
            <p-message severity="error" text="Le type de public est invalide"></p-message>
          </div>
        </div>
        <div *ngIf="!action.isEvaluation()" class="ui-g-4">
          <div class="ui-g-12">
            <label>Groupes observateurs</label>
          </div>
          <div class="ui-g-12">
            <p-multiSelect [options]="groupesList" formControlName="groupes" dataKey="id_groupe" [readonly]="actionIsClosed"
              name="groupes" optionLabel="libelle" [filter]="false" defaultLabel="Aucun" [class]="actionIsClosed ? 'disabled' : ''"
              selectedItemsLabel="{0} sélectionnés"></p-multiSelect>
          </div>
          <div class="ui-g-12"
            *ngIf="actionForm.controls['groupes'].invalid && (actionForm.controls['groupes'].dirty || actionForm.controls['groupes'].touched)">
            <p-message severity="error" text="Les groupes sont invalides"></p-message>
          </div>
        </div>
      </div>
      <div class="ui-grid-row">
        <div class="ui-g-12">
          <div class="ui-g-4">
            <label>Descriptif/Réponse de l'action</label>
          </div>
          <div class="ui-g-4">
            <button pButton type="button" [disabled]="actionIsClosed" [class]="getClassButton()"
              [label]="getLabelCopyDescription()" icon="pi pi-copy"
              (click)="copyDescriptionInAction()"></button>
          </div>
          <div *ngIf="action.isEvaluation() && action.evaluation_entete && action.evaluation_entete.id_evaluation_entete" class="ui-g-4">
            <button pButton type="button" [class]="getClassButton()" label="Voir l'évaluation" icon="pi pi-eye"
              (click)="goToEvaluation()"></button>
          </div>
          <div class="ui-g-12">
            <textarea pInputTextarea [rows]="5" [readonly]="actionIsClosed" placeholder="Descriptif/Réponse de l'action"
              formControlName="description_action"></textarea>
          </div>
          <div class="ui-g-12"
            *ngIf="actionForm.controls['description_action'].invalid && (actionForm.controls['description_action'].dirty || actionForm.controls['description_action'].touched)">
            <p-message severity="error" text="La description est invalide"></p-message>
          </div>
        </div>
      </div>
      <div class="ui-grid-row">
        <div class="ui-g-4">
          <div class="ui-g-12">
            <label>MAJ</label>
          </div>
          <div class="ui-g-12">
            <input type="text" pInputText [value]="actionForm.value.updated | date: datePipeFormat" readonly="true" />
          </div>
          <div class="ui-g-12"
            *ngIf="actionForm.controls['updated'].invalid && (actionForm.controls['updated'].dirty || actionForm.controls['updated'].touched)">
            <p-message severity="error" text="La date de mise à jour est invalide"></p-message>
          </div>
        </div>
        <div class="ui-g-4">
          <div class="ui-g-12">
            <label>Statut de l'action</label>
          </div>
          <div class="ui-g-12">
            <p-dropdown [options]="statutsActionList" [class]="actionIsClosed ? 'disabled' : ''"
              [placeholder]="'Statut...'" [style]="{width: '100%'}"
              [panelStyleClass]="getClassDropdown()" [readonly]="actionIsClosed" formControlName="statut_action"
              optionLabel="libelle" dataKey="id_parametre" appendTo="body" baseZIndex="11001"></p-dropdown>
          </div>
          <div class="ui-g-12"
            *ngIf="actionForm.controls['statut_action'].invalid && (actionForm.controls['statut_action'].dirty || actionForm.controls['statut_action'].touched)">
            <p-message severity="error" text="Le statut est invalide"></p-message>
          </div>
        </div>
        <div *ngIf="action.evaluation_entete" class="ui-g-4" formGroupName="evaluation_entete">
          <div class="ui-g-12">
            <label>Statut de l'évaluation</label>
          </div>
          <div class="ui-g-12">
            <p-dropdown *ngIf="actionIsNew" [options]="statutsEvaluationActionCreationList" [placeholder]="'Statut de l\'évaluation...'" [style]="{width: '100%'}"
            [panelStyleClass]="getClassDropdown()" formControlName="statut_evaluation"
            optionLabel="libelle" dataKey="id_parametre" appendTo="body" baseZIndex="11001"></p-dropdown>

            <input *ngIf="!actionIsNew" type="text" pInputText [value]="action.evaluation_entete.statut_evaluation.libelle"
              readonly="true" />
          </div>
        </div>
      </div>
    </form>
  </div>
  <p-footer>
    <div class="ui-grid-row">
      <div class="ui-g-12">
        <p-checkbox *ngIf="showResponsableMail" [label]="showResponsableMailLabel"
          [formControl]="actionForm.controls['send_mail_responsable']"></p-checkbox>
      </div>
    </div>
  </p-footer>
</p-dialog>
