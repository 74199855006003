import { Component, OnInit, ViewChild } from '@angular/core';

import { EchangeService, AuthenticationService } from '../../shared/_services';
import { Utilisateur } from '../../shared/_models';

import {FiltreEchange} from '../../shared/utils/filtre-echange';

import {Globals} from '../../shared/utils/globals';

import { FiltresEchangeComponent } from '../filtres-echange/filtres-echange.component';
import { ListeEchangesComponent } from 'src/app/shared/liste-echanges/liste-echanges.component';

@Component({
  selector: 'app-recherche-echange',
  templateUrl: './recherche-echange.component.html',
  styleUrls: ['./recherche-echange.component.css']
})
export class RechercheEchangeComponent implements OnInit {

  @ViewChild('accordionFilter', { static: true }) accordionFilter;
  @ViewChild(FiltresEchangeComponent, { static: true }) private filtresEchangeComponent: FiltresEchangeComponent; // Composant filtres des échanges
  @ViewChild(ListeEchangesComponent, { static: true }) private listeEchangesComponent: ListeEchangesComponent; // Composant qui affiche la liste des echanges

  currentUtilisateur: Utilisateur;

  filtreEchange: FiltreEchange;

  datePipeFormatWithoutTime = Globals.datePipeFormatWithoutTime;
  timeFormat = Globals.timeFormat;

  activeIndexFilter: number;

  constructor(
    private authenticationService: AuthenticationService,
    private echangeService: EchangeService
    ) { }

  ngOnInit() {
    this.authenticationService.currentUtilisateur$.subscribe(utilisateur => {
      if ( utilisateur ) {
        this.currentUtilisateur = this.authenticationService.currentUtilisateurValue;
      }
    });

    this.filtreEchange = this.echangeService.filtreEchange;

    this.activeIndexFilter = 0;
  }

  searchEchange(filtreEchange: FiltreEchange) {
    // Remise a zero de l'offset au clic Rechercher (page 1)
    filtreEchange.offset = 0;

    this.listeEchangesComponent.updateFiltreEchange(filtreEchange);
    this.listeEchangesComponent.resetPagination();
  }

  refresh() {
    this.filtresEchangeComponent.onSubmit();
  }

  initFilter() {
    this.filtreEchange = new FiltreEchange();
    this.filtresEchangeComponent.initFilter(false);
  }

  toggleShowFilter() {
    if (this.activeIndexFilter === 0) {
      this.activeIndexFilter = -1;
    } else {
      this.activeIndexFilter = 0;
    }
  }

  closeFilters() {
    this.activeIndexFilter = -1;
  }

  get toggleShowFilterLabel() {
    if (this.activeIndexFilter === 0) {
      return 'Masquer les critères';
    } else {
      return 'Afficher les critères';
    }
  }

}
