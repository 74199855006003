import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../shared/_guards';
import {AdminHomeComponent} from './admin-home/admin-home.component';
// Agence Components
import {NsiAdminAgenceEditComponent} from 'node_modules/nsi-admin-agences';
import {NsiAdminAgenceListComponent} from 'node_modules/nsi-admin-agences';
import {NsiAdminAgenceNewComponent} from 'node_modules/nsi-admin-agences';
// Association Components
import {NsiAdminAssociationEditComponent} from 'nsi-admin-associations';
import {NsiAdminAssociationListComponent} from 'nsi-admin-associations';
import {NsiAdminAssociationNewComponent} from 'nsi-admin-associations';
// Federation Components
import {NsiAdminFederationEditComponent} from 'nsi-admin-federations';
import {NsiAdminFederationListComponent} from 'nsi-admin-federations';
import {NsiAdminFederationNewComponent} from 'nsi-admin-federations';
// Groupe Components
import {NsiAdminGroupeEditComponent} from 'nsi-admin-groupes';
import {NsiAdminGroupeListComponent} from 'nsi-admin-groupes';
import {NsiAdminGroupeNewComponent} from 'nsi-admin-groupes';
// Parametre Components
import {NsiAdminParametreEditComponent} from 'nsi-admin-parametres';
import {NsiAdminParametreListComponent} from 'nsi-admin-parametres';
import {NsiAdminParametreNewComponent} from 'nsi-admin-parametres';
// Personia Components
// Profil Components
import {NsiAdminProfilEditComponent} from 'nsi-admin-profils';
import {NsiAdminProfilListComponent} from 'nsi-admin-profils';
import {NsiAdminProfilNewComponent} from 'nsi-admin-profils';
// Utilisateur Components
import {NsiAdminUtilisateurEditComponent} from 'nsi-admin-utilisateurs';
import {NsiAdminUtilisateurListComponent} from 'nsi-admin-utilisateurs';

// Motif Component
import { AdminMotifsComponent } from './admin-motifs/admin-motifs.component';
// Marqueur Component
import { AdminMarqueursComponent } from './admin-marqueurs/admin-marqueurs.component';

const adminRoutes: Routes = [
  {
    path: 'in/admin',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: AdminHomeComponent,
    children: [
      {
        path: 'utilisateur',
        children: [
          {
            path: 'edit/:id',
            component: NsiAdminUtilisateurEditComponent
          },
          {
            path: '',
            component: NsiAdminUtilisateurListComponent
          }
        ]
      },
      {
        path: 'federation',
        children: [
          {
            path: 'edit/:id',
            component: NsiAdminFederationEditComponent
          },
          {
            path: 'new/:id',
            component: NsiAdminFederationNewComponent
          },
          {
            path: '',
            component: NsiAdminFederationListComponent
          }
        ]
      },
      {
        path: 'association',
        children: [
          {
            path: 'edit/:id',
            component: NsiAdminAssociationEditComponent
          },
          {
            path: ':idFedereation/new/:idAssociation',
            component: NsiAdminAssociationNewComponent
          },
          {
            path: '',
            component: NsiAdminAssociationListComponent
          },
        ]
      },
      {
        path: 'parametre',
        children: [
          {
            path: 'edit/:id',
            component: NsiAdminParametreEditComponent
          },
          {
            path: 'new',
            component: NsiAdminParametreNewComponent
          },
          {
            path: '',
            component: NsiAdminParametreListComponent
          }
        ]
      },
      {
        path: 'profil',
        children: [
          {
            path: 'edit/:id',
            component: NsiAdminProfilEditComponent
          },
          {
            path: 'new',
            component: NsiAdminProfilNewComponent
          },
          {
            path: '',
            component: NsiAdminProfilListComponent
          }
        ]
      },
      {
        path: 'groupe',
        children: [
          {
            path: 'edit/:id',
            component: NsiAdminGroupeEditComponent
          },
          {
            path: 'new',
            component: NsiAdminGroupeNewComponent
          },
          {
            path: '',
            component: NsiAdminGroupeListComponent
          }
        ]
      },
      {
        path: 'agence',
        children: [
          {
            path: 'edit/:id',
            component: NsiAdminAgenceEditComponent
          },
          {
            path: 'new',
            component: NsiAdminAgenceNewComponent
          },
          {
            path: '',
            component: NsiAdminAgenceListComponent
          }
        ]
      },
      {
        path: 'motifs',
        children: [
          {
            path: '',
            component: AdminMotifsComponent
          }
        ]
      },
      {
        path: 'marqueurs',
        children: [
          {
            path: '',
            component: AdminMarqueursComponent
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(adminRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AdminRoutingModule {
}
