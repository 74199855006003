import {classToPlain} from 'class-transformer';
import {Association} from './association';

export class Droit {
  id_droit: number;
  utilisateur: string;
  association: Association;
  association_id: number;

  static serializeToApiObject(id_utilisateur: number, associationsList: Association[]) {
    let serializeObject = new Object;
    serializeObject['utilisateur'] = id_utilisateur;

    serializeObject['association'] = [];
    associationsList.forEach(function (association: Association) {
      serializeObject['association'].push(association.id_association);
    });

    return serializeObject;
  }

  serialize() {
    let serializeObject = classToPlain(this);
    delete serializeObject['id_droit'];
    delete serializeObject['association_id'];

    if (this.association !== null && this.association !== undefined) {
      serializeObject['association'] = this.association.id_association;
    }

    return serializeObject;
  }


}
