<p-card id="loading-card" [style]="{width: '440px', margin: '30px auto', padding: '40px 30px' }" styleClass="ui-card-shadow">
    <p-header>
        <div style="text-align: center;">
            <img src="assets/img/admr_logo.png" alt="NSI-ADMR">
            <h1 class="text-center text-loader">{{appTitle}}</h1>
            <p style="text-align: center;color: #5E5E5E;margin-bottom:20px">Chargement des données de l'application en cours</p>
        </div>
    </p-header>
    <div style="text-align: center;">
        <p-progressSpinner></p-progressSpinner>
    </div>
</p-card>

