import {FormControl, FormGroup, Validators,} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import {organismeFinancierValidator} from '../_directives/organisme-financier-validator.directive';
import {Globals} from '../utils/globals';
import {Parametre} from './parametre';
import {Personne} from './personne';

export class OrganismeFinancier {

  id_organisme_financier: number;

  @Type(() => Personne)
  client: Personne;

  @Type(() => Parametre)
  organisme_financier: Parametre;

  @Type(() => Parametre)
  type_aide: Parametre;

  @Type(() => Date)
  date_debut: Date;

  @Type(() => Date)
  date_fin: Date;

  nombre_heure: number;

  quotient_familiale: string;

  identification_organisme: string;

  @Type(() => Parametre)
  frequence_nombre_heure: Parametre;

  nom_organisme_financier: string;

  complement_organisme_financier: string;

  montant: number;

  en_cours: boolean;

  get type_aide_label() {
    let type_aide_label = '';

    if (this.type_aide) {
      type_aide_label = this.type_aide.libelle;
    }

    if (this.complement_organisme_financier) {
      type_aide_label += ' - ' + this.complement_organisme_financier;
    }

    return type_aide_label;
  }

  get organisme_financier_label() {
    let organisme_financier_label = '';

    if (this.organisme_financier) {
      organisme_financier_label = this.organisme_financier.libelle;
    }

    if (this.nom_organisme_financier) {
      organisme_financier_label += ' - ' + this.nom_organisme_financier;
    }

    return organisme_financier_label;
  }

  static getForm(organisme_financier = null): FormGroup {
    if (!organisme_financier) {
      organisme_financier = new organisme_financier();
    }

    let form = new FormGroup({
      'id_organisme_financier': new FormControl(organisme_financier.id_organisme_financier),
      'client': new FormControl(organisme_financier.client),
      'organisme_financier': new FormControl(organisme_financier.organisme_financier, Validators.required),
      'type_aide': new FormControl(organisme_financier.type_aide, Validators.required),
      'date_debut': new FormControl(organisme_financier.date_debut),
      'date_fin': new FormControl(organisme_financier.date_fin),
      'nombre_heure': new FormControl(organisme_financier.nombre_heure, Validators.pattern(/^[.\d]+$/)),
      'quotient_familiale': new FormControl(organisme_financier.quotient_familiale),
      'identification_organisme': new FormControl(organisme_financier.identification_organisme),
      'frequence_nombre_heure': new FormControl(organisme_financier.frequence_nombre_heure),
      'nom_organisme_financier': new FormControl(organisme_financier.nom_organisme_financier),
      'complement_organisme_financier': new FormControl(organisme_financier.complement_organisme_financier),
      'montant': new FormControl(organisme_financier.montant, Validators.pattern(/^[.\d]+$/)),
      'en_cours': new FormControl(organisme_financier.en_cours),
    }, {validators: organismeFinancierValidator});

    if (!organisme_financier.en_cours) {
      form.get('date_debut').setValidators(Validators.required);
    }

    form.updateValueAndValidity();

    return form;
  }

  serialize() {
    let serializeObject = classToPlain(this);

    if (this.organisme_financier !== null && this.organisme_financier !== undefined) {
      serializeObject['organisme_financier'] = this.organisme_financier.id_parametre;
    }

    if (this.type_aide !== null && this.type_aide !== undefined) {
      serializeObject['type_aide'] = this.type_aide.id_parametre;
    }

    if (this.frequence_nombre_heure !== null && this.frequence_nombre_heure !== undefined) {
      serializeObject['frequence_nombre_heure'] = this.frequence_nombre_heure.id_parametre;
    }

    // Conversion des dates en FR
    for (let propertyName in serializeObject) { // On parcourt les proprietes de l'objet
      if (serializeObject[propertyName] && serializeObject[propertyName] instanceof Date) { // Si c'est une date non null
        serializeObject[propertyName] = Globals.getDateWithTimezone(serializeObject[propertyName]);
      }
    }

    return serializeObject;
  }
}
