import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {classToPlain, Type} from 'class-transformer';
import * as uuid from 'uuid';
import {Equipement} from './equipement';


export class GroupeEquipement {

  id_groupe_equipement: number;

  informations_complementaires: string;

  existe: boolean;

  @Type(() => Equipement)
  equipements: Equipement[] = [];

  uuid: string;

  constructor() {
    if (this.id_groupe_equipement) {
      this.uuid = this.id_groupe_equipement.toString();
    } else if (!this.uuid) {
      this.uuid = uuid.v4();
    }
  }

  get equipementsLabel() {
    let labelEquipements = '';
    this.equipements.forEach(function (equipement) {
      if (labelEquipements !== '') {
        labelEquipements += ', ';
      }
      if (equipement.reference !== null) {
        labelEquipements += equipement.reference.libelle;
      } else {
        labelEquipements += 'Autre équipement: ' + equipement.autre_equipement;
      }
    });

    return labelEquipements;
  }

  get hasAutreEquipement() {
    let hasAutreEquipement = false;
    this.equipements.forEach(function (equipement) {
      if (equipement.autre_equipement) {
        hasAutreEquipement = true;
      }
    });

    return hasAutreEquipement;
  }

  get equipementsParametreList() {
    let equipementsParametreList = [];
    this.equipements.forEach(function (equipement) {
      if (equipement.reference) {
        equipementsParametreList.push(equipement.reference);
      }
    });

    return equipementsParametreList;
  }

  static getForm(groupe_equipement = null): FormGroup {
    if (!groupe_equipement) {
      groupe_equipement = new GroupeEquipement();
    }

    let equipementsForm = [];
    groupe_equipement.equipements.forEach(function (equipement) {
      equipementsForm.push(Equipement.getForm(equipement));
    });

    return new FormGroup({
      'id_groupe_equipement': new FormControl(groupe_equipement.id_groupe_equipement),
      'uuid': new FormControl(groupe_equipement.uuid),
      'informations_complementaires': new FormControl(groupe_equipement.informations_complementaires),
      'existe': new FormControl(groupe_equipement.existe),
      'equipements': new FormArray(equipementsForm),
    });
  }

  serialize() {
    let serializeObject = classToPlain(this);
    delete serializeObject['uuid'];

    serializeObject['equipements'] = [];
    this.equipements.forEach(function (equipement) {
      serializeObject['equipements'].push(equipement.serialize());
    });

    return serializeObject;
  }
}
